import { isNotNullish } from '@sp/util/helpers';
import {
  differenceInCalendarMonths,
  differenceInDays,
  format,
  isToday,
  isYesterday,
  startOfMonth,
  subMonths,
} from 'date-fns';
import { Income, IncomeChallenge, IncomeFollow, IncomeSubscription, IncomeType, Period } from './types';

export function isPeriod(period: string): period is Period {
  if (period === 'overall' || period === 'this-month') {
    return true;
  }
  return isNotNullish(period.match(/^(01|02|03|04|05|06|07|08|09|10|11|12)-2\d{3}$/));
}

export function isIncomeChallenge(income: Income): income is IncomeChallenge {
  return income.type === IncomeType.challenge;
}

export function isIncomeSubscription(income: Income): income is IncomeSubscription {
  return income.type === IncomeType.subscription;
}

export function isIncomeFollow(income: Income): income is IncomeFollow {
  return income.type === IncomeType.follow;
}

export function formatDay(ts: number): string {
  if (isToday(ts)) {
    return 'Today';
  }
  if (isYesterday(ts)) {
    return 'Yesterday';
  }
  return format(ts, 'd MMM');
}

export function formatStartsIn(ts: number): string {
  const days = differenceInDays(ts, new Date());
  return days === 0 ? 'today' : days === 1 ? 'tomorrow' : `in ${days} days`;
}

export function getPeriods(from: number): { label: string; value: Period }[] {
  const periods: { label: string; value: Period }[] = [
    { label: 'Overall', value: 'overall' },
    { label: 'This month', value: 'this-month' },
  ];
  const monthCount = differenceInCalendarMonths(startOfMonth(Date.now()), startOfMonth(from));

  return [
    ...periods,
    ...Array.from({ length: monthCount }, (_, i) => {
      const date = subMonths(startOfMonth(Date.now()), i);
      return { label: format(date, 'MMMM, yyyy'), value: format(date, 'MM-yyyy') as Period };
    }),
  ];
}

export function formatSpentMoney(amount: number): string {
  return `$${(amount / 100).toLocaleString('en-US', { minimumFractionDigits: 2 })}`;
}
