import { isNotNullish, isNullish } from '@sp/util/helpers';
import { $isNativeAppInitialized, callNativeBack, enableNativeBackHandler } from '@sp/util/native';
import { browserHistory, ROUTES } from '@sp/util/router';
import { attach, combine, createEffect, createEvent, createStore, restore, sample, split } from 'effector';
import PhotoSwipe from 'photoswipe';

const navigatedBack = createEvent();
const previousUrlSet = createEvent<string>();
const previousUrlReset = createEvent();
const setModalOpened = createEvent<string>();
const setModalClosed = createEvent<string>();
const modalClosed = createEvent<string>();
const galleryOpened = createEvent<PhotoSwipe>();
const galleryClosed = createEvent();

const $previousUrl = restore(previousUrlSet, ROUTES.HOME_URL).reset(previousUrlReset);
const $openedModalsStack = createStore<string[]>([])
  .on(setModalOpened, (state, id) => (state.includes(id) ? state : [...state, id]))
  .on([setModalClosed, modalClosed], (state, id) => state.filter(item => item !== id));
const $isModalOpened = $openedModalsStack.map(openedModalsTypes => openedModalsTypes.length > 0);
const $lastOpenedModal = $openedModalsStack.map(openedModalsTypes => openedModalsTypes[openedModalsTypes.length - 1]);
const $galleryInstance = restore(galleryOpened, null).reset(galleryClosed);

const enableNativeBackHandlerFx = createEffect(() => {
  enableNativeBackHandler();
  window.nativeCallbackEventBack = () => navigatedBack();
});

const navigateBackFx = attach({
  source: $previousUrl,
  effect: createEffect((url: string) => {
    const isInHome = window.location.pathname === ROUTES.HOME_URL;
    const isInLogin = window.location.pathname === ROUTES.LOGIN_PATH;

    if (isInHome || isInLogin) {
      return callNativeBack();
    }

    browserHistory.push(url);
  }),
});

const closeGalleryFx = attach({
  source: $galleryInstance,
  effect: createEffect((gallery: PhotoSwipe | null) => {
    if (isNullish(gallery)) return;
    gallery.close();
  }),
});

const closeLastModalFx = attach({
  source: $lastOpenedModal,
  effect: createEffect((id: string) => id),
});

split({
  source: navigatedBack,
  match: combine($isModalOpened, $galleryInstance).map(([isModalOpened, galleryInstance]) => {
    return isNotNullish(galleryInstance) && !galleryInstance.isDestroying
      ? 'galleryOpened'
      : isModalOpened
      ? 'modalOpened'
      : 'modalClosed';
  }),
  cases: {
    galleryOpened: closeGalleryFx,
    modalOpened: closeLastModalFx,
    modalClosed: navigateBackFx,
  },
});

sample({
  clock: closeLastModalFx.doneData,
  target: modalClosed,
});

sample({
  clock: $isNativeAppInitialized,
  filter: Boolean,
  target: enableNativeBackHandlerFx,
});

export const NAVIGATE_BACK_MODEL = {
  navigatedBack,
  previousUrlSet,
  previousUrlReset,
  modalClosed,
  setModalOpened,
  setModalClosed,
  galleryOpened,
  galleryClosed,
};
