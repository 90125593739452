import { USER_MODEL } from '@sp/data/auth';
import { getMySkillPoints, patchUserProfile, uploadFileToAWS } from '@sp/data/bif';
import { optimizeImage } from '@sp/util/files';
import { isNotNullish } from '@sp/util/helpers';
import { createDomain, forward, merge, restore } from 'effector';

export type UserFieldsPatch = Partial<{ bio: string; name: string; avatar: File }>;

const ownedProfileDomain = createDomain();

const updateUser = ownedProfileDomain.createEvent<UserFieldsPatch>();
const showEditForm = ownedProfileDomain.createEvent<unknown>();
const hideEditForm = ownedProfileDomain.createEvent<unknown>();

const updateUserFx = ownedProfileDomain.createEffect(async (fields: UserFieldsPatch) => {
  const { avatar, ...patch } = fields;
  if (isNotNullish(avatar)) {
    const { id: avatarId } = await uploadFileToAWS(await optimizeImage(avatar));
    return await patchUserProfile({ ...patch, avatarId });
  }
  return await patchUserProfile(patch);
});

const loadSkillsFx = ownedProfileDomain.createEffect(getMySkillPoints);

forward({ from: updateUser, to: updateUserFx });
forward({ from: updateUserFx.doneData, to: USER_MODEL.patch });
// sample({
//   clock: [
//     USER_MODEL.$isAuthorised.updates.filterMap(isAuthorised => isAuthorised ?? undefined),
//     USER_MODEL.refreshStarted,
//   ],
//   target: loadSkillsFx,
// });

const $skills = restore(loadSkillsFx.doneData, []);

const $isUpdating = updateUserFx.pending;
const $isSkillsLoading = loadSkillsFx.pending;

const $isShowEditProfileForm = ownedProfileDomain
  .createStore(false)
  .on(showEditForm, () => true)
  .on(merge([hideEditForm, updateUserFx.finally]), () => false);

export const OWNED_PROFILE_MODEL = {
  $isShowEditProfileForm,
  showEditForm,
  hideEditForm,
  updateUser,
  $isUpdating,
  $skills,
  $isSkillsLoading,
};
