import { useAuth } from '@sp/data/auth';
import { Button } from '@sp/ui/elements';

export function OauthAppleForm() {
  const { startLoginWithApple, isLoginWithAppleInProgress: isLoading } = useAuth();

  return (
    <Button
      onClick={startLoginWithApple}
      color="apple"
      size="md"
      className="!py-2 h-[2.625rem] w-full"
      replaceWithSpinner={true}
      loading={isLoading}
    >
      <img src="/images/login/a-logo.svg" alt="Apple" />
    </Button>
  );
}
