import { isNotNullish, Nullable } from '@sp/util/helpers';
import { useEffect, useMemo, useState } from 'react';
import { useFilePicker } from 'use-file-picker';
import { IMAGE_CROP_FILE_TYPES } from './config';

export function useSelectCropModel() {
  const [selectedImage, setSelectedImage] = useState<Nullable<File>>(null);
  const isSelected = isNotNullish(selectedImage);
  const selectedImageDataUri = useMemo(
    () => (isSelected ? URL.createObjectURL(selectedImage) : ''),
    [isSelected, selectedImage],
  );
  const [openFilePicker, { plainFiles, clear }] = useFilePicker({
    accept: IMAGE_CROP_FILE_TYPES,
    multiple: false,
    readFilesContent: false,
  });

  useEffect(() => {
    if (plainFiles.length > 0) {
      setSelectedImage(plainFiles[0]);
      clear();
    }
  }, [clear, plainFiles]);

  return {
    selectImage: openFilePicker,
    reset: () => setSelectedImage(null),
    selectedImage,
    selectedImageDataUri,
    isSelected,
  };
}
