import { ChatMessageUploadableAttachment, isUploadable } from '@sp/util/chat-attachments';
import { ChatMessageAttachment } from '@sp/util/stream-chat';
import { FC } from 'react';
import './shared-upload-progress.pcss';

function calcSharedProgress(attachments: ReadonlyArray<ChatMessageUploadableAttachment>): number {
  const totalSize = attachments.reduce((sum, { size }) => sum + size, 0);
  const uploadedSize = attachments.reduce((sum, a) => sum + (a.progress * a.size) / 100, 0);
  return Math.round((uploadedSize / totalSize) * 100);
}

export const SharedUploadProgress: FC<{
  attachments: ReadonlyArray<ChatMessageAttachment | ChatMessageUploadableAttachment>;
}> = ({ attachments }) => {
  const uploadableAttachments = attachments.filter(isUploadable);
  if (uploadableAttachments.length === 0) return null;
  const progress = calcSharedProgress(uploadableAttachments);

  return (
    <div className="relative w-full grid grid-cols-8 py-2 px-5 rounded-2.5xl bg-brand-dirty-light text-xs text-brand font-semibold overflow-hidden">
      <div
        className="absolute h-full shared-upload-progress-bar-bg transition-all ease-in-out duration-500 z-0"
        style={{
          width: `${progress}%`,
        }}
      />
      <div className="col-span-6 col-start-2 text-center z-10">
        Uploading {uploadableAttachments.length} {uploadableAttachments.length === 1 ? 'file' : 'files'}
      </div>
      <div className="text-right z-10">{progress}%</div>
    </div>
  );
};
