import { createKeyValueStore, generateUniqueString, isNullish } from '@sp/util/helpers';
import { useEffect, useRef } from 'react';

// Стек контекстов, в которых находится пользователь, события отправляются в рамках верхего контекста
type Context = Record<string, string>;
const contextModel = createKeyValueStore<Context>('analytics context');
export const $context = contextModel.$list.map(stack => stack[stack.length - 1] ?? {});

export function useSetAnalyticsContext(value: Context | null) {
  const key = useRef(generateUniqueString()).current;

  useEffect(() => {
    if (isNullish(value)) return;
    contextModel.set({ key, value });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(
    () => () => {
      setTimeout(() => contextModel.unset(key));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
}
