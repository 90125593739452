import { RemirrorJSON } from '@remirror/core';
import { Remirror, useRemirror } from '@remirror/react';
import {
  createEmptyDoc,
  extensions,
  handleRemirrorError,
  parseMessageContent,
  serializeMessageContent,
} from '@sp/util/text-editor';
import { FC, useCallback, useMemo } from 'react';
import { OnChangeInput } from './on-change-input';
import { OnChangeOutput } from './on-change-output';
import { OnPaste } from './on-paste';

export const TextEditorProvider: FC<
  Readonly<{
    text?: string;
    onChange: (text: string) => void;
    placeholder?: string;
  }>
> = ({ text: content, onChange, placeholder, children }) => {
  const doc = useMemo(() => (content ? parseMessageContent(content) : createEmptyDoc()), [content]);

  const { manager, state } = useRemirror({
    extensions,
    content: doc,
    selection: 'start',
    onError: handleRemirrorError,
  });

  const handleOnChange = useCallback(
    (updatedContent: RemirrorJSON) => {
      onChange(serializeMessageContent(updatedContent));
    },
    [onChange],
  );

  return (
    <Remirror manager={manager} initialContent={state} placeholder={placeholder}>
      <OnChangeOutput onChange={handleOnChange} input={doc} />
      <OnChangeInput input={doc} />
      <OnPaste />
      {children}
    </Remirror>
  );
};
