import { useUser } from '@sp/data/auth';
import { ANALYTICS, useSetAnalyticsContext } from '@sp/feature/analytics';
import { Chat, MessageExtraContent, ReactionsModal } from '@sp/feature/chat';
import { CHAT_CHANNEL } from '@sp/feature/chat-channel';
import { UseSpacialBadge } from '@sp/feature/space';
import { LoaderBlock } from '@sp/ui/elements';
import { DesktopKeyBindings } from '@sp/ui/text-editor';
import { isNotNullish } from '@sp/util/helpers';
import { ROUTES } from '@sp/util/router';
import { useGate, useStore } from 'effector-react';
import { useEffect, useMemo, useState, VFC } from 'react';
import { useParams } from 'react-router-dom';
import { LIVE_MODEL } from './live-model';

export const EmbeddedLiveChat: VFC = () => {
  const params = useParams<typeof ROUTES.SPACE_ID_PARAM>();
  const spaceId = Number(params.spaceId);

  if (isNaN(spaceId)) {
    console.error(`Invalid space id: ${params.spaceId}`);
    return null;
  }

  return <EmbeddedLiveChatPage liveId={spaceId} />;
};

const EmbeddedLiveChatPage: VFC<{ liveId: number }> = ({ liveId }) => {
  const chatModel = useStore(CHAT_CHANNEL.$instance);
  const live = useStore(LIVE_MODEL.$live);
  const user = useUser();
  useGate(LIVE_MODEL.LiveGate, liveId);
  const [isOpenTracked, setIsOpenTracked] = useState(false);

  useSetAnalyticsContext(
    isNotNullish(live)
      ? {
          'Space ID': live?.id?.toString() ?? '',
          'Space Name': live?.title?.toString() ?? '',
          'Creator ID': live?.creator?.id.toString() ?? '',
          'Creator Name': live?.creator?.name.toString() ?? '',
          From: 'LiveChatEmbed',
          Type: 'Live',
        }
      : null,
  );

  useEffect(() => {
    ANALYTICS.spaceOpenTrackingStart();
    return () => {
      ANALYTICS.spaceCloseTracked();
    };
  }, []);

  useEffect(() => {
    if (isNotNullish(live) && isNotNullish(chatModel) && !isOpenTracked) {
      ANALYTICS.spaceOpenTracked();
      setIsOpenTracked(true);
    }
  }, [live, chatModel, isOpenTracked]);

  const fullAcceptedFileAttachments = user.isAdmin || user.id === live?.creator?.id;
  const [specialBadgeContent] = UseSpacialBadge(live?.creator || null);
  const messageExtraContent: MessageExtraContent = useMemo(
    () => ({ afterUserName: specialBadgeContent }),
    [specialBadgeContent],
  );

  return live && chatModel ? (
    <div className="h-full flex flex-col">
      <Chat
        messageExtraContent={messageExtraContent}
        fullAcceptedFileAttachments={fullAcceptedFileAttachments}
        chatModel={chatModel}
        keyMapExtension={DesktopKeyBindings}
      />
      <ReactionsModal />
    </div>
  ) : (
    <div className="h-full flex items-center justify-center">
      <LoaderBlock />
    </div>
  );
};
