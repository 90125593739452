import { isAWSSrc } from '@sp/util/files';
import { cls } from '@sp/util/helpers';
import { ForwardedRef, forwardRef, ImgHTMLAttributes, ReactElement, Ref } from 'react';
import { AWSResponsiveImage } from './aws-responsive-image';

export function ResponsiveImageComponent(
  props: ImgHTMLAttributes<HTMLImageElement> & {
    src: string;
    maxWidth?: number;
    srcRef?: Ref<string | null>;
  },
  ref: ForwardedRef<HTMLImageElement>,
): ReactElement {
  const { src, alt, className, maxWidth, srcRef, ...rest } = props;
  if (isAWSSrc(src)) {
    return <AWSResponsiveImage ref={ref} srcRef={srcRef} {...props} maxWidth={maxWidth} />;
  }

  return <img ref={ref} src={src} alt={alt} className={cls(className, 'image-rendering-crisp-edges')} {...rest} />; //fallback to regular image
}

export const ResponsiveImage = forwardRef(ResponsiveImageComponent);
