import { LinksApiFactory } from '../../api/default';
import { MeUserLink } from '../../user.types';
import { apiResponseAdHoc } from '../helpers';

const linksApi = LinksApiFactory();

export function getLinks(): Promise<MeUserLink[]> {
  return linksApi.linksGet().then(r => apiResponseAdHoc(r.data));
}

export function deleteLink(linkId: number) {
  return linksApi.linksLinkIdDelete(linkId);
}

export function updateLink(linkId: number, data: MeUserLink): Promise<MeUserLink> {
  return linksApi.linksLinkIdPost(linkId, data).then(r => apiResponseAdHoc(r.data));
}

export function createLink(data: { href: string; title: string }): Promise<MeUserLink> {
  return linksApi.linksPost(data).then(r => apiResponseAdHoc(r.data));
}

export function reorderLinks(data: MeUserLink[]) {
  return linksApi.linksOrderPatch({ links: data });
}
