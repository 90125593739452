import { useUser } from '@sp/data/auth';
import { PURCHASE_MODEL } from '@sp/data/global';
import { GoToAccountButton } from '@sp/feature/native-elements';
import { Button, LoaderBlock } from '@sp/ui/elements';
import { Modal } from '@sp/ui/modal';
import { PriceConfig, useFormatPrice } from '@sp/util/format';
import { isNotNullish, isNullish } from '@sp/util/helpers';
import { useStore } from 'effector-react';
import { FC, useCallback, useMemo } from 'react';
import { CONFIRM_CARD_MODEL } from './confirm-card-model';
import { SavedCard } from './saved-card';

const ConfirmCardModalBody: FC = () => {
  const user = useUser();
  const { paymentMethod } = user;
  const challenge = useStore(CONFIRM_CARD_MODEL.$challenge);
  const isLoading = useStore(CONFIRM_CARD_MODEL.$isLoading);
  const isUseSavedCard = useStore(CONFIRM_CARD_MODEL.$isUseSavedCard);
  const formatPrice = useFormatPrice();

  // This modal is only accessible on Android thus we can ignore ios price.
  const priceConfig = useMemo(() => ({ web: challenge?.price ?? 0, ios: 0 } as PriceConfig), [challenge?.price]);

  const onConfirm = useCallback(() => {
    if (isNotNullish(challenge)) {
      PURCHASE_MODEL.purchaseConfirmed(challenge.id);
      CONFIRM_CARD_MODEL.close();
    }
  }, [challenge]);

  if (isNullish(paymentMethod)) {
    CONFIRM_CARD_MODEL.close();
    return null;
  }

  if (isLoading) {
    return (
      <div className="flex h-56 items-center justify-center">
        <LoaderBlock />
      </div>
    );
  }

  if (isNullish(challenge)) {
    return <div className="flex h-56 items-center justify-center">Challenge not found</div>;
  }

  return (
    <div>
      <h1 className="text-center text-2xl mt-0 mb-2">Proceed to checkout</h1>

      <SavedCard
        lastDigits={paymentMethod.card.last4}
        expMonth={paymentMethod.card.exp_month}
        expYear={paymentMethod.card.exp_year}
        brand={paymentMethod.card.brand}
      />

      {isUseSavedCard ? null : (
        <div className="text-center mb-6">
          <GoToAccountButton type="change_card" onGoToAccount={CONFIRM_CARD_MODEL.close} />
        </div>
      )}

      <Button block disabled={!isUseSavedCard} color="black" onClick={onConfirm} className="!font-bold">
        Pay {formatPrice(priceConfig)}
      </Button>
    </div>
  );
};

export const ConfirmCardModal: FC = () => {
  const isOpen = useStore(CONFIRM_CARD_MODEL.$isOpened);
  const challenge = useStore(CONFIRM_CARD_MODEL.$challenge);

  const onClose = useCallback(() => {
    if (isNotNullish(challenge)) PURCHASE_MODEL.purchaseNotConfirmed(challenge.id);
    CONFIRM_CARD_MODEL.close();
  }, [challenge]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} withBackdrop closeOnBackdropClick>
      <ConfirmCardModalBody />
    </Modal>
  );
};
