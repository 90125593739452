import { RemirrorJSON } from '@remirror/core';
import { sanitizeHtml } from '@sp/util/html';
import LRUCache from 'lru-cache';
import { editor, manager } from './remirror-instance';

export function _renderHtml(doc: RemirrorJSON): string {
  return editor.helpers.getHTML(manager.createState({ content: doc }));
}

const cache = new LRUCache<RemirrorJSON, string>({ max: 1000 });

export function renderDocToHtml(doc: RemirrorJSON): string {
  const cached = cache.get(doc);
  if (cached != null) {
    return cached;
  }

  const html = sanitizeHtml(_renderHtml(doc));
  cache.set(doc, html);
  return html;
}
