import { ResponsiveImage } from '@sp/ui/elements';
import { HtmlDocRenderer } from '@sp/ui/text-editor';
import { noop } from '@sp/util/helpers';
import { MouseEventHandler, ReactElement } from 'react';

export interface QuotedMessagePreview {
  readonly url: string;
  readonly name: string;
}

export const enum QuotedMessageContentType {
  Attachment = 'attachment',
  MessageText = 'messageText',
}

export const ChatQuotedMessageBodyView = ({
  content,
  user,
  preview,
  onClick = noop,
}: Readonly<{
  content: { type: QuotedMessageContentType; value: string } | null;
  user: string;
  preview?: QuotedMessagePreview;
  onClick?: () => void;
}>): ReactElement => {
  let contentElement: ReactElement | null = null;

  if (content != null) {
    switch (content.type) {
      case QuotedMessageContentType.Attachment:
        contentElement = <div className="text-xs leading-3 text-secondary">{content.value}</div>;
        break;
      case QuotedMessageContentType.MessageText:
        contentElement = <HtmlDocRenderer quoted text={content.value} />;
        break;
    }
  }

  return (
    <div
      role="button"
      onClick={onClick}
      className="pl-2 pt-1 pb-1 border-l-2 border-[#79A894] flex flex-row items-center"
    >
      {preview ? (
        <div className="w-8 aspect-square aspect-ratio-wrap mr-2">
          <ResponsiveImage
            src={preview.url}
            className="object-cover overflow-hidden rounded"
            alt={preview.name}
            maxWidth={32}
          />
        </div>
      ) : null}
      <div className="overflow-hidden">
        <div className="text-xs text-secondary font-semibold">{user}</div>
        <div className="max-h-4">{contentElement}</div>
      </div>
    </div>
  );
};

export function ChatMessageBodyView({
  text,
  onClick,
}: Readonly<{ text: string; onClick: MouseEventHandler<HTMLElement> }>): ReactElement {
  return (
    // TODO[Dmitriy Teplov] find a better way to publish message click events.
    <div onClick={onClick} className="overflow-hidden break-words">
      {text.length > 0 ? <HtmlDocRenderer text={text} /> : ''}
    </div>
  );
}
