export const KNOWN_SKILL_COLORS = ['cyan', 'salmon', 'yellow', 'purple'] as const;
export type KnownSkillColor = typeof KNOWN_SKILL_COLORS[number];

export function getSkillClassNameAndColor(skillColor: KnownSkillColor): { className: string; color: string } {
  switch (skillColor) {
    case 'cyan':
      return { className: `bg-brand-cyan`, color: '#66CFB0E5' };
    case 'salmon':
      return { className: `bg-brand-salmon`, color: '#FD906DE5' };
    case 'yellow':
      return { className: `bg-brand-yellow`, color: '#FAB81EE5' };
    case 'purple':
      return { className: `bg-brand-purple`, color: '#A088FFE5' };
    default:
      return { className: 'bg-secondary', color: '#e8e8e8' };
  }
}
