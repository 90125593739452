export enum ATTACHMENT_TYPE {
  image = 'image',
  video = 'video',
  audio = 'audio',
  file = 'file',
  link = 'link',
}

interface AttachmentBase {
  name: string;
  url: string;
}

export interface ImageAttachment extends AttachmentBase {
  type: ATTACHMENT_TYPE.image;
  fileType: string; // MIME type
  previewUrl: string;
  width: number;
  height: number;
}

export interface VideoAttachment extends AttachmentBase {
  type: ATTACHMENT_TYPE.video;
  fileType: string; // MIME type
  originalUrl?: string;
  previewUrl: string;
  width: number;
  height: number;
  duration: number;
}

export interface AudioAttachment extends AttachmentBase {
  type: ATTACHMENT_TYPE.audio;
  fileType: string; // MIME type
  duration: number;
  histogram?: ReadonlyArray<number>;
}

export interface FileAttachment extends AttachmentBase {
  type: ATTACHMENT_TYPE.file;
  fileType: string; // MIME type
}

export interface LinkAttachment extends AttachmentBase {
  type: ATTACHMENT_TYPE.link;
  previewUrl: string | null;
}

export type ChatMessageAttachment =
  | ImageAttachment
  | VideoAttachment
  | AudioAttachment
  | FileAttachment
  | LinkAttachment;
