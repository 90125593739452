import { useUser } from '@sp/data/auth';
import { BuyButtonResultFn, RenewWebSubscriptionButton, SubscribeButton } from '@sp/feature/buy-button';
import { Avatar, Button } from '@sp/ui/elements';
import { IconArrowCounterClockwise, IconCoins, IconTimer } from '@sp/ui/icons';
import { Modal } from '@sp/ui/modal';
import { SpaceTag } from '@sp/ui/spaces-shared';
import { formatPriceRaw } from '@sp/util/format';
import { isNotNullish, isNullish } from '@sp/util/helpers';
import { $isNativeIos } from '@sp/util/native';
import { format } from 'date-fns';
import { useStore } from 'effector-react';
import { FC, useCallback, useState } from 'react';
import { CANCEL_SUBSCRIPTION_MODEL } from './cancel-subscription-model';
import { COMMUNITY_MODEL } from './community-model';

export const CommunitySubscriptionDetailsModal: FC<{ isOpen: boolean; onClose: VoidFunction }> = ({
  isOpen,
  onClose,
}) => {
  const [isCancelSubscriptionConformationModalOpen, setIsCancelSubscriptionConformationModalOpen] = useState(false);
  const community = useStore(COMMUNITY_MODEL.$community);
  const isIos = useStore($isNativeIos);
  const user = useUser();

  const onSubscribeResult: BuyButtonResultFn = useCallback(() => COMMUNITY_MODEL.refetchData(), []);

  const isCancelPending = useStore(CANCEL_SUBSCRIPTION_MODEL.isPending);

  const handleClickCancel = useCallback(() => {
    if (!community) return;
    CANCEL_SUBSCRIPTION_MODEL.cancel(community.id);
  }, [community]);

  if (
    isNullish(community) ||
    isNullish(community.subscription) ||
    isNullish(community.priceConfig) ||
    isNullish(community.creator)
  ) {
    return null;
  }

  const isCanceled = isNotNullish(community.subscription.cancelledAt);
  const price = formatPriceRaw(community.subscription.price) + (community.subscription.inApp ? ' + VAT' : '');

  const isInAppSubscription = community.subscription.inApp;
  const isWebSubscriptionRenewableOnIos = isNotNullish(user.paymentMethod?.id);

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnBackdropClick withBackdrop>
      <>
        <div className="flex justify-start items-start gap-2 pb-2 w-full">
          <span>
            <Avatar size="sm" src={community.cover?.url} />
          </span>
          <div className="flex flex-col flex-1 overflow-x-hidden">
            <h1 className="m-0 text-ellipsis whitespace-nowrap overflow-x-hidden text-base">{community.title}</h1>
            <span className="text-secondary text-xs font-semibold">{community.creator?.name}</span>
          </div>
        </div>

        {community.tags && community.tags.length > 0 ? (
          <div className="mb-2">
            <span className="font-semibold text-xs">Monthly skills achieved:</span>
            <div className="flex flex-row gap-1 flex-wrap">
              {community.tags.map(({ name, id, weight, color }) => (
                <SpaceTag key={id} color={color} text={`+${weight} ${name}`} />
              ))}
            </div>
          </div>
        ) : null}

        <hr className="mt-2 mb-4" />

        {isCanceled ? (
          <>
            <div className="flex flex-col gap-2 mb-6">
              <span className="font-semibold">You’ve canceled the subscription</span>
              <div className="flex items-center gap-1">
                <IconCoins size={18} />
                <span className="font-semibold">{price}</span>
                Monthly
              </div>
              <div className="flex items-center gap-1">
                <IconTimer size={18} />
                Expires
                <span className="font-semibold">{format(community.subscription.nextPaymentDate ?? 0, 'd MMMM')}</span>
              </div>
            </div>

            {isInAppSubscription && isIos && (
              <SubscribeButton
                id={community.id}
                title={community.title}
                price={community.priceConfig}
                iosProductId={community.iosProductId}
                creatorId={community.creator.id}
                creatorName={community.creator.name}
                bought={false}
                openUrl="."
                onResult={onSubscribeResult}
              >
                Renew Subscription
              </SubscribeButton>
            )}

            {!isInAppSubscription && !isIos && (
              <SubscribeButton
                id={community.id}
                title={community.title}
                price={community.priceConfig}
                iosProductId={community.iosProductId}
                creatorId={community.creator.id}
                creatorName={community.creator.name}
                bought={false}
                openUrl="."
                onResult={onSubscribeResult}
              >
                Renew Subscription
              </SubscribeButton>
            )}

            {isInAppSubscription && !isIos && null}

            {!isInAppSubscription &&
              isIos &&
              (isWebSubscriptionRenewableOnIos ? (
                <RenewWebSubscriptionButton spaceId={community.id} onResult={onSubscribeResult}>
                  Renew Subscription
                </RenewWebSubscriptionButton>
              ) : (
                <SubscribeButton
                  id={community.id}
                  title={community.title}
                  price={community.priceConfig}
                  iosProductId={community.iosProductId}
                  creatorId={community.creator.id}
                  creatorName={community.creator.name}
                  bought={false}
                  openUrl="."
                  onResult={onSubscribeResult}
                >
                  Renew Subscription
                </SubscribeButton>
              ))}
          </>
        ) : (
          <>
            <div className="flex flex-col gap-2 mb-10">
              <div className="flex items-center gap-1">
                <IconCoins size={18} />
                <span className="font-semibold">{price}</span>
                Monthly
              </div>
              <div className="flex items-center gap-1">
                <IconArrowCounterClockwise size={18} />
                Renews
                <span className="font-semibold">{format(community.subscription.nextPaymentDate ?? 0, 'd MMMM')}</span>
              </div>
            </div>

            {isInAppSubscription ? (
              isIos ? (
                'You can unsubscribe in your Apple account.'
              ) : null
            ) : (
              <button
                type="button"
                className="text-secondary font-medium"
                onClick={() => setIsCancelSubscriptionConformationModalOpen(true)}
              >
                Cancel Premium Subscription
              </button>
            )}

            <Modal
              isOpen={isCancelSubscriptionConformationModalOpen}
              onClose={() => setIsCancelSubscriptionConformationModalOpen(false)}
              closeOnBackdropClick
              withBackdrop
            >
              <>
                <h3 className="mt-0 mb-1 text-xl leading-6">Are you sure you want to cancel?</h3>
                <p className="mt-0 mb-6">
                  Premium subscription is a great way to support your favorite creators and get access to exclusive
                  content
                </p>
                <Button
                  color="black"
                  block
                  onClick={() => setIsCancelSubscriptionConformationModalOpen(false)}
                  className="mb-2"
                >
                  Keep my benefits
                </Button>
                <Button color="transparent" loading={isCancelPending} disableOnLoad block onClick={handleClickCancel}>
                  Cancel subscription
                </Button>
              </>
            </Modal>
          </>
        )}
      </>
    </Modal>
  );
};
