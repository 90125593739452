import { createDomain, createEffect, restore, sample } from 'effector';

type InfoModalType = 'creator' | 'challenge' | 'avatar';

const infoModalDomain = createDomain();

const openInfoModal = infoModalDomain.createEvent();

const closeInfoModal = infoModalDomain.createEvent();

const infoModalTypeSet = infoModalDomain.createEvent<InfoModalType>();

const $infoModalType = restore(infoModalTypeSet, 'creator');

const $isInfoModalOpen = infoModalDomain
  .createStore<boolean>(false)
  // .on(openInfoModal, () => true)
  .reset(closeInfoModal);

sample({
  clock: openInfoModal,
  target: createEffect(() => console.warn(`InfoModal is disabled.`)),
});

export const INFO_MODAL_MODEL = { $isInfoModalOpen, openInfoModal, closeInfoModal, $infoModalType, infoModalTypeSet };
