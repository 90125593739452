/* tslint:disable */
/* eslint-disable */
/**
 * Spaces
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 *
 * @export
 * @interface ChallengeStepsStepIdMovePost200Response
 */
export interface ChallengeStepsStepIdMovePost200Response {
  /**
   *
   * @type {number}
   * @memberof ChallengeStepsStepIdMovePost200Response
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof ChallengeStepsStepIdMovePost200Response
   */
  createdAt?: number;
  /**
   *
   * @type {number}
   * @memberof ChallengeStepsStepIdMovePost200Response
   */
  updatedAt?: number | null;
  /**
   *
   * @type {number}
   * @memberof ChallengeStepsStepIdMovePost200Response
   */
  challengeSpace?: number;
  /**
   *
   * @type {number}
   * @memberof ChallengeStepsStepIdMovePost200Response
   */
  previousStep?: number | null;
  /**
   *
   * @type {number}
   * @memberof ChallengeStepsStepIdMovePost200Response
   */
  content?: number;
}
/**
 *
 * @export
 * @interface ChallengeStepsStepIdMovePostRequest
 */
export interface ChallengeStepsStepIdMovePostRequest {
  /**
   *
   * @type {number}
   * @memberof ChallengeStepsStepIdMovePostRequest
   */
  afterStepId?: number;
}
/**
 *
 * @export
 * @interface SpacesGet200Response
 */
export interface SpacesGet200Response {
  /**
   *
   * @type {number}
   * @memberof SpacesGet200Response
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof SpacesGet200Response
   */
  created_at?: number;
  /**
   *
   * @type {number}
   * @memberof SpacesGet200Response
   */
  updated_at?: number | null;
  /**
   *
   * @type {string}
   * @memberof SpacesGet200Response
   */
  title?: string;
  /**
   *
   * @type {number}
   * @memberof SpacesGet200Response
   */
  price?: number;
  /**
   *
   * @type {string}
   * @memberof SpacesGet200Response
   */
  type?: SpacesGet200ResponseTypeEnum;
  /**
   *
   * @type {string}
   * @memberof SpacesGet200Response
   */
  status?: SpacesGet200ResponseStatusEnum;
  /**
   *
   * @type {string}
   * @memberof SpacesGet200Response
   */
  payment_type?: SpacesGet200ResponsePaymentTypeEnum;
  /**
   *
   * @type {SpacesSpaceIdClonePost200ResponseConfig}
   * @memberof SpacesGet200Response
   */
  config?: SpacesSpaceIdClonePost200ResponseConfig | null;
  /**
   *
   * @type {string}
   * @memberof SpacesGet200Response
   */
  slug?: string;
  /**
   *
   * @type {SpacesSpaceIdClonePost200ResponseConfigLiveRecordVideo}
   * @memberof SpacesGet200Response
   */
  cover?: SpacesSpaceIdClonePost200ResponseConfigLiveRecordVideo | null;
}

export const SpacesGet200ResponseTypeEnum = {
  Post: 'post',
  Live: 'live',
  Challenge: 'challenge',
  Trailer: 'trailer',
  Channel: 'channel',
  Community: 'community',
} as const;

export type SpacesGet200ResponseTypeEnum =
  typeof SpacesGet200ResponseTypeEnum[keyof typeof SpacesGet200ResponseTypeEnum];
export const SpacesGet200ResponseStatusEnum = {
  Draft: 'draft',
  Published: 'published',
} as const;

export type SpacesGet200ResponseStatusEnum =
  typeof SpacesGet200ResponseStatusEnum[keyof typeof SpacesGet200ResponseStatusEnum];
export const SpacesGet200ResponsePaymentTypeEnum = {
  FixedPrice: 'fixed_price',
  FixedPriceSubscribersOnly: 'fixed_price_subscribers_only',
  SubscribersOnly: 'subscribers_only',
  Free: 'free',
  MonthlyPrice: 'monthly_price',
} as const;

export type SpacesGet200ResponsePaymentTypeEnum =
  typeof SpacesGet200ResponsePaymentTypeEnum[keyof typeof SpacesGet200ResponsePaymentTypeEnum];

/**
 *
 * @export
 * @interface SpacesGet200ResponseInner
 */
export interface SpacesGet200ResponseInner {
  /**
   *
   * @type {number}
   * @memberof SpacesGet200ResponseInner
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof SpacesGet200ResponseInner
   */
  created_at?: number;
  /**
   *
   * @type {number}
   * @memberof SpacesGet200ResponseInner
   */
  updated_at?: number | null;
  /**
   *
   * @type {string}
   * @memberof SpacesGet200ResponseInner
   */
  title?: string;
  /**
   *
   * @type {number}
   * @memberof SpacesGet200ResponseInner
   */
  price?: number;
  /**
   *
   * @type {object}
   * @memberof SpacesGet200ResponseInner
   */
  content?: object | null;
  /**
   *
   * @type {object}
   * @memberof SpacesGet200ResponseInner
   */
  teaser?: object;
  /**
   *
   * @type {string}
   * @memberof SpacesGet200ResponseInner
   */
  type?: SpacesGet200ResponseInnerTypeEnum;
  /**
   *
   * @type {string}
   * @memberof SpacesGet200ResponseInner
   */
  status?: SpacesGet200ResponseInnerStatusEnum;
  /**
   *
   * @type {string}
   * @memberof SpacesGet200ResponseInner
   */
  payment_type?: SpacesGet200ResponseInnerPaymentTypeEnum;
  /**
   *
   * @type {SpacesGet200ResponseInnerConfig}
   * @memberof SpacesGet200ResponseInner
   */
  config?: SpacesGet200ResponseInnerConfig | null;
  /**
   *
   * @type {string}
   * @memberof SpacesGet200ResponseInner
   */
  slug?: string;
  /**
   *
   * @type {SpacesSpaceIdClonePost200ResponseConfigLiveRecordVideo}
   * @memberof SpacesGet200ResponseInner
   */
  cover?: SpacesSpaceIdClonePost200ResponseConfigLiveRecordVideo | null;
  /**
   *
   * @type {SpacesSpaceIdDelete200ResponseOwner}
   * @memberof SpacesGet200ResponseInner
   */
  owner?: SpacesSpaceIdDelete200ResponseOwner | null;
}

export const SpacesGet200ResponseInnerTypeEnum = {
  Post: 'post',
  Live: 'live',
  Challenge: 'challenge',
  Trailer: 'trailer',
  Channel: 'channel',
  Community: 'community',
} as const;

export type SpacesGet200ResponseInnerTypeEnum =
  typeof SpacesGet200ResponseInnerTypeEnum[keyof typeof SpacesGet200ResponseInnerTypeEnum];
export const SpacesGet200ResponseInnerStatusEnum = {
  Draft: 'draft',
  Published: 'published',
} as const;

export type SpacesGet200ResponseInnerStatusEnum =
  typeof SpacesGet200ResponseInnerStatusEnum[keyof typeof SpacesGet200ResponseInnerStatusEnum];
export const SpacesGet200ResponseInnerPaymentTypeEnum = {
  FixedPrice: 'fixed_price',
  FixedPriceSubscribersOnly: 'fixed_price_subscribers_only',
  SubscribersOnly: 'subscribers_only',
  Free: 'free',
  MonthlyPrice: 'monthly_price',
} as const;

export type SpacesGet200ResponseInnerPaymentTypeEnum =
  typeof SpacesGet200ResponseInnerPaymentTypeEnum[keyof typeof SpacesGet200ResponseInnerPaymentTypeEnum];

/**
 *
 * @export
 * @interface SpacesGet200ResponseInnerConfig
 */
export interface SpacesGet200ResponseInnerConfig {
  /**
   *
   * @type {SpacesSpaceIdClonePost200ResponseConfigChallenge}
   * @memberof SpacesGet200ResponseInnerConfig
   */
  challenge?: SpacesSpaceIdClonePost200ResponseConfigChallenge | null;
  /**
   *
   * @type {SpacesGet200ResponseInnerConfigLive}
   * @memberof SpacesGet200ResponseInnerConfig
   */
  live?: SpacesGet200ResponseInnerConfigLive | null;
}
/**
 *
 * @export
 * @interface SpacesGet200ResponseInnerConfigLive
 */
export interface SpacesGet200ResponseInnerConfigLive {
  /**
   *
   * @type {number}
   * @memberof SpacesGet200ResponseInnerConfigLive
   */
  startAt?: number;
  /**
   *
   * @type {number}
   * @memberof SpacesGet200ResponseInnerConfigLive
   */
  duration?: number;
}
/**
 *
 * @export
 * @interface SpacesGetRequest
 */
export interface SpacesGetRequest {
  /**
   *
   * @type {string}
   * @memberof SpacesGetRequest
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof SpacesGetRequest
   */
  type?: SpacesGetRequestTypeEnum;
  /**
   *
   * @type {number}
   * @memberof SpacesGetRequest
   */
  coverFileId?: number;
  /**
   *
   * @type {string}
   * @memberof SpacesGetRequest
   */
  description?: string | null;
  /**
   *
   * @type {number}
   * @memberof SpacesGetRequest
   */
  metaImageFileId?: number | null;
  /**
   *
   * @type {string}
   * @memberof SpacesGetRequest
   */
  metaDescription?: string | null;
  /**
   *
   * @type {string}
   * @memberof SpacesGetRequest
   */
  payment_type?: SpacesGetRequestPaymentTypeEnum;
  /**
   *
   * @type {string}
   * @memberof SpacesGetRequest
   */
  slug?: string | null;
  /**
   *
   * @type {number}
   * @memberof SpacesGetRequest
   */
  price?: number;
  /**
   *
   * @type {SpacesGetRequestConfig}
   * @memberof SpacesGetRequest
   */
  config?: SpacesGetRequestConfig | null;
}

export const SpacesGetRequestTypeEnum = {
  Post: 'post',
  Live: 'live',
  Challenge: 'challenge',
  Trailer: 'trailer',
  Channel: 'channel',
  Community: 'community',
} as const;

export type SpacesGetRequestTypeEnum = typeof SpacesGetRequestTypeEnum[keyof typeof SpacesGetRequestTypeEnum];
export const SpacesGetRequestPaymentTypeEnum = {
  FixedPrice: 'fixed_price',
  FixedPriceSubscribersOnly: 'fixed_price_subscribers_only',
  SubscribersOnly: 'subscribers_only',
  Free: 'free',
  MonthlyPrice: 'monthly_price',
} as const;

export type SpacesGetRequestPaymentTypeEnum =
  typeof SpacesGetRequestPaymentTypeEnum[keyof typeof SpacesGetRequestPaymentTypeEnum];

/**
 *
 * @export
 * @interface SpacesGetRequestConfig
 */
export interface SpacesGetRequestConfig {
  /**
   *
   * @type {SpacesSpaceIdDelete200ResponseConfigChallenge}
   * @memberof SpacesGetRequestConfig
   */
  challenge?: SpacesSpaceIdDelete200ResponseConfigChallenge | null;
  /**
   *
   * @type {SpacesGetRequestConfigLive}
   * @memberof SpacesGetRequestConfig
   */
  live?: SpacesGetRequestConfigLive | null;
  /**
   *
   * @type {SpacesSpaceIdClonePost200ResponseConfigChannel}
   * @memberof SpacesGetRequestConfig
   */
  channel?: SpacesSpaceIdClonePost200ResponseConfigChannel | null;
  /**
   *
   * @type {SpacesGetRequestConfigCommunity}
   * @memberof SpacesGetRequestConfig
   */
  community?: SpacesGetRequestConfigCommunity | null;
}
/**
 *
 * @export
 * @interface SpacesGetRequestConfigCommunity
 */
export interface SpacesGetRequestConfigCommunity {
  /**
   *
   * @type {string}
   * @memberof SpacesGetRequestConfigCommunity
   */
  updateFrequency?: SpacesGetRequestConfigCommunityUpdateFrequencyEnum;
  /**
   *
   * @type {string}
   * @memberof SpacesGetRequestConfigCommunity
   */
  creatorDescription?: string | null;
  /**
   *
   * @type {string}
   * @memberof SpacesGetRequestConfigCommunity
   */
  targetAudience?: string | null;
  /**
   *
   * @type {string}
   * @memberof SpacesGetRequestConfigCommunity
   */
  inside?: string | null;
  /**
   *
   * @type {string}
   * @memberof SpacesGetRequestConfigCommunity
   */
  offerSummary?: string | null;
  /**
   *
   * @type {Array<string>}
   * @memberof SpacesGetRequestConfigCommunity
   */
  creatorAchievements?: Array<string>;
  /**
   *
   * @type {SpacesSpaceIdDeleteRequestConfigCommunityVideo}
   * @memberof SpacesGetRequestConfigCommunity
   */
  video?: SpacesSpaceIdDeleteRequestConfigCommunityVideo | null;
}

export const SpacesGetRequestConfigCommunityUpdateFrequencyEnum = {
  Daily: 'daily',
  TwoThreePerWeek: 'twoThreePerWeek',
  Weekly: 'weekly',
  TwoThreePerMonth: 'twoThreePerMonth',
  Occasionally: 'occasionally',
} as const;

export type SpacesGetRequestConfigCommunityUpdateFrequencyEnum =
  typeof SpacesGetRequestConfigCommunityUpdateFrequencyEnum[keyof typeof SpacesGetRequestConfigCommunityUpdateFrequencyEnum];

/**
 *
 * @export
 * @interface SpacesGetRequestConfigLive
 */
export interface SpacesGetRequestConfigLive {
  /**
   *
   * @type {number}
   * @memberof SpacesGetRequestConfigLive
   */
  startAt?: number;
  /**
   *
   * @type {number}
   * @memberof SpacesGetRequestConfigLive
   */
  duration?: number;
  /**
   *
   * @type {string}
   * @memberof SpacesGetRequestConfigLive
   */
  linkToZoom?: string | null;
  /**
   *
   * @type {string}
   * @memberof SpacesGetRequestConfigLive
   */
  creatorDescription?: string;
  /**
   *
   * @type {string}
   * @memberof SpacesGetRequestConfigLive
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof SpacesGetRequestConfigLive
   */
  requirements?: string | null;
  /**
   *
   * @type {string}
   * @memberof SpacesGetRequestConfigLive
   */
  offerDescription?: string | null;
  /**
   *
   * @type {string}
   * @memberof SpacesGetRequestConfigLive
   */
  offerSummary?: string | null;
  /**
   *
   * @type {SpacesSpaceIdDeleteRequestConfigLiveRecord}
   * @memberof SpacesGetRequestConfigLive
   */
  record?: SpacesSpaceIdDeleteRequestConfigLiveRecord | null;
  /**
   *
   * @type {Array<SpacesSpaceIdDeleteRequestConfigLiveTestimonialsInner>}
   * @memberof SpacesGetRequestConfigLive
   */
  testimonials?: Array<SpacesSpaceIdDeleteRequestConfigLiveTestimonialsInner>;
}
/**
 *
 * @export
 * @interface SpacesSpaceIdChallengePostsGetRequest
 */
export interface SpacesSpaceIdChallengePostsGetRequest {
  /**
   *
   * @type {Array<number>}
   * @memberof SpacesSpaceIdChallengePostsGetRequest
   */
  deleted?: Array<number>;
  /**
   *
   * @type {Array<SpacesSpaceIdChallengePostsPostIdDeleteRequest>}
   * @memberof SpacesSpaceIdChallengePostsGetRequest
   */
  created?: Array<SpacesSpaceIdChallengePostsPostIdDeleteRequest>;
  /**
   *
   * @type {Array<SpacesSpaceIdChallengePostsGetRequestUpdatedInner>}
   * @memberof SpacesSpaceIdChallengePostsGetRequest
   */
  updated?: Array<SpacesSpaceIdChallengePostsGetRequestUpdatedInner>;
}
/**
 *
 * @export
 * @interface SpacesSpaceIdChallengePostsGetRequestUpdatedInner
 */
export interface SpacesSpaceIdChallengePostsGetRequestUpdatedInner {
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdChallengePostsGetRequestUpdatedInner
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdChallengePostsGetRequestUpdatedInner
   */
  type?: SpacesSpaceIdChallengePostsGetRequestUpdatedInnerTypeEnum;
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdChallengePostsGetRequestUpdatedInner
   */
  stepId?: number | null;
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdChallengePostsGetRequestUpdatedInner
   */
  order?: number | null;
  /**
   *
   * @type {object}
   * @memberof SpacesSpaceIdChallengePostsGetRequestUpdatedInner
   */
  content?: object | null;
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdChallengePostsGetRequestUpdatedInner
   */
  publishAt?: number | null;
  /**
   *
   * @type {boolean}
   * @memberof SpacesSpaceIdChallengePostsGetRequestUpdatedInner
   */
  isDraft?: boolean | null;
}

export const SpacesSpaceIdChallengePostsGetRequestUpdatedInnerTypeEnum = {
  Intro: 'intro',
  Step: 'step',
  Finish: 'finish',
  Deferred: 'deferred',
} as const;

export type SpacesSpaceIdChallengePostsGetRequestUpdatedInnerTypeEnum =
  typeof SpacesSpaceIdChallengePostsGetRequestUpdatedInnerTypeEnum[keyof typeof SpacesSpaceIdChallengePostsGetRequestUpdatedInnerTypeEnum];

/**
 *
 * @export
 * @interface SpacesSpaceIdChallengePostsPostIdDelete200Response
 */
export interface SpacesSpaceIdChallengePostsPostIdDelete200Response {
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdChallengePostsPostIdDelete200Response
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdChallengePostsPostIdDelete200Response
   */
  createdAt?: number;
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdChallengePostsPostIdDelete200Response
   */
  updatedAt?: number | null;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdChallengePostsPostIdDelete200Response
   */
  type?: SpacesSpaceIdChallengePostsPostIdDelete200ResponseTypeEnum;
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdChallengePostsPostIdDelete200Response
   */
  spaceId?: number;
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdChallengePostsPostIdDelete200Response
   */
  stepId?: number | null;
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdChallengePostsPostIdDelete200Response
   */
  order?: number;
  /**
   *
   * @type {object}
   * @memberof SpacesSpaceIdChallengePostsPostIdDelete200Response
   */
  content?: object;
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdChallengePostsPostIdDelete200Response
   */
  publishAt?: number | null;
  /**
   *
   * @type {boolean}
   * @memberof SpacesSpaceIdChallengePostsPostIdDelete200Response
   */
  isDraft?: boolean;
}

export const SpacesSpaceIdChallengePostsPostIdDelete200ResponseTypeEnum = {
  Intro: 'intro',
  Step: 'step',
  Finish: 'finish',
  Deferred: 'deferred',
} as const;

export type SpacesSpaceIdChallengePostsPostIdDelete200ResponseTypeEnum =
  typeof SpacesSpaceIdChallengePostsPostIdDelete200ResponseTypeEnum[keyof typeof SpacesSpaceIdChallengePostsPostIdDelete200ResponseTypeEnum];

/**
 *
 * @export
 * @interface SpacesSpaceIdChallengePostsPostIdDelete200ResponseInner
 */
export interface SpacesSpaceIdChallengePostsPostIdDelete200ResponseInner {
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdChallengePostsPostIdDelete200ResponseInner
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdChallengePostsPostIdDelete200ResponseInner
   */
  createdAt?: number;
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdChallengePostsPostIdDelete200ResponseInner
   */
  updatedAt?: number | null;
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdChallengePostsPostIdDelete200ResponseInner
   */
  deletedAt?: number | null;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdChallengePostsPostIdDelete200ResponseInner
   */
  type?: SpacesSpaceIdChallengePostsPostIdDelete200ResponseInnerTypeEnum;
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdChallengePostsPostIdDelete200ResponseInner
   */
  spaceId?: number;
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdChallengePostsPostIdDelete200ResponseInner
   */
  stepId?: number | null;
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdChallengePostsPostIdDelete200ResponseInner
   */
  order?: number;
  /**
   *
   * @type {object}
   * @memberof SpacesSpaceIdChallengePostsPostIdDelete200ResponseInner
   */
  content?: object;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdChallengePostsPostIdDelete200ResponseInner
   */
  gsMessageId?: string | null;
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdChallengePostsPostIdDelete200ResponseInner
   */
  publishAt?: number | null;
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdChallengePostsPostIdDelete200ResponseInner
   */
  publishedAt?: number | null;
  /**
   *
   * @type {boolean}
   * @memberof SpacesSpaceIdChallengePostsPostIdDelete200ResponseInner
   */
  isDraft?: boolean;
}

export const SpacesSpaceIdChallengePostsPostIdDelete200ResponseInnerTypeEnum = {
  Intro: 'intro',
  Step: 'step',
  Finish: 'finish',
  Deferred: 'deferred',
} as const;

export type SpacesSpaceIdChallengePostsPostIdDelete200ResponseInnerTypeEnum =
  typeof SpacesSpaceIdChallengePostsPostIdDelete200ResponseInnerTypeEnum[keyof typeof SpacesSpaceIdChallengePostsPostIdDelete200ResponseInnerTypeEnum];

/**
 *
 * @export
 * @interface SpacesSpaceIdChallengePostsPostIdDeleteRequest
 */
export interface SpacesSpaceIdChallengePostsPostIdDeleteRequest {
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdChallengePostsPostIdDeleteRequest
   */
  type?: SpacesSpaceIdChallengePostsPostIdDeleteRequestTypeEnum;
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdChallengePostsPostIdDeleteRequest
   */
  stepId?: number | null;
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdChallengePostsPostIdDeleteRequest
   */
  order?: number;
  /**
   *
   * @type {object}
   * @memberof SpacesSpaceIdChallengePostsPostIdDeleteRequest
   */
  content?: object;
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdChallengePostsPostIdDeleteRequest
   */
  publishAt?: number | null;
  /**
   *
   * @type {boolean}
   * @memberof SpacesSpaceIdChallengePostsPostIdDeleteRequest
   */
  isDraft?: boolean;
}

export const SpacesSpaceIdChallengePostsPostIdDeleteRequestTypeEnum = {
  Intro: 'intro',
  Step: 'step',
  Finish: 'finish',
  Deferred: 'deferred',
} as const;

export type SpacesSpaceIdChallengePostsPostIdDeleteRequestTypeEnum =
  typeof SpacesSpaceIdChallengePostsPostIdDeleteRequestTypeEnum[keyof typeof SpacesSpaceIdChallengePostsPostIdDeleteRequestTypeEnum];

/**
 *
 * @export
 * @interface SpacesSpaceIdChallengeResultsGet200ResponseInner
 */
export interface SpacesSpaceIdChallengeResultsGet200ResponseInner {
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdChallengeResultsGet200ResponseInner
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdChallengeResultsGet200ResponseInner
   */
  name?: string;
  /**
   *
   * @type {SpacesSpaceIdChallengeResultsGet200ResponseInnerAvatar}
   * @memberof SpacesSpaceIdChallengeResultsGet200ResponseInner
   */
  avatar?: SpacesSpaceIdChallengeResultsGet200ResponseInnerAvatar | null;
}
/**
 *
 * @export
 * @interface SpacesSpaceIdChallengeResultsGet200ResponseInnerAvatar
 */
export interface SpacesSpaceIdChallengeResultsGet200ResponseInnerAvatar {
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdChallengeResultsGet200ResponseInnerAvatar
   */
  url?: string | null;
}
/**
 *
 * @export
 * @interface SpacesSpaceIdChallengeStepsGet200ResponseInner
 */
export interface SpacesSpaceIdChallengeStepsGet200ResponseInner {
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdChallengeStepsGet200ResponseInner
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdChallengeStepsGet200ResponseInner
   */
  createdAt?: number;
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdChallengeStepsGet200ResponseInner
   */
  updatedAt?: number | null;
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdChallengeStepsGet200ResponseInner
   */
  challengeSpace?: number;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdChallengeStepsGet200ResponseInner
   */
  title?: string;
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdChallengeStepsGet200ResponseInner
   */
  markAsCompletedForm?: number | null;
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdChallengeStepsGet200ResponseInner
   */
  feedbackForm?: number | null;
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdChallengeStepsGet200ResponseInner
   */
  startAt?: number | null;
  /**
   *
   * @type {boolean}
   * @memberof SpacesSpaceIdChallengeStepsGet200ResponseInner
   */
  isEnabled?: boolean;
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdChallengeStepsGet200ResponseInner
   */
  estimatedEffort?: number;
  /**
   *
   * @type {boolean}
   * @memberof SpacesSpaceIdChallengeStepsGet200ResponseInner
   */
  isSignificant?: boolean;
}
/**
 *
 * @export
 * @interface SpacesSpaceIdChallengeStepsGet200ResponseInner1
 */
export interface SpacesSpaceIdChallengeStepsGet200ResponseInner1 {
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdChallengeStepsGet200ResponseInner1
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdChallengeStepsGet200ResponseInner1
   */
  createdAt?: number;
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdChallengeStepsGet200ResponseInner1
   */
  updatedAt?: number | null;
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdChallengeStepsGet200ResponseInner1
   */
  challengeSpace?: number;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdChallengeStepsGet200ResponseInner1
   */
  type?: SpacesSpaceIdChallengeStepsGet200ResponseInner1TypeEnum;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdChallengeStepsGet200ResponseInner1
   */
  title?: string;
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdChallengeStepsGet200ResponseInner1
   */
  markAsCompletedForm?: number | null;
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdChallengeStepsGet200ResponseInner1
   */
  feedbackForm?: number | null;
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdChallengeStepsGet200ResponseInner1
   */
  startAt?: number | null;
  /**
   *
   * @type {boolean}
   * @memberof SpacesSpaceIdChallengeStepsGet200ResponseInner1
   */
  isEnabled?: boolean;
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdChallengeStepsGet200ResponseInner1
   */
  estimatedEffort?: number;
  /**
   *
   * @type {boolean}
   * @memberof SpacesSpaceIdChallengeStepsGet200ResponseInner1
   */
  isSignificant?: boolean;
}

export const SpacesSpaceIdChallengeStepsGet200ResponseInner1TypeEnum = {
  Post: 'post',
  LiveStream: 'live_stream',
} as const;

export type SpacesSpaceIdChallengeStepsGet200ResponseInner1TypeEnum =
  typeof SpacesSpaceIdChallengeStepsGet200ResponseInner1TypeEnum[keyof typeof SpacesSpaceIdChallengeStepsGet200ResponseInner1TypeEnum];

/**
 *
 * @export
 * @interface SpacesSpaceIdChallengeStepsGetRequest
 */
export interface SpacesSpaceIdChallengeStepsGetRequest {
  /**
   *
   * @type {Array<number>}
   * @memberof SpacesSpaceIdChallengeStepsGetRequest
   */
  deleted?: Array<number>;
  /**
   *
   * @type {Array<SpacesSpaceIdChallengeStepsGetRequestCreatedInner>}
   * @memberof SpacesSpaceIdChallengeStepsGetRequest
   */
  created?: Array<SpacesSpaceIdChallengeStepsGetRequestCreatedInner>;
  /**
   *
   * @type {Array<SpacesSpaceIdChallengeStepsGetRequestUpdatedInner>}
   * @memberof SpacesSpaceIdChallengeStepsGetRequest
   */
  updated?: Array<SpacesSpaceIdChallengeStepsGetRequestUpdatedInner>;
}
/**
 *
 * @export
 * @interface SpacesSpaceIdChallengeStepsGetRequestCreatedInner
 */
export interface SpacesSpaceIdChallengeStepsGetRequestCreatedInner {
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdChallengeStepsGetRequestCreatedInner
   */
  startAt?: number | null;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdChallengeStepsGetRequestCreatedInner
   */
  title?: string;
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdChallengeStepsGetRequestCreatedInner
   */
  estimatedEffort?: number;
  /**
   *
   * @type {boolean}
   * @memberof SpacesSpaceIdChallengeStepsGetRequestCreatedInner
   */
  isEnabled?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof SpacesSpaceIdChallengeStepsGetRequestCreatedInner
   */
  isSignificant?: boolean;
}
/**
 *
 * @export
 * @interface SpacesSpaceIdChallengeStepsGetRequestUpdatedInner
 */
export interface SpacesSpaceIdChallengeStepsGetRequestUpdatedInner {
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdChallengeStepsGetRequestUpdatedInner
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdChallengeStepsGetRequestUpdatedInner
   */
  startAt?: number | null;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdChallengeStepsGetRequestUpdatedInner
   */
  title?: string | null;
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdChallengeStepsGetRequestUpdatedInner
   */
  estimatedEffort?: number | null;
  /**
   *
   * @type {boolean}
   * @memberof SpacesSpaceIdChallengeStepsGetRequestUpdatedInner
   */
  isEnabled?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof SpacesSpaceIdChallengeStepsGetRequestUpdatedInner
   */
  isSignificant?: boolean | null;
}
/**
 *
 * @export
 * @interface SpacesSpaceIdClonePost200Response
 */
export interface SpacesSpaceIdClonePost200Response {
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdClonePost200Response
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdClonePost200Response
   */
  club?: number;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdClonePost200Response
   */
  title?: string;
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdClonePost200Response
   */
  price?: number;
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdClonePost200Response
   */
  creatorPrice?: number;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdClonePost200Response
   */
  type?: SpacesSpaceIdClonePost200ResponseTypeEnum;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdClonePost200Response
   */
  payment_type?: SpacesSpaceIdClonePost200ResponsePaymentTypeEnum;
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdClonePost200Response
   */
  coverFileId?: number;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdClonePost200Response
   */
  priceModel?: SpacesSpaceIdClonePost200ResponsePriceModelEnum;
  /**
   *
   * @type {object}
   * @memberof SpacesSpaceIdClonePost200Response
   */
  priceConfig?: object | null;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdClonePost200Response
   */
  iosProductId?: string | null;
  /**
   *
   * @type {SpacesSpaceIdClonePost200ResponseConfig}
   * @memberof SpacesSpaceIdClonePost200Response
   */
  config?: SpacesSpaceIdClonePost200ResponseConfig | null;
  /**
   *
   * @type {SpacesSpaceIdClonePost200ResponseMarketingPriceConfig}
   * @memberof SpacesSpaceIdClonePost200Response
   */
  marketingPriceConfig?: SpacesSpaceIdClonePost200ResponseMarketingPriceConfig;
  /**
   *
   * @type {Array<SpacesSpaceIdClonePost200ResponseStepsInner>}
   * @memberof SpacesSpaceIdClonePost200Response
   */
  steps?: Array<SpacesSpaceIdClonePost200ResponseStepsInner>;
  /**
   *
   * @type {Array<SpacesSpaceIdClonePost200ResponseStepsInnerPostsInner>}
   * @memberof SpacesSpaceIdClonePost200Response
   */
  posts?: Array<SpacesSpaceIdClonePost200ResponseStepsInnerPostsInner>;
}

export const SpacesSpaceIdClonePost200ResponseTypeEnum = {
  Post: 'post',
  Live: 'live',
  Challenge: 'challenge',
  Trailer: 'trailer',
  Channel: 'channel',
  Community: 'community',
} as const;

export type SpacesSpaceIdClonePost200ResponseTypeEnum =
  typeof SpacesSpaceIdClonePost200ResponseTypeEnum[keyof typeof SpacesSpaceIdClonePost200ResponseTypeEnum];
export const SpacesSpaceIdClonePost200ResponsePaymentTypeEnum = {
  FixedPrice: 'fixed_price',
  FixedPriceSubscribersOnly: 'fixed_price_subscribers_only',
  SubscribersOnly: 'subscribers_only',
  Free: 'free',
  MonthlyPrice: 'monthly_price',
} as const;

export type SpacesSpaceIdClonePost200ResponsePaymentTypeEnum =
  typeof SpacesSpaceIdClonePost200ResponsePaymentTypeEnum[keyof typeof SpacesSpaceIdClonePost200ResponsePaymentTypeEnum];
export const SpacesSpaceIdClonePost200ResponsePriceModelEnum = {
  Fixed: 'fixed',
  Auction: 'auction',
} as const;

export type SpacesSpaceIdClonePost200ResponsePriceModelEnum =
  typeof SpacesSpaceIdClonePost200ResponsePriceModelEnum[keyof typeof SpacesSpaceIdClonePost200ResponsePriceModelEnum];

/**
 *
 * @export
 * @interface SpacesSpaceIdClonePost200ResponseConfig
 */
export interface SpacesSpaceIdClonePost200ResponseConfig {
  /**
   *
   * @type {SpacesSpaceIdClonePost200ResponseConfigChallenge}
   * @memberof SpacesSpaceIdClonePost200ResponseConfig
   */
  challenge?: SpacesSpaceIdClonePost200ResponseConfigChallenge | null;
  /**
   *
   * @type {SpacesSpaceIdClonePost200ResponseConfigLive}
   * @memberof SpacesSpaceIdClonePost200ResponseConfig
   */
  live?: SpacesSpaceIdClonePost200ResponseConfigLive | null;
  /**
   *
   * @type {SpacesSpaceIdClonePost200ResponseConfigChannel}
   * @memberof SpacesSpaceIdClonePost200ResponseConfig
   */
  channel?: SpacesSpaceIdClonePost200ResponseConfigChannel | null;
}
/**
 *
 * @export
 * @interface SpacesSpaceIdClonePost200ResponseConfigChallenge
 */
export interface SpacesSpaceIdClonePost200ResponseConfigChallenge {
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdClonePost200ResponseConfigChallenge
   */
  finishTimestamp?: number | null;
}
/**
 *
 * @export
 * @interface SpacesSpaceIdClonePost200ResponseConfigChannel
 */
export interface SpacesSpaceIdClonePost200ResponseConfigChannel {
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdClonePost200ResponseConfigChannel
   */
  creatorDescription?: string;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdClonePost200ResponseConfigChannel
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdClonePost200ResponseConfigChannel
   */
  targetAudience?: string;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdClonePost200ResponseConfigChannel
   */
  contentFormat?: string;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdClonePost200ResponseConfigChannel
   */
  updateFrequency?: SpacesSpaceIdClonePost200ResponseConfigChannelUpdateFrequencyEnum;
}

export const SpacesSpaceIdClonePost200ResponseConfigChannelUpdateFrequencyEnum = {
  Daily: 'daily',
  TwoThreePerWeek: 'twoThreePerWeek',
  Weekly: 'weekly',
  TwoThreePerMonth: 'twoThreePerMonth',
  Occasionally: 'occasionally',
} as const;

export type SpacesSpaceIdClonePost200ResponseConfigChannelUpdateFrequencyEnum =
  typeof SpacesSpaceIdClonePost200ResponseConfigChannelUpdateFrequencyEnum[keyof typeof SpacesSpaceIdClonePost200ResponseConfigChannelUpdateFrequencyEnum];

/**
 *
 * @export
 * @interface SpacesSpaceIdClonePost200ResponseConfigLive
 */
export interface SpacesSpaceIdClonePost200ResponseConfigLive {
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdClonePost200ResponseConfigLive
   */
  startAt?: number;
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdClonePost200ResponseConfigLive
   */
  duration?: number;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdClonePost200ResponseConfigLive
   */
  linkToZoom?: string | null;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdClonePost200ResponseConfigLive
   */
  requirements?: string | null;
  /**
   *
   * @type {SpacesSpaceIdClonePost200ResponseConfigLiveRecord}
   * @memberof SpacesSpaceIdClonePost200ResponseConfigLive
   */
  record?: SpacesSpaceIdClonePost200ResponseConfigLiveRecord | null;
  /**
   *
   * @type {Array<SpacesSpaceIdClonePost200ResponseConfigLiveTestimonialsInner>}
   * @memberof SpacesSpaceIdClonePost200ResponseConfigLive
   */
  testimonials?: Array<SpacesSpaceIdClonePost200ResponseConfigLiveTestimonialsInner>;
}
/**
 *
 * @export
 * @interface SpacesSpaceIdClonePost200ResponseConfigLiveRecord
 */
export interface SpacesSpaceIdClonePost200ResponseConfigLiveRecord {
  /**
   *
   * @type {object}
   * @memberof SpacesSpaceIdClonePost200ResponseConfigLiveRecord
   */
  meta?: object | null;
  /**
   *
   * @type {SpacesSpaceIdClonePost200ResponseConfigLiveRecordVideo}
   * @memberof SpacesSpaceIdClonePost200ResponseConfigLiveRecord
   */
  video?: SpacesSpaceIdClonePost200ResponseConfigLiveRecordVideo | null;
  /**
   *
   * @type {SpacesSpaceIdClonePost200ResponseConfigLiveRecordVideo}
   * @memberof SpacesSpaceIdClonePost200ResponseConfigLiveRecord
   */
  cover?: SpacesSpaceIdClonePost200ResponseConfigLiveRecordVideo | null;
}
/**
 *
 * @export
 * @interface SpacesSpaceIdClonePost200ResponseConfigLiveRecordVideo
 */
export interface SpacesSpaceIdClonePost200ResponseConfigLiveRecordVideo {
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdClonePost200ResponseConfigLiveRecordVideo
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdClonePost200ResponseConfigLiveRecordVideo
   */
  url?: string;
}
/**
 *
 * @export
 * @interface SpacesSpaceIdClonePost200ResponseConfigLiveTestimonialsInner
 */
export interface SpacesSpaceIdClonePost200ResponseConfigLiveTestimonialsInner {
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdClonePost200ResponseConfigLiveTestimonialsInner
   */
  title?: string | null;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdClonePost200ResponseConfigLiveTestimonialsInner
   */
  authorName?: string;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdClonePost200ResponseConfigLiveTestimonialsInner
   */
  review?: string;
  /**
   *
   * @type {SpacesSpaceIdClonePost200ResponseConfigLiveRecordVideo}
   * @memberof SpacesSpaceIdClonePost200ResponseConfigLiveTestimonialsInner
   */
  avatar?: SpacesSpaceIdClonePost200ResponseConfigLiveRecordVideo | null;
}
/**
 *
 * @export
 * @interface SpacesSpaceIdClonePost200ResponseMarketingPriceConfig
 */
export interface SpacesSpaceIdClonePost200ResponseMarketingPriceConfig {
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdClonePost200ResponseMarketingPriceConfig
   */
  previousPrice?: number | null;
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdClonePost200ResponseMarketingPriceConfig
   */
  iosPrice?: number;
}
/**
 *
 * @export
 * @interface SpacesSpaceIdClonePost200ResponseStepsInner
 */
export interface SpacesSpaceIdClonePost200ResponseStepsInner {
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdClonePost200ResponseStepsInner
   */
  title?: string;
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdClonePost200ResponseStepsInner
   */
  startAt?: number | null;
  /**
   *
   * @type {boolean}
   * @memberof SpacesSpaceIdClonePost200ResponseStepsInner
   */
  isEnabled?: boolean;
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdClonePost200ResponseStepsInner
   */
  estimatedEffort?: number;
  /**
   *
   * @type {boolean}
   * @memberof SpacesSpaceIdClonePost200ResponseStepsInner
   */
  isSignificant?: boolean;
  /**
   *
   * @type {Array<SpacesSpaceIdClonePost200ResponseStepsInnerPostsInner>}
   * @memberof SpacesSpaceIdClonePost200ResponseStepsInner
   */
  posts?: Array<SpacesSpaceIdClonePost200ResponseStepsInnerPostsInner>;
}
/**
 *
 * @export
 * @interface SpacesSpaceIdClonePost200ResponseStepsInnerPostsInner
 */
export interface SpacesSpaceIdClonePost200ResponseStepsInnerPostsInner {
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdClonePost200ResponseStepsInnerPostsInner
   */
  type?: SpacesSpaceIdClonePost200ResponseStepsInnerPostsInnerTypeEnum;
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdClonePost200ResponseStepsInnerPostsInner
   */
  order?: number;
  /**
   *
   * @type {object}
   * @memberof SpacesSpaceIdClonePost200ResponseStepsInnerPostsInner
   */
  content?: object;
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdClonePost200ResponseStepsInnerPostsInner
   */
  publishAt?: number | null;
  /**
   *
   * @type {boolean}
   * @memberof SpacesSpaceIdClonePost200ResponseStepsInnerPostsInner
   */
  isDraft?: boolean;
}

export const SpacesSpaceIdClonePost200ResponseStepsInnerPostsInnerTypeEnum = {
  Intro: 'intro',
  Step: 'step',
  Finish: 'finish',
  Deferred: 'deferred',
} as const;

export type SpacesSpaceIdClonePost200ResponseStepsInnerPostsInnerTypeEnum =
  typeof SpacesSpaceIdClonePost200ResponseStepsInnerPostsInnerTypeEnum[keyof typeof SpacesSpaceIdClonePost200ResponseStepsInnerPostsInnerTypeEnum];

/**
 *
 * @export
 * @interface SpacesSpaceIdDelete200Response
 */
export interface SpacesSpaceIdDelete200Response {
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdDelete200Response
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdDelete200Response
   */
  created_at?: number;
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdDelete200Response
   */
  updated_at?: number | null;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdDelete200Response
   */
  title?: string;
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdDelete200Response
   */
  price?: number;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdDelete200Response
   */
  type?: SpacesSpaceIdDelete200ResponseTypeEnum;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdDelete200Response
   */
  status?: SpacesSpaceIdDelete200ResponseStatusEnum;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdDelete200Response
   */
  payment_type?: SpacesSpaceIdDelete200ResponsePaymentTypeEnum;
  /**
   *
   * @type {SpacesSpaceIdDelete200ResponseConfig}
   * @memberof SpacesSpaceIdDelete200Response
   */
  config?: SpacesSpaceIdDelete200ResponseConfig | null;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdDelete200Response
   */
  slug?: string;
  /**
   *
   * @type {SpacesSpaceIdClonePost200ResponseConfigLiveRecordVideo}
   * @memberof SpacesSpaceIdDelete200Response
   */
  cover?: SpacesSpaceIdClonePost200ResponseConfigLiveRecordVideo | null;
  /**
   *
   * @type {SpacesSpaceIdDelete200ResponseOwner}
   * @memberof SpacesSpaceIdDelete200Response
   */
  owner?: SpacesSpaceIdDelete200ResponseOwner | null;
}

export const SpacesSpaceIdDelete200ResponseTypeEnum = {
  Post: 'post',
  Live: 'live',
  Challenge: 'challenge',
  Trailer: 'trailer',
  Channel: 'channel',
  Community: 'community',
} as const;

export type SpacesSpaceIdDelete200ResponseTypeEnum =
  typeof SpacesSpaceIdDelete200ResponseTypeEnum[keyof typeof SpacesSpaceIdDelete200ResponseTypeEnum];
export const SpacesSpaceIdDelete200ResponseStatusEnum = {
  Draft: 'draft',
  Published: 'published',
} as const;

export type SpacesSpaceIdDelete200ResponseStatusEnum =
  typeof SpacesSpaceIdDelete200ResponseStatusEnum[keyof typeof SpacesSpaceIdDelete200ResponseStatusEnum];
export const SpacesSpaceIdDelete200ResponsePaymentTypeEnum = {
  FixedPrice: 'fixed_price',
  FixedPriceSubscribersOnly: 'fixed_price_subscribers_only',
  SubscribersOnly: 'subscribers_only',
  Free: 'free',
  MonthlyPrice: 'monthly_price',
} as const;

export type SpacesSpaceIdDelete200ResponsePaymentTypeEnum =
  typeof SpacesSpaceIdDelete200ResponsePaymentTypeEnum[keyof typeof SpacesSpaceIdDelete200ResponsePaymentTypeEnum];

/**
 *
 * @export
 * @interface SpacesSpaceIdDelete200Response1
 */
export interface SpacesSpaceIdDelete200Response1 {
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdDelete200Response1
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdDelete200Response1
   */
  created_at?: number;
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdDelete200Response1
   */
  updated_at?: number | null;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdDelete200Response1
   */
  title?: string;
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdDelete200Response1
   */
  price?: number;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdDelete200Response1
   */
  type?: SpacesSpaceIdDelete200Response1TypeEnum;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdDelete200Response1
   */
  status?: SpacesSpaceIdDelete200Response1StatusEnum;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdDelete200Response1
   */
  payment_type?: SpacesSpaceIdDelete200Response1PaymentTypeEnum;
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdDelete200Response1
   */
  coverFileId?: number;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdDelete200Response1
   */
  description?: string | null;
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdDelete200Response1
   */
  metaImageFileId?: number | null;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdDelete200Response1
   */
  metaDescription?: string | null;
  /**
   *
   * @type {SpacesSpaceIdDelete200Response1Config}
   * @memberof SpacesSpaceIdDelete200Response1
   */
  config?: SpacesSpaceIdDelete200Response1Config | null;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdDelete200Response1
   */
  slug?: string;
  /**
   *
   * @type {SpacesSpaceIdClonePost200ResponseConfigLiveRecordVideo}
   * @memberof SpacesSpaceIdDelete200Response1
   */
  cover?: SpacesSpaceIdClonePost200ResponseConfigLiveRecordVideo | null;
  /**
   *
   * @type {SpacesSpaceIdDelete200ResponseOwner}
   * @memberof SpacesSpaceIdDelete200Response1
   */
  owner?: SpacesSpaceIdDelete200ResponseOwner | null;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdDelete200Response1
   */
  userSlug?: string | null;
}

export const SpacesSpaceIdDelete200Response1TypeEnum = {
  Post: 'post',
  Live: 'live',
  Challenge: 'challenge',
  Trailer: 'trailer',
  Channel: 'channel',
  Community: 'community',
} as const;

export type SpacesSpaceIdDelete200Response1TypeEnum =
  typeof SpacesSpaceIdDelete200Response1TypeEnum[keyof typeof SpacesSpaceIdDelete200Response1TypeEnum];
export const SpacesSpaceIdDelete200Response1StatusEnum = {
  Draft: 'draft',
  Published: 'published',
} as const;

export type SpacesSpaceIdDelete200Response1StatusEnum =
  typeof SpacesSpaceIdDelete200Response1StatusEnum[keyof typeof SpacesSpaceIdDelete200Response1StatusEnum];
export const SpacesSpaceIdDelete200Response1PaymentTypeEnum = {
  FixedPrice: 'fixed_price',
  FixedPriceSubscribersOnly: 'fixed_price_subscribers_only',
  SubscribersOnly: 'subscribers_only',
  Free: 'free',
  MonthlyPrice: 'monthly_price',
} as const;

export type SpacesSpaceIdDelete200Response1PaymentTypeEnum =
  typeof SpacesSpaceIdDelete200Response1PaymentTypeEnum[keyof typeof SpacesSpaceIdDelete200Response1PaymentTypeEnum];

/**
 *
 * @export
 * @interface SpacesSpaceIdDelete200Response1Config
 */
export interface SpacesSpaceIdDelete200Response1Config {
  /**
   *
   * @type {SpacesSpaceIdDelete200ResponseConfigChallenge}
   * @memberof SpacesSpaceIdDelete200Response1Config
   */
  challenge?: SpacesSpaceIdDelete200ResponseConfigChallenge | null;
  /**
   *
   * @type {SpacesSpaceIdDelete200ResponseConfigLive}
   * @memberof SpacesSpaceIdDelete200Response1Config
   */
  live?: SpacesSpaceIdDelete200ResponseConfigLive | null;
  /**
   *
   * @type {SpacesSpaceIdClonePost200ResponseConfigChannel}
   * @memberof SpacesSpaceIdDelete200Response1Config
   */
  channel?: SpacesSpaceIdClonePost200ResponseConfigChannel | null;
  /**
   *
   * @type {SpacesSpaceIdDelete200Response1ConfigCommunity}
   * @memberof SpacesSpaceIdDelete200Response1Config
   */
  community?: SpacesSpaceIdDelete200Response1ConfigCommunity | null;
}
/**
 *
 * @export
 * @interface SpacesSpaceIdDelete200Response1ConfigCommunity
 */
export interface SpacesSpaceIdDelete200Response1ConfigCommunity {
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdDelete200Response1ConfigCommunity
   */
  updateFrequency?: SpacesSpaceIdDelete200Response1ConfigCommunityUpdateFrequencyEnum;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdDelete200Response1ConfigCommunity
   */
  creatorDescription?: string | null;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdDelete200Response1ConfigCommunity
   */
  targetAudience?: string | null;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdDelete200Response1ConfigCommunity
   */
  inside?: string | null;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdDelete200Response1ConfigCommunity
   */
  offerSummary?: string | null;
  /**
   *
   * @type {Array<string>}
   * @memberof SpacesSpaceIdDelete200Response1ConfigCommunity
   */
  creatorAchievements?: Array<string>;
  /**
   *
   * @type {Array<SpacesSpaceIdDelete200Response1ConfigCommunityReviewsInner>}
   * @memberof SpacesSpaceIdDelete200Response1ConfigCommunity
   */
  reviews?: Array<SpacesSpaceIdDelete200Response1ConfigCommunityReviewsInner> | null;
  /**
   *
   * @type {Array<SpacesSpaceIdDeleteRequestConfigCommunityFaqInner>}
   * @memberof SpacesSpaceIdDelete200Response1ConfigCommunity
   */
  faq?: Array<SpacesSpaceIdDeleteRequestConfigCommunityFaqInner> | null;
  /**
   *
   * @type {SpacesSpaceIdDelete200Response1ConfigCommunityVideo}
   * @memberof SpacesSpaceIdDelete200Response1ConfigCommunity
   */
  video?: SpacesSpaceIdDelete200Response1ConfigCommunityVideo | null;
}

export const SpacesSpaceIdDelete200Response1ConfigCommunityUpdateFrequencyEnum = {
  Daily: 'daily',
  TwoThreePerWeek: 'twoThreePerWeek',
  Weekly: 'weekly',
  TwoThreePerMonth: 'twoThreePerMonth',
  Occasionally: 'occasionally',
} as const;

export type SpacesSpaceIdDelete200Response1ConfigCommunityUpdateFrequencyEnum =
  typeof SpacesSpaceIdDelete200Response1ConfigCommunityUpdateFrequencyEnum[keyof typeof SpacesSpaceIdDelete200Response1ConfigCommunityUpdateFrequencyEnum];

/**
 *
 * @export
 * @interface SpacesSpaceIdDelete200Response1ConfigCommunityReviewsInner
 */
export interface SpacesSpaceIdDelete200Response1ConfigCommunityReviewsInner {
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdDelete200Response1ConfigCommunityReviewsInner
   */
  name?: string;
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdDelete200Response1ConfigCommunityReviewsInner
   */
  avatarFileId?: number;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdDelete200Response1ConfigCommunityReviewsInner
   */
  text?: string;
  /**
   *
   * @type {SpacesSpaceIdClonePost200ResponseConfigLiveRecordVideo}
   * @memberof SpacesSpaceIdDelete200Response1ConfigCommunityReviewsInner
   */
  avatar?: SpacesSpaceIdClonePost200ResponseConfigLiveRecordVideo | null;
}
/**
 *
 * @export
 * @interface SpacesSpaceIdDelete200Response1ConfigCommunityVideo
 */
export interface SpacesSpaceIdDelete200Response1ConfigCommunityVideo {
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdDelete200Response1ConfigCommunityVideo
   */
  videoFileId?: number | null;
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdDelete200Response1ConfigCommunityVideo
   */
  coverFileId?: number | null;
  /**
   *
   * @type {SpacesSpaceIdClonePost200ResponseConfigLiveRecordVideo}
   * @memberof SpacesSpaceIdDelete200Response1ConfigCommunityVideo
   */
  file?: SpacesSpaceIdClonePost200ResponseConfigLiveRecordVideo | null;
  /**
   *
   * @type {SpacesSpaceIdClonePost200ResponseConfigLiveRecordVideo}
   * @memberof SpacesSpaceIdDelete200Response1ConfigCommunityVideo
   */
  cover?: SpacesSpaceIdClonePost200ResponseConfigLiveRecordVideo | null;
}
/**
 *
 * @export
 * @interface SpacesSpaceIdDelete200ResponseConfig
 */
export interface SpacesSpaceIdDelete200ResponseConfig {
  /**
   *
   * @type {SpacesSpaceIdDelete200ResponseConfigChallenge}
   * @memberof SpacesSpaceIdDelete200ResponseConfig
   */
  challenge?: SpacesSpaceIdDelete200ResponseConfigChallenge | null;
  /**
   *
   * @type {SpacesSpaceIdDelete200ResponseConfigLive}
   * @memberof SpacesSpaceIdDelete200ResponseConfig
   */
  live?: SpacesSpaceIdDelete200ResponseConfigLive | null;
  /**
   *
   * @type {SpacesSpaceIdClonePost200ResponseConfigChannel}
   * @memberof SpacesSpaceIdDelete200ResponseConfig
   */
  channel?: SpacesSpaceIdClonePost200ResponseConfigChannel | null;
}
/**
 *
 * @export
 * @interface SpacesSpaceIdDelete200ResponseConfigChallenge
 */
export interface SpacesSpaceIdDelete200ResponseConfigChallenge {
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdDelete200ResponseConfigChallenge
   */
  finishTimestamp?: number | null;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdDelete200ResponseConfigChallenge
   */
  offerDescription?: string | null;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdDelete200ResponseConfigChallenge
   */
  offerSummary?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof SpacesSpaceIdDelete200ResponseConfigChallenge
   */
  isProgram?: boolean;
}
/**
 *
 * @export
 * @interface SpacesSpaceIdDelete200ResponseConfigLive
 */
export interface SpacesSpaceIdDelete200ResponseConfigLive {
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdDelete200ResponseConfigLive
   */
  startAt?: number;
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdDelete200ResponseConfigLive
   */
  duration?: number;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdDelete200ResponseConfigLive
   */
  linkToZoom?: string | null;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdDelete200ResponseConfigLive
   */
  creatorDescription?: string;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdDelete200ResponseConfigLive
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdDelete200ResponseConfigLive
   */
  requirements?: string | null;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdDelete200ResponseConfigLive
   */
  offerDescription?: string | null;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdDelete200ResponseConfigLive
   */
  offerSummary?: string | null;
  /**
   *
   * @type {SpacesSpaceIdClonePost200ResponseConfigLiveRecord}
   * @memberof SpacesSpaceIdDelete200ResponseConfigLive
   */
  record?: SpacesSpaceIdClonePost200ResponseConfigLiveRecord | null;
  /**
   *
   * @type {Array<SpacesSpaceIdClonePost200ResponseConfigLiveTestimonialsInner>}
   * @memberof SpacesSpaceIdDelete200ResponseConfigLive
   */
  testimonials?: Array<SpacesSpaceIdClonePost200ResponseConfigLiveTestimonialsInner>;
}
/**
 *
 * @export
 * @interface SpacesSpaceIdDelete200ResponseOwner
 */
export interface SpacesSpaceIdDelete200ResponseOwner {
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdDelete200ResponseOwner
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdDelete200ResponseOwner
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdDelete200ResponseOwner
   */
  description?: string | null;
  /**
   *
   * @type {SpacesSpaceIdClonePost200ResponseConfigLiveRecordVideo}
   * @memberof SpacesSpaceIdDelete200ResponseOwner
   */
  avatar?: SpacesSpaceIdClonePost200ResponseConfigLiveRecordVideo | null;
}
/**
 *
 * @export
 * @interface SpacesSpaceIdDeleteRequest
 */
export interface SpacesSpaceIdDeleteRequest {
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdDeleteRequest
   */
  slug?: string | null;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdDeleteRequest
   */
  title?: string | null;
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdDeleteRequest
   */
  price?: number | null;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdDeleteRequest
   */
  status?: SpacesSpaceIdDeleteRequestStatusEnum;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdDeleteRequest
   */
  payment_type?: SpacesSpaceIdDeleteRequestPaymentTypeEnum;
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdDeleteRequest
   */
  coverFileId?: number | null;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdDeleteRequest
   */
  description?: string | null;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdDeleteRequest
   */
  metaDescription?: string | null;
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdDeleteRequest
   */
  metaImageFileId?: number | null;
  /**
   *
   * @type {SpacesSpaceIdDeleteRequestConfig}
   * @memberof SpacesSpaceIdDeleteRequest
   */
  config?: SpacesSpaceIdDeleteRequestConfig | null;
}

export const SpacesSpaceIdDeleteRequestStatusEnum = {
  Draft: 'draft',
  Published: 'published',
} as const;

export type SpacesSpaceIdDeleteRequestStatusEnum =
  typeof SpacesSpaceIdDeleteRequestStatusEnum[keyof typeof SpacesSpaceIdDeleteRequestStatusEnum];
export const SpacesSpaceIdDeleteRequestPaymentTypeEnum = {
  FixedPrice: 'fixed_price',
  FixedPriceSubscribersOnly: 'fixed_price_subscribers_only',
  SubscribersOnly: 'subscribers_only',
  Free: 'free',
  MonthlyPrice: 'monthly_price',
} as const;

export type SpacesSpaceIdDeleteRequestPaymentTypeEnum =
  typeof SpacesSpaceIdDeleteRequestPaymentTypeEnum[keyof typeof SpacesSpaceIdDeleteRequestPaymentTypeEnum];

/**
 *
 * @export
 * @interface SpacesSpaceIdDeleteRequestConfig
 */
export interface SpacesSpaceIdDeleteRequestConfig {
  /**
   *
   * @type {SpacesSpaceIdDelete200ResponseConfigChallenge}
   * @memberof SpacesSpaceIdDeleteRequestConfig
   */
  challenge?: SpacesSpaceIdDelete200ResponseConfigChallenge | null;
  /**
   *
   * @type {SpacesSpaceIdDeleteRequestConfigLive}
   * @memberof SpacesSpaceIdDeleteRequestConfig
   */
  live?: SpacesSpaceIdDeleteRequestConfigLive | null;
  /**
   *
   * @type {SpacesSpaceIdClonePost200ResponseConfigChannel}
   * @memberof SpacesSpaceIdDeleteRequestConfig
   */
  channel?: SpacesSpaceIdClonePost200ResponseConfigChannel | null;
  /**
   *
   * @type {SpacesSpaceIdDeleteRequestConfigCommunity}
   * @memberof SpacesSpaceIdDeleteRequestConfig
   */
  community?: SpacesSpaceIdDeleteRequestConfigCommunity | null;
}
/**
 *
 * @export
 * @interface SpacesSpaceIdDeleteRequestConfigCommunity
 */
export interface SpacesSpaceIdDeleteRequestConfigCommunity {
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdDeleteRequestConfigCommunity
   */
  updateFrequency?: SpacesSpaceIdDeleteRequestConfigCommunityUpdateFrequencyEnum;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdDeleteRequestConfigCommunity
   */
  creatorDescription?: string;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdDeleteRequestConfigCommunity
   */
  targetAudience?: string;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdDeleteRequestConfigCommunity
   */
  inside?: string;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdDeleteRequestConfigCommunity
   */
  offerSummary?: string | null;
  /**
   *
   * @type {Array<string>}
   * @memberof SpacesSpaceIdDeleteRequestConfigCommunity
   */
  creatorAchievements?: Array<string> | null;
  /**
   *
   * @type {Array<SpacesSpaceIdDeleteRequestConfigCommunityReviewsInner>}
   * @memberof SpacesSpaceIdDeleteRequestConfigCommunity
   */
  reviews?: Array<SpacesSpaceIdDeleteRequestConfigCommunityReviewsInner> | null;
  /**
   *
   * @type {Array<SpacesSpaceIdDeleteRequestConfigCommunityFaqInner>}
   * @memberof SpacesSpaceIdDeleteRequestConfigCommunity
   */
  faq?: Array<SpacesSpaceIdDeleteRequestConfigCommunityFaqInner> | null;
  /**
   *
   * @type {SpacesSpaceIdDeleteRequestConfigCommunityVideo}
   * @memberof SpacesSpaceIdDeleteRequestConfigCommunity
   */
  video?: SpacesSpaceIdDeleteRequestConfigCommunityVideo | null;
}

export const SpacesSpaceIdDeleteRequestConfigCommunityUpdateFrequencyEnum = {
  Daily: 'daily',
  TwoThreePerWeek: 'twoThreePerWeek',
  Weekly: 'weekly',
  TwoThreePerMonth: 'twoThreePerMonth',
  Occasionally: 'occasionally',
} as const;

export type SpacesSpaceIdDeleteRequestConfigCommunityUpdateFrequencyEnum =
  typeof SpacesSpaceIdDeleteRequestConfigCommunityUpdateFrequencyEnum[keyof typeof SpacesSpaceIdDeleteRequestConfigCommunityUpdateFrequencyEnum];

/**
 *
 * @export
 * @interface SpacesSpaceIdDeleteRequestConfigCommunityFaqInner
 */
export interface SpacesSpaceIdDeleteRequestConfigCommunityFaqInner {
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdDeleteRequestConfigCommunityFaqInner
   */
  question?: string;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdDeleteRequestConfigCommunityFaqInner
   */
  answer?: string;
}
/**
 *
 * @export
 * @interface SpacesSpaceIdDeleteRequestConfigCommunityReviewsInner
 */
export interface SpacesSpaceIdDeleteRequestConfigCommunityReviewsInner {
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdDeleteRequestConfigCommunityReviewsInner
   */
  name?: string;
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdDeleteRequestConfigCommunityReviewsInner
   */
  avatarFileId?: number;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdDeleteRequestConfigCommunityReviewsInner
   */
  text?: string;
}
/**
 *
 * @export
 * @interface SpacesSpaceIdDeleteRequestConfigCommunityVideo
 */
export interface SpacesSpaceIdDeleteRequestConfigCommunityVideo {
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdDeleteRequestConfigCommunityVideo
   */
  videoFileId?: number | null;
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdDeleteRequestConfigCommunityVideo
   */
  coverFileId?: number | null;
}
/**
 *
 * @export
 * @interface SpacesSpaceIdDeleteRequestConfigLive
 */
export interface SpacesSpaceIdDeleteRequestConfigLive {
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdDeleteRequestConfigLive
   */
  startAt?: number;
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdDeleteRequestConfigLive
   */
  duration?: number;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdDeleteRequestConfigLive
   */
  linkToZoom?: string;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdDeleteRequestConfigLive
   */
  requirements?: string | null;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdDeleteRequestConfigLive
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdDeleteRequestConfigLive
   */
  creatorDescription?: string;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdDeleteRequestConfigLive
   */
  offerDescription?: string | null;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdDeleteRequestConfigLive
   */
  offerSummary?: string | null;
  /**
   *
   * @type {SpacesSpaceIdDeleteRequestConfigLiveRecord}
   * @memberof SpacesSpaceIdDeleteRequestConfigLive
   */
  record?: SpacesSpaceIdDeleteRequestConfigLiveRecord | null;
  /**
   *
   * @type {Array<SpacesSpaceIdDeleteRequestConfigLiveTestimonialsInner>}
   * @memberof SpacesSpaceIdDeleteRequestConfigLive
   */
  testimonials?: Array<SpacesSpaceIdDeleteRequestConfigLiveTestimonialsInner>;
}
/**
 *
 * @export
 * @interface SpacesSpaceIdDeleteRequestConfigLiveRecord
 */
export interface SpacesSpaceIdDeleteRequestConfigLiveRecord {
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdDeleteRequestConfigLiveRecord
   */
  videoFileId?: number;
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdDeleteRequestConfigLiveRecord
   */
  coverFileId?: number | null;
  /**
   *
   * @type {object}
   * @memberof SpacesSpaceIdDeleteRequestConfigLiveRecord
   */
  meta?: object | null;
}
/**
 *
 * @export
 * @interface SpacesSpaceIdDeleteRequestConfigLiveTestimonialsInner
 */
export interface SpacesSpaceIdDeleteRequestConfigLiveTestimonialsInner {
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdDeleteRequestConfigLiveTestimonialsInner
   */
  title?: string | null;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdDeleteRequestConfigLiveTestimonialsInner
   */
  authorName?: string;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdDeleteRequestConfigLiveTestimonialsInner
   */
  review?: string;
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdDeleteRequestConfigLiveTestimonialsInner
   */
  avatarFileId?: number | null;
}
/**
 *
 * @export
 * @interface UploadImagePost200Response
 */
export interface UploadImagePost200Response {
  /**
   *
   * @type {string}
   * @memberof UploadImagePost200Response
   */
  path?: string;
  /**
   *
   * @type {string}
   * @memberof UploadImagePost200Response
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof UploadImagePost200Response
   */
  type?: string;
  /**
   *
   * @type {number}
   * @memberof UploadImagePost200Response
   */
  size?: number;
  /**
   *
   * @type {string}
   * @memberof UploadImagePost200Response
   */
  mime?: string;
  /**
   *
   * @type {object}
   * @memberof UploadImagePost200Response
   */
  meta?: object;
}

/**
 * ChallengeApi - axios parameter creator
 * @export
 */
export const ChallengeApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} stepId
     * @param {ChallengeStepsStepIdMovePostRequest} [challengeStepsStepIdMovePostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    challengeStepsStepIdMovePost: async (
      stepId: number,
      challengeStepsStepIdMovePostRequest?: ChallengeStepsStepIdMovePostRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'stepId' is not null or undefined
      assertParamExists('challengeStepsStepIdMovePost', 'stepId', stepId);
      const localVarPath = `/challenge/steps/{stepId}/move`.replace(
        `{${'stepId'}}`,
        encodeURIComponent(String(stepId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        challengeStepsStepIdMovePostRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ChallengeApi - functional programming interface
 * @export
 */
export const ChallengeApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ChallengeApiAxiosParamCreator(configuration);
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} stepId
     * @param {ChallengeStepsStepIdMovePostRequest} [challengeStepsStepIdMovePostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async challengeStepsStepIdMovePost(
      stepId: number,
      challengeStepsStepIdMovePostRequest?: ChallengeStepsStepIdMovePostRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChallengeStepsStepIdMovePost200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.challengeStepsStepIdMovePost(
        stepId,
        challengeStepsStepIdMovePostRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * ChallengeApi - factory interface
 * @export
 */
export const ChallengeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = ChallengeApiFp(configuration);
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} stepId
     * @param {ChallengeStepsStepIdMovePostRequest} [challengeStepsStepIdMovePostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    challengeStepsStepIdMovePost(
      stepId: number,
      challengeStepsStepIdMovePostRequest?: ChallengeStepsStepIdMovePostRequest,
      options?: any,
    ): AxiosPromise<ChallengeStepsStepIdMovePost200Response> {
      return localVarFp
        .challengeStepsStepIdMovePost(stepId, challengeStepsStepIdMovePostRequest, options)
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * ChallengeApi - object-oriented interface
 * @export
 * @class ChallengeApi
 * @extends {BaseAPI}
 */
export class ChallengeApi extends BaseAPI {
  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {number} stepId
   * @param {ChallengeStepsStepIdMovePostRequest} [challengeStepsStepIdMovePostRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ChallengeApi
   */
  public challengeStepsStepIdMovePost(
    stepId: number,
    challengeStepsStepIdMovePostRequest?: ChallengeStepsStepIdMovePostRequest,
    options?: AxiosRequestConfig,
  ) {
    return ChallengeApiFp(this.configuration)
      .challengeStepsStepIdMovePost(stepId, challengeStepsStepIdMovePostRequest, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * SpacesApi - axios parameter creator
 * @export
 */
export const SpacesApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Список всех спейсов эксперта <br /><br /> <b>Authentication:</b> required
     * @summary Список всех спейсов эксперта
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    spacesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/spaces`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Создание нового спейса <br /><br /> <b>Authentication:</b> required
     * @summary Создание нового спейса
     * @param {SpacesGetRequest} [spacesGetRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    spacesPost: async (spacesGetRequest?: SpacesGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/spaces`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(spacesGetRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} spaceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    spacesSpaceIdChallengePostsGet: async (spaceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'spaceId' is not null or undefined
      assertParamExists('spacesSpaceIdChallengePostsGet', 'spaceId', spaceId);
      const localVarPath = `/spaces/{spaceId}/challenge/posts`.replace(
        `{${'spaceId'}}`,
        encodeURIComponent(String(spaceId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} spaceId
     * @param {SpacesSpaceIdChallengePostsGetRequest} [spacesSpaceIdChallengePostsGetRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    spacesSpaceIdChallengePostsPatch: async (
      spaceId: number,
      spacesSpaceIdChallengePostsGetRequest?: SpacesSpaceIdChallengePostsGetRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'spaceId' is not null or undefined
      assertParamExists('spacesSpaceIdChallengePostsPatch', 'spaceId', spaceId);
      const localVarPath = `/spaces/{spaceId}/challenge/posts`.replace(
        `{${'spaceId'}}`,
        encodeURIComponent(String(spaceId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        spacesSpaceIdChallengePostsGetRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} spaceId
     * @param {SpacesSpaceIdChallengePostsPostIdDeleteRequest} [spacesSpaceIdChallengePostsPostIdDeleteRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    spacesSpaceIdChallengePostsPost: async (
      spaceId: number,
      spacesSpaceIdChallengePostsPostIdDeleteRequest?: SpacesSpaceIdChallengePostsPostIdDeleteRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'spaceId' is not null or undefined
      assertParamExists('spacesSpaceIdChallengePostsPost', 'spaceId', spaceId);
      const localVarPath = `/spaces/{spaceId}/challenge/posts`.replace(
        `{${'spaceId'}}`,
        encodeURIComponent(String(spaceId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        spacesSpaceIdChallengePostsPostIdDeleteRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} spaceId
     * @param {number} postId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    spacesSpaceIdChallengePostsPostIdDelete: async (
      spaceId: number,
      postId: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'spaceId' is not null or undefined
      assertParamExists('spacesSpaceIdChallengePostsPostIdDelete', 'spaceId', spaceId);
      // verify required parameter 'postId' is not null or undefined
      assertParamExists('spacesSpaceIdChallengePostsPostIdDelete', 'postId', postId);
      const localVarPath = `/spaces/{spaceId}/challenge/posts/{postId}`
        .replace(`{${'spaceId'}}`, encodeURIComponent(String(spaceId)))
        .replace(`{${'postId'}}`, encodeURIComponent(String(postId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} spaceId
     * @param {number} postId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    spacesSpaceIdChallengePostsPostIdGet: async (
      spaceId: number,
      postId: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'spaceId' is not null or undefined
      assertParamExists('spacesSpaceIdChallengePostsPostIdGet', 'spaceId', spaceId);
      // verify required parameter 'postId' is not null or undefined
      assertParamExists('spacesSpaceIdChallengePostsPostIdGet', 'postId', postId);
      const localVarPath = `/spaces/{spaceId}/challenge/posts/{postId}`
        .replace(`{${'spaceId'}}`, encodeURIComponent(String(spaceId)))
        .replace(`{${'postId'}}`, encodeURIComponent(String(postId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} postId
     * @param {number} spaceId
     * @param {SpacesSpaceIdChallengePostsPostIdDeleteRequest} [spacesSpaceIdChallengePostsPostIdDeleteRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    spacesSpaceIdChallengePostsPostIdPost: async (
      postId: number,
      spaceId: number,
      spacesSpaceIdChallengePostsPostIdDeleteRequest?: SpacesSpaceIdChallengePostsPostIdDeleteRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'postId' is not null or undefined
      assertParamExists('spacesSpaceIdChallengePostsPostIdPost', 'postId', postId);
      // verify required parameter 'spaceId' is not null or undefined
      assertParamExists('spacesSpaceIdChallengePostsPostIdPost', 'spaceId', spaceId);
      const localVarPath = `/spaces/{spaceId}/challenge/posts/{postId}`
        .replace(`{${'postId'}}`, encodeURIComponent(String(postId)))
        .replace(`{${'spaceId'}}`, encodeURIComponent(String(spaceId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        spacesSpaceIdChallengePostsPostIdDeleteRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} spaceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    spacesSpaceIdChallengeResultsGet: async (
      spaceId: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'spaceId' is not null or undefined
      assertParamExists('spacesSpaceIdChallengeResultsGet', 'spaceId', spaceId);
      const localVarPath = `/spaces/{spaceId}/challenge/results`.replace(
        `{${'spaceId'}}`,
        encodeURIComponent(String(spaceId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Получение списка всех шагов челленджа <br /><br /> <b>Authentication:</b> required
     * @summary Получение списка всех шагов челленджа
     * @param {number} spaceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    spacesSpaceIdChallengeStepsGet: async (spaceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'spaceId' is not null or undefined
      assertParamExists('spacesSpaceIdChallengeStepsGet', 'spaceId', spaceId);
      const localVarPath = `/spaces/{spaceId}/challenge/steps`.replace(
        `{${'spaceId'}}`,
        encodeURIComponent(String(spaceId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Update challenge steps <br /><br /> <b>Authentication:</b> required
     * @summary Update challenge steps
     * @param {number} spaceId
     * @param {SpacesSpaceIdChallengeStepsGetRequest} [spacesSpaceIdChallengeStepsGetRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    spacesSpaceIdChallengeStepsPatch: async (
      spaceId: number,
      spacesSpaceIdChallengeStepsGetRequest?: SpacesSpaceIdChallengeStepsGetRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'spaceId' is not null or undefined
      assertParamExists('spacesSpaceIdChallengeStepsPatch', 'spaceId', spaceId);
      const localVarPath = `/spaces/{spaceId}/challenge/steps`.replace(
        `{${'spaceId'}}`,
        encodeURIComponent(String(spaceId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        spacesSpaceIdChallengeStepsGetRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} spaceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    spacesSpaceIdClonePost: async (spaceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'spaceId' is not null or undefined
      assertParamExists('spacesSpaceIdClonePost', 'spaceId', spaceId);
      const localVarPath = `/spaces/{spaceId}/clone`.replace(`{${'spaceId'}}`, encodeURIComponent(String(spaceId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} spaceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    spacesSpaceIdDelete: async (spaceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'spaceId' is not null or undefined
      assertParamExists('spacesSpaceIdDelete', 'spaceId', spaceId);
      const localVarPath = `/spaces/{spaceId}`.replace(`{${'spaceId'}}`, encodeURIComponent(String(spaceId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} spaceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    spacesSpaceIdGet: async (spaceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'spaceId' is not null or undefined
      assertParamExists('spacesSpaceIdGet', 'spaceId', spaceId);
      const localVarPath = `/spaces/{spaceId}`.replace(`{${'spaceId'}}`, encodeURIComponent(String(spaceId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} spaceId
     * @param {SpacesSpaceIdDeleteRequest} [spacesSpaceIdDeleteRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    spacesSpaceIdPatch: async (
      spaceId: number,
      spacesSpaceIdDeleteRequest?: SpacesSpaceIdDeleteRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'spaceId' is not null or undefined
      assertParamExists('spacesSpaceIdPatch', 'spaceId', spaceId);
      const localVarPath = `/spaces/{spaceId}`.replace(`{${'spaceId'}}`, encodeURIComponent(String(spaceId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        spacesSpaceIdDeleteRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * SpacesApi - functional programming interface
 * @export
 */
export const SpacesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = SpacesApiAxiosParamCreator(configuration);
  return {
    /**
     * Список всех спейсов эксперта <br /><br /> <b>Authentication:</b> required
     * @summary Список всех спейсов эксперта
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async spacesGet(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SpacesGet200ResponseInner>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.spacesGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Создание нового спейса <br /><br /> <b>Authentication:</b> required
     * @summary Создание нового спейса
     * @param {SpacesGetRequest} [spacesGetRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async spacesPost(
      spacesGetRequest?: SpacesGetRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SpacesGet200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.spacesPost(spacesGetRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} spaceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async spacesSpaceIdChallengePostsGet(
      spaceId: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<SpacesSpaceIdChallengePostsPostIdDelete200ResponseInner>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.spacesSpaceIdChallengePostsGet(spaceId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} spaceId
     * @param {SpacesSpaceIdChallengePostsGetRequest} [spacesSpaceIdChallengePostsGetRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async spacesSpaceIdChallengePostsPatch(
      spaceId: number,
      spacesSpaceIdChallengePostsGetRequest?: SpacesSpaceIdChallengePostsGetRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<SpacesSpaceIdChallengePostsPostIdDelete200ResponseInner>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.spacesSpaceIdChallengePostsPatch(
        spaceId,
        spacesSpaceIdChallengePostsGetRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} spaceId
     * @param {SpacesSpaceIdChallengePostsPostIdDeleteRequest} [spacesSpaceIdChallengePostsPostIdDeleteRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async spacesSpaceIdChallengePostsPost(
      spaceId: number,
      spacesSpaceIdChallengePostsPostIdDeleteRequest?: SpacesSpaceIdChallengePostsPostIdDeleteRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SpacesSpaceIdChallengePostsPostIdDelete200ResponseInner>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.spacesSpaceIdChallengePostsPost(
        spaceId,
        spacesSpaceIdChallengePostsPostIdDeleteRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} spaceId
     * @param {number} postId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async spacesSpaceIdChallengePostsPostIdDelete(
      spaceId: number,
      postId: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.spacesSpaceIdChallengePostsPostIdDelete(
        spaceId,
        postId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} spaceId
     * @param {number} postId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async spacesSpaceIdChallengePostsPostIdGet(
      spaceId: number,
      postId: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<SpacesSpaceIdChallengePostsPostIdDelete200ResponseInner>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.spacesSpaceIdChallengePostsPostIdGet(
        spaceId,
        postId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} postId
     * @param {number} spaceId
     * @param {SpacesSpaceIdChallengePostsPostIdDeleteRequest} [spacesSpaceIdChallengePostsPostIdDeleteRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async spacesSpaceIdChallengePostsPostIdPost(
      postId: number,
      spaceId: number,
      spacesSpaceIdChallengePostsPostIdDeleteRequest?: SpacesSpaceIdChallengePostsPostIdDeleteRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SpacesSpaceIdChallengePostsPostIdDelete200Response>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.spacesSpaceIdChallengePostsPostIdPost(
        postId,
        spaceId,
        spacesSpaceIdChallengePostsPostIdDeleteRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} spaceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async spacesSpaceIdChallengeResultsGet(
      spaceId: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<SpacesSpaceIdChallengeResultsGet200ResponseInner>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.spacesSpaceIdChallengeResultsGet(spaceId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Получение списка всех шагов челленджа <br /><br /> <b>Authentication:</b> required
     * @summary Получение списка всех шагов челленджа
     * @param {number} spaceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async spacesSpaceIdChallengeStepsGet(
      spaceId: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SpacesSpaceIdChallengeStepsGet200ResponseInner>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.spacesSpaceIdChallengeStepsGet(spaceId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Update challenge steps <br /><br /> <b>Authentication:</b> required
     * @summary Update challenge steps
     * @param {number} spaceId
     * @param {SpacesSpaceIdChallengeStepsGetRequest} [spacesSpaceIdChallengeStepsGetRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async spacesSpaceIdChallengeStepsPatch(
      spaceId: number,
      spacesSpaceIdChallengeStepsGetRequest?: SpacesSpaceIdChallengeStepsGetRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SpacesSpaceIdChallengeStepsGet200ResponseInner1>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.spacesSpaceIdChallengeStepsPatch(
        spaceId,
        spacesSpaceIdChallengeStepsGetRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} spaceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async spacesSpaceIdClonePost(
      spaceId: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SpacesSpaceIdClonePost200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.spacesSpaceIdClonePost(spaceId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} spaceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async spacesSpaceIdDelete(
      spaceId: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.spacesSpaceIdDelete(spaceId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} spaceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async spacesSpaceIdGet(
      spaceId: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SpacesSpaceIdDelete200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.spacesSpaceIdGet(spaceId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} spaceId
     * @param {SpacesSpaceIdDeleteRequest} [spacesSpaceIdDeleteRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async spacesSpaceIdPatch(
      spaceId: number,
      spacesSpaceIdDeleteRequest?: SpacesSpaceIdDeleteRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SpacesSpaceIdDelete200Response1>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.spacesSpaceIdPatch(
        spaceId,
        spacesSpaceIdDeleteRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * SpacesApi - factory interface
 * @export
 */
export const SpacesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = SpacesApiFp(configuration);
  return {
    /**
     * Список всех спейсов эксперта <br /><br /> <b>Authentication:</b> required
     * @summary Список всех спейсов эксперта
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    spacesGet(options?: any): AxiosPromise<Array<SpacesGet200ResponseInner>> {
      return localVarFp.spacesGet(options).then(request => request(axios, basePath));
    },
    /**
     * Создание нового спейса <br /><br /> <b>Authentication:</b> required
     * @summary Создание нового спейса
     * @param {SpacesGetRequest} [spacesGetRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    spacesPost(spacesGetRequest?: SpacesGetRequest, options?: any): AxiosPromise<SpacesGet200Response> {
      return localVarFp.spacesPost(spacesGetRequest, options).then(request => request(axios, basePath));
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} spaceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    spacesSpaceIdChallengePostsGet(
      spaceId: number,
      options?: any,
    ): AxiosPromise<Array<SpacesSpaceIdChallengePostsPostIdDelete200ResponseInner>> {
      return localVarFp.spacesSpaceIdChallengePostsGet(spaceId, options).then(request => request(axios, basePath));
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} spaceId
     * @param {SpacesSpaceIdChallengePostsGetRequest} [spacesSpaceIdChallengePostsGetRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    spacesSpaceIdChallengePostsPatch(
      spaceId: number,
      spacesSpaceIdChallengePostsGetRequest?: SpacesSpaceIdChallengePostsGetRequest,
      options?: any,
    ): AxiosPromise<Array<SpacesSpaceIdChallengePostsPostIdDelete200ResponseInner>> {
      return localVarFp
        .spacesSpaceIdChallengePostsPatch(spaceId, spacesSpaceIdChallengePostsGetRequest, options)
        .then(request => request(axios, basePath));
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} spaceId
     * @param {SpacesSpaceIdChallengePostsPostIdDeleteRequest} [spacesSpaceIdChallengePostsPostIdDeleteRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    spacesSpaceIdChallengePostsPost(
      spaceId: number,
      spacesSpaceIdChallengePostsPostIdDeleteRequest?: SpacesSpaceIdChallengePostsPostIdDeleteRequest,
      options?: any,
    ): AxiosPromise<SpacesSpaceIdChallengePostsPostIdDelete200ResponseInner> {
      return localVarFp
        .spacesSpaceIdChallengePostsPost(spaceId, spacesSpaceIdChallengePostsPostIdDeleteRequest, options)
        .then(request => request(axios, basePath));
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} spaceId
     * @param {number} postId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    spacesSpaceIdChallengePostsPostIdDelete(spaceId: number, postId: number, options?: any): AxiosPromise<object> {
      return localVarFp
        .spacesSpaceIdChallengePostsPostIdDelete(spaceId, postId, options)
        .then(request => request(axios, basePath));
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} spaceId
     * @param {number} postId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    spacesSpaceIdChallengePostsPostIdGet(
      spaceId: number,
      postId: number,
      options?: any,
    ): AxiosPromise<Array<SpacesSpaceIdChallengePostsPostIdDelete200ResponseInner>> {
      return localVarFp
        .spacesSpaceIdChallengePostsPostIdGet(spaceId, postId, options)
        .then(request => request(axios, basePath));
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} postId
     * @param {number} spaceId
     * @param {SpacesSpaceIdChallengePostsPostIdDeleteRequest} [spacesSpaceIdChallengePostsPostIdDeleteRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    spacesSpaceIdChallengePostsPostIdPost(
      postId: number,
      spaceId: number,
      spacesSpaceIdChallengePostsPostIdDeleteRequest?: SpacesSpaceIdChallengePostsPostIdDeleteRequest,
      options?: any,
    ): AxiosPromise<SpacesSpaceIdChallengePostsPostIdDelete200Response> {
      return localVarFp
        .spacesSpaceIdChallengePostsPostIdPost(postId, spaceId, spacesSpaceIdChallengePostsPostIdDeleteRequest, options)
        .then(request => request(axios, basePath));
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} spaceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    spacesSpaceIdChallengeResultsGet(
      spaceId: number,
      options?: any,
    ): AxiosPromise<Array<SpacesSpaceIdChallengeResultsGet200ResponseInner>> {
      return localVarFp.spacesSpaceIdChallengeResultsGet(spaceId, options).then(request => request(axios, basePath));
    },
    /**
     * Получение списка всех шагов челленджа <br /><br /> <b>Authentication:</b> required
     * @summary Получение списка всех шагов челленджа
     * @param {number} spaceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    spacesSpaceIdChallengeStepsGet(
      spaceId: number,
      options?: any,
    ): AxiosPromise<Array<SpacesSpaceIdChallengeStepsGet200ResponseInner>> {
      return localVarFp.spacesSpaceIdChallengeStepsGet(spaceId, options).then(request => request(axios, basePath));
    },
    /**
     * Update challenge steps <br /><br /> <b>Authentication:</b> required
     * @summary Update challenge steps
     * @param {number} spaceId
     * @param {SpacesSpaceIdChallengeStepsGetRequest} [spacesSpaceIdChallengeStepsGetRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    spacesSpaceIdChallengeStepsPatch(
      spaceId: number,
      spacesSpaceIdChallengeStepsGetRequest?: SpacesSpaceIdChallengeStepsGetRequest,
      options?: any,
    ): AxiosPromise<Array<SpacesSpaceIdChallengeStepsGet200ResponseInner1>> {
      return localVarFp
        .spacesSpaceIdChallengeStepsPatch(spaceId, spacesSpaceIdChallengeStepsGetRequest, options)
        .then(request => request(axios, basePath));
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} spaceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    spacesSpaceIdClonePost(spaceId: number, options?: any): AxiosPromise<SpacesSpaceIdClonePost200Response> {
      return localVarFp.spacesSpaceIdClonePost(spaceId, options).then(request => request(axios, basePath));
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} spaceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    spacesSpaceIdDelete(spaceId: number, options?: any): AxiosPromise<object> {
      return localVarFp.spacesSpaceIdDelete(spaceId, options).then(request => request(axios, basePath));
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} spaceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    spacesSpaceIdGet(spaceId: number, options?: any): AxiosPromise<SpacesSpaceIdDelete200Response> {
      return localVarFp.spacesSpaceIdGet(spaceId, options).then(request => request(axios, basePath));
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} spaceId
     * @param {SpacesSpaceIdDeleteRequest} [spacesSpaceIdDeleteRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    spacesSpaceIdPatch(
      spaceId: number,
      spacesSpaceIdDeleteRequest?: SpacesSpaceIdDeleteRequest,
      options?: any,
    ): AxiosPromise<SpacesSpaceIdDelete200Response1> {
      return localVarFp
        .spacesSpaceIdPatch(spaceId, spacesSpaceIdDeleteRequest, options)
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * SpacesApi - object-oriented interface
 * @export
 * @class SpacesApi
 * @extends {BaseAPI}
 */
export class SpacesApi extends BaseAPI {
  /**
   * Список всех спейсов эксперта <br /><br /> <b>Authentication:</b> required
   * @summary Список всех спейсов эксперта
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SpacesApi
   */
  public spacesGet(options?: AxiosRequestConfig) {
    return SpacesApiFp(this.configuration)
      .spacesGet(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Создание нового спейса <br /><br /> <b>Authentication:</b> required
   * @summary Создание нового спейса
   * @param {SpacesGetRequest} [spacesGetRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SpacesApi
   */
  public spacesPost(spacesGetRequest?: SpacesGetRequest, options?: AxiosRequestConfig) {
    return SpacesApiFp(this.configuration)
      .spacesPost(spacesGetRequest, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {number} spaceId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SpacesApi
   */
  public spacesSpaceIdChallengePostsGet(spaceId: number, options?: AxiosRequestConfig) {
    return SpacesApiFp(this.configuration)
      .spacesSpaceIdChallengePostsGet(spaceId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {number} spaceId
   * @param {SpacesSpaceIdChallengePostsGetRequest} [spacesSpaceIdChallengePostsGetRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SpacesApi
   */
  public spacesSpaceIdChallengePostsPatch(
    spaceId: number,
    spacesSpaceIdChallengePostsGetRequest?: SpacesSpaceIdChallengePostsGetRequest,
    options?: AxiosRequestConfig,
  ) {
    return SpacesApiFp(this.configuration)
      .spacesSpaceIdChallengePostsPatch(spaceId, spacesSpaceIdChallengePostsGetRequest, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {number} spaceId
   * @param {SpacesSpaceIdChallengePostsPostIdDeleteRequest} [spacesSpaceIdChallengePostsPostIdDeleteRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SpacesApi
   */
  public spacesSpaceIdChallengePostsPost(
    spaceId: number,
    spacesSpaceIdChallengePostsPostIdDeleteRequest?: SpacesSpaceIdChallengePostsPostIdDeleteRequest,
    options?: AxiosRequestConfig,
  ) {
    return SpacesApiFp(this.configuration)
      .spacesSpaceIdChallengePostsPost(spaceId, spacesSpaceIdChallengePostsPostIdDeleteRequest, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {number} spaceId
   * @param {number} postId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SpacesApi
   */
  public spacesSpaceIdChallengePostsPostIdDelete(spaceId: number, postId: number, options?: AxiosRequestConfig) {
    return SpacesApiFp(this.configuration)
      .spacesSpaceIdChallengePostsPostIdDelete(spaceId, postId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {number} spaceId
   * @param {number} postId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SpacesApi
   */
  public spacesSpaceIdChallengePostsPostIdGet(spaceId: number, postId: number, options?: AxiosRequestConfig) {
    return SpacesApiFp(this.configuration)
      .spacesSpaceIdChallengePostsPostIdGet(spaceId, postId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {number} postId
   * @param {number} spaceId
   * @param {SpacesSpaceIdChallengePostsPostIdDeleteRequest} [spacesSpaceIdChallengePostsPostIdDeleteRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SpacesApi
   */
  public spacesSpaceIdChallengePostsPostIdPost(
    postId: number,
    spaceId: number,
    spacesSpaceIdChallengePostsPostIdDeleteRequest?: SpacesSpaceIdChallengePostsPostIdDeleteRequest,
    options?: AxiosRequestConfig,
  ) {
    return SpacesApiFp(this.configuration)
      .spacesSpaceIdChallengePostsPostIdPost(postId, spaceId, spacesSpaceIdChallengePostsPostIdDeleteRequest, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {number} spaceId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SpacesApi
   */
  public spacesSpaceIdChallengeResultsGet(spaceId: number, options?: AxiosRequestConfig) {
    return SpacesApiFp(this.configuration)
      .spacesSpaceIdChallengeResultsGet(spaceId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Получение списка всех шагов челленджа <br /><br /> <b>Authentication:</b> required
   * @summary Получение списка всех шагов челленджа
   * @param {number} spaceId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SpacesApi
   */
  public spacesSpaceIdChallengeStepsGet(spaceId: number, options?: AxiosRequestConfig) {
    return SpacesApiFp(this.configuration)
      .spacesSpaceIdChallengeStepsGet(spaceId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Update challenge steps <br /><br /> <b>Authentication:</b> required
   * @summary Update challenge steps
   * @param {number} spaceId
   * @param {SpacesSpaceIdChallengeStepsGetRequest} [spacesSpaceIdChallengeStepsGetRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SpacesApi
   */
  public spacesSpaceIdChallengeStepsPatch(
    spaceId: number,
    spacesSpaceIdChallengeStepsGetRequest?: SpacesSpaceIdChallengeStepsGetRequest,
    options?: AxiosRequestConfig,
  ) {
    return SpacesApiFp(this.configuration)
      .spacesSpaceIdChallengeStepsPatch(spaceId, spacesSpaceIdChallengeStepsGetRequest, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {number} spaceId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SpacesApi
   */
  public spacesSpaceIdClonePost(spaceId: number, options?: AxiosRequestConfig) {
    return SpacesApiFp(this.configuration)
      .spacesSpaceIdClonePost(spaceId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {number} spaceId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SpacesApi
   */
  public spacesSpaceIdDelete(spaceId: number, options?: AxiosRequestConfig) {
    return SpacesApiFp(this.configuration)
      .spacesSpaceIdDelete(spaceId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {number} spaceId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SpacesApi
   */
  public spacesSpaceIdGet(spaceId: number, options?: AxiosRequestConfig) {
    return SpacesApiFp(this.configuration)
      .spacesSpaceIdGet(spaceId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {number} spaceId
   * @param {SpacesSpaceIdDeleteRequest} [spacesSpaceIdDeleteRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SpacesApi
   */
  public spacesSpaceIdPatch(
    spaceId: number,
    spacesSpaceIdDeleteRequest?: SpacesSpaceIdDeleteRequest,
    options?: AxiosRequestConfig,
  ) {
    return SpacesApiFp(this.configuration)
      .spacesSpaceIdPatch(spaceId, spacesSpaceIdDeleteRequest, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * UploadApi - axios parameter creator
 * @export
 */
export const UploadApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Загрузка изображений <br /><br /> <b>Authentication:</b> required
     * @summary Загрузка изображений
     * @param {File} [image]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadImagePost: async (image?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/upload/image`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (image !== undefined) {
        localVarFormParams.append('image', image as any);
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Загрузка видео <br /><br /> <b>Authentication:</b> required
     * @summary Загрузка видео
     * @param {File} [video]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadVideoPost: async (video?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/upload/video`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (video !== undefined) {
        localVarFormParams.append('video', video as any);
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * UploadApi - functional programming interface
 * @export
 */
export const UploadApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = UploadApiAxiosParamCreator(configuration);
  return {
    /**
     * Загрузка изображений <br /><br /> <b>Authentication:</b> required
     * @summary Загрузка изображений
     * @param {File} [image]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async uploadImagePost(
      image?: File,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadImagePost200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.uploadImagePost(image, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Загрузка видео <br /><br /> <b>Authentication:</b> required
     * @summary Загрузка видео
     * @param {File} [video]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async uploadVideoPost(
      video?: File,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadImagePost200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.uploadVideoPost(video, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * UploadApi - factory interface
 * @export
 */
export const UploadApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = UploadApiFp(configuration);
  return {
    /**
     * Загрузка изображений <br /><br /> <b>Authentication:</b> required
     * @summary Загрузка изображений
     * @param {File} [image]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadImagePost(image?: File, options?: any): AxiosPromise<UploadImagePost200Response> {
      return localVarFp.uploadImagePost(image, options).then(request => request(axios, basePath));
    },
    /**
     * Загрузка видео <br /><br /> <b>Authentication:</b> required
     * @summary Загрузка видео
     * @param {File} [video]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadVideoPost(video?: File, options?: any): AxiosPromise<UploadImagePost200Response> {
      return localVarFp.uploadVideoPost(video, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * UploadApi - object-oriented interface
 * @export
 * @class UploadApi
 * @extends {BaseAPI}
 */
export class UploadApi extends BaseAPI {
  /**
   * Загрузка изображений <br /><br /> <b>Authentication:</b> required
   * @summary Загрузка изображений
   * @param {File} [image]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UploadApi
   */
  public uploadImagePost(image?: File, options?: AxiosRequestConfig) {
    return UploadApiFp(this.configuration)
      .uploadImagePost(image, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Загрузка видео <br /><br /> <b>Authentication:</b> required
   * @summary Загрузка видео
   * @param {File} [video]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UploadApi
   */
  public uploadVideoPost(video?: File, options?: AxiosRequestConfig) {
    return UploadApiFp(this.configuration)
      .uploadVideoPost(video, options)
      .then(request => request(this.axios, this.basePath));
  }
}
