import { ReactElement } from 'react';
import { ICON_DEFAULTS, IconProps } from './types';

export function IconArrowBendUpLeft({
  size = ICON_DEFAULTS.size,
  color = ICON_DEFAULTS.color,
  className = ICON_DEFAULTS.className,
}: IconProps): ReactElement {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      className={className}
    >
      <path
        d="M5.625 10.6875L2.25 7.3125L5.625 3.9375"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.75 14.0625C15.75 12.2723 15.0388 10.5554 13.773 9.28953C12.5071 8.02366 10.7902 7.3125 9 7.3125H2.25"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
