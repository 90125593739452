import { Button } from '@sp/ui/elements';
import { GRAY_PIXEL_IMG_SRC } from '@sp/util/files';
import { cls, isNotNullish } from '@sp/util/helpers';
import { useCallback, useEffect, VFC } from 'react';
import { useFilePicker } from 'use-file-picker';

export const VideoPicker: VFC<
  Readonly<{
    currentUrl?: string;
    isLoading?: boolean;
    onChange: (file: File) => void;
    onCancel?: () => void;
    showPlaceholder?: boolean;
  }>
> = ({ currentUrl, isLoading = false, onChange, onCancel, showPlaceholder = false }) => {
  const isSet = isNotNullish(currentUrl);

  const [openFilePicker, { plainFiles, clear }] = useFilePicker({
    accept: 'video/*',
    multiple: false,
    readFilesContent: false,
  });

  const selectFile = useCallback(() => {
    if (isLoading) return;
    openFilePicker();
  }, [isLoading, openFilePicker]);

  useEffect(() => {
    if (plainFiles.length === 0) return;
    onChange(plainFiles[0]);
    clear();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onChange, plainFiles]);

  return (
    <>
      <div className={cls('w-full rounded-xl', isLoading && 'animate-pulse')}>
        {currentUrl ? (
          <video
            src={currentUrl + '#t=0.01'} // set first frame for ios
            controls
            className="w-full object-contain bg-[#c2c2c2]"
          />
        ) : (
          showPlaceholder && (
            <div role="button" onClick={selectFile} className="w-full aspect-square aspect-ratio-wrap">
              <img src={GRAY_PIXEL_IMG_SRC} alt="" />
            </div>
          )
        )}
      </div>

      <div className={showPlaceholder || currentUrl ? 'mt-2' : undefined}>
        {onCancel && isLoading ? (
          <Button color="warning" block loading={true} onClick={onCancel}>
            cancel
          </Button>
        ) : (
          <Button color={isSet ? undefined : 'primary'} block loading={isLoading} disableOnLoad onClick={selectFile}>
            {isSet ? 'update' : 'add'}
          </Button>
        )}
      </div>
    </>
  );
};
