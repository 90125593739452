import { useUser } from '@sp/data/auth';
import { DirectMessageUser } from '@sp/data/bif';
import { GlobalMediaPlayerModel } from '@sp/data/global';
import { useSetAnalyticsContext } from '@sp/feature/analytics';
import { Chat, MessageExtraContent } from '@sp/feature/chat';
import { UseSpacialBadge } from '@sp/feature/space';
import { ChatChannelModel } from '@sp/feature/stream-chat';
import { Avatar, SpinnerPage } from '@sp/ui/elements';
import { IconCaretLeft } from '@sp/ui/icons';
import { isNotNullish, Nullable } from '@sp/util/helpers';
import { useStore } from 'effector-react';
import { FC, useEffect, useMemo } from 'react';
import { DM_CHANNEL } from './dm-model';

const Header: FC<{
  name: string;
  avatar?: Nullable<string>;
  onClick: () => void;
}> = ({ name, avatar, onClick }) => {
  return (
    <div className="flex border-b border-stripe justify-start items-center px-2 py-2 pr-6 w-full">
      <button type="button" title="Back" onClick={onClick} className="text-secondary py-2 pl-3 pr-2">
        <IconCaretLeft size={24} />
      </button>
      <div className="mr-2">
        <Avatar size="sm" src={avatar} />
      </div>
      <div className="flex flex-col flex-1 overflow-x-hidden">
        <h1 className="m-0 text-ellipsis whitespace-nowrap overflow-x-hidden text-base">{name}</h1>
      </div>
    </div>
  );
};

const DmChat: FC<{ chatModel: ChatChannelModel; chatPartner: DirectMessageUser | null }> = ({
  chatModel,
  chatPartner,
}) => {
  const user = useUser();
  const [specialBadgeContent] = UseSpacialBadge(null);
  const messageExtraContent: MessageExtraContent = useMemo(
    () => ({ afterUserName: specialBadgeContent }),
    [specialBadgeContent],
  );

  return (
    <div className="flex-1">
      <Chat
        chatModel={chatModel}
        fullAcceptedFileAttachments={user.isAdmin}
        messageExtraContent={messageExtraContent}
        welcomeMessage={{ show: true, partner: chatPartner }}
      />
    </div>
  );
};

export const DmLayout: FC<{ userId: number; onBackClick: () => void }> = ({ userId, onBackClick }) => {
  const meUser = useUser();
  const dmChannel = useStore(DM_CHANNEL.$dmInstance);
  const dmChat = useStore(DM_CHANNEL.$dmChat);

  useEffect(() => {
    DM_CHANNEL.dmCreated(userId);
    return () => {
      DM_CHANNEL.instanceDestroyed();
    };
  }, [userId]);
  useEffect(() => GlobalMediaPlayerModel.close, []);

  const chatPartner = useMemo(
    () => dmChat?.dmUsers.find(u => u.id !== meUser.id) ?? null,
    [dmChat?.dmUsers, meUser.id],
  );

  useSetAnalyticsContext(
    isNotNullish(dmChat) && isNotNullish(chatPartner)
      ? {
          'Dm Partner ID': chatPartner.id.toString(),
          'Dm Partner Name': chatPartner.name.toString(),
          'Dm ID': dmChat.id.toString(),
          Type: 'Dm',
        }
      : null,
  );

  return dmChannel ? (
    <div className="h-full flex flex-col">
      <Header avatar={chatPartner?.avatar?.url} onClick={onBackClick} name={chatPartner?.name ?? '...'} />
      <DmChat chatModel={dmChannel} chatPartner={chatPartner} />
    </div>
  ) : (
    <SpinnerPage />
  );
};
