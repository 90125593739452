import {
  CreateChallengeSpaceDto,
  createSpace,
  getChallengePosts,
  getChallengeSteps,
  getSpace,
  OwnedChallengeSpace,
  OwnedChallengeSpacePost,
  OwnedChallengeSpaceStep,
  patchChallengePosts,
  patchChallengeSteps,
  patchSpace,
  SPACE_PAYMENT_TYPE,
  SPACE_TYPE,
} from '@sp/data/bif';
import { hoursToMilliseconds } from 'date-fns';
import { createDomain, forward, merge, restore, sample } from 'effector';
import { createGate } from 'effector-react';
import { pending } from 'patronum';
import { CreateChallengeDto, SaveChallengeDto } from './types';
import {
  fieldReducer,
  isChallenge,
  isPostsChanged,
  isStepsChanged,
  updateChallengeReducer,
  updatePostsReducer,
  updateStepsReducer,
} from './utils';

const domain = createDomain('challenge-editor');
const ChallengeEditorGate = createGate<number>();
const ChallengeCreationGate = createGate();

// CREATE CHALLENGE
const createChallengeStarted = domain.createEvent<CreateChallengeDto>();

const createChallengeFx = domain.createEffect(
  async ({
    title,
    slug,
    price,
    coverFileId,
    startAt,
    stepsCount,
    offerDescription,
    offerSummary,
    isProgram,
  }: CreateChallengeDto) => {
    const space: CreateChallengeSpaceDto = {
      title,
      slug,
      price,
      coverFileId,
      config: {
        challenge: {
          finishTimestamp: startAt + stepsCount * hoursToMilliseconds(24),
          offerDescription,
          offerSummary,
          isProgram,
        },
      },
      payment_type: SPACE_PAYMENT_TYPE.fixed_price,
      type: SPACE_TYPE.challenge,
    };
    const { id: challengeId } = await createSpace({ space });

    await patchChallengeSteps({
      challengeId,
      patch: {
        deleted: [],
        updated: [],
        created: Array.from({ length: stepsCount }, (_, i) => ({
          startAt: startAt + hoursToMilliseconds(24) * i,
          isEnabled: true,
          isSignificant: true,
          estimatedEffort: 0,
          title: i === 0 ? 'First day' : 'Next day',
        })),
      },
    });

    return challengeId;
  },
);

forward({ from: createChallengeStarted, to: createChallengeFx });

const challengeCreated = createChallengeFx.doneData;
const $challengeCreateError = restore(createChallengeFx.failData, null)
  .reset(createChallengeStarted)
  .reset(createChallengeStarted);
const $challengeCreateInProgress = createChallengeFx.pending;
// END CREATE CHALLENGE

// EDIT CHALLENGE

const saveChallengeStarted = domain.createEvent<SaveChallengeDto>();

const loadChallengeFx = domain.createEffect((challengeId: number) => getSpace({ spaceId: challengeId }));
const loadStepsFx = domain.createEffect((challengeId: number) => getChallengeSteps({ challengeId }));
const loadPostsFx = domain.createEffect((challengeId: number) => getChallengePosts({ challengeId }));

const saveChallengeFx = domain.createEffect(
  async ({
    challenge,
    steps,
    posts,
    changes,
  }: {
    challenge: OwnedChallengeSpace;
    steps: ReadonlyArray<OwnedChallengeSpaceStep>;
    posts: ReadonlyArray<OwnedChallengeSpacePost>;
    changes: SaveChallengeDto;
  }) => {
    const result = { challenge, steps, posts };

    if (isStepsChanged(steps, changes.steps)) {
      result.steps = await patchChallengeSteps(updateStepsReducer(steps, changes));
    }
    if (isPostsChanged(posts, changes.posts)) {
      result.posts = await patchChallengePosts(updatePostsReducer(posts, changes));
    }
    const updatedChallenge = await patchSpace(updateChallengeReducer(challenge, changes));
    if (isChallenge(updatedChallenge)) {
      result.challenge = updatedChallenge;
    }

    return result;
  },
);

const $challenge = restore(
  sample({
    clock: loadChallengeFx.doneData,
    filter: isChallenge,
  }),
  null,
)
  .on(saveChallengeFx.doneData, (state, { challenge }) => ({ ...state, ...challenge }))
  .reset(ChallengeEditorGate.close);
const $steps = domain
  .createStore<ReadonlyArray<OwnedChallengeSpaceStep>>([])
  .on(loadStepsFx.doneData, fieldReducer)
  .on(saveChallengeFx.doneData, (_, { steps }) => steps)
  .reset(ChallengeEditorGate.close);
const $posts = domain
  .createStore<ReadonlyArray<OwnedChallengeSpacePost>>([])
  .on(loadPostsFx.doneData, fieldReducer)
  .on(saveChallengeFx.doneData, (_, { posts }) => posts)
  .reset(ChallengeEditorGate.close);

forward({ from: ChallengeEditorGate.open, to: [loadChallengeFx, loadStepsFx, loadPostsFx] });

sample({
  clock: saveChallengeStarted,
  source: { challenge: $challenge, steps: $steps, posts: $posts },
  fn: ({ challenge, steps, posts }, changes) => ({
    challenge: challenge as OwnedChallengeSpace,
    steps,
    posts,
    changes,
  }),
  target: saveChallengeFx,
});

const $challengeSaveInProgress = saveChallengeFx.pending;

const $challengeSaveError = restore(saveChallengeFx.failData, null)
  .reset(saveChallengeStarted)
  .reset(ChallengeEditorGate.close);

const $challengeLoadInProgress = pending({
  effects: [loadChallengeFx, loadPostsFx, loadStepsFx],
});

const $challengeLoadError = restore(
  merge([loadChallengeFx.failData, loadPostsFx.failData, loadStepsFx.failData]),
  null,
).reset(ChallengeEditorGate.close);
// END EDIT CHALLENGE

export const CHALLENGE_EDITOR_MODEL = {
  ChallengeEditorGate,
  ChallengeCreationGate,
  createChallengeStarted,
  challengeCreated,
  $challengeCreateError,
  $challengeCreateInProgress,

  $challenge,
  $steps,
  $posts,
  $challengeLoadInProgress,
  $challengeLoadError,
  $challengeSaveInProgress,
  $challengeSaveError,
  saveChallengeStarted,
};
