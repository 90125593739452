import { PURCHASE_MODEL } from '@sp/data/global';
import { GoToAccountButton } from '@sp/feature/native-elements';
import { ErrorMessage } from '@sp/ui/elements';
import { Modal } from '@sp/ui/modal';
import { isNotNullish } from '@sp/util/helpers';
import { createEvent, restore, sample } from 'effector';
import { useStore } from 'effector-react';
import { FC } from 'react';

const setError = createEvent<string>();
const reset = createEvent();

const $lastPaymentError = restore(setError, null).reset(reset);

sample({
  clock: PURCHASE_MODEL.purchaseRejected.map(({ error }) => error),
  target: setError,
});

export const PaymentFailedModal: FC = () => {
  const lastPaymentError = useStore($lastPaymentError);
  const isOpened = isNotNullish(lastPaymentError);

  return (
    <Modal isOpen={isOpened} onClose={reset} withBackdrop closeOnBackdropClick>
      <div className="text-center">
        <ErrorMessage>
          Something went wrong.
          <div className="text-secondary text-xs">{lastPaymentError}</div>
        </ErrorMessage>
        <p className="mt-8 mb-10">
          <GoToAccountButton type="manage" onGoToAccount={reset} />
        </p>
      </div>
    </Modal>
  );
};
