import {
  createPost,
  deletePost,
  EventTypeUnion,
  getPosts,
  PlatformPost,
  PlatformPostData,
  PlatformPostType,
  PlatformPostTypeUnion,
  publishPost,
  publishTestPost,
  reorderPosts,
  updatePost,
} from '@sp/data/bif';
import { createDomain, restore, sample } from 'effector';
import groupBy from 'lodash/groupBy';

const platformDomain = createDomain();
const postsGot = platformDomain.createEvent();
const postCreated = platformDomain.createEvent<PlatformPostData>();
const postUpdated = platformDomain.createEvent<{ postId: number; data: PlatformPostData }>();
const postDeleted = platformDomain.createEvent<number>();
const postPublished = platformDomain.createEvent<number>();
const postPublishedTest = platformDomain.createEvent<number>();
const postToEditSet = platformDomain.createEvent<PlatformPost>();
const postToEditReset = platformDomain.createEvent();
const postCreationFinished = platformDomain.createEvent<boolean>();
const postCreationFinishedReset = platformDomain.createEvent();
const upOrder = platformDomain.createEvent<{ postId: number; type: PlatformPostTypeUnion; event?: EventTypeUnion }>();
const dawnOrder = platformDomain.createEvent<{ postId: number; type: PlatformPostTypeUnion; event?: EventTypeUnion }>();

const $posts = platformDomain.createStore<PlatformPost[]>([]);
const $groupedPosts = $posts.map(posts => {
  const groupedPosts = groupBy(posts, 'type');
  const afterEventPosts = groupedPosts['afterEvent'] || [];
  const groupedAfterEventPosts = groupBy(afterEventPosts, 'event') as Record<string, PlatformPost[]>;
  return {
    onboarding: groupedPosts['onboarding'] || [],
    realtime: groupedPosts['realtime'] || [],
    deferred: groupedPosts['deferred'] || [],
    afterEvent: groupedAfterEventPosts,
  };
});
const $postToEdit = restore(postToEditSet, null).reset(postToEditReset);
const $postCreationFinished = restore(postCreationFinished, false).reset(postCreationFinishedReset);

const loadPostsFx = platformDomain.createEffect(() => {
  return getPosts();
});

const createPostsFx = platformDomain.createEffect((data: PlatformPostData) => {
  return createPost(data);
});

const updatePostsFx = platformDomain.createEffect(({ data, postId }: { data: PlatformPostData; postId: number }) => {
  return updatePost(postId, data);
});

const deletePostsFx = platformDomain.createEffect(async (postId: number) => {
  await deletePost(postId);
  return postId;
});

const publishPostsFx = platformDomain.createEffect((postId: number) => {
  return publishPost(postId);
});

const publishTestPostsFx = platformDomain.createEffect((postId: number) => {
  return publishTestPost(postId);
});

const upOrderFx = platformDomain.createEffect(
  async ({
    groupedPosts,
    data,
  }: {
    groupedPosts: {
      onboarding: PlatformPost[];
      realtime: PlatformPost[];
      afterEvent: Record<string, PlatformPost[]>;
      deferred: PlatformPost[];
    };
    data: { postId: number; type: PlatformPostTypeUnion; event?: EventTypeUnion };
  }) => {
    let group: PlatformPost[] = [];

    if (data.type === PlatformPostType.afterEvent && data.event) {
      group = groupedPosts[data.type][data.event];
    } else if (data.type !== PlatformPostType.afterEvent) {
      group = groupedPosts[data.type];
    }
    const postIndex = group.findIndex(p => p.id === data.postId);

    if (postIndex > 0) {
      const prevPost = group[postIndex - 1];
      const curPost = group[postIndex];
      const data = [
        { id: prevPost.id, order: curPost.order },
        { id: curPost.id, order: prevPost.order },
      ];
      await reorderPosts(data);
      return true;
    }
    return false;
  },
);

const dawnOrderFx = platformDomain.createEffect(
  async ({
    groupedPosts,
    data,
  }: {
    groupedPosts: {
      onboarding: PlatformPost[];
      realtime: PlatformPost[];
      afterEvent: Record<string, PlatformPost[]>;
      deferred: PlatformPost[];
    };
    data: { postId: number; type: PlatformPostTypeUnion; event?: EventTypeUnion };
  }) => {
    let group: PlatformPost[] = [];

    if (data.type === PlatformPostType.afterEvent && data.event) {
      group = groupedPosts[data.type][data.event];
    } else if (data.type !== PlatformPostType.afterEvent) {
      group = groupedPosts[data.type];
    }
    const postIndex = group.findIndex(p => p.id === data.postId);

    if (postIndex !== -1 && postIndex !== group.length - 1) {
      const nextPost = group[postIndex + 1];
      const curPost = group[postIndex];
      const data = [
        { id: nextPost.id, order: curPost.order },
        { id: curPost.id, order: nextPost.order },
      ];
      await reorderPosts(data);
      return true;
    }
    return false;
  },
);

sample({ clock: postsGot, target: loadPostsFx });
sample({ source: loadPostsFx.doneData, target: $posts });

sample({
  source: $groupedPosts,
  clock: upOrder,
  fn: (groupedPosts, data) => ({ groupedPosts, data }),
  target: upOrderFx,
});
sample({
  source: $groupedPosts,
  clock: dawnOrder,
  fn: (groupedPosts, data) => ({ groupedPosts, data }),
  target: dawnOrderFx,
});
sample({ clock: [dawnOrderFx.doneData, upOrderFx.doneData], filter: success => success, target: postsGot });

sample({ clock: postCreated, target: createPostsFx });
sample({ clock: postUpdated, target: updatePostsFx });
sample({ clock: [createPostsFx.doneData, updatePostsFx.doneData], fn: () => true, target: postCreationFinished });
sample({ clock: postDeleted, target: deletePostsFx });
sample({
  source: $posts,
  clock: deletePostsFx.doneData,
  fn: (posts, id) => {
    return posts.filter(post => post.id !== id);
  },
  target: $posts,
});
sample({ clock: postPublished, target: publishPostsFx });
sample({
  source: $posts,
  clock: publishPostsFx.doneData,
  fn: (posts, newPost) => {
    return posts.map(post => (post.id !== newPost.id ? post : newPost));
  },
  target: $posts,
});
sample({ clock: postPublishedTest, target: publishTestPostsFx });
export const PLATFORM_EDITOR_MODEL = {
  postsGot,
  postDeleted,
  postPublished,
  postPublishedTest,
  postCreated,
  postUpdated,
  postToEditSet,
  postToEditReset,
  upOrder,
  dawnOrder,
  $posts,
  $groupedPosts,
  $postToEdit,
  $postCreationFinished,
  postCreationFinishedReset,
  deleting: deletePostsFx.pending,
  creating: createPostsFx.pending,
  updating: updatePostsFx.pending,
  publishing: publishPostsFx.pending,
  testPublishing: publishTestPostsFx.pending,
  orderingUp: upOrderFx.pending,
  orderingDawn: dawnOrderFx.pending,
};
