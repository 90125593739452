import { AUTH_MODEL } from '@sp/data/auth';
import { useIsInsidePath } from '@sp/util/helpers';
import { ROUTES } from '@sp/util/router';
import { useStore } from 'effector-react';

export function useIsShowNav(): boolean {
  const isLoggedIn = useStore(AUTH_MODEL.$isReady);
  const isInChallenge = useIsInsidePath(`${ROUTES.CHALLENGE_PAGE_URL}/:${ROUTES.SPACE_ID_PARAM}`);
  const isInDirectMessages = useIsInsidePath(ROUTES.DM);
  const isInTrailer = useIsInsidePath(`${ROUTES.TRAILER_PAGE_URL}/:${ROUTES.SPACE_ID_PARAM}`);
  const isInLive = useIsInsidePath(`${ROUTES.LIVE_PAGE_URL}/:${ROUTES.SPACE_ID_PARAM}`);
  const isInChannel = useIsInsidePath(`${ROUTES.CHANNEL_PAGE_URL}/:${ROUTES.SPACE_ID_PARAM}`);
  const isInCommunity = useIsInsidePath(`${ROUTES.COMMUNITY_PAGE_URL}/:${ROUTES.SPACE_ID_PARAM}`);

  if (!isLoggedIn || isInChallenge || isInDirectMessages || isInTrailer || isInLive || isInChannel || isInCommunity) {
    return false;
  }

  return true;
}
