import { ANALYTICS } from '@sp/feature/analytics';
import { NAVIGATE_BACK_MODEL, useSetPreviousUrl } from '@sp/feature/back-button';
import { UserProfileCard } from '@sp/feature/profile';
import { parseFromParam, ROUTES } from '@sp/util/router';
import { useEffect, VFC } from 'react';
import { useSearchParams } from 'react-router-dom';
import { PlatformChannel } from './platform-channel';

export const PlatformMessageScreen: VFC = () => {
  const backHandler = () => NAVIGATE_BACK_MODEL.navigatedBack();
  useSetPreviousUrl(ROUTES.HOME_URL);

  const [searchParams] = useSearchParams();
  const fromParam = parseFromParam(searchParams);

  useEffect(() => {
    ANALYTICS.platformChannelCheckTracked({
      From: fromParam,
    });
  }, [fromParam]);

  return (
    <>
      <PlatformChannel onBackClick={backHandler} />
      <UserProfileCard cardContext="dm" />
    </>
  );
};
