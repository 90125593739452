import { Button, Input, TextArea } from '@sp/ui/elements';
import { ImageCropperMode } from '@sp/ui/image-cropper';
import { ADAPTERS, bindFieldValue, bindFieldValueWithAdapter } from '@sp/util/effector-forms';
import { ConnectedField, useForm } from 'effector-forms';
import { useStore } from 'effector-react';
import { ReactElement, useCallback, useMemo, VFC } from 'react';
import { ImagePicker } from '../../../utils/image-picker';
import { DETAILS_FORM_MODEL } from './details-form-model';
import { VideoPicker } from './video-picker';

function getInvalidClassName<T>(field: ConnectedField<T>) {
  return field.isValid ? '' : 'ring-1 ring-alert';
}

function FieldError<T>({ field }: Readonly<{ field: ConnectedField<T> }>): ReactElement | null {
  if (field.isValid) {
    return null;
  }
  return <span className="text-alert">{field.firstError?.errorText}</span>;
}

export const DetailsForm: VFC<Readonly<{ canUploadRecording: boolean }>> = ({ canUploadRecording }) => {
  const details = useForm(DETAILS_FORM_MODEL.form);

  const isVideoRecordingUploading = useStore(DETAILS_FORM_MODEL.uploader.videoRecording.$isUploading);
  const isVideoRecordingCoverUploading = useStore(DETAILS_FORM_MODEL.uploader.videoRecordingCover.$isUploading);

  const isVideoRecordingSet = useMemo(
    () => details.fields.videoRecording.value !== null,
    [details.fields.videoRecording.value],
  );

  const handleResetVideoRecording = useCallback(
    () => details.fields.videoRecording.reset(),
    [details.fields.videoRecording],
  );

  return (
    <div className="flex gap-4 flex-col md:flex-row">
      <div className="flex flex-col basis-1/2">
        <div className="flex flex-col break-inside-avoid-column">
          <label htmlFor="title">Title</label>
          <Input
            id="title"
            type="text"
            placeholder="My livestream"
            className={getInvalidClassName(details.fields.title)}
            {...bindFieldValue(details.fields.title)}
          />
          <FieldError field={details.fields.title} />
        </div>

        <div className="flex flex-col break-inside-avoid-column mt-4">
          <label htmlFor="slug">Slug</label>
          <Input
            id="slug"
            type="text"
            placeholder="my-livestream"
            className={getInvalidClassName(details.fields.slug)}
            {...bindFieldValue(details.fields.slug)}
          />
          <FieldError field={details.fields.slug} />
        </div>

        <div className="flex flex-col break-inside-avoid-column mt-4">
          <label className="mb-1">Cover</label>
          <ImagePicker
            currentUrl={details.fields.cover.value?.url}
            onChange={DETAILS_FORM_MODEL.uploader.cover.upload}
            isLoading={useStore(DETAILS_FORM_MODEL.uploader.cover.$isUploading)}
            cropMode={ImageCropperMode.threeToFour}
          />
          {!details.fields.cover.isValid && <span className="text-alert mt-4">Cover is required.</span>}
        </div>

        <div className="flex flex-col break-inside-avoid-column mt-4">
          <label htmlFor="price">Price (USD $)</label>
          <Input
            id="price"
            type="number"
            min="0.01"
            step="0.01"
            placeholder="4,99"
            className={getInvalidClassName(details.fields.price)}
            {...bindFieldValueWithAdapter(details.fields.price, ADAPTERS.money)}
          />
          <FieldError field={details.fields.price} />
        </div>

        <div className="flex flex-col break-inside-avoid-column mt-4">
          <label htmlFor="scheduled-date">Scheduled Date</label>
          <Input
            id="scheduled-date"
            type="datetime-local"
            pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}"
            className={getInvalidClassName(details.fields.startAt)}
            {...bindFieldValueWithAdapter(details.fields.startAt, ADAPTERS.timestamp)}
          />
          <FieldError field={details.fields.startAt} />
        </div>

        <div className="flex flex-col break-inside-avoid-column mt-4">
          <label htmlFor="duration">Duration (minutes)</label>
          <Input
            id="duration"
            type="number"
            min={0}
            step={1}
            className={getInvalidClassName(details.fields.duration)}
            {...bindFieldValueWithAdapter(details.fields.duration, ADAPTERS.integer)}
          />
          <FieldError field={details.fields.duration} />
        </div>

        <div className="flex flex-col break-inside-avoid-column mt-4">
          <label htmlFor="zoomUrl">Zoom Url</label>
          <Input
            id="zoomUrl"
            type="url"
            placeholder="https://zoom.us/j/123456789?pwd=YmRr09"
            className={getInvalidClassName(details.fields.linkToZoom)}
            {...bindFieldValue(details.fields.linkToZoom)}
          />
          <FieldError field={details.fields.linkToZoom} />
        </div>

        <div className="flex flex-col break-inside-avoid-column mt-4">
          <label htmlFor="requirements">Requirements</label>
          <TextArea
            id="requirements"
            minRows={4}
            maxRows={10}
            className={getInvalidClassName(details.fields.requirements)}
            {...bindFieldValue(details.fields.requirements)}
          />
          <FieldError field={details.fields.requirements} />
        </div>

        <div className="flex flex-col break-inside-avoid-column mt-4">
          <label htmlFor="about-creator">About Creator</label>
          <TextArea
            id="about-creator"
            minRows={4}
            maxRows={10}
            className={getInvalidClassName(details.fields.creatorDescription)}
            {...bindFieldValue(details.fields.creatorDescription)}
          />
          <FieldError field={details.fields.creatorDescription} />
        </div>

        <div className="flex flex-col break-inside-avoid-column mt-4">
          <label htmlFor="about-live">About Live</label>
          <TextArea
            id="about-live"
            minRows={4}
            maxRows={10}
            className={getInvalidClassName(details.fields.description)}
            {...bindFieldValue(details.fields.description)}
          />
          <FieldError field={details.fields.description} />
        </div>

        <div className="flex flex-col break-inside-avoid-column mt-4">
          <label htmlFor="offer-description">Offer Description</label>
          <TextArea
            id="offer-description"
            minRows={4}
            maxRows={10}
            className={getInvalidClassName(details.fields.offerDescription)}
            {...bindFieldValue(details.fields.offerDescription)}
          />
          <FieldError field={details.fields.offerDescription} />
        </div>

        <div className="flex flex-col break-inside-avoid-column mt-4">
          <label htmlFor="offer-summary">Offer Summary</label>
          <TextArea
            id="offer-summary"
            minRows={4}
            maxRows={10}
            className={getInvalidClassName(details.fields.offerSummary)}
            {...bindFieldValue(details.fields.offerSummary)}
          />
          <FieldError field={details.fields.offerSummary} />
        </div>
      </div>

      <div className="flex flex-col basis-1/2">
        {canUploadRecording && (
          <>
            <div className="flex flex-col break-inside-avoid-column">
              <div className="mb-2 flex flex-row justify-between items-center">
                <label>Video Recording</label>
                {isVideoRecordingSet && (
                  <Button size="sm" onClick={handleResetVideoRecording}>
                    remove
                  </Button>
                )}
              </div>
              <VideoPicker
                currentUrl={details.fields.videoRecording.value?.url}
                onChange={DETAILS_FORM_MODEL.uploader.videoRecording.upload}
                isLoading={isVideoRecordingUploading}
                onCancel={DETAILS_FORM_MODEL.uploader.videoRecording.reset}
              />
            </div>

            {isVideoRecordingSet && (
              <div className="flex flex-col break-inside-avoid-column mt-4">
                <label className="mb-1">Video Recording Cover</label>
                <ImagePicker
                  currentUrl={details.fields.videoRecordingCover.value?.url}
                  onChange={DETAILS_FORM_MODEL.uploader.videoRecordingCover.upload}
                  isLoading={isVideoRecordingCoverUploading}
                  cropMode={ImageCropperMode.squareImage}
                />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};
