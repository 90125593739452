import { USER_MODEL } from '@sp/data/auth';
import { useStore } from 'effector-react';
import { createGlobalState } from 'react-hooks-global-state';

interface ExpertSlugGlobalState {
  expertSlug: string | null;
}

const { useGlobalState } = createGlobalState<ExpertSlugGlobalState>({
  expertSlug: null,
} as const);

export const useExpertSlugGlobalState = () => useGlobalState('expertSlug');

export function useIsUserInsideOwnExpertRoute(): boolean {
  const user = useStore(USER_MODEL.$user);
  const [expertSlug] = useExpertSlugGlobalState();

  if (user == null || expertSlug == null) {
    return false;
  }

  return user.slug === expertSlug;
}
