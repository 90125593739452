import { openGalleryHandler } from '@sp/feature/back-button';
import { PHOTO_GALLERY_OPTIONS, PhotoswipeGallery } from '@sp/ui/gallery';
import { GroupedAttachments } from '@sp/util/chat-attachments';
import { cls } from '@sp/util/helpers';
import { $isNativeApp } from '@sp/util/native';
import { ATTACHMENT_TYPE } from '@sp/util/stream-chat';
import { useStore } from 'effector-react';
import { useCallback } from 'react';
import { AttachmentAudioView } from './attachments/attachment-audio-view';
import { AttachmentFileView } from './attachments/attachment-file-view';
import { AttachmentImageView } from './attachments/attachment-image-view';
import { AttachmentLinkView } from './attachments/attachment-link-view';
import { AttachmentAudioPlug, AttachmentImagePlug, AttachmentVideoPlug } from './attachments/attachment-plugs';
import { AttachmentVideoView } from './attachments/attachment-video-view';

const groupClasses = 'flex gap-2 py-1 last:mb-0 flex-wrap';

export const ChatMessageAttachmentsView = ({
  media,
  audio,
  files,
  links,
  authorName,
  locked = false,
}: GroupedAttachments &
  Readonly<{
    authorName: string;
    locked?: boolean;
  }>) => {
  const isMediaLarge = useCallback((index: number) => (media ?? []).length % 2 !== 0 && index === 0, [media]);
  const isNative = useStore($isNativeApp);

  return (
    <div>
      {media && media.length > 0 && (
        <PhotoswipeGallery
          options={{
            ...PHOTO_GALLERY_OPTIONS,
            ...(isNative
              ? {}
              : { mainClass: `${PHOTO_GALLERY_OPTIONS.mainClass} desktop-chat-message-attachments-gallery` }),
          }}
          onOpen={openGalleryHandler}
        >
          <div className="grid grid-cols-2 gap-2 py-1">
            {media.map((attachment, index) => {
              switch (attachment.type) {
                case ATTACHMENT_TYPE.image:
                  return (
                    <div key={index} className={cls(isMediaLarge(index) ? 'col-span-2' : 'col-span-1')}>
                      {locked ? <AttachmentImagePlug /> : <AttachmentImageView attachment={attachment} />}
                    </div>
                  );
                case ATTACHMENT_TYPE.video:
                  return (
                    <div key={index} className={isMediaLarge(index) ? 'col-span-2' : 'col-span-1'}>
                      {locked ? (
                        <AttachmentVideoPlug />
                      ) : (
                        <AttachmentVideoView attachment={attachment} big={isMediaLarge(index)} />
                      )}
                    </div>
                  );
              }
            })}
          </div>
        </PhotoswipeGallery>
      )}

      {audio && audio.length > 0 && (
        <div className={groupClasses}>
          {audio.map((attachment, index) =>
            locked ? (
              <AttachmentAudioPlug key={index} />
            ) : (
              <AttachmentAudioView key={index} attachment={attachment} authorName={authorName} />
            ),
          )}
        </div>
      )}

      {!locked && files && files.length > 0 && (
        <div className={groupClasses}>
          {files.map((attachment, index) => (
            <AttachmentFileView key={index} attachment={attachment} />
          ))}
        </div>
      )}

      {!locked && links && links.length > 0 && (
        <div className={groupClasses}>
          {links.map((attachment, index) => (
            <AttachmentLinkView key={index} attachment={attachment} />
          ))}
        </div>
      )}
    </div>
  );
};
