import debounce from 'lodash/debounce';
import { Logger } from 'stream-chat';
import { LoggerMiddleware } from './middleware';

export type CollectedLog = {
  timestamp: number;
  args: Parameters<Logger>;
};

export type OnCriticalNumberReached = (collectedLogs: CollectedLog[]) => void;

function checkMessageIncludes(message: string, matches: string[]): boolean {
  return matches.some(w => message.includes(w));
}

function clearArray<T>(array: Array<T>): void {
  array.splice(0, array.length);
}

// Collects logs received repeatedly within timeout
// and calls callback
// when critical number of logs is collected.
export const collectRepeatedLogsMiddleware: (options: {
  resetTimeout: number;
  matches: string[];
  criticalNumberOfLogs: number;
  onCriticalNumberReached: OnCriticalNumberReached;
}) => LoggerMiddleware = ({ matches, resetTimeout, criticalNumberOfLogs, onCriticalNumberReached }) => {
  if (matches.length === 0) throw new Error(`matches can't be empty.`);
  if (resetTimeout <= 0) throw new Error('resetTimeout must be positive.');
  if (criticalNumberOfLogs <= 1) throw new Error('criticalNumberOfLogs must be greater than 1.');

  const collectedLogs: CollectedLog[] = [];
  const scheduleReset = debounce(() => clearArray(collectedLogs), resetTimeout);

  return (logLevel, message, extraData) => {
    if (!checkMessageIncludes(message, matches)) return [logLevel, message, extraData];

    collectedLogs.push({
      timestamp: Date.now(),
      args: [logLevel, message, extraData],
    });

    scheduleReset();

    if (collectedLogs.length >= criticalNumberOfLogs) {
      onCriticalNumberReached([...collectedLogs]);
      scheduleReset.flush();
    }

    return false;
  };
};
