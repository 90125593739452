import {
  AudioAttachment,
  FileAttachment,
  ImageAttachment,
  LinkAttachment,
  VideoAttachment,
} from '@sp/util/stream-chat';

export enum UploadingStatus {
  ready = 'ready',
  preProcessing = 'preProcessing',
  uploading = 'uploading',
  postProcessing = 'postProcessing',
  success = 'success',
  error = 'error',
}

export interface Uploadable {
  uploadId: number;
  readonly status: UploadingStatus;
  progress: number;
  size: number;
  readonly start: () => Promise<void>;
  readonly cancel: () => void;
}

export interface ViewImageAttachment extends ImageAttachment {
  previewDataUrl?: string;
}

export type UploadableImageAttachment = ViewImageAttachment & Uploadable;

export interface ViewVideoAttachment extends VideoAttachment {
  previewDataUrl?: string;
}

export interface ViewAudioAttachment extends AudioAttachment {
  fileDataUrl?: string;
}

export type UploadableVideoAttachment = ViewVideoAttachment & Uploadable;

export type UploadableAudioAttachment = ViewAudioAttachment & Uploadable;

export type UploadableFileAttachment = FileAttachment & Uploadable;

export type ChatMessageViewAttachment =
  | ViewImageAttachment
  | ViewVideoAttachment
  | ViewAudioAttachment
  | FileAttachment
  | LinkAttachment;
export type ChatMessageUploadableAttachment =
  | UploadableImageAttachment
  | UploadableVideoAttachment
  | UploadableAudioAttachment
  | UploadableFileAttachment;
