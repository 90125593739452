import { asyncWait, isNotNullish, Nullable } from '@sp/util/helpers';
import { startOfDay } from 'date-fns';
import { createDomain, restore, sample, Store } from 'effector';
import { createGate } from 'effector-react';
import orderBy from 'lodash/orderBy';
import sortBy from 'lodash/sortBy';
import { mockIncomes } from './mock-data';
import { AudienceItem, Income, IncomeChallenge, IncomeSubscription, IncomeType, Period, ProceedsItem } from './types';
import { formatDay, isIncomeChallenge, isIncomeFollow } from './utils';

const domain = createDomain('explore-domain');
const ExploreGate = createGate<Period>('ExploreGate');

const periodSet = domain.createEvent<Period>('periodSet');

const loadIncomesFx = domain.createEffect(async (period: Period) => {
  await asyncWait(400);
  return mockIncomes(period);
});

const $period = domain
  .createStore<Nullable<Period>>(null)
  .on(periodSet, (_, period) => period)
  .on(ExploreGate.open, (_, period) => period)
  .reset(ExploreGate.close);

const $incomes = domain
  .createStore<ReadonlyArray<Income>>([])
  .on(loadIncomesFx.doneData, (_, incomes) => incomes)
  .reset(ExploreGate.close);

const $transactions = $incomes.map(
  incomes => incomes.filter(i => !isIncomeFollow(i)) as ReadonlyArray<IncomeChallenge | IncomeSubscription>,
);

const $total = $transactions.map(incomes => incomes.reduce((total, income) => total + income.amount, 0));

const $audience = $incomes.map(incomes =>
  sortBy(
    Object.values(
      incomes.reduce((acc, income) => {
        const current = acc[income.user.id] ?? { ...income.user, isFollowed: false, total: 0 };
        if (isIncomeFollow(income)) {
          current.isFollowed = true;
        } else {
          current.total += income.amount;
        }
        return { ...acc, [income.user.id]: current };
      }, {} as Record<number, AudienceItem>),
    ),
    ({ total }) => -total,
  ),
);

const $proceeds: Store<{ day: string; items: ProceedsItem[] }[]> = $incomes.map(incomes => {
  const groupsByDay: Record<number, Income[]> = {};

  incomes.forEach(income => {
    const day = startOfDay(income.createdAt).getTime();
    const current = groupsByDay[day] ?? [];
    groupsByDay[day] = [...current, income];
  });

  return orderBy(Object.entries(groupsByDay), ([dayTs]) => -Number(dayTs))
    .map(([dayTs, incomes]) => {
      const groupsByProduct: Record<string, (IncomeChallenge | IncomeSubscription)[]> = {}; // by challenge or subscription

      incomes.forEach(income => {
        if (isIncomeFollow(income)) {
          return;
        }
        const key = isIncomeChallenge(income) ? `challenge-${income.challenge.id}` : `subscription-${income.chanel.id}`;
        const current = groupsByProduct[key] ?? [];
        groupsByProduct[key] = [...current, income];
      });

      return {
        day: formatDay(Number(dayTs)),
        items: Object.values(groupsByProduct)
          .map(innerIncomes => {
            const firstIncome = innerIncomes[0];
            let item: ProceedsItem;
            if (isIncomeChallenge(firstIncome)) {
              item = {
                incomes: innerIncomes as IncomeChallenge[],
                type: IncomeType.challenge,
                challenge: firstIncome.challenge,
              };
            } else {
              item = {
                incomes: innerIncomes as IncomeSubscription[],
                type: IncomeType.subscription,
                chanel: firstIncome.chanel,
              };
            }
            return item;
          })
          .filter(item => item.incomes.length > 0),
      };
    })
    .filter(({ items }) => items.length > 0);
});

sample({
  source: $period,
  filter: isNotNullish,
  target: loadIncomesFx,
});

const $isLoading = loadIncomesFx.pending;
const $loadIncomesError = restore(loadIncomesFx.failData, null).reset($period).reset(ExploreGate.close);

export const EXPLORE_MODEL = {
  ExploreGate,
  periodSet,
  $period,
  $incomes,
  $transactions,
  $total,
  $audience,
  $proceeds,
  $isLoading,
  $loadIncomesError,
};
