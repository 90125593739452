import { IconQuickEdit } from '@sp/ui/icons';
import { format } from 'date-fns';
import { ReactElement, ReactNode } from 'react';

export function formatMessageDateTime(date: Date) {
  return format(date, 'hh:mm a');
}

export function ChatMessageHeaderView({
  date,
  author,
  showDate,
  afterUserNameContent,
  showEditButton,
  onEditClick = () => null,
  onUserClick = () => null,
}: Readonly<{
  author: string;
  date?: Date;
  showDate: boolean;
  afterUserNameContent: ReactNode;
  showEditButton: boolean;
  onEditClick?: () => void;
  onUserClick?: () => void;
}>): ReactElement {
  return (
    <div className="flex items-center gap-1">
      <span role="button" className="font-semibold whitespace-nowrap overflow-hidden" onClick={onUserClick}>
        {author}
      </span>
      {afterUserNameContent}
      {showDate && date && (
        <span className="text-xs text-secondary whitespace-nowrap">{formatMessageDateTime(date)}</span>
      )}
      <div className="flex-grow" />
      {showEditButton && (
        <button onClick={onEditClick} type="button">
          <IconQuickEdit size={24} />
        </button>
      )}
    </div>
  );
}
