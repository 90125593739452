import { createImage } from './files';
import { scaleImage } from './scale-image';

const OPTIMIZED_IMG_SIZE = 1280; // px
const FILE_EXT_REGEX = /\.[^/.]+$/;

export async function optimizeImage(file: File): Promise<File> {
  const blobUrl = URL.createObjectURL(file);
  const image = await createImage(blobUrl);

  const shouldDownscale = image.width > OPTIMIZED_IMG_SIZE || image.height > OPTIMIZED_IMG_SIZE;
  const shouldTranscode = file.type !== 'image/jpeg';

  if (shouldDownscale || shouldTranscode) {
    try {
      const resizedBlob = await scaleImage(
        blobUrl,
        shouldDownscale ? OPTIMIZED_IMG_SIZE / Math.max(image.width, image.height) : 1,
        'image/jpeg',
      );

      const optimizedFile = new File([resizedBlob], file.name.replace(FILE_EXT_REGEX, '.jpg'), {
        type: resizedBlob.type,
        lastModified: file.lastModified,
      });

      URL.revokeObjectURL(blobUrl);

      return optimizedFile;
    } catch (e) {
      // Upload without resizing on error.
      console.error(e);
    }
  }

  return file;
}
