import DomPurify from 'dompurify';

const DEFAULT_PROTOCOL = 'http://';

/*
 * Match position at start of the string if it is not start with /(\w:)?\/\//
 * or match '//'
 * '?:' for not adding group (\w:) to match
 */
export function normalizeUrlProtocol(url: string) {
  return url.replace(/^(?!(?:\w+:)?\/\/)|^\/\//, DEFAULT_PROTOCOL);
}

export function getLinkHostName(url: string) {
  const urlObj = new URL(normalizeUrlProtocol(url));
  return urlObj.hostname;
}

export function sanitizeUrl(url: string): string {
  const anchor = document.createElement('a');

  anchor.href = url;
  DomPurify.sanitize(anchor, { IN_PLACE: true });
  return anchor.href;
}
