import { ANALYTICS } from '@sp/feature/analytics';
import { ResponsiveImage } from '@sp/ui/elements';
import { PhotoswipeItem } from '@sp/ui/gallery';
import { getImagePreviewUrl, ViewImageAttachment } from '@sp/util/chat-attachments';
import { getAWSImageFullSrc } from '@sp/util/files';
import { ReactElement, useCallback, useState } from 'react';

export function AttachmentImageView({ attachment }: { attachment: ViewImageAttachment }): ReactElement {
  const previewUrl = getImagePreviewUrl(attachment) ?? attachment.url;
  const fullSizeUrl = getAWSImageFullSrc(attachment.url);
  const [thumbnailSrc, setThumbnailSrc] = useState<string | undefined>(undefined);

  const handleThumbnailChange = useCallback((src: string | null) => {
    setThumbnailSrc(src ?? undefined);
  }, []);

  return (
    <PhotoswipeItem
      cropped
      original={fullSizeUrl}
      thumbnail={thumbnailSrc}
      width={attachment.width}
      height={attachment.height}
      alt={attachment.name}
    >
      {({ ref, open }) => (
        <div className="w-full relative rounded-2.5xl bg-stripe cursor-pointer">
          <div className="aspect-square aspect-ratio-wrap w-full">
            <ResponsiveImage
              ref={ref as any}
              srcRef={handleThumbnailChange}
              role="button"
              onClick={event => {
                ANALYTICS.openMediaTracked({
                  'Media Type': attachment.fileType,
                  'Media Name': attachment.url,
                });
                open(event);
              }}
              src={previewUrl}
              className="object-cover overflow-hidden rounded-2.5xl"
              alt={attachment.name}
            />
          </div>
        </div>
      )}
    </PhotoswipeItem>
  );
}
