import { ReactElement } from 'react';
import { ICON_DEFAULTS, IconProps } from './types';

export function IconStarShadow({
  size = ICON_DEFAULTS.size,
  color = ICON_DEFAULTS.color,
  className = ICON_DEFAULTS.className,
}: IconProps): ReactElement {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.1932 25.2766C29.9247 27.7598 31.4414 31.1093 30.7988 33.6693C29.7734 37.7557 24.6204 35.207 22.6288 33.7157C22.1002 33.3199 21.5754 32.9172 21.0702 32.4921C20.4686 31.9865 19.8751 31.4693 19.3025 30.9309C17.6596 32.8948 15.329 35.0501 12.8888 35.9248C10.8442 36.6576 9.14258 35.7586 8.763 33.5817C8.30954 30.983 9.05274 28.0179 9.71455 25.5161C7.73823 23.6375 5.68671 21.7322 4.22116 19.4316C2.81257 17.2196 3.86566 15.1489 6.34266 14.5818C7.14282 14.3985 7.96252 14.3077 8.78156 14.268C9.88438 14.2146 12.1408 14.3016 13.2095 14.3016C13.284 14.2586 13.4432 13.6026 13.4833 13.4909C13.7538 12.7324 14.0376 11.9841 14.3531 11.2428C14.5914 10.6836 14.8342 10.1258 15.0833 9.57146C15.9694 7.59807 17.9382 2.28838 20.9019 4.31125C21.2033 4.51699 21.4566 4.7758 21.6864 5.05674C22.0666 5.52095 22.3648 6.06872 22.6383 6.60064C23.1526 7.59906 23.5543 8.64922 23.9629 9.69386C24.5729 11.2535 25.0843 12.8665 25.7602 14.4001C25.7686 14.419 25.7748 14.4389 25.7797 14.4588C26.7846 14.4835 27.7921 14.5012 28.7961 14.5513C30.9863 14.66 34.4109 14.0903 35.3719 16.274C35.8914 17.4547 35.3166 18.7041 34.6111 19.6614C33.1778 21.6068 31.2392 23.1078 29.3599 24.5936"
        fill="none"
      />
      <path
        d="M29.1932 25.2766C29.9247 27.7598 31.4414 31.1093 30.7988 33.6693C29.7734 37.7557 24.6204 35.207 22.6288 33.7157C22.1002 33.3199 21.5754 32.9172 21.0702 32.4921C20.4686 31.9865 19.8751 31.4693 19.3025 30.9309C17.6596 32.8948 15.329 35.0501 12.8888 35.9248C10.8442 36.6576 9.14258 35.7586 8.763 33.5817C8.30954 30.983 9.05274 28.0179 9.71455 25.5161C7.73823 23.6375 5.68671 21.7322 4.22116 19.4316C2.81257 17.2196 3.86566 15.1489 6.34266 14.5818C7.14282 14.3985 7.96252 14.3077 8.78156 14.268C9.88438 14.2146 12.1408 14.3016 13.2095 14.3016C13.284 14.2586 13.4432 13.6026 13.4833 13.4909C13.7538 12.7324 14.0376 11.9841 14.3531 11.2428C14.5914 10.6836 14.8342 10.1258 15.0833 9.57146C15.9694 7.59807 17.9382 2.28838 20.9019 4.31125C21.2033 4.51699 21.4566 4.7758 21.6864 5.05674C22.0666 5.52095 22.3648 6.06872 22.6383 6.60064C23.1526 7.59906 23.5543 8.64922 23.9629 9.69386C24.5729 11.2535 25.0843 12.8665 25.7602 14.4001C25.7686 14.419 25.7748 14.4389 25.7797 14.4588C26.7846 14.4835 27.7921 14.5012 28.7961 14.5513C30.9863 14.66 34.4109 14.0903 35.3719 16.274C35.8914 17.4547 35.3166 18.7041 34.6111 19.6614C33.1778 21.6068 31.2392 23.1078 29.3599 24.5936"
        stroke={color}
        strokeWidth="2"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.0449 7.51165C23.8081 6.75811 24.6367 6.03526 25.4588 5.34717"
        stroke={color}
        strokeWidth="2"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.6582 11.4897C25.7052 10.4955 26.7395 9.49865 27.8052 8.52515"
        stroke={color}
        strokeWidth="2"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.7344 14.5251C29.559 13.5575 30.3913 12.5886 31.2792 11.6771"
        stroke={color}
        strokeWidth="2"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.7031 15.3297C35.2966 14.8085 35.8761 14.2694 36.4423 13.7188"
        stroke={color}
        strokeWidth="2"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.8516 27.2981C30.3727 26.8107 30.8949 26.3265 31.4471 25.8741"
        stroke={color}
        strokeWidth="2"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.9219 32.9069C31.498 32.3873 32.0725 31.859 32.6796 31.375"
        stroke={color}
        strokeWidth="2"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.0266 33.2474C21.2253 34.1927 20.3036 34.9683 19.3379 35.7367"
        stroke={color}
        strokeWidth="2"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
