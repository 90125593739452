import { RemirrorJSON } from '@remirror/core';

export function wrapTextIntoDoc(text: string): RemirrorJSON {
  return { type: 'doc', content: [{ type: 'paragraph', content: [{ type: 'text', text }] }] };
}

export function createEmptyDoc(): RemirrorJSON {
  return { type: 'doc', content: [] };
}

export function isEmptyDoc(doc: RemirrorJSON): boolean {
  return (doc?.content?.length ?? 0) === 0;
}

export function isEmptyParagraph(node: RemirrorJSON): boolean {
  return node.type === 'paragraph' && node.content == null && (node.marks?.length ?? 0) === 0;
}
