import { createEffect, createEvent, forward, merge, restore } from 'effector';

export function createMediaPlayer(src: string, type: 'audio' | 'video' = 'audio') {
  const media = document.createElement(type);
  media.src = src;

  // Events
  const play = createEvent<void>();
  const pause = createEvent<void>();
  const setProgress = createEvent<number>();
  const destroy = createEvent<void>();
  const playerPausedChanged = createEvent<boolean>();
  const playerProgressChanged = createEvent<number>();

  // States
  const $paused = restore(playerPausedChanged, true);
  const $progress = restore(playerProgressChanged, 0);

  // Listeners
  const playListener = () => {
    playerPausedChanged(false);
  };
  const pauseListener = () => {
    playerPausedChanged(true);
  };
  const timeupdateListener = () => {
    const { currentTime, duration } = media;
    playerProgressChanged(currentTime / duration);
  };
  media.addEventListener('play', playListener);
  media.addEventListener('pause', pauseListener);
  media.addEventListener('timeupdate', timeupdateListener);

  // Effects
  const togglePlayFx = createEffect<boolean, void>(play => {
    if (play) {
      media.play();
    } else {
      media.pause();
    }
  });
  const destroyFx = createEffect<void, void>(() => {
    media.pause();
    media.removeEventListener('play', playListener);
    media.removeEventListener('pause', pauseListener);
    media.removeEventListener('timeupdate', timeupdateListener);
  });
  const setProgressFx = createEffect<number, void>(progress => {
    const { duration } = media;
    media.currentTime = progress * duration;
  });

  forward({ from: merge([play.map(() => true), pause.map(() => false)]), to: togglePlayFx });
  forward({ from: destroy, to: destroyFx });
  forward({ from: setProgress, to: setProgressFx });

  return {
    media,
    play,
    pause,
    setProgress,
    destroy,
    $paused,
    $progress,
  };
}

export type MediaPlayer = ReturnType<typeof createMediaPlayer>;

const cache = new Map<string, MediaPlayer>();

export const MediaPlayersCache = {
  clear: (): void => {
    for (const player of cache.values()) {
      player.destroy();
    }

    cache.clear();
  },
  get: (src: string, type: 'audio' | 'video' = 'audio'): MediaPlayer => {
    let player = cache.get(src);

    if (!player) {
      player = createMediaPlayer(src, type);
      cache.set(src, player);
    }

    return player;
  },
};
