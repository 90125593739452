import { USER_PROFILE_CARD_SHOW_MODEL } from '@sp/data/user-profile-card';
import { ChatChannelModel, createChatChannelModel, STREAM_CHAT_MODEL } from '@sp/feature/stream-chat';
import { isNotNullish, Nullable, switchMap } from '@sp/util/helpers';
import { attach, createEffect, createEvent, createStore, forward, restore, sample } from 'effector';

const $instance = createStore<ChatChannelModel | null>(null);

const instanceDestroyed = createEvent();

const activeChatIdGet = createEvent<Nullable<number>>();

const $activeChatId = restore(activeChatIdGet, null);

const destroyFx = createEffect(async (channel: ChatChannelModel | null) => await channel?.destroy());

const createFx = createEffect(async (channelId: string) => await createChatChannelModel(STREAM_CHAT_MODEL, channelId));

$instance.reset(destroyFx);

forward({
  from: $activeChatId,
  to: attach({
    source: $instance,
    effect: destroyFx,
  }),
});

forward({
  from: instanceDestroyed,
  to: attach({
    source: $instance,
    effect: destroyFx,
  }),
});

sample({
  clock: destroyFx.done,
  source: $activeChatId,
  filter: isNotNullish,
  // sample's types break if fn's type is not set explicitly.
  fn: (id: number) => id.toString(),
  target: createFx,
});

forward({
  from: createFx.doneData,
  to: $instance,
});

const $isError = restore(
  createFx.fail.map(() => true),
  false,
).reset(destroyFx);

export const CHAT_CHANNEL = { $instance, instanceDestroyed, activeChatIdGet, $isError };

// Init UserProfileCard
{
  switchMap({
    source: $instance,
    fn: i => i?.showUserCard,
    target: USER_PROFILE_CARD_SHOW_MODEL.show,
  });
}
