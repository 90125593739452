import { Button, SpinnerPage } from '@sp/ui/elements';
import { isNotNullish, isNullish } from '@sp/util/helpers';
import { format } from 'date-fns';
import { useGate, useStore } from 'effector-react';
import { FC } from 'react';
import { CHALLENGE_EDITOR_MODEL } from './challenge-editor-model';
import { CHALLENGE_FORM_MODEL } from './challenge-form-model';
import { ChallengeParamsForm } from './challenge-params-form';
import { ChallengeStepsForm } from './challenge-steps-form';

export const EditChallengePage: FC<{ challengeId: number }> = ({ challengeId }) => {
  useGate(CHALLENGE_EDITOR_MODEL.ChallengeEditorGate, challengeId);
  const challenge = useStore(CHALLENGE_EDITOR_MODEL.$challenge);
  const isLoading = useStore(CHALLENGE_EDITOR_MODEL.$challengeLoadInProgress);
  const loadError = useStore(CHALLENGE_EDITOR_MODEL.$challengeLoadError);
  const isSaving = useStore(CHALLENGE_EDITOR_MODEL.$challengeSaveInProgress);
  const saveError = useStore(CHALLENGE_EDITOR_MODEL.$challengeSaveError);
  const isChallengePublished = useStore(CHALLENGE_FORM_MODEL.$isChallengePublished);

  if (isLoading) {
    return <SpinnerPage />;
  }

  if (isNotNullish(loadError) || isNullish(challenge)) {
    return <>error - {loadError?.message ?? 'challenge load error'}</>;
  }

  return (
    <div className="mobile-pan h-full">
      <div className="mt-4 shadow-modal bg-primary p-4">
        <ChallengeParamsForm />
        <hr />
        <div className="flex items-center gap-2">
          <Button
            onClick={() => CHALLENGE_FORM_MODEL.saveChallengeStarted()}
            loading={isSaving}
            disabled={isSaving}
            color="primary"
          >
            save
          </Button>
          <div>
            {isNotNullish(saveError) ? (
              <span className="text-alert text-xs">Save error - {saveError.message}</span>
            ) : (
              <span className="text-secondary text-xs">
                Last saved changes - {format(challenge.updated_at ?? 0, `do  MMMM 'at' HH:mm`)}
              </span>
            )}
          </div>

          <div className="flex-grow" />

          <div className="text-xs text-secondary">
            {isChallengePublished ? (
              'published challenge'
            ) : (
              <Button onClick={() => CHALLENGE_FORM_MODEL.publishChallengeStarted()} color="primary">
                publish
              </Button>
            )}
          </div>
        </div>
      </div>

      <ChallengeStepsForm />
    </div>
  );
};
