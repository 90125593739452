import { ReactElement } from 'react';
import { ICON_DEFAULTS, IconProps } from './types';

export function IconInstagram({
  size = ICON_DEFAULTS.size,
  className = ICON_DEFAULTS.className,
}: IconProps): ReactElement {
  return (
    <svg
      width={size}
      height={size}
      className={className}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" rx="16" fill="url(#paint0_radial_6893_13440)" />
      <rect width="32" height="32" rx="16" fill="url(#paint1_radial_6893_13440)" />
      <path
        d="M16 19.75C18.0711 19.75 19.75 18.0711 19.75 16C19.75 13.9289 18.0711 12.25 16 12.25C13.9289 12.25 12.25 13.9289 12.25 16C12.25 18.0711 13.9289 19.75 16 19.75Z"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M20.125 7.375H11.875C9.38972 7.375 7.375 9.38972 7.375 11.875V20.125C7.375 22.6103 9.38972 24.625 11.875 24.625H20.125C22.6103 24.625 24.625 22.6103 24.625 20.125V11.875C24.625 9.38972 22.6103 7.375 20.125 7.375Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.9375 11.125C21.9375 11.7118 21.4618 12.1875 20.875 12.1875C20.2882 12.1875 19.8125 11.7118 19.8125 11.125C19.8125 10.5382 20.2882 10.0625 20.875 10.0625C21.4618 10.0625 21.9375 10.5382 21.9375 11.125Z"
        fill="white"
        stroke="white"
        strokeWidth="0.125"
      />
      <defs>
        <radialGradient
          id="paint0_radial_6893_13440"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(27.04 36.32) rotate(-115.776) scale(43.6379 64.9064)"
        >
          <stop offset="0.24392" stopColor="#FF1B90" />
          <stop offset="0.436673" stopColor="#F80261" />
          <stop offset="0.688476" stopColor="#ED00C0" />
          <stop offset="0.776787" stopColor="#C500E9" />
          <stop offset="0.893155" stopColor="#7017FF" />
        </radialGradient>
        <radialGradient
          id="paint1_radial_6893_13440"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(11.6 30.896) rotate(-57.4396) scale(18.9079 19.6669)"
        >
          <stop stopColor="#FFD600" />
          <stop offset="0.484375" stopColor="#FF6930" />
          <stop offset="0.734375" stopColor="#FE3B36" />
          <stop offset="1" stopColor="#FE3B36" stopOpacity="0" />
        </radialGradient>
      </defs>
    </svg>
  );
}
