import { registerNativeAuthApi } from '@sp/data/auth';
import { APP_REVISION, ENV_MODE, EnvMode } from '@sp/data/env';
import { registerNativeNotificationPermissionHandlers } from '@sp/feature/permissions';
import { initXanoDatasourceInterceptor } from '@sp/util/env';
import { registerExternalLinkClickHandler, registerNativeAppStatusCallback } from '@sp/util/native';
import React from 'react';
import ReactDOM from 'react-dom';
import { App } from './app';

if (import.meta.env.PROD) {
  import('./init-sentry').then(module => module.initSentry());
  import('@sp/util/groovehq').then(module => module.initGroove());
}

if (ENV_MODE === EnvMode.production) {
  import('./init-smartlook');
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
(window as any).selfplusRevision = APP_REVISION;

initXanoDatasourceInterceptor();
registerExternalLinkClickHandler();
registerNativeAuthApi();
registerNativeAppStatusCallback();
registerNativeNotificationPermissionHandlers();

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);
