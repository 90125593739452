import { IconCaretUp } from '@sp/ui/icons';
import { cls } from '@sp/util/helpers';
import { motion } from 'framer-motion';
import { FC, ReactElement } from 'react';

const variants = {
  open: {
    height: 'auto',
  },
  collapsed: { height: 0 },
};

const transition = { duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] };

const HiddenContainer: FC<{
  isHidden: boolean;
}> = ({ isHidden, children }) => {
  return (
    <motion.div
      className="overflow-hidden"
      variants={variants}
      initial={isHidden ? 'collapsed' : 'open'}
      animate={isHidden ? 'collapsed' : 'open'}
      transition={transition}
    >
      {children}
    </motion.div>
  );
};

export const StepView: FC<{
  disabled?: boolean;
  isActive?: boolean;
  header: ReactElement;
  subHeader?: ReactElement;
  isOpen: boolean;
  onToggle: () => void;
}> = ({ isOpen, onToggle, header, subHeader, children, disabled = false, isActive = false }) => {
  return (
    <div>
      <div
        className={cls(
          isActive ? 'bg-brand-medium text-active' : isOpen ? 'bg-light text-secondary' : 'bg-white text-secondary',
        )}
      >
        <button
          type="button"
          disabled={disabled}
          onClick={onToggle}
          className="px-6 py-4 flex justify-between items-center w-full"
        >
          <div className={cls('flex-grow mr-2', disabled && 'text-disabled')}>{header}</div>
          {!disabled && (
            <IconCaretUp
              size={20}
              className={cls('transition-transform duration-300 origin-center', !isOpen && '-rotate-180')}
            />
          )}
        </button>

        <HiddenContainer isHidden={!(isOpen && subHeader) || disabled}>
          <div className="px-4 pb-4">{subHeader}</div>
        </HiddenContainer>
      </div>

      <div>
        <HiddenContainer isHidden={!(isOpen && children) || disabled}>
          <div className="pl-3 pr-6 py-3 pb-4">{children}</div>
        </HiddenContainer>
      </div>
    </div>
  );
};
