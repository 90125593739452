import { USER_PROFILE_CARD_SHOW_MODEL } from '@sp/data/user-profile-card';
import { MENTION_ATOM_ID_DATASET_KEY, MENTION_TYPE_DATASET_KEY, MentionType } from '@sp/util/text-editor';
import { sample } from 'effector';
import { messageClicked } from './elements/chat-message-body';

sample({
  clock: messageClicked.filterMap(event => {
    const target = event.target as HTMLElement;
    if (target.dataset[MENTION_TYPE_DATASET_KEY] !== MentionType.User) return undefined;

    const userId = target.dataset[MENTION_ATOM_ID_DATASET_KEY];
    if (userId == null || userId.length === 0) return undefined;

    return { userId };
  }),
  target: USER_PROFILE_CARD_SHOW_MODEL.show,
});
