import { useUser } from '@sp/data/auth';
import { ANALYTICS } from '@sp/feature/analytics';
import { UseSpacialBadge } from '@sp/feature/space';
import { SpinnerPage } from '@sp/ui/elements';
import { isNotNullish } from '@sp/util/helpers';
import { useStore } from 'effector-react';
import { lazy, ReactElement, useEffect, useMemo, useState } from 'react';
import { COMMUNITY_MODEL } from './community-model';

const Chat = lazy(() => import('@sp/feature/chat').then(m => ({ default: m.Chat })));

export const CommunityChat = (): ReactElement => {
  const chatModel = useStore(COMMUNITY_MODEL.$chatModel);
  const community = useStore(COMMUNITY_MODEL.$community);
  const user = useUser();
  const isOwner = useStore(COMMUNITY_MODEL.$isOwner);
  const [isOpenTracked, setIsOpenTracked] = useState(false);

  useEffect(() => {
    if (isNotNullish(community) && isNotNullish(chatModel) && !isOpenTracked) {
      ANALYTICS.spaceOpenTracked();
      setIsOpenTracked(true);
    }
  }, [community, chatModel, isOpenTracked, user.isFirstChallengeVisited]);

  const [specialBadgeContent] = UseSpacialBadge(community.creator);

  const fullAcceptedFileAttachments = user.isAdmin || user.id === community?.creator?.id;

  const messageModalConfig = useMemo(
    () => ({ reactions: true, feed: isOwner, reply: true, copy: true, edit: true, delete: true }),
    [isOwner],
  );

  return chatModel ? (
    <>
      <Chat
        chatModel={chatModel}
        messageExtraContent={{ afterUserName: specialBadgeContent }}
        fullAcceptedFileAttachments={fullAcceptedFileAttachments}
        messageModalConfig={messageModalConfig}
      />
    </>
  ) : (
    <SpinnerPage />
  );
};
