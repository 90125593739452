import { getSelfPlusAdmins, SelfPlusAdmin } from '@sp/data/bif';
import { USER_PROFILE_CARD_MEMBERS_MODEL } from '@sp/data/user-profile-card';
import { createLocalStorageCache, registerLocalStorageCache } from '@sp/util/cache';
import { createResource } from '@sp/util/helpers';
import { createEffect, sample } from 'effector';
import { z } from 'zod';

const cache = createLocalStorageCache(z.array(SelfPlusAdmin), 'sp_selfplus_admins');
registerLocalStorageCache(cache.key);

export const SELFPLUS_ADMINS_MODEL = createResource(getSelfPlusAdmins);

const cachedValue = cache.getValue();
if (cachedValue) SELFPLUS_ADMINS_MODEL.replace(cachedValue);

sample({
  clock: SELFPLUS_ADMINS_MODEL.$data,
  target: createEffect(cache.setValue),
});

// Init UserProfileCard
{
  const users = USER_PROFILE_CARD_MEMBERS_MODEL.users.createItemAdapter('selfplusAdmins');

  sample({
    source: SELFPLUS_ADMINS_MODEL.$data.map(admins => admins ?? []),
    target: users.set,
  });
}
