import { IS_MOBILE } from '@sp/data/env';
import { useCloseModalOnBack } from '@sp/feature/back-button';
import { cls, isNotNullish } from '@sp/util/helpers';
import {
  motion,
  PanInfo,
  Transition,
  useAnimation,
  useDragControls,
  useElementScroll,
  useMotionValue,
  Variants,
} from 'framer-motion';
import { FC, PointerEventHandler, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

const rootNode = document.getElementById('root');

export const MODAL_ANIMATION_DURATION = 400;

const dragConstraints = {
  top: 0,
};
const OPEN = 'open';
const CLOSE = 'close';
const modalVariants: Variants = {
  [OPEN]: {
    y: 0,
  },
  [CLOSE]: {
    y: '100%',
  },
};

// TODO[Dmitriy Teplov] switch to design tokens.
const backdropVariants: Variants = {
  [OPEN]: {
    backgroundColor: 'rgba(37, 38, 43, 0.85)',
    backdropFilter: 'blur(12px)',
    WebkitBackdropFilter: 'blur(12px)',
  },
  [CLOSE]: {
    backgroundColor: 'rgba(37, 38, 43, 0)',
    backdropFilter: 'blur(0px)',
    WebkitBackdropFilter: 'blur(0px)',
  },
};

function calcBackdropStylesOnClose(progress: number) {
  if (isNaN(progress) || progress < 0) {
    progress = 0;
  }
  if (progress > 1) {
    progress = 1;
  }
  return {
    backgroundColor: `rgba(37, 38, 43, ${0.85 * (1 - progress)})`,
    backdropFilter: `blur(${12 * (1 - progress)}px)`,
    WebkitBackdropFilter: `blur(${12 * (1 - progress)}px)`,
  };
}

const transition: Transition = {
  duration: MODAL_ANIMATION_DURATION / 1000,
  ease: [0.51, 0, 0, 1.33],
};

interface ModalProps {
  isOpen: boolean;
  onClose?: () => void;
  withBackdrop?: boolean;
  closeOnBackdropClick?: boolean;
  withBlur?: boolean;
  maxWidth?: string | number;
  header?: ReactNode;
  footer?: ReactNode;
}

const ModalView: FC<ModalProps> = ({
  isOpen,
  onClose,
  withBlur = true,
  withBackdrop = false,
  closeOnBackdropClick = false,
  maxWidth = '100%',
  children,
  header,
  footer,
}) => {
  const bodyRef = useRef<HTMLDivElement>(null);
  const { scrollY } = useElementScroll(bodyRef);
  const [isScrollInTop, setIsScrollInTop] = useState(true);
  const backdropAnimation = useAnimation();
  const modalAnimation = useAnimation();
  const y = useMotionValue(0);
  const dragControls = useDragControls();
  const [pointerY, setPointerY] = useState(0);
  const [isDragging, setIsDragging] = useState(false);

  const [isClosing, setIsClosing] = useState(false);

  useEffect(() => {
    return scrollY.onChange(value => {
      setIsScrollInTop(value <= 0);
    });
  }, [scrollY]);

  useEffect(() => {
    const activeElement = document.activeElement as HTMLElement | null;
    if (activeElement && withBlur) {
      activeElement.blur();
    }
  }, [withBlur]);

  const open = useCallback(() => {
    modalAnimation.start(OPEN);
    backdropAnimation.start(OPEN);
  }, [modalAnimation, backdropAnimation]);

  const close = useCallback(() => {
    setIsClosing(true);
    Promise.all([modalAnimation.start(CLOSE), backdropAnimation.start(CLOSE)])
      .then(() => onClose?.())
      .finally(() => setIsClosing(false));
  }, [modalAnimation, backdropAnimation, onClose]);

  useEffect(() => {
    isOpen ? open() : close();
  }, [isOpen, open, close]);

  const handleIosOverscroll = useCallback(async () => {
    const body = bodyRef.current;
    if (!body) return;
    if (isClosing) return;

    await new Promise(window.requestAnimationFrame);

    // iOS adhoc to close modal on overscroll.
    if (body?.scrollTop <= -20) close();
  }, [close, isClosing]);

  useEffect(() => {
    bodyRef.current?.addEventListener('touchmove', handleIosOverscroll, { passive: false });
    return bodyRef.current?.removeEventListener('touchmove', handleIosOverscroll);
  }, [handleIosOverscroll]);

  // Handle backdrop styles on modal drag
  useEffect(() => {
    y.onChange(offset => {
      const closeProgress =
        typeof offset === 'string' ? Number.parseInt(offset) / 100 : offset / (bodyRef.current?.offsetHeight ?? 1);
      backdropAnimation.set(calcBackdropStylesOnClose(closeProgress));
    });
  }, [backdropAnimation, y]);

  const onStaticPointerDown = useCallback<PointerEventHandler>(
    e => {
      dragControls.start(e);
      setIsDragging(true);
    },
    [dragControls],
  );

  const onPointerDown = useCallback<PointerEventHandler>(
    e => {
      if (isScrollInTop) {
        setPointerY(e.clientY);
      }
    },
    [isScrollInTop],
  );

  const onPointerMove = useCallback<PointerEventHandler>(
    e => {
      if (isDragging || !IS_MOBILE) return;

      const offset = e.clientY - pointerY;
      const isScrollDown = isScrollInTop && offset > 0;
      if (isScrollDown) {
        dragControls.start(e);
        setIsDragging(true);
      }
    },
    [isDragging, pointerY, isScrollInTop, dragControls],
  );

  const onPointerUp = useCallback<PointerEventHandler>(() => {
    setIsDragging(false);
    setPointerY(0);
  }, []);

  function onDragStart() {
    setIsDragging(true);
  }

  function onDragEnd(_: MouseEvent | TouchEvent, { velocity }: PanInfo) {
    setIsDragging(false);
    if (velocity.y > 40) {
      close();
    } else {
      open();
    }
  }

  return (
    <div className="fullscreen-modal absolute z-60 w-full h-full bottom-0">
      {withBackdrop && (
        <motion.div
          animate={backdropAnimation}
          initial={CLOSE}
          variants={backdropVariants}
          transition={transition}
          onClick={() => closeOnBackdropClick && close()}
          className="absolute w-full h-full"
        />
      )}
      <motion.div
        animate={modalAnimation}
        initial={CLOSE}
        variants={modalVariants}
        transition={transition}
        drag="y"
        dragConstraints={dragConstraints}
        dragControls={dragControls}
        dragListener={false}
        dragElastic={0.1}
        onPointerDown={onPointerDown}
        onPointerMove={onPointerMove}
        onPointerUp={onPointerUp}
        onDragStart={onDragStart}
        onDragEnd={onDragEnd}
        style={{ y, maxWidth, x: '-50%' }}
        className="absolute w-full left-1/2 bottom-0 rounded-t-2xl bg-white pb-[100px] -mb-[100px] shadow-menu touch-none"
      >
        <div onPointerDown={onStaticPointerDown} className="flex justify-center pt-4 pb-4">
          <div className="w-8 h-1 rounded-sm bg-stroke" />
        </div>
        {header && (
          <div onPointerDown={onStaticPointerDown} className="max-h-[20vh]">
            {header}
          </div>
        )}
        <div
          tabIndex={100}
          ref={bodyRef}
          className={cls(
            'px-6 pb-6 mobile-pan max-h-[80vh] overflow-auto relative',
            isScrollInTop && 'touch-pan-down',
            header || footer ? 'max-h-[70vh]' : 'max-h-[80vh]',
          )}
          style={{ overscrollBehavior: 'none' }}
        >
          {children}
        </div>
        {footer && (
          <div onPointerDown={onStaticPointerDown} className="max-h-[20vh]">
            {footer}
          </div>
        )}
      </motion.div>
    </div>
  );
};

export const Modal: FC<ModalProps> = ({ isOpen, onClose, ...props }) => {
  const [isShowPortal, setIsShowPortal] = useState(false);

  useCloseModalOnBack({ isOpen, onCloseCallback: () => onClose?.() });

  useEffect(() => {
    isOpen && setIsShowPortal(isOpen);
  }, [isOpen]);

  function handleClose() {
    setIsShowPortal(false);
    onClose?.();
  }

  return isShowPortal && isNotNullish(rootNode)
    ? createPortal(<ModalView isOpen={isOpen} onClose={handleClose} {...props} />, rootNode)
    : null;
};
