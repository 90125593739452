// Declare native types

import { IS_IOS } from '@sp/data/env';

interface AnalyticEventFn {
  (action: 'track', properties: { eventName: string; properties: Record<string, string> }): void;

  (action: 'time', properties: { eventName: string }): void;
}

interface AnalyticPeopleFn {
  (action: 'identify', properties: { userId: string }): void;

  (action: 'set', properties: { property: string; value: string }): void;

  (action: 'append', properties: { list: string; value: string }): void;

  (action: 'unset', properties: { property: string }): void;

  (action: 'increment', properties: { property: string; by: number }): void;

  (action: 'charge', properties: { amount: number }): void;
}

interface AnalyticGroupFn {
  (action: 'add', properties: { group: string; id: string }): void;

  (action: 'remove', properties: { group: string; id: string }): void;
}

declare global {
  interface Window {
    nativeCallbackRegisterNotificationToken: (token: string) => void;
    nativeCallbackNotificationsDenied: () => void;
    isApp: boolean;
    native: {
      isiOs: boolean;
      permissions: {
        camera: 'unknown' | 'denied' | 'granted' | 'restricted';
        microphone: 'unknown' | 'denied' | 'granted' | 'restricted';
        notifications: 'unknown' | 'denied' | 'granted';
      };
      service: {
        push: {
          request: VoidFunction;
        };
        openSettings: VoidFunction;
        openExternalLink: (url: string) => void;
        media: {
          request: (type: 'audio' | 'video') => void;
        };
        showLoader: (type: 'self' | 'large' | 'small') => void;
        hideLoader: VoidFunction;
        rateApp: VoidFunction;
        enableBackHandler: VoidFunction;
        systemBack: VoidFunction;
        share: (title: string, description: string, url: string) => void;
      };
      signin: {
        authenticate: {
          google: VoidFunction;
          facebook: VoidFunction;
          apple: VoidFunction;
        };
      };
      analytics: {
        event: AnalyticEventFn;
        people: AnalyticPeopleFn;
        group: AnalyticGroupFn;
      };
      iap: {
        purchase: (product: string) => void;
      };
    };
    nativeCallbackRegisterGoogleToken?: (status: number, token: string) => void;
    nativeCallbackRegisterAppleToken?: (status: number, token: string) => void;
    nativeCallbackRegisterFacebookToken?: (status: number, token: string) => void;
    nativeCallbackMediaPermissionUpdate?: (result: boolean, type: 'audio' | 'video') => void;
    nativeCallbackEventBack?: VoidFunction;
    nativeCallbackAppStatus?: (status: 'active' | 'inactive' | 'background') => void;
    nativeCallbackKeyboardStatus?: (status: 'willShow' | 'didShow' | 'willHide') => void;
    // TODO[Dmitriy Teplov] make union type with success / error variants.
    nativeCallbackIAPResult?: (result: 1 | -1 | -2, transactionId?: string) => void;
  }
}

export function isInApp(): boolean {
  return Boolean(window.isApp);
}

const NATIVE_APP_INIT_TIMEOUT = 3_000;

export async function whenNativeAppInitialized(): Promise<void> {
  return new Promise((resolve, reject) => {
    if (isInApp()) {
      resolve();
    } else {
      const timer = window.setTimeout(() => {
        reject(new Error('NATIVE_APP_INIT_TIMEOUT'));
        destroy();
      }, NATIVE_APP_INIT_TIMEOUT);

      const listener = () => {
        resolve();
        destroy();
      };

      const destroy = () => {
        window.clearTimeout(timer);
        window.removeEventListener('nativeAppInitialized', listener);
      };

      window.addEventListener('nativeAppInitialized', listener);
    }
  });
}

export function navigateExternal(url: string): void {
  if (!isInApp()) return;
  window.native.service.openExternalLink(url);
}

export function showNativeLoader(type: 'self' | 'large' | 'small'): void {
  if (!isInApp()) return;
  window.native.service.showLoader(type);
}

export function hideNativeLoader(): void {
  if (!isInApp()) return;
  window.native.service.hideLoader();
}

export function enableNativeBackHandler(): void {
  if (!isInApp()) return;

  window.native.service.enableBackHandler();
}

export function callNativeBack(): void {
  if (!isInApp()) return;

  window.native.service.systemBack();
}

export function rateApp(): void {
  if (!isInApp()) return;

  window.native.service.rateApp();
}

export async function makeInAppPurchase(
  product: string,
): Promise<
  | { success: true; product: string; transactionId: string }
  | { success: false; error: 'cancel' | 'inAppError' | 'notInApp' | 'busy' }
> {
  if (!isInApp() || !window.native?.iap?.purchase) return { success: false, error: 'notInApp' };
  if (window.nativeCallbackIAPResult !== undefined) return { success: false, error: 'busy' };

  return new Promise(resolve => {
    window.nativeCallbackIAPResult = (result, transactionId) => {
      switch (result) {
        case 1: {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          resolve({ success: true, product, transactionId: transactionId! });
          break;
        }
        case -1: {
          resolve({ success: false, error: 'cancel' });
          break;
        }
        case -2: {
          resolve({ success: false, error: 'inAppError' });
          break;
        }
      }

      delete window.nativeCallbackIAPResult;
    };

    window.native.iap.purchase(product);
  });
}

/***
 *
 * @param url - A link to be shared
 * @param text - A link text to be shared
 * @param title - A string representing a title to be shared. May be ignored by the target.
 */
export function shareUrl({ url, text, title = '' }: { url: string; text: string; title?: string }): void {
  if (IS_IOS) {
    const data: ShareData = { url, text, title };
    if (navigator.share && navigator.canShare(data)) {
      navigator.share(data);
    } else {
      console.error(`Can't be shared or share is not a function`, data);
    }
  } else if (isInApp()) {
    window.native.service.share(title, text, url);
  }
}
