import { ChatEvent } from '@sp/data/bif';
import { CHAT_CHANNEL } from '@sp/feature/chat-channel';
import { ChatChannelModel } from '@sp/feature/stream-chat';
import { useGate, useStore } from 'effector-react';
import { FC, useCallback, useEffect } from 'react';
import { Virtuoso } from 'react-virtuoso';
import { Mention } from './mention';
import { MENTIONS_MODEL } from './mentions-model';

const MentionsList: FC<{ chatChannelModel: ChatChannelModel; mentions: ChatEvent[] }> = ({
  chatChannelModel,
  mentions,
}) => {
  const itemContent = useCallback(
    (_: number, item: ChatEvent) => {
      return <Mention key={item.messageId} mention={item} model={chatChannelModel}></Mention>;
    },
    [chatChannelModel],
  );
  return <Virtuoso className="h-full" data={mentions} itemContent={itemContent} />;
};

const EmptyMentionsList: FC = () => {
  return (
    <div className="w-full h-full flex items-center justify-center">
      <div className="flex flex-col items-center">
        <div className="flex justify-center mb-12">
          <img src="/images/empty-mentions.svg" alt="empty-mentions" className="w-64 h-[12rem]" />
        </div>
        <h2 className="m-0 mb-3 text-2xl font-semibold">No recent mentions</h2>
        <div className="text-center text-secondary">All of your recent mentions will&nbsp;show&nbsp;up&nbsp;here</div>
      </div>
    </div>
  );
};

export const MentionsLayout: FC<{ chatId: number | null }> = ({ chatId }) => {
  const chatChannelModel = useStore(CHAT_CHANNEL.$instance);
  const mentions = useStore(MENTIONS_MODEL.$viewMentions);

  useGate(MENTIONS_MODEL.MentionsGate, chatId);

  useEffect(() => {
    const id = chatChannelModel?.channel.id;
    if (!id) return;
    MENTIONS_MODEL.mentionsRead(Number(id));
  }, [chatChannelModel?.channel.id, mentions]);

  return chatChannelModel && mentions.length > 0 ? (
    <div className="h-full mobile-pan">
      <MentionsList mentions={mentions} chatChannelModel={chatChannelModel} />
    </div>
  ) : (
    <EmptyMentionsList />
  );
};
