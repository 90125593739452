import { USER_MODEL } from '@sp/data/auth';
import { $totalUnreadMessagesCount } from '@sp/data/global';
import { Avatar, Logo } from '@sp/ui/elements';
import { IconBinoculars, IconHome } from '@sp/ui/icons';
import { cls } from '@sp/util/helpers';
import { ROUTES } from '@sp/util/router';
import { useStore } from 'effector-react';
import { Link, NavLink } from 'react-router-dom';
import { useResolveActiveNavTab } from './use-resolve-active-nav-tab';

const LOGO_WIDTH = 56;

function navLinkClassname(isActive: boolean): string {
  return cls(
    `flex flex-col items-center no-underline w-1/4 py-3 relative`,
    `md:flex-row md:gap-2 md:p-4 md:w-auto`,
    isActive ? `md:border-b-2 border-emerald-500` : '',
  );
}

function navLinkTextClassname(isActive: boolean): string {
  return cls(`text-xs mt-1.5`, isActive ? `text-primary` : 'text-secondary');
}

export function MainNavigation() {
  const activeTab = useResolveActiveNavTab();
  const user = useStore(USER_MODEL.$user);
  const hasUnreadMessages = useStore($totalUnreadMessagesCount) > 0;

  return (
    <nav
      className={cls('w-full text-xl bg-white border-t border-stripe', 'md:relative md:text-base')}
      // FIXME[Dmitriy Teplov] this is needed or expert profile space cover bg
      style={{ zIndex: 1 }}
    >
      <div className="container mx-auto flex justify-between items-center gap-4 md:px-4">
        <Link to="/" className="hidden py-2 md:block">
          <Logo width={LOGO_WIDTH} />
        </Link>

        <div className="flex flex-grow justify-around items-center gap-4 md:justify-center">
          <NavLink to={ROUTES.HOME_URL} className={navLinkClassname(activeTab === 'home')}>
            {hasUnreadMessages && <span className="bg-new-message h-1 w-1 rounded-full -mt-1.5 mb-0.5"></span>}
            <IconHome size={24} filled={activeTab === 'home'} />
            <div className={navLinkTextClassname(activeTab === 'home')}>Communities</div>
          </NavLink>

          <NavLink
            to={`${ROUTES.DISCOVER_URL}/${ROUTES.DISCOVER.CHALLENGES}`}
            className={navLinkClassname(activeTab === 'discover')}
          >
            <IconBinoculars size={24} filled={activeTab === 'discover'} />
            <div className={navLinkTextClassname(activeTab === 'discover')}>Discover</div>
          </NavLink>

          <NavLink to={ROUTES.PROFILE_URL} className={navLinkClassname(activeTab === 'profile')}>
            <div
              className={cls(
                'flex',
                activeTab === 'profile'
                  ? `relative after:content-[''] after:absolute after:-top-[4px] after:-left-[4px] after:-bottom-[4px] after:-right-[4px] after:border-2 after:border-black after:rounded-full`
                  : null,
              )}
            >
              <Avatar size="xxs" src={user?.avatar?.url} name={user?.name ?? ''} />
            </div>
            <div className={navLinkTextClassname(activeTab === 'profile')}>Profile</div>
          </NavLink>
        </div>

        <div className="hidden md:block" style={{ width: LOGO_WIDTH }} />
      </div>
    </nav>
  );
}
