import { isNullish } from '@sp/util/helpers';
import { PostMeta, PostType } from '@sp/util/stream-chat';
import { useStore } from 'effector-react';
import { FC } from 'react';
import { CHALLENGE_MODEL } from '../challenge-model';
import { FinishPane, StepFormPane } from '../journey';
import { STEPS_MODEL } from '../steps';

export const MessagePostExtraContent: FC<{ selfplusPostMeta: PostMeta }> = ({ selfplusPostMeta }) => {
  const stepsWithUI = useStore(STEPS_MODEL.$stepsWithUI);
  const challenge = useStore(CHALLENGE_MODEL.$challenge);

  if (selfplusPostMeta.type === PostType.intro) return null;
  if (selfplusPostMeta.type === PostType.deferred) return null;

  if (selfplusPostMeta.type === PostType.finish) {
    const isShowFinishPane = isNullish(selfplusPostMeta.postId);
    return isShowFinishPane ? (
      <div className="mt-1">
        <FinishPane finishAt={challenge.finishAt} />
      </div>
    ) : null;
  }

  const stepWithUI = stepsWithUI.find(s => s.step.id === selfplusPostMeta.stepId);
  if (isNullish(stepWithUI)) return null;

  const isShowForm = isNullish(selfplusPostMeta.postId);

  return isShowForm ? (
    <div className="mt-1">
      <StepFormPane stepWithUI={stepWithUI} />
    </div>
  ) : null;
};
