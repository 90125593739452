import { useKeymap } from '@remirror/react';
import { baseKeymap } from 'prosemirror-commands';
import { useCallback } from 'react';

export const DesktopKeyBindings = ({ submit }: { submit: VoidFunction }) => {
  const onEnter = useCallback(() => {
    submit();
    return true;
  }, [submit]);

  const onShiftEnter = useCallback(({ state, dispatch, view }) => {
    baseKeymap['Enter'](state, dispatch, view);
    return true;
  }, []);

  useKeymap('Enter', onEnter);
  useKeymap('Shift-Enter', onShiftEnter);

  return null;
};
