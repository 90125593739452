import { EventType, FormType, PlatformPostData, PlatformPostType } from '@sp/data/bif';
import {
  ChatMessageAvatar,
  ChatMessageBody,
  ChatMessageBottomAttachments,
  ChatMessageHeader,
  ChatMessageProvider,
  ChatMessageReply,
  ChatMessageTopAttachments,
  mapAttachmentsToStream,
  MessageForm,
  mockChatModel,
  useMessageForm,
} from '@sp/feature/chat';
import { PermissionForm, RateForm } from '@sp/feature/platform-channel';
import {
  ChatMessageBodyContainer,
  ChatMessageContainer,
  ChatMessageLeftContainer,
  ChatMessageRightContainer,
} from '@sp/ui/chat';
import { Button, Input } from '@sp/ui/elements';
import { cls, isNotNullish, noop } from '@sp/util/helpers';
import { ChatAttachment, ChatFormatMessage } from '@sp/util/stream-chat';
import { useStore } from 'effector-react';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { formatDateTimeForInput, parseDateTimeInputValue } from '../challenge-editor/utils';
import { PLATFORM_EDITOR_MODEL } from './platform-editor-create-message-model';

export const PreviewPost: FC<{
  message?: ReturnType<typeof useMessageForm>['state']['text'];
  attachments?: ChatAttachment[];
  formType?: FormType;
}> = ({ message, attachments, formType }) => {
  const chatMessageProviderVal = useMemo(
    () => ({
      message: {
        id: '0',
        created_at: new Date(),
        pinned_at: null,
        status: '',
        updated_at: new Date(),
        attachments: attachments,
        text: message,
      },
      model: mockChatModel(),
    }),
    [attachments, message],
  );
  return (
    <div>
      <ChatMessageProvider value={chatMessageProviderVal}>
        <ChatMessageContainer className={cls('transition duration-500 pl-3 pr-6 py-3')}>
          <ChatMessageLeftContainer>
            <ChatMessageAvatar />
          </ChatMessageLeftContainer>

          <ChatMessageRightContainer>
            <ChatMessageHeader />
            <ChatMessageBodyContainer>
              <ChatMessageReply />
              <ChatMessageTopAttachments />
              <ChatMessageBody />
            </ChatMessageBodyContainer>
            <ChatMessageBottomAttachments />
            {formType === 'permission' ? <PermissionForm /> : null}
            {formType === 'rate' ? <RateForm /> : null}
          </ChatMessageRightContainer>
        </ChatMessageContainer>
      </ChatMessageProvider>
    </div>
  );
};

export const PlatformEditorCreateMessage: FC = () => {
  const postToEdit = useStore(PLATFORM_EDITOR_MODEL.$postToEdit);
  const [postType, setPostType] = useState(postToEdit?.type || PlatformPostType.onboarding);
  const [formType, setFormType] = useState<FormType>();
  const [eventType, setEventType] = useState<EventType | undefined>(EventType.tripleMarkAsComplete);
  const [publishAt, setPublishAt] = useState<string | undefined>(formatDateTimeForInput(Date.now()));
  const [isMuted, setIsMuted] = useState(true);
  const formModel = useMessageForm('platform-messages-editor');
  const navigate = useNavigate();
  const creating = useStore(PLATFORM_EDITOR_MODEL.creating);
  const updating = useStore(PLATFORM_EDITOR_MODEL.updating);
  const postCreationFinished = useStore(PLATFORM_EDITOR_MODEL.$postCreationFinished);
  const dateRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isNotNullish(postToEdit)) {
      setPostType(postToEdit.type);
      setEventType(postToEdit.event ? EventType[postToEdit.event] : undefined);
      setIsMuted(Boolean(postToEdit.content?.selfplusMeta?.isMuted));
      setFormType(postToEdit.content.selfplusMeta?.formType);
      if (isNotNullish(postToEdit.publishAt)) {
        setPublishAt(formatDateTimeForInput(postToEdit.publishAt));
      }
      formModel.edit(postToEdit.content as unknown as ChatFormatMessage);
    }
  }, [postToEdit]);
  useEffect(() => {
    if (postCreationFinished) goBack();
  }, [postCreationFinished]);

  useEffect(
    () => () => {
      formModel.reset();
      PLATFORM_EDITOR_MODEL.postToEditReset();
      PLATFORM_EDITOR_MODEL.postCreationFinishedReset();
    },
    [],
  );

  const prepareMessage = (): PlatformPostData => {
    const content = {
      selfplusMeta: { formType: formType, isMuted },
      text: formModel.state.text,
      attachments: mapAttachmentsToStream(formModel.attachmentManager.attachments),
    };
    return {
      content,
      event: postType === 'afterEvent' ? eventType : undefined,
      type: postType,
      publishAt:
        postType === 'deferred' && publishAt && publishAt !== '' ? parseDateTimeInputValue(publishAt)?.getTime() : null,
    };
  };

  const goBack = () => {
    navigate('../');
  };

  return (
    <div className="flex flex-col justify-end h-full">
      <div className="flex w-full gap-2 p-4">
        <div className="flex w-[31%] flex-col">
          <label className="text-xs text-secondary">Type</label>
          <select value={postType} onChange={e => setPostType(e.target.value as PlatformPostType)} placeholder="Type">
            <option value={PlatformPostType.onboarding}>Onboarding</option>
            <option value={PlatformPostType.afterEvent}>After event</option>
            <option value={PlatformPostType.realtime}>Realtime</option>
            <option value={PlatformPostType.deferred}>Deferred</option>
          </select>
        </div>

        <div className="flex w-[31%] flex-col">
          <label className="text-xs text-secondary">Form</label>
          <select value={formType} onChange={e => setFormType(e.target.value as FormType)} placeholder="Type">
            <option value={undefined}>None</option>
            <option value={FormType.permission}>Permission</option>
            <option value={FormType.rate}>Rate</option>
          </select>
        </div>

        {postType === PlatformPostType.afterEvent ? (
          <div className="flex w-[31%] flex-col">
            <label className="text-xs text-secondary">Event</label>
            <select value={eventType} onChange={e => setEventType(e.target.value as EventType)} placeholder="Type">
              <option value={EventType.tripleMarkAsComplete}>Triple mark as complete</option>
            </select>
          </div>
        ) : null}
      </div>
      <div className="flex w-full gap-2 px-4 pb-4">
        {postType === PlatformPostType.deferred ? (
          <div className="flex w-[62%] flex-col">
            <label className="text-xs text-secondary">Publish date</label>
            <Input
              ref={dateRef}
              placeholder="Publish immediately"
              min={formatDateTimeForInput(Date.now())}
              value={publishAt}
              onChange={({ target: { value } }) => {
                setPublishAt(value);
              }}
              type="datetime-local"
              block
            />
          </div>
        ) : null}
        <div className="flex w-[31%] flex-col">
          <label className="text-xs text-secondary">Is muted</label>
          <input
            className="w-10 h-10"
            type={'checkbox'}
            checked={isMuted}
            onChange={v => setIsMuted(v.target.checked)}
          />
        </div>
      </div>
      <div className="text-secondary p-4">Preview</div>
      <div className="flex-1 overflow-y-scroll flex flex-col mb-4 ">
        <PreviewPost
          attachments={formModel.attachmentManager.attachments?.map(a => ({ selfplusAttachment: a }))}
          message={formModel.state.text}
          formType={formType}
        />
      </div>
      <div className="p-4 border-t border-stripe flex justify-around">
        <Button
          loading={creating || updating}
          color="primary"
          onClick={() => {
            const message = prepareMessage();
            if (formModel.state.isEditing && postToEdit) {
              PLATFORM_EDITOR_MODEL.postUpdated({ postId: postToEdit.id, data: message });
            } else {
              PLATFORM_EDITOR_MODEL.postCreated(message);
            }
          }}
        >
          Save
        </Button>
        <Button onClick={goBack}>Cancel</Button>
      </div>
      <MessageForm submit={noop} isLoading={false} model={formModel} editorMaxHeight="20vh" />
    </div>
  );
};
