import { useUser } from '@sp/data/auth';
import { Button, Input } from '@sp/ui/elements';
import { FullScreenImageCropper, IMAGE_CROP_FILE_TYPES, ImageCropperMode } from '@sp/ui/image-cropper';
import { dataURItoBlob } from '@sp/util/files';
import { cls, isNotNullish, Nullable } from '@sp/util/helpers';
import { useStore } from 'effector-react';
import { FC, useCallback, useEffect, useMemo, useState, VFC } from 'react';
import { useFilePicker } from 'use-file-picker';
import { UserAvatar } from './edit-user-info-form';
import { OWNED_PROFILE_MODEL, UserFieldsPatch } from './owned-profile-model';

const CompleteRegistrationForm: VFC = () => {
  const user = useUser();
  const isUpdating = useStore(OWNED_PROFILE_MODEL.$isUpdating);
  const [nameField, setNameField] = useState('');
  const [selectedAvatar, setSelectedAvatar] = useState<Nullable<File>>(null);
  const isNewAvatarSelected = isNotNullish(selectedAvatar);
  const selectedAvatarDataUri = useMemo(
    () => (isNewAvatarSelected ? URL.createObjectURL(selectedAvatar) : ''),
    [isNewAvatarSelected, selectedAvatar],
  );
  const [resizedAvatar, setResizedAvatar] = useState<Nullable<File>>(null);
  const isAvatarChanged = isNotNullish(resizedAvatar);
  const resizedAvatarDataUri = useMemo(
    () => (isAvatarChanged ? URL.createObjectURL(resizedAvatar) : null),
    [isAvatarChanged, resizedAvatar],
  );
  const avatarImage = resizedAvatarDataUri ?? user.avatar?.url ?? null;
  const [openFilePicker, { plainFiles, clear }] = useFilePicker({
    accept: IMAGE_CROP_FILE_TYPES,
    multiple: false,
    readFilesContent: false,
  });

  const isNameChanged = nameField != user.name;
  const canSubmit = isNotNullish(nameField) && nameField !== '';

  useEffect(() => {
    if (user.id !== -1) {
      setNameField(user.name);
    }
  }, [user.id, user.name]);

  useEffect(() => {
    if (plainFiles.length > 0) {
      setSelectedAvatar(plainFiles[0]);
      clear();
    }
  }, [clear, plainFiles]);

  const submit = useCallback(() => {
    if (canSubmit) {
      const patch: UserFieldsPatch = {};
      if (isNameChanged) {
        patch.name = nameField;
      }
      if (isAvatarChanged) {
        patch.avatar = resizedAvatar;
      }
      OWNED_PROFILE_MODEL.updateUser(patch);
    }
  }, [canSubmit, isAvatarChanged, isNameChanged, nameField, resizedAvatar]);

  const onResizeAvatarResult = useCallback(
    (resizedAvatarUri: string) => {
      if (isNotNullish(selectedAvatar)) {
        const name = selectedAvatar.name;
        const type = selectedAvatar.type;
        const newAvatar = new File([dataURItoBlob(resizedAvatarUri)], name, { type });
        setResizedAvatar(newAvatar);
        setSelectedAvatar(null);
      }
    },
    [selectedAvatar],
  );

  function onCancelResizeAvatar() {
    setSelectedAvatar(null);
    setResizedAvatar(null);
  }

  return (
    <div className="w-full h-full flex flex-col items-center justify-around p-10">
      <div className="flex justify-center">
        <img src="/images/selfplus-logo.svg" />
      </div>

      <div className="w-full">
        <h2 className="text-center mb-2">It&apos;s nice to meet you&nbsp;👏</h2>
        <p className="text-secondary text-center m-0 mt-2 mb-6">
          Add your photo.
          <br />
          It helps other app members and creators to recognize you 🤩
        </p>

        <div className="flex flex-col gap-2 items-center mb-2">
          <button
            type="button"
            className={cls('select-none', avatarImage ? '' : 'animate-pulse')}
            onClick={openFilePicker}
          >
            <UserAvatar url={avatarImage} />
          </button>

          <button className="w-fit text-brand font-semibold" type="button" onClick={openFilePicker}>
            {isNotNullish(user.avatar?.url) ? 'Change profile photo' : 'Upload a photo'}
          </button>
        </div>

        <div className="mb-6">
          <label htmlFor="edit-user-name" className="block mb-2">
            Name
          </label>
          <Input
            type="text"
            placeholder="Type your name"
            value={nameField}
            onChange={({ target }) => setNameField(target.value)}
            block
            id="edit-user-name"
          />
        </div>

        <Button
          disabled={isUpdating || !canSubmit}
          loading={isUpdating}
          onClick={submit}
          color="black"
          block
          className="mb-2"
        >
          Save
        </Button>
      </div>
      <FullScreenImageCropper
        isOpen={isNewAvatarSelected}
        src={selectedAvatarDataUri}
        onResult={onResizeAvatarResult}
        onCancel={onCancelResizeAvatar}
        mode={ImageCropperMode.avatar}
      />
    </div>
  );
};

export const CompleteRegistrationScreen: FC = ({ children }) => {
  const user = useUser();
  if (user.isRegistrationCompleted) return <>{children}</>;
  return <CompleteRegistrationForm />;
};
