import { MeUser } from '@sp/data/bif';
import { useStore } from 'effector-react';
import { createContext, ReactElement, useContext } from 'react';
import { USER_MODEL } from './user-model';

const UserContext = createContext<MeUser>({} as MeUser);

export function useUser() {
  return useContext(UserContext);
}

export function UserProvider({ children }: { children: ReactElement | ReactElement[] }): ReactElement | null {
  const user = useStore(USER_MODEL.$user);
  const isLoading = useStore(USER_MODEL.$isFetching);

  if (isLoading && user === null) {
    return null;
  }

  if (user == null) {
    console.error("Can't use UserContext without authenticated user.");
    return null;
  }

  return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
}
