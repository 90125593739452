import { ReactElement } from 'react';
import { ICON_DEFAULTS, IconProps } from './types';

export function IconTwitter({
  size = ICON_DEFAULTS.size,
  className = ICON_DEFAULTS.className,
}: IconProps): ReactElement {
  return (
    <svg
      width={size}
      height={size}
      className={className}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" rx="16" fill="#4A9AE8" />
      <path
        d="M15.9986 12.2503C15.9986 10.1878 17.7329 8.47222 19.7954 8.50034C20.5177 8.50868 21.2222 8.72545 21.8243 9.1246C22.4263 9.52375 22.9003 10.0883 23.1892 10.7503H26.4986L23.4704 13.7785C23.275 16.8203 21.9285 19.6734 19.7047 21.758C17.4808 23.8425 14.5466 25.0018 11.4986 25.0003C8.49855 25.0003 7.74855 23.8753 7.74855 23.8753C7.74855 23.8753 10.7486 22.7503 12.2486 20.5003C12.2486 20.5003 6.24855 17.5003 7.74855 9.25034C7.74855 9.25034 11.4986 13.0003 15.9986 13.7503V12.2503Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
