import { ReactElement } from 'react';
import { ICON_DEFAULTS, IconProps } from './types';

export function IconFile({
  size = ICON_DEFAULTS.size,
  color = ICON_DEFAULTS.color,
  className = ICON_DEFAULTS.className,
}: IconProps): ReactElement {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M15.625 17.4998H4.375C4.20924 17.4998 4.05027 17.4339 3.93306 17.3167C3.81585 17.1995 3.75 17.0405 3.75 16.8748V3.12476C3.75 2.959 3.81585 2.80002 3.93306 2.68281C4.05027 2.5656 4.20924 2.49976 4.375 2.49976H11.875L16.25 6.87476V16.8748C16.25 17.0405 16.1842 17.1995 16.0669 17.3167C15.9497 17.4339 15.7908 17.4998 15.625 17.4998Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M11.875 2.49976V6.87476H16.25" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
