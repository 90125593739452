import { ReactElement } from 'react';
import { ICON_DEFAULTS, IconProps } from './types';

export function IconTiktok({
  size = ICON_DEFAULTS.size,
  className = ICON_DEFAULTS.className,
}: IconProps): ReactElement {
  return (
    <svg
      width={size}
      height={size}
      className={className}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" rx="16" fill="url(#paint0_radial_6893_13449)" />
      <rect width="32" height="32" rx="16" fill="#1D0D1B" />
      <path
        d="M19.75 13.9375C21.28 15.0361 23.1164 15.6263 25 15.625V11.875C23.6076 11.875 22.2723 11.3219 21.2877 10.3373C20.3031 9.35274 19.75 8.01739 19.75 6.625H16V18.625C16 19.0948 15.8739 19.5559 15.6349 19.9603C15.3959 20.3647 15.0528 20.6976 14.6413 20.9242C14.2298 21.1508 13.765 21.2629 13.2955 21.2486C12.8259 21.2344 12.3688 21.0944 11.9718 20.8433C11.5748 20.5922 11.2524 20.2392 11.0384 19.821C10.8243 19.4029 10.7264 18.9349 10.7548 18.466C10.7832 17.9971 10.937 17.5445 11.1999 17.1552C11.4629 16.766 11.8256 16.4544 12.25 16.2531V12.3531C11.0562 12.5672 9.94816 13.1173 9.05605 13.939C8.16394 14.7607 7.52474 15.8198 7.21349 16.9921C6.90224 18.1643 6.93185 19.4011 7.29885 20.5571C7.66585 21.7131 8.355 22.7404 9.28541 23.5185C10.2158 24.2965 11.3489 24.793 12.5516 24.9497C13.7543 25.1063 14.9767 24.9166 16.0754 24.4029C17.1741 23.8891 18.1034 23.0726 18.7543 22.0492C19.4052 21.0257 19.7506 19.8379 19.75 18.625V13.9375Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <radialGradient
          id="paint0_radial_6893_13449"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(27.04 36.32) rotate(-115.776) scale(43.6379 64.9064)"
        >
          <stop offset="0.24392" stopColor="#FF1B90" />
          <stop offset="0.436673" stopColor="#F80261" />
          <stop offset="0.688476" stopColor="#ED00C0" />
          <stop offset="0.776787" stopColor="#C500E9" />
          <stop offset="0.893155" stopColor="#7017FF" />
        </radialGradient>
      </defs>
    </svg>
  );
}
