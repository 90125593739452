import { memo, ReactElement } from 'react';
import { ICON_DEFAULTS, IconProps } from './types';

export const IconTextBolder = memo(function IconTextBolder({
  size = ICON_DEFAULTS.size,
  color = ICON_DEFAULTS.color,
  className = ICON_DEFAULTS.className,
}: IconProps): ReactElement {
  return (
    <svg
      width={size}
      height={size}
      className={className}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 7.5H9.5C10.163 7.5 10.7989 7.76339 11.2678 8.23223C11.7366 8.70107 12 9.33696 12 10C12 10.663 11.7366 11.2989 11.2678 11.7678C10.7989 12.2366 10.163 12.5 9.5 12.5H4V3H8.75C9.34674 3 9.91903 3.23705 10.341 3.65901C10.7629 4.08097 11 4.65326 11 5.25C11 5.84674 10.7629 6.41903 10.341 6.84099C9.91903 7.26295 9.34674 7.5 8.75 7.5"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
});
