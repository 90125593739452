import { ShowUserCardPayload, USER_PROFILE_CARD_SHOW_MODEL } from '@sp/data/user-profile-card';
import { ChatChannelModel } from '@sp/feature/stream-chat';
import { Nullable } from '@sp/util/helpers';
import { ChatFormatMessage } from '@sp/util/stream-chat';
import { createEvent, createStore, sample } from 'effector';
import { createContext, FC, useContext } from 'react';

export type ChatMessageModel = Pick<
  ChatChannelModel,
  'reactionAdded' | 'reactionDeleted' | 'jumpedToMessage' | '$highlightedMessageId' | 'showUserCard'
>;

interface ChatMessageContext {
  readonly message: ChatFormatMessage;
  readonly model: ChatMessageModel;
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
const context = createContext<ChatMessageContext>(null);

export const ChatMessageProvider: FC<Readonly<{ value: ChatMessageContext }>> = ({ value, children }) => {
  return <context.Provider value={value}>{children}</context.Provider>;
};

export const useChatMessage = () => useContext(context);

export function mockChatModel(): ChatMessageModel {
  const showUserCard = createEvent<ShowUserCardPayload>();
  sample({
    source: showUserCard,
    target: USER_PROFILE_CARD_SHOW_MODEL.show,
  });

  return {
    reactionAdded: createEvent(),
    reactionDeleted: createEvent(),
    jumpedToMessage: createEvent(),
    $highlightedMessageId: createStore<Nullable<string>>(null),
    showUserCard,
  };
}
