import { Button } from '@sp/ui/elements';
import { IconUserCirclePlus } from '@sp/ui/icons';
import { USE_LONG_PRESS_OPTIONS } from '@sp/util/config';
import { cls, noop } from '@sp/util/helpers';
import { KnownReaction } from '@sp/util/stream-chat';
import { CSSProperties, ReactElement, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useLongPress } from 'use-long-press';
import { isKnownReaction, REACTION_REGISTRY } from './reactions-registry';

const backdropStyles: CSSProperties = {
  position: 'fixed',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'transparent',
};

const buttonClasses = 'flex items-center font-medium px-2 py-0 h-8 rounded-lg gap-1 whitespace-nowrap';
const inactiveButtonClasses = 'bg-light text-secondary border border-transparent';

const activeDropdownButtonClasses =
  'relative ' +
  'border border-stripe ' +
  'after:absolute after:z-[1000] after:left-[-1px] after:box-content ' +
  'after:bg-light after:border-stripe after:border-l after:border-r ' +
  'after:h-[15px] after:w-full';

const activeBottomDropdownClasses = cls(activeDropdownButtonClasses, 'after:-bottom-[10px]');
const activeTopDropdownClasses = cls(activeDropdownButtonClasses, 'after:-top-[10px]');

export interface MessageReaction {
  readonly type: string | KnownReaction;
  readonly count: number;
  readonly isActive: boolean;
}

type HorizontalPosition = { left: number; right?: never } | { left?: never; right: number };
type VerticalPosition = { top: number; bottom?: never } | { top?: never; bottom: number };
type DropdownPosition = Readonly<HorizontalPosition & VerticalPosition>;

const DEFAULT_DROPDOWN_POSITION: DropdownPosition = { top: 0, left: 0 };

const DROPDOWN_OFFSET = 8 as const;

export function ChatMessageReactionsView({
  reactions,
  onToggleReaction,
  onShowDetails,
}: Readonly<{
  reactions: readonly MessageReaction[];
  onToggleReaction: (reaction: string) => void;
  onShowDetails: () => void;
}>): ReactElement {
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const dropdownButtonRef = useRef<HTMLButtonElement>(null);
  const [dropdownPosition, setDropdownPosition] = useState<DropdownPosition>(DEFAULT_DROPDOWN_POSITION);
  const isDirectedToBottom = useMemo(() => 'top' in dropdownPosition, [dropdownPosition]);

  const rootNode = document.getElementById('root');
  const reactionsDropdown = rootNode
    ? createPortal(
        <div style={{ position: 'relative', zIndex: 100 }}>
          <div style={backdropStyles} onClick={closeDropdown} />
          <div
            className="fixed z-10 bg-light rounded-lg py-4 px-2 left-10 border border-stripe"
            style={{ maxWidth: 'calc(100vw - 24px)', ...dropdownPosition }}
          >
            <div className="mobile-pan overflow-x-auto pb-2 -mb-2 flex gap-3">
              {Object.entries(REACTION_REGISTRY).map(([reaction, config]) => (
                <Button
                  key={reaction}
                  color="transparent"
                  onClick={() => {
                    onToggleReaction(reaction);
                    closeDropdown();
                  }}
                  className="!p-0 !flex-none"
                >
                  {config.body}
                </Button>
              ))}
            </div>
          </div>
        </div>,

        rootNode,
      )
    : null;

  function closeDropdown() {
    setIsDropdownOpen(false);
  }

  function open() {
    const button = dropdownButtonRef.current;

    if (button) {
      const isAtTopTwoThirds = button.getBoundingClientRect().bottom < window.innerHeight / 1.5;

      const xPos: HorizontalPosition = { left: 12 };

      const yPos: VerticalPosition = isAtTopTwoThirds
        ? { top: button.getBoundingClientRect().bottom + DROPDOWN_OFFSET }
        : { bottom: window.innerHeight - button.getBoundingClientRect().top + DROPDOWN_OFFSET };

      setDropdownPosition({ ...xPos, ...yPos });
    } else {
      setDropdownPosition(DEFAULT_DROPDOWN_POSITION);
    }

    setIsDropdownOpen(true);
  }

  const bindLongPress = useLongPress(onShowDetails ?? noop, USE_LONG_PRESS_OPTIONS);

  return (
    <>
      <div className="flex gap-1 flex-wrap">
        {reactions.map(({ type, count, isActive }) => {
          const body = isKnownReaction(type) ? (
            <span className="text-base leading-4">{REACTION_REGISTRY[type].body}</span>
          ) : (
            <span className="text-[0.5rem] leading-4 overflow-hidden text-ellipsis">{type}</span>
          );

          return (
            <div key={type} onTouchStart={e => e.stopPropagation()} onMouseDown={e => e.stopPropagation()}>
              <button
                type="button"
                onClick={() => onToggleReaction(type)}
                onContextMenu={e => {
                  e.preventDefault();
                }}
                {...bindLongPress()}
                className={cls(buttonClasses, isActive ? 'bg-brand-medium text-active' : inactiveButtonClasses)}
              >
                <span className="leading-4">{count}</span>
                {body}
              </button>
            </div>
          );
        })}

        <button
          ref={dropdownButtonRef}
          type="button"
          onClick={open}
          className={cls(
            buttonClasses,
            inactiveButtonClasses,
            isDropdownOpen && (isDirectedToBottom ? activeBottomDropdownClasses : activeTopDropdownClasses),
          )}
        >
          <IconUserCirclePlus size={24} />
        </button>
      </div>

      {isDropdownOpen && reactionsDropdown}
    </>
  );
}
