import { useUser } from '@sp/data/auth';
import { CommunityLive } from '@sp/data/bif';
import { SELFPLUS_WEBSITE_URL } from '@sp/data/env';
import { GlobalMediaPlayerModel } from '@sp/data/global';
import { ANALYTICS, useSetAnalyticsContext } from '@sp/feature/analytics';
import { AudioPlayer } from '@sp/feature/challenge';
import { ReactionsModal } from '@sp/feature/chat';
import { COMMUNITY_NOTIFIER_MODEL } from '@sp/feature/community-notifier';
import { InCallView, LiveContent } from '@sp/feature/live-channel';
import { MENTIONS_MODEL, MentionsLayout } from '@sp/feature/mentions';
import { UserProfileCard } from '@sp/feature/profile';
import { ChatTitle, Header, LiveTitle, MentionsTitle } from '@sp/feature/space';
import { SpinnerPage } from '@sp/ui/elements';
import { TabPane, Tabs } from '@sp/ui/tabs';
import { GRAY_PIXEL_IMG_SRC } from '@sp/util/files';
import { isNotNullish } from '@sp/util/helpers';
import { parseFromParam, ROUTES } from '@sp/util/router';
import { useStore } from 'effector-react';
import { useEffect, useState, VFC } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { CommunityChat } from './community-chat';
import { CommunityDescriptionModal, openDescriptionModal } from './community-description-modal';
import { COMMUNITY_MODEL } from './community-model';
import { FeedLayout } from './feed/feed';
import { FEED_MODEL } from './feed/feed-model';

const THREE_HOURS = 3 * 60 * 60 * 1000;

function pickClosestLive(lives: CommunityLive[]): CommunityLive | null {
  return (lives || []).find(live => live.startAt + live.duration * 60 * 1000 + THREE_HOURS > Date.now()) || null;
}

export const CommunityLayout: VFC<Readonly<{ communityId: number; onBackClick: () => void; tabKey: string }>> = ({
  communityId,
  tabKey,
}) => {
  const navigate = useNavigate();
  const chatModel = useStore(COMMUNITY_MODEL.$chatModel);
  const community = useStore(COMMUNITY_MODEL.$community);
  const isLoading = useStore(COMMUNITY_MODEL.$isLoading);
  const [searchParams] = useSearchParams();
  const fromParam = parseFromParam(searchParams);
  const activeAudioPlayer = useStore(GlobalMediaPlayerModel.$player);
  const mentionsUnviewedCount = useStore(MENTIONS_MODEL.$unviewedCount);
  const user = useUser();
  const [isInCall, setIsInCall] = useState(false);
  const isOwner = useStore(COMMUNITY_MODEL.$isOwner);
  const [animateFeedTab, setAnimateFeedTab] = useState<boolean>();

  useSetAnalyticsContext(
    isNotNullish(community)
      ? {
          'Space ID': community?.id.toString() ?? '',
          'Space Name': community?.title.toString() ?? '',
          'Creator ID': community?.creator?.id.toString() ?? '',
          'Creator Name': community?.creator?.name.toString() ?? '',
          From: fromParam,
          Type: 'Community',
        }
      : null,
  );

  useEffect(() =>
    COMMUNITY_NOTIFIER_MODEL.shouldAnimateTab.watch(() => {
      setAnimateFeedTab(true);
    }),
  );

  useEffect(() => {
    ANALYTICS.spaceOpenTrackingStart();
    return () => ANALYTICS.spaceCloseTracked();
  }, []);

  useEffect(() => {
    COMMUNITY_MODEL.init(communityId);
  }, [communityId]);

  useEffect(() => {
    return () => {
      COMMUNITY_MODEL.destroy();
      GlobalMediaPlayerModel.close();
    };
  }, []);

  function onTabChange(key: string) {
    navigate(`${ROUTES.COMMUNITY_PAGE_URL}/${communityId}/${key}`, { replace: true });
  }

  useEffect(() => {
    FEED_MODEL.getPosts(chatModel?.channel);
    return () => FEED_MODEL.resetPosts();
  }, [chatModel?.channel]);

  if (!community || !chatModel || isLoading) return <SpinnerPage />;

  const live = pickClosestLive(community.lives);

  return (
    <div className="h-full flex flex-col">
      <Header
        name={community?.creator?.name}
        creatorId={community.creator?.id}
        title={community?.title || ''}
        members={community.membersCount}
        avatar={community.cover?.url ?? GRAY_PIXEL_IMG_SRC}
        urlToShare={`${SELFPLUS_WEBSITE_URL}/${community?.userSlug}`}
        onClick={openDescriptionModal}
      />

      <div className="flex-1">
        <Tabs
          onChange={onTabChange}
          animateTab={animateFeedTab ? 'feed' : undefined}
          onAnimationEnd={() => setAnimateFeedTab(false)}
          activeKey={tabKey}
          activeTabTextColor="text-primary"
        >
          <TabPane title={<ChatTitle />} key="chat">
            <CommunityChat />
          </TabPane>
          <TabPane title="Feed" key="feed">
            <div className="relative h-full w-full">
              <AudioPlayer player={activeAudioPlayer} />
              <FeedLayout isOwner={isOwner} />
            </div>
          </TabPane>
          {live ? (
            <TabPane title={<LiveTitle duration={live.duration} date={live.startAt} />} key="live">
              <div className="relative h-full w-full">
                {isInCall ? (
                  <InCallView linkToZoom={live.linkToZoom} creator={community.creator} user={user} />
                ) : (
                  <>
                    <AudioPlayer player={activeAudioPlayer} />
                    <LiveContent
                      live={live}
                      cover={community.cover}
                      title={community.title}
                      showTestimonials={false}
                      creator={community.creator}
                      onEnterCall={() => setIsInCall(true)}
                    />
                  </>
                )}
              </div>
            </TabPane>
          ) : null}
          <TabPane title={<MentionsTitle unviewedCount={mentionsUnviewedCount} />} key="mentions">
            <div className="relative h-full w-full">
              <AudioPlayer player={activeAudioPlayer} />
              <MentionsLayout chatId={community?.chatId || null} />
            </div>
          </TabPane>
        </Tabs>
      </div>

      <UserProfileCard cardContext="space" />
      <ReactionsModal />
      <CommunityDescriptionModal />
    </div>
  );
};
