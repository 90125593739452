import { Button } from '@sp/ui/elements';

import { Modal } from '@sp/ui/modal';
import { useStore } from 'effector-react';
import { FC } from 'react';
import { LINKS_FORM_MODEL } from './links-form-model';
import { LINKS_MODEL } from './links-model';

const AddLinkModalContent: FC = () => {
  const title = useStore(LINKS_FORM_MODEL.$title);
  const href = useStore(LINKS_FORM_MODEL.$href);
  const modalContent = useStore(LINKS_MODEL.$modalContent);
  const isLinkLoading = useStore(LINKS_MODEL.$isLinkLoading);
  const link = useStore(LINKS_FORM_MODEL.$link);
  return (
    <div>
      <div className="flex gap-4 items-center py-3 pb-4 pt-0 w-full">
        <h4 className="m-0">{modalContent === 'addLink' ? 'Add' : 'Edit'} a custom link</h4>
      </div>

      <form
        noValidate={true}
        onSubmit={e => {
          e.preventDefault();
          LINKS_FORM_MODEL.linkFormSubmitted(link || null);
        }}
      >
        <label>
          <span>Link title</span>
          <input
            type="text"
            value={title}
            onChange={e => LINKS_FORM_MODEL.titleChanged(e.target.value)}
            placeholder="My website"
            className=" w-full my-2 border border-stroke rounded-lg p-3 mb-6"
          />
        </label>
        <label>
          <span>URL</span>
          <input
            type="url"
            value={href}
            onChange={e => LINKS_FORM_MODEL.hrefChanged(e.target.value)}
            placeholder="mywebsite.com"
            className=" w-full my-2 border border-stroke rounded-lg p-3 mb-6"
          />
        </label>

        <Button color="black" loading={isLinkLoading} type="submit" block className="my-2">
          {modalContent === 'addLink' ? 'Add' : 'Save'}
        </Button>
        <Button
          color="transparent"
          onClick={() => LINKS_MODEL.setModalVisible(false)}
          block
          type="button"
          className="mt-2"
        >
          Cancel
        </Button>
      </form>
    </div>
  );
};

export const EditLinksModal: FC = () => {
  const modalVisible = useStore(LINKS_MODEL.$modalVisible);
  return (
    <Modal isOpen={modalVisible} withBackdrop closeOnBackdropClick onClose={() => LINKS_MODEL.setModalVisible(false)}>
      <AddLinkModalContent />
    </Modal>
  );
};
