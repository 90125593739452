import { KnownCardBrand } from '@sp/data/bif';
import { Input } from '@sp/ui/elements';
import { useStore } from 'effector-react';
import { FC } from 'react';
import { CONFIRM_CARD_MODEL } from './confirm-card-model';

function getBrandName(brand: KnownCardBrand): string {
  switch (brand) {
    case 'visa':
      return 'Visa';
    case 'mastercard':
      return 'Mastercard';
    case 'amex':
      return 'American Express';
    case 'discover':
      return 'Discover';
    case 'jcb':
      return 'JCB';
    case 'diners':
      return 'Diners';
    case 'unionpay':
      return 'UnionPay';
    case 'cartes_bancaires':
      return 'Cartes Bancaires';
  }
}

function getCardLogo(brand: KnownCardBrand): string {
  return `/images/payment-cards-logos/${brand}.svg`;
}

export const SavedCard: FC<{
  lastDigits: string;
  expMonth: number;
  expYear: number;
  brand: KnownCardBrand;
}> = ({ brand, lastDigits }) => {
  const isUseSavedCard = useStore(CONFIRM_CARD_MODEL.$isUseSavedCard);

  return (
    <div className="flex flex-col items-center">
      <div className="py-5 w-full flex gap-3 items-center text-sm border-stripe border-b">
        {/* eslint-disable react-hooks/rules-of-hooks */}
        <Input
          type="radio"
          checked={isUseSavedCard}
          onChange={e => e.target.checked && CONFIRM_CARD_MODEL.useSavedCard()}
          id="use-saved-card"
        />
        <label htmlFor="use-saved-card" className="flex gap-1 items-center">
          <img className="w-6 h-4" src={getCardLogo(brand)} alt={brand} />
          <span>{getBrandName(brand)}</span>
          <span>··</span>
          <span>{lastDigits}</span>
        </label>
      </div>
      <div className="py-5 w-full flex gap-3 items-center text-sm">
        <Input
          type="radio"
          checked={!isUseSavedCard}
          onChange={e => e.target.checked && CONFIRM_CARD_MODEL.useNewCard()}
          id="change-card"
        />
        <label htmlFor="change-card">Add a new card</label>
      </div>
    </div>
  );
};
