/* tslint:disable */
/* eslint-disable */
/**
 * Default
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 *
 * @export
 * @interface AuthCodePostRequest
 */
export interface AuthCodePostRequest {
  /**
   *
   * @type {string}
   * @memberof AuthCodePostRequest
   */
  redirectUri?: string;
}
/**
 *
 * @export
 * @interface AuthLoginPost200Response
 */
export interface AuthLoginPost200Response {
  /**
   *
   * @type {string}
   * @memberof AuthLoginPost200Response
   */
  authToken?: string;
}
/**
 *
 * @export
 * @interface AuthLoginPostRequest
 */
export interface AuthLoginPostRequest {
  /**
   *
   * @type {string}
   * @memberof AuthLoginPostRequest
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof AuthLoginPostRequest
   */
  password?: string;
}
/**
 *
 * @export
 * @interface AuthMagicPost200Response
 */
export interface AuthMagicPost200Response {
  /**
   *
   * @type {string}
   * @memberof AuthMagicPost200Response
   */
  success?: string;
  /**
   *
   * @type {AuthMagicPost200ResponseUserId}
   * @memberof AuthMagicPost200Response
   */
  userId?: AuthMagicPost200ResponseUserId;
}
/**
 *
 * @export
 * @interface AuthMagicPost200ResponseUserId
 */
export interface AuthMagicPost200ResponseUserId {
  /**
   *
   * @type {number}
   * @memberof AuthMagicPost200ResponseUserId
   */
  id?: number;
}
/**
 *
 * @export
 * @interface AuthMagicPostRequest
 */
export interface AuthMagicPostRequest {
  /**
   *
   * @type {string}
   * @memberof AuthMagicPostRequest
   */
  redirect_uri?: string;
  /**
   *
   * @type {string}
   * @memberof AuthMagicPostRequest
   */
  auth_uri?: string | null;
  /**
   *
   * @type {object}
   * @memberof AuthMagicPostRequest
   */
  metadata?: object | null;
  /**
   *
   * @type {string}
   * @memberof AuthMagicPostRequest
   */
  local_dev_token?: string | null;
  /**
   *
   * @type {string}
   * @memberof AuthMagicPostRequest
   */
  email?: string;
}
/**
 *
 * @export
 * @interface AuthMeGet200Response
 */
export interface AuthMeGet200Response {
  /**
   *
   * @type {number}
   * @memberof AuthMeGet200Response
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof AuthMeGet200Response
   */
  created_at?: number;
  /**
   *
   * @type {string}
   * @memberof AuthMeGet200Response
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof AuthMeGet200Response
   */
  name?: string;
  /**
   *
   * @type {boolean}
   * @memberof AuthMeGet200Response
   */
  isAdmin?: boolean | null;
  /**
   *
   * @type {string}
   * @memberof AuthMeGet200Response
   */
  bio?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof AuthMeGet200Response
   */
  isEmailConfirmed?: boolean;
  /**
   *
   * @type {string}
   * @memberof AuthMeGet200Response
   */
  description?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof AuthMeGet200Response
   */
  isRegistrationCompleted?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof AuthMeGet200Response
   */
  isFirstChallengeVisited?: boolean;
  /**
   *
   * @type {Array<number>}
   * @memberof AuthMeGet200Response
   */
  pinnedChatsIds?: Array<number> | null;
  /**
   *
   * @type {Array<number>}
   * @memberof AuthMeGet200Response
   */
  archivedChatsIds?: Array<number> | null;
  /**
   *
   * @type {boolean}
   * @memberof AuthMeGet200Response
   */
  isPaymentMethodAdditionSkipped?: boolean;
  /**
   *
   * @type {object}
   * @memberof AuthMeGet200Response
   */
  gbFeatures?: object | null;
  /**
   *
   * @type {string}
   * @memberof AuthMeGet200Response
   */
  gbUuid?: string | null;
  /**
   *
   * @type {AuthMeGet200ResponseNotifications}
   * @memberof AuthMeGet200Response
   */
  notifications?: AuthMeGet200ResponseNotifications;
  /**
   *
   * @type {AuthMeGet200ResponsePaymentMethod}
   * @memberof AuthMeGet200Response
   */
  paymentMethod?: AuthMeGet200ResponsePaymentMethod | null;
  /**
   *
   * @type {boolean}
   * @memberof AuthMeGet200Response
   */
  isExpert?: boolean;
  /**
   *
   * @type {number}
   * @memberof AuthMeGet200Response
   */
  subscriptionPrice?: number | null;
  /**
   *
   * @type {string}
   * @memberof AuthMeGet200Response
   */
  slug?: string;
  /**
   *
   * @type {Array<AuthMeGet200ResponseLinksInner>}
   * @memberof AuthMeGet200Response
   */
  links?: Array<AuthMeGet200ResponseLinksInner>;
  /**
   *
   * @type {AuthMeGet200ResponseAvatar}
   * @memberof AuthMeGet200Response
   */
  avatar?: AuthMeGet200ResponseAvatar | null;
}
/**
 *
 * @export
 * @interface AuthMeGet200ResponseAvatar
 */
export interface AuthMeGet200ResponseAvatar {
  /**
   *
   * @type {number}
   * @memberof AuthMeGet200ResponseAvatar
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof AuthMeGet200ResponseAvatar
   */
  url?: string;
}
/**
 *
 * @export
 * @interface AuthMeGet200ResponseLinksInner
 */
export interface AuthMeGet200ResponseLinksInner {
  /**
   *
   * @type {number}
   * @memberof AuthMeGet200ResponseLinksInner
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof AuthMeGet200ResponseLinksInner
   */
  href?: string;
  /**
   *
   * @type {string}
   * @memberof AuthMeGet200ResponseLinksInner
   */
  title?: string;
  /**
   *
   * @type {number}
   * @memberof AuthMeGet200ResponseLinksInner
   */
  order?: number;
}
/**
 *
 * @export
 * @interface AuthMeGet200ResponseNotifications
 */
export interface AuthMeGet200ResponseNotifications {
  /**
   *
   * @type {boolean}
   * @memberof AuthMeGet200ResponseNotifications
   */
  newMessagesInChallenges?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof AuthMeGet200ResponseNotifications
   */
  newMentionsInChallenges?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof AuthMeGet200ResponseNotifications
   */
  newCreatorsMessagesInChallenges?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof AuthMeGet200ResponseNotifications
   */
  newMessagesInDMs?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof AuthMeGet200ResponseNotifications
   */
  newPlatformMessages?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof AuthMeGet200ResponseNotifications
   */
  newRepliesInChallenges?: boolean;
}
/**
 *
 * @export
 * @interface AuthMeGet200ResponsePaymentMethod
 */
export interface AuthMeGet200ResponsePaymentMethod {
  /**
   *
   * @type {string}
   * @memberof AuthMeGet200ResponsePaymentMethod
   */
  id?: string;
  /**
   *
   * @type {object}
   * @memberof AuthMeGet200ResponsePaymentMethod
   */
  card?: object;
  /**
   *
   * @type {string}
   * @memberof AuthMeGet200ResponsePaymentMethod
   */
  type?: string;
}
/**
 *
 * @export
 * @interface AuthSignupPostRequest
 */
export interface AuthSignupPostRequest {
  /**
   *
   * @type {string}
   * @memberof AuthSignupPostRequest
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof AuthSignupPostRequest
   */
  password?: string;
  /**
   *
   * @type {boolean}
   * @memberof AuthSignupPostRequest
   */
  sendConfirmEmail?: boolean;
}
/**
 *
 * @export
 * @interface CreatorsCreatorIdSubscribePost200Response
 */
export interface CreatorsCreatorIdSubscribePost200Response {
  /**
   *
   * @type {CreatorsCreatorIdSubscribePost200ResponseCreator}
   * @memberof CreatorsCreatorIdSubscribePost200Response
   */
  creator?: CreatorsCreatorIdSubscribePost200ResponseCreator;
}
/**
 *
 * @export
 * @interface CreatorsCreatorIdSubscribePost200ResponseCreator
 */
export interface CreatorsCreatorIdSubscribePost200ResponseCreator {
  /**
   *
   * @type {number}
   * @memberof CreatorsCreatorIdSubscribePost200ResponseCreator
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof CreatorsCreatorIdSubscribePost200ResponseCreator
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof CreatorsCreatorIdSubscribePost200ResponseCreator
   */
  description?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof CreatorsCreatorIdSubscribePost200ResponseCreator
   */
  isAvatar?: boolean;
  /**
   *
   * @type {number}
   * @memberof CreatorsCreatorIdSubscribePost200ResponseCreator
   */
  subscriptionPrice?: number | null;
  /**
   *
   * @type {CreatorsCreatorIdSubscribePost200ResponseCreatorClub}
   * @memberof CreatorsCreatorIdSubscribePost200ResponseCreator
   */
  club?: CreatorsCreatorIdSubscribePost200ResponseCreatorClub | null;
  /**
   *
   * @type {AuthMeGet200ResponseAvatar}
   * @memberof CreatorsCreatorIdSubscribePost200ResponseCreator
   */
  avatar?: AuthMeGet200ResponseAvatar | null;
  /**
   *
   * @type {string}
   * @memberof CreatorsCreatorIdSubscribePost200ResponseCreator
   */
  slug?: string;
  /**
   *
   * @type {Array<AuthMeGet200ResponseLinksInner>}
   * @memberof CreatorsCreatorIdSubscribePost200ResponseCreator
   */
  links?: Array<AuthMeGet200ResponseLinksInner>;
}
/**
 *
 * @export
 * @interface CreatorsCreatorIdSubscribePost200ResponseCreatorClub
 */
export interface CreatorsCreatorIdSubscribePost200ResponseCreatorClub {
  /**
   *
   * @type {number}
   * @memberof CreatorsCreatorIdSubscribePost200ResponseCreatorClub
   */
  id?: number;
}
/**
 *
 * @export
 * @interface ExpertMembersGet200ResponseInner
 */
export interface ExpertMembersGet200ResponseInner {
  /**
   *
   * @type {number}
   * @memberof ExpertMembersGet200ResponseInner
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof ExpertMembersGet200ResponseInner
   */
  subscribed_at?: number;
  /**
   *
   * @type {string}
   * @memberof ExpertMembersGet200ResponseInner
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof ExpertMembersGet200ResponseInner
   */
  description?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof ExpertMembersGet200ResponseInner
   */
  isAvatar?: boolean;
  /**
   *
   * @type {number}
   * @memberof ExpertMembersGet200ResponseInner
   */
  customerId?: number | null;
  /**
   *
   * @type {string}
   * @memberof ExpertMembersGet200ResponseInner
   */
  slug?: string;
  /**
   *
   * @type {AuthMeGet200ResponseAvatar}
   * @memberof ExpertMembersGet200ResponseInner
   */
  avatar?: AuthMeGet200ResponseAvatar | null;
}
/**
 *
 * @export
 * @interface ExpertPricePost200Response
 */
export interface ExpertPricePost200Response {
  /**
   *
   * @type {string}
   * @memberof ExpertPricePost200Response
   */
  price?: string;
}
/**
 *
 * @export
 * @interface ExpertPricePostRequest
 */
export interface ExpertPricePostRequest {
  /**
   * Цена (в центах!)
   * @type {number}
   * @memberof ExpertPricePostRequest
   */
  price?: number;
}
/**
 *
 * @export
 * @interface LinksGet200Response
 */
export interface LinksGet200Response {
  /**
   *
   * @type {number}
   * @memberof LinksGet200Response
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof LinksGet200Response
   */
  createdAt?: number;
  /**
   *
   * @type {string}
   * @memberof LinksGet200Response
   */
  href?: string;
  /**
   *
   * @type {string}
   * @memberof LinksGet200Response
   */
  title?: string;
  /**
   *
   * @type {number}
   * @memberof LinksGet200Response
   */
  order?: number;
}
/**
 *
 * @export
 * @interface LinksLinkIdDelete200Response
 */
export interface LinksLinkIdDelete200Response {
  /**
   *
   * @type {number}
   * @memberof LinksLinkIdDelete200Response
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof LinksLinkIdDelete200Response
   */
  createdAt?: number;
  /**
   *
   * @type {number}
   * @memberof LinksLinkIdDelete200Response
   */
  updatedAt?: number | null;
  /**
   *
   * @type {string}
   * @memberof LinksLinkIdDelete200Response
   */
  href?: string;
  /**
   *
   * @type {string}
   * @memberof LinksLinkIdDelete200Response
   */
  title?: string;
  /**
   *
   * @type {number}
   * @memberof LinksLinkIdDelete200Response
   */
  order?: number;
}
/**
 *
 * @export
 * @interface LinksLinkIdDeleteRequest
 */
export interface LinksLinkIdDeleteRequest {
  /**
   *
   * @type {string}
   * @memberof LinksLinkIdDeleteRequest
   */
  href?: string;
  /**
   *
   * @type {string}
   * @memberof LinksLinkIdDeleteRequest
   */
  title?: string;
  /**
   *
   * @type {number}
   * @memberof LinksLinkIdDeleteRequest
   */
  order?: number;
}
/**
 *
 * @export
 * @interface LinksOrderPatchRequest
 */
export interface LinksOrderPatchRequest {
  /**
   *
   * @type {Array<LinksOrderPatchRequestLinksInner>}
   * @memberof LinksOrderPatchRequest
   */
  links?: Array<LinksOrderPatchRequestLinksInner>;
}
/**
 *
 * @export
 * @interface LinksOrderPatchRequestLinksInner
 */
export interface LinksOrderPatchRequestLinksInner {
  /**
   *
   * @type {number}
   * @memberof LinksOrderPatchRequestLinksInner
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof LinksOrderPatchRequestLinksInner
   */
  order?: number;
}
/**
 *
 * @export
 * @interface MeCreatorsGet200Response
 */
export interface MeCreatorsGet200Response {
  /**
   *
   * @type {Array<MeCreatorsGet200ResponseSubscriptionsInner>}
   * @memberof MeCreatorsGet200Response
   */
  subscriptions?: Array<MeCreatorsGet200ResponseSubscriptionsInner>;
  /**
   *
   * @type {Array<MeCreatorsGet200ResponsePurchasesInner>}
   * @memberof MeCreatorsGet200Response
   */
  purchases?: Array<MeCreatorsGet200ResponsePurchasesInner>;
}
/**
 *
 * @export
 * @interface MeCreatorsGet200ResponsePurchasesInner
 */
export interface MeCreatorsGet200ResponsePurchasesInner {
  /**
   *
   * @type {number}
   * @memberof MeCreatorsGet200ResponsePurchasesInner
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof MeCreatorsGet200ResponsePurchasesInner
   */
  name?: string;
  /**
   *
   * @type {AuthMeGet200ResponseAvatar}
   * @memberof MeCreatorsGet200ResponsePurchasesInner
   */
  avatar?: AuthMeGet200ResponseAvatar | null;
}
/**
 *
 * @export
 * @interface MeCreatorsGet200ResponseSubscriptionsInner
 */
export interface MeCreatorsGet200ResponseSubscriptionsInner {
  /**
   *
   * @type {number}
   * @memberof MeCreatorsGet200ResponseSubscriptionsInner
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof MeCreatorsGet200ResponseSubscriptionsInner
   */
  name?: string;
  /**
   *
   * @type {boolean}
   * @memberof MeCreatorsGet200ResponseSubscriptionsInner
   */
  isAvatar?: boolean;
  /**
   *
   * @type {AuthMeGet200ResponseAvatar}
   * @memberof MeCreatorsGet200ResponseSubscriptionsInner
   */
  avatar?: AuthMeGet200ResponseAvatar | null;
}
/**
 *
 * @export
 * @interface MePointsGet200ResponseInner
 */
export interface MePointsGet200ResponseInner {
  /**
   *
   * @type {number}
   * @memberof MePointsGet200ResponseInner
   */
  points?: number;
  /**
   *
   * @type {MePointsGet200ResponseInnerTag}
   * @memberof MePointsGet200ResponseInner
   */
  tag?: MePointsGet200ResponseInnerTag | null;
}
/**
 *
 * @export
 * @interface MePointsGet200ResponseInnerTag
 */
export interface MePointsGet200ResponseInnerTag {
  /**
   *
   * @type {number}
   * @memberof MePointsGet200ResponseInnerTag
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof MePointsGet200ResponseInnerTag
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof MePointsGet200ResponseInnerTag
   */
  color?: string;
}
/**
 *
 * @export
 * @interface MePurchasesGet200ResponseInner
 */
export interface MePurchasesGet200ResponseInner {
  /**
   *
   * @type {number}
   * @memberof MePurchasesGet200ResponseInner
   */
  created_at?: number;
  /**
   *
   * @type {number}
   * @memberof MePurchasesGet200ResponseInner
   */
  price?: number;
  /**
   *
   * @type {boolean}
   * @memberof MePurchasesGet200ResponseInner
   */
  inApp?: boolean;
  /**
   *
   * @type {MePurchasesGet200ResponseInnerSpace}
   * @memberof MePurchasesGet200ResponseInner
   */
  space?: MePurchasesGet200ResponseInnerSpace | null;
}
/**
 *
 * @export
 * @interface MePurchasesGet200ResponseInnerSpace
 */
export interface MePurchasesGet200ResponseInnerSpace {
  /**
   *
   * @type {number}
   * @memberof MePurchasesGet200ResponseInnerSpace
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof MePurchasesGet200ResponseInnerSpace
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof MePurchasesGet200ResponseInnerSpace
   */
  type?: MePurchasesGet200ResponseInnerSpaceTypeEnum;
  /**
   *
   * @type {string}
   * @memberof MePurchasesGet200ResponseInnerSpace
   */
  status?: MePurchasesGet200ResponseInnerSpaceStatusEnum;
  /**
   *
   * @type {string}
   * @memberof MePurchasesGet200ResponseInnerSpace
   */
  payment_type?: MePurchasesGet200ResponseInnerSpacePaymentTypeEnum;
  /**
   *
   * @type {MePurchasesGet200ResponseInnerSpaceConfig}
   * @memberof MePurchasesGet200ResponseInnerSpace
   */
  config?: MePurchasesGet200ResponseInnerSpaceConfig | null;
  /**
   *
   * @type {string}
   * @memberof MePurchasesGet200ResponseInnerSpace
   */
  slug?: string;
  /**
   *
   * @type {string}
   * @memberof MePurchasesGet200ResponseInnerSpace
   */
  userSlug?: string | null;
  /**
   *
   * @type {number}
   * @memberof MePurchasesGet200ResponseInnerSpace
   */
  chatId?: number | null;
  /**
   *
   * @type {AuthMeGet200ResponseAvatar}
   * @memberof MePurchasesGet200ResponseInnerSpace
   */
  cover?: AuthMeGet200ResponseAvatar | null;
  /**
   *
   * @type {MePurchasesGet200ResponseInnerSpaceExpert}
   * @memberof MePurchasesGet200ResponseInnerSpace
   */
  expert?: MePurchasesGet200ResponseInnerSpaceExpert | null;
  /**
   *
   * @type {MePurchasesGet200ResponseInnerSpaceFirstStep}
   * @memberof MePurchasesGet200ResponseInnerSpace
   */
  firstStep?: MePurchasesGet200ResponseInnerSpaceFirstStep | null;
}

export const MePurchasesGet200ResponseInnerSpaceTypeEnum = {
  Post: 'post',
  Live: 'live',
  Challenge: 'challenge',
  Trailer: 'trailer',
  Channel: 'channel',
  Community: 'community',
} as const;

export type MePurchasesGet200ResponseInnerSpaceTypeEnum =
  typeof MePurchasesGet200ResponseInnerSpaceTypeEnum[keyof typeof MePurchasesGet200ResponseInnerSpaceTypeEnum];
export const MePurchasesGet200ResponseInnerSpaceStatusEnum = {
  Draft: 'draft',
  Published: 'published',
} as const;

export type MePurchasesGet200ResponseInnerSpaceStatusEnum =
  typeof MePurchasesGet200ResponseInnerSpaceStatusEnum[keyof typeof MePurchasesGet200ResponseInnerSpaceStatusEnum];
export const MePurchasesGet200ResponseInnerSpacePaymentTypeEnum = {
  FixedPrice: 'fixed_price',
  FixedPriceSubscribersOnly: 'fixed_price_subscribers_only',
  SubscribersOnly: 'subscribers_only',
  Free: 'free',
  MonthlyPrice: 'monthly_price',
} as const;

export type MePurchasesGet200ResponseInnerSpacePaymentTypeEnum =
  typeof MePurchasesGet200ResponseInnerSpacePaymentTypeEnum[keyof typeof MePurchasesGet200ResponseInnerSpacePaymentTypeEnum];

/**
 *
 * @export
 * @interface MePurchasesGet200ResponseInnerSpaceConfig
 */
export interface MePurchasesGet200ResponseInnerSpaceConfig {
  /**
   *
   * @type {MePurchasesGet200ResponseInnerSpaceConfigLive}
   * @memberof MePurchasesGet200ResponseInnerSpaceConfig
   */
  live?: MePurchasesGet200ResponseInnerSpaceConfigLive | null;
}
/**
 *
 * @export
 * @interface MePurchasesGet200ResponseInnerSpaceConfigLive
 */
export interface MePurchasesGet200ResponseInnerSpaceConfigLive {
  /**
   *
   * @type {number}
   * @memberof MePurchasesGet200ResponseInnerSpaceConfigLive
   */
  startAt?: number;
  /**
   *
   * @type {number}
   * @memberof MePurchasesGet200ResponseInnerSpaceConfigLive
   */
  duration?: number;
  /**
   *
   * @type {MePurchasesGet200ResponseInnerSpaceConfigLiveRecord}
   * @memberof MePurchasesGet200ResponseInnerSpaceConfigLive
   */
  record?: MePurchasesGet200ResponseInnerSpaceConfigLiveRecord | null;
}
/**
 *
 * @export
 * @interface MePurchasesGet200ResponseInnerSpaceConfigLiveRecord
 */
export interface MePurchasesGet200ResponseInnerSpaceConfigLiveRecord {
  /**
   *
   * @type {number}
   * @memberof MePurchasesGet200ResponseInnerSpaceConfigLiveRecord
   */
  videoFileId?: number;
  /**
   *
   * @type {number}
   * @memberof MePurchasesGet200ResponseInnerSpaceConfigLiveRecord
   */
  coverFileId?: number | null;
  /**
   *
   * @type {object}
   * @memberof MePurchasesGet200ResponseInnerSpaceConfigLiveRecord
   */
  meta?: object | null;
}
/**
 *
 * @export
 * @interface MePurchasesGet200ResponseInnerSpaceExpert
 */
export interface MePurchasesGet200ResponseInnerSpaceExpert {
  /**
   *
   * @type {number}
   * @memberof MePurchasesGet200ResponseInnerSpaceExpert
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof MePurchasesGet200ResponseInnerSpaceExpert
   */
  name?: string;
  /**
   *
   * @type {AuthMeGet200ResponseAvatar}
   * @memberof MePurchasesGet200ResponseInnerSpaceExpert
   */
  avatar?: AuthMeGet200ResponseAvatar | null;
}
/**
 *
 * @export
 * @interface MePurchasesGet200ResponseInnerSpaceFirstStep
 */
export interface MePurchasesGet200ResponseInnerSpaceFirstStep {
  /**
   *
   * @type {number}
   * @memberof MePurchasesGet200ResponseInnerSpaceFirstStep
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof MePurchasesGet200ResponseInnerSpaceFirstStep
   */
  startAt?: number | null;
}
/**
 *
 * @export
 * @interface MeSubscriptionsGet200ResponseInner
 */
export interface MeSubscriptionsGet200ResponseInner {
  /**
   *
   * @type {number}
   * @memberof MeSubscriptionsGet200ResponseInner
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof MeSubscriptionsGet200ResponseInner
   */
  createdAt?: number;
  /**
   *
   * @type {number}
   * @memberof MeSubscriptionsGet200ResponseInner
   */
  lastPaymentDate?: number | null;
  /**
   *
   * @type {string}
   * @memberof MeSubscriptionsGet200ResponseInner
   */
  type?: MeSubscriptionsGet200ResponseInnerTypeEnum;
  /**
   *
   * @type {number}
   * @memberof MeSubscriptionsGet200ResponseInner
   */
  startedAt?: number | null;
  /**
   *
   * @type {number}
   * @memberof MeSubscriptionsGet200ResponseInner
   */
  cancelledAt?: number | null;
  /**
   *
   * @type {number}
   * @memberof MeSubscriptionsGet200ResponseInner
   */
  price?: number;
  /**
   *
   * @type {boolean}
   * @memberof MeSubscriptionsGet200ResponseInner
   */
  isActive?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof MeSubscriptionsGet200ResponseInner
   */
  inApp?: boolean;
  /**
   *
   * @type {MeSubscriptionsGet200ResponseInnerSpace}
   * @memberof MeSubscriptionsGet200ResponseInner
   */
  space?: MeSubscriptionsGet200ResponseInnerSpace | null;
}

export const MeSubscriptionsGet200ResponseInnerTypeEnum = {
  ForeverFree: 'foreverFree',
  FreeTrial: 'freeTrial',
  PaidTrial: 'paidTrial',
  FullPrice: 'fullPrice',
} as const;

export type MeSubscriptionsGet200ResponseInnerTypeEnum =
  typeof MeSubscriptionsGet200ResponseInnerTypeEnum[keyof typeof MeSubscriptionsGet200ResponseInnerTypeEnum];

/**
 *
 * @export
 * @interface MeSubscriptionsGet200ResponseInnerSpace
 */
export interface MeSubscriptionsGet200ResponseInnerSpace {
  /**
   *
   * @type {number}
   * @memberof MeSubscriptionsGet200ResponseInnerSpace
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof MeSubscriptionsGet200ResponseInnerSpace
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof MeSubscriptionsGet200ResponseInnerSpace
   */
  type?: MeSubscriptionsGet200ResponseInnerSpaceTypeEnum;
  /**
   *
   * @type {string}
   * @memberof MeSubscriptionsGet200ResponseInnerSpace
   */
  status?: MeSubscriptionsGet200ResponseInnerSpaceStatusEnum;
  /**
   *
   * @type {string}
   * @memberof MeSubscriptionsGet200ResponseInnerSpace
   */
  payment_type?: MeSubscriptionsGet200ResponseInnerSpacePaymentTypeEnum;
  /**
   *
   * @type {AuthMeGet200ResponseAvatar}
   * @memberof MeSubscriptionsGet200ResponseInnerSpace
   */
  cover?: AuthMeGet200ResponseAvatar | null;
  /**
   *
   * @type {string}
   * @memberof MeSubscriptionsGet200ResponseInnerSpace
   */
  userSlug?: string | null;
}

export const MeSubscriptionsGet200ResponseInnerSpaceTypeEnum = {
  Post: 'post',
  Live: 'live',
  Challenge: 'challenge',
  Trailer: 'trailer',
  Channel: 'channel',
  Community: 'community',
} as const;

export type MeSubscriptionsGet200ResponseInnerSpaceTypeEnum =
  typeof MeSubscriptionsGet200ResponseInnerSpaceTypeEnum[keyof typeof MeSubscriptionsGet200ResponseInnerSpaceTypeEnum];
export const MeSubscriptionsGet200ResponseInnerSpaceStatusEnum = {
  Draft: 'draft',
  Published: 'published',
} as const;

export type MeSubscriptionsGet200ResponseInnerSpaceStatusEnum =
  typeof MeSubscriptionsGet200ResponseInnerSpaceStatusEnum[keyof typeof MeSubscriptionsGet200ResponseInnerSpaceStatusEnum];
export const MeSubscriptionsGet200ResponseInnerSpacePaymentTypeEnum = {
  FixedPrice: 'fixed_price',
  FixedPriceSubscribersOnly: 'fixed_price_subscribers_only',
  SubscribersOnly: 'subscribers_only',
  Free: 'free',
  MonthlyPrice: 'monthly_price',
} as const;

export type MeSubscriptionsGet200ResponseInnerSpacePaymentTypeEnum =
  typeof MeSubscriptionsGet200ResponseInnerSpacePaymentTypeEnum[keyof typeof MeSubscriptionsGet200ResponseInnerSpacePaymentTypeEnum];

/**
 *
 * @export
 * @interface MobileUploadUrlPost200Response
 */
export interface MobileUploadUrlPost200Response {
  /**
   *
   * @type {MobileUploadUrlPost200ResponseFile}
   * @memberof MobileUploadUrlPost200Response
   */
  file?: MobileUploadUrlPost200ResponseFile;
  /**
   *
   * @type {string}
   * @memberof MobileUploadUrlPost200Response
   */
  uploadUrl?: string;
}
/**
 *
 * @export
 * @interface MobileUploadUrlPost200ResponseFile
 */
export interface MobileUploadUrlPost200ResponseFile {
  /**
   *
   * @type {number}
   * @memberof MobileUploadUrlPost200ResponseFile
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof MobileUploadUrlPost200ResponseFile
   */
  createdAt?: number;
  /**
   *
   * @type {number}
   * @memberof MobileUploadUrlPost200ResponseFile
   */
  userId?: number;
  /**
   *
   * @type {string}
   * @memberof MobileUploadUrlPost200ResponseFile
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof MobileUploadUrlPost200ResponseFile
   */
  type?: MobileUploadUrlPost200ResponseFileTypeEnum;
  /**
   *
   * @type {string}
   * @memberof MobileUploadUrlPost200ResponseFile
   */
  mime?: string | null;
  /**
   *
   * @type {string}
   * @memberof MobileUploadUrlPost200ResponseFile
   */
  url?: string;
  /**
   *
   * @type {string}
   * @memberof MobileUploadUrlPost200ResponseFile
   */
  originalUrl?: string | null;
  /**
   *
   * @type {object}
   * @memberof MobileUploadUrlPost200ResponseFile
   */
  meta?: object | null;
}

export const MobileUploadUrlPost200ResponseFileTypeEnum = {
  Audio: 'audio',
  Video: 'video',
  Image: 'image',
  Other: 'other',
} as const;

export type MobileUploadUrlPost200ResponseFileTypeEnum =
  typeof MobileUploadUrlPost200ResponseFileTypeEnum[keyof typeof MobileUploadUrlPost200ResponseFileTypeEnum];

/**
 *
 * @export
 * @interface MobileUploadUrlPostRequest
 */
export interface MobileUploadUrlPostRequest {
  /**
   *
   * @type {string}
   * @memberof MobileUploadUrlPostRequest
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof MobileUploadUrlPostRequest
   */
  type?: MobileUploadUrlPostRequestTypeEnum;
  /**
   *
   * @type {string}
   * @memberof MobileUploadUrlPostRequest
   */
  mime?: string | null;
  /**
   *
   * @type {object}
   * @memberof MobileUploadUrlPostRequest
   */
  meta?: object | null;
}

export const MobileUploadUrlPostRequestTypeEnum = {
  Audio: 'audio',
  Video: 'video',
  Image: 'image',
  Other: 'other',
} as const;

export type MobileUploadUrlPostRequestTypeEnum =
  typeof MobileUploadUrlPostRequestTypeEnum[keyof typeof MobileUploadUrlPostRequestTypeEnum];

/**
 *
 * @export
 * @interface NpsGet200Response
 */
export interface NpsGet200Response {
  /**
   *
   * @type {number}
   * @memberof NpsGet200Response
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof NpsGet200Response
   */
  rate?: number;
  /**
   *
   * @type {boolean}
   * @memberof NpsGet200Response
   */
  readyToTalk?: boolean;
}
/**
 *
 * @export
 * @interface NpsGet200Response1
 */
export interface NpsGet200Response1 {
  /**
   *
   * @type {number}
   * @memberof NpsGet200Response1
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof NpsGet200Response1
   */
  rate?: number;
  /**
   *
   * @type {boolean}
   * @memberof NpsGet200Response1
   */
  readyToTalk?: boolean;
  /**
   *
   * @type {string}
   * @memberof NpsGet200Response1
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof NpsGet200Response1
   */
  email?: string;
}
/**
 *
 * @export
 * @interface NpsGetRequest
 */
export interface NpsGetRequest {
  /**
   *
   * @type {number}
   * @memberof NpsGetRequest
   */
  rate?: number;
  /**
   *
   * @type {boolean}
   * @memberof NpsGetRequest
   */
  readyToTalk?: boolean;
}
/**
 *
 * @export
 * @interface PaymentMethodSuccessPostRequest
 */
export interface PaymentMethodSuccessPostRequest {
  /**
   *
   * @type {object}
   * @memberof PaymentMethodSuccessPostRequest
   */
  setupIntent?: object;
}
/**
 *
 * @export
 * @interface PaymentsPaymentIdGet200Response
 */
export interface PaymentsPaymentIdGet200Response {
  /**
   *
   * @type {string}
   * @memberof PaymentsPaymentIdGet200Response
   */
  status?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentsPaymentIdGet200Response
   */
  id?: string;
}
/**
 *
 * @export
 * @interface ProfileChatsArchivedDelete200Response
 */
export interface ProfileChatsArchivedDelete200Response {
  /**
   *
   * @type {Array<number>}
   * @memberof ProfileChatsArchivedDelete200Response
   */
  archivedChatsIds?: Array<number> | null;
}
/**
 *
 * @export
 * @interface ProfileChatsArchivedDeleteRequest
 */
export interface ProfileChatsArchivedDeleteRequest {
  /**
   *
   * @type {Array<number>}
   * @memberof ProfileChatsArchivedDeleteRequest
   */
  added?: Array<number>;
}
/**
 *
 * @export
 * @interface ProfileChatsPinnedDelete200Response
 */
export interface ProfileChatsPinnedDelete200Response {
  /**
   *
   * @type {Array<number>}
   * @memberof ProfileChatsPinnedDelete200Response
   */
  pinnedChatsIds?: Array<number> | null;
}
/**
 *
 * @export
 * @interface ProfileConfirmEmailPostRequest
 */
export interface ProfileConfirmEmailPostRequest {
  /**
   * Токен подтверждения email
   * @type {string}
   * @memberof ProfileConfirmEmailPostRequest
   */
  token?: string;
}
/**
 *
 * @export
 * @interface ProfilePatch200Response
 */
export interface ProfilePatch200Response {
  /**
   *
   * @type {number}
   * @memberof ProfilePatch200Response
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof ProfilePatch200Response
   */
  created_at?: number;
  /**
   *
   * @type {string}
   * @memberof ProfilePatch200Response
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof ProfilePatch200Response
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof ProfilePatch200Response
   */
  bio?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProfilePatch200Response
   */
  description?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof ProfilePatch200Response
   */
  isRegistrationCompleted?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ProfilePatch200Response
   */
  gsCreated?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ProfilePatch200Response
   */
  isFirstChallengeVisited?: boolean;
  /**
   *
   * @type {Array<number>}
   * @memberof ProfilePatch200Response
   */
  pinnedChatsIds?: Array<number> | null;
  /**
   *
   * @type {Array<number>}
   * @memberof ProfilePatch200Response
   */
  archivedChatsIds?: Array<number> | null;
  /**
   *
   * @type {boolean}
   * @memberof ProfilePatch200Response
   */
  isPaymentMethodAdditionSkipped?: boolean;
  /**
   *
   * @type {AuthMeGet200ResponseNotifications}
   * @memberof ProfilePatch200Response
   */
  notifications?: AuthMeGet200ResponseNotifications;
  /**
   *
   * @type {string}
   * @memberof ProfilePatch200Response
   */
  slug?: string;
  /**
   *
   * @type {AuthMeGet200ResponseAvatar}
   * @memberof ProfilePatch200Response
   */
  avatar?: AuthMeGet200ResponseAvatar | null;
}
/**
 *
 * @export
 * @interface ProfilePatchRequest
 */
export interface ProfilePatchRequest {
  /**
   *
   * @type {string}
   * @memberof ProfilePatchRequest
   */
  slug?: string;
  /**
   *
   * @type {string}
   * @memberof ProfilePatchRequest
   */
  name?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProfilePatchRequest
   */
  email?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProfilePatchRequest
   */
  password?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProfilePatchRequest
   */
  description?: string | null;
  /**
   *
   * @type {number}
   * @memberof ProfilePatchRequest
   */
  avatarId?: number | null;
  /**
   *
   * @type {boolean}
   * @memberof ProfilePatchRequest
   */
  isFirstChallengeVisited?: boolean | null;
  /**
   *
   * @type {Array<number>}
   * @memberof ProfilePatchRequest
   */
  pinnedChatsIds?: Array<number> | null;
  /**
   *
   * @type {boolean}
   * @memberof ProfilePatchRequest
   */
  isPaymentMethodAdditionSkipped?: boolean;
  /**
   *
   * @type {Array<number>}
   * @memberof ProfilePatchRequest
   */
  archivedChatsIds?: Array<number> | null;
  /**
   *
   * @type {string}
   * @memberof ProfilePatchRequest
   */
  bio?: string | null;
  /**
   *
   * @type {ProfilePatchRequestNotifications}
   * @memberof ProfilePatchRequest
   */
  notifications?: ProfilePatchRequestNotifications | null;
}
/**
 *
 * @export
 * @interface ProfilePatchRequestNotifications
 */
export interface ProfilePatchRequestNotifications {
  /**
   *
   * @type {boolean}
   * @memberof ProfilePatchRequestNotifications
   */
  newMessagesInChallenges?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ProfilePatchRequestNotifications
   */
  newMentionsInChallenges?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ProfilePatchRequestNotifications
   */
  newCreatorsMessagesInChallenges?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ProfilePatchRequestNotifications
   */
  newMessagesInDMs?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ProfilePatchRequestNotifications
   */
  newPlatformMessages?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ProfilePatchRequestNotifications
   */
  newRepliesInChallenges?: boolean;
}
/**
 *
 * @export
 * @interface ProfilePushTokensDeleteRequest
 */
export interface ProfilePushTokensDeleteRequest {
  /**
   *
   * @type {string}
   * @memberof ProfilePushTokensDeleteRequest
   */
  service?: ProfilePushTokensDeleteRequestServiceEnum;
  /**
   *
   * @type {string}
   * @memberof ProfilePushTokensDeleteRequest
   */
  deviceToken?: string;
  /**
   *
   * @type {object}
   * @memberof ProfilePushTokensDeleteRequest
   */
  customConfig?: object | null;
}

export const ProfilePushTokensDeleteRequestServiceEnum = {
  Apns: 'apns',
  Fcm: 'fcm',
} as const;

export type ProfilePushTokensDeleteRequestServiceEnum =
  typeof ProfilePushTokensDeleteRequestServiceEnum[keyof typeof ProfilePushTokensDeleteRequestServiceEnum];

/**
 *
 * @export
 * @interface SelfplusUsersGet200ResponseInner
 */
export interface SelfplusUsersGet200ResponseInner {
  /**
   *
   * @type {number}
   * @memberof SelfplusUsersGet200ResponseInner
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof SelfplusUsersGet200ResponseInner
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof SelfplusUsersGet200ResponseInner
   */
  slug?: string;
  /**
   *
   * @type {AuthMeGet200ResponseAvatar}
   * @memberof SelfplusUsersGet200ResponseInner
   */
  avatar?: AuthMeGet200ResponseAvatar | null;
}
/**
 *
 * @export
 * @interface SignupMagicPostRequest
 */
export interface SignupMagicPostRequest {
  /**
   *
   * @type {string}
   * @memberof SignupMagicPostRequest
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof SignupMagicPostRequest
   */
  redirect_uri?: string;
  /**
   *
   * @type {string}
   * @memberof SignupMagicPostRequest
   */
  auth_uri?: string | null;
  /**
   *
   * @type {object}
   * @memberof SignupMagicPostRequest
   */
  metadata?: object | null;
  /**
   *
   * @type {string}
   * @memberof SignupMagicPostRequest
   */
  local_dev_token?: string | null;
}
/**
 *
 * @export
 * @interface SpacesSpaceIdInAppPurchasePostRequest
 */
export interface SpacesSpaceIdInAppPurchasePostRequest {
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdInAppPurchasePostRequest
   */
  os?: SpacesSpaceIdInAppPurchasePostRequestOsEnum;
  /**
   *
   * @type {boolean}
   * @memberof SpacesSpaceIdInAppPurchasePostRequest
   */
  force?: boolean;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdInAppPurchasePostRequest
   */
  transactionId?: string;
}

export const SpacesSpaceIdInAppPurchasePostRequestOsEnum = {
  Ios: 'ios',
  Android: 'android',
} as const;

export type SpacesSpaceIdInAppPurchasePostRequestOsEnum =
  typeof SpacesSpaceIdInAppPurchasePostRequestOsEnum[keyof typeof SpacesSpaceIdInAppPurchasePostRequestOsEnum];

/**
 *
 * @export
 * @interface SpacesSpaceIdPurchasePost200Response
 */
export interface SpacesSpaceIdPurchasePost200Response {
  /**
   *
   * @type {SpacesSpaceIdPurchasePost200ResponseSpace}
   * @memberof SpacesSpaceIdPurchasePost200Response
   */
  space?: SpacesSpaceIdPurchasePost200ResponseSpace;
}
/**
 *
 * @export
 * @interface SpacesSpaceIdPurchasePost200ResponseSpace
 */
export interface SpacesSpaceIdPurchasePost200ResponseSpace {
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdPurchasePost200ResponseSpace
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdPurchasePost200ResponseSpace
   */
  title?: string;
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdPurchasePost200ResponseSpace
   */
  price?: number;
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdPurchasePost200ResponseSpace
   */
  creatorPrice?: number;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdPurchasePost200ResponseSpace
   */
  type?: SpacesSpaceIdPurchasePost200ResponseSpaceTypeEnum;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdPurchasePost200ResponseSpace
   */
  status?: SpacesSpaceIdPurchasePost200ResponseSpaceStatusEnum;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdPurchasePost200ResponseSpace
   */
  payment_type?: SpacesSpaceIdPurchasePost200ResponseSpacePaymentTypeEnum;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdPurchasePost200ResponseSpace
   */
  priceModel?: SpacesSpaceIdPurchasePost200ResponseSpacePriceModelEnum;
  /**
   *
   * @type {SpacesSpaceIdPurchasePost200ResponseSpaceConfig}
   * @memberof SpacesSpaceIdPurchasePost200ResponseSpace
   */
  config?: SpacesSpaceIdPurchasePost200ResponseSpaceConfig | null;
  /**
   *
   * @type {SpacesSpaceIdPurchasePost200ResponseSpaceMarketingPriceConfig}
   * @memberof SpacesSpaceIdPurchasePost200ResponseSpace
   */
  marketingPriceConfig?: SpacesSpaceIdPurchasePost200ResponseSpaceMarketingPriceConfig;
  /**
   *
   * @type {AuthMeGet200ResponseAvatar}
   * @memberof SpacesSpaceIdPurchasePost200ResponseSpace
   */
  cover?: AuthMeGet200ResponseAvatar | null;
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdPurchasePost200ResponseSpace
   */
  startAt?: number | null;
}

export const SpacesSpaceIdPurchasePost200ResponseSpaceTypeEnum = {
  Post: 'post',
  Live: 'live',
  Challenge: 'challenge',
  Trailer: 'trailer',
  Channel: 'channel',
  Community: 'community',
} as const;

export type SpacesSpaceIdPurchasePost200ResponseSpaceTypeEnum =
  typeof SpacesSpaceIdPurchasePost200ResponseSpaceTypeEnum[keyof typeof SpacesSpaceIdPurchasePost200ResponseSpaceTypeEnum];
export const SpacesSpaceIdPurchasePost200ResponseSpaceStatusEnum = {
  Draft: 'draft',
  Published: 'published',
} as const;

export type SpacesSpaceIdPurchasePost200ResponseSpaceStatusEnum =
  typeof SpacesSpaceIdPurchasePost200ResponseSpaceStatusEnum[keyof typeof SpacesSpaceIdPurchasePost200ResponseSpaceStatusEnum];
export const SpacesSpaceIdPurchasePost200ResponseSpacePaymentTypeEnum = {
  FixedPrice: 'fixed_price',
  FixedPriceSubscribersOnly: 'fixed_price_subscribers_only',
  SubscribersOnly: 'subscribers_only',
  Free: 'free',
  MonthlyPrice: 'monthly_price',
} as const;

export type SpacesSpaceIdPurchasePost200ResponseSpacePaymentTypeEnum =
  typeof SpacesSpaceIdPurchasePost200ResponseSpacePaymentTypeEnum[keyof typeof SpacesSpaceIdPurchasePost200ResponseSpacePaymentTypeEnum];
export const SpacesSpaceIdPurchasePost200ResponseSpacePriceModelEnum = {
  Fixed: 'fixed',
  Auction: 'auction',
} as const;

export type SpacesSpaceIdPurchasePost200ResponseSpacePriceModelEnum =
  typeof SpacesSpaceIdPurchasePost200ResponseSpacePriceModelEnum[keyof typeof SpacesSpaceIdPurchasePost200ResponseSpacePriceModelEnum];

/**
 *
 * @export
 * @interface SpacesSpaceIdPurchasePost200ResponseSpaceConfig
 */
export interface SpacesSpaceIdPurchasePost200ResponseSpaceConfig {
  /**
   *
   * @type {SpacesSpaceIdPurchasePost200ResponseSpaceConfigChallenge}
   * @memberof SpacesSpaceIdPurchasePost200ResponseSpaceConfig
   */
  challenge?: SpacesSpaceIdPurchasePost200ResponseSpaceConfigChallenge | null;
}
/**
 *
 * @export
 * @interface SpacesSpaceIdPurchasePost200ResponseSpaceConfigChallenge
 */
export interface SpacesSpaceIdPurchasePost200ResponseSpaceConfigChallenge {
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdPurchasePost200ResponseSpaceConfigChallenge
   */
  finishTimestamp?: number | null;
}
/**
 *
 * @export
 * @interface SpacesSpaceIdPurchasePost200ResponseSpaceMarketingPriceConfig
 */
export interface SpacesSpaceIdPurchasePost200ResponseSpaceMarketingPriceConfig {
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdPurchasePost200ResponseSpaceMarketingPriceConfig
   */
  previousPrice?: number | null;
}
/**
 *
 * @export
 * @interface SpacesSpaceIdSubscribePost200Response
 */
export interface SpacesSpaceIdSubscribePost200Response {
  /**
   *
   * @type {SpacesSpaceIdSubscribePost200ResponseSpace}
   * @memberof SpacesSpaceIdSubscribePost200Response
   */
  space?: SpacesSpaceIdSubscribePost200ResponseSpace;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdSubscribePost200Response
   */
  clientSecret?: string;
  /**
   *
   * @type {SpacesSpaceIdSubscribePost200ResponseSubscription}
   * @memberof SpacesSpaceIdSubscribePost200Response
   */
  subscription?: SpacesSpaceIdSubscribePost200ResponseSubscription;
}
/**
 *
 * @export
 * @interface SpacesSpaceIdSubscribePost200ResponseSpace
 */
export interface SpacesSpaceIdSubscribePost200ResponseSpace {
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdSubscribePost200ResponseSpace
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdSubscribePost200ResponseSpace
   */
  expert?: number;
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdSubscribePost200ResponseSpace
   */
  club?: number;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdSubscribePost200ResponseSpace
   */
  title?: string;
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdSubscribePost200ResponseSpace
   */
  price?: number;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdSubscribePost200ResponseSpace
   */
  type?: SpacesSpaceIdSubscribePost200ResponseSpaceTypeEnum;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdSubscribePost200ResponseSpace
   */
  status?: SpacesSpaceIdSubscribePost200ResponseSpaceStatusEnum;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdSubscribePost200ResponseSpace
   */
  payment_type?: SpacesSpaceIdSubscribePost200ResponseSpacePaymentTypeEnum;
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdSubscribePost200ResponseSpace
   */
  trialPrice?: number;
}

export const SpacesSpaceIdSubscribePost200ResponseSpaceTypeEnum = {
  Post: 'post',
  Live: 'live',
  Challenge: 'challenge',
  Trailer: 'trailer',
  Channel: 'channel',
  Community: 'community',
} as const;

export type SpacesSpaceIdSubscribePost200ResponseSpaceTypeEnum =
  typeof SpacesSpaceIdSubscribePost200ResponseSpaceTypeEnum[keyof typeof SpacesSpaceIdSubscribePost200ResponseSpaceTypeEnum];
export const SpacesSpaceIdSubscribePost200ResponseSpaceStatusEnum = {
  Draft: 'draft',
  Published: 'published',
} as const;

export type SpacesSpaceIdSubscribePost200ResponseSpaceStatusEnum =
  typeof SpacesSpaceIdSubscribePost200ResponseSpaceStatusEnum[keyof typeof SpacesSpaceIdSubscribePost200ResponseSpaceStatusEnum];
export const SpacesSpaceIdSubscribePost200ResponseSpacePaymentTypeEnum = {
  FixedPrice: 'fixed_price',
  FixedPriceSubscribersOnly: 'fixed_price_subscribers_only',
  SubscribersOnly: 'subscribers_only',
  Free: 'free',
  MonthlyPrice: 'monthly_price',
} as const;

export type SpacesSpaceIdSubscribePost200ResponseSpacePaymentTypeEnum =
  typeof SpacesSpaceIdSubscribePost200ResponseSpacePaymentTypeEnum[keyof typeof SpacesSpaceIdSubscribePost200ResponseSpacePaymentTypeEnum];

/**
 *
 * @export
 * @interface SpacesSpaceIdSubscribePost200ResponseSubscription
 */
export interface SpacesSpaceIdSubscribePost200ResponseSubscription {
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdSubscribePost200ResponseSubscription
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdSubscribePost200ResponseSubscription
   */
  lastPaymentDate?: number | null;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdSubscribePost200ResponseSubscription
   */
  type?: SpacesSpaceIdSubscribePost200ResponseSubscriptionTypeEnum;
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdSubscribePost200ResponseSubscription
   */
  startedAt?: number | null;
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdSubscribePost200ResponseSubscription
   */
  cancelledAt?: number | null;
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdSubscribePost200ResponseSubscription
   */
  price?: number;
  /**
   *
   * @type {boolean}
   * @memberof SpacesSpaceIdSubscribePost200ResponseSubscription
   */
  isActive?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof SpacesSpaceIdSubscribePost200ResponseSubscription
   */
  inApp?: boolean;
}

export const SpacesSpaceIdSubscribePost200ResponseSubscriptionTypeEnum = {
  ForeverFree: 'foreverFree',
  FreeTrial: 'freeTrial',
  PaidTrial: 'paidTrial',
  FullPrice: 'fullPrice',
} as const;

export type SpacesSpaceIdSubscribePost200ResponseSubscriptionTypeEnum =
  typeof SpacesSpaceIdSubscribePost200ResponseSubscriptionTypeEnum[keyof typeof SpacesSpaceIdSubscribePost200ResponseSubscriptionTypeEnum];

/**
 *
 * @export
 * @interface SpacesSpaceIdSubscriptionGet200Response
 */
export interface SpacesSpaceIdSubscriptionGet200Response {
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdSubscriptionGet200Response
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdSubscriptionGet200Response
   */
  lastPaymentDate?: number | null;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdSubscriptionGet200Response
   */
  type?: SpacesSpaceIdSubscriptionGet200ResponseTypeEnum;
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdSubscriptionGet200Response
   */
  startedAt?: number | null;
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdSubscriptionGet200Response
   */
  cancelledAt?: number | null;
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdSubscriptionGet200Response
   */
  price?: number;
  /**
   *
   * @type {boolean}
   * @memberof SpacesSpaceIdSubscriptionGet200Response
   */
  isActive?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof SpacesSpaceIdSubscriptionGet200Response
   */
  inApp?: boolean;
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdSubscriptionGet200Response
   */
  nextPaymentDate?: number | null;
}

export const SpacesSpaceIdSubscriptionGet200ResponseTypeEnum = {
  ForeverFree: 'foreverFree',
  FreeTrial: 'freeTrial',
  PaidTrial: 'paidTrial',
  FullPrice: 'fullPrice',
} as const;

export type SpacesSpaceIdSubscriptionGet200ResponseTypeEnum =
  typeof SpacesSpaceIdSubscriptionGet200ResponseTypeEnum[keyof typeof SpacesSpaceIdSubscriptionGet200ResponseTypeEnum];

/**
 *
 * @export
 * @interface UploadFilePost200Response
 */
export interface UploadFilePost200Response {
  /**
   *
   * @type {string}
   * @memberof UploadFilePost200Response
   */
  path?: string;
  /**
   *
   * @type {string}
   * @memberof UploadFilePost200Response
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof UploadFilePost200Response
   */
  type?: string;
  /**
   *
   * @type {number}
   * @memberof UploadFilePost200Response
   */
  size?: number;
  /**
   *
   * @type {string}
   * @memberof UploadFilePost200Response
   */
  mime?: string;
  /**
   *
   * @type {object}
   * @memberof UploadFilePost200Response
   */
  meta?: object;
}
/**
 *
 * @export
 * @interface UploadUrlPost200Response
 */
export interface UploadUrlPost200Response {
  /**
   *
   * @type {UploadUrlPost200ResponseFile}
   * @memberof UploadUrlPost200Response
   */
  file?: UploadUrlPost200ResponseFile;
  /**
   *
   * @type {string}
   * @memberof UploadUrlPost200Response
   */
  uploadUrl?: string;
}
/**
 *
 * @export
 * @interface UploadUrlPost200ResponseFile
 */
export interface UploadUrlPost200ResponseFile {
  /**
   *
   * @type {number}
   * @memberof UploadUrlPost200ResponseFile
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof UploadUrlPost200ResponseFile
   */
  createdAt?: number;
  /**
   *
   * @type {number}
   * @memberof UploadUrlPost200ResponseFile
   */
  userId?: number;
  /**
   *
   * @type {string}
   * @memberof UploadUrlPost200ResponseFile
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof UploadUrlPost200ResponseFile
   */
  type?: UploadUrlPost200ResponseFileTypeEnum;
  /**
   *
   * @type {string}
   * @memberof UploadUrlPost200ResponseFile
   */
  mime?: string | null;
  /**
   *
   * @type {string}
   * @memberof UploadUrlPost200ResponseFile
   */
  url?: string;
  /**
   *
   * @type {string}
   * @memberof UploadUrlPost200ResponseFile
   */
  originalUrl?: string | null;
  /**
   *
   * @type {object}
   * @memberof UploadUrlPost200ResponseFile
   */
  meta?: object | null;
  /**
   *
   * @type {string}
   * @memberof UploadUrlPost200ResponseFile
   */
  previewUrl?: string | null;
}

export const UploadUrlPost200ResponseFileTypeEnum = {
  Audio: 'audio',
  Video: 'video',
  Image: 'image',
  Other: 'other',
} as const;

export type UploadUrlPost200ResponseFileTypeEnum =
  typeof UploadUrlPost200ResponseFileTypeEnum[keyof typeof UploadUrlPost200ResponseFileTypeEnum];

/**
 *
 * @export
 * @interface VideosVideoFileIdPreviewPostRequest
 */
export interface VideosVideoFileIdPreviewPostRequest {
  /**
   *
   * @type {number}
   * @memberof VideosVideoFileIdPreviewPostRequest
   */
  frame?: number;
}

/**
 * AndroidApi - axios parameter creator
 * @export
 */
export const AndroidApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * <br /><br /> <b>Authentication:</b> not required
     * @summary
     * @param {string} deviceToken
     * @param {string} [message]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    androidPushDmGet: async (
      deviceToken: string,
      message?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'deviceToken' is not null or undefined
      assertParamExists('androidPushDmGet', 'deviceToken', deviceToken);
      const localVarPath = `/android/push/dm`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (deviceToken !== undefined) {
        localVarQueryParameter['deviceToken'] = deviceToken;
      }

      if (message !== undefined) {
        localVarQueryParameter['message'] = message;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <br /><br /> <b>Authentication:</b> not required
     * @summary
     * @param {string} deviceToken
     * @param {string} [url]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    androidPushGet: async (
      deviceToken: string,
      url?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'deviceToken' is not null or undefined
      assertParamExists('androidPushGet', 'deviceToken', deviceToken);
      const localVarPath = `/android/push`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (deviceToken !== undefined) {
        localVarQueryParameter['deviceToken'] = deviceToken;
      }

      if (url !== undefined) {
        localVarQueryParameter['url'] = url;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AndroidApi - functional programming interface
 * @export
 */
export const AndroidApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = AndroidApiAxiosParamCreator(configuration);
  return {
    /**
     * <br /><br /> <b>Authentication:</b> not required
     * @summary
     * @param {string} deviceToken
     * @param {string} [message]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async androidPushDmGet(
      deviceToken: string,
      message?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.androidPushDmGet(deviceToken, message, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * <br /><br /> <b>Authentication:</b> not required
     * @summary
     * @param {string} deviceToken
     * @param {string} [url]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async androidPushGet(
      deviceToken: string,
      url?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.androidPushGet(deviceToken, url, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * AndroidApi - factory interface
 * @export
 */
export const AndroidApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = AndroidApiFp(configuration);
  return {
    /**
     * <br /><br /> <b>Authentication:</b> not required
     * @summary
     * @param {string} deviceToken
     * @param {string} [message]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    androidPushDmGet(deviceToken: string, message?: string, options?: any): AxiosPromise<object> {
      return localVarFp.androidPushDmGet(deviceToken, message, options).then(request => request(axios, basePath));
    },
    /**
     * <br /><br /> <b>Authentication:</b> not required
     * @summary
     * @param {string} deviceToken
     * @param {string} [url]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    androidPushGet(deviceToken: string, url?: string, options?: any): AxiosPromise<object> {
      return localVarFp.androidPushGet(deviceToken, url, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * AndroidApi - object-oriented interface
 * @export
 * @class AndroidApi
 * @extends {BaseAPI}
 */
export class AndroidApi extends BaseAPI {
  /**
   * <br /><br /> <b>Authentication:</b> not required
   * @summary
   * @param {string} deviceToken
   * @param {string} [message]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AndroidApi
   */
  public androidPushDmGet(deviceToken: string, message?: string, options?: AxiosRequestConfig) {
    return AndroidApiFp(this.configuration)
      .androidPushDmGet(deviceToken, message, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * <br /><br /> <b>Authentication:</b> not required
   * @summary
   * @param {string} deviceToken
   * @param {string} [url]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AndroidApi
   */
  public androidPushGet(deviceToken: string, url?: string, options?: AxiosRequestConfig) {
    return AndroidApiFp(this.configuration)
      .androidPushGet(deviceToken, url, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {AuthCodePostRequest} [authCodePostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authCodePost: async (
      authCodePostRequest?: AuthCodePostRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/auth/code`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(authCodePostRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Login and retrieve an authentication token <br /><br /> <b>Authentication:</b> not required
     * @summary Login and retrieve an authentication token
     * @param {AuthLoginPostRequest} [authLoginPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authLoginPost: async (
      authLoginPostRequest?: AuthLoginPostRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/auth/login`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(authLoginPostRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Аутентификация/регистрация через \"магическую\" ссылку на почту <br /><br /> <b>Authentication:</b> not required
     * @summary Аутентификация/регистрация через \"магическую\" ссылку на почту
     * @param {string} key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authMagicKeyPost: async (key: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'key' is not null or undefined
      assertParamExists('authMagicKeyPost', 'key', key);
      const localVarPath = `/auth/magic/{key}`.replace(`{${'key'}}`, encodeURIComponent(String(key)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Аутентификация через \"магическую\" ссылку на почту <br /><br /> <b>Authentication:</b> not required
     * @summary Аутентификация через \"магическую\" ссылку на почту
     * @param {AuthMagicPostRequest} [authMagicPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authMagicPost: async (
      authMagicPostRequest?: AuthMagicPostRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/auth/magic`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(authMagicPostRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get the user record belonging to the authentication token <br /><br /> <b>Authentication:</b> required
     * @summary Get the user record belonging to the authentication token
     * @param {boolean} [force]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authMeGet: async (force?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/auth/me`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (force !== undefined) {
        localVarQueryParameter['force'] = force;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Запрос письма на подтверждение электронной почты <br /><br /> <b>Authentication:</b> required
     * @summary Запрос письма на подтверждение электронной почты
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authRequestConfirmEmailPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/auth/requestConfirmEmail`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Signup and retrieve an authentication token <br /><br /> <b>Authentication:</b> not required
     * @summary Signup and retrieve an authentication token
     * @param {AuthSignupPostRequest} [authSignupPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authSignupPost: async (
      authSignupPostRequest?: AuthSignupPostRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/auth/signup`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(authSignupPostRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration);
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {AuthCodePostRequest} [authCodePostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async authCodePost(
      authCodePostRequest?: AuthCodePostRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.authCodePost(authCodePostRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Login and retrieve an authentication token <br /><br /> <b>Authentication:</b> not required
     * @summary Login and retrieve an authentication token
     * @param {AuthLoginPostRequest} [authLoginPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async authLoginPost(
      authLoginPostRequest?: AuthLoginPostRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthLoginPost200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.authLoginPost(authLoginPostRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Аутентификация/регистрация через \"магическую\" ссылку на почту <br /><br /> <b>Authentication:</b> not required
     * @summary Аутентификация/регистрация через \"магическую\" ссылку на почту
     * @param {string} key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async authMagicKeyPost(
      key: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.authMagicKeyPost(key, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Аутентификация через \"магическую\" ссылку на почту <br /><br /> <b>Authentication:</b> not required
     * @summary Аутентификация через \"магическую\" ссылку на почту
     * @param {AuthMagicPostRequest} [authMagicPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async authMagicPost(
      authMagicPostRequest?: AuthMagicPostRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthMagicPost200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.authMagicPost(authMagicPostRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Get the user record belonging to the authentication token <br /><br /> <b>Authentication:</b> required
     * @summary Get the user record belonging to the authentication token
     * @param {boolean} [force]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async authMeGet(
      force?: boolean,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthMeGet200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.authMeGet(force, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Запрос письма на подтверждение электронной почты <br /><br /> <b>Authentication:</b> required
     * @summary Запрос письма на подтверждение электронной почты
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async authRequestConfirmEmailPost(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.authRequestConfirmEmailPost(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Signup and retrieve an authentication token <br /><br /> <b>Authentication:</b> not required
     * @summary Signup and retrieve an authentication token
     * @param {AuthSignupPostRequest} [authSignupPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async authSignupPost(
      authSignupPostRequest?: AuthSignupPostRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthLoginPost200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.authSignupPost(authSignupPostRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = AuthApiFp(configuration);
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {AuthCodePostRequest} [authCodePostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authCodePost(authCodePostRequest?: AuthCodePostRequest, options?: any): AxiosPromise<object> {
      return localVarFp.authCodePost(authCodePostRequest, options).then(request => request(axios, basePath));
    },
    /**
     * Login and retrieve an authentication token <br /><br /> <b>Authentication:</b> not required
     * @summary Login and retrieve an authentication token
     * @param {AuthLoginPostRequest} [authLoginPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authLoginPost(authLoginPostRequest?: AuthLoginPostRequest, options?: any): AxiosPromise<AuthLoginPost200Response> {
      return localVarFp.authLoginPost(authLoginPostRequest, options).then(request => request(axios, basePath));
    },
    /**
     * Аутентификация/регистрация через \"магическую\" ссылку на почту <br /><br /> <b>Authentication:</b> not required
     * @summary Аутентификация/регистрация через \"магическую\" ссылку на почту
     * @param {string} key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authMagicKeyPost(key: string, options?: any): AxiosPromise<object> {
      return localVarFp.authMagicKeyPost(key, options).then(request => request(axios, basePath));
    },
    /**
     * Аутентификация через \"магическую\" ссылку на почту <br /><br /> <b>Authentication:</b> not required
     * @summary Аутентификация через \"магическую\" ссылку на почту
     * @param {AuthMagicPostRequest} [authMagicPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authMagicPost(authMagicPostRequest?: AuthMagicPostRequest, options?: any): AxiosPromise<AuthMagicPost200Response> {
      return localVarFp.authMagicPost(authMagicPostRequest, options).then(request => request(axios, basePath));
    },
    /**
     * Get the user record belonging to the authentication token <br /><br /> <b>Authentication:</b> required
     * @summary Get the user record belonging to the authentication token
     * @param {boolean} [force]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authMeGet(force?: boolean, options?: any): AxiosPromise<AuthMeGet200Response> {
      return localVarFp.authMeGet(force, options).then(request => request(axios, basePath));
    },
    /**
     * Запрос письма на подтверждение электронной почты <br /><br /> <b>Authentication:</b> required
     * @summary Запрос письма на подтверждение электронной почты
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authRequestConfirmEmailPost(options?: any): AxiosPromise<object> {
      return localVarFp.authRequestConfirmEmailPost(options).then(request => request(axios, basePath));
    },
    /**
     * Signup and retrieve an authentication token <br /><br /> <b>Authentication:</b> not required
     * @summary Signup and retrieve an authentication token
     * @param {AuthSignupPostRequest} [authSignupPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authSignupPost(
      authSignupPostRequest?: AuthSignupPostRequest,
      options?: any,
    ): AxiosPromise<AuthLoginPost200Response> {
      return localVarFp.authSignupPost(authSignupPostRequest, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {AuthCodePostRequest} [authCodePostRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public authCodePost(authCodePostRequest?: AuthCodePostRequest, options?: AxiosRequestConfig) {
    return AuthApiFp(this.configuration)
      .authCodePost(authCodePostRequest, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Login and retrieve an authentication token <br /><br /> <b>Authentication:</b> not required
   * @summary Login and retrieve an authentication token
   * @param {AuthLoginPostRequest} [authLoginPostRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public authLoginPost(authLoginPostRequest?: AuthLoginPostRequest, options?: AxiosRequestConfig) {
    return AuthApiFp(this.configuration)
      .authLoginPost(authLoginPostRequest, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Аутентификация/регистрация через \"магическую\" ссылку на почту <br /><br /> <b>Authentication:</b> not required
   * @summary Аутентификация/регистрация через \"магическую\" ссылку на почту
   * @param {string} key
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public authMagicKeyPost(key: string, options?: AxiosRequestConfig) {
    return AuthApiFp(this.configuration)
      .authMagicKeyPost(key, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Аутентификация через \"магическую\" ссылку на почту <br /><br /> <b>Authentication:</b> not required
   * @summary Аутентификация через \"магическую\" ссылку на почту
   * @param {AuthMagicPostRequest} [authMagicPostRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public authMagicPost(authMagicPostRequest?: AuthMagicPostRequest, options?: AxiosRequestConfig) {
    return AuthApiFp(this.configuration)
      .authMagicPost(authMagicPostRequest, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Get the user record belonging to the authentication token <br /><br /> <b>Authentication:</b> required
   * @summary Get the user record belonging to the authentication token
   * @param {boolean} [force]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public authMeGet(force?: boolean, options?: AxiosRequestConfig) {
    return AuthApiFp(this.configuration)
      .authMeGet(force, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Запрос письма на подтверждение электронной почты <br /><br /> <b>Authentication:</b> required
   * @summary Запрос письма на подтверждение электронной почты
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public authRequestConfirmEmailPost(options?: AxiosRequestConfig) {
    return AuthApiFp(this.configuration)
      .authRequestConfirmEmailPost(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Signup and retrieve an authentication token <br /><br /> <b>Authentication:</b> not required
   * @summary Signup and retrieve an authentication token
   * @param {AuthSignupPostRequest} [authSignupPostRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public authSignupPost(authSignupPostRequest?: AuthSignupPostRequest, options?: AxiosRequestConfig) {
    return AuthApiFp(this.configuration)
      .authSignupPost(authSignupPostRequest, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * CreatorsApi - axios parameter creator
 * @export
 */
export const CreatorsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} creatorId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    creatorsCreatorIdSubscribePost: async (
      creatorId: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'creatorId' is not null or undefined
      assertParamExists('creatorsCreatorIdSubscribePost', 'creatorId', creatorId);
      const localVarPath = `/creators/{creatorId}/subscribe`.replace(
        `{${'creatorId'}}`,
        encodeURIComponent(String(creatorId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * CreatorsApi - functional programming interface
 * @export
 */
export const CreatorsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = CreatorsApiAxiosParamCreator(configuration);
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} creatorId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async creatorsCreatorIdSubscribePost(
      creatorId: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreatorsCreatorIdSubscribePost200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.creatorsCreatorIdSubscribePost(creatorId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * CreatorsApi - factory interface
 * @export
 */
export const CreatorsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = CreatorsApiFp(configuration);
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} creatorId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    creatorsCreatorIdSubscribePost(
      creatorId: number,
      options?: any,
    ): AxiosPromise<CreatorsCreatorIdSubscribePost200Response> {
      return localVarFp.creatorsCreatorIdSubscribePost(creatorId, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * CreatorsApi - object-oriented interface
 * @export
 * @class CreatorsApi
 * @extends {BaseAPI}
 */
export class CreatorsApi extends BaseAPI {
  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {number} creatorId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CreatorsApi
   */
  public creatorsCreatorIdSubscribePost(creatorId: number, options?: AxiosRequestConfig) {
    return CreatorsApiFp(this.configuration)
      .creatorsCreatorIdSubscribePost(creatorId, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * ExpertApi - axios parameter creator
 * @export
 */
export const ExpertApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Signup and retrieve an authentication token <br /><br /> <b>Authentication:</b> not required
     * @summary Signup and retrieve an authentication token
     * @param {AuthLoginPostRequest} [authLoginPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    expertAuthSignupPost: async (
      authLoginPostRequest?: AuthLoginPostRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/expert/auth/signup`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(authLoginPostRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Получение списка подписчиков <br /><br /> <b>Authentication:</b> required
     * @summary Получение списка подписчиков
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    expertMembersGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/expert/members`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Настройка цены <br /><br /> <b>Authentication:</b> required
     * @summary Настройка цены
     * @param {ExpertPricePostRequest} [expertPricePostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    expertPricePost: async (
      expertPricePostRequest?: ExpertPricePostRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/expert/price`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        expertPricePostRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ExpertApi - functional programming interface
 * @export
 */
export const ExpertApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ExpertApiAxiosParamCreator(configuration);
  return {
    /**
     * Signup and retrieve an authentication token <br /><br /> <b>Authentication:</b> not required
     * @summary Signup and retrieve an authentication token
     * @param {AuthLoginPostRequest} [authLoginPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async expertAuthSignupPost(
      authLoginPostRequest?: AuthLoginPostRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthLoginPost200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.expertAuthSignupPost(authLoginPostRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Получение списка подписчиков <br /><br /> <b>Authentication:</b> required
     * @summary Получение списка подписчиков
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async expertMembersGet(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ExpertMembersGet200ResponseInner>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.expertMembersGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Настройка цены <br /><br /> <b>Authentication:</b> required
     * @summary Настройка цены
     * @param {ExpertPricePostRequest} [expertPricePostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async expertPricePost(
      expertPricePostRequest?: ExpertPricePostRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExpertPricePost200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.expertPricePost(expertPricePostRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * ExpertApi - factory interface
 * @export
 */
export const ExpertApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = ExpertApiFp(configuration);
  return {
    /**
     * Signup and retrieve an authentication token <br /><br /> <b>Authentication:</b> not required
     * @summary Signup and retrieve an authentication token
     * @param {AuthLoginPostRequest} [authLoginPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    expertAuthSignupPost(
      authLoginPostRequest?: AuthLoginPostRequest,
      options?: any,
    ): AxiosPromise<AuthLoginPost200Response> {
      return localVarFp.expertAuthSignupPost(authLoginPostRequest, options).then(request => request(axios, basePath));
    },
    /**
     * Получение списка подписчиков <br /><br /> <b>Authentication:</b> required
     * @summary Получение списка подписчиков
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    expertMembersGet(options?: any): AxiosPromise<Array<ExpertMembersGet200ResponseInner>> {
      return localVarFp.expertMembersGet(options).then(request => request(axios, basePath));
    },
    /**
     * Настройка цены <br /><br /> <b>Authentication:</b> required
     * @summary Настройка цены
     * @param {ExpertPricePostRequest} [expertPricePostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    expertPricePost(
      expertPricePostRequest?: ExpertPricePostRequest,
      options?: any,
    ): AxiosPromise<ExpertPricePost200Response> {
      return localVarFp.expertPricePost(expertPricePostRequest, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * ExpertApi - object-oriented interface
 * @export
 * @class ExpertApi
 * @extends {BaseAPI}
 */
export class ExpertApi extends BaseAPI {
  /**
   * Signup and retrieve an authentication token <br /><br /> <b>Authentication:</b> not required
   * @summary Signup and retrieve an authentication token
   * @param {AuthLoginPostRequest} [authLoginPostRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExpertApi
   */
  public expertAuthSignupPost(authLoginPostRequest?: AuthLoginPostRequest, options?: AxiosRequestConfig) {
    return ExpertApiFp(this.configuration)
      .expertAuthSignupPost(authLoginPostRequest, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Получение списка подписчиков <br /><br /> <b>Authentication:</b> required
   * @summary Получение списка подписчиков
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExpertApi
   */
  public expertMembersGet(options?: AxiosRequestConfig) {
    return ExpertApiFp(this.configuration)
      .expertMembersGet(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Настройка цены <br /><br /> <b>Authentication:</b> required
   * @summary Настройка цены
   * @param {ExpertPricePostRequest} [expertPricePostRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExpertApi
   */
  public expertPricePost(expertPricePostRequest?: ExpertPricePostRequest, options?: AxiosRequestConfig) {
    return ExpertApiFp(this.configuration)
      .expertPricePost(expertPricePostRequest, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * IosApi - axios parameter creator
 * @export
 */
export const IosApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * <br /><br /> <b>Authentication:</b> not required
     * @summary
     * @param {string} deviceToken
     * @param {string} [message]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    iosPushDmGet: async (
      deviceToken: string,
      message?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'deviceToken' is not null or undefined
      assertParamExists('iosPushDmGet', 'deviceToken', deviceToken);
      const localVarPath = `/ios/push/dm`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (deviceToken !== undefined) {
        localVarQueryParameter['deviceToken'] = deviceToken;
      }

      if (message !== undefined) {
        localVarQueryParameter['message'] = message;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * IosApi - functional programming interface
 * @export
 */
export const IosApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = IosApiAxiosParamCreator(configuration);
  return {
    /**
     * <br /><br /> <b>Authentication:</b> not required
     * @summary
     * @param {string} deviceToken
     * @param {string} [message]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async iosPushDmGet(
      deviceToken: string,
      message?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.iosPushDmGet(deviceToken, message, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * IosApi - factory interface
 * @export
 */
export const IosApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = IosApiFp(configuration);
  return {
    /**
     * <br /><br /> <b>Authentication:</b> not required
     * @summary
     * @param {string} deviceToken
     * @param {string} [message]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    iosPushDmGet(deviceToken: string, message?: string, options?: any): AxiosPromise<object> {
      return localVarFp.iosPushDmGet(deviceToken, message, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * IosApi - object-oriented interface
 * @export
 * @class IosApi
 * @extends {BaseAPI}
 */
export class IosApi extends BaseAPI {
  /**
   * <br /><br /> <b>Authentication:</b> not required
   * @summary
   * @param {string} deviceToken
   * @param {string} [message]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof IosApi
   */
  public iosPushDmGet(deviceToken: string, message?: string, options?: AxiosRequestConfig) {
    return IosApiFp(this.configuration)
      .iosPushDmGet(deviceToken, message, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * LinksApi - axios parameter creator
 * @export
 */
export const LinksApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    linksGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/links`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} linkId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    linksLinkIdDelete: async (linkId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'linkId' is not null or undefined
      assertParamExists('linksLinkIdDelete', 'linkId', linkId);
      const localVarPath = `/links/{linkId}`.replace(`{${'linkId'}}`, encodeURIComponent(String(linkId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} linkId
     * @param {LinksLinkIdDeleteRequest} [linksLinkIdDeleteRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    linksLinkIdPost: async (
      linkId: number,
      linksLinkIdDeleteRequest?: LinksLinkIdDeleteRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'linkId' is not null or undefined
      assertParamExists('linksLinkIdPost', 'linkId', linkId);
      const localVarPath = `/links/{linkId}`.replace(`{${'linkId'}}`, encodeURIComponent(String(linkId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        linksLinkIdDeleteRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {LinksOrderPatchRequest} [linksOrderPatchRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    linksOrderPatch: async (
      linksOrderPatchRequest?: LinksOrderPatchRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/links/order`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        linksOrderPatchRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {LinksLinkIdDeleteRequest} [linksLinkIdDeleteRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    linksPost: async (
      linksLinkIdDeleteRequest?: LinksLinkIdDeleteRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/links`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        linksLinkIdDeleteRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * LinksApi - functional programming interface
 * @export
 */
export const LinksApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = LinksApiAxiosParamCreator(configuration);
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async linksGet(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LinksLinkIdDelete200Response>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.linksGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} linkId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async linksLinkIdDelete(
      linkId: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.linksLinkIdDelete(linkId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} linkId
     * @param {LinksLinkIdDeleteRequest} [linksLinkIdDeleteRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async linksLinkIdPost(
      linkId: number,
      linksLinkIdDeleteRequest?: LinksLinkIdDeleteRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LinksLinkIdDelete200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.linksLinkIdPost(
        linkId,
        linksLinkIdDeleteRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {LinksOrderPatchRequest} [linksOrderPatchRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async linksOrderPatch(
      linksOrderPatchRequest?: LinksOrderPatchRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.linksOrderPatch(linksOrderPatchRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {LinksLinkIdDeleteRequest} [linksLinkIdDeleteRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async linksPost(
      linksLinkIdDeleteRequest?: LinksLinkIdDeleteRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LinksGet200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.linksPost(linksLinkIdDeleteRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * LinksApi - factory interface
 * @export
 */
export const LinksApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = LinksApiFp(configuration);
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    linksGet(options?: any): AxiosPromise<Array<LinksLinkIdDelete200Response>> {
      return localVarFp.linksGet(options).then(request => request(axios, basePath));
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} linkId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    linksLinkIdDelete(linkId: number, options?: any): AxiosPromise<object> {
      return localVarFp.linksLinkIdDelete(linkId, options).then(request => request(axios, basePath));
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} linkId
     * @param {LinksLinkIdDeleteRequest} [linksLinkIdDeleteRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    linksLinkIdPost(
      linkId: number,
      linksLinkIdDeleteRequest?: LinksLinkIdDeleteRequest,
      options?: any,
    ): AxiosPromise<LinksLinkIdDelete200Response> {
      return localVarFp
        .linksLinkIdPost(linkId, linksLinkIdDeleteRequest, options)
        .then(request => request(axios, basePath));
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {LinksOrderPatchRequest} [linksOrderPatchRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    linksOrderPatch(linksOrderPatchRequest?: LinksOrderPatchRequest, options?: any): AxiosPromise<object> {
      return localVarFp.linksOrderPatch(linksOrderPatchRequest, options).then(request => request(axios, basePath));
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {LinksLinkIdDeleteRequest} [linksLinkIdDeleteRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    linksPost(linksLinkIdDeleteRequest?: LinksLinkIdDeleteRequest, options?: any): AxiosPromise<LinksGet200Response> {
      return localVarFp.linksPost(linksLinkIdDeleteRequest, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * LinksApi - object-oriented interface
 * @export
 * @class LinksApi
 * @extends {BaseAPI}
 */
export class LinksApi extends BaseAPI {
  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LinksApi
   */
  public linksGet(options?: AxiosRequestConfig) {
    return LinksApiFp(this.configuration)
      .linksGet(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {number} linkId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LinksApi
   */
  public linksLinkIdDelete(linkId: number, options?: AxiosRequestConfig) {
    return LinksApiFp(this.configuration)
      .linksLinkIdDelete(linkId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {number} linkId
   * @param {LinksLinkIdDeleteRequest} [linksLinkIdDeleteRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LinksApi
   */
  public linksLinkIdPost(
    linkId: number,
    linksLinkIdDeleteRequest?: LinksLinkIdDeleteRequest,
    options?: AxiosRequestConfig,
  ) {
    return LinksApiFp(this.configuration)
      .linksLinkIdPost(linkId, linksLinkIdDeleteRequest, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {LinksOrderPatchRequest} [linksOrderPatchRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LinksApi
   */
  public linksOrderPatch(linksOrderPatchRequest?: LinksOrderPatchRequest, options?: AxiosRequestConfig) {
    return LinksApiFp(this.configuration)
      .linksOrderPatch(linksOrderPatchRequest, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {LinksLinkIdDeleteRequest} [linksLinkIdDeleteRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LinksApi
   */
  public linksPost(linksLinkIdDeleteRequest?: LinksLinkIdDeleteRequest, options?: AxiosRequestConfig) {
    return LinksApiFp(this.configuration)
      .linksPost(linksLinkIdDeleteRequest, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * MeApi - axios parameter creator
 * @export
 */
export const MeApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Преобразование кастомера в эксперта <br /><br /> <b>Authentication:</b> required
     * @summary Преобразование кастомера в эксперта
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    meBecomeAnExpertPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/me/becomeAnExpert`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    meCreatorsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/me/creators`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mePointsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/me/points`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mePurchasesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/me/purchases`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    meSubscriptionsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/me/subscriptions`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * MeApi - functional programming interface
 * @export
 */
export const MeApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = MeApiAxiosParamCreator(configuration);
  return {
    /**
     * Преобразование кастомера в эксперта <br /><br /> <b>Authentication:</b> required
     * @summary Преобразование кастомера в эксперта
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async meBecomeAnExpertPost(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.meBecomeAnExpertPost(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async meCreatorsGet(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MeCreatorsGet200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.meCreatorsGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async mePointsGet(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MePointsGet200ResponseInner>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.mePointsGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async mePurchasesGet(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MePurchasesGet200ResponseInner>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.mePurchasesGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async meSubscriptionsGet(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MeSubscriptionsGet200ResponseInner>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.meSubscriptionsGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * MeApi - factory interface
 * @export
 */
export const MeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = MeApiFp(configuration);
  return {
    /**
     * Преобразование кастомера в эксперта <br /><br /> <b>Authentication:</b> required
     * @summary Преобразование кастомера в эксперта
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    meBecomeAnExpertPost(options?: any): AxiosPromise<object> {
      return localVarFp.meBecomeAnExpertPost(options).then(request => request(axios, basePath));
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    meCreatorsGet(options?: any): AxiosPromise<MeCreatorsGet200Response> {
      return localVarFp.meCreatorsGet(options).then(request => request(axios, basePath));
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mePointsGet(options?: any): AxiosPromise<Array<MePointsGet200ResponseInner>> {
      return localVarFp.mePointsGet(options).then(request => request(axios, basePath));
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mePurchasesGet(options?: any): AxiosPromise<Array<MePurchasesGet200ResponseInner>> {
      return localVarFp.mePurchasesGet(options).then(request => request(axios, basePath));
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    meSubscriptionsGet(options?: any): AxiosPromise<Array<MeSubscriptionsGet200ResponseInner>> {
      return localVarFp.meSubscriptionsGet(options).then(request => request(axios, basePath));
    },
  };
};

/**
 * MeApi - object-oriented interface
 * @export
 * @class MeApi
 * @extends {BaseAPI}
 */
export class MeApi extends BaseAPI {
  /**
   * Преобразование кастомера в эксперта <br /><br /> <b>Authentication:</b> required
   * @summary Преобразование кастомера в эксперта
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MeApi
   */
  public meBecomeAnExpertPost(options?: AxiosRequestConfig) {
    return MeApiFp(this.configuration)
      .meBecomeAnExpertPost(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MeApi
   */
  public meCreatorsGet(options?: AxiosRequestConfig) {
    return MeApiFp(this.configuration)
      .meCreatorsGet(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MeApi
   */
  public mePointsGet(options?: AxiosRequestConfig) {
    return MeApiFp(this.configuration)
      .mePointsGet(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MeApi
   */
  public mePurchasesGet(options?: AxiosRequestConfig) {
    return MeApiFp(this.configuration)
      .mePurchasesGet(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MeApi
   */
  public meSubscriptionsGet(options?: AxiosRequestConfig) {
    return MeApiFp(this.configuration)
      .meSubscriptionsGet(options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * MobileApi - axios parameter creator
 * @export
 */
export const MobileApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * <br /><br /> <b>Authentication:</b> not required
     * @summary
     * @param {MobileUploadUrlPostRequest} [mobileUploadUrlPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mobileUploadUrlPost: async (
      mobileUploadUrlPostRequest?: MobileUploadUrlPostRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/mobile/upload/url`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        mobileUploadUrlPostRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * MobileApi - functional programming interface
 * @export
 */
export const MobileApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = MobileApiAxiosParamCreator(configuration);
  return {
    /**
     * <br /><br /> <b>Authentication:</b> not required
     * @summary
     * @param {MobileUploadUrlPostRequest} [mobileUploadUrlPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async mobileUploadUrlPost(
      mobileUploadUrlPostRequest?: MobileUploadUrlPostRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MobileUploadUrlPost200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.mobileUploadUrlPost(
        mobileUploadUrlPostRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * MobileApi - factory interface
 * @export
 */
export const MobileApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = MobileApiFp(configuration);
  return {
    /**
     * <br /><br /> <b>Authentication:</b> not required
     * @summary
     * @param {MobileUploadUrlPostRequest} [mobileUploadUrlPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mobileUploadUrlPost(
      mobileUploadUrlPostRequest?: MobileUploadUrlPostRequest,
      options?: any,
    ): AxiosPromise<MobileUploadUrlPost200Response> {
      return localVarFp
        .mobileUploadUrlPost(mobileUploadUrlPostRequest, options)
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * MobileApi - object-oriented interface
 * @export
 * @class MobileApi
 * @extends {BaseAPI}
 */
export class MobileApi extends BaseAPI {
  /**
   * <br /><br /> <b>Authentication:</b> not required
   * @summary
   * @param {MobileUploadUrlPostRequest} [mobileUploadUrlPostRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MobileApi
   */
  public mobileUploadUrlPost(mobileUploadUrlPostRequest?: MobileUploadUrlPostRequest, options?: AxiosRequestConfig) {
    return MobileApiFp(this.configuration)
      .mobileUploadUrlPost(mobileUploadUrlPostRequest, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * NpsApi - axios parameter creator
 * @export
 */
export const NpsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    npsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/nps`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {NpsGetRequest} [npsGetRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    npsPost: async (npsGetRequest?: NpsGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/nps`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(npsGetRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * NpsApi - functional programming interface
 * @export
 */
export const NpsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = NpsApiAxiosParamCreator(configuration);
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async npsGet(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NpsGet200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.npsGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {NpsGetRequest} [npsGetRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async npsPost(
      npsGetRequest?: NpsGetRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NpsGet200Response1>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.npsPost(npsGetRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * NpsApi - factory interface
 * @export
 */
export const NpsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = NpsApiFp(configuration);
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    npsGet(options?: any): AxiosPromise<NpsGet200Response> {
      return localVarFp.npsGet(options).then(request => request(axios, basePath));
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {NpsGetRequest} [npsGetRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    npsPost(npsGetRequest?: NpsGetRequest, options?: any): AxiosPromise<NpsGet200Response1> {
      return localVarFp.npsPost(npsGetRequest, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * NpsApi - object-oriented interface
 * @export
 * @class NpsApi
 * @extends {BaseAPI}
 */
export class NpsApi extends BaseAPI {
  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NpsApi
   */
  public npsGet(options?: AxiosRequestConfig) {
    return NpsApiFp(this.configuration)
      .npsGet(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {NpsGetRequest} [npsGetRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NpsApi
   */
  public npsPost(npsGetRequest?: NpsGetRequest, options?: AxiosRequestConfig) {
    return NpsApiFp(this.configuration)
      .npsPost(npsGetRequest, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * PaymentMethodApi - axios parameter creator
 * @export
 */
export const PaymentMethodApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentMethodCreatePost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/paymentMethod/create`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentMethodDelete: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/paymentMethod`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {PaymentMethodSuccessPostRequest} [paymentMethodSuccessPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentMethodSuccessPost: async (
      paymentMethodSuccessPostRequest?: PaymentMethodSuccessPostRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/paymentMethod/success`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        paymentMethodSuccessPostRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PaymentMethodApi - functional programming interface
 * @export
 */
export const PaymentMethodApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = PaymentMethodApiAxiosParamCreator(configuration);
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async paymentMethodCreatePost(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.paymentMethodCreatePost(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async paymentMethodDelete(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.paymentMethodDelete(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {PaymentMethodSuccessPostRequest} [paymentMethodSuccessPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async paymentMethodSuccessPost(
      paymentMethodSuccessPostRequest?: PaymentMethodSuccessPostRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.paymentMethodSuccessPost(
        paymentMethodSuccessPostRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * PaymentMethodApi - factory interface
 * @export
 */
export const PaymentMethodApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = PaymentMethodApiFp(configuration);
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentMethodCreatePost(options?: any): AxiosPromise<object> {
      return localVarFp.paymentMethodCreatePost(options).then(request => request(axios, basePath));
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentMethodDelete(options?: any): AxiosPromise<object> {
      return localVarFp.paymentMethodDelete(options).then(request => request(axios, basePath));
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {PaymentMethodSuccessPostRequest} [paymentMethodSuccessPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentMethodSuccessPost(
      paymentMethodSuccessPostRequest?: PaymentMethodSuccessPostRequest,
      options?: any,
    ): AxiosPromise<object> {
      return localVarFp
        .paymentMethodSuccessPost(paymentMethodSuccessPostRequest, options)
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * PaymentMethodApi - object-oriented interface
 * @export
 * @class PaymentMethodApi
 * @extends {BaseAPI}
 */
export class PaymentMethodApi extends BaseAPI {
  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaymentMethodApi
   */
  public paymentMethodCreatePost(options?: AxiosRequestConfig) {
    return PaymentMethodApiFp(this.configuration)
      .paymentMethodCreatePost(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaymentMethodApi
   */
  public paymentMethodDelete(options?: AxiosRequestConfig) {
    return PaymentMethodApiFp(this.configuration)
      .paymentMethodDelete(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {PaymentMethodSuccessPostRequest} [paymentMethodSuccessPostRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaymentMethodApi
   */
  public paymentMethodSuccessPost(
    paymentMethodSuccessPostRequest?: PaymentMethodSuccessPostRequest,
    options?: AxiosRequestConfig,
  ) {
    return PaymentMethodApiFp(this.configuration)
      .paymentMethodSuccessPost(paymentMethodSuccessPostRequest, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * PaymentsApi - axios parameter creator
 * @export
 */
export const PaymentsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {string} paymentId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentsPaymentIdGet: async (paymentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'paymentId' is not null or undefined
      assertParamExists('paymentsPaymentIdGet', 'paymentId', paymentId);
      const localVarPath = `/payments/{paymentId}`.replace(`{${'paymentId'}}`, encodeURIComponent(String(paymentId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PaymentsApi - functional programming interface
 * @export
 */
export const PaymentsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = PaymentsApiAxiosParamCreator(configuration);
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {string} paymentId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async paymentsPaymentIdGet(
      paymentId: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentsPaymentIdGet200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.paymentsPaymentIdGet(paymentId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * PaymentsApi - factory interface
 * @export
 */
export const PaymentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = PaymentsApiFp(configuration);
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {string} paymentId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentsPaymentIdGet(paymentId: string, options?: any): AxiosPromise<PaymentsPaymentIdGet200Response> {
      return localVarFp.paymentsPaymentIdGet(paymentId, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * PaymentsApi - object-oriented interface
 * @export
 * @class PaymentsApi
 * @extends {BaseAPI}
 */
export class PaymentsApi extends BaseAPI {
  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {string} paymentId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaymentsApi
   */
  public paymentsPaymentIdGet(paymentId: string, options?: AxiosRequestConfig) {
    return PaymentsApiFp(this.configuration)
      .paymentsPaymentIdGet(paymentId, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * ProfileApi - axios parameter creator
 * @export
 */
export const ProfileApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} deleted
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    profileChatsArchivedDelete: async (deleted: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'deleted' is not null or undefined
      assertParamExists('profileChatsArchivedDelete', 'deleted', deleted);
      const localVarPath = `/profile/chats/archived`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (deleted !== undefined) {
        localVarQueryParameter['deleted'] = deleted;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {ProfileChatsArchivedDeleteRequest} [profileChatsArchivedDeleteRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    profileChatsArchivedPost: async (
      profileChatsArchivedDeleteRequest?: ProfileChatsArchivedDeleteRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/profile/chats/archived`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        profileChatsArchivedDeleteRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} deleted
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    profileChatsPinnedDelete: async (deleted: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'deleted' is not null or undefined
      assertParamExists('profileChatsPinnedDelete', 'deleted', deleted);
      const localVarPath = `/profile/chats/pinned`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (deleted !== undefined) {
        localVarQueryParameter['deleted'] = deleted;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {ProfileChatsArchivedDeleteRequest} [profileChatsArchivedDeleteRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    profileChatsPinnedPost: async (
      profileChatsArchivedDeleteRequest?: ProfileChatsArchivedDeleteRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/profile/chats/pinned`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        profileChatsArchivedDeleteRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {ProfileConfirmEmailPostRequest} [profileConfirmEmailPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    profileConfirmEmailPost: async (
      profileConfirmEmailPostRequest?: ProfileConfirmEmailPostRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/profile/confirmEmail`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        profileConfirmEmailPostRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Редактирование профиля <br /><br /> <b>Authentication:</b> required
     * @summary Редактирование профиля
     * @param {ProfilePatchRequest} [profilePatchRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    profilePatch: async (
      profilePatchRequest?: ProfilePatchRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/profile`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(profilePatchRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    profilePushTokensDelete: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/profile/push/tokens`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {ProfilePushTokensDeleteRequest} [profilePushTokensDeleteRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    profilePushTokensPost: async (
      profilePushTokensDeleteRequest?: ProfilePushTokensDeleteRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/profile/push/tokens`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        profilePushTokensDeleteRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ProfileApi - functional programming interface
 * @export
 */
export const ProfileApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ProfileApiAxiosParamCreator(configuration);
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} deleted
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async profileChatsArchivedDelete(
      deleted: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProfileChatsArchivedDelete200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.profileChatsArchivedDelete(deleted, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {ProfileChatsArchivedDeleteRequest} [profileChatsArchivedDeleteRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async profileChatsArchivedPost(
      profileChatsArchivedDeleteRequest?: ProfileChatsArchivedDeleteRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProfileChatsArchivedDelete200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.profileChatsArchivedPost(
        profileChatsArchivedDeleteRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} deleted
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async profileChatsPinnedDelete(
      deleted: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProfileChatsPinnedDelete200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.profileChatsPinnedDelete(deleted, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {ProfileChatsArchivedDeleteRequest} [profileChatsArchivedDeleteRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async profileChatsPinnedPost(
      profileChatsArchivedDeleteRequest?: ProfileChatsArchivedDeleteRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProfileChatsPinnedDelete200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.profileChatsPinnedPost(
        profileChatsArchivedDeleteRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {ProfileConfirmEmailPostRequest} [profileConfirmEmailPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async profileConfirmEmailPost(
      profileConfirmEmailPostRequest?: ProfileConfirmEmailPostRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.profileConfirmEmailPost(
        profileConfirmEmailPostRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Редактирование профиля <br /><br /> <b>Authentication:</b> required
     * @summary Редактирование профиля
     * @param {ProfilePatchRequest} [profilePatchRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async profilePatch(
      profilePatchRequest?: ProfilePatchRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProfilePatch200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.profilePatch(profilePatchRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async profilePushTokensDelete(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.profilePushTokensDelete(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {ProfilePushTokensDeleteRequest} [profilePushTokensDeleteRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async profilePushTokensPost(
      profilePushTokensDeleteRequest?: ProfilePushTokensDeleteRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.profilePushTokensPost(
        profilePushTokensDeleteRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * ProfileApi - factory interface
 * @export
 */
export const ProfileApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = ProfileApiFp(configuration);
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} deleted
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    profileChatsArchivedDelete(deleted: number, options?: any): AxiosPromise<ProfileChatsArchivedDelete200Response> {
      return localVarFp.profileChatsArchivedDelete(deleted, options).then(request => request(axios, basePath));
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {ProfileChatsArchivedDeleteRequest} [profileChatsArchivedDeleteRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    profileChatsArchivedPost(
      profileChatsArchivedDeleteRequest?: ProfileChatsArchivedDeleteRequest,
      options?: any,
    ): AxiosPromise<ProfileChatsArchivedDelete200Response> {
      return localVarFp
        .profileChatsArchivedPost(profileChatsArchivedDeleteRequest, options)
        .then(request => request(axios, basePath));
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} deleted
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    profileChatsPinnedDelete(deleted: number, options?: any): AxiosPromise<ProfileChatsPinnedDelete200Response> {
      return localVarFp.profileChatsPinnedDelete(deleted, options).then(request => request(axios, basePath));
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {ProfileChatsArchivedDeleteRequest} [profileChatsArchivedDeleteRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    profileChatsPinnedPost(
      profileChatsArchivedDeleteRequest?: ProfileChatsArchivedDeleteRequest,
      options?: any,
    ): AxiosPromise<ProfileChatsPinnedDelete200Response> {
      return localVarFp
        .profileChatsPinnedPost(profileChatsArchivedDeleteRequest, options)
        .then(request => request(axios, basePath));
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {ProfileConfirmEmailPostRequest} [profileConfirmEmailPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    profileConfirmEmailPost(
      profileConfirmEmailPostRequest?: ProfileConfirmEmailPostRequest,
      options?: any,
    ): AxiosPromise<object> {
      return localVarFp
        .profileConfirmEmailPost(profileConfirmEmailPostRequest, options)
        .then(request => request(axios, basePath));
    },
    /**
     * Редактирование профиля <br /><br /> <b>Authentication:</b> required
     * @summary Редактирование профиля
     * @param {ProfilePatchRequest} [profilePatchRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    profilePatch(profilePatchRequest?: ProfilePatchRequest, options?: any): AxiosPromise<ProfilePatch200Response> {
      return localVarFp.profilePatch(profilePatchRequest, options).then(request => request(axios, basePath));
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    profilePushTokensDelete(options?: any): AxiosPromise<object> {
      return localVarFp.profilePushTokensDelete(options).then(request => request(axios, basePath));
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {ProfilePushTokensDeleteRequest} [profilePushTokensDeleteRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    profilePushTokensPost(
      profilePushTokensDeleteRequest?: ProfilePushTokensDeleteRequest,
      options?: any,
    ): AxiosPromise<object> {
      return localVarFp
        .profilePushTokensPost(profilePushTokensDeleteRequest, options)
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * ProfileApi - object-oriented interface
 * @export
 * @class ProfileApi
 * @extends {BaseAPI}
 */
export class ProfileApi extends BaseAPI {
  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {number} deleted
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProfileApi
   */
  public profileChatsArchivedDelete(deleted: number, options?: AxiosRequestConfig) {
    return ProfileApiFp(this.configuration)
      .profileChatsArchivedDelete(deleted, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {ProfileChatsArchivedDeleteRequest} [profileChatsArchivedDeleteRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProfileApi
   */
  public profileChatsArchivedPost(
    profileChatsArchivedDeleteRequest?: ProfileChatsArchivedDeleteRequest,
    options?: AxiosRequestConfig,
  ) {
    return ProfileApiFp(this.configuration)
      .profileChatsArchivedPost(profileChatsArchivedDeleteRequest, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {number} deleted
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProfileApi
   */
  public profileChatsPinnedDelete(deleted: number, options?: AxiosRequestConfig) {
    return ProfileApiFp(this.configuration)
      .profileChatsPinnedDelete(deleted, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {ProfileChatsArchivedDeleteRequest} [profileChatsArchivedDeleteRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProfileApi
   */
  public profileChatsPinnedPost(
    profileChatsArchivedDeleteRequest?: ProfileChatsArchivedDeleteRequest,
    options?: AxiosRequestConfig,
  ) {
    return ProfileApiFp(this.configuration)
      .profileChatsPinnedPost(profileChatsArchivedDeleteRequest, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {ProfileConfirmEmailPostRequest} [profileConfirmEmailPostRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProfileApi
   */
  public profileConfirmEmailPost(
    profileConfirmEmailPostRequest?: ProfileConfirmEmailPostRequest,
    options?: AxiosRequestConfig,
  ) {
    return ProfileApiFp(this.configuration)
      .profileConfirmEmailPost(profileConfirmEmailPostRequest, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Редактирование профиля <br /><br /> <b>Authentication:</b> required
   * @summary Редактирование профиля
   * @param {ProfilePatchRequest} [profilePatchRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProfileApi
   */
  public profilePatch(profilePatchRequest?: ProfilePatchRequest, options?: AxiosRequestConfig) {
    return ProfileApiFp(this.configuration)
      .profilePatch(profilePatchRequest, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProfileApi
   */
  public profilePushTokensDelete(options?: AxiosRequestConfig) {
    return ProfileApiFp(this.configuration)
      .profilePushTokensDelete(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {ProfilePushTokensDeleteRequest} [profilePushTokensDeleteRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProfileApi
   */
  public profilePushTokensPost(
    profilePushTokensDeleteRequest?: ProfilePushTokensDeleteRequest,
    options?: AxiosRequestConfig,
  ) {
    return ProfileApiFp(this.configuration)
      .profilePushTokensPost(profilePushTokensDeleteRequest, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * SelfplusUsersApi - axios parameter creator
 * @export
 */
export const SelfplusUsersApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {boolean} [force]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    selfplusUsersGet: async (force?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/selfplusUsers`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (force !== undefined) {
        localVarQueryParameter['force'] = force;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * SelfplusUsersApi - functional programming interface
 * @export
 */
export const SelfplusUsersApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = SelfplusUsersApiAxiosParamCreator(configuration);
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {boolean} [force]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async selfplusUsersGet(
      force?: boolean,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SelfplusUsersGet200ResponseInner>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.selfplusUsersGet(force, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * SelfplusUsersApi - factory interface
 * @export
 */
export const SelfplusUsersApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = SelfplusUsersApiFp(configuration);
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {boolean} [force]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    selfplusUsersGet(force?: boolean, options?: any): AxiosPromise<Array<SelfplusUsersGet200ResponseInner>> {
      return localVarFp.selfplusUsersGet(force, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * SelfplusUsersApi - object-oriented interface
 * @export
 * @class SelfplusUsersApi
 * @extends {BaseAPI}
 */
export class SelfplusUsersApi extends BaseAPI {
  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {boolean} [force]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SelfplusUsersApi
   */
  public selfplusUsersGet(force?: boolean, options?: AxiosRequestConfig) {
    return SelfplusUsersApiFp(this.configuration)
      .selfplusUsersGet(force, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * SignupApi - axios parameter creator
 * @export
 */
export const SignupApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Аутентификация/регистрация через \"магическую\" ссылку на почту <br /><br /> <b>Authentication:</b> not required
     * @summary Аутентификация/регистрация через \"магическую\" ссылку на почту
     * @param {SignupMagicPostRequest} [signupMagicPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    signupMagicPost: async (
      signupMagicPostRequest?: SignupMagicPostRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/signup/magic`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        signupMagicPostRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * SignupApi - functional programming interface
 * @export
 */
export const SignupApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = SignupApiAxiosParamCreator(configuration);
  return {
    /**
     * Аутентификация/регистрация через \"магическую\" ссылку на почту <br /><br /> <b>Authentication:</b> not required
     * @summary Аутентификация/регистрация через \"магическую\" ссылку на почту
     * @param {SignupMagicPostRequest} [signupMagicPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async signupMagicPost(
      signupMagicPostRequest?: SignupMagicPostRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthMagicPost200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.signupMagicPost(signupMagicPostRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * SignupApi - factory interface
 * @export
 */
export const SignupApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = SignupApiFp(configuration);
  return {
    /**
     * Аутентификация/регистрация через \"магическую\" ссылку на почту <br /><br /> <b>Authentication:</b> not required
     * @summary Аутентификация/регистрация через \"магическую\" ссылку на почту
     * @param {SignupMagicPostRequest} [signupMagicPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    signupMagicPost(
      signupMagicPostRequest?: SignupMagicPostRequest,
      options?: any,
    ): AxiosPromise<AuthMagicPost200Response> {
      return localVarFp.signupMagicPost(signupMagicPostRequest, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * SignupApi - object-oriented interface
 * @export
 * @class SignupApi
 * @extends {BaseAPI}
 */
export class SignupApi extends BaseAPI {
  /**
   * Аутентификация/регистрация через \"магическую\" ссылку на почту <br /><br /> <b>Authentication:</b> not required
   * @summary Аутентификация/регистрация через \"магическую\" ссылку на почту
   * @param {SignupMagicPostRequest} [signupMagicPostRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SignupApi
   */
  public signupMagicPost(signupMagicPostRequest?: SignupMagicPostRequest, options?: AxiosRequestConfig) {
    return SignupApiFp(this.configuration)
      .signupMagicPost(signupMagicPostRequest, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * SpacesApi - axios parameter creator
 * @export
 */
export const SpacesApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} spaceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    spacesSpaceIdCheckoutPost: async (spaceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'spaceId' is not null or undefined
      assertParamExists('spacesSpaceIdCheckoutPost', 'spaceId', spaceId);
      const localVarPath = `/spaces/{spaceId}/checkout`.replace(`{${'spaceId'}}`, encodeURIComponent(String(spaceId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} spaceId
     * @param {SpacesSpaceIdInAppPurchasePostRequest} [spacesSpaceIdInAppPurchasePostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    spacesSpaceIdInAppPurchasePost: async (
      spaceId: number,
      spacesSpaceIdInAppPurchasePostRequest?: SpacesSpaceIdInAppPurchasePostRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'spaceId' is not null or undefined
      assertParamExists('spacesSpaceIdInAppPurchasePost', 'spaceId', spaceId);
      const localVarPath = `/spaces/{spaceId}/inAppPurchase`.replace(
        `{${'spaceId'}}`,
        encodeURIComponent(String(spaceId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        spacesSpaceIdInAppPurchasePostRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} spaceId
     * @param {SpacesSpaceIdInAppPurchasePostRequest} [spacesSpaceIdInAppPurchasePostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    spacesSpaceIdInAppSubscriptionPost: async (
      spaceId: number,
      spacesSpaceIdInAppPurchasePostRequest?: SpacesSpaceIdInAppPurchasePostRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'spaceId' is not null or undefined
      assertParamExists('spacesSpaceIdInAppSubscriptionPost', 'spaceId', spaceId);
      const localVarPath = `/spaces/{spaceId}/inAppSubscription`.replace(
        `{${'spaceId'}}`,
        encodeURIComponent(String(spaceId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        spacesSpaceIdInAppPurchasePostRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} spaceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    spacesSpaceIdIsPurchasedPost: async (spaceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'spaceId' is not null or undefined
      assertParamExists('spacesSpaceIdIsPurchasedPost', 'spaceId', spaceId);
      const localVarPath = `/spaces/{spaceId}/isPurchased`.replace(
        `{${'spaceId'}}`,
        encodeURIComponent(String(spaceId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} spaceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    spacesSpaceIdPurchasePost: async (spaceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'spaceId' is not null or undefined
      assertParamExists('spacesSpaceIdPurchasePost', 'spaceId', spaceId);
      const localVarPath = `/spaces/{spaceId}/purchase`.replace(`{${'spaceId'}}`, encodeURIComponent(String(spaceId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} spaceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    spacesSpaceIdSubscribePost: async (spaceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'spaceId' is not null or undefined
      assertParamExists('spacesSpaceIdSubscribePost', 'spaceId', spaceId);
      const localVarPath = `/spaces/{spaceId}/subscribe`.replace(`{${'spaceId'}}`, encodeURIComponent(String(spaceId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} spaceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    spacesSpaceIdSubscriptionCheckoutPost: async (
      spaceId: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'spaceId' is not null or undefined
      assertParamExists('spacesSpaceIdSubscriptionCheckoutPost', 'spaceId', spaceId);
      const localVarPath = `/spaces/{spaceId}/subscription/checkout`.replace(
        `{${'spaceId'}}`,
        encodeURIComponent(String(spaceId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} spaceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    spacesSpaceIdSubscriptionGet: async (spaceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'spaceId' is not null or undefined
      assertParamExists('spacesSpaceIdSubscriptionGet', 'spaceId', spaceId);
      const localVarPath = `/spaces/{spaceId}/subscription`.replace(
        `{${'spaceId'}}`,
        encodeURIComponent(String(spaceId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} spaceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    spacesSpaceIdUnsubscribePost: async (spaceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'spaceId' is not null or undefined
      assertParamExists('spacesSpaceIdUnsubscribePost', 'spaceId', spaceId);
      const localVarPath = `/spaces/{spaceId}/unsubscribe`.replace(
        `{${'spaceId'}}`,
        encodeURIComponent(String(spaceId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * SpacesApi - functional programming interface
 * @export
 */
export const SpacesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = SpacesApiAxiosParamCreator(configuration);
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} spaceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async spacesSpaceIdCheckoutPost(
      spaceId: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.spacesSpaceIdCheckoutPost(spaceId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} spaceId
     * @param {SpacesSpaceIdInAppPurchasePostRequest} [spacesSpaceIdInAppPurchasePostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async spacesSpaceIdInAppPurchasePost(
      spaceId: number,
      spacesSpaceIdInAppPurchasePostRequest?: SpacesSpaceIdInAppPurchasePostRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.spacesSpaceIdInAppPurchasePost(
        spaceId,
        spacesSpaceIdInAppPurchasePostRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} spaceId
     * @param {SpacesSpaceIdInAppPurchasePostRequest} [spacesSpaceIdInAppPurchasePostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async spacesSpaceIdInAppSubscriptionPost(
      spaceId: number,
      spacesSpaceIdInAppPurchasePostRequest?: SpacesSpaceIdInAppPurchasePostRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.spacesSpaceIdInAppSubscriptionPost(
        spaceId,
        spacesSpaceIdInAppPurchasePostRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} spaceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async spacesSpaceIdIsPurchasedPost(
      spaceId: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.spacesSpaceIdIsPurchasedPost(spaceId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} spaceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async spacesSpaceIdPurchasePost(
      spaceId: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SpacesSpaceIdPurchasePost200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.spacesSpaceIdPurchasePost(spaceId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} spaceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async spacesSpaceIdSubscribePost(
      spaceId: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SpacesSpaceIdSubscribePost200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.spacesSpaceIdSubscribePost(spaceId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} spaceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async spacesSpaceIdSubscriptionCheckoutPost(
      spaceId: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.spacesSpaceIdSubscriptionCheckoutPost(spaceId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} spaceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async spacesSpaceIdSubscriptionGet(
      spaceId: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SpacesSpaceIdSubscriptionGet200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.spacesSpaceIdSubscriptionGet(spaceId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} spaceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async spacesSpaceIdUnsubscribePost(
      spaceId: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.spacesSpaceIdUnsubscribePost(spaceId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * SpacesApi - factory interface
 * @export
 */
export const SpacesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = SpacesApiFp(configuration);
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} spaceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    spacesSpaceIdCheckoutPost(spaceId: number, options?: any): AxiosPromise<object> {
      return localVarFp.spacesSpaceIdCheckoutPost(spaceId, options).then(request => request(axios, basePath));
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} spaceId
     * @param {SpacesSpaceIdInAppPurchasePostRequest} [spacesSpaceIdInAppPurchasePostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    spacesSpaceIdInAppPurchasePost(
      spaceId: number,
      spacesSpaceIdInAppPurchasePostRequest?: SpacesSpaceIdInAppPurchasePostRequest,
      options?: any,
    ): AxiosPromise<object> {
      return localVarFp
        .spacesSpaceIdInAppPurchasePost(spaceId, spacesSpaceIdInAppPurchasePostRequest, options)
        .then(request => request(axios, basePath));
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} spaceId
     * @param {SpacesSpaceIdInAppPurchasePostRequest} [spacesSpaceIdInAppPurchasePostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    spacesSpaceIdInAppSubscriptionPost(
      spaceId: number,
      spacesSpaceIdInAppPurchasePostRequest?: SpacesSpaceIdInAppPurchasePostRequest,
      options?: any,
    ): AxiosPromise<object> {
      return localVarFp
        .spacesSpaceIdInAppSubscriptionPost(spaceId, spacesSpaceIdInAppPurchasePostRequest, options)
        .then(request => request(axios, basePath));
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} spaceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    spacesSpaceIdIsPurchasedPost(spaceId: number, options?: any): AxiosPromise<object> {
      return localVarFp.spacesSpaceIdIsPurchasedPost(spaceId, options).then(request => request(axios, basePath));
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} spaceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    spacesSpaceIdPurchasePost(spaceId: number, options?: any): AxiosPromise<SpacesSpaceIdPurchasePost200Response> {
      return localVarFp.spacesSpaceIdPurchasePost(spaceId, options).then(request => request(axios, basePath));
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} spaceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    spacesSpaceIdSubscribePost(spaceId: number, options?: any): AxiosPromise<SpacesSpaceIdSubscribePost200Response> {
      return localVarFp.spacesSpaceIdSubscribePost(spaceId, options).then(request => request(axios, basePath));
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} spaceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    spacesSpaceIdSubscriptionCheckoutPost(spaceId: number, options?: any): AxiosPromise<object> {
      return localVarFp
        .spacesSpaceIdSubscriptionCheckoutPost(spaceId, options)
        .then(request => request(axios, basePath));
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} spaceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    spacesSpaceIdSubscriptionGet(
      spaceId: number,
      options?: any,
    ): AxiosPromise<SpacesSpaceIdSubscriptionGet200Response> {
      return localVarFp.spacesSpaceIdSubscriptionGet(spaceId, options).then(request => request(axios, basePath));
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} spaceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    spacesSpaceIdUnsubscribePost(spaceId: number, options?: any): AxiosPromise<object> {
      return localVarFp.spacesSpaceIdUnsubscribePost(spaceId, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * SpacesApi - object-oriented interface
 * @export
 * @class SpacesApi
 * @extends {BaseAPI}
 */
export class SpacesApi extends BaseAPI {
  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {number} spaceId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SpacesApi
   */
  public spacesSpaceIdCheckoutPost(spaceId: number, options?: AxiosRequestConfig) {
    return SpacesApiFp(this.configuration)
      .spacesSpaceIdCheckoutPost(spaceId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {number} spaceId
   * @param {SpacesSpaceIdInAppPurchasePostRequest} [spacesSpaceIdInAppPurchasePostRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SpacesApi
   */
  public spacesSpaceIdInAppPurchasePost(
    spaceId: number,
    spacesSpaceIdInAppPurchasePostRequest?: SpacesSpaceIdInAppPurchasePostRequest,
    options?: AxiosRequestConfig,
  ) {
    return SpacesApiFp(this.configuration)
      .spacesSpaceIdInAppPurchasePost(spaceId, spacesSpaceIdInAppPurchasePostRequest, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {number} spaceId
   * @param {SpacesSpaceIdInAppPurchasePostRequest} [spacesSpaceIdInAppPurchasePostRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SpacesApi
   */
  public spacesSpaceIdInAppSubscriptionPost(
    spaceId: number,
    spacesSpaceIdInAppPurchasePostRequest?: SpacesSpaceIdInAppPurchasePostRequest,
    options?: AxiosRequestConfig,
  ) {
    return SpacesApiFp(this.configuration)
      .spacesSpaceIdInAppSubscriptionPost(spaceId, spacesSpaceIdInAppPurchasePostRequest, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {number} spaceId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SpacesApi
   */
  public spacesSpaceIdIsPurchasedPost(spaceId: number, options?: AxiosRequestConfig) {
    return SpacesApiFp(this.configuration)
      .spacesSpaceIdIsPurchasedPost(spaceId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {number} spaceId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SpacesApi
   */
  public spacesSpaceIdPurchasePost(spaceId: number, options?: AxiosRequestConfig) {
    return SpacesApiFp(this.configuration)
      .spacesSpaceIdPurchasePost(spaceId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {number} spaceId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SpacesApi
   */
  public spacesSpaceIdSubscribePost(spaceId: number, options?: AxiosRequestConfig) {
    return SpacesApiFp(this.configuration)
      .spacesSpaceIdSubscribePost(spaceId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {number} spaceId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SpacesApi
   */
  public spacesSpaceIdSubscriptionCheckoutPost(spaceId: number, options?: AxiosRequestConfig) {
    return SpacesApiFp(this.configuration)
      .spacesSpaceIdSubscriptionCheckoutPost(spaceId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {number} spaceId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SpacesApi
   */
  public spacesSpaceIdSubscriptionGet(spaceId: number, options?: AxiosRequestConfig) {
    return SpacesApiFp(this.configuration)
      .spacesSpaceIdSubscriptionGet(spaceId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {number} spaceId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SpacesApi
   */
  public spacesSpaceIdUnsubscribePost(spaceId: number, options?: AxiosRequestConfig) {
    return SpacesApiFp(this.configuration)
      .spacesSpaceIdUnsubscribePost(spaceId, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * UploadApi - axios parameter creator
 * @export
 */
export const UploadApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {File} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadFilePost: async (file?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/upload/file`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (file !== undefined) {
        localVarFormParams.append('file', file as any);
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {MobileUploadUrlPostRequest} [mobileUploadUrlPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadUrlPost: async (
      mobileUploadUrlPostRequest?: MobileUploadUrlPostRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/upload/url`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        mobileUploadUrlPostRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * UploadApi - functional programming interface
 * @export
 */
export const UploadApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = UploadApiAxiosParamCreator(configuration);
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {File} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async uploadFilePost(
      file?: File,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadFilePost200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.uploadFilePost(file, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {MobileUploadUrlPostRequest} [mobileUploadUrlPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async uploadUrlPost(
      mobileUploadUrlPostRequest?: MobileUploadUrlPostRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadUrlPost200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.uploadUrlPost(mobileUploadUrlPostRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * UploadApi - factory interface
 * @export
 */
export const UploadApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = UploadApiFp(configuration);
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {File} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadFilePost(file?: File, options?: any): AxiosPromise<UploadFilePost200Response> {
      return localVarFp.uploadFilePost(file, options).then(request => request(axios, basePath));
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {MobileUploadUrlPostRequest} [mobileUploadUrlPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadUrlPost(
      mobileUploadUrlPostRequest?: MobileUploadUrlPostRequest,
      options?: any,
    ): AxiosPromise<UploadUrlPost200Response> {
      return localVarFp.uploadUrlPost(mobileUploadUrlPostRequest, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * UploadApi - object-oriented interface
 * @export
 * @class UploadApi
 * @extends {BaseAPI}
 */
export class UploadApi extends BaseAPI {
  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {File} [file]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UploadApi
   */
  public uploadFilePost(file?: File, options?: AxiosRequestConfig) {
    return UploadApiFp(this.configuration)
      .uploadFilePost(file, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {MobileUploadUrlPostRequest} [mobileUploadUrlPostRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UploadApi
   */
  public uploadUrlPost(mobileUploadUrlPostRequest?: MobileUploadUrlPostRequest, options?: AxiosRequestConfig) {
    return UploadApiFp(this.configuration)
      .uploadUrlPost(mobileUploadUrlPostRequest, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * VideosApi - axios parameter creator
 * @export
 */
export const VideosApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} videoFileId
     * @param {VideosVideoFileIdPreviewPostRequest} [videosVideoFileIdPreviewPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    videosVideoFileIdPreviewPost: async (
      videoFileId: number,
      videosVideoFileIdPreviewPostRequest?: VideosVideoFileIdPreviewPostRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'videoFileId' is not null or undefined
      assertParamExists('videosVideoFileIdPreviewPost', 'videoFileId', videoFileId);
      const localVarPath = `/videos/{videoFileId}/preview`.replace(
        `{${'videoFileId'}}`,
        encodeURIComponent(String(videoFileId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        videosVideoFileIdPreviewPostRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * VideosApi - functional programming interface
 * @export
 */
export const VideosApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = VideosApiAxiosParamCreator(configuration);
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} videoFileId
     * @param {VideosVideoFileIdPreviewPostRequest} [videosVideoFileIdPreviewPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async videosVideoFileIdPreviewPost(
      videoFileId: number,
      videosVideoFileIdPreviewPostRequest?: VideosVideoFileIdPreviewPostRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.videosVideoFileIdPreviewPost(
        videoFileId,
        videosVideoFileIdPreviewPostRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * VideosApi - factory interface
 * @export
 */
export const VideosApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = VideosApiFp(configuration);
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} videoFileId
     * @param {VideosVideoFileIdPreviewPostRequest} [videosVideoFileIdPreviewPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    videosVideoFileIdPreviewPost(
      videoFileId: number,
      videosVideoFileIdPreviewPostRequest?: VideosVideoFileIdPreviewPostRequest,
      options?: any,
    ): AxiosPromise<object> {
      return localVarFp
        .videosVideoFileIdPreviewPost(videoFileId, videosVideoFileIdPreviewPostRequest, options)
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * VideosApi - object-oriented interface
 * @export
 * @class VideosApi
 * @extends {BaseAPI}
 */
export class VideosApi extends BaseAPI {
  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {number} videoFileId
   * @param {VideosVideoFileIdPreviewPostRequest} [videosVideoFileIdPreviewPostRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VideosApi
   */
  public videosVideoFileIdPreviewPost(
    videoFileId: number,
    videosVideoFileIdPreviewPostRequest?: VideosVideoFileIdPreviewPostRequest,
    options?: AxiosRequestConfig,
  ) {
    return VideosApiFp(this.configuration)
      .videosVideoFileIdPreviewPost(videoFileId, videosVideoFileIdPreviewPostRequest, options)
      .then(request => request(this.axios, this.basePath));
  }
}
