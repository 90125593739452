import { useAuth } from '@sp/data/auth';
import { Button, Input } from '@sp/ui/elements';
import { FormEvent, useState } from 'react';

interface ILoginFormState {
  email: string;
  password: string;
}

export function LoginForm() {
  const { loginWithPassword, isLoginWithPasswordInProgress: isLoading } = useAuth();
  const [formState, setFormState] = useState<ILoginFormState>({
    email: '',
    password: '',
  });

  function setFormField(field: keyof ILoginFormState, value: string) {
    setFormState(state => ({ ...state, [field]: value }));
  }

  function submit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    loginWithPassword(formState);
  }

  return (
    <form onSubmit={submit}>
      <Input
        type="email"
        required
        value={formState.email}
        onChange={e => setFormField('email', e.target.value)}
        placeholder="Email"
        block
        className="!bg-primary !border !border-stroke !border-solid"
      />
      <Input
        type="password"
        required
        value={formState.password}
        onChange={e => setFormField('password', e.target.value)}
        placeholder="Password"
        block
        className="mt-3 !bg-primary !border !border-stroke !border-solid"
      />

      <Button type="submit" loading={isLoading} disabled={isLoading} color="black" block className="mt-3">
        Log in
      </Button>
    </form>
  );
}
