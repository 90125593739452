function getOutOfBoundsErrorMessage(index: number, keys: number[]): string {
  return `Index ${index} must be one of '${keys.join(', ')}'`;
}

export function moveArrayItem<T>(array: readonly T[], from: number, to: number): T[] {
  if (array.length === 0) throw new Error(`Can't move item in an empty array.`);

  const keys = Array.from(array.keys());
  if (!keys.includes(from)) throw new RangeError(getOutOfBoundsErrorMessage(from, keys));
  if (!keys.includes(to)) throw new RangeError(getOutOfBoundsErrorMessage(to, keys));

  const clone = array.slice();
  if (from === to) return clone;

  clone.splice(from, 1);
  clone.splice(to, 0, array[from]);

  return clone;
}
