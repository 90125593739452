import { ANALYTICS } from '@sp/feature/analytics';
import { DmLayout } from '@sp/feature/dm';
import { IconCaretLeft } from '@sp/ui/icons';
import { FullscreenModal } from '@sp/ui/modal';
import { isNotNullish, Nullable } from '@sp/util/helpers';
import { useGate, useStore } from 'effector-react';
import { FC, useEffect } from 'react';
import { ForeignProfileLayout } from '../foreign-profile-layout';
import { PROFILE_MODEL } from '../profile-model';
import { USER_PROFILE_CARD } from './user-profile-card-model';

const UserProfileContent: FC<{
  onDm: () => void;
  avatar?: Nullable<string>;
  name?: string;
  onClose: () => void;
  onForceClose: VoidFunction;
}> = ({ onClose, avatar, name, onDm, onForceClose }) => {
  return (
    <>
      <ForeignProfileLayout avatar={avatar} name={name} onDm={onDm} onForceClose={onForceClose} />
      <button
        type="button"
        onClick={e => {
          onClose();
          e.stopPropagation();
        }}
        className="absolute top-3 left-6 bg-blur-secondary text-primary rounded-lg p-1 flex "
      >
        <IconCaretLeft size={24} />
        Back
      </button>
    </>
  );
};

export const UserProfileCard: FC<{
  cardContext: 'space' | 'dm';
  onClose?: () => void;
}> = ({ onClose, cardContext }) => {
  const profileCardUser = useStore(USER_PROFILE_CARD.$activeUser);

  useGate(PROFILE_MODEL.ProfileGate, profileCardUser?.id ?? null);

  const profileUser = useStore(PROFILE_MODEL.$user);

  useEffect(() => {
    if (isNotNullish(profileUser)) {
      ANALYTICS.viewProfileFromChatTracked({
        'Chat profile View': 'true',
        'Profile ID': profileUser.id.toString(),
      });
    }
  }, [profileUser]);

  const isOpen = useStore(USER_PROFILE_CARD.$isOpen);

  const handleCloseProfileCard = () => {
    USER_PROFILE_CARD.resetUserCard();
    USER_PROFILE_CARD.close();
    PROFILE_MODEL.profileReset();
    onClose?.();
  };

  const contentType = useStore(USER_PROFILE_CARD.$type);

  const onDm = () => {
    // Тут, на самом деле, нужно проверять равен ли юзер в модале юзеру в открытом DM и если не равен, то открывать DM в модале
    // Но такая ситуация сейчас (никогда?) невозможна
    cardContext === 'space' ? USER_PROFILE_CARD.typeSet('dm') : handleCloseProfileCard();
  };

  const onDmBack = () => {
    USER_PROFILE_CARD.typeSet('card');
  };

  return (
    <FullscreenModal isOpen={isOpen} onClose={handleCloseProfileCard}>
      {contentType === 'dm' && isNotNullish(profileUser) ? (
        <DmLayout onBackClick={onDmBack} userId={profileUser.id} />
      ) : (
        <UserProfileContent
          avatar={profileCardUser?.avatar?.url}
          name={profileCardUser?.name}
          onDm={onDm}
          onClose={() => USER_PROFILE_CARD.close()}
          onForceClose={handleCloseProfileCard}
        />
      )}
    </FullscreenModal>
  );
};
