import { IconCaretLeft } from '@sp/ui/icons';
import { FC, MouseEventHandler } from 'react';
import { NAVIGATE_BACK_MODEL } from './navigate-back-model';

export const BackButton: FC<{
  onBackClick?: () => void;
}> = ({ onBackClick }) => {
  const handleClick: MouseEventHandler = e => {
    e.preventDefault();
    onBackClick?.();
    NAVIGATE_BACK_MODEL.navigatedBack();
  };
  return (
    <button type="button" title="Back" onClick={handleClick} className="text-secondary py-2 pl-3 pr-2">
      <IconCaretLeft size={24} />
    </button>
  );
};
