import { AUTH_MODEL } from '@sp/data/auth';
import { pushTokens } from '@sp/data/bif';
import { isNotNullish } from '@sp/util/helpers';
import {
  $isNativeIos,
  checkNotifications,
  requestNotifications,
  setNativeCallbackNotificationsDenied,
  setNativeCallbackRegisterNotificationToken,
} from '@sp/util/native';
import { attach, createEffect, createEvent, createStore, restore, sample } from 'effector';

const tokenGot = createEvent<string>();
const tokenDenied = createEvent<boolean>();
const requestNotificationDone = createEvent();
const permissionChecked = createEvent();

const $token = restore(tokenGot, null);

const $notificationPermission = createStore<'unknown' | 'denied' | 'granted'>('unknown');

const checkPermissionFx = createEffect(() => {
  return checkNotifications();
});

sample({
  source: AUTH_MODEL.$isReady,
  clock: permissionChecked,
  target: checkPermissionFx,
  filter: isAuth => isAuth,
});
sample({ source: checkPermissionFx.doneData, target: $notificationPermission });
sample({ clock: tokenDenied, target: $notificationPermission, fn: () => 'denied' as const });

const loadTokenFx = attach({
  source: $isNativeIos,
  mapParams: (params: string, states) => ({ token: params, isNativeIos: states }),
  effect: createEffect(({ token, isNativeIos }: { token: string; isNativeIos: boolean }) => {
    const service = isNativeIos ? 'apns' : 'fcm';
    return pushTokens(token, service);
  }),
});

const requestNotificationFx = createEffect(() => {
  registerNativeNotificationPermissionHandlers();
  requestNotifications();
});

sample({ source: $notificationPermission, target: requestNotificationDone, filter: n => n === 'granted' });

sample({ clock: requestNotificationDone, target: requestNotificationFx });

sample({ source: $token, target: loadTokenFx, filter: isNotNullish });

sample({ source: loadTokenFx.doneData, target: $notificationPermission, fn: () => 'granted' as const });

export const PERMISSION_MODEL = {
  tokenGot,
  requestNotificationDone,
  tokenDenied,
  permissionChecked,
  $notificationPermission,
  tokenLoading: loadTokenFx.pending,
};

export function registerNativeNotificationPermissionHandlers() {
  setNativeCallbackRegisterNotificationToken(tokenGot);
  setNativeCallbackNotificationsDenied(tokenDenied);
}
