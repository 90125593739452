import { createKeyValueStore } from '@sp/util/helpers';
import { ChatChannelMember } from '@sp/util/stream-chat';
import { ProfileCardUser } from './types';

export const USER_PROFILE_CARD_MEMBERS_MODEL = {
  creators: createKeyValueStore<ProfileCardUser[]>(),
  users: createKeyValueStore<ProfileCardUser[]>(),
};

export function mapStreamChatMembersToProfileCardUsers(members: Record<string, ChatChannelMember>): ProfileCardUser[] {
  const users: ProfileCardUser[] = [];

  for (const member of Object.values(members)) {
    const user = member.user;
    if (!user) continue;

    const id = parseInt(user.id);
    if (isNaN(id)) continue;

    users.push({
      id,
      name: user.name,
      avatar: { url: user.image },
    });
  }

  return users;
}
