import { cls } from '@sp/util/helpers';
import { ForwardedRef, forwardRef } from 'react';
import TextareaAutosize, { TextareaAutosizeProps } from 'react-textarea-autosize';

type TextAreaProps = TextareaAutosizeProps & {
  block?: boolean;
};

const style = {
  default: 'bg-stripe p-3 rounded-lg border-none placeholder:text-secondary',
  block: `w-full`,
  disabled: 'text-secondary cursor-not-allowed',
};

export const TextArea = forwardRef(function TextArea(
  { className, block = false, ...props }: TextAreaProps,
  ref: ForwardedRef<HTMLTextAreaElement>,
) {
  return (
    <TextareaAutosize
      style={{ WebkitUserSelect: 'text' }}
      ref={ref}
      {...props}
      className={cls(style.default, block ? style.block : '', props.disabled ? style.disabled : '', className)}
    />
  );
});
