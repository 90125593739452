import { AUTH_MODEL, useAuth } from '@sp/data/auth';
import { SpinnerPage } from '@sp/ui/elements';
import { isNotNullish } from '@sp/util/helpers';
import { ROUTES, useRedirectAfterLogin } from '@sp/util/router';
import { useStore } from 'effector-react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export function ContinueMagicLink() {
  const search = new URLSearchParams(window.location.search);
  const magicCode = search.get('magic-code');
  const redirectUriParam = search.get('redirect_uri');
  const isLoggedIn = useStore(AUTH_MODEL.$isReady);
  const { continueLoginWithMagicLink, magicLinkError } = useAuth();
  const { redirectUrl, setRedirect } = useRedirectAfterLogin();
  const navigate = useNavigate();

  useEffect(() => {
    if (redirectUriParam) {
      setRedirect(redirectUriParam);
    }
    if (isLoggedIn) {
      navigate(redirectUriParam || redirectUrl);
      setRedirect('/');
    }
  }, [isLoggedIn, navigate, redirectUriParam, redirectUrl, setRedirect]);

  useEffect(() => {
    if (isNotNullish(magicLinkError)) {
      navigate(ROUTES.LOGIN_PATH);
    }
  }, [magicLinkError, navigate]);

  useEffect(() => {
    if (magicCode) {
      window.history.replaceState({}, document.title, window.location.pathname);
      continueLoginWithMagicLink({ code: magicCode });
    }
  }, [continueLoginWithMagicLink, magicCode]);

  return (
    <div className="flex items-center justify-center w-full h-screen">
      <SpinnerPage />
    </div>
  );
}
