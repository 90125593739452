import { ATTACHMENT_TYPE, ChatMessage, ChatMessageAttachment, PostType } from '@sp/util/stream-chat';

export function getAttachmentLabel(attachment: ChatMessageAttachment): string {
  switch (attachment.type) {
    case ATTACHMENT_TYPE.file:
      return attachment.name;
    case ATTACHMENT_TYPE.image:
      return 'Photo';
    case ATTACHMENT_TYPE.video:
      return 'Video';
    case ATTACHMENT_TYPE.audio:
      return 'Audio File';
    case ATTACHMENT_TYPE.link:
      return attachment.name;
    default:
      return 'Attachment';
  }
}

export const EMPTY_MESSAGE_CONTENT = 'Empty message';
export const UNKNOWN_MESSAGE_AUTHOR = 'Unknown user';

export function getChallengeJourneyMessageLabel(meta: NonNullable<ChatMessage['selfplusPostMeta']>): string | null {
  switch (meta.type) {
    case PostType.step:
      return 'Next step started!';
    case PostType.finish:
      return 'Challenge finished!';
    case PostType.intro:
      return 'Welcome to the challenge!';
  }

  return null;
}
