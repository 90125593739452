type VolumeMiterOptions = {
  minLimit: number;
  maxLimit: number;
  fftSize: number;
  smoothing: number;
};

const defaultOptions: MediaRecorderOptions = {
  audioBitsPerSecond: 96000,
};

const defaultVolumeMiterOptions: VolumeMiterOptions = {
  minLimit: 0,
  maxLimit: 32,
  fftSize: 1024,
  smoothing: 0.1,
};

export function createAudioRecorder(options?: MediaRecorderOptions, volumeMiterOptions?: VolumeMiterOptions) {
  if (!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia)) {
    throw new Error('mediaDevices API or getUserMedia method is not supported in this browser.');
  }
  const resolvedOptions = { ...options, ...defaultOptions };
  const resolvedVolumeMiterOptions = { ...defaultVolumeMiterOptions, ...volumeMiterOptions };

  // Analyze the audio volume
  const audioContext = new AudioContext();
  const analyser = audioContext.createAnalyser();
  analyser.fftSize = resolvedVolumeMiterOptions.fftSize;
  analyser.smoothingTimeConstant = resolvedVolumeMiterOptions.smoothing;
  const cache = new Uint8Array(analyser.frequencyBinCount);
  let volumeUpdateCallback: (volume: number) => void;

  function processData() {
    if (volumeUpdateCallback) {
      analyser.getByteFrequencyData(cache);
      const volume = calcAvgVolume(cache);
      volumeUpdateCallback(alignVolume(volume));
    }
  }

  function alignVolume(volume: number): number {
    const opts = resolvedVolumeMiterOptions;
    if (volume < opts.minLimit) {
      volume = opts.minLimit;
    }
    if (volume > opts.maxLimit) {
      volume = opts.maxLimit;
    }
    return (volume - opts.minLimit) / (opts.maxLimit - opts.minLimit);
  }

  function calcAvgVolume(data: Uint8Array): number {
    let sum = 0;
    const len = data.length;
    for (let i = 0; i < len; i++) {
      sum += data[i];
    }
    return sum / len;
  }

  function dataListener(event: BlobEvent) {
    if (event.data.size > 0) {
      audioChunks.push(event.data);
      processData();
    }
  }

  //End of analyze the audio volume

  let recorder: MediaRecorder | undefined;
  let stream: MediaStream | undefined;
  let source: MediaStreamAudioSourceNode | undefined;
  const audioChunks: Blob[] = [];

  async function start(timeslice: number): Promise<void> {
    try {
      stream = await navigator.mediaDevices.getUserMedia({ audio: true });

      recorder = new MediaRecorder(stream, resolvedOptions);

      recorder.addEventListener('dataavailable', dataListener);

      source = audioContext.createMediaStreamSource(stream);
      source.connect(analyser);

      recorder.start(timeslice);
    } catch (error) {
      await Promise.reject(error);
    }
  }

  async function stop(): Promise<Blob> {
    return new Promise((resolve, reject) => {
      if (recorder && stream && source) {
        const mimeType = recorder.mimeType;

        recorder.addEventListener('stop', () => {
          const audioBlob = new Blob(audioChunks, { type: mimeType });
          resolve(audioBlob);
        });

        cancel();
      } else {
        reject('recorder was not initialized');
      }
    });
  }

  function cancel() {
    if (recorder && stream && source) {
      recorder.removeEventListener('dataavailable', dataListener);
      recorder.stop();
      stream.getTracks().forEach(track => track.stop());
      source.disconnect(analyser);
      recorder = undefined;
      stream = undefined;
      source = undefined;
    }
  }

  function onVolumeUpdate(callback: (volume: number) => void) {
    volumeUpdateCallback = callback;
  }

  return { start, stop, onVolumeUpdate, cancel };
}

export type AudioRecorder = ReturnType<typeof createAudioRecorder>;
