import { ChannelSpaceConfig, SpaceCreator, SpaceMember, UpdateFrequency } from '@sp/data/bif';
import { SELFPLUS_WEBSITE_URL } from '@sp/data/env';
import { SELFPLUS_ADMINS_MODEL } from '@sp/data/global';
import { USER_PROFILE_CARD_SHOW_MODEL } from '@sp/data/user-profile-card';
import { Member } from '@sp/feature/members';
import { Avatar } from '@sp/ui/elements';
import { IconExport, IconLinesCoinDollar, IconSmileyHappy, IconStarShadow } from '@sp/ui/icons';
import { Modal } from '@sp/ui/modal';
import { SpaceDescriptionAuthor, SpaceDescriptionMultiline } from '@sp/ui/spaces-shared';
import { TabPane, Tabs } from '@sp/ui/tabs';
import { GRAY_PIXEL_IMG_SRC } from '@sp/util/files';
import { Nullable } from '@sp/util/helpers';
import { shareUrl } from '@sp/util/native';
import { channelUpdateFrequencyFormat } from '@sp/util/spaces';
import { createEvent, createStore } from 'effector';
import { useStore } from 'effector-react';
import { FC, Fragment, useCallback, useState, VFC } from 'react';
import { CHANNEL_MODEL } from './channel-model';
import { ChannelSubscriptionDetailsModal } from './channel-subscription-details-modal';

export const openDescriptionModal = createEvent();
export const closeDescriptionModal = createEvent();
export const descriptionModalReset = createEvent();

const $isOpen = createStore<boolean>(false)
  .reset(descriptionModalReset)
  .on(openDescriptionModal, () => true)
  .on(closeDescriptionModal, () => false);

const ChannelDescription: FC<{ config?: ChannelSpaceConfig; name: string; avatar: string }> = ({
  config,
  name,
  avatar,
}) => {
  const {
    description = '',
    creatorDescription = '',
    targetAudience = '',
    contentFormat = '',
    updateFrequency = UpdateFrequency.occasionally,
  } = config?.channel || {};
  return (
    <div className="mt-6 mb-6">
      <h3>What is the Channel all about?</h3>
      <SpaceDescriptionMultiline text={description} />
      <SpaceDescriptionMultiline heading="Who’s it for?" text={targetAudience} />
      <SpaceDescriptionMultiline
        heading={
          <span>
            Check out my exclusive posts. I publish original content
            <span className="italic"> {channelUpdateFrequencyFormat[updateFrequency]} </span>
            🙌
          </span>
        }
        text={contentFormat}
      />
      <SpaceDescriptionAuthor heading="Your Expert" name={name} avatar={avatar} description={creatorDescription} />
    </div>
  );
};

const Header: FC<{
  name: string;
  title: string;
  urlToShare?: string;
  members?: number;
  avatar?: string;
}> = ({ title, members, avatar, urlToShare, name }) => {
  const handleShareClick = useCallback(() => {
    if (urlToShare) {
      shareUrl({ url: urlToShare, text: title, title });
    }
  }, [urlToShare, title]);

  return (
    // Псевдоэлемент нужен чтобы перекрыть артефакт отображения проскролленного контента под фиксированными табами
    // (на iOS просвечивает контент над табами)
    <div className="flex justify-start items-start px-6 gap-2 pb-2 w-full relative before:content[''] before:absolute before:w-full before:h-2 before:left-0 before:-bottom-1 before:bg-primary before:z-50">
      <span>
        <Avatar size="sm" src={avatar} />
      </span>
      <div className="flex flex-col flex-1 overflow-x-hidden">
        <h1 className="m-0 text-ellipsis whitespace-nowrap overflow-x-hidden text-base">{title}</h1>
        <span className="text-secondary text-xs">
          <span className="font-semibold">{name}</span>
          <span> · {members} participants</span>
        </span>
      </div>
      <button type="button" title="Back" onClick={handleShareClick} className="text-secondary p-2">
        <IconExport size={24} />
      </button>
    </div>
  );
};

const Members: FC<{
  members: SpaceMember[];
  creator?: Nullable<SpaceCreator>;
}> = ({ members, creator }) => {
  const selfplusAdmins = useStore(SELFPLUS_ADMINS_MODEL.$data);
  const checkBadge = useCallback(
    (userId: number) => {
      if (!creator) return false;
      return !!(userId === creator.id || selfplusAdmins?.find(admin => userId === admin.id));
    },
    [creator, selfplusAdmins],
  );
  const handleClick = (user: { id: number; avatar: { url: string | null } | null }) => {
    USER_PROFILE_CARD_SHOW_MODEL.show({ userId: user.id, avatar: user.avatar || undefined });
  };

  return (
    <div className="py-4">
      {creator && (
        <Member
          withBadge={checkBadge(creator.id)}
          onClick={() => handleClick(creator)}
          key={creator.id}
          userName={creator.name}
          url={creator.avatar?.url}
        />
      )}
      {members.map((member, i, array) => (
        <Fragment key={member.id}>
          {/* the array must be sorted "buyers – first, trial participants – last"  */}
          {member.isTrial && i > 0 && !array[i - 1].isTrial && <hr className="my-4" />}
          <Member
            withBadge={checkBadge(member.id)}
            onClick={() => handleClick(member)}
            userName={member.name || 'Newcomer'}
            url={member.avatar?.url}
          />
        </Fragment>
      ))}
    </div>
  );
};

const FreeChannelDescription: FC<{
  config?: ChannelSpaceConfig;
  name: string;
  avatar: string;
  onSubscribe: VoidFunction;
}> = ({ config, name, avatar, onSubscribe }) => {
  const isOwner = useStore(CHANNEL_MODEL.$isOwner);
  return (
    <div>
      <ChannelDescription name={name} avatar={avatar} config={config} />
      {!isOwner && (
        <button type="button" onClick={onSubscribe} className="text-brand">
          Subscribe
        </button>
      )}
    </div>
  );
};

const PaidChannelDescription: FC<{ config?: ChannelSpaceConfig; name: string; avatar: string }> = ({
  config,
  name,
  avatar,
}) => {
  const [isOpenManageSubscription, setIsOpenManageSubscription] = useState(false);

  return (
    <div className="py-4">
      <h2 className="text-center text-2xl leading-7 m-0">You are the Premium Follower!</h2>
      <p className="text-center text-secondary mt-2">
        Thank you for subscribing to this channel.
        <br /> Here’s what is now unlocked
      </p>

      <div className="flex flex-col divide-y divide-stroke mb-4">
        <div className="flex gap-3 py-4">
          <IconStarShadow size={40} className="text-brand" />
          <div>
            <span className="font-medium">All premium messages from creator</span>
            <p className="m-0 text-secondary">Watch, listen and read the most interesting and useful information</p>
          </div>
        </div>
        <div className="flex gap-3 py-4">
          <IconSmileyHappy size={40} className="text-brand" />
          <div>
            <span className="font-medium">Reactions on premium messages</span>
            <p className="m-0 text-secondary">
              Tell about emotions and make desicions in a circle of like-minded people
            </p>
          </div>
        </div>
        <div className="flex gap-3 py-4">
          <IconLinesCoinDollar size={40} className="text-brand" />
          <div>
            <span className="font-medium">10% discount on other creator’s events</span>
            <p className="m-0 text-secondary">Use the possibilities of a closed club and get more skills</p>
          </div>
        </div>
      </div>

      <ChannelDescription name={name} avatar={avatar} config={config} />

      <p className="text-secondary font-medium mb-2">About Premium Subscription</p>

      <div className="p-4 rounded-2.5xl bg-light">
        <p className="mt-0 mb-2">You get access to all premium content during the subscription period</p>

        <button type="button" onClick={() => setIsOpenManageSubscription(true)} className="text-brand">
          Manage Subscription
        </button>
      </div>

      <ChannelSubscriptionDetailsModal
        isOpen={isOpenManageSubscription}
        onClose={() => setIsOpenManageSubscription(false)}
      />
    </div>
  );
};

export const ChannelDescriptionModal: VFC<Readonly<{ onSubscribe: VoidFunction }>> = ({ onSubscribe }) => {
  const isOpen = useStore($isOpen);
  const channel = useStore(CHANNEL_MODEL.$channel);
  const [tabKey, setTabKey] = useState('members');
  const title = channel?.title ?? '';
  const creator = channel?.creator;
  const members = channel?.members ?? [];
  const avatar = channel?.cover?.url ?? GRAY_PIXEL_IMG_SRC;
  const urlToShare = `${SELFPLUS_WEBSITE_URL}/channel/?id=${channel?.id}`;
  const isOwner = useStore(CHANNEL_MODEL.$isOwner);

  return (
    <Modal
      header={
        <Header
          title={title}
          members={members.length}
          avatar={avatar}
          urlToShare={urlToShare}
          name={creator?.name ?? ''}
        />
      }
      closeOnBackdropClick
      onClose={closeDescriptionModal}
      withBackdrop
      isOpen={isOpen}
    >
      <div className="min-h-[70vh] -mx-6 relative">
        <Tabs onChange={setTabKey} activeKey={tabKey} activeTabTextColor="text-primary" sticky maxTabWidth="33%">
          <TabPane title="Members" key="members">
            <div className="px-6">
              <Members members={members} creator={creator} />
            </div>
          </TabPane>
          <TabPane title="Description" key="description">
            <div className="px-6">
              {!channel?.isPurchased || isOwner ? (
                <FreeChannelDescription
                  avatar={avatar}
                  name={creator?.name ?? ''}
                  config={channel?.config}
                  onSubscribe={onSubscribe}
                />
              ) : (
                <PaidChannelDescription avatar={avatar} name={creator?.name ?? ''} config={channel?.config} />
              )}
            </div>
          </TabPane>
        </Tabs>
      </div>
    </Modal>
  );
};
