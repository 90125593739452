import { useSetPreviousUrl } from '@sp/feature/back-button';
import { ROUTES } from '@sp/util/router';
import { VFC } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { LivePage } from './live-page';

export const LiveScreen: VFC<{ tabKey: string }> = ({ tabKey }) => {
  useSetPreviousUrl(ROUTES.HOME_URL);

  const params = useParams<typeof ROUTES.SPACE_ID_PARAM>();
  const spaceId = Number(params.spaceId);

  if (isNaN(spaceId)) {
    console.error(`Invalid trailer space id: ${params.spaceId}`);
    return <Navigate to={ROUTES.HOME_URL} />;
  }

  return <LivePage liveId={spaceId} tabKey={tabKey} />;
};
