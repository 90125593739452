/***
 * Format estimeted time in minutes to a string
 * @param time - minutes
 */
export function formatEstimateTime(time: number): string {
  const hours = Math.floor(time / 60);
  const minutes = time % 60;
  const hoursStr = hours > 0 ? `${hours} h ` : '';
  const minutesStr = minutes > 0 ? `${minutes} min` : '';
  return `${hoursStr}${minutesStr}`;
}
