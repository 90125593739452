import { IconLockedKey } from '@sp/ui/icons';
import { cls } from '@sp/util/helpers';
import { FC } from 'react';

export const StepTrialFormView: FC<{
  stepTitle: string;
  isActive?: boolean;
  isProgram?: boolean;
}> = ({ stepTitle, isActive = false, isProgram = false }) => {
  return (
    <div className={cls(isActive && 'text-active')}>
      <div className="font-semibold mb-2">{stepTitle}</div>

      <div className={cls(isActive ? 'text-active' : 'text-secondary', 'text-xs flex items-center mb-2 gap-1')}>
        <IconLockedKey size={13} className="inline" />
        <span> {`Join the ${isProgram ? 'program ' : 'challenge '}`}to unlock</span>
      </div>
    </div>
  );
};
