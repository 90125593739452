import { ProsemirrorNode, RemirrorJSON } from '@remirror/core';
import { MentionAtomNodeAttributes } from '@remirror/react';
import { MentionAtomExtension } from 'remirror/extensions';
import { MENTION_TYPE_DATA_ATTR_KEY, MentionType } from './extensions';
import { editor, manager } from './remirror-instance';

interface UserMention {
  readonly id: string;
  readonly name: string;
}

const mentionAtomNodeType = editor.getExtension(MentionAtomExtension).name;

type MentionAtomNode = ProsemirrorNode & { readonly attrs: MentionAtomNodeAttributes };

function isMentionAtomNode(node: ProsemirrorNode): node is MentionAtomNode {
  return node.type.name === mentionAtomNodeType;
}

function isUserMentionAtomNode(node: ProsemirrorNode): node is MentionAtomNode {
  return isMentionAtomNode(node) && node.attrs[MENTION_TYPE_DATA_ATTR_KEY] === MentionType.User;
}

export function parseUserMentionsFromDoc(serializedDoc: RemirrorJSON): UserMention[] {
  const doc = manager.createState({ content: serializedDoc }).doc;

  const userMentionAtomNodes: MentionAtomNode[] = [];

  doc.descendants(node => {
    if (isUserMentionAtomNode(node)) {
      userMentionAtomNodes.push(node);
    }
  });

  return userMentionAtomNodes.map(({ attrs, text }) => ({
    id: attrs.id,
    name: text ?? '',
  }));
}
