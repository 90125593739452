import { useUser } from '@sp/data/auth';
import { ChallengePost, ChallengePublishedPost, SpaceCreator } from '@sp/data/bif';
import {
  ChatMessageAvatar,
  ChatMessageBody,
  ChatMessageBottomAttachments,
  ChatMessageHeader,
  ChatMessageProvider,
  ChatMessageReactions,
  ChatMessageReply,
  ChatMessageTopAttachments,
  mockChatModel,
} from '@sp/feature/chat';
import { CHAT_CHANNEL } from '@sp/feature/chat-channel';
import { ViewInChatButton } from '@sp/feature/space';
import { ChatChannelModel } from '@sp/feature/stream-chat';
import {
  ChatMessageBodyContainer,
  ChatMessageContainer,
  ChatMessageFooterContainer,
  ChatMessageLeftContainer,
  ChatMessageRightContainer,
} from '@sp/ui/chat';
import { cls, isNotNullish, Nullable } from '@sp/util/helpers';
import { ChatNonFormatMessage, formatChatMessage } from '@sp/util/stream-chat';
import { formatISO } from 'date-fns';
import { useStore, useStoreMap } from 'effector-react';
import { FC, useMemo } from 'react';
import { CHALLENGE_MODEL } from '../../challenge-model';

export function createMockMessage(
  content: ChallengePost['content'],
  creator: Nullable<SpaceCreator>,
  created_at: string,
): ChatNonFormatMessage {
  return {
    ...content,
    user: {
      id: creator?.id.toString() ?? '',
      name: creator?.name ?? '',
      image: creator?.avatar?.url,
    },
    created_at,
  } as ChatNonFormatMessage;
}

function isPublishedPost(post: ChallengePost): post is ChallengePublishedPost {
  return isNotNullish((post as ChallengePublishedPost).gsMessage);
}

const ScheduledPost: FC<{ content: ChallengePost['content']; createdAt: string; hideContent: boolean }> = ({
  content,
  createdAt,
  hideContent,
}) => {
  const challenge = useStore(CHALLENGE_MODEL.$challenge);
  const mockMessage = createMockMessage(content, challenge?.creator, createdAt);
  const message = useMemo(() => formatChatMessage(mockMessage), [mockMessage]);
  const contextValue = useMemo(() => ({ message, model: mockChatModel() }), [message]);
  return (
    <div>
      <ChatMessageProvider value={contextValue}>
        <ChatMessageContainer>
          <ChatMessageLeftContainer>
            <ChatMessageAvatar />
          </ChatMessageLeftContainer>

          <ChatMessageRightContainer>
            <ChatMessageHeader />

            <ChatMessageReply />

            <ChatMessageTopAttachments locked={hideContent} />

            <div
              className={cls(
                hideContent &&
                  'relative max-h-[3.75rem] overflow-hidden pointer-events-none after:block after:absolute after:top-0 after:left-0 after:right-0 after:bottom-0 after:bg-gradient-to-b after:from-white/0 after:via-white/80 after:to-white',
              )}
            >
              <ChatMessageBodyContainer>
                <ChatMessageBody />
              </ChatMessageBodyContainer>
            </div>

            {!hideContent && <ChatMessageBottomAttachments />}
          </ChatMessageRightContainer>
        </ChatMessageContainer>
      </ChatMessageProvider>
    </div>
  );
};

const PublishedPost: FC<{ gsMessage: ChatNonFormatMessage; model: ChatChannelModel }> = ({ gsMessage, model }) => {
  const message = useMemo(() => formatChatMessage(gsMessage), [gsMessage]);
  const contextValue = useMemo(() => ({ message, model }), [message, model]);

  return (
    <div>
      <ChatMessageProvider value={contextValue}>
        <ChatMessageContainer>
          <ChatMessageLeftContainer>
            <ChatMessageAvatar />
          </ChatMessageLeftContainer>

          <ChatMessageRightContainer>
            <ChatMessageHeader />

            <ChatMessageBodyContainer>
              <ChatMessageReply />

              <ChatMessageTopAttachments />

              <ChatMessageBody />
            </ChatMessageBodyContainer>

            <ChatMessageBottomAttachments />

            <ChatMessageFooterContainer>
              <ChatMessageReactions />
            </ChatMessageFooterContainer>

            <ViewInChatButton model={model} messageId={message.id} />
          </ChatMessageRightContainer>
        </ChatMessageContainer>
      </ChatMessageProvider>
    </div>
  );
};

export const PostsView: FC<{
  posts: readonly ChallengePost[];
  isProgramPost?: boolean;
  startAt: number;
}> = ({ posts, startAt, isProgramPost = false }) => {
  const chatChannelModel = useStore(CHAT_CHANNEL.$instance);
  const user = useUser();
  const creatorId = useStoreMap(CHALLENGE_MODEL.$challenge, challenge => challenge.creator?.id);
  const isFullAccess = creatorId === user.id || user.isAdmin || isProgramPost;

  if (posts.length === 0) return null;

  const firstPost = posts[0];

  if (isFullAccess || isPublishedPost(firstPost)) {
    return (
      <div className="flex flex-col gap-4 py-4 mb-2">
        {posts.map(post =>
          isPublishedPost(post) && chatChannelModel ? (
            <PublishedPost key={post.id} gsMessage={post.gsMessage} model={chatChannelModel} />
          ) : (
            <ScheduledPost key={post.id} content={post.content} createdAt={formatISO(startAt)} hideContent={false} />
          ),
        )}
      </div>
    );
  } else {
    return (
      <ScheduledPost key={firstPost.id} content={firstPost.content} createdAt={formatISO(startAt)} hideContent={true} />
    );
  }
};
