import { ChatMessageAttachmentsView } from '@sp/ui/chat';
import { groupAttachments, GroupedAttachments } from '@sp/util/chat-attachments';
import { isNotNullish } from '@sp/util/helpers';
import { FC, useMemo } from 'react';
import { useChatMessage } from './chat-message-context';

export const ChatMessageAttachments: FC<{ types?: Array<keyof GroupedAttachments>; locked?: boolean }> = ({
  types = ['media', 'audio', 'files', 'links'],
  locked = false,
}) => {
  const { message } = useChatMessage();

  const attachments = useMemo(
    () => message.attachments?.map(a => a.selfplusAttachment).filter(isNotNullish) ?? [],
    [message.attachments],
  );

  const groupedAttachments = useMemo(() => groupAttachments(attachments, types), [attachments, types]);

  if (attachments.length === 0) {
    return null;
  }

  return (
    <ChatMessageAttachmentsView {...groupedAttachments} authorName={message.user?.name ?? 'Unknown'} locked={locked} />
  );
};

export const ChatMessageTopAttachments: FC<{ locked?: boolean }> = ({ locked = false }) => {
  return <ChatMessageAttachments types={['media', 'audio']} locked={locked} />;
};

export const ChatMessageBottomAttachments: FC<{ locked?: boolean }> = ({ locked = false }) => {
  return <ChatMessageAttachments types={['files', 'links']} locked={locked} />;
};
