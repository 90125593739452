import { IconCircleWavyCheck } from '@sp/ui/icons';
import { VFC } from 'react';

export const OwnedVerifiedBadge: VFC = () => {
  return (
    <div>
      {/*TODO: заменить на токены фона*/}
      <div
        className="flex items-start text-left rounded-2.5xl w-full p-4 pr-3 bg-[#E8F6F0] bg-no-repeat bg-right-bottom"
        style={{ backgroundImage: 'url("/images/creator-banner-big-bg-lines.svg")' }}
      >
        <img src="/images/creator-badge-big.svg" alt="creator badge" className="w-24 mr-4" />
        <div className="pt-2">
          <div className="flex items-center mb-1 ">
            <span className="text-base font-semibold">Verified Self+ Creator</span>
            <span className="pl-1">
              <IconCircleWavyCheck size={16} className="text-brand" />
            </span>
          </div>
          <div className="text-xs mb-3">
            Trusted creator handpicked by Self+
            <br />
            Top-tier content
          </div>
        </div>
      </div>
    </div>
  );
};

export const VerifiedBadge: VFC = () => {
  return (
    <div>
      {/*TODO: заменить на токены фона*/}
      <div
        className="flex relative text-left rounded-2.5xl w-full p-4 pr-3 bg-[#E8F6F0] bg-no-repeat bg-right-top"
        style={{ backgroundImage: 'url("/images/creator-banner-small-bg-lines.svg")' }}
      >
        <img src="/images/creator-badge-big.svg" alt="creator badge" className="w-24 mr-4" />
        <div className="pt-2">
          <div className="flex items-center mb-1 ">
            <span className="text-base font-semibold">Verified Self+ Creator</span>
            <span className="pl-1">
              <IconCircleWavyCheck size={16} className="text-brand" />
            </span>
          </div>
          <div className="text-xs">
            {/* TODO: Тут должен быть другой текст: какой именно пока непонятно */}
            Trusted creator handpicked by Self+
            <br />
            Top-tier content
          </div>
          {/* <IconArrow size={24} className="text-brand absolute right-4 bottom-4" /> */}
        </div>
      </div>
    </div>
  );
};
