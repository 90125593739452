import { USE_LONG_PRESS_OPTIONS } from '@sp/util/config';
import { assert, isNotNullish, useRefWithCallback } from '@sp/util/helpers';
import {
  cloneElement,
  ComponentType,
  ReactElement,
  MouseEvent as ReactMouseEvent,
  useCallback,
  useRef,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import { useLongPress } from 'use-long-press';
import { calcMenuPosition, MenuPosition } from './menu-position';

const rootNode = document.getElementById('root');

export interface ContextMenuProps {
  closeMenu(): void;
}

export interface ContextMenuChildProps {
  readonly isContextMenuOpen?: boolean;
}

export const WithContextMenu = ({
  view,
  children,
  withBackdrop = true,
  showEvent = 'contextmenu',
}: Readonly<{
  view: ComponentType<ContextMenuProps>;
  children: ReactElement<Readonly<{ isContextMenuOpen?: boolean }>>;
  withBackdrop?: boolean;
  showEvent?: keyof Pick<HTMLElementEventMap, 'contextmenu' | 'click'>;
}>): ReactElement => {
  assert(isNotNullish(rootNode));

  const [position, setPosition] = useState<MenuPosition | null>(null);
  const isOpen = position !== null;
  const closeMenu = useCallback(() => setPosition(null), []);
  const Menu = view;

  const bind = useLongPress(event => {
    if (event.type === 'touchstart') {
      const { clientX, clientY } = (event.nativeEvent as TouchEvent).touches[0];
      setPosition(calcMenuPosition({ clientX, clientY }));
    }
  }, USE_LONG_PRESS_OPTIONS);

  const containerListener = useCallback((event: ReactMouseEvent) => {
    event.preventDefault();
    setPosition(calcMenuPosition(event));
  }, []);

  const containerRef = useRef<HTMLDivElement>(null);

  const backdropListener = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();
      event.stopPropagation();
      closeMenu();
    },
    [closeMenu],
  );

  const [setBackdropRef] = useRefWithCallback<HTMLDivElement>(
    backdrop => backdrop.addEventListener('click', backdropListener),
    backdrop => backdrop.removeEventListener('click', backdropListener),
  );

  const eventProp =
    showEvent === 'click' ? { onClick: containerListener } : { onContextMenu: containerListener, ...bind() };

  return (
    <>
      {isOpen &&
        createPortal(
          <div className="relative z-50">
            {withBackdrop && <div ref={setBackdropRef} className="fixed w-full h-full inset-0 bg-transparent" />}
            <div className="fixed z-10" style={position}>
              <Menu closeMenu={closeMenu} />
            </div>
          </div>,
          rootNode,
        )}

      <div {...eventProp} ref={containerRef} className="select-none">
        {cloneElement(children, { isContextMenuOpen: isOpen })}
      </div>
    </>
  );
};
