import {
  CHALLENGE_POST_TYPE,
  ChallengePostsPatchDto,
  ChallengeStepsPatchDto,
  CreateChallengePostDto,
  CreateChallengeStepDto,
  OwnedChallengeSpace,
  OwnedChallengeSpacePost,
  OwnedChallengeSpaceStep,
  OwnedSpace,
  patchChallengePosts,
  patchChallengeSteps,
  PatchSpacePayload,
  SPACE_TYPE,
  UpdateChallengePostDto,
  UpdateChallengeSpaceDto,
  UpdateChallengeStepDto,
} from '@sp/data/bif';
import { isNotNullish, isNullish } from '@sp/util/helpers';
import { add, format, parse, startOfDay, startOfToday } from 'date-fns';
import isEqual from 'lodash/isEqual';
import {
  CHANGEABLE_CHALLENGE_FIELDS,
  CHANGEABLE_POSTS_FIELDS,
  CHANGEABLE_STEP_FIELDS,
  DeferredPost,
  Post,
  PostMapKeys,
  SaveChallengeDto,
  Seed,
  Step,
  StepPost,
} from './types';

export function getNewChallengeStartAt(): number {
  return add(startOfToday(), { days: 1, hours: 9 }).getTime();
}

export function isChallenge(space: OwnedSpace): space is OwnedChallengeSpace {
  return space.type === SPACE_TYPE.challenge;
}

export function isCreateStepDto(
  step: OwnedChallengeSpaceStep | CreateChallengeStepDto,
): step is CreateChallengeStepDto {
  return isNullish((step as OwnedChallengeSpaceStep).id);
}

export function isExistingStep(
  step: OwnedChallengeSpaceStep | CreateChallengeStepDto,
): step is OwnedChallengeSpaceStep {
  return !isCreateStepDto(step);
}

export function isCreatePostDto(
  post: OwnedChallengeSpacePost | CreateChallengePostDto,
): post is CreateChallengePostDto {
  return isNullish((post as OwnedChallengeSpacePost).id);
}

export function isExistingPost(
  post: OwnedChallengeSpacePost | CreateChallengePostDto,
): post is OwnedChallengeSpacePost {
  return !isCreatePostDto(post);
}

function getChangedFields<T extends object, S extends T, F extends keyof T>(prev: S, next: T, fields: F[]): F[] {
  return fields.filter(field => !isEqual(prev[field], next[field]));
}

// Challenge helpers
export function updateChallengeReducer(
  state: OwnedChallengeSpace | null,
  { challenge }: SaveChallengeDto,
): PatchSpacePayload<UpdateChallengeSpaceDto> {
  if (isNullish(state)) {
    throw new Error('Challenge is not loaded');
  }
  const patch: UpdateChallengeSpaceDto = getChangedFields(state, challenge, CHANGEABLE_CHALLENGE_FIELDS).reduce(
    (patch, field) => {
      return { ...patch, [field]: challenge[field] };
    },
    {},
  );

  if (isNotNullish(challenge.cover) && challenge.cover.id !== state.cover?.id) {
    patch.coverFileId = challenge.cover.id;
  }

  return {
    spaceId: state.id,
    patch,
  };
}

// END Challenge helpers

// Steps helpers
export function isStepsChanged(
  prev: ReadonlyArray<OwnedChallengeSpaceStep>,
  next: (OwnedChallengeSpaceStep | CreateChallengeStepDto)[],
): boolean {
  const hasNewSteps = next.some(isCreateStepDto);
  if (hasNewSteps) {
    return true;
  }

  const nextStepIds = next.filter(isExistingStep).map(({ id }) => id);
  const hasRemovedSteps = prev.some(step => !nextStepIds.includes(step.id));
  if (hasRemovedSteps) {
    return true;
  }

  const hasChangedSteps = next.some(nextStep => {
    const prevStep = prev.find(({ id }) => isExistingStep(nextStep) && nextStep.id === id);
    return (
      isNotNullish(prevStep) &&
      isExistingStep(nextStep) &&
      getChangedFields(prevStep, nextStep, CHANGEABLE_STEP_FIELDS).length > 0
    );
  });
  return hasChangedSteps;
}

export function updateStepsReducer(
  state: ReadonlyArray<OwnedChallengeSpaceStep>,
  { challenge, steps: nextSteps }: SaveChallengeDto,
): Parameters<typeof patchChallengeSteps>[0] {
  const patch: ChallengeStepsPatchDto = {
    created: [],
    updated: [],
    deleted: [],
  };

  const nextPostIds = nextSteps.filter(isExistingStep).map(({ id }) => id);
  state.forEach(prevStep => {
    if (!nextPostIds.includes(prevStep.id)) {
      patch.deleted.push(prevStep.id);
    }
  });

  nextSteps.forEach(nextStep => {
    if (isCreateStepDto(nextStep)) {
      patch.created.push(nextStep);
    }
    const prevStep = state.find(({ id }) => isExistingStep(nextStep) && nextStep.id === id);

    if (
      isNotNullish(prevStep) &&
      isExistingStep(nextStep) &&
      getChangedFields(prevStep, nextStep, CHANGEABLE_STEP_FIELDS).length > 0
    ) {
      patch.updated.push(nextStep);
    }
  });

  return {
    challengeId: challenge.id,
    patch,
  };
}

// END Steps helpers

// Posts helpers
export function isPostsChanged(
  prev: ReadonlyArray<OwnedChallengeSpacePost>,
  next: (OwnedChallengeSpacePost | CreateChallengePostDto)[],
): boolean {
  const hasNewPosts = next.some(isCreatePostDto);
  if (hasNewPosts) {
    return true;
  }

  const nextPostIds = next.filter(isExistingPost).map(({ id }) => id);
  const hasRemovedPosts = prev.some(post => !nextPostIds.includes(post.id));
  if (hasRemovedPosts) {
    return true;
  }

  const hasChangedPosts = next.some(nextPost => {
    const prevPost = prev.find(({ id }) => isExistingPost(nextPost) && nextPost.id === id);
    return (
      isNotNullish(prevPost) &&
      isExistingPost(nextPost) &&
      getChangedFields(prevPost, nextPost, CHANGEABLE_POSTS_FIELDS).length > 0
    );
  });
  return hasChangedPosts;
}

export function updatePostsReducer(
  state: ReadonlyArray<OwnedChallengeSpacePost>,
  { challenge, posts: nextPosts }: SaveChallengeDto,
): Parameters<typeof patchChallengePosts>[0] {
  const patch: ChallengePostsPatchDto = {
    created: [],
    updated: [],
    deleted: [],
  };

  const nextPostIds = nextPosts.filter(isExistingPost).map(({ id }) => id);
  state.forEach(prevPost => {
    if (!nextPostIds.includes(prevPost.id)) {
      patch.deleted.push(prevPost.id);
    }
  });

  nextPosts.forEach(nextPost => {
    if (isCreatePostDto(nextPost)) {
      patch.created.push(nextPost);
    }
    const prevPost = state.find(({ id }) => isExistingPost(nextPost) && nextPost.id === id);

    if (
      isNotNullish(prevPost) &&
      isExistingPost(nextPost) &&
      getChangedFields(prevPost, nextPost, CHANGEABLE_POSTS_FIELDS).length > 0
    ) {
      patch.updated.push(nextPost);
    }
  });

  return {
    challengeId: challenge.id,
    patch,
  };
}

// END Posts helpers

export const formatDateForInput = (date: Date | number) => format(date, `yyyy-MM-dd`);
export const formatDateTimeForInput = (date: Date | number) => format(date, `yyyy-MM-dd'T'HH:mm`);
export const formatTimeForInput = (date: Date | number) => format(date, `HH:mm`);

const PARSE_ERROR_RESULT = 'Invalid Date';
export const parseDateInputValue = (value: string) => {
  const date = parse(value, `yyyy-MM-dd`, new Date());
  return date.toDateString() === PARSE_ERROR_RESULT ? null : date;
};
export const parseDateTimeInputValue = (value: string) => {
  const date = parse(value, `yyyy-MM-dd'T'HH:mm`, new Date());
  return date.toDateString() === PARSE_ERROR_RESULT ? null : date;
};

export function isNotSeed<T extends { id: number }>(seed: T | Seed<T>): seed is T {
  return isNotNullish((seed as T).id);
}

export function stepToUpdateStepDto(
  step: Step | Seed<Step>,
  dayTimestamp: number,
): UpdateChallengeStepDto | CreateChallengeStepDto {
  const result: CreateChallengeStepDto = {
    title: step.title,
    isSignificant: step.isSignificant,
    isEnabled: true,
    estimatedEffort: step.estimatedEffort,
    startAt: add(startOfDay(dayTimestamp), step.startTime).getTime(),
  };
  if (isNotSeed(step)) {
    return { ...result, id: step.id };
  }
  return result;
}

export function isDeferredPost(post: Post | Seed<Post>): post is DeferredPost {
  return post.type === CHALLENGE_POST_TYPE.deferred;
}

export function isStepPost(post: Post | Seed<Post>): post is StepPost {
  return post.type === CHALLENGE_POST_TYPE.step;
}

export function postToUpdatePostDto(
  post: Post | Seed<Post>,
  key: PostMapKeys,
  order: number,
  stepPublishAt?: number,
): UpdateChallengePostDto | CreateChallengePostDto {
  let publishAt = stepPublishAt || null;
  if (isDeferredPost(post)) {
    publishAt = post.publishAt;
  }

  let stepId = null;
  if (isStepPost(post) && !isNaN(Number(key))) {
    stepId = Number(key);
  }

  const result: CreateChallengePostDto = {
    type: post.type,
    content: post.content,
    isDraft: false,
    stepId,
    order,
    publishAt,
  };
  if (isNotSeed(post)) {
    return { ...result, id: post.id };
  }
  return result;
}

export function fieldReducer<T>(_: T, next: T) {
  return next;
}

export function getStepSeed(): Seed<Step> {
  return {
    title: 'Next day',
    isSignificant: true,
    startTime: { hours: 9, minutes: 0 },
    estimatedEffort: 0,
  };
}

export function getPostKey(post: OwnedChallengeSpacePost): PostMapKeys {
  switch (post.type) {
    case CHALLENGE_POST_TYPE.step:
      return Number(post.stepId);
    case CHALLENGE_POST_TYPE.deferred:
      return 'deferred';
    case CHALLENGE_POST_TYPE.intro:
      return 'intro';
    case CHALLENGE_POST_TYPE.finish:
      return 'finish';
  }
}

export function getPostType(key: PostMapKeys): CHALLENGE_POST_TYPE {
  if (key === 'deferred') {
    return CHALLENGE_POST_TYPE.deferred;
  }
  if (key === 'intro') {
    return CHALLENGE_POST_TYPE.intro;
  }
  if (key === 'finish') {
    return CHALLENGE_POST_TYPE.finish;
  }
  return CHALLENGE_POST_TYPE.step;
}
