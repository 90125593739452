import { ChatChannelModel } from '@sp/feature/stream-chat';
import { IconArrowBendUpLeft } from '@sp/ui/icons';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

export const ViewInChatButton: FC<{ model: ChatChannelModel; messageId: string }> = ({ model, messageId }) => {
  const navigate = useNavigate();

  const onViewClick = () => {
    model.jumpedToMessage({ messageId: messageId });
    navigate('../chat', { replace: true });
  };
  return (
    <div className="flex justify-end pr-2">
      <button className="flex gap-2 text-brand items-center" type="button" onClick={onViewClick}>
        <IconArrowBendUpLeft size={18} />
        <span className="font-semibold">View in chat</span>
      </button>
    </div>
  );
};
