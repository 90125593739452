import { cls } from '@sp/util/helpers';
import { ButtonHTMLAttributes, ForwardedRef, forwardRef, ReactElement, ReactNode } from 'react';
import { CgSpinner } from 'react-icons/all';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children?: ReactNode;
  className?: string;
  disabled?: boolean;
  loading?: boolean;
  type?: 'submit' | 'reset' | 'button';
  size?: 'xs' | 'sm' | 'md' | 'lg';
  color?:
    | 'primary'
    | 'brand'
    | 'warning'
    | 'danger'
    | 'gray'
    | 'transparent'
    | 'black'
    | 'google'
    | 'facebook'
    | 'apple';
  block?: boolean;
  outline?: boolean;
  rounded?: boolean;
  circle?: boolean;
  disableOnLoad?: boolean;
  replaceWithSpinner?: boolean;
  propagation?: boolean;
}

const style = {
  rounded: `!rounded-full`,
  circle: `!rounded-full`,
  block: `flex justify-center w-full`,
  default: `inline-flex items-center justify-center rounded-lg text-active focus:outline-none transition ease-in duration-200 font-medium`,
  disabled: `opacity-60 cursor-not-allowed`,
  sizes: {
    xs: {
      button: {
        base: 'text-sm',
        rect: 'px-2 py-1',
        circle: 'h-8 w-8',
      },
      loading: 'px-3.5',
      spinner: {
        base: 'h-4 w-4',
        rect: '-ml-0.5 mr-1.5',
      },
    },
    sm: {
      button: {
        base: 'text-sm',
        rect: 'px-2 py-1',
        circle: 'h-10 w-10',
      },
      loading: 'px-3.5',
      spinner: {
        base: 'h-4 w-4',
        rect: '-ml-0.5 mr-1.5',
      },
    },
    md: {
      button: {
        base: '',
        rect: 'px-6 py-2.5',
        circle: 'h-12 w-12',
      },
      loading: 'px-3',
      spinner: {
        base: 'h-5 w-5',
        rect: '-m-0.5 mr-1.5',
      },
    },
    lg: {
      button: {
        base: 'text-lg',
        rect: 'px-6 py-3',
        circle: 'h-14 w-14',
      },
      loading: 'px-2.5',
      spinner: {
        base: 'h-6 w-6',
        rect: '-m-0.5 mr-1.5',
      },
    },
  },
  color: {
    transparent: {
      bg: `bg-transparent !text-secondary hover:bg-gray-100 focus:ring-2 focus:ring-offset-2 focus:ring-gray-300 focus:ring-offset-gray-200`,
      outline: `border-transparent border no-shadow text-primary active:bg-transparent`,
    },
    gray: {
      bg: `bg-stripe !text-secondary focus:ring-2 focus:ring-offset-2 focus:ring-gray-300 focus:ring-offset-gray-200`,
      outline: `border-stripe border !text-primary active:bg-stripe`,
    },
    black: {
      bg: `bg-accent !text-active focus:ring-2 focus:ring-offset-2 focus:ring-active focus:ring-active`,
      outline: `border-accent border !text-primary active:bg-accent active:!text-active`,
    },
    primary: {
      bg: `bg-brand-main focus:ring-2 focus:ring-offset-2 focus:ring-brand-main focus:ring-offset-brand-light`,
      outline: `border-brand-main border text-brand-main active:bg-brand-main active:text-active`,
    },
    brand: {
      bg: `bg-brand-main`,
      outline: `border-green-700 border text-green-700 active:bg-green-700 active:text-active`,
    },
    danger: {
      bg: `bg-red-600 focus:ring-2 focus:ring-offset-2 focus:ring-red-600 focus:ring-offset-red-200`,
      outline: `border-red-600 border text-red-600 active:bg-red-600 active:text-active`,
    },
    warning: {
      bg: `bg-yellow-500 focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500 focus:ring-offset-yellow-200`,
      outline: `border-yellow-500 border text-yellow-500 active:bg-yellow-500 active:text-active`,
    },
    google: {
      bg: `bg-google border-stroke border !text-primary active:bg-stroke active:!text-stroke focus:ring-2 focus:ring-offset-2 focus:ring-active`,
      outline: ``,
    },
    facebook: {
      bg: `bg-facebook focus:ring-2 focus:ring-offset-2 focus:ring-active`,
      outline: ``,
    },
    apple: {
      bg: `bg-apple focus:ring-2 focus:ring-offset-2 focus:ring-active`,
      outline: ``,
    },
  },
} as const;

function SpinnerIcon({
  size,
  circle,
  className,
}: Required<Pick<ButtonProps, 'size' | 'circle' | 'className'>>): ReactElement {
  return (
    <CgSpinner
      className={cls(
        `self-center flex items-center justify-center animate-spin`,
        className,
        style.sizes[size].spinner.base,
        circle ? null : style.sizes[size].spinner.rect,
      )}
    />
  );
}

export const Button = forwardRef(function Button(
  {
    children,
    className,
    disabled = false,
    loading = false,
    type = 'button',
    size = 'md',
    color = 'gray',
    block = false,
    outline = false,
    rounded = false,
    circle = false,
    disableOnLoad = false,
    onClick,
    propagation = false,
    replaceWithSpinner = circle,
    ...props
  }: ButtonProps,
  ref: ForwardedRef<HTMLButtonElement>,
) {
  const isDisabled: boolean = disabled || (disableOnLoad && loading);

  return (
    <button
      ref={ref}
      {...props}
      type={type}
      onClick={e => {
        if (!propagation) e.stopPropagation();
        onClick?.(e);
      }}
      disabled={isDisabled}
      className={cls(
        style.default,
        block ? style.block : '',
        isDisabled ? style.disabled : '',
        rounded ? style.rounded : '',
        style.sizes[size].button.base,
        circle ? style.circle : null,
        circle ? style.sizes[size].button.circle : style.sizes[size].button.rect,
        style.color[color][outline ? 'outline' : 'bg'],
        loading && !circle ? style.sizes[size].loading : '',
        className,
      )}
    >
      {replaceWithSpinner ? (
        <>{loading ? <SpinnerIcon size={size} circle={circle} className="!m-0" /> : children}</>
      ) : (
        <>
          {loading && <SpinnerIcon size={size} circle={circle} className="" />}
          {children}
        </>
      )}
    </button>
  );
});
