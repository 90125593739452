import { getPrivateUserById } from '@sp/data/bif';
import { createResourceWithParams } from '@sp/util/helpers';
import { createGate } from 'effector-react';

const ProfileGate = createGate<number | null>({ defaultState: null });

const PROFILE_USER_RESOURCE = createResourceWithParams({
  loadOnArgsChange: true,
  $args: ProfileGate.state.map(userId => [userId] as [number | null]),
  fetchResource: async userId => (userId ? getPrivateUserById(userId) : null),
});

export const PROFILE_MODEL = {
  ProfileGate,
  $userId: ProfileGate.state,
  $user: PROFILE_USER_RESOURCE.$data,
  $isUserLoading: PROFILE_USER_RESOURCE.$isLoading,
  profileReset: ProfileGate.close.prepend(() => null),
  $skills: PROFILE_USER_RESOURCE.$data.map(user => user?.points ?? []),
};
