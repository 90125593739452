import { Button } from '@sp/ui/elements';
import { isNotNullish } from '@sp/util/helpers';
import { useGate, useStore } from 'effector-react';
import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { CHALLENGE_EDITOR_MODEL } from './challenge-editor-model';
import { CHALLENGE_FORM_MODEL } from './challenge-form-model';
import { ChallengeParamsForm } from './challenge-params-form';

export const CreateChallengePage: FC = () => {
  useGate(CHALLENGE_EDITOR_MODEL.ChallengeCreationGate);
  const isCanCreate = useStore(CHALLENGE_FORM_MODEL.$isCanCreateChallenge);
  const isCreating = useStore(CHALLENGE_EDITOR_MODEL.$challengeCreateInProgress);
  const creatingError = useStore(CHALLENGE_EDITOR_MODEL.$challengeCreateError);
  const navigate = useNavigate();

  useEffect(() => {
    const { unsubscribe } = CHALLENGE_EDITOR_MODEL.challengeCreated.watch(id => navigate(`./${id}`));
    return unsubscribe;
  }, [navigate]);

  return (
    <div className="mobile-pan h-full">
      <div className="mt-4 mb-4 shadow-modal bg-primary p-4">
        <ChallengeParamsForm />
        <hr />
        <div className="flex items-center gap-2">
          <Button
            disabled={!isCanCreate || isCreating}
            loading={isCreating}
            onClick={() => CHALLENGE_FORM_MODEL.createChallengeStarted()}
            color="primary"
          >
            Next
          </Button>

          {isNotNullish(creatingError) ? (
            <span className="text-alert font-semibold">creating error - {creatingError.message}</span>
          ) : null}
        </div>
      </div>
    </div>
  );
};
