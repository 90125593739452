import { STREAM_CHAT_MODEL } from '@sp/feature/stream-chat';
import { isKnownReaction } from '@sp/ui/chat';
import { ChatChannel, KnownReaction, SelfPlusExtendedGenerics } from '@sp/util/stream-chat';
import { createDomain, restore, sample } from 'effector';
import { createGate } from 'effector-react';
import { GetReactionsAPIResponse, ReactionResponse } from 'stream-chat';

export type ChatReaction = ReactionResponse<SelfPlusExtendedGenerics>;
export type MessageReactions = Record<KnownReaction, ChatReaction[]>;
const REACTIONS_LIMIT = 300;

const reactionDomain = createDomain();
const ReactionGate = createGate<string>();

const reactionLoadStarted = reactionDomain.createEvent<string>();
const isModalOpenSet = reactionDomain.createEvent<boolean>();
const reactionsReset = reactionDomain.createEvent();

const $isModalOpen = restore(isModalOpenSet, false);
const $reactions = reactionDomain.createStore<MessageReactions>({} as MessageReactions);

function getReactions(...[messageId, options]: Parameters<ChatChannel['getReactions']>) {
  return STREAM_CHAT_MODEL.client.get<GetReactionsAPIResponse<SelfPlusExtendedGenerics>>(
    STREAM_CHAT_MODEL.client.baseURL + `/messages/${messageId}/reactions`,
    { ...options },
  );
}

const loadReactionsFx = reactionDomain.createEffect((messageId: string) =>
  getReactions(messageId, { limit: REACTIONS_LIMIT }).then(data => data.reactions),
);

sample({ source: ReactionGate.close, target: reactionsReset });

sample({ clock: reactionLoadStarted, target: loadReactionsFx });

sample({ source: loadReactionsFx.doneData, target: $reactions, fn: parseChatReactions });
$reactions.reset(reactionsReset);

function parseChatReactions(reactions: ChatReaction[]) {
  // Lodash GroupBy sucks (bad types)
  return reactions.reduce((acc, reaction) => {
    const type = reaction.type;
    if (!isKnownReaction(type)) return acc;

    if (!acc[type]) {
      acc[type] = [];
    }
    acc[type].push(reaction);
    return acc;
  }, <MessageReactions>{});
}

export const REACTIONS_MODAL_MODEL = {
  $reactions,
  $isModalOpen,
  isModalOpenSet,
  ReactionGate,
  reactionLoadStarted,
};
