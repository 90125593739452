import { checkFeatureFlag, FeatureFlag } from '@sp/data/env';
import { format, utcToZonedTime } from 'date-fns-tz';
import { createEvent } from 'effector';
import { collectRepeatedLogsMiddleware } from './collect-repeated-logs-middleware';
import { consoleLogger } from './console-logger';
import { createLogger, LoggerMiddleware } from './middleware';

const middlewares: LoggerMiddleware[] = [];

if (!checkFeatureFlag(FeatureFlag.enableStreamChatInfoLogLevel)) {
  const disableInfoLogs: LoggerMiddleware = (...args) => (args[0] !== 'info' ? args : false);
  middlewares.push(disableInfoLogs);
}

export const webSocketConnectionStuck = createEvent();

const logContinuousWebSocketWarnings = collectRepeatedLogsMiddleware({
  resetTimeout: 10_000,
  criticalNumberOfLogs: 20,
  matches: [
    // connection was closed abnormally, e.g., without sending or receiving a Close control frame.
    'WS failed with code 1006',
    // unknown browser error.
    'WS failed with code undefined',
  ],
  onCriticalNumberReached: collectedMessages => {
    const elapsedSeconds = (
      (collectedMessages[collectedMessages.length - 1].timestamp - collectedMessages[0].timestamp) /
      1000
    ).toFixed(2);
    console.error(
      `StreamChat WS fails to connect for ${elapsedSeconds}s.\n`,
      collectedMessages
        .map(({ timestamp, args }) => {
          const utcTime = format(utcToZonedTime(timestamp, 'UTC'), 'HH:mm:ss', { timeZone: 'UTC' });
          return `${utcTime} ${args[0]} ${args[1]}`;
        })
        .join('\n'),
    );

    webSocketConnectionStuck();
  },
});

middlewares.push(logContinuousWebSocketWarnings);

export const streamChatLogger = createLogger(consoleLogger, middlewares);
