import { ReactElement } from 'react';
import { ICON_DEFAULTS, IconProps } from './types';

export function IconChatTeardropDots({
  size = ICON_DEFAULTS.size,
  color = ICON_DEFAULTS.color,
  className = ICON_DEFAULTS.className,
}: IconProps): ReactElement {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.6249 20.25H19.5281C19.6232 20.2513 19.7176 20.2334 19.8058 20.1976C19.8939 20.1618 19.974 20.1087 20.0413 20.0414C20.1086 19.9741 20.1617 19.894 20.1976 19.8059C20.2334 19.7177 20.2512 19.6233 20.2499 19.5281V11.625C20.2499 9.33751 19.3412 7.14371 17.7237 5.5262C16.1062 3.9087 13.9124 3 11.6249 3V3C10.4923 3 9.37073 3.22309 8.32429 3.65654C7.27786 4.08999 6.32705 4.7253 5.52614 5.5262C4.72524 6.32711 4.08992 7.27792 3.65648 8.32436C3.22303 9.37079 2.99994 10.4923 2.99994 11.625V11.625C2.99994 12.7577 3.22303 13.8792 3.65648 14.9256C4.08992 15.9721 4.72524 16.9229 5.52614 17.7238C6.32705 18.5247 7.27786 19.16 8.32429 19.5935C9.37073 20.0269 10.4923 20.25 11.6249 20.25V20.25Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.625 13.125C11.0037 13.125 10.5 12.6213 10.5 12C10.5 11.3787 11.0037 10.875 11.625 10.875C12.2463 10.875 12.75 11.3787 12.75 12C12.75 12.6213 12.2463 13.125 11.625 13.125Z"
        fill={color}
      />
      <path
        d="M16.125 13.125C15.5037 13.125 15 12.6213 15 12C15 11.3787 15.5037 10.875 16.125 10.875C16.7463 10.875 17.25 11.3787 17.25 12C17.25 12.6213 16.7463 13.125 16.125 13.125Z"
        fill={color}
      />
      <path
        d="M7.125 13.125C6.50368 13.125 6 12.6213 6 12C6 11.3787 6.50368 10.875 7.125 10.875C7.74632 10.875 8.25 11.3787 8.25 12C8.25 12.6213 7.74632 13.125 7.125 13.125Z"
        fill={color}
      />
    </svg>
  );
}
