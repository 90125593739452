import { UploadedFile } from '@sp/data/bif';
import { createFileUploader, createSingleFileUploader } from '@sp/feature/file-uploader';
import { createZodRule, RULES } from '@sp/util/effector-forms';
import { getVideoMeta, optimizeImage } from '@sp/util/files';
import { createShowAlertFx } from '@sp/util/helpers';
import { addHours, startOfHour } from 'date-fns';
import { sample, StoreValue } from 'effector';
import { createForm } from 'effector-forms';
import { z } from 'zod';

const IMAGE_UPLOADER = createFileUploader(optimizeImage);
const VIDEO_UPLOADER = createFileUploader(undefined, getVideoMeta);

const FILE_UPLOADERS = [IMAGE_UPLOADER, VIDEO_UPLOADER];

IMAGE_UPLOADER.uploadFailed.watch(v => console.log('fail', v));
IMAGE_UPLOADER.uploadCanceled.watch(v => console.log('canceled', v));

sample({
  clock: FILE_UPLOADERS.map(v => v.uploadFailed),
  target: createShowAlertFx('Failed to upload file'),
});

const coverUploader = createSingleFileUploader('live-cover', IMAGE_UPLOADER);
const videoRecordingUploader = createSingleFileUploader('live-video-recording', VIDEO_UPLOADER);
const videoRecordingCoverUploader = createSingleFileUploader('live-video-recording-cover', IMAGE_UPLOADER);

const getStartOfNextHour = () => startOfHour(addHours(Date.now(), 1)).getTime();

const form = createForm({
  validateOn: ['submit'],
  fields: {
    title: { init: '', rules: [RULES.nonEmptyString()] },
    // TODO[Dmitriy Teplov] validate slug format (no whitespace etc.)
    slug: { init: '', rules: [RULES.nonEmptyString()] },
    startAt: { init: getStartOfNextHour, rules: [RULES.timestamp()] },
    price: { init: 4_99, rules: [RULES.positiveInt()] },
    videoRecording: {
      init: null as UploadedFile | null,
      rules: [RULES.uploadedFile(true)],
    },
    videoRecordingCover: {
      init: null as UploadedFile | null,
      rules: [RULES.uploadedFile(true)],
    },
    videoRecordingDuration: { init: 0 },
    cover: {
      init: null as unknown as UploadedFile,
      rules: [RULES.uploadedFile()],
    },
    duration: { init: 10, rules: [RULES.positiveInt()] },
    linkToZoom: { init: '', rules: [RULES.url()] },
    requirements: { init: '', rules: [createZodRule({ name: 'string', schema: z.string() })] },
    creatorDescription: { init: '', rules: [RULES.nonEmptyString()] },
    description: { init: '', rules: [RULES.nonEmptyString()] },
    offerDescription: { init: '', rules: [RULES.nonEmptyString()] },
    offerSummary: { init: '', rules: [RULES.nonEmptyString()] },
  },
});

export type DetailsFormState = StoreValue<typeof form['$values']>;

sample({
  clock: form.fields.videoRecording.$value.map(value => value === null),
  filter: isEmpty => isEmpty,
  target: [form.fields.videoRecordingCover.reset, form.fields.videoRecordingDuration.reset],
});

sample({
  clock: form.reset,
  target: FILE_UPLOADERS.map(v => v.resetAll),
});

sample({
  clock: coverUploader.finished.map(({ data }) => data),
  target: form.fields.cover.set,
});

sample({
  clock: videoRecordingUploader.finished.map(({ data }) => data),
  target: form.fields.videoRecording.set,
});

sample({
  clock: videoRecordingUploader.finished.filterMap(({ meta }) => meta?.duration ?? 0),
  target: form.fields.videoRecordingDuration.set,
});

sample({
  clock: videoRecordingCoverUploader.finished.map(({ data }) => data),
  target: form.fields.videoRecordingCover.set,
});

export const DETAILS_FORM_MODEL = {
  form,
  uploader: {
    videoRecording: videoRecordingUploader,
    videoRecordingCover: videoRecordingCoverUploader,
    cover: coverUploader,
  },
} as const;
