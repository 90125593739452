import { isInApp, navigateExternal } from './helpers';

let isRegistered = false;

export function registerExternalLinkClickHandler() {
  if (isRegistered) return;

  isRegistered = true;

  document.addEventListener('click', (event: Event) => {
    if (!isInApp()) return;

    if (!(event.target instanceof HTMLElement)) return;

    const linkElement = (event.target as HTMLElement).closest('a[target="_blank"]');
    if (!(linkElement instanceof HTMLAnchorElement)) return;

    event.preventDefault();
    navigateExternal(linkElement.href);
  });
}
