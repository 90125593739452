import { isUserSet, USER_MODEL } from '@sp/data/auth';
import { MeUser } from '@sp/data/bif';
import { isNotNullish, isNullish, waitUntil } from '@sp/util/helpers';
import { createEffect, createEvent, restore, sample } from 'effector';

interface GrooveContact {
  contact_first_name: string;
  contact_bio: string;
  contact_email?: string;
  contact_avatar_url?: string;
}

interface Groove {
  widget: {
    identifyContact: (field: 'selfplus_id', value: string) => void;
    setContact: (contact: GrooveContact) => void;
    open: VoidFunction;
    close: VoidFunction;
  };
}

export const initGroove = createEvent();
const initGrooveFx = createEffect(() => import('./groove').then(module => module.default as Groove));
const $groove = restore(initGrooveFx.doneData, null);
export const $isGrooveActive = $groove.map(isNotNullish);

sample({
  clock: initGroove,
  source: $groove,
  filter: isNullish,
  target: initGrooveFx,
});

sample({
  clock: waitUntil<MeUser>({
    clock: USER_MODEL.$user.updates.filter({ fn: isUserSet }),
    until: $groove.map(isNotNullish),
  }),
  source: $groove,
  fn: (groove, user) => ({ groove: groove as Groove, user }),
  target: createEffect(({ user, groove }: { user: MeUser; groove: Groove }) => {
    groove.widget.identifyContact('selfplus_id', user.id.toString());

    const contact: GrooveContact = {
      contact_first_name: user.name,
      contact_bio: `selfplus ID: ${user.id}`,
    };

    if (isNotNullish(user.email)) {
      contact.contact_email = user.email;
    }

    const avatarUrl = user.avatar?.url ?? null;
    if (isNotNullish(avatarUrl)) {
      contact.contact_avatar_url = avatarUrl;
    }

    groove.widget.setContact(contact);
  }),
});

export const openGrooveWidget = createEvent();

sample({
  clock: openGrooveWidget,
  source: $groove,
  filter: (groove): groove is Groove => isNotNullish(groove),
  target: createEffect((groove: Groove) => groove.widget.open()),
});
