import { UpdateFrequency, UploadedFile } from '@sp/data/bif';
import { createFileUploader, createSingleFileUploader } from '@sp/feature/file-uploader';
import { RULES } from '@sp/util/effector-forms';
import { optimizeImage } from '@sp/util/files';
import { createShowAlertFx } from '@sp/util/helpers';
import { combine, createEvent, sample, StoreValue } from 'effector';
import { createForm } from 'effector-forms';
import { and } from 'patronum';

const IMAGE_UPLOADER = createFileUploader(optimizeImage);

IMAGE_UPLOADER.uploadFailed.watch(v => console.log('fail', v));
IMAGE_UPLOADER.uploadCanceled.watch(v => console.log('canceled', v));

sample({
  clock: IMAGE_UPLOADER.uploadFailed,
  target: createShowAlertFx('Failed to upload file'),
});

const coverUploader = createSingleFileUploader('live-cover', IMAGE_UPLOADER);

const form = createForm({
  validateOn: ['submit'],
  fields: {
    title: { init: '', rules: [RULES.nonEmptyString()] },
    slug: { init: '', rules: [RULES.nonEmptyString()] },
    price: { init: 4_99, rules: [RULES.positiveInt()] },
    cover: {
      init: null as unknown as UploadedFile,
      rules: [RULES.uploadedFile()],
    },
    creatorDescription: { init: '', rules: [RULES.nonEmptyString()] },
    description: { init: '', rules: [RULES.nonEmptyString()] },
    targetAudience: { init: '' },
    contentFormat: { init: '' },
    updateFrequency: { init: UpdateFrequency.daily, rules: [RULES.enum(UpdateFrequency)] },
  },
});

export type ChannelFormState = StoreValue<typeof form['$values']>;

const set = createEvent<ChannelFormState>();

sample({ clock: set, target: form.setForm });

sample({
  clock: form.reset,
  target: IMAGE_UPLOADER.resetAll,
});

sample({
  clock: coverUploader.finished.map(({ data }) => data),
  target: form.fields.cover.set,
});

const reset = createEvent();

sample({ clock: reset, target: form.reset });

const submit = createEvent();
sample({
  clock: submit,
  target: form.validate,
});

const validFormSubmitted = sample({
  source: combine({ state: form.$values, isValid: form.$isValid }),
  clock: submit,
  filter: ({ isValid }) => isValid,
  fn: ({ state }) => state,
});

export const CHANNEL_FORM_MODEL = {
  form,
  validFormSubmitted,
  uploader: {
    cover: coverUploader,
  },
  $isValid: and(form.$isValid),
  $isDirty: and(form.$isDirty),
  set,
  reset,
  submit,
} as const;
