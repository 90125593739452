import { CHAT_CHANNEL } from '@sp/feature/chat-channel';
import { IconHourglass } from '@sp/ui/icons';
import { switchMap, useContinuousEventStatus } from '@sp/util/helpers';
import { createEvent, restore } from 'effector';
import { useStore } from 'effector-react';
import { VFC } from 'react';

const unreadCountChanged = createEvent<number>();
const $unreadCount = restore(unreadCountChanged, 0).reset(CHAT_CHANNEL.instanceDestroyed);
switchMap({ source: CHAT_CHANNEL.$instance, fn: i => i?.$unreadCount, target: unreadCountChanged });

export const ChatTitle = () => {
  const hasUnread = useStore($unreadCount) > 0;
  return (
    <span className="flex items-center justify-center">
      Chat
      {hasUnread && <span className="h-1.5 w-1.5 bg-new-message rounded-full ml-1 -mr-2.5" />}
    </span>
  );
};

export const MentionsTitle = ({ unviewedCount }: { unviewedCount: number }) => {
  const hasUnread = unviewedCount > 0;
  return (
    <span className="flex items-center justify-center">
      Mentions
      {hasUnread && <span className="h-1.5 w-1.5 bg-new-message rounded-full ml-1 -mr-2.5" />}
    </span>
  );
};

export const LiveTitle: VFC<{ date?: number; duration?: number }> = ({ date, duration }) => {
  const { isEnded, isStarted } = useContinuousEventStatus({ startAt: date || 0, duration });
  if (!date || !duration) return <div>LIVE</div>;
  const isLive = isStarted && !isEnded;
  return (
    <div className={` ${isLive ? '' : 'gap-1'} pr-1 py-0  h-fit flex items-center justify-center`}>
      {!isStarted && <IconHourglass size={16} />}
      {isLive ? (
        <span className="font-bold text-xs px-1.5 p-1 rounded bg-new-message text-white">• LIVE NOW</span>
      ) : (
        <span>LIVE</span>
      )}
    </div>
  );
};
