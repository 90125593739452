export type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

export type RecursiveRequired<T> = {
  [P in keyof T]-?: T[P] extends (infer U)[] ? RecursiveRequired<U>[] : RecursiveRequired<T[P]>;
};

export type Mutable<T> = { -readonly [P in keyof T]: Mutable<T[P]> };

export function isNullish<T>(value: T | undefined | null): value is null | undefined {
  return value == null;
}

export function isNotNullish<T>(value: T | undefined | null): value is NonNullable<T> {
  return value != null;
}

export function assert(condition: boolean): asserts condition {
  if (!condition) {
    throw new Error('Assert Error.');
  }
}

export function noop(): void {
  return;
}

export function id<T>(v: T): T {
  return v;
}

export function snd<K, T>(_: K, v: T): T {
  return v;
}

export function fst<K, T>(v: T, _: K): T {
  return v;
}

export type Values<T> = T[keyof T];

export type ArrayValues<T extends readonly unknown[]> = T extends ReadonlyArray<infer U> ? U : never;

export type DiscriminatingOmit<Type, Excluded extends keyof Type> = {
  [Property in keyof Type as Exclude<Property, Excluded>]: Type[Property];
};

export type Nullable<T> = T | null;

export type WithOptional<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

export type ShallowNonNullable<T> = { [K in keyof T]: NonNullable<T[K]> };

export type PublicClassMembers<T> = { [P in keyof T]: T[P] };

export type DeepReadonly<T> = T extends (infer R)[]
  ? DeepReadonlyArray<R>
  : T extends object
  ? DeepReadonlyObject<T>
  : T;

type DeepReadonlyArray<T> = ReadonlyArray<DeepReadonly<T>>;

type DeepReadonlyObject<T> = {
  readonly [P in keyof T]: DeepReadonly<T[P]>;
};

export type BooleanMap<T extends Record<string, unknown>> = { [key in keyof T]: boolean };
