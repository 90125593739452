import { cls } from '@sp/util/helpers';
import { ForwardedRef, forwardRef, InputHTMLAttributes } from 'react';

type InputProps = InputHTMLAttributes<HTMLInputElement> & {
  block?: boolean;
};

const style = {
  default: 'bg-stripe p-3 rounded-lg border-none placeholder:text-secondary',
  block: `w-full`,
  disabled: 'text-disabled cursor-not-allowed !opacity-100',
  checkbox: '!w-4 !h-4 !p-0 !rounded-full !text-brand',
  radio: '!w-4 !h-4 !p-0 !rounded-full !text-primary',
};

export const Input = forwardRef(function Input(
  { className, block = false, ...props }: InputProps,
  ref: ForwardedRef<HTMLInputElement>,
) {
  return (
    <input
      ref={ref}
      {...props}
      className={cls(
        style.default,
        block ? style.block : '',
        props.disabled ? style.disabled : '',
        props.type === 'checkbox' ? style.checkbox : '',
        props.type === 'radio' ? style.radio : '',
        className,
      )}
    />
  );
});
