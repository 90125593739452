import { ReactElement } from 'react';
import { ICON_DEFAULTS, IconProps } from './types';

export function IconTextAa({
  size = ICON_DEFAULTS.size,
  color = ICON_DEFAULTS.color,
  className = ICON_DEFAULTS.className,
}: IconProps): ReactElement {
  return (
    <svg
      width={size}
      height={size}
      className={className}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.25 18L7.5 5.25L0.75 18"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.5 18.2715C21.1569 18.2715 22.5 17.0962 22.5 15.6465C22.5 14.1967 21.1569 13.0215 19.5 13.0215C17.8431 13.0215 16.5 14.1967 16.5 15.6465C16.5 17.0962 17.8431 18.2715 19.5 18.2715Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.25 10.5373C17.8543 9.95709 18.6623 9.63727 19.5 9.64669C21.1594 9.64669 22.5 10.8186 22.5 12.2717V17.9998"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M12.2633 14.25H2.73828" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
