import { ArrayValues } from '@sp/util/helpers';
import { format } from 'date-fns';
import { BindFieldAdapter } from './bindings';

export const ADAPTERS = {
  money: {
    input: number => (isNaN(number) ? '' : number / 100),
    output: (string: string) => {
      try {
        return parseFloat(string) * 100;
      } catch (e) {
        return NaN;
      }
    },
  } as BindFieldAdapter<number, string>,
  integer: {
    input: number => number.toString(),
    output: (string: string) => {
      try {
        return parseInt(string, 10);
      } catch (e) {
        return NaN;
      }
    },
  } as BindFieldAdapter<number, string>,
  timestamp: {
    input: timestamp => format(timestamp, `yyyy-MM-dd'T'HH:mm`),
    output: isoDateString => new Date(isoDateString).getTime(),
  } as BindFieldAdapter<number, string>,
} as const;

export const ADAPTER_FACTORIES = {
  stringOptions: <Values extends string[]>(values: Values) => {
    type Value = ArrayValues<Values>;

    return {
      input: (value: Value) => value,
      output: (value: string) => {
        if (!values.includes(value)) {
          throw new RangeError(`"${value}" is not one of ${values.map(v => `"${v}"`).join(', ')}.`);
        }
        return value as Value;
      },
    } as const;
  },
} as const;
