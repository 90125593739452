import { Avatar } from '@sp/ui/elements';
import { IconCircleWavyCheck } from '@sp/ui/icons';
import { FC } from 'react';

export const SpaceDescriptionAuthor: FC<{
  heading: string;
  name: string;
  avatar?: string;
  description: string;
}> = ({ heading, name, avatar, description }) => {
  return (
    <div>
      <h4>{heading}</h4>
      <div className="flex flex-row items-start gap-2">
        <Avatar src={avatar} size="sm" />
        <div className="flex flex-col gap-2">
          <div className="m-0 flex flex-row items-center gap-1">
            <span className="text-lg leading-6">{name}</span>
            <IconCircleWavyCheck size={16} color="#487864" />
          </div>
          <div className="p-2 rounded-lg rounded-tl-none bg-[#EFF2F7] w-fit overflow-hidden break-words whitespace-pre-line">
            {description}
          </div>
        </div>
      </div>
    </div>
  );
};
