import { SpaceCreator, SpaceMember, SubscriptionType } from '@sp/data/bif';
import { SELFPLUS_WEBSITE_URL } from '@sp/data/env';
import { SELFPLUS_ADMINS_MODEL } from '@sp/data/global';
import { USER_PROFILE_CARD_SHOW_MODEL } from '@sp/data/user-profile-card';
import { Member } from '@sp/feature/members';
import { Avatar } from '@sp/ui/elements';
import { IconExport } from '@sp/ui/icons';
import { Modal } from '@sp/ui/modal';
import { TabPane, Tabs } from '@sp/ui/tabs';
import { GRAY_PIXEL_IMG_SRC } from '@sp/util/files';
import { Nullable } from '@sp/util/helpers';
import { shareUrl } from '@sp/util/native';
import { createEvent, createStore } from 'effector';
import { useStore } from 'effector-react';
import { FC, Fragment, useCallback, useState, VFC } from 'react';
import { COMMUNITY_MODEL } from './community-model';
import { CommunitySubscriptionDetailsModal } from './community-subscription-details-modal';

export const openDescriptionModal = createEvent();
export const closeDescriptionModal = createEvent();
export const descriptionModalReset = createEvent();

const $isOpen = createStore<boolean>(false)
  .reset(descriptionModalReset)
  .on(openDescriptionModal, () => true)
  .on(closeDescriptionModal, () => false);

const Header: FC<{
  name: string;
  title: string;
  urlToShare?: string;
  members?: number;
  avatar?: string;
}> = ({ title, members, avatar, urlToShare, name }) => {
  const handleShareClick = useCallback(() => {
    if (urlToShare) {
      shareUrl({ url: urlToShare, text: title, title });
    }
  }, [urlToShare, title]);

  return (
    // Псевдоэлемент нужен чтобы перекрыть артефакт отображения проскролленного контента под фиксированными табами
    // (на iOS просвечивает контент над табами)
    <div className="flex justify-start items-start px-6 gap-2 pb-2 w-full relative before:content[''] before:absolute before:w-full before:h-2 before:left-0 before:-bottom-1 before:bg-primary before:z-50">
      <span>
        <Avatar size="sm" src={avatar} />
      </span>
      <div className="flex flex-col flex-1 overflow-x-hidden">
        <h1 className="m-0 text-ellipsis whitespace-nowrap overflow-x-hidden text-base">{title}</h1>
        <span className="text-secondary text-xs">
          <span className="font-semibold">{name}</span>
          <span> · {members} participants</span>
        </span>
      </div>
      <button type="button" title="Back" onClick={handleShareClick} className="text-secondary p-2">
        <IconExport size={24} />
      </button>
    </div>
  );
};

const Members: FC<{
  members: SpaceMember[];
  creator?: Nullable<SpaceCreator>;
}> = ({ members, creator }) => {
  const selfplusAdmins = useStore(SELFPLUS_ADMINS_MODEL.$data);
  const checkBadge = useCallback(
    (userId: number) => {
      if (!creator) return false;
      return !!(userId === creator.id || selfplusAdmins?.find(admin => userId === admin.id));
    },
    [creator, selfplusAdmins],
  );
  const handleClick = (user: { id: number; avatar: { url: string | null } | null }) => {
    USER_PROFILE_CARD_SHOW_MODEL.show({ userId: user.id, avatar: user.avatar || undefined });
  };

  return (
    <div className="py-4">
      {creator && (
        <Member
          withBadge={checkBadge(creator.id)}
          onClick={() => handleClick(creator)}
          key={creator.id}
          userName={creator.name}
          url={creator.avatar?.url}
        />
      )}
      {members.map((member, i, array) => (
        <Fragment key={member.id}>
          {/* the array must be sorted "buyers – first, trial participants – last"  */}
          {member.isTrial && i > 0 && !array[i - 1].isTrial && <hr className="my-4" />}
          <Member
            withBadge={checkBadge(member.id)}
            onClick={() => handleClick(member)}
            userName={member.name || 'Newcomer'}
            url={member.avatar?.url}
          />
        </Fragment>
      ))}
    </div>
  );
};

const CommunityDescription: FC<Readonly<{ isManageSubscriptionDisplayed: boolean; description: string | null }>> = ({
  isManageSubscriptionDisplayed,
  description,
}) => {
  const [isOpenManageSubscription, setIsOpenManageSubscription] = useState(false);

  return (
    <div className="py-4">
      {description && <p className="m-0 font-medium overflow-hidden break-words whitespace-pre-line">{description}</p>}

      {isManageSubscriptionDisplayed && (
        <>
          <p className="text-secondary font-medium mt-6 mb-2">About Premium Subscription</p>

          <div className="p-4 rounded-2.5xl bg-light">
            <p className="mt-0 mb-2">You get access to all premium content during the subscription period</p>

            <button type="button" onClick={() => setIsOpenManageSubscription(true)} className="text-brand">
              Manage Subscription
            </button>
          </div>
        </>
      )}
      <CommunitySubscriptionDetailsModal
        isOpen={isManageSubscriptionDisplayed && isOpenManageSubscription}
        onClose={() => setIsOpenManageSubscription(false)}
      />
    </div>
  );
};

export const CommunityDescriptionModal: VFC = () => {
  const isOpen = useStore($isOpen);
  const community = useStore(COMMUNITY_MODEL.$community);
  const [tabKey, setTabKey] = useState('members');
  const title = community?.title ?? '';
  const creator = community?.creator;
  const members = community?.members ?? [];
  const avatar = community?.cover?.url ?? GRAY_PIXEL_IMG_SRC;
  const urlToShare = `${SELFPLUS_WEBSITE_URL}/${community.creator?.name}/`;
  const isOwner = useStore(COMMUNITY_MODEL.$isOwner);

  const isManageSubscriptionDisplayed = !(isOwner || community.subscription?.type === SubscriptionType.foreverFree);

  return (
    <Modal
      header={
        <Header
          title={title}
          members={members.length}
          avatar={avatar}
          urlToShare={urlToShare}
          name={creator?.name ?? ''}
        />
      }
      closeOnBackdropClick
      onClose={closeDescriptionModal}
      withBackdrop
      isOpen={isOpen}
    >
      <div className="min-h-[70vh] -mx-6 relative">
        <Tabs onChange={setTabKey} activeKey={tabKey} activeTabTextColor="text-primary" sticky maxTabWidth="33%">
          <TabPane title="Members" key="members">
            <div className="px-6">
              <Members members={members} creator={creator} />
            </div>
          </TabPane>
          <TabPane title="Description" key="description">
            <div className="px-6">
              <CommunityDescription
                isManageSubscriptionDisplayed={isManageSubscriptionDisplayed}
                description={community.description}
              />
            </div>
          </TabPane>
        </Tabs>
      </div>
    </Modal>
  );
};
