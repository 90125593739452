import { ChatEvent } from '@sp/data/bif';
import {
  ChatMessageAvatar,
  ChatMessageBody,
  ChatMessageBottomAttachments,
  ChatMessageHeader,
  ChatMessageProvider,
  ChatMessageReactions,
  ChatMessageReply,
  ChatMessageTopAttachments,
} from '@sp/feature/chat';
import { ViewInChatButton } from '@sp/feature/space';
import { ChatChannelModel } from '@sp/feature/stream-chat';
import {
  ChatMessageBodyContainer,
  ChatMessageContainer,
  ChatMessageFooterContainer,
  ChatMessageLeftContainer,
  ChatMessageRightContainer,
} from '@sp/ui/chat';
import { formatChatMessage } from '@sp/util/stream-chat';
import { isEmptyDoc, parseMessageContent } from '@sp/util/text-editor';
import { ReactElement, useMemo } from 'react';
import { MentionDescription } from './mention-description';

export const Mention = ({
  mention,
  model,
}: Readonly<{ mention: ChatEvent; model: ChatChannelModel }>): ReactElement => {
  const message = useMemo(() => formatChatMessage(mention.message), [mention]);
  const contextValue = useMemo(() => ({ message, model }), [message, model]);
  const showText = !isEmptyDoc(parseMessageContent(message.text ?? ''));

  return (
    <ChatMessageProvider value={contextValue}>
      <div className="pl-3 pr-6 py-3">
        <div className="mb-1 text-secondary text-xs">
          <MentionDescription
            type={mention.events.type}
            userName={mention.message.user?.name}
            date={mention.createdAt}
          />
        </div>

        <ChatMessageContainer>
          <ChatMessageLeftContainer>
            <ChatMessageAvatar />
          </ChatMessageLeftContainer>

          <ChatMessageRightContainer>
            <ChatMessageHeader showDate={false} />

            <ChatMessageBodyContainer>
              <ChatMessageReply />

              <ChatMessageTopAttachments />

              {showText && <ChatMessageBody />}
            </ChatMessageBodyContainer>

            <ChatMessageBottomAttachments />

            <ChatMessageFooterContainer>
              <ChatMessageReactions />
            </ChatMessageFooterContainer>
          </ChatMessageRightContainer>
        </ChatMessageContainer>

        <ViewInChatButton model={model} messageId={message.id} />
      </div>
    </ChatMessageProvider>
  );
};
