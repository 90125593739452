import { loadSpaceById } from '@sp/data/bif';
import { PURCHASE_MODEL } from '@sp/data/global';
import { attach, createApi, createEffect, createStore, restore, sample } from 'effector';

const $isOpened = createStore(false);

const { open, close } = createApi($isOpened, {
  open: () => true,
  close: () => false,
});

const $isUseSavedCard = createStore(true).reset(open);

const { useSavedCard, useNewCard } = createApi($isUseSavedCard, {
  useSavedCard: () => true,
  useNewCard: () => false,
});

const getChallengeFx = createEffect((challengeId: number) => {
  //TODO: попробовать переиспользовать челендж из других моделей (дискавер, чаты)
  return loadSpaceById(challengeId);
});

const $challenge = restore(getChallengeFx.doneData, null).reset(close);

sample({
  clock: PURCHASE_MODEL.purchaseNeedToBeConfirmed,
  target: [attach({ effect: getChallengeFx, mapParams: ({ spaceId }: { spaceId: number }) => spaceId }), open],
});

const $isLoading = getChallengeFx.pending;

export const CONFIRM_CARD_MODEL = {
  $isOpened,
  $challenge,
  $isLoading,
  open,
  close,
  $isUseSavedCard,
  useSavedCard,
  useNewCard,
};
