import { useUser } from '@sp/data/auth';
import { DirectMessageUser } from '@sp/data/bif';
import { IS_IOS } from '@sp/data/env';
import { SELFPLUS_ADMINS_MODEL } from '@sp/data/global';
import { ChatChannelModel } from '@sp/feature/stream-chat';
import { Avatar, Button } from '@sp/ui/elements';
import { IconCircleWavyCheck } from '@sp/ui/icons';
import { Modal } from '@sp/ui/modal';
import { isNotNullish, noop, Nullable } from '@sp/util/helpers';
import { ChatFormatMessage, ChatUser, formatChatMessage } from '@sp/util/stream-chat';
import { resetScrollWithOpenKeyboardIosAdhoc } from '@sp/util/viewport';
import { useStore, useStoreMap } from 'effector-react';
import { FC, RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ChatMessageList } from './chat-message-list';
import { ChatMessageListItem } from './chat-message-list-item';
import { mockChatModel } from './elements/chat-message-context';
import { MessageModal, MessageModalConfig } from './message-context-modal/message-modal';
import { ReadonlyMessageModal } from './message-context-modal/readonly-message-modal';
import { MessageForm, MessageFormHandle } from './message-form';
import { AcceptFileTypesProvider } from './use-accept-file-types';
import { ChatExtraControls, ChatExtraControlsContext } from './use-chat-extra-controls';
import { MessageExtraContent, MessageExtraContentContext, useMessageExtraContent } from './use-message-extra-content';
import { buildMessageUpdate, buildNewMessage, useMessageForm } from './use-message-form';
import { MessageTemplateContext, MessageTemplateProvider } from './use-message-template';

export type MessageFormTooltip = {
  tooltipText: string;
  textForInput?: string;
};

export const MessageFormTooltip: FC<{
  tooltipText: string;
  onClick: () => void;
}> = ({ tooltipText, onClick }) => {
  return (
    <div className="absolute z-10 w-full top-0 ">
      <div className="absolute bottom-3 w-full flex justify-center">
        <button
          type="button"
          onClick={onClick}
          className="
          relative
          p-4
          bg-accent  rounded-lg
          text-active text-left whitespace-pre-line
          after:w-4 after:h-4 after:bg-accent after:rounded after:rotate-45
          after:absolute after:-bottom-1.5 after:left-1/2 after:-translate-x-1/2"
        >
          {tooltipText}
        </button>
      </div>
    </div>
  );
};

const PreviewModal: FC<{
  formModel: ReturnType<typeof useMessageForm>;
  isOpen: boolean;
  onClose: () => void;
  onPublish: () => void;
}> = ({ formModel, isOpen, onClose, onPublish }) => {
  return (
    <Modal
      footer={
        <div className="px-6 py-6">
          <Button block color="black" onClick={onPublish}>
            {formModel.state.isEditing ? 'Save' : 'Publish'}
          </Button>
        </div>
      }
      onClose={onClose}
      isOpen={isOpen}
      closeOnBackdropClick
      withBackdrop
    >
      <div className="-mx-6">
        <MessagePreview formModel={formModel} />
      </div>
    </Modal>
  );
};

const MessagePreview: FC<{ formModel: ReturnType<typeof useMessageForm> }> = ({ formModel }) => {
  const user = useUser();
  const model = useMemo(() => mockChatModel(), []);
  const message = formatChatMessage({
    id: '0',
    created_at: new Date().toString(),
    pinned_at: null,
    status: '',
    attachments: formModel.attachmentManager.attachments?.map(a => ({ selfplusAttachment: a })),
    text: formModel.state.text,
    user: { name: user.name, image: user.avatar?.url || null, isCreator: true, id: String(user.id) },
  });
  return <ChatMessageListItem onLongPress={noop} message={message} model={model} />;
};

export const MessageFormContainer: FC<{
  readonly chatModel: ChatChannelModel;
  messageFormRef: RefObject<MessageFormHandle>;
  messageFormTooltip?: Nullable<MessageFormTooltip>;
  formModel: ReturnType<typeof useMessageForm>;
  keyMapExtension?: (props: { submit: VoidFunction }) => null;
  withPreview?: boolean;
}> = ({ messageFormTooltip, chatModel, messageFormRef, formModel, keyMapExtension, withPreview }) => {
  const [isShowTooltip, setIsShowTooltip] = useState(isNotNullish(messageFormTooltip));
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
  const isPostingMessage = useStore(chatModel.$isPostingMessage);
  // TODO[Dmitriy Teplov] use users from privateUserApi.userUserSlugSpacesSpaceSlugMembersGet?
  const mentionableUsers: Record<string, ChatUser | undefined> = useStoreMap(chatModel.$members, members =>
    Object.values(members)
      .filter(member => !member.banned && !member.banned)
      .map(member => member.user)
      .filter(isNotNullish)
      .reduce((acc, user) => ({ ...acc, [user.id]: user }), {}),
  );
  const onTooltipClick = useCallback(() => {
    if (isNotNullish(messageFormTooltip) && isNotNullish(messageFormTooltip.textForInput)) {
      formModel.setText(messageFormTooltip.textForInput);
      setIsShowTooltip(false);
    }
  }, [formModel, messageFormTooltip]);

  const onSubmit = useCallback(() => {
    withPreview && setIsPreviewModalOpen(false);
    if (!formModel.state.canSave) {
      return;
    }

    const { formId } = formModel.state;
    if (formModel.state.isEditing) {
      chatModel.messageUpdated({
        message: buildMessageUpdate(formModel.state, formModel.attachmentManager.attachments),
        formId,
      });
    } else {
      chatModel.messagePosted({
        message: buildNewMessage(formModel.state, formModel.attachmentManager.attachments),
        formId,
      });
    }
    messageFormRef.current?.refocus();
  }, [chatModel, formModel.attachmentManager.attachments, formModel.state, messageFormRef, withPreview]);

  useEffect(() => {
    const submitSuccessHandler = async ({ formId }: { formId: string }) => {
      if (formId !== formModel.state.formId) return;

      if (IS_IOS) {
        formModel.reset();
        // contenteditable message input focus is postponed
        //  otherwise Safari doesn't let it be cleared after sending a message.
        await Promise.resolve();
        messageFormRef.current?.refocus();
        // Скролл сбрасывается для того, чтобы страница не ехала наверх после того, как
        // мультилайн инпут очищается до одной строки.
        resetScrollWithOpenKeyboardIosAdhoc();
      } else {
        // On Desktop form has to be reset after focusing to clear selected text.
        messageFormRef.current?.refocus();
        formModel.reset();
      }
    };
    const subs = [
      chatModel.sendMessageSuccess.watch(submitSuccessHandler),
      chatModel.updateMessageSuccess.watch(submitSuccessHandler),
    ];
    return () => subs.forEach(unsubscribe => unsubscribe());
  }, [chatModel, formModel.state.formId, formModel.reset, messageFormRef, formModel]);
  return (
    <div className="relative">
      <MessageForm
        ref={messageFormRef}
        submit={withPreview ? () => setIsPreviewModalOpen(true) : onSubmit}
        isLoading={isPostingMessage}
        model={formModel}
        mentionableUsers={mentionableUsers}
        editorMaxHeight="20vh"
        KeyMapExtension={keyMapExtension}
      />
      {withPreview ? (
        <PreviewModal
          onPublish={onSubmit}
          onClose={() => setIsPreviewModalOpen(false)}
          isOpen={isPreviewModalOpen}
          formModel={formModel}
        />
      ) : null}
      {isShowTooltip && isNotNullish(messageFormTooltip) ? (
        <MessageFormTooltip tooltipText={messageFormTooltip.tooltipText} onClick={onTooltipClick} />
      ) : null}
    </div>
  );
};

export const MessageFormWrapper: FC<{
  activeMessage: ChatFormatMessage | null;
  setActiveMessage: (message: ChatFormatMessage | null) => void;
  messageFormTooltip?: Nullable<MessageFormTooltip>;
  keyMapExtension?: (props: { submit: VoidFunction }) => null;
  messageModalConfig?: MessageModalConfig;
  readonly chatModel: ChatChannelModel;
}> = ({ messageFormTooltip, chatModel, setActiveMessage, activeMessage, keyMapExtension, messageModalConfig }) => {
  const messageFormRef = useRef<MessageFormHandle>(null);
  const formModel = useMessageForm(`chat-${chatModel.channel.id}`);
  return (
    <>
      <MessageFormContainer
        keyMapExtension={keyMapExtension}
        formModel={formModel}
        messageFormRef={messageFormRef}
        chatModel={chatModel}
        messageFormTooltip={messageFormTooltip}
      />
      <MessageModal
        formModel={formModel}
        chatModel={chatModel}
        messageFormRef={messageFormRef}
        activeMessage={activeMessage}
        setActiveMessage={setActiveMessage}
        config={messageModalConfig}
      />
    </>
  );
};

const WelcomeMessage: FC<{ partner: DirectMessageUser }> = ({ partner }) => {
  const selfplusAdmins = useStore(SELFPLUS_ADMINS_MODEL.$data);
  const showBadge = useMemo(() => {
    return !!selfplusAdmins?.find(admin => partner.id === admin.id);
  }, [selfplusAdmins, partner.id]);
  return (
    <div className="pl-3 pr-6 py-3">
      <Avatar src={partner.avatar?.url} size={95} />
      <div className="flex items-center font-medium py-2 gap-2">
        <span className="text-xl">{partner.name}</span>
        {showBadge && <IconCircleWavyCheck size={16} className="text-brand -ml-1 self-center" />}
      </div>
      <div className="text-secondary text-sm">{`This is the very beginning of your legendary conversation with ${partner.name}`}</div>
    </div>
  );
};

export const Chat: FC<{
  readonly chatModel: ChatChannelModel;
  messageFormTooltip?: Nullable<MessageFormTooltip>;
  messageExtraContent?: MessageExtraContent;
  extraControls?: ChatExtraControls;
  messageTemplate?: MessageTemplateProvider;
  keyMapExtension?: (props: { submit: VoidFunction }) => null;
  fullAcceptedFileAttachments?: boolean;
  messageModalConfig?: MessageModalConfig;
  welcomeMessage?: { show: boolean; partner: DirectMessageUser | null };
}> = ({
  chatModel,
  messageFormTooltip = null,
  messageExtraContent = {},
  extraControls = {},
  fullAcceptedFileAttachments = false,
  messageTemplate = undefined,
  keyMapExtension,
  messageModalConfig,
  welcomeMessage = { show: false },
}) => {
  const [activeMessage, setActiveMessage] = useState<ChatFormatMessage | null>(null);
  const messagesCount = useStore(chatModel.$messages)?.length;

  return (
    <>
      <div className="flex flex-col-reverse flex-grow justify-end overflow-y-hidden h-full">
        <AcceptFileTypesProvider fullAccepted={fullAcceptedFileAttachments}>
          <ChatExtraControlsContext.Provider value={extraControls}>
            <MessageFormWrapper
              messageFormTooltip={messageFormTooltip}
              activeMessage={activeMessage}
              setActiveMessage={setActiveMessage}
              chatModel={chatModel}
              keyMapExtension={keyMapExtension}
              messageModalConfig={messageModalConfig}
            />
          </ChatExtraControlsContext.Provider>
        </AcceptFileTypesProvider>
        <div className="flex-grow">
          <MessageExtraContentContext.Provider value={messageExtraContent}>
            <MessageTemplateContext.Provider value={messageTemplate}>
              {!messagesCount && welcomeMessage?.show && welcomeMessage.partner ? (
                <WelcomeMessage partner={welcomeMessage.partner} />
              ) : (
                <ChatMessageList model={chatModel} onShowMessageActions={setActiveMessage} />
              )}
            </MessageTemplateContext.Provider>
          </MessageExtraContentContext.Provider>
        </div>
      </div>
    </>
  );
};
export const ReadonlyChat: FC<{
  readonly chatModel: ChatChannelModel;
  messageExtraContent?: MessageExtraContent;
  messageTemplate?: MessageTemplateProvider;
}> = ({ chatModel, messageExtraContent = {}, messageTemplate = undefined }) => {
  const [activeMessage, setActiveMessage] = useState<ChatFormatMessage | null>(null);
  return (
    <div className="flex flex-col-reverse flex-grow justify-end overflow-y-hidden h-full">
      <div className="flex-grow">
        <MessageExtraContentContext.Provider value={messageExtraContent}>
          <MessageTemplateContext.Provider value={messageTemplate}>
            <ChatMessageList model={chatModel} onShowMessageActions={setActiveMessage} />
          </MessageTemplateContext.Provider>
        </MessageExtraContentContext.Provider>
        <ReadonlyMessageModal activeMessage={activeMessage} chatModel={chatModel} setActiveMessage={setActiveMessage} />
      </div>
    </div>
  );
};
