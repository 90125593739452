import { USER_MODEL } from '@sp/data/auth';
import { ChatBackEvent, ChatEvent, ChatEventsRes, getChatEvents, MeUser, readChatEvents } from '@sp/data/bif';
import { CHAT_CHANNEL } from '@sp/feature/chat-channel';
import { isNotNullish, isNullish, ShallowNonNullable, switchMap } from '@sp/util/helpers';
import { ChatNonFormatMessage, ChatEvent as StreamChatEvent } from '@sp/util/stream-chat';
import { attach, combine, createDomain, createStore, forward, restore, sample } from 'effector';
import { createGate } from 'effector-react';
import isEmpty from 'lodash/isEmpty';
import {
  canHaveSideEffect,
  compareByPriority,
  eventPriority,
  getItemByPriority,
  isMention,
  isReaction,
  isReply,
  notMyEvent,
  parseToChatEvent,
} from './util';

type ChannelEventWithUser = { event: StreamChatEvent; meUser: MeUser };
type Mentions = Map<string, ChatEvent>;
type MentionUpdateType = 'update' | 'delete';
type MentionUpdate = { messageId: string; type: MentionUpdateType; message: ChatNonFormatMessage };

const MentionsGate = createGate<number | null>();
const $meUser = USER_MODEL.$user;

const mentionsDomain = createDomain();

const mentionsReset = mentionsDomain.createEvent();

// Channel events
const channelEventFired = mentionsDomain.createEvent<StreamChatEvent>();
const mentionsRead = mentionsDomain.createEvent<number>();

switchMap({
  source: CHAT_CHANNEL.$instance,
  fn: instance =>
    instance?.pickEvent(
      'message.new',
      'message.updated',
      'message.deleted',
      'reaction.new',
      'reaction.updated',
      'reaction.deleted',
    ),
  target: channelEventFired,
});

const parseChannelEventFx = mentionsDomain.createEffect(({ event, meUser }: ChannelEventWithUser) => {
  return parseToChatEvent(event, meUser);
});

const $combinedChannelEvent = combine(restore(channelEventFired, null), $meUser, (event, meUser) => ({
  event,
  meUser,
}));

sample({
  source: $combinedChannelEvent,
  target: parseChannelEventFx,
  filter: (data): data is ShallowNonNullable<ChannelEventWithUser> =>
    isNotNullish(data.event) &&
    isNotNullish(data.meUser) &&
    notMyEvent(data.event, data.meUser) &&
    (isReaction(data.event, data.meUser) || isMention(data.event, data.meUser) || isReply(data.event, data.meUser)),
});

// Mentions
const loadMentionsFx = mentionsDomain.createEffect((chatId: number) => {
  return getChatEvents(chatId);
});

const readMentionsFx = mentionsDomain.createEffect((chatId: number) => {
  return readChatEvents(chatId);
});

sample({ clock: mentionsRead, target: readMentionsFx });
sample({ source: MentionsGate.open, target: loadMentionsFx, filter: isNotNullish });

sample({ source: MentionsGate.close, target: mentionsReset });

const $mentions = mentionsDomain
  .createStore<Mentions>(new Map())
  .on(loadMentionsFx.doneData, loadMentionsReducer)
  .on(parseChannelEventFx.doneData, channelEventMergeReducer)
  .reset(mentionsReset);

const $unviewedCount = mentionsDomain
  .createStore<number>(0)
  .on(loadMentionsFx.doneData, unviewedReducer)
  .reset(mentionsRead);

const $pairMentions = createStore<{ prev: Mentions | null; cur: Mentions }>({ prev: null, cur: new Map() }).on(
  $mentions,
  (prevData, data) => ({ prev: prevData.cur, cur: data }),
);

sample({
  clock: $pairMentions,
  target: $unviewedCount,
  filter: ({ prev, cur }) => {
    if (isNullish(prev)) return false;
    return cur.size > prev.size;
  },
  fn: () => 1,
});

const $viewMentions = $mentions.map(mentions =>
  Array.from(mentions.values()).sort((a, b) => b.createdAt - a.createdAt),
);

//Side effects
const checkMentionForSideFx = attach({
  effect: mentionsDomain.createEffect((data: MentionUpdate[]) => data),
  source: combine({ mentions: $mentions, user: $meUser }),
  mapParams: sideEffectHandler,
});

forward({ from: channelEventFired, to: checkMentionForSideFx });
$mentions.on(checkMentionForSideFx.doneData, channelEventUpdateReducer);

// Utils
function channelEventMergeReducer(mentions: Mentions, newMention: ChatEvent | undefined): Mentions | undefined {
  if (!newMention) return;

  const newMentions = new Map(mentions);

  if (newMention.deleted) {
    newMentions.delete(newMention.messageId);
  } else {
    newMentions.set(newMention.messageId, newMention);
  }

  return newMentions;
}

function loadMentionsReducer(_: Mentions, chatEventsRes: ChatEventsRes): Mentions {
  const chatEvents = chatEventsRes.events;
  const filteredChatEvents = chatEvents.filter((event): event is ShallowNonNullable<ChatBackEvent> =>
    isNotNullish(event.message),
  );
  return new Map(
    filteredChatEvents.map(event => [
      event.messageId,
      {
        ...event,
        events: getItemByPriority(event.events, eventPriority, compareByPriority),
        deleted: false,
      },
    ]),
  );
}

function channelEventUpdateReducer(mentions: Mentions, updates: MentionUpdate[]) {
  if (!updates.length) return;

  const newMentions = new Map(mentions);

  updates.forEach(update => {
    if (update.type === 'delete') {
      newMentions.delete(update.messageId);
    } else if (newMentions.has(update.messageId)) {
      const newMention = { ...newMentions.get(update.messageId) } as ChatEvent; // https://github.com/microsoft/TypeScript/issues/9619 sad but true
      newMention.message = update.message;
      newMentions.set(update.messageId, newMention);
    }
  });

  return newMentions;
}

function unviewedReducer(_: number, chatEventsRes: ChatEventsRes): number {
  return chatEventsRes.unviewedCount;
}

function handleReplies(event: StreamChatEvent, mentions: Mentions): MentionUpdate[] {
  const type = event.type === 'message.deleted' ? 'delete' : 'update';

  const repliesForUpdate = [...mentions.values()].filter(
    ({ message }) => message.quoted_message_id === event.message?.id,
  );

  if (repliesForUpdate.length) {
    return repliesForUpdate.map(r => {
      const messageId = r.messageId;
      const message = { ...r.message, quoted_message: event.message };
      return { messageId, type, message };
    });
  }
  return [];
}

export function sideEffectHandler(
  event: StreamChatEvent,
  { mentions, user }: { mentions: Mentions; user: MeUser | null },
): MentionUpdate[] {
  if (!user) return [];
  if (!event.message) return [];

  const updates: MentionUpdate[] = [];

  if (canHaveSideEffect(event)) {
    updates.push(...handleReplies(event, mentions));

    if (mentions.has(event.message.id)) {
      const isMyMessage = event.message.user?.id === String(user.id);
      if (!isMention(event, user) && !isReply(event, user) && !isMyMessage) {
        // remove mention/reply after message update
        updates.push({
          messageId: event.message.id,
          type: 'delete',
          message: event.message,
        });
      }
      if (isMyMessage) {
        // delete/update meMassage
        const reactionEmpty = isEmpty(event.message.reaction_counts);
        const type = event.type === 'message.deleted' ? 'delete' : 'update';
        updates.push({
          messageId: event.message.id,
          type: reactionEmpty ? 'delete' : type,
          message: event.message,
        });
      } else {
        updates.push({
          messageId: event.message.id,
          type: 'update',
          message: event.message,
        });
      }
    }
  }

  return updates;
}

export const MENTIONS_MODEL = {
  $viewMentions,
  channelEventFired,
  mentionsReset,
  $unviewedCount,
  mentionsRead,
  MentionsGate,
};
