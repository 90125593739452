import { isAfter } from 'date-fns';
import { useTime } from 'react-time-sync';
import { Interval } from 'time-sync/constants';

export function useContinuousEventStatus({
  startAt,
  duration = 0,
}: {
  startAt: number; // start date in milliseconds
  duration?: number; // in minutes
}) {
  const now = useTime({ interval: Interval.SECONDS }) * 1000;
  return {
    isUpcoming: isAfter(startAt, now),
    isStarted: isAfter(now, startAt),
    isEnded: isAfter(now, startAt + duration * 60 * 1000),
  };
}
