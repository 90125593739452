import { useAuth } from '@sp/data/auth';
import { Button } from '@sp/ui/elements';

export function OauthFacebookForm() {
  const { startLoginWithFacebook, isLoginWithFacebookInProgress: isLoading } = useAuth();

  return (
    <Button
      onClick={startLoginWithFacebook}
      color="facebook"
      size="md"
      className="!py-2 h-[2.625rem] w-full"
      replaceWithSpinner={true}
      loading={isLoading}
    >
      <img src="/images/login/f-logo.svg" alt="Facebook" />
    </Button>
  );
}
