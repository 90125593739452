import { ReactElement } from 'react';
import { ICON_DEFAULTS, IconProps } from './types';

export const IconFilmStrip = ({
  size = ICON_DEFAULTS.size,
  color = ICON_DEFAULTS.color,
  filled = ICON_DEFAULTS.filled,
  className = ICON_DEFAULTS.className,
}: IconProps): ReactElement => (
  <svg
    className={className}
    width={size}
    height={size}
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {filled ? (
      <path
        d="M20.75 3.75H4.25C3.85218 3.75 3.47064 3.90804 3.18934 4.18934C2.90804 4.47064 2.75 4.85218 2.75 5.25V18.75C2.75 19.1478 2.90804 19.5294 3.18934 19.8107C3.47064 20.092 3.85218 20.25 4.25 20.25H20.75C21.1478 20.25 21.5294 20.092 21.8107 19.8107C22.092 19.5294 22.25 19.1478 22.25 18.75V5.25C22.25 4.85218 22.092 4.47064 21.8107 4.18934C21.5294 3.90804 21.1478 3.75 20.75 3.75ZM17.75 5.25H20.75V6.75H17.75V5.25ZM13.25 5.25H16.25V6.75H13.25V5.25ZM8.75 5.25H11.75V6.75H8.75V5.25ZM7.25 18.75H4.25V17.25H7.25V18.75ZM7.25 6.75H4.25V5.25H7.25V6.75ZM8.75 18.75V17.3719H11.75V18.75H8.75ZM13.25 18.75V17.3719H16.25V18.75H13.25ZM20.75 18.75H17.75V17.3719H18.6125V17.25H20.75V18.75Z"
        fill={color}
      />
    ) : (
      <>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.75 5.25C2.75 4.42157 3.42157 3.75 4.25 3.75H20.75C21.5784 3.75 22.25 4.42157 22.25 5.25V18.75C22.25 19.5784 21.5784 20.25 20.75 20.25H4.25C3.42157 20.25 2.75 19.5784 2.75 18.75V5.25ZM20.75 5.25H4.25V18.75H20.75V5.25Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.5 3.75C12.9142 3.75 13.25 4.08579 13.25 4.5V19.5C13.25 19.9142 12.9142 20.25 12.5 20.25C12.0858 20.25 11.75 19.9142 11.75 19.5V4.5C11.75 4.08579 12.0858 3.75 12.5 3.75Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.75 7.5C2.75 7.08579 3.08579 6.75 3.5 6.75H21.5C21.9142 6.75 22.25 7.08579 22.25 7.5C22.25 7.91421 21.9142 8.25 21.5 8.25H3.5C3.08579 8.25 2.75 7.91421 2.75 7.5Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.75 16.5C2.75 16.0858 3.08579 15.75 3.5 15.75H21.5C21.9142 15.75 22.25 16.0858 22.25 16.5C22.25 16.9142 21.9142 17.25 21.5 17.25H3.5C3.08579 17.25 2.75 16.9142 2.75 16.5Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 3.75C8.41421 3.75 8.75 4.08579 8.75 4.5V7.5C8.75 7.91421 8.41421 8.25 8 8.25C7.58579 8.25 7.25 7.91421 7.25 7.5V4.5C7.25 4.08579 7.58579 3.75 8 3.75Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17 3.75C17.4142 3.75 17.75 4.08579 17.75 4.5V7.5C17.75 7.91421 17.4142 8.25 17 8.25C16.5858 8.25 16.25 7.91421 16.25 7.5V4.5C16.25 4.08579 16.5858 3.75 17 3.75Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 15.75C8.41421 15.75 8.75 16.0858 8.75 16.5V19.5C8.75 19.9142 8.41421 20.25 8 20.25C7.58579 20.25 7.25 19.9142 7.25 19.5V16.5C7.25 16.0858 7.58579 15.75 8 15.75Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17 15.75C17.4142 15.75 17.75 16.0858 17.75 16.5V19.5C17.75 19.9142 17.4142 20.25 17 20.25C16.5858 20.25 16.25 19.9142 16.25 19.5V16.5C16.25 16.0858 16.5858 15.75 17 15.75Z"
          fill={color}
        />
      </>
    )}
  </svg>
);
