import { ReactElement } from 'react';
import { ICON_DEFAULTS, IconProps } from './types';

export function IconWarning({
  size = ICON_DEFAULTS.size,
  color = ICON_DEFAULTS.color,
  className = ICON_DEFAULTS.className,
}: IconProps): ReactElement {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M12 9.75V13.5" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M10.7058 3.75024L2.45585 18.0002C2.32442 18.2279 2.25512 18.4861 2.25488 18.7489C2.25465 19.0118 2.3235 19.2701 2.45452 19.498C2.58555 19.7258 2.77416 19.9153 3.00145 20.0473C3.22875 20.1793 3.48674 20.2493 3.7496 20.2502H20.2496C20.5125 20.2493 20.7705 20.1793 20.9977 20.0473C21.225 19.9153 21.4136 19.7258 21.5447 19.498C21.6757 19.2701 21.7445 19.0118 21.7443 18.7489C21.7441 18.4861 21.6748 18.2279 21.5434 18.0002L13.2934 3.75024C13.1629 3.5223 12.9746 3.33287 12.7474 3.20113C12.5202 3.06938 12.2622 3 11.9996 3C11.737 3 11.479 3.06938 11.2518 3.20113C11.0246 3.33287 10.8363 3.5223 10.7058 3.75024V3.75024Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.375 16.875C12.375 17.0821 12.2071 17.25 12 17.25C11.7929 17.25 11.625 17.0821 11.625 16.875C11.625 16.6679 11.7929 16.5 12 16.5C12.2071 16.5 12.375 16.6679 12.375 16.875Z"
        fill={color}
        stroke={color}
        strokeWidth="1.5"
      />
    </svg>
  );
}
