import { Nullable } from '@sp/util/helpers';
import { getLinkHostName } from '@sp/util/links';

export enum SocialServices {
  instagram = 'instagram',
  facebook = 'facebook',
  snapchat = 'snapchat',
  twitter = 'twitter',
  tiktok = 'tiktok',
}

export const SocialServicesOrder = Object.keys(SocialServices);

const socialServiceDomains: Record<SocialServices, string[]> = {
  [SocialServices.instagram]: ['instagram.com'],
  [SocialServices.facebook]: ['facebook.com', 'fb.com'],
  [SocialServices.snapchat]: ['snapchat.com'],
  [SocialServices.twitter]: ['twitter.com'],
  [SocialServices.tiktok]: ['tiktok.com'],
};

export function linkResolver(url: string): Nullable<SocialServices> {
  const hostName = getLinkHostName(url);

  for (const [service, domains] of Object.entries(socialServiceDomains)) {
    for (const domain of domains) {
      if (hostName.match(new RegExp(`(^|\\.)${domain}$`))) {
        return SocialServices[service as SocialServices];
      }
    }
  }
  return null;
}
