import { RemirrorJSON } from '@remirror/core';
import LRUCache from 'lru-cache';
import { DeserializeError, deserializeMessageContent } from './content-serialization';
import { wrapTextIntoDoc } from './data-helpers';

const cache = new LRUCache<string, RemirrorJSON>({ max: 1000 });

export function parseMessageContent(content: string): RemirrorJSON {
  const cached = cache.get(content);
  if (cached != null) {
    return cached;
  }

  let doc: RemirrorJSON;
  try {
    doc = deserializeMessageContent(content);
  } catch (e) {
    if (e instanceof DeserializeError) {
      console.log(e.message);
    } else {
      throw e;
    }

    doc = wrapTextIntoDoc(content);
  }

  cache.set(content, doc);
  return doc;
}
