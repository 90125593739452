export const enum EnvMode {
  production = 'production',
  staging = 'staging',
  development = 'development',
}

export const APP_REVISION = import.meta.env.VITE_GIT_REVISION;
export const ENV_MODE = import.meta.env.MODE as EnvMode;

export const API_BASE_PATH = 'https://api.app.selfplus.com';
export const PRODUCTION_APP_HOSTNAME = 'app.selfplus.com';
export const SELFPLUS_WEBSITE_URL =
  import.meta.env.MODE === EnvMode.production
    ? ('https://selfplus.com' as const)
    : ('https://sales.st1.selfplus.com' as const);

export const IS_IOS = ['iPhone', 'iPad', 'iPod'].includes(window.navigator.platform);

export const IS_MOBILE =
  navigator.maxTouchPoints > 0 &&
  navigator.userAgent.search(
    /iOS|iPhone OS|Android|BlackBerry|BB10|Series ?[64]0|J2ME|MIDP|opera mini|opera mobi|mobi.+Gecko|Windows Phone/i,
  ) != -1;

export const STREAM_CHAT_API_KEY = import.meta.env.VITE_STREAM_CHAT_API_KEY;
if (STREAM_CHAT_API_KEY === undefined) throw new Error(`Missing env var VITE_STREAM_CHAT_API_KEY`);

export const XANO_DATASOURCE = import.meta.env.MODE === EnvMode.production ? ('live' as const) : ('test' as const);

export const SELFPLUS_ACCOUNT_URL = `${SELFPLUS_WEBSITE_URL}/account` as const;
export const SELFPLUS_DISCOVER_STATIC_URL = `${SELFPLUS_WEBSITE_URL}/app_discover_season_1/` as const;
