import { IOS_IN_APP_PURCHASE_MODEL } from '@sp/data/global';
import { ErrorMessage } from '@sp/ui/elements';
import { Modal } from '@sp/ui/modal';
import { StoreValue } from 'effector';
import { useStoreMap } from 'effector-react';
import { FC } from 'react';

const criticalErrors: readonly StoreValue<typeof IOS_IN_APP_PURCHASE_MODEL.$error>[] = [
  'inAppError',
  'busy',
  'failedToRegister',
  'mismatchingInAppPurchase',
  'invalidProductId',
];

export const InAppPurchaseFailedModal: FC = () => {
  const isOpened = useStoreMap(IOS_IN_APP_PURCHASE_MODEL.$error, error => criticalErrors.includes(error));

  return (
    <Modal isOpen={isOpened} onClose={IOS_IN_APP_PURCHASE_MODEL.reset} withBackdrop closeOnBackdropClick>
      <div className="text-center">
        <ErrorMessage>
          Sorry, could not make In-App Purchase.
          <br />
          Please, try again.
        </ErrorMessage>
      </div>
    </Modal>
  );
};
