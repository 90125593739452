import { isNotNullish, isNullish, switchMap } from '@sp/util/helpers';
import { ChatChannel, ChatEvent, ChatNonFormatMessage, formatChatMessage } from '@sp/util/stream-chat';
import { createEffect, createEvent, createStore, sample } from 'effector';
import { COMMUNITY_MODEL } from '../community-model';

const resetPosts = createEvent();

const $posts = createStore<Record<string, ChatNonFormatMessage>>({}).reset(resetPosts);

function convertDateToUnix(time?: string | null): number {
  return new Date(time ?? 0).getTime();
}

const $viewedPosts = $posts.map(posts =>
  Object.values(posts)
    .sort((a, b) => convertDateToUnix(a.pinned_at) - convertDateToUnix(b.pinned_at))
    // Нужно подменять created_at, т.к. на это поле завязаны UI компоненты списка сообщений чата.
    .map(m => formatChatMessage({ ...m, created_at: m.pinned_at ?? undefined })),
);

const getPosts = createEvent<ChatChannel | undefined>();

const channelEventFired = createEvent<ChatEvent>();

const getPostsFx = createEffect((channel: ChatChannel) => {
  return channel.getPinnedMessages({ limit: 1000 });
});

sample({
  source: getPosts,
  filter: isNotNullish,
  target: getPostsFx,
});

sample({
  source: getPostsFx.doneData,
  fn: data => {
    return data.messages.reduce<Record<string, ChatNonFormatMessage>>((acc, message) => {
      acc[message.id] = message;
      return acc;
    }, {});
  },
  target: $posts,
});

$posts.on(channelEventFired, (posts, event) => {
  const newPosts = { ...posts };
  if (isNullish(event.message)) return newPosts;
  if (event.message?.pinned && event.type != 'message.deleted') {
    newPosts[event.message?.id] = event.message;
  } else if (newPosts[event.message?.id]) {
    delete newPosts[event.message?.id];
  }
  return newPosts;
});

switchMap({
  source: COMMUNITY_MODEL.$chatModel,
  fn: instance =>
    instance?.pickEvent(
      'message.new',
      'message.updated',
      'message.deleted',
      'reaction.new',
      'reaction.updated',
      'reaction.deleted',
    ),
  target: channelEventFired,
});

export const FEED_MODEL = {
  getPosts,
  resetPosts,
  $viewedPosts,
};
