import { USER_MODEL } from '@sp/data/auth';
import { AlreadyPurchasedError, getPayment, initSpacePurchase, PaymentStatus, SpacePurchaseType } from '@sp/data/bif';
import { ANALYTICS } from '@sp/feature/analytics';
import { asyncWait, forwardMap, isNotNullish } from '@sp/util/helpers';
import { createDomain, createEffect, forward, merge, sample, split } from 'effector';

type InitPaymentPayload = {
  type: SpacePurchaseType;
  spaceId: number;
  title: string;
  creatorId: number;
  creatorName: string;
  price: number;
};

type PaymentInProgress = InitPaymentPayload & {
  paymentId?: string | null;
  error?: string;
  status: PaymentStatus;
};

type InitializedPaymentInProgress = PaymentInProgress & { paymentId: string };

function isPaymentInitialized(payment: PaymentInProgress): payment is InitializedPaymentInProgress {
  return isNotNullish(payment.paymentId);
}

type SkippedPayment = Omit<PaymentInProgress, 'paymentId'> & { paymentId: null };

function isPaymentNotNeeded(payment: PaymentInProgress): payment is SkippedPayment {
  return payment.paymentId === null && payment.status !== PaymentStatus.skipped;
}

const CHECK_PAYMENT_INTERVAL = 1000;

const domain = createDomain('purchase-domain');

const purchaseFailed = domain.createEvent<{ spaceId: number; error: string; reload?: boolean }>();
const initPurchaseStarted = domain.createEvent<InitPaymentPayload>();
const purchaseSucceeded = domain.createEvent<number>();
const purchaseRejected = domain.createEvent<{ spaceId: number; error: string }>();
const purchaseNeedToBeConfirmed = domain.createEvent<InitPaymentPayload>();
const purchaseConfirmed = domain.createEvent<number>();
const purchaseNotConfirmed = domain.createEvent<number>();
const purchaseWithoutCardAttached = domain.createEvent<InitPaymentPayload>();

const paymentsSkipped = domain.createEvent<SkippedPayment[]>();

const initPurchaseFx = domain.createEffect(({ spaceId, type }: InitPaymentPayload) => initSpacePurchase(spaceId, type));

const checkPaymentsFx = domain.createEffect<
  ReadonlyArray<InitializedPaymentInProgress>,
  ReadonlyArray<InitializedPaymentInProgress>
>((payments: ReadonlyArray<InitializedPaymentInProgress>) => {
  return Promise.all(
    payments.map(payment =>
      getPayment(payment.paymentId)
        .then(result => ({ ...payment, status: result.status, error: result.error }))
        .catch(() => ({
          ...payment,
          status: PaymentStatus.check_payment_error,
        })),
    ),
  );
});
const checkPaymentsTimeOutFx = domain.createEffect(() => asyncWait(CHECK_PAYMENT_INTERVAL));

const trackPurchaseFx = domain.createEffect((payment: InitializedPaymentInProgress) => {
  ANALYTICS.purchaseViewedTracked({
    'Transaction ID': payment.paymentId,
    'Creator Name': payment.creatorName,
    'Creator ID': payment.creatorId.toString(),
    'Space Name': payment.title,
    'Space ID': payment.spaceId.toString(),
    Value: (payment.price / 100).toString(),
    Source: '2',
  });
});

const $paymentsInProgress = domain
  .createStore<ReadonlyArray<PaymentInProgress>>([])
  .on(
    merge([
      purchaseRejected.map(({ spaceId }) => spaceId),
      purchaseNotConfirmed,
      purchaseWithoutCardAttached.map(({ spaceId }) => spaceId),
    ]),
    (state, spaceId) => state.filter(p => p.spaceId !== spaceId),
  )
  .on(initPurchaseStarted, (state, payload) =>
    state.some(item => item.spaceId === payload.spaceId)
      ? state
      : [
          ...state,
          {
            ...payload,
            status: PaymentStatus.processing,
          },
        ],
  )
  .on(initPurchaseFx.done, (state, { params, result: { paymentId } }) => {
    return state.map(item => (item.spaceId === params.spaceId ? { ...item, paymentId } : item));
  })
  .on(checkPaymentsFx.doneData, (state, results) => {
    return state
      .map(item => {
        const result = results.find(({ paymentId }) => paymentId === item.paymentId);
        return isNotNullish(result) ? result : item;
      })
      .filter(({ status }) => status === PaymentStatus.processing);
  })
  .on(paymentsSkipped, (state, skipped) => {
    return state.map(item => {
      const result = skipped.find(({ spaceId }) => spaceId === item.spaceId);
      return isNotNullish(result) ? { ...result, status: PaymentStatus.skipped } : item;
    });
  });
const $challengesIdsInProgress = $paymentsInProgress.map(items => items.map(({ spaceId }) => spaceId));

split({
  source: initPurchaseStarted,
  match: USER_MODEL.$user.map(user => (isNotNullish(user?.paymentMethod) ? 'canPay' : 'cantPay')),
  cases: {
    canPay: purchaseNeedToBeConfirmed,
    cantPay: purchaseWithoutCardAttached,
  },
});

sample({
  clock: purchaseConfirmed,
  source: $paymentsInProgress,
  filter: (payments, spaceId) => payments.some(p => p.spaceId === spaceId),
  fn: (payments, spaceId) => payments.find(p => p.spaceId === spaceId) as PaymentInProgress,
  target: initPurchaseFx,
});

forward({ from: checkPaymentsFx.finally, to: checkPaymentsTimeOutFx });

sample({
  clock: [checkPaymentsTimeOutFx.done, $paymentsInProgress.updates],
  source: {
    payments: $paymentsInProgress,
    isWaitingTimeout: checkPaymentsTimeOutFx.pending,
  },
  filter: ({ isWaitingTimeout, payments }) =>
    !isWaitingTimeout && payments.some(({ status }) => status === PaymentStatus.processing),
  fn: ({ payments }) => payments.filter(isPaymentInitialized),
  target: checkPaymentsFx,
});

const paymentsFailed = merge([
  initPurchaseFx.fail.map(({ params: { spaceId }, error }) => [
    { spaceId, error: error.message, reload: error instanceof AlreadyPurchasedError },
  ]),
  checkPaymentsFx.doneData.map(payments =>
    payments
      .filter(({ status }) => status !== PaymentStatus.processing && status !== PaymentStatus.succeeded)
      .map(({ spaceId, error }) => ({
        spaceId,
        error: error ?? 'Processing error',
      })),
  ),
]);

const paymentsSucceeded = checkPaymentsFx.doneData.filterMap(payments =>
  payments.filter(({ status }) => status === PaymentStatus.succeeded),
);

sample({
  clock: $paymentsInProgress.updates.filterMap(payments => payments.filter(isPaymentNotNeeded)),
  filter: skippedPayments => skippedPayments.length > 0,
  target: paymentsSkipped,
});

forwardMap({
  from: paymentsFailed,
  to: purchaseFailed,
});

forwardMap({
  from: merge([paymentsSucceeded, paymentsSkipped]).map(payments => payments.map(p => p.spaceId)),
  to: purchaseSucceeded,
});

forwardMap({
  from: paymentsSucceeded,
  to: trackPurchaseFx,
});

forward({
  from: purchaseFailed,
  to: purchaseRejected,
});

sample({
  clock: purchaseFailed.map(({ error, spaceId }) => ({ error, spaceId })),
  target: createEffect((v: unknown) => console.error('Checkout failed.', JSON.stringify(v))),
});

export const PURCHASE_MODEL = {
  initPurchaseStarted,
  $challengesIdsInProgress,
  purchaseFailed,
  purchaseSucceeded,
  purchaseNeedToBeConfirmed,
  purchaseConfirmed,
  purchaseNotConfirmed,
  purchaseRejected,
  purchaseWithoutCardAttached,
};
