import { USE_LONG_PRESS_OPTIONS } from '@sp/util/config';
import { cls, noop } from '@sp/util/helpers';
import { FC, ForwardedRef, forwardRef, HTMLAttributes } from 'react';
import { LongPressCallback, useLongPress } from 'use-long-press';

export const ChatMessageContainer = forwardRef(function ChatMessageContainer(
  {
    children,
    className,
    onLongPress,
    ...props
  }: Readonly<{ onLongPress?: LongPressCallback }> & Readonly<HTMLAttributes<HTMLDivElement>>,
  ref: ForwardedRef<HTMLDivElement>,
) {
  // TODO[Dmitriy Teplov] extract shared config.
  const bindLongPress = useLongPress(onLongPress ?? noop, USE_LONG_PRESS_OPTIONS);

  const containerClassName = cls('flex flex-row w-full', className);

  if (onLongPress) {
    return (
      <div
        ref={ref}
        className={containerClassName}
        {...props}
        role="button"
        onContextMenu={e => e.preventDefault()}
        {...bindLongPress()}
      >
        {children}
      </div>
    );
  } else {
    return (
      <div ref={ref} className={containerClassName} {...props}>
        {children}
      </div>
    );
  }
});

export const ChatMessageLeftContainer: FC = ({ children }) => (
  <div className="flex flex-col gap-2 items-center mr-3 -mb-4 flex-none">{children}</div>
);
export const ChatMessageRightContainer: FC = ({ children }) => <div className="flex-grow min-w-0">{children}</div>;
export const ChatMessageBodyContainer: FC = ({ children }) => <div className="py-1">{children}</div>;
export const ChatMessageFooterContainer: FC = ({ children }) => <div className="pt-1.5">{children}</div>;
