import { generateUniqueString } from '@sp/util/helpers';
import { useEffect, useRef } from 'react';
import { NAVIGATE_BACK_MODEL } from './navigate-back-model';

export function useCloseModalOnBack({
  isOpen,
  onCloseCallback,
}: {
  isOpen: boolean;
  onCloseCallback: VoidFunction;
}): void {
  const idRef = useRef(generateUniqueString());
  useEffect(() => {
    const { unsubscribe } = NAVIGATE_BACK_MODEL.modalClosed.watch(id => id === idRef.current && onCloseCallback());
    if (isOpen) {
      NAVIGATE_BACK_MODEL.setModalOpened(idRef.current);
    } else {
      NAVIGATE_BACK_MODEL.setModalClosed(idRef.current);
    }
    return () => {
      unsubscribe();
    };
  }, [isOpen, onCloseCallback]);
}
