export function generateUniqueString(): string {
  return Math.random().toString(36).substring(2, 9);
}

export function coerceNullableString(str?: string | null): string | null {
  if (str == null) {
    return null;
  }

  return str.length === 0 ? null : str;
}
