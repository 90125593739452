import { CreatorInfo, CreatorSpace, loadCreatorById } from '@sp/data/bif';
import { isNotNullish, Nullable } from '@sp/util/helpers';
import { createDomain, sample } from 'effector';

const creatorDomain = createDomain();

const creatorReset = creatorDomain.createEvent();

const activeCreatorIdSet = creatorDomain.createEvent<number>();

const $activeCreatorId = creatorDomain.createStore<Nullable<number>>(null).reset(creatorReset);

const $creator = creatorDomain.createStore<Nullable<CreatorInfo>>(null).reset(creatorReset);

const $challengesMap = creatorDomain.createStore<Record<number, CreatorSpace>>({}).reset(creatorReset);

const loadCreatorFx = creatorDomain.createEffect((id: number) => {
  return loadCreatorById(id);
});

sample({ source: activeCreatorIdSet, target: $activeCreatorId });
sample({ source: $activeCreatorId, filter: isNotNullish, target: loadCreatorFx });
sample({ source: loadCreatorFx.doneData, target: $creator });
sample({
  source: loadCreatorFx.doneData,
  target: $challengesMap,
  fn: ({ spaces }) => spaces.reduce((acc, ch) => ({ ...acc, [ch.id]: ch }), {}),
});
const $creatorLoading = loadCreatorFx.pending;

export const CREATOR_INFO_MODEL = { $creator, $creatorLoading, activeCreatorIdSet, creatorReset, $challengesMap };
