import { OwnedLivestreamSpace, SPACE_TYPE } from '@sp/data/bif';
import { Button, ErrorMessage, SpinnerPage } from '@sp/ui/elements';
import { parseParamInteger, ROUTES } from '@sp/util/router';
import { format } from 'date-fns';
import { useEvent, useStore } from 'effector-react';
import { useCallback, useMemo, VFC } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { LiveSpaceForm } from './live-space-form';
import { LIVE_SPACE_RESOURCE_MODEL, UPDATE_LIVE_MODEL, UpdateLiveGate } from './update-live-model';

const ErrorPage: VFC = () => {
  const reload = useCallback(() => window.location.reload(), []);

  return (
    <div className="h-full w-full flex flex-col justify-center items-center p-8 gap-8">
      <ErrorMessage>Something went wrong.</ErrorMessage>
      <Button onClick={reload} block color="black">
        Try again
      </Button>
    </div>
  );
};

export const UpdateLiveScreen: VFC = () => {
  const params = useParams<typeof ROUTES.STUDIO.SPACE_ID_PARAM>();
  const spaceId = parseParamInteger(params.spaceId);

  const space = useStore(LIVE_SPACE_RESOURCE_MODEL.$data);
  const loadingError = useStore(LIVE_SPACE_RESOURCE_MODEL.$loadingError);
  const invalidSpaceError = useStore(UPDATE_LIVE_MODEL.$invalidSpaceError);

  if (isNaN(spaceId)) {
    console.error(`Invalid space ID: ${JSON.stringify(params.spaceId)}`);
    return <Navigate to={ROUTES.PROFILE_URL} />;
  }

  if (invalidSpaceError) {
    console.error(`Space ${spaceId} is not of type "${SPACE_TYPE.live}".`, invalidSpaceError.space);
    return <Navigate to={ROUTES.PROFILE_URL} />;
  }

  if (loadingError) return <ErrorPage />;

  return (
    <>
      <UpdateLiveGate spaceId={spaceId} />
      {space ? (
        <div className="h-full overflow-y-auto overflow-x-hidden mobile-pan">
          <UpdateLiveForm space={space}></UpdateLiveForm>
        </div>
      ) : (
        <SpinnerPage />
      )}
    </>
  );
};

const UpdateLiveForm: VFC<Readonly<{ space: OwnedLivestreamSpace }>> = ({ space }) => {
  const updatedAt = useMemo(() => format(space.updated_at ?? 0, `yyyy-MM-dd HH:mm:ss`), [space.updated_at]);

  const isLoading = useStore(UPDATE_LIVE_MODEL.$isLoading);
  const isPublishing = useStore(UPDATE_LIVE_MODEL.$isPublishing);
  const isPublished = useStore(UPDATE_LIVE_MODEL.$isPublished);

  const handlePublish = useEvent(UPDATE_LIVE_MODEL.publish);

  return (
    <>
      <div className="m-4 flex flex-row justify-between items-start">
        <span className="mr-2">
          Space ID: <strong>{space.id}</strong>
          <br />
          Creator:{' '}
          <strong>
            {space.owner.name} ({space.owner.id})
          </strong>
          <br />
          Last Update: <strong>{updatedAt}</strong>
        </span>
        {isPublished ? (
          <Button color="primary" disabled>
            Published
          </Button>
        ) : (
          <Button color="primary" loading={isPublishing} disableOnLoad onClick={handlePublish}>
            Publish
          </Button>
        )}
      </div>
      <hr />
      <LiveSpaceForm canUploadRecording={true} isLoading={isLoading} />
    </>
  );
};
