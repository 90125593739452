import { ChatEvent, ChatMessageEvent, ChatMessageEventTypes, ChatMessageMentionEvent, MeUser } from '@sp/data/bif';
import { ChatEvent as StreamChatEvent } from '@sp/util/stream-chat';
import getTime from 'date-fns/getTime';

export const getItemByPriority = <T, P>(arr: T[], priorities: P[], comparator: (item: T, p: P) => boolean): T => {
  if (arr.length === 1) return arr[0];
  for (let i = 0; i < priorities.length; i++) {
    const res = arr.find(item => comparator(item, priorities[i]));
    if (res) return res;
  }

  return arr[0];
};

export const eventPriority = [
  ChatMessageEventTypes.reply,
  ChatMessageEventTypes.mentions,
  ChatMessageEventTypes.reactions,
];
export const compareByPriority = (event: ChatMessageEvent, type: ChatMessageEventTypes) => event.type === type;

export function parseToChatEvent(data: StreamChatEvent, meUser: MeUser): ChatEvent | undefined {
  if (!data.message || !data.created_at) return;

  let eventType = ChatMessageEventTypes.reactions;
  if (isReply(data, meUser)) {
    eventType = ChatMessageEventTypes.reply;
  } else if (isMention(data, meUser)) {
    eventType = ChatMessageEventTypes.mentions;
  }

  const reactionType = data.reaction?.type;

  let event: ChatMessageEvent;

  if (eventType === ChatMessageEventTypes.reactions && reactionType) {
    event = {
      createdAt: getTime(new Date(data.created_at)),
      type: eventType,
      reactionType,
    };
  } else {
    event = {
      createdAt: getTime(new Date(data.created_at)),
      type: eventType,
    } as ChatMessageMentionEvent;
  }

  return {
    userId: Number(data.user?.id),
    chatId: Number(data.channel_id),
    messageId: data.message.id,
    createdAt: getTime(new Date(data.created_at)),
    message: data.message,
    deleted: data.type === 'message.deleted',
    events: event,
  };
}

export function isReaction(event: StreamChatEvent, user: MeUser) {
  return event.type === 'reaction.new' && event.message?.user?.id === String(user.id);
}

export function isMention(event: StreamChatEvent, user: MeUser) {
  return (event.message?.mentioned_users || []).some(({ id }) => id === String(user.id));
}

export function isReply(event: StreamChatEvent, user: MeUser) {
  return event.message?.quoted_message?.user?.id === String(user.id) && event.message.quoted_message_id != null;
}

export function notMyEvent(event: StreamChatEvent, user: MeUser) {
  return event.user?.id !== String(user.id);
}

export function canHaveSideEffect(event: StreamChatEvent) {
  const types = ['message.deleted', 'message.updated', 'reaction.deleted', 'reaction.new'];
  return types.includes(event.type);
}
