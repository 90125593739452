import { cls, useContinuousEventStatus } from '@sp/util/helpers';
import { FC } from 'react';

export const LiveIndicator: FC<{
  startAt: number;
  duration: number;
}> = ({ startAt, duration }) => {
  const { isEnded, isUpcoming } = useContinuousEventStatus({ startAt, duration });
  return (
    <div
      className={cls(
        'tracking-wide px-2 py-1 rounded font-semibold text-xs h-fit self-end flex items-center gap-1 flex-row justify-center text-center',
        !isUpcoming && !isEnded ? 'text-active font-semibold bg-[#FF825C]' : 'text-primary bg-primary/80',
      )}
    >
      {isEnded && (
        <>
          <span className="font-bold">•</span> RECORDING
        </>
      )}
      {isUpcoming && (
        <>
          <span className="font-bold">•</span> SCHEDULED
        </>
      )}
      {!isUpcoming && !isEnded && (
        <>
          <span className="font-bold">•</span> LIVE NOW
        </>
      )}
    </div>
  );
};
