import { ReactElement } from 'react';
import { ICON_DEFAULTS, IconProps } from './types';

export function IconFileZip({
  size = ICON_DEFAULTS.size,
  color = ICON_DEFAULTS.color,
  className = ICON_DEFAULTS.className,
}: IconProps): ReactElement {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M3.75 10V3.125C3.75 2.95924 3.81585 2.80027 3.93306 2.68306C4.05027 2.56585 4.20924 2.5 4.375 2.5H11.875L16.25 6.875V10"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M11.875 2.5V6.875H16.25" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M13.125 15.625H14.375C14.7065 15.625 15.0245 15.4933 15.2589 15.2589C15.4933 15.0245 15.625 14.7065 15.625 14.375C15.625 14.0435 15.4933 13.7255 15.2589 13.4911C15.0245 13.2567 14.7065 13.125 14.375 13.125H13.125V16.875"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M10 13.125V16.875" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M4.375 13.125H6.875L4.375 16.875H6.875" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
