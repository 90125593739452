import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export function useScrollToTopOnNavigate(): void {
  const location = useLocation();

  useEffect(() => {
    document.querySelectorAll('.scroll-container').forEach(scrollContainerElement => {
      scrollContainerElement.scrollTo({ top: 0 });
    });
  }, [location]);
}
