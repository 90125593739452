import { isNotNullish } from './ts-helpers';

type ClassName = string | undefined | null | false;

function filterPositive(className: ClassName): className is string {
  return isNotNullish(className) && className !== false;
}

// Sanitize className string from line breaks and extra spaces.
export const cls = (...className: ClassName[]): string =>
  className.filter(filterPositive).join(' ').replace(/\s+/gm, ' ').trim();
