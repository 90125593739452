import { Button, Input } from '@sp/ui/elements';
import { IconPlus, IconTrashSimple } from '@sp/ui/icons';
import { add, addDays, format, startOfDay, startOfToday } from 'date-fns';
import { useStore } from 'effector-react';
import { FC } from 'react';
import { CHALLENGE_FORM_MODEL } from './challenge-form-model';
import { ChallengePostsList } from './challenge-posts-list';
import { formatTimeForInput, isNotSeed } from './utils';

export const StepName: FC = ({ children }) => {
  return <h3 className="text-center m-0">{children}</h3>;
};

export const StepLayout: FC = ({ children }) => {
  return (
    <div className="w-[375px] flex-none flex flex-col gap-4 shadow-modal p-4 bg-primary shrink-0 scroll-mx-6 snap-start">
      {children}
    </div>
  );
};

export const ChallengeStepsForm: FC = () => {
  const steps = useStore(CHALLENGE_FORM_MODEL.$steps);
  const startAt = useStore(CHALLENGE_FORM_MODEL.$startAt);
  const finishAt = useStore(CHALLENGE_FORM_MODEL.$finishAt);
  const isPublished = useStore(CHALLENGE_FORM_MODEL.$isChallengePublished);
  return (
    <div className="flex gap-6 items-stretch flex-nowrap -mx-6 p-6 overflow-x-scroll snap-x snap-mandatory">
      <StepLayout>
        <StepName>Getting started</StepName>
        <hr />
        <ChallengePostsList stepKey="intro" />
      </StepLayout>

      {steps.map((step, index) => (
        <StepLayout key={index}>
          <div>
            <header className="flex items-center gap-2">
              <StepName>Day {index + 1}</StepName>
              <div className="flex-grow" />
              <Button disabled={isPublished} onClick={() => CHALLENGE_FORM_MODEL.stepAdded(index)}>
                <IconPlus size={16} />
                &nbsp;next day
              </Button>
              <Button
                disabled={isPublished || steps.length <= 1}
                onClick={() => CHALLENGE_FORM_MODEL.stepRemoved(index)}
              >
                <IconTrashSimple size={24} />
              </Button>
            </header>
            <span className="text-secondary text-xs">{format(addDays(startAt, index), 'do MMM')}</span>
          </div>

          <div>
            <label className="text-xs text-secondary pl-3">Step title</label>
            <Input
              placeholder="Step title"
              disabled={isPublished}
              value={step.title}
              onChange={({ target }) =>
                CHALLENGE_FORM_MODEL.stepTitleChanged({ stepIndex: index, title: target.value })
              }
              block
            />
          </div>

          <div>
            <label className="text-xs text-secondary pl-3">Publish time</label>
            <Input
              placeholder="Publish time"
              disabled={isPublished}
              type="time"
              value={formatTimeForInput(add(startOfToday(), step.startTime))}
              onChange={({ target }) =>
                CHALLENGE_FORM_MODEL.stepStartTimeChanged({
                  stepIndex: index,
                  startTime: { hours: Number(target.value.split(':')[0]), minutes: Number(target.value.split(':')[1]) },
                })
              }
              block
            />
          </div>

          <div>
            <label className="text-xs text-secondary pl-3">Estimated effort in minutes (set 0 to disable)</label>
            <Input
              placeholder="Estimated effort"
              disabled={isPublished}
              type="number"
              min={0}
              step={5}
              value={step.estimatedEffort}
              onChange={({ target }) =>
                CHALLENGE_FORM_MODEL.stepEstimatedEffortChanged({
                  stepIndex: index,
                  estimatedEffort: Number(target.value),
                })
              }
              block
            />
          </div>

          <div>
            <Input
              type="checkbox"
              disabled={isPublished}
              checked={step.isSignificant}
              onChange={({ target }) =>
                CHALLENGE_FORM_MODEL.stepIsSignificantChanged({
                  stepIndex: index,
                  isSignificant: target.checked,
                })
              }
            />
            <label className="text-xs text-secondary pl-3">Is Significant</label>
          </div>

          <hr />

          {isNotSeed(step) ? <ChallengePostsList stepKey={step.id} /> : null}
        </StepLayout>
      ))}

      <StepLayout>
        <div>
          <StepName>Finishing line</StepName>
          <span className="text-secondary text-xs">{format(finishAt, 'do MMM')}</span>
        </div>

        <div>
          <label className="text-xs text-secondary pl-3">Publish time</label>
          <Input
            placeholder="Start time"
            disabled={isPublished}
            type="time"
            value={formatTimeForInput(finishAt)}
            onChange={({ target }) =>
              CHALLENGE_FORM_MODEL.finishAtChanged(
                add(startOfDay(finishAt), {
                  hours: Number(target.value.split(':')[0]),
                  minutes: Number(target.value.split(':')[1]),
                }).getTime(),
              )
            }
            block
          />
        </div>

        <hr />
        <ChallengePostsList stepKey="finish" />
      </StepLayout>

      <StepLayout>
        <StepName>Deferred messages</StepName>
        <hr />
        <ChallengePostsList stepKey="deferred" />
      </StepLayout>
    </div>
  );
};
