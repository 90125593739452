import { cls, noop } from '@sp/util/helpers';
import { FC } from 'react';
import { Tab } from './types';

const TabNavButton: FC<{
  tab: Tab;
  onClick: (key: string) => void;
  width: string;
  activeKey: string;
  maxWidth: string | number;
  animate?: boolean;
  onAnimationEnd?: () => void;
}> = ({ tab, onClick, width, activeKey, maxWidth, animate = false, onAnimationEnd = noop }) => {
  const color = activeKey === tab.key ? 'text-current' : 'text-secondary';

  const handleClick = () => {
    onClick(tab.key);
  };
  return (
    <button
      type="button"
      onClick={handleClick}
      className={`py-2 ${color} !font-medium animated-tab ${animate ? 'animated-tab-active' : ''}`}
      onAnimationEnd={onAnimationEnd}
      key={tab.key}
      style={{ width, maxWidth }}
    >
      {tab.title}
    </button>
  );
};

export const TabsNavBar: FC<{
  tabs: Tab[];
  activeKey: string;
  onClick: (key: string) => void;
  maxTabWidth: string | number;
  activeTabTextColor: string; // Color token
  sticky: boolean;
  animateTab?: string;
  onAnimationEnd?: () => void;
}> = ({ tabs, activeKey, onClick, maxTabWidth: maxWidth, activeTabTextColor, sticky, animateTab, onAnimationEnd }) => {
  const width = `${100 / tabs.length}%`;
  const activeIndex = tabs.findIndex(tab => activeKey === tab.key);

  return (
    <div
      className={cls(
        'flex items-center bg-primary border-b border-gray-100',
        activeTabTextColor,
        sticky ? 'sticky top-0 z-10' : 'relative',
      )}
    >
      {tabs.map(tab => {
        return (
          <TabNavButton
            key={tab.key}
            tab={tab}
            onClick={onClick}
            width={width}
            maxWidth={maxWidth}
            activeKey={activeKey}
            animate={animateTab === tab.key}
            onAnimationEnd={onAnimationEnd}
          />
        );
      })}
      <div
        style={{
          transform: `translateX(${activeIndex * 100}%) translateY(1px)`,
          width,
          maxWidth,
          height: '2px',
          borderTopLeftRadius: '2px',
          borderTopRightRadius: '2px',
        }}
        className="absolute bg-black bottom-0 left-0 transition-transform"
      ></div>
    </div>
  );
};
