import pako from 'pako';

/**
 * This module uses zlib for (de)compression.
 * The resulting binary data is encoded to base64 for safe transport.
 * This is a pretty standard algorithm, you can use a website such as
 * https://codebeautify.org/zlib-decompress-online
 * to decompress the data manually.
 */

export function compress(data: string): string {
  const binData = pako.deflate(data, { level: 6 });
  const charData = [...binData];
  const strData = charData.map(i => String.fromCharCode(i)).join('');
  const b64Data = window.btoa(strData);
  return b64Data;
}

export function decompress(b64Data: string): string {
  const strData = window.atob(b64Data);
  const charData = Array.from(strData, x => x.charCodeAt(0));
  const binData = new Uint8Array(charData);
  const data = pako.inflate(binData, { to: 'string' });
  return data;
}
