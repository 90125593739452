import { ChatNonFormatMessage } from '@sp/util/stream-chat';

export type UploadedFile = {
  id: number;
  url: string;
};

export interface ChallengeSpaceConfig {
  readonly challenge: {
    readonly finishTimestamp: number;
    readonly offerDescription: string | null;
    readonly offerSummary: string | null;
    readonly isProgram: boolean;
  };
}

export enum ChallengePostType {
  step = 'step',
  intro = 'intro',
  finish = 'finish',
}

type ChallengeScheduledPost = {
  readonly id: number;
  readonly spaceId: number;
  readonly order: number;
  readonly isDraft: boolean;
  readonly content: Pick<ChatNonFormatMessage, 'text' | 'html' | 'attachments' | 'user'>;
} & (
  | {
      readonly type: ChallengePostType.step;
      readonly stepId: number;
    }
  | {
      readonly type: ChallengePostType.finish | ChallengePostType.intro;
    }
);

export type ChallengePublishedPost = ChallengeScheduledPost & {
  readonly publishedAt: number;
  readonly gsMessageId: string;
  readonly gsMessage: ChatNonFormatMessage;
};

export type ChallengePost = ChallengeScheduledPost | ChallengePublishedPost;

export interface ChallengeSpaceStep {
  readonly id: number;
  readonly startAt: number;
  readonly title: string;
  readonly estimatedEffort: number;
  readonly posts: ReadonlyArray<ChallengePost>;
  readonly isSignificant: boolean;
  readonly result: {
    readonly isCompleted: boolean;
    readonly isSkipped?: boolean;
    readonly rate?: number;
    readonly message?: string;
  };
}

export interface ChallengeSpaceMeta {
  readonly steps: ReadonlyArray<ChallengeSpaceStep>;
  readonly intro: { readonly posts: ReadonlyArray<ChallengePost> };
  readonly finish: { readonly posts: ReadonlyArray<ChallengePost> };
}

export interface SpaceMember {
  readonly id: number;
  readonly slug: string;
  readonly name: string;
  readonly isTrial: boolean;
  readonly avatar: {
    readonly url: string | null;
  } | null;
}

export type LiveSpaceTestimonial = Readonly<{
  title?: string;
  authorName: string;
  review: string;
  avatar: UploadedFile | null;
}>;

export type LiveSpaceConfig = Readonly<{
  live: Readonly<{
    startAt: number; // unix timestamp
    duration: number; // duration in minutes
    linkToZoom: null | string;
    requirements?: string;
    creatorDescription: string;
    description: string;
    record: null | {
      video: UploadedFile;
      cover: UploadedFile | null;
      meta: { duration: number };
    };
    testimonials: LiveSpaceTestimonial[];
    readonly offerDescription: string | null;
    readonly offerSummary: string | null;
  }>;
}>;

export enum UpdateFrequency {
  daily = 'daily',
  twoThreePerWeek = 'twoThreePerWeek',
  weekly = 'weekly',
  twoThreePerMonth = 'twoThreePerMonth',
  occasionally = 'occasionally',
}

export type ChannelSpaceConfig = Readonly<{
  channel: {
    creatorDescription: string;
    description: string;
    targetAudience: string;
    contentFormat: string;
    updateFrequency: UpdateFrequency;
  };
}>;

export type CommunitySpaceConfig = Readonly<{
  //TODO
}>;

export type CommunityLive = {
  startAt: number; // unix timestamp
  duration: number; // duration in minutes
  linkToZoom: null | string;
  requirements?: string;
  creatorDescription: string;
  description: string;
  record: null | {
    video: UploadedFile;
    cover: UploadedFile | null;
    meta: { duration: number };
  };
  readonly offerDescription: string;
  readonly offerSummary: string;
};
