import { ReactElement } from 'react';
import { ICON_DEFAULTS, IconProps } from './types';

export function IconUserCirclePlus({
  size = ICON_DEFAULTS.size,
  color = ICON_DEFAULTS.color,
  className = ICON_DEFAULTS.className,
}: IconProps): ReactElement {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      className={className}
    >
      <path d="M16.5 5.25H21" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M18.75 3V7.5" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M20.8875 10.5844C20.9633 11.0524 21.0009 11.5259 21 12C21 13.78 20.4722 15.5201 19.4832 17.0001C18.4943 18.4802 17.0887 19.6337 15.4442 20.3149C13.7996 20.9961 11.99 21.1743 10.2442 20.8271C8.49836 20.4798 6.89472 19.6226 5.63604 18.364C4.37737 17.1053 3.5202 15.5017 3.17294 13.7558C2.82567 12.01 3.0039 10.2004 3.68509 8.55587C4.36628 6.91133 5.51983 5.50572 6.99987 4.51679C8.47991 3.52786 10.22 3.00002 12 3.00002C12.4741 2.99909 12.9476 3.03671 13.4156 3.11252"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.75977 11.6367C9.38109 11.6367 9.88477 11.133 9.88477 10.5117C9.88477 9.8904 9.38109 9.38672 8.75977 9.38672C8.13845 9.38672 7.63477 9.8904 7.63477 10.5117C7.63477 11.133 8.13845 11.6367 8.75977 11.6367Z"
        fill={color}
      />
      <path
        d="M15.5098 11.6367C16.1311 11.6367 16.6348 11.133 16.6348 10.5117C16.6348 9.8904 16.1311 9.38672 15.5098 9.38672C14.8884 9.38672 14.3848 9.8904 14.3848 10.5117C14.3848 11.133 14.8884 11.6367 15.5098 11.6367Z"
        fill={color}
      />
      <path
        d="M16.0324 14.6367C15.6359 15.3198 15.067 15.8867 14.3826 16.2808C13.6982 16.6749 12.9222 16.8824 12.1324 16.8824C11.3426 16.8824 10.5667 16.6749 9.88226 16.2808C9.19782 15.8867 8.6289 15.3198 8.23242 14.6367"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
