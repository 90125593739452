import { IconArchive, IconHourglass } from '@sp/ui/icons';
import { cls, coerceNullableString, isNullish } from '@sp/util/helpers';
import { ChatFormatMessage } from '@sp/util/stream-chat';
import { renderContentToText } from '@sp/util/text-editor';
import { endOfToday, format, isThisMinute, isToday, isWithinInterval, startOfWeek } from 'date-fns';
import { FC, useMemo, VFC } from 'react';
import { EMPTY_MESSAGE_CONTENT, getAttachmentLabel, getChallengeJourneyMessageLabel } from './message-format';

function isWithinCurrentWeek(date: Date): boolean {
  return isWithinInterval(date, { start: startOfWeek(Date.now()), end: endOfToday() });
}

function formatDistanceSince(date: Date): string {
  if (isThisMinute(date)) {
    return `now`;
  }

  // 2:04 PM
  if (isToday(date)) {
    return format(date, `h:mm a`);
  }

  // Mon
  if (isWithinCurrentWeek(date)) {
    return format(date, `EEE`);
  }

  // Oct, 27
  return format(date, `MMM, d`);
}

// TODO[Dmitriy Teplov] update value once a minute.
export function useLastActivityDate(date: number | null): string {
  if (isNullish(date)) {
    return '';
  }

  return formatDistanceSince(new Date(date));
}

export const HomeTeaserContainer: FC = ({ children }) => (
  <div className="w-full flex flex-row items-center">{children}</div>
);

export const UnreadCountBadge: FC<Readonly<{ unreadCount: number }>> = ({ unreadCount }) => (
  <div className="min-w-[1.25rem] rounded-[5px] bg-new-message flex items-center justify-center py-1 px-1.5 text-xs leading-3 text-active">
    {unreadCount}
  </div>
);

export const TeaserLeftColumnContainer: FC<Readonly<{ isArchived: boolean }>> = ({ isArchived, children }) => (
  <div className={cls('flex-none pr-3', isArchived && 'opacity-70')}>{children}</div>
);

export const TeaserCenterColumnContainer: FC = ({ children }) => (
  <div className="grow flex flex-col items-start justify-center min-w-0 overflow-hidden">{children}</div>
);

export const TeaserTitle: FC<Readonly<{ isArchived: boolean; isTrailer?: boolean }>> = ({
  isArchived,
  isTrailer = false,
  children,
}) => (
  <div
    className={cls(
      'flex-none font-semibold max-w-full overflow-hidden whitespace-nowrap overflow-ellipsis',
      !isArchived ? 'text-primary' : 'text-secondary',
    )}
  >
    {isArchived ? (
      <IconArchive size={16} className="inline-block mr-1" />
    ) : (
      isTrailer && <IconHourglass size={16} className="inline-block mr-1 mb-[3px]" />
    )}
    {children}
  </div>
);

export const TeaserRightColumn: FC<Readonly<{ date: number | null; count: number }>> = ({ date, count }) => {
  const formattedDate = useLastActivityDate(date);

  return (
    <div className="flex-none flex flex-col items-end justify-between w-[3.5rem]">
      <div className="text-secondary text-xs">{formattedDate}</div>
      <div>{count > 0 && <UnreadCountBadge unreadCount={count} />}</div>
    </div>
  );
};

export const MessagePreview: VFC<
  Readonly<{
    message: ChatFormatMessage;
    multiline?: boolean;
    showName?: boolean;
  }>
> = ({ message, multiline = false, showName = true }) => {
  const renderedText = useMemo(() => (message.text ? renderContentToText(message.text) : null), [message.text]);

  const attachment = message.attachments?.[0]?.selfplusAttachment;

  const content = useMemo(() => {
    if (coerceNullableString(renderedText) !== null) {
      return renderedText;
    } else if (attachment != null) {
      return getAttachmentLabel(attachment);
    } else if (message.selfplusPostMeta != null) {
      const label = getChallengeJourneyMessageLabel(message.selfplusPostMeta);
      if (label !== null) return label;
    } else if (message.selfplusMeta != null) {
      return 'We want to know your opinion!';
    }

    return EMPTY_MESSAGE_CONTENT;
  }, [attachment, message.selfplusMeta, message.selfplusPostMeta, renderedText]);

  return (
    <div
      className={cls(
        'max-w-full overflow-hidden',
        multiline ? 'overflow-ellipsis-multiline' : 'overflow-ellipsis whitespace-nowrap',
      )}
    >
      {showName ? <span className="font-semibold">{message.user?.name}: </span> : null}
      <span>{content}</span>
    </div>
  );
};
