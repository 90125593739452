import { MessageUpdate } from '@sp/feature/stream-chat';
import { ChatMessageViewAttachment, isUploadable, UploadingStatus } from '@sp/util/chat-attachments';
import { assert, isNotNullish, useDeepCompareMemoize } from '@sp/util/helpers';
import {
  ATTACHMENT_TYPE,
  ChatFormatMessage,
  ChatMessage,
  formatChatMessage,
  LinkAttachment,
  SelfplusExtraMessageFields,
  unformatChatMessage,
} from '@sp/util/stream-chat';
import { isEmptyDoc, parseLinksFromDoc, parseMessageContent, parseUserMentionsFromDoc } from '@sp/util/text-editor';
import isEmpty from 'lodash/isEmpty';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { getDraft, resetDraft, updateDraft } from './message-drafts';
import { isExtraMessageField, mapAttachmentsToClient, mapAttachmentsToStream } from './message-helpers';
import { prepareAttachmentsForLocalSave, useAttachmentManager } from './use-attachment-manager';

export type MessageFormModel = ReturnType<typeof useMessageForm>;

const MAX_ATTACHMENT_COUNT = 10;

export const useMessageForm = (
  formId: string,
  data?: {
    defaultExtraFields?: SelfplusExtraMessageFields;
    defaultFields?: Partial<ChatFormatMessage>;
  },
) => {
  const draft = useMemo(() => getDraft(formId, data), [formId, data]);
  const [draftId, setDraftId] = useState(draft.draftId);
  const [text, setText] = useState(draft.text);
  const [pinned, setPinned] = useState(draft.pinned);
  const [editedMessage, setEditedMessage] = useState<ChatFormatMessage | null>(
    draft.editedMessage ? formatChatMessage(draft.editedMessage) : null,
  );
  const [quotedMessage, setQuotedMessage] = useState<ChatFormatMessage | null>(
    draft.quotedMessage ? formatChatMessage(draft.quotedMessage) : null,
  );
  const attachmentManager = useAttachmentManager(draft.attachments);
  const [excludedUrls, setExcludedUrls] = useState<{ [url: string]: true | undefined }>({});

  const [meta, setMeta] = useState<ChatFormatMessage['selfplusMessageMeta']>(draft.meta);
  const setMetaField = useCallback(
    <K extends keyof Required<ChatFormatMessage>['selfplusMessageMeta']>(
      field: K,
      value: Required<ChatFormatMessage>['selfplusMessageMeta'][K],
    ) => setMeta({ ...meta, [field]: value }),
    [meta],
  );

  const [extraFields, setExtraFields] = useState<Partial<SelfplusExtraMessageFields>>(draft.extraFields);
  const setExtraField = useCallback(
    <K extends keyof Required<SelfplusExtraMessageFields>>(field: K, value: Required<SelfplusExtraMessageFields>[K]) =>
      setExtraFields({ ...extraFields, [field]: value }),
    [extraFields],
  );

  const [mentionedUserIds, setMentionedUserIds] = useState<string[]>(draft.mentionedUserIds);

  const editedMessageMentionedUserIds = useDeepCompareMemoize(
    useMemo(() => editedMessage?.mentioned_users?.map(u => u.id) ?? [], [editedMessage]),
  );

  useEffect(() => {
    setMentionedUserIds(editedMessageMentionedUserIds);
  }, [editedMessageMentionedUserIds]);

  // Draft sync
  useEffect(() => {
    if (isNotNullish(editedMessage)) updateDraft(formId, { editedMessage: unformatChatMessage(editedMessage) });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editedMessage]);

  useEffect(() => {
    updateDraft(formId, {
      quotedMessage: quotedMessage ? unformatChatMessage(quotedMessage) : null,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quotedMessage]);

  useEffect(() => {
    updateDraft(formId, { text });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [text]);

  useEffect(() => {
    updateDraft(formId, {
      attachments: prepareAttachmentsForLocalSave(attachmentManager.attachments),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attachmentManager.attachments]);

  useEffect(() => {
    updateDraft(formId, { pinned });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pinned]);

  useEffect(() => {
    updateDraft(formId, { extraFields });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [extraFields]);

  useEffect(() => {
    updateDraft(formId, { meta });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meta]);

  useEffect(() => {
    updateDraft(formId, { mentionedUserIds });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mentionedUserIds]);

  const reset = useCallback(() => {
    const newDraft = resetDraft(formId, data);
    setText(newDraft.text);
    attachmentManager.resetAttachments(newDraft.attachments);
    setPinned(newDraft.pinned);
    setMeta(newDraft.meta);
    setExtraFields(newDraft.extraFields);
    setMentionedUserIds(newDraft.mentionedUserIds);
    setExcludedUrls({});
    setQuotedMessage(newDraft.quotedMessage ? formatChatMessage(newDraft.quotedMessage) : null);
    setEditedMessage(newDraft.editedMessage ? formatChatMessage(newDraft.editedMessage) : null);
  }, [attachmentManager, data, formId]);
  // End draft sync

  useEffect(() => {
    if (editedMessage != null) {
      setText(editedMessage.text ?? '');
      attachmentManager.resetAttachments(mapAttachmentsToClient(editedMessage.attachments ?? []));

      if (
        editedMessage.quoted_message_id != null &&
        editedMessage.quoted_message != null &&
        editedMessage.quoted_message.type !== 'deleted'
      ) {
        setQuotedMessage(formatChatMessage(editedMessage.quoted_message));
      } else {
        setQuotedMessage(null);
      }

      if (editedMessage.selfplusMessageMeta) {
        setMeta(editedMessage.selfplusMessageMeta);
      }

      if (isNotNullish(editedMessage.pinned)) {
        setPinned(editedMessage.pinned);
      }

      Object.keys(editedMessage).forEach(field => {
        if (isExtraMessageField(field)) {
          const val = editedMessage[field];
          if (isNotNullish(val)) {
            setExtraField(field, val);
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editedMessage]);

  const resetQuote = useCallback(() => setQuotedMessage(null), []);

  // TODO[Dmitriy Teplov] move into attachments manager?
  const isAttachmentManagerReady = useMemo(() => {
    return !attachmentManager.attachments.some(
      attachment => isUploadable(attachment) && attachment.status !== UploadingStatus.ready,
    );
  }, [attachmentManager.attachments]);

  const syncLinkAttachments = useCallback(
    (text: string) => {
      if (text === '') {
        for (const attachment of attachmentManager.attachments.filter(
          attachment => attachment.type === ATTACHMENT_TYPE.link,
        )) {
          attachmentManager.removeAttachment(attachment);
        }

        return;
      }

      const links = parseLinksFromDoc(parseMessageContent(text)).filter(({ href }) => excludedUrls[href] !== true);

      // TODO[Dmitriy Teplov] move to attachments manager,
      //  can't be done while it's built on top of React Hooks
      //  because state only changes after render.
      let attachmentsCount = attachmentManager.attachments.length;

      // Batch deletion to avoid messing up with indexes.
      const attachmentsToDelete: LinkAttachment[] = [];

      // Remove deleted.
      for (let i = 0; i < attachmentManager.attachments.length; i++) {
        const attachment = attachmentManager.attachments[i];
        if (attachment.type === ATTACHMENT_TYPE.link && links.find(l => l.href === attachment.url) == null) {
          attachmentsToDelete.push(attachment);
        }
      }

      for (const deletedAttachment of attachmentsToDelete) {
        const isDeleted = attachmentManager.removeAttachment(deletedAttachment);
        if (isDeleted) {
          attachmentsCount--;
        }
      }

      // Add new.
      if (links.length > 0) {
        const linkAttachments = attachmentManager.attachments.filter(
          (a): a is LinkAttachment => a.type === ATTACHMENT_TYPE.link,
        );

        for (const link of links) {
          if (attachmentsCount >= MAX_ATTACHMENT_COUNT) {
            return;
          }

          const isNewLink = linkAttachments.find(a => a.url === link.href) == null;

          if (isNewLink) {
            attachmentManager.addLinkAttachment(link.href);
            attachmentsCount++;
          }
        }
      }
    },
    [attachmentManager, excludedUrls],
  );

  const syncMentionedUserIds = useCallback((text: string) => {
    if (text === '') {
      setMentionedUserIds([]);
    }

    const ids = parseUserMentionsFromDoc(parseMessageContent(text)).map(m => m.id);
    const uniqueIds = Array.from(new Set(ids));
    setMentionedUserIds(uniqueIds);
  }, []);

  useEffect(() => {
    syncLinkAttachments(text);
    syncMentionedUserIds(text);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [text]);

  const excludeUrlFromAttachments = useCallback(
    (url: string) => setExcludedUrls(prev => ({ ...prev, [url]: true })),
    [],
  );

  const isEditing = editedMessage != null;
  const hasAttachments = attachmentManager.attachments.length > 0;
  const tooMuchAttachments = attachmentManager.attachments.length > 10;
  const hasText = !isEmptyDoc(parseMessageContent(text));
  const canSave = hasAttachments ? isAttachmentManagerReady && !tooMuchAttachments : hasText;

  const state = useMemo(
    () => ({
      text,
      pinned,
      mentionedUserIds,
      quotedMessage,
      editedMessage,
      hasAttachments,
      tooMuchAttachments,
      hasText,
      canSave,
      isEditing,
      meta,
      extraFields,
      draftId,
      formId,
    }),
    [
      text,
      pinned,
      mentionedUserIds,
      quotedMessage,
      editedMessage,
      hasAttachments,
      hasText,
      canSave,
      isEditing,
      meta,
      extraFields,
      draftId,
      formId,
    ],
  );

  return useMemo(
    () => ({
      state,
      reset,
      setText,
      edit: setEditedMessage,
      quote: setQuotedMessage,
      resetQuote,
      excludeUrlFromAttachments,
      attachmentManager,
      setMetaField,
      setExtraField,
    }),
    [attachmentManager, excludeUrlFromAttachments, reset, resetQuote, setExtraField, setMetaField, state],
  );
};

export function buildMessageUpdate(
  { editedMessage, quotedMessage, text, mentionedUserIds, meta, extraFields, pinned }: MessageFormModel['state'],
  attachments: ReadonlyArray<ChatMessageViewAttachment>,
): MessageUpdate {
  assert(editedMessage !== null);

  const update: Required<MessageUpdate['update']> = {
    set: {
      text,
      attachments: mapAttachmentsToStream(attachments),
      // Stream Chat types require mentioned_users to be an array of Users because of the inferred types,
      // but you only need to pass an array of ids.
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      mentioned_users: mentionedUserIds as any,
      ...extraFields,
    },
    unset: [],
  };

  if (editedMessage.quoted_message_id != null && quotedMessage == null) {
    // FIXME[Dmitriy Teplov] update.unset.push('quoted_message_id') doesn't work.
    //  But setting quoted_message_id to an invalid id such as null does work.
    update.set.quoted_message_id = null as unknown as string;
  }
  if (isNotNullish(meta)) {
    update.set.selfplusMessageMeta = meta;
  }

  if (isNotNullish(pinned)) {
    update.set.pinned = pinned;
  }

  return {
    id: editedMessage.id,
    update,
  };
}

export function buildNewMessage(
  {
    editedMessage,
    text,
    quotedMessage,
    hasText,
    hasAttachments,
    mentionedUserIds,
    pinned,
    meta,
    extraFields,
  }: MessageFormModel['state'],
  attachments: ReadonlyArray<ChatMessageViewAttachment>,
): ChatMessage {
  assert(editedMessage == null);

  const newMessage: ChatMessage = extraFields;

  if (hasText) {
    newMessage.text = text;
  }

  if (isNotNullish(pinned)) {
    newMessage.pinned = pinned;
  }

  if (hasAttachments) {
    newMessage.attachments = mapAttachmentsToStream(attachments);
  }

  if (quotedMessage) {
    newMessage.quoted_message_id = quotedMessage.id;
  }

  if (mentionedUserIds.length > 0) {
    newMessage.mentioned_users = mentionedUserIds;
  }
  if (!isEmpty(meta)) {
    newMessage.selfplusMessageMeta = meta;
  }

  return newMessage;
}
