import { useSetPreviousUrl } from '@sp/feature/back-button';
import { ROUTES } from '@sp/util/router';
import { FC } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { EditLinksPage } from './links';
import { NotificationsSettings } from './notifications';
import { OwnedProfileLayout } from './owned-profile-layout';
import { PurchasesPage } from './purchases';

export const ProfileScreen: FC = () => {
  useSetPreviousUrl(ROUTES.HOME_URL);

  return (
    <Routes>
      <Route path={`${ROUTES.PROFILE.PURCHASES_NESTED_URL}`} element={<PurchasesPage />} />
      <Route path={`${ROUTES.PROFILE.LINKS_SETTINGS}`} element={<EditLinksPage />} />
      <Route path={`${ROUTES.PROFILE.NOTIFICATIONS_SETTINGS}`} element={<NotificationsSettings />} />

      <Route path="/" element={<OwnedProfileLayout />} />
      <Route path="*" element={<Navigate to="/" replace={true} />} />
    </Routes>
  );
};
