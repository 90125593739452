import { ReactElement } from 'react';
import { ICON_DEFAULTS, IconProps } from './types';

export function IconLinesCoinDollar({
  size = ICON_DEFAULTS.size,
  color = ICON_DEFAULTS.color,
  className = ICON_DEFAULTS.className,
}: IconProps): ReactElement {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 41 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29.8083 8.2246C23.5114 3.31881 14.1572 4.23383 8.85814 9.59475C3.39959 15.1173 2.8395 25.8541 9.48738 31.1182C13.3307 34.1615 19.8487 35.9096 24.7219 34.4836C33.8064 31.8255 38.5458 20.7818 33.4638 12.1615"
        stroke={color}
        strokeWidth="2"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.6895 13.9589C20.93 13.7833 18.993 13.767 17.5229 14.8769C16.3299 15.7775 15.7241 17.4172 17.0747 18.5114C19.0311 20.0964 22.2341 19.0892 24.0102 21.0452C25.2362 22.3952 24.2564 24.2362 22.9094 25.0303C21.8662 25.6453 20.6002 25.7125 19.4253 25.5445C18.1715 25.3653 16.9019 25.2254 15.6543 25.0104"
        stroke={color}
        strokeWidth="2"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.916 13.958C19.9351 13.2294 19.9823 12.5063 19.9575 11.777"
        stroke={color}
        strokeWidth="2"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.7969 25.579C19.8188 26.2681 19.8415 26.9564 19.8797 27.6449"
        stroke={color}
        strokeWidth="2"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
