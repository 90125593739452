import { isNotNullish } from '@sp/util/helpers';
import { ATTACHMENT_TYPE, ChatMessageAttachment } from '@sp/util/stream-chat';
import {
  ChatMessageUploadableAttachment,
  ChatMessageViewAttachment,
  Uploadable,
  UploadableImageAttachment,
  UploadableVideoAttachment,
  UploadingStatus,
  ViewImageAttachment,
  ViewVideoAttachment,
} from './types';

export function getImagePreviewUrl(attachment: ViewImageAttachment | UploadableImageAttachment): string | null {
  if (isNotNullish(attachment.previewDataUrl)) {
    return attachment.previewDataUrl;
  }
  if (isNotNullish(attachment.previewUrl)) {
    return attachment.previewUrl;
  }
  if (isNotNullish(attachment.url)) {
    return attachment.url;
  }
  return null;
}

export function getVideoUrl(attachment: ViewVideoAttachment | UploadableVideoAttachment): string | null {
  if (isNotNullish(attachment.previewDataUrl)) {
    return attachment.previewDataUrl;
  }
  if (isNotNullish(attachment.url)) {
    return attachment.url;
  }
  return null;
}

// убираем все DataUrl перед сохранением
export function sanitizeDataUrlInAttachment(attachment: ChatMessageViewAttachment): ChatMessageAttachment {
  if (attachment.type === ATTACHMENT_TYPE.image || attachment.type === ATTACHMENT_TYPE.video) {
    const { previewDataUrl: _, ...withoutDataUrl } = attachment;
    return withoutDataUrl;
  }
  if (attachment.type === ATTACHMENT_TYPE.audio) {
    const { fileDataUrl: _, ...withoutDataUrl } = attachment;
    return withoutDataUrl;
  }
  return attachment;
}

export function isUploadable(
  attachment: ChatMessageViewAttachment | ChatMessageUploadableAttachment,
): attachment is ChatMessageUploadableAttachment {
  return isNotNullish((attachment as Uploadable).status);
}

export function isUploadRejected(attachment: ChatMessageViewAttachment | ChatMessageUploadableAttachment): boolean {
  if (!isUploadable(attachment)) {
    return false;
  }
  return attachment.status === UploadingStatus.error;
}
