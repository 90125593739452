import { AUTH_MODEL, useAuth } from '@sp/data/auth';
import { Button, Input } from '@sp/ui/elements';
import { FormEvent, useEffect, useState } from 'react';

export function MagicLinkForm() {
  const { startLoginWithMagicLink, isLoginWithMagicLinkInProgress: isLoading, magicLinkSuccess } = useAuth();
  const [email, setEmail] = useState<string>('');
  const [magicLinkDisabled, setMagicLinkDisabled] = useState(false);

  function submit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    startLoginWithMagicLink({ email });
  }
  useEffect(() => {
    let timer: NodeJS.Timeout;
    const { unsubscribe } = AUTH_MODEL.$sendMagicLinkSuccess.watch(v => {
      if (v) {
        setMagicLinkDisabled(true);
        timer = setTimeout(() => {
          setMagicLinkDisabled(false);
        }, 3000);
      }
    });
    return () => {
      clearTimeout(timer);
      unsubscribe();
    };
  }, []);

  return (
    <form onSubmit={submit}>
      <Input
        type="email"
        required
        value={email}
        onChange={e => setEmail(e.target.value)}
        placeholder="Type your e-mail"
        block
        className="!bg-primary !border !border-stroke !border-solid"
      />

      <Button
        type="submit"
        disabled={isLoading || magicLinkDisabled}
        color="black"
        loading={isLoading}
        block
        className="mt-3"
      >
        {magicLinkSuccess ? 'Sent! Now, check your inbox' : 'Log in with a one-time link'}
      </Button>
      <p className="m-0 mt-1 text-secondary text-xs">
        {magicLinkSuccess
          ? 'Tap on the link to log in to your Self+ account'
          : 'We will email you a link to this email address'}
      </p>
    </form>
  );
}
