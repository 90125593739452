import { ReactElement } from 'react';
import { ICON_DEFAULTS, IconProps } from './types';

export function IconSnapchat({
  size = ICON_DEFAULTS.size,
  className = ICON_DEFAULTS.className,
}: IconProps): ReactElement {
  return (
    <svg width={size} height={size} className={className} fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="32" height="32" rx="16" fill="url(#paint0_radial_7051_9736)" />
      <rect width="32" height="32" rx="16" fill="#F7F451" />
      <path
        d="M5.73438 21.6906C5.73438 21.6906 10.75 19.3656 10.75 11.5C10.75 10.1076 11.3031 8.77226 12.2877 7.78769C13.2723 6.80312 14.6076 6.25 16 6.25C17.3924 6.25 18.7277 6.80312 19.7123 7.78769C20.6969 8.77226 21.25 10.1076 21.25 11.5C21.25 19.3656 26.2656 21.6906 26.2656 21.6906C25.3937 22.5062 23.5469 22.0094 22.4969 22.5812C21.4469 23.1531 20.9031 24.9812 19.7313 25.2812C18.5594 25.5813 17.2188 24.25 16 24.25C14.7812 24.25 13.4031 25.5719 12.2687 25.2812C11.1344 24.9906 10.5344 23.1531 9.50313 22.5812C8.47188 22.0094 6.60625 22.5062 5.73438 21.6906Z"
        stroke="#181828"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.7461 15.5031L24.2492 14.5"
        stroke="#181828"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.2531 15.5031L7.75 14.5"
        stroke="#181828"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <radialGradient
          id="paint0_radial_7051_9736"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(27.04 36.32) rotate(-115.776) scale(43.6379 64.9064)"
        >
          <stop offset="0.24392" stopColor="#FF1B90" />
          <stop offset="0.436673" stopColor="#F80261" />
          <stop offset="0.688476" stopColor="#ED00C0" />
          <stop offset="0.776787" stopColor="#C500E9" />
          <stop offset="0.893155" stopColor="#7017FF" />
        </radialGradient>
      </defs>
    </svg>
  );
}
