import { useIsFirstRender } from '@sp/util/helpers';
import { PhotoSwipeOptions } from 'photoswipe';
import 'photoswipe/dist/photoswipe.css';
import { createContext, FC, useContext, useEffect } from 'react';
import { Gallery, GalleryProps, Item, ItemProps } from 'react-photoswipe-gallery';
import './photoswipe-custom.pcss';

export const PHOTO_GALLERY_OPTIONS: PhotoSwipeOptions = {
  arrowNext: false,
  arrowPrev: false,
  bgOpacity: 1,
  mainClass: 'chat-message-attachments-gallery',
};

const Context = createContext(false);

export const useIsInsidePhotoswipeGallery = () => useContext(Context);

export const PhotoswipeGallery: FC<GalleryProps> = props => {
  return (
    <Context.Provider value={true}>
      <Gallery {...props} />
    </Context.Provider>
  );
};

export const PhotoswipeItem: FC<ItemProps> = ({ children, ...props }) => {
  const isFirstRender = useIsFirstRender();
  const isInsidePhotoswipeGallery = useIsInsidePhotoswipeGallery();

  useEffect(() => {
    if (isFirstRender && !isInsidePhotoswipeGallery) {
      console.error('Item must be nested in Photoswipe Gallery component.');
    }
  }, [isFirstRender, isInsidePhotoswipeGallery]);

  return <Item {...props}>{children}</Item>;
};
