import { Rule, ValidationResult } from 'effector-forms';
import { z } from 'zod';

export function createZodValidator<T, V>(schema: z.Schema<T>): (value: V) => ValidationResult {
  return (value: V) => {
    const result = schema.safeParse(value);

    return result.success
      ? { isValid: true }
      : {
          isValid: false,
          value: value,
          errorText: result.error.errors[0].message,
        };
  };
}

export function createZodRule<V, T>({ schema, name }: { schema: z.Schema<T>; name: string }): Rule<V> {
  return { name, validator: createZodValidator(schema) };
}
