import { RemirrorJSON } from '@remirror/core';
import { LinkAttributes } from 'remirror/extensions';
import { getAllMarksByType } from './document-search';
import { editor, manager } from './remirror-instance';

interface Link {
  readonly href: string;
  readonly text: string;
}

const linkMarkType = editor.schema.marks['link'];

export function parseLinksFromDoc(doc: RemirrorJSON): Link[] {
  return getAllMarksByType(manager.createState({ content: doc }).doc, linkMarkType).map(({ mark, text }) => ({
    href: (mark.attrs as LinkAttributes).href,
    text,
  }));
}
