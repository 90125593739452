import { DocChangedExtension, RemirrorJSON } from '@remirror/core';
import { useExtensionEvent, useHelpers } from '@remirror/react';
import { createEmptyDoc } from '@sp/util/text-editor';
import isEqual from 'lodash/isEqual';
import { useCallback } from 'react';

export const OnChangeOutput = ({
  onChange,
  input,
}: Readonly<{ input: RemirrorJSON; onChange: (content: RemirrorJSON) => void }>): null => {
  const { getJSON, getText } = useHelpers();

  useExtensionEvent(
    DocChangedExtension,
    'docChanged',
    useCallback(
      ({ state }) => {
        const text = getText({ state }).trim();
        const isEmpty = text === '';

        if (isEmpty) {
          onChange(createEmptyDoc());
          return;
        }

        const output: RemirrorJSON = getJSON(state);

        // TODO[Dmitriy Teplov] Filter out empty nodes.
        // TODO[Dmitriy Teplov] trim whitespace.
        // if (output.content != null) {
        //   output = {
        //     ...output,
        //     content: output.content.filter(item => !isEmptyParagraph(item)),
        //   };
        // }

        if (!isEqual(output, input)) {
          onChange(output);
        }
      },
      [getJSON, getText, input, onChange],
    ),
  );

  return null;
};
