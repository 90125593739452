import { AUTH_MODEL, USER_MODEL } from '@sp/data/auth';
import { SpinnerPage } from '@sp/ui/elements';
import { ROUTES, useRedirectAfterLogin } from '@sp/util/router';
import { useStore } from 'effector-react';
import { ReactElement, useEffect } from 'react';
import { useHref, useLocation, useNavigate } from 'react-router-dom';

export function useRedirectNonAuthenticated(redirectTo: string = ROUTES.LOGIN_PATH): void {
  const isLoading = useStore(USER_MODEL.$isFetching);
  const isTokenSet = useStore(AUTH_MODEL.$isTokenSet);
  const { setRedirect } = useRedirectAfterLogin();
  const currentHref = useHref(useLocation());
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoading && !isTokenSet) {
      setRedirect(currentHref);
      navigate(redirectTo, { replace: true });
    }
  });
}

export function AuthenticatedRoute({
  children,
  redirectTo,
}: {
  readonly children: ReactElement;
  readonly redirectTo?: string;
}): ReactElement | null {
  const user = useStore(USER_MODEL.$user);
  const isLoading = useStore(USER_MODEL.$isFetching);
  const isLoggedIn = useStore(AUTH_MODEL.$isReady);

  useRedirectNonAuthenticated(redirectTo);

  if ((isLoading && user === null) || !isLoggedIn) {
    return <SpinnerPage />;
  }

  return children;
}

export function useRedirectAuthenticated(redirectTo?: string): void {
  const user = useStore(USER_MODEL.$user);
  const isLoading = useStore(USER_MODEL.$isFetching);

  const navigate = useNavigate();

  const { setRedirect, redirectUrl } = useRedirectAfterLogin();

  useEffect(() => {
    if (!isLoading && user != null) {
      navigate(redirectTo ?? redirectUrl, { replace: true });
      setRedirect('/');
    }
  });
}

export function NonAuthenticatedRoute({
  children,
  redirectTo,
}: {
  readonly children: ReactElement;
  readonly redirectTo?: string;
}): ReactElement | null {
  const user = useStore(USER_MODEL.$user);
  const isLoading = useStore(USER_MODEL.$isFetching);

  useRedirectAuthenticated(redirectTo);

  if (isLoading || user != null) {
    return <SpinnerPage />;
  }

  return children;
}
