import { ChatMessageHeaderView } from '@sp/ui/chat';
import { trackTime } from '@sp/util/native';
import { FC, ReactNode, useCallback } from 'react';
import { useChatMessage } from './chat-message-context';

export const ChatMessageHeader: FC<{
  showDate?: boolean;
  afterUserNameContent?: ReactNode;
  showEditButton?: boolean;
  onEditClick?: () => void;
}> = ({ showDate = true, showEditButton = false, afterUserNameContent = null, onEditClick }) => {
  const { message, model } = useChatMessage();

  const onUserClick = useCallback(() => {
    if (message.user?.id != null) {
      trackTime('View profile from chat');
      model.showUserCard({
        userId: message.user.id,
        avatar: { url: message.user.image },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message.user?.id, model.showUserCard]);

  return (
    <ChatMessageHeaderView
      author={message.user?.name ?? 'Unknown'}
      date={message.created_at}
      showDate={showDate}
      afterUserNameContent={afterUserNameContent}
      showEditButton={showEditButton}
      onEditClick={onEditClick}
      onUserClick={onUserClick}
    />
  );
};
