import { deserialize, isSerializedContainer, SerializationFormat, serialize } from '@sp/util/serialization';
import { RemirrorJSON } from 'remirror';
import { createEmptyDoc } from './data-helpers';

export class DeserializeError extends Error {
  constructor(message: string, content: unknown) {
    super(`DeserializeError: ${message}\n${JSON.stringify(content)}`);
  }
}

export function deserializeMessageContent(text: string): RemirrorJSON {
  if (text === '') {
    return createEmptyDoc();
  }

  let parsedText: unknown;

  try {
    parsedText = JSON.parse(text);
  } catch (e) {
    throw new DeserializeError(`failed to parse text.`, text);
  }

  if (!isSerializedContainer(parsedText)) {
    throw new DeserializeError(`text is not a serialized container.`, parsedText);
  }

  let deserializedData: string;

  try {
    deserializedData = deserialize(parsedText);
  } catch (e) {
    throw new DeserializeError(`failed to deserialize text.`, parsedText);
  }

  let parsedData: unknown;

  try {
    parsedData = JSON.parse(deserializedData);
  } catch (e) {
    throw new DeserializeError(`failed to parse deserialized data.`, deserializedData);
  }

  if (typeof parsedData !== 'object' || parsedData == null) {
    throw new DeserializeError(`deserialized data is not a doc object.`, parsedData);
  }

  if (!('content' in parsedData && 'type' in parsedData)) {
    throw new DeserializeError(`deserialized data is not a doc object.`, parsedData);
  }

  return parsedData as RemirrorJSON;
}

export function serializeMessageContent(doc: RemirrorJSON): string {
  return JSON.stringify(serialize(JSON.stringify(doc), SerializationFormat.Compressed));
}
