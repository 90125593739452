import { AxiosStatic } from 'axios';

export type RequestFulfilledInterceptor = NonNullable<Parameters<AxiosStatic['interceptors']['request']['use']>[0]>;
export type RequestRejectedInterceptor = NonNullable<Parameters<AxiosStatic['interceptors']['request']['use']>[1]>;

export type ResponseFulfilledInterceptor = NonNullable<Parameters<AxiosStatic['interceptors']['response']['use']>[0]>;
export type ResponseRejectedInterceptor = NonNullable<Parameters<AxiosStatic['interceptors']['response']['use']>[1]>;

export class AxiosInterceptorBatchManager {
  private readonly requestIds: number[] = [];
  private readonly responseIds: number[] = [];

  constructor(private readonly managers: AxiosStatic['interceptors']) {}

  set({
    requestFulfilled = [],
    requestRejected = [],
    responseFulfilled = [],
    responseRejected = [],
  }: {
    requestFulfilled?: ReadonlyArray<RequestFulfilledInterceptor>;
    requestRejected?: ReadonlyArray<RequestRejectedInterceptor>;
    responseFulfilled?: ReadonlyArray<ResponseFulfilledInterceptor>;
    responseRejected?: ReadonlyArray<ResponseRejectedInterceptor>;
  }) {
    this.clear();

    for (const requestFulfilledInterceptor of requestFulfilled) {
      this.requestIds.push(this.managers.request.use(requestFulfilledInterceptor));
    }

    for (const requestRejectedInterceptor of requestRejected) {
      this.requestIds.push(this.managers.request.use(undefined, requestRejectedInterceptor));
    }

    for (const responseFulfilledInterceptor of responseFulfilled) {
      this.responseIds.push(this.managers.response.use(responseFulfilledInterceptor));
    }

    for (const responseRejectedInterceptor of responseRejected) {
      this.responseIds.push(this.managers.response.use(undefined, responseRejectedInterceptor));
    }
  }

  clear() {
    AxiosInterceptorBatchManager.runEjector(this.requestIds, this.managers.request.eject.bind(this.managers.request));
    AxiosInterceptorBatchManager.runEjector(
      this.responseIds,
      this.managers.response.eject.bind(this.managers.response),
    );
  }

  private static runEjector(ids: number[], ejector: (id: number) => void) {
    while (ids.length > 0) {
      const id = ids.pop();
      if (id != null) {
        ejector(id);
      }
    }
  }
}
