import { useUser } from '@sp/data/auth';
import { cls } from '@sp/util/helpers';
import { isAfter, isBefore } from 'date-fns';
import { useStore } from 'effector-react';
import { FC, useMemo } from 'react';
import { useScheduledActualDate } from '../challenge-helpers';
import { CHALLENGE_MODEL } from '../challenge-model';
import { ChallengeStepWithUI, STEPS_MODEL } from '../steps';
import { StepTrialFormView } from '../steps/step-trial-form-view';
import { StepForm } from './step-form';
import { StepFormPaneHeader } from './step-header';

export const StepFormPane: FC<{
  stepWithUI: ChallengeStepWithUI;
}> = ({ stepWithUI }) => {
  const user = useUser();
  const challenge = useStore(CHALLENGE_MODEL.$challenge);
  const stepsWithUI = useStore(STEPS_MODEL.$stepsWithUI);
  const now = useScheduledActualDate(
    useMemo(() => stepsWithUI.map(({ step }) => new Date(step.startAt)), [stepsWithUI]),
  );
  const { step } = stepWithUI;
  const isStarted = isAfter(now, stepWithUI.step.startAt);
  const isStepActive = isStarted && isBefore(now, stepWithUI.step.endAt) && !step.result.isCompleted;
  const showPaywall = !challenge.isPurchased && challenge.creator?.id !== user.id;

  // FIXME onBuy
  const stepForm = showPaywall ? (
    <StepTrialFormView stepTitle={step.title} isProgram={!!challenge.isProgram} isActive={isStepActive} />
  ) : (
    <StepForm stepWithUI={stepWithUI} />
  );
  return (
    <div>
      <div className={cls('rounded-2.5xl p-4', isStepActive ? 'bg-brand-medium text-active' : 'bg-light')}>
        <div className="mb-2">
          <StepFormPaneHeader
            dayName={step.dayName}
            isCompleted={step.result.isCompleted}
            isStarted={isStarted}
            startTimestamp={step.startAt}
            isActive={isStepActive}
            isProgramPost={!!challenge.isProgram}
          />
        </div>

        {stepForm}
      </div>
    </div>
  );
};
