import { SELFPLUS_ACCOUNT_URL } from '@sp/data/env';
import { noop } from '@sp/util/helpers';
import { VFC } from 'react';

type GoToAccountButtonType = 'manage' | 'change_card';

const labelByType: Record<GoToAccountButtonType, JSX.Element> = {
  change_card: <>to manage&nbsp;your&nbsp;Self+&nbsp;account and&nbsp;change card.</>,
  manage: <>to manage&nbsp;your&nbsp;account and&nbsp;purchases.</>,
};

export const GoToAccountButton: VFC<Readonly<{ type: GoToAccountButtonType; onGoToAccount?: VoidFunction }>> = ({
  type,
  onGoToAccount = noop,
}) => {
  return (
    <>
      Please go to {/* eslint-disable-next-line react/jsx-no-target-blank */}
      <a
        className="text-blue-500 underline"
        href={SELFPLUS_ACCOUNT_URL}
        onClick={() => onGoToAccount()}
        target="_blank"
        rel="noopener"
      >
        selfplus.com/account
      </a>{' '}
      {labelByType[type]}
    </>
  );
};
