import { isAfter } from 'date-fns';
import { FC, useMemo } from 'react';
import { useScheduledActualDate } from '../challenge-helpers';
import { ChallengeStepWithUI, StepCompleteFormView, STEPS_MODEL } from '../steps/';

export const StepForm: FC<{
  stepWithUI: ChallengeStepWithUI;
}> = ({ stepWithUI: { step, ui } }) => {
  const { completeStep, uncompleteStep } = STEPS_MODEL;
  const { isCompleting } = ui;
  const isCompleted = step.result.isCompleted;
  const now = useScheduledActualDate(useMemo(() => [new Date(step.startAt)], [step.startAt]));
  const isStepStarted = isAfter(now, step.startAt);
  const isStepFinished = isAfter(now, step.endAt);

  return (
    <StepCompleteFormView
      stepTitle={step.title}
      estimatedTime={step.estimatedTime}
      isCanComplete={isStepStarted}
      isCompleted={isCompleted}
      isStepCompleting={isCompleting}
      onCompleteStep={() => (isCompleted ? uncompleteStep({ stepId: step.id }) : completeStep({ stepId: step.id }))}
      isActive={isStepStarted && !isStepFinished && !isCompleted}
    />
  );
};
