import { isNullish } from '@sp/util/helpers';

export function parseParamInteger(param: string | undefined): number {
  if (typeof param !== 'string') return NaN;
  return parseInt(param, 10);
}

export function parseFromParam(params: URLSearchParams): string {
  if (isNullish(params.get('from'))) {
    return 'Other';
  }
  if (params.get('from') === 'home') {
    return 'Home';
  }
  if (params.get('from') === 'push') {
    return 'Notification';
  }
  return 'Other';
}
