import { createDomEditor, createDomManager } from '@remirror/dom';
import { corePreset } from '@remirror/preset-core';
import { extensions } from './extensions';
import { handleRemirrorError } from './handle-remirror-error';

export const manager = createDomManager(
  () => [...extensions(), ...corePreset({ excludeExtensions: ['events', 'history', 'positioner', 'gapCursor'] })],
  { onError: handleRemirrorError },
);

export const editor = createDomEditor({ element: document.createElement('div'), manager });
