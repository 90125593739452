import { useUser } from '@sp/data/auth';
import { SharedUploadProgress } from '@sp/ui/chat';
import { Button, Input } from '@sp/ui/elements';
import {
  IconAdd,
  IconArrowBendUpLeft,
  IconArrowSendMessage,
  IconLink,
  IconMicrophone,
  IconTextAa,
  IconTextBolder,
  IconTextItalic,
  IconWarning,
  IconX,
} from '@sp/ui/icons';
import { Modal } from '@sp/ui/modal';
import {
  LinkEditorProvider,
  MentionOption,
  TextEditor,
  TextEditorCommandButton,
  TextEditorMentionSuggester,
  TextEditorProvider,
  useFocusTextEditor,
  useLinkEditor,
} from '@sp/ui/text-editor';
import { cls, isNotNullish } from '@sp/util/helpers';
import {
  $isNativeIos,
  checkMicrophone,
  openSettings,
  requestMedia,
  setNativeCallbackMediaPermissionUpdate,
} from '@sp/util/native';
import { ChatUser } from '@sp/util/stream-chat';
import { NATIVE_KEYBOARD_CBS } from '@sp/util/viewport';
import { format } from 'date-fns';
import { useStore } from 'effector-react';
import {
  FC,
  FormEvent,
  forwardRef,
  memo,
  ReactElement,
  Ref,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
  VFC,
} from 'react';
import { useFilePicker } from 'use-file-picker';
import { ChatAudioRecorder } from './chat-audio-recorder';
import { ChatEditorAttachments } from './editor-attachments/chat-editor-attachments';
import { ChatMessageQuote } from './elements/chat-message-reply';
import { useAcceptFileTypes } from './use-accept-file-types';
import { useChatExtraControls } from './use-chat-extra-controls';
import { MessageFormModel } from './use-message-form';

interface AddFileAttachmentsFn {
  (files: File[]): void;
}

function useMessageFormAttachments({
  addFileAttachments,
  acceptFileTypes,
}: Readonly<{ addFileAttachments: AddFileAttachmentsFn; acceptFileTypes: string[] }>) {
  const [openFilePicker, { plainFiles, clear }] = useFilePicker({
    accept: acceptFileTypes,
    multiple: true,
    readFilesContent: false,
  });

  useEffect(() => {
    if (plainFiles.length > 0) {
      addFileAttachments(plainFiles);
      clear();
    }
  }, [addFileAttachments, clear, plainFiles]);

  return openFilePicker;
}

function useAudioAttachment({
  addFileAttachments,
  userId,
}: Readonly<{
  addFileAttachments: AddFileAttachmentsFn;
  userId: number;
}>) {
  return useCallback(
    (result: Blob) => {
      const name = `audio-record-user-${userId}-${format(new Date(), 'MM-dd-yyyy')}.${
        result.type.includes('webm') ? 'webm' : 'mp4' // фолбэк для safari
      }`;
      const file = new File([result], name, { type: result.type });
      addFileAttachments([file]);
    },
    [addFileAttachments, userId],
  );
}

const LinkEditorModal: VFC = memo(function LinkEditorModal() {
  const { isEditing, cancelEditing } = useLinkEditor();

  return (
    <Modal isOpen={isEditing} onClose={cancelEditing} withBackdrop>
      <LinkEditorForm />
    </Modal>
  );
});

// eslint-disable-next-line react/prop-types
const LinkEditorButton: VFC<Readonly<{ onClick?: VoidFunction }>> = ({ onClick }) => {
  const { startEditing, canEdit, isLinkActive } = useLinkEditor();

  const handleClick = useCallback(() => {
    onClick?.();
    startEditing();
  }, [onClick, startEditing]);

  return (
    <button
      type="button"
      disabled={!canEdit}
      className={cls(
        'px-2 h-6 flex items-center justify-center',
        'disabled:opacity-60 disabled:cursor-not-allowed',
        isLinkActive ? 'text-brand' : 'bg-transparent text-disabled',
      )}
      onClick={handleClick}
    >
      <IconLink size={20} />
    </button>
  );
};

const PermissionModal: VFC<{ text: string; settingsButton: boolean; open: boolean; onClose: () => void }> = ({
  open,
  onClose,
  text,
  settingsButton,
}) => {
  return (
    <Modal isOpen={open} onClose={onClose} withBackdrop>
      <div className="mb-4">{text}</div>
      {settingsButton ? <Button onClick={() => openSettings()}>Open settings</Button> : null}
    </Modal>
  );
};

const LinkEditorForm: VFC = () => {
  const { isValid, isLinkActive, title, setTitle, href, setHref, upsertLink, removeLink, cancelEditing } =
    useLinkEditor();

  const handleTitleChange = useCallback(e => setTitle(e.target.value), [setTitle]);
  const handleHrefChange = useCallback(e => setHref(e.target.value), [setHref]);

  return (
    <div>
      <div className="flex items-center">
        <h4 className="my-0 text-xl font-semibold">Link</h4>
        {isLinkActive && (
          <button type="button" className="text-brand ml-auto" onClick={removeLink}>
            Remove link
          </button>
        )}
      </div>

      <div className="mt-6">
        <label htmlFor="link-text">Title</label>
        <Input
          block
          id="link-text"
          className="mt-2"
          type="text"
          placeholder="Type name here"
          value={title}
          onChange={handleTitleChange}
        />
      </div>

      <div className="mt-6">
        <label htmlFor="link-href">Link</label>
        <Input
          block
          id="link-href"
          className="mt-2"
          type="url"
          placeholder="Type link here"
          value={href}
          onChange={handleHrefChange}
        />
      </div>

      <Button block color="brand" className="mt-6" disabled={!isValid} onClick={upsertLink}>
        {isLinkActive ? 'Update' : 'Attach'}
      </Button>

      <Button block color="transparent" className="mt-2" onClick={cancelEditing}>
        Cancel
      </Button>
    </div>
  );
};

export interface MessageFormHandle {
  readonly focus: VoidFunction;
  readonly refocus: VoidFunction;
}

type MessageFormProps = Readonly<{
  submit: VoidFunction;
  isLoading: boolean;
  model: MessageFormModel;
  mentionableUsers?: Record<string, ChatUser | undefined>;
  editorMaxHeight: string;
  KeyMapExtension?: FC<{ submit: VoidFunction }>;
}>;

const WrappedMessageForm = forwardRef(function WrappedMessageForm(
  { submit, isLoading, model, mentionableUsers, editorMaxHeight }: MessageFormProps,
  ref: Ref<MessageFormHandle>,
): ReactElement {
  const {
    reset,
    resetQuote,
    state: { hasText },
    excludeUrlFromAttachments,
  } = model;
  const { quotedMessage, hasAttachments, tooMuchAttachments, canSave, isEditing } = model.state;
  const { attachments, addFileAttachments, removeAttachmentAt, updateAttachmentAt } = model.attachmentManager;
  const isIOs = useStore($isNativeIos);
  const { beforeQuoted } = useChatExtraControls();

  const handleMicrophoneClick = () => {
    if (!isIOs) {
      setIsRecording(true);
      return;
    }
    const permission = checkMicrophone();
    if (permission === 'granted') {
      setIsRecording(true);
    } else if (permission === 'denied') {
      setPermissionModalOpen(true);
      setPermissionModalText('Please, allow access to your microphone.');
      setPermissionModalButton(true);
    } else if (permission === 'restricted') {
      setPermissionModalOpen(true);
      setPermissionModalText('Sorry, your access to {device} is restricted.');
    } else if (permission === 'unknown') {
      requestMedia('audio');
    }
  };

  const [permissionModalOpen, setPermissionModalOpen] = useState(false);
  const [permissionModalText, setPermissionModalText] = useState('');
  const [permissionModalButton, setPermissionModalButton] = useState(false);

  useEffect(() => {
    setNativeCallbackMediaPermissionUpdate((res: boolean, type: 'audio' | 'video') => {
      if (res && type === 'audio') {
        setIsRecording(true);
      }
    });
  }, []);

  const acceptFileTypes = useAcceptFileTypes();
  const openFilePicker = useMessageFormAttachments({ addFileAttachments, acceptFileTypes });
  const addAudioAttachment = useAudioAttachment({ addFileAttachments, userId: useUser().id });

  const [isRecording, setIsRecording] = useState(false);

  const onRecordResult = useCallback(
    (result: Blob) => {
      setIsRecording(false);
      addAudioAttachment(result);
    },
    [addAudioAttachment],
  );

  const cancelRecording = useCallback(() => setIsRecording(false), []);

  const [isFormatToolbarActive, setIsFormatToolbarActive] = useState(false);

  const focusCommands = useFocusTextEditor();

  const handleToggleFormatToolbar = useCallback(() => {
    setIsFormatToolbarActive(isActive => !isActive);
    focusCommands.focus();
  }, [focusCommands]);

  const hideFormatToolbar = useCallback(() => setIsFormatToolbarActive(false), []);

  // Hide format toolbar when all text is erased.
  useEffect(() => {
    if (!hasText) setIsFormatToolbarActive(false);
  }, [hasText]);

  const handleOpenFilePicker = useCallback(() => {
    setTimeout(() => openFilePicker(), 50); // File picker opens under keyboard without timeout
    focusCommands.refocus();
  }, [focusCommands, openFilePicker]);

  const isLinkEditorActive = useLinkEditor().isEditing;

  const isFormActive =
    isLoading ||
    isFormatToolbarActive ||
    hasText ||
    hasAttachments ||
    isRecording ||
    isLinkEditorActive ||
    quotedMessage != null;

  const resetFormUI = useCallback(() => {
    setIsFormatToolbarActive(false);
    setIsRecording(false);
  }, []);

  const onSubmit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      submit();
      // TODO: better reset after successful sumbit (after loading)
      resetFormUI();
    },
    [resetFormUI, submit],
  );

  const handleReset = useCallback(() => {
    resetFormUI();
    reset();
  }, [reset, resetFormUI]);

  const mentionOptions: readonly MentionOption[] | null = useMemo(
    () =>
      mentionableUsers
        ? Object.values(mentionableUsers)
            .filter(isNotNullish)
            .map(user => ({ id: user.id, label: user.name ?? '' }))
        : null,
    [mentionableUsers],
  );

  useImperativeHandle(ref, () => focusCommands, [focusCommands]);

  const [keyboardOpen, setKeyboardOpen] = useState(true); // because android doesn't have native keyboard events

  const keyboardCloseCb = useCallback(
    (status: 'willHide' | 'willShow' | 'didShow') => {
      if (status === 'willHide') {
        setKeyboardOpen(false);
      } else if (status === 'willShow') {
        setKeyboardOpen(true);
      }
    },
    [isFormActive],
  );

  useEffect(() => {
    NATIVE_KEYBOARD_CBS.add(keyboardCloseCb);
    return () => {
      NATIVE_KEYBOARD_CBS.delete(keyboardCloseCb);
    };
  }, [keyboardCloseCb]);

  return (
    <>
      <div className="relative bg-primary border-t border-stripe pt-2 z-10">
        {mentionOptions && mentionOptions.length > 0 && (
          <TextEditorMentionSuggester options={mentionOptions} optionsData={mentionableUsers} />
        )}
        {isEditing && (
          <div className="flex items-center justify-between ml-10 mb-2 pr-2 pl-1 pt-1 pb-1 border-l-2 border-link-hover">
            <span className="text-link-hover font-medium">Edit message</span>
            <button type="button" onClick={handleReset} className="self-center mr-4 text-secondary">
              <IconX size={24} />
            </button>
          </div>
        )}

        {isNotNullish(beforeQuoted) ? beforeQuoted(model) : null}

        {quotedMessage && !isEditing && (
          <div className="flex justify-between px-2 pb-2">
            <div className="flex items-center gap-2 min-w-0">
              <IconArrowBendUpLeft size={24} className="text-link-hover -scale-x-100" />
              <ChatMessageQuote message={quotedMessage} />
            </div>
            <button type="button" onClick={resetQuote} className="self-center mr-4 text-secondary">
              <IconX size={24} />
            </button>
          </div>
        )}

        {hasAttachments && (
          <div className="w-full flex gap-2 pl-10 pr-2 pt-2 pb-1 -mt-2 items-center overflow-x-auto mobile-pan">
            <ChatEditorAttachments
              attachments={attachments}
              onRemoveAttachment={removeAttachmentAt}
              onRemoveLinkAttachment={excludeUrlFromAttachments}
              onUpdateAttachment={updateAttachmentAt}
            />
          </div>
        )}

        {/*Floating section*/}
        <div className="absolute top-0 left-0 w-full">
          <div className="absolute bottom-1 left-0 w-full px-6 flex justify-center">
            <div className="w-full flex flex-col gap-1">
              {tooMuchAttachments && (
                <div className="flex items-center border border-[#FFCC62] bg-[#FFF5DF] gap-2 p-2 rounded-lg">
                  <IconWarning size={24} />
                  <div className="text-xs">
                    Only 10 attachments are available.
                    <br />
                    Please remove extra
                  </div>
                </div>
              )}

              <SharedUploadProgress attachments={attachments} />
            </div>
          </div>
        </div>

        {isRecording && (
          <div className="px-2 pb-2">
            <ChatAudioRecorder onResult={onRecordResult} onCancel={cancelRecording} />
          </div>
        )}

        <form
          onSubmit={onSubmit}
          className={cls('flex-column items-center px-2 pb-2 w-full overflow-visible', isRecording ? 'hidden' : null)}
        >
          <div className="flex gap-1 items-end w-full">
            <button
              type="button"
              className="flex items-center justify-center w-8 h-8 mb-1 -ml-1 text-secondary flex-none"
              onClick={handleOpenFilePicker}
            >
              <IconAdd size={24} />
            </button>
            <div className="relative flex items-end flex-grow rounded-1.5lg bg-secondary py-0 px-2">
              <div className="flex-grow">
                <div className="py-2 w-full">
                  <TextEditor maxHeight={editorMaxHeight} />
                  {isFormActive && isFormatToolbarActive && keyboardOpen && (
                    <div className="absolute flex flex-row bg-primary rounded-md drop-shadow-md py-2 top-[-2.55rem] right-2 z-10 divide-x">
                      <TextEditorCommandButton name="bold" command="toggleBold">
                        <IconTextBolder size={20} />
                      </TextEditorCommandButton>
                      <TextEditorCommandButton name="italic" command="toggleItalic">
                        <IconTextItalic size={20} />
                      </TextEditorCommandButton>
                      <LinkEditorButton onClick={hideFormatToolbar} />
                    </div>
                  )}
                </div>
              </div>
              <div className="flex items-center h-10">
                {hasText && (
                  <button
                    type="button"
                    className={cls(
                      'text-secondary -ml-1 relative rounded-full p-2 left-[0.25rem]',
                      isFormatToolbarActive && keyboardOpen && 'bg-brand-substrate !text-brand',
                    )}
                    onClick={handleToggleFormatToolbar}
                  >
                    <IconTextAa size={20} />
                  </button>
                )}
              </div>
            </div>
            {isFormActive ? (
              <Button
                type="submit"
                loading={isLoading}
                disabled={!canSave}
                size="sm"
                color="primary"
                circle
                className="flex-none"
              >
                <IconArrowSendMessage size={24} />
              </Button>
            ) : (
              <button
                type="button"
                onClick={handleMicrophoneClick}
                disabled={attachments.length >= 10}
                className="flex items-center justify-center w-10 h-10 text-secondary flex-none"
              >
                <IconMicrophone size={24} />
              </button>
            )}
          </div>
        </form>
      </div>

      <PermissionModal
        open={permissionModalOpen}
        settingsButton={permissionModalButton}
        text={permissionModalText}
        onClose={() => {
          setPermissionModalOpen(false);
          setPermissionModalText('');
          setPermissionModalButton(false);
        }}
      />
      <LinkEditorModal />
    </>
  );
});

// Wrap the whole form in TextEditorProvider
// to be able to use editor's context for format buttons, focus fn etc.
export const MessageForm = forwardRef(function MessageForm(
  { KeyMapExtension, ...props }: MessageFormProps,
  ref: Ref<MessageFormHandle>,
): ReactElement {
  return (
    <TextEditorProvider onChange={props.model.setText} text={props.model.state.text} placeholder="Type your message">
      {KeyMapExtension && <KeyMapExtension submit={props.submit} />}
      <LinkEditorProvider>
        <WrappedMessageForm ref={ref} {...props} />
      </LinkEditorProvider>
    </TextEditorProvider>
  );
});
