import { Logger } from 'stream-chat';

// Return either (modified) parameters to continue or false to cancel logging.
export type LoggerMiddleware = (...args: Parameters<Logger>) => Parameters<Logger> | false;

export function createLogger(logger: Logger, middlewares: LoggerMiddleware[]): Logger {
  if (!middlewares.length) return logger;

  return (...args) => {
    for (const middleware of middlewares) {
      const argsOrCancel = middleware(...args);
      if (!argsOrCancel) return;
      args = argsOrCancel;
    }
    return logger(...args);
  };
}
