import { isNotNullish } from '@sp/util/helpers';
import { Children, FC, isValidElement, ReactElement, useEffect, useState } from 'react';
import { TabsNavBar } from './tabs-nav-bar';
import { TabPaneList } from './tabs-pane-list';
import { Tab, TabPaneProps } from './types';

type TabsProps = {
  activeTabTextColor?: string; // Color token
  activeKey?: string;
  destroyInactiveTabPane?: boolean;
  onChange?: (activeKey: string) => void;
  maxTabWidth?: string | number;
  children: (ReactElement<TabPaneProps> | null)[];
  sticky?: boolean;
  animateTab?: string;
  onAnimationEnd?: () => void;
};

function parseTabList(children: (ReactElement<TabPaneProps> | null)[]): Tab[] {
  return Children.map(children.filter(isNotNullish), (node: ReactElement<TabPaneProps>) => {
    if (isValidElement(node)) {
      return {
        key: String(node.key),
        ...node.props,
        node,
      };
    }

    return null;
  }).filter(Boolean);
}

export const Tabs: FC<TabsProps> = ({
  activeKey,
  children,
  onChange,
  maxTabWidth = '100%',
  activeTabTextColor = 'text-primary',
  sticky = false,
  animateTab,
  onAnimationEnd,
}) => {
  const tabs = parseTabList(children);
  const firstTabKey = tabs[0].key;
  const [active, setActive] = useState(activeKey || firstTabKey);

  useEffect(() => {
    setActive(activeKey || firstTabKey);
  }, [activeKey, firstTabKey]);

  const handleClick = (key: string) => {
    setActive(key);
    onChange && onChange(key);
  };
  return (
    <div className="flex flex-col h-full w-full">
      <TabsNavBar
        onClick={handleClick}
        activeKey={active}
        tabs={tabs}
        maxTabWidth={maxTabWidth}
        activeTabTextColor={activeTabTextColor}
        sticky={sticky}
        animateTab={animateTab}
        onAnimationEnd={onAnimationEnd}
      />
      <TabPaneList activeKey={active} tabs={tabs} />
    </div>
  );
};
