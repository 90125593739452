import { CreatorCommunity, isExpertUser } from '@sp/data/bif';
import { BuyButtonResultFn, SubscribeButton } from '@sp/feature/buy-button';
import { Avatar, Button } from '@sp/ui/elements';
import { IconChatTeardropDots } from '@sp/ui/icons';
import { PriceConfig } from '@sp/util/format';
import { isNotNullish, Nullable } from '@sp/util/helpers';
import { ROUTES } from '@sp/util/router';
import { useStore, useStoreMap } from 'effector-react';
import { FC, useCallback, useMemo, VFC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Links } from './links/links';
import { ProfileHeader } from './profile-header';
import { PROFILE_MODEL } from './profile-model';
import { VerifiedBadge } from './verified-badge';

const CreatorCommunityInfo: VFC<
  Readonly<{ title: string; image?: string | null; followerCount: number; description: string | null }>
> = ({ title, image, followerCount, description }) => (
  <>
    <div className="mt-3 flex items-center">
      <Avatar size="md" src={image} />
      <div className="ml-2 flex flex-col flex-1 overflow-x-hidden">
        <span className="m-0 text-ellipsis whitespace-nowrap overflow-x-hidden text-base font-semibold">{title}</span>
        <span className="text-secondary">{followerCount} followers</span>
      </div>
    </div>

    {description && <div className="mt-2 overflow-hidden break-words whitespace-pre-line text-xs">{description}</div>}
  </>
);

const CreatorCommunityButton: VFC<
  Readonly<{ community: CreatorCommunity; onCommunityOpened: VoidFunction; creator: { id: number; name: string } }>
> = ({ community, creator, onCommunityOpened }) => {
  const navigate = useNavigate();

  const navigateToCommunity = useCallback(() => {
    onCommunityOpened();
    // FIXME[Dmitriy Teplov] add analytics "from" query parameter.
    // navigate(`${ROUTES.COMMUNITY_PAGE_URL}/${community.id}?from=creator_profile`);
    setTimeout(() => navigate(`${ROUTES.COMMUNITY_PAGE_URL}/${community.id}`));
  }, [navigate, community.id, onCommunityOpened]);

  const isAvailable = community.subscription?.isActive ?? false;

  const priceConfig: PriceConfig = useMemo(
    () => ({
      ios: community.marketingPriceConfig.iosPrice,
      web: community.price,
    }),
    [community.marketingPriceConfig.iosPrice, community.price],
  );

  const onSubscribeResult: BuyButtonResultFn = useCallback(
    result => result.success && navigateToCommunity(),
    [navigateToCommunity],
  );

  return isAvailable ? (
    <Button className="mt-2 !py-1 !px-4" color="black" onClick={navigateToCommunity}>
      Open
    </Button>
  ) : (
    <SubscribeButton
      className="mt-2 !py-1 !px-4"
      color="black"
      id={community.id}
      title={community.title}
      price={priceConfig}
      iosProductId={community.iosProductId ?? ''}
      creatorId={creator.id}
      creatorName={creator.name}
      bought={false}
      openUrl="."
      onResult={onSubscribeResult}
    >
      Become a member
    </SubscribeButton>
  );
};

export const ForeignProfileLayout: FC<{
  avatar?: Nullable<string>;
  name?: string;
  onDm?: VoidFunction;
  onForceClose: VoidFunction;
}> = ({ avatar, name, onDm, onForceClose }) => {
  const user = useStore(PROFILE_MODEL.$user);
  const userIsLoading = useStore(PROFILE_MODEL.$isUserLoading);
  // const subscriptions = useStore(USER_CREATORS_MODEL.$subscriptions) || [];
  // const purchases = useStore(USER_CREATORS_MODEL.$purchases) || [];
  const isCreator = user?.isExpert ?? false;
  const creatorCommunity = useStoreMap(
    PROFILE_MODEL.$user,
    user => (isNotNullish(user) && isExpertUser(user) && user.communities.length > 0 && user.communities[0]) ?? null,
  );

  return (
    <div>
      <ProfileHeader
        created_at={user?.created_at}
        isLoading={userIsLoading}
        userName={user?.name || name}
        bio={user?.bio}
        avatar={user?.avatar?.url || avatar}
      />

      {onDm && user ? (
        <Button
          outline
          color="black"
          onClick={e => {
            onDm();
            e.preventDefault();
          }}
          className="!py-1 !px-4 mb-6 ml-6 flex gap-1"
        >
          <IconChatTeardropDots size={24} /> Message
        </Button>
      ) : null}

      {isCreator ? (
        <>
          <div className="px-6 mb-6">
            <VerifiedBadge />
          </div>
        </>
      ) : null}

      {creatorCommunity && user && (
        <>
          <hr className="border-stripe w-full" />
          <div className="px-6 mb-6">
            <h3 className="m-0 text-xl font-semibold">Communities</h3>

            <CreatorCommunityInfo
              title={creatorCommunity.title}
              image={creatorCommunity.cover?.url}
              description={creatorCommunity.description}
              followerCount={creatorCommunity.subscribersCount}
            />

            <CreatorCommunityButton community={creatorCommunity} onCommunityOpened={onForceClose} creator={user} />
          </div>
        </>
      )}

      {/* <UserCreatorsLayout addMentorSub={false} items={subscriptions} type="subscriptions" />
      <UserCreatorsLayout addMentorSub={false} items={purchases} type="purchases" /> */}

      <hr className="border-stripe w-full" />
      <div className="px-6 mb-6">
        <Links editable={false} />
      </div>
    </div>
  );
};
