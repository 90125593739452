import { AUTH_MODEL, useAuth } from '@sp/data/auth';
import { SpinnerPage } from '@sp/ui/elements';
import { useRedirectAfterLogin } from '@sp/util/router';
import { useStore } from 'effector-react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export function ContinueFacebookOauth() {
  const isLoggedIn = useStore(AUTH_MODEL.$isReady);
  const { continueLoginWithFacebook } = useAuth();
  const { redirectUrl, setRedirect } = useRedirectAfterLogin();
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoggedIn) {
      navigate(redirectUrl);
      setRedirect('/');
    }
  });

  useEffect(() => {
    const search = new URLSearchParams(window.location.search);
    const code = search.get('code');

    if (code) {
      window.history.replaceState({}, document.title, window.location.pathname);
      continueLoginWithFacebook({ code });
    }
  }, [continueLoginWithFacebook]);

  return <SpinnerPage />;
}
