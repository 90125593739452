import { createContext, ReactNode, useContext } from 'react';
import { MessageFormModel } from './use-message-form';

export type ChatExtraControls = {
  beforeQuoted?: (formModel: MessageFormModel) => ReactNode;
};

export const ChatExtraControlsContext = createContext<ChatExtraControls>({});

export const useChatExtraControls = () => useContext(ChatExtraControlsContext);
