import { useContinuousEventStatus } from '@sp/util/helpers';
import { FC, ReactNode } from 'react';
import { LiveCountdown } from './live-countdown';
import { LiveIndicator } from './live-indicator';

export const LiveDescriptionCard: FC<{
  startAt: number; // Start date in milliseconds
  duration: number; // in minutes
  title: ReactNode;
  name: string;
  cover: string;
}> = ({ startAt, duration, title, name, cover, children }) => {
  const { isEnded } = useContinuousEventStatus({ startAt, duration });
  const coverStyles = { backgroundImage: `url(${cover}` };
  return (
    <div className="w-full aspect-[0.75] aspect-ratio-wrap">
      <div style={coverStyles} className="rounded-lg flex flex-col bg-center bg-cover bg-accent overflow-hidden">
        <div className="flex flex-1 flex-col justify-between items-start gap-2 p-4 text-white bg-gradient-to-b from-transparent via-transparent to-black/80">
          <LiveIndicator startAt={startAt} duration={duration} />
          <div className="w-full">
            {title}
            {!isEnded && (
              <div className="mt-0 mb-3 text-3xl flex flex-row justify-start items-center text-white/90">
                <LiveCountdown startAt={startAt} duration={duration} />
              </div>
            )}

            {children}
          </div>
        </div>
      </div>
    </div>
  );
};
