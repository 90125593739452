import { isNotNullish } from '@sp/util/helpers';
import { AxiosPromise } from 'axios';
import { z } from 'zod';
import { SpacesApiFactory } from '../../api/default';
import { apiResponseAdHoc } from '../helpers';

const defaultSpacesApi = SpacesApiFactory();

export class AlreadyPurchasedError extends Error {
  constructor(msg: string) {
    super(msg);
    this.name = 'AlreadyPurchasedError';
  }
}

const SpacePurchaseTypeEnum = z.enum(['oneTime', 'subscription']);
export type SpacePurchaseType = z.infer<typeof SpacePurchaseTypeEnum>;
export const SpacePurchaseType = SpacePurchaseTypeEnum.enum;

const purchaseApiMap: Record<SpacePurchaseType, (spaceId: number) => AxiosPromise<{ paymentId?: string }>> = {
  oneTime: defaultSpacesApi.spacesSpaceIdCheckoutPost,
  subscription: defaultSpacesApi.spacesSpaceIdSubscriptionCheckoutPost,
};

export async function initSpacePurchase(id: number, type: SpacePurchaseType): Promise<{ paymentId: string }> {
  SpacePurchaseTypeEnum.parse(type);

  return await purchaseApiMap[type](id)
    .then(r => apiResponseAdHoc(r.data))
    .catch(e => {
      const message = e.response?.data?.message;
      if (e.response?.data?.payload?.errorCode === 1) {
        throw new AlreadyPurchasedError(message);
      }
      throw isNotNullish(message) ? new Error(message) : e;
    });
}

function checkOneTimePayment(spaceId: number): Promise<true> {
  return defaultSpacesApi
    .spacesSpaceIdIsPurchasedPost(spaceId)
    .then(() => true as const)
    .catch(e => {
      const message = e.response?.data?.message;
      if (e.response?.data?.payload?.errorCode === 1) {
        throw new AlreadyPurchasedError(message);
      }
      throw isNotNullish(message) ? new Error(message) : e;
    });
}

function checkSubscription(spaceId: number): Promise<true> {
  return defaultSpacesApi
    .spacesSpaceIdSubscriptionGet(spaceId)
    .then(r => apiResponseAdHoc(r.data))
    .then(subscription => {
      // Has an active subscription.
      if (subscription.isActive && subscription.cancelledAt == null) {
        throw new AlreadyPurchasedError('subscription is active');
      } else {
        return true as const;
      }
    })
    .catch(e => {
      const ERROR_CODE_NOT_FOUND = 6 as const;
      const message = e.response?.data?.message;

      // Never subscribed.
      if (e.response?.data?.payload?.errorCode === ERROR_CODE_NOT_FOUND) {
        return true as const;
      }

      throw isNotNullish(message) ? new Error(message) : e;
    });
}

const checkPurchaseApiMap: Record<SpacePurchaseType, (id: number) => Promise<true>> = {
  oneTime: checkOneTimePayment,
  subscription: checkSubscription,
};

export async function checkNotPurchased(id: number, type: SpacePurchaseType): Promise<true> {
  SpacePurchaseTypeEnum.parse(type);
  return await checkPurchaseApiMap[type](id);
}

const registerInAppPurchaseApiMap: Record<
  SpacePurchaseType,
  (spaceId: number, options: { os: 'ios'; transactionId: string }) => Promise<unknown>
> = {
  oneTime: defaultSpacesApi.spacesSpaceIdInAppPurchasePost,
  subscription: defaultSpacesApi.spacesSpaceIdInAppSubscriptionPost,
};

export async function registerInAppPurchasedSpace({
  spaceId,
  platform,
  transactionId,
  type,
}: {
  spaceId: number;
  platform: 'ios';
  transactionId: string;
  type: SpacePurchaseType;
}) {
  return registerInAppPurchaseApiMap[type](spaceId, { os: platform, transactionId });
}

export function cancelChannelSubscription(spaceId: number): Promise<unknown> {
  return defaultSpacesApi.spacesSpaceIdUnsubscribePost(spaceId).then(r => apiResponseAdHoc(r.data));
}
