import { Button, Input, TextArea } from '@sp/ui/elements';
import { IconCaretLeft, IconPlus } from '@sp/ui/icons';
import { cls } from '@sp/util/helpers';
import { useStore } from 'effector-react';
import { ChangeEventHandler, useCallback, useMemo, useRef, VFC } from 'react';
import { ImagePicker } from '../../../utils/image-picker';
import { TestimonialFormItemState, TESTIMONIALS_FORM_MODEL } from './testimonials-form-model';

function bindTestimonialField<Property extends keyof TestimonialFormItemState>(
  testimonial: TestimonialFormItemState,
  property: Property,
): {
  value: TestimonialFormItemState[Property];
  onChange: ChangeEventHandler<{ value: TestimonialFormItemState[Property] }>;
} {
  return {
    value: testimonial[property],
    onChange: e => TESTIMONIALS_FORM_MODEL.form.patch({ patch: { [property]: e.target.value }, key: testimonial._id }),
  };
}

function getTestimonialFieldId<Property extends keyof TestimonialFormItemState>(property: Property, key: string) {
  return `testimonial-${property}-${key}`;
}

const useTestimonialsForm = () => {
  const state = useStore(TESTIMONIALS_FORM_MODEL.form.$state);
  const isValid = useStore(TESTIMONIALS_FORM_MODEL.form.$isValid);

  return useMemo(
    () => ({
      add: TESTIMONIALS_FORM_MODEL.form.add,
      remove: TESTIMONIALS_FORM_MODEL.form.remove,
      patch: TESTIMONIALS_FORM_MODEL.form.patch,
      reset: TESTIMONIALS_FORM_MODEL.form.reset,
      state,
      isValid,
    }),
    [isValid, state],
  );
};

function scrollToChildAtIndex(container: HTMLElement, index: number): void {
  const element = container.children[index];
  if (!element) return;

  // gap-2
  const xOffset = -16;

  container.scrollTo({
    left:
      element.getBoundingClientRect().left - container.getBoundingClientRect().left + container.scrollLeft + xOffset,
    behavior: 'smooth',
  });
}

function scrollToLastTestimonial(container: HTMLElement): void {
  // account for the "add" button.
  scrollToChildAtIndex(container, container.children.length - 2);
}

export const TestimonialsForm: VFC = () => {
  const testimonialsContainerRef = useRef<HTMLDivElement>(null);
  const testimonials = useTestimonialsForm();

  const isTestimonialsEmpty = useMemo(() => testimonials.state.length === 0, [testimonials.state.length]);

  const testimonialImageLoadingKeys = useStore(TESTIMONIALS_FORM_MODEL.coversUploader.$loadingKeys);

  const checkTestimonialImageLoading = useCallback(
    (key: string) => testimonialImageLoadingKeys.includes(key),
    [testimonialImageLoadingKeys],
  );

  const handleAddTestimonial = useCallback(() => {
    TESTIMONIALS_FORM_MODEL.form.add();
    setTimeout(() => testimonialsContainerRef.current && scrollToLastTestimonial(testimonialsContainerRef.current));
  }, []);

  const handleRemoveTestimonial = useCallback(
    (testimonial: TestimonialFormItemState) => TESTIMONIALS_FORM_MODEL.form.remove(testimonial._id),
    [],
  );

  const handleMoveTestimonial = useCallback((testimonial: TestimonialFormItemState, index: number) => {
    TESTIMONIALS_FORM_MODEL.form.moveItem({ key: testimonial._id, index });
    setTimeout(() => testimonialsContainerRef.current && scrollToChildAtIndex(testimonialsContainerRef.current, index));
  }, []);

  return (
    <div className="flex flex-col break-inside-avoid-column mt-4">
      <div className="flex flex-row items-center justify-between gap-1">
        <label>Testimonials</label>
      </div>

      <div
        ref={testimonialsContainerRef}
        className="mt-4 p-4 -mx-4 md:mx-0 flex flex-row items-center gap-4 overflow-x-auto border border-stroke"
      >
        {testimonials.state.map((testimonial, index) => (
          <div
            key={testimonial._id}
            className="w-[calc(100vw-2rem)] md:w-96 flex-none flex flex-col gap-4 border rounded-xl p-2 border-stroke"
          >
            <div className="flex flex-row items-center gap-2">
              <label>
                #{index + 1}/{testimonials.state.length}
              </label>
              <Button size="sm" disabled={index === 0} onClick={() => handleMoveTestimonial(testimonial, index - 1)}>
                <IconCaretLeft size={20} />
              </Button>
              <Button
                size="sm"
                disabled={index === testimonials.state.length - 1}
                onClick={() => handleMoveTestimonial(testimonial, index + 1)}
              >
                <IconCaretLeft size={20} className="rotate-180" />
              </Button>

              <Button className="ml-auto" size="sm" onClick={() => handleRemoveTestimonial(testimonial)}>
                Remove
              </Button>
            </div>
            <div className="flex flex-col">
              <label htmlFor={getTestimonialFieldId('title', testimonial._id)}>Title</label>
              <Input
                id={getTestimonialFieldId('title', testimonial._id)}
                type="text"
                {...bindTestimonialField(testimonial, 'title')}
              />
            </div>

            <div className="flex flex-col">
              <label htmlFor={getTestimonialFieldId('authorName', testimonial._id)}>Author Name</label>
              <Input
                id={getTestimonialFieldId('authorName', testimonial._id)}
                type="text"
                {...bindTestimonialField(testimonial, 'authorName')}
              />
            </div>

            <div className="flex flex-col">
              <label className="mb-1">Author Picture</label>
              <ImagePicker
                currentUrl={testimonial.avatar?.url}
                onChange={file => TESTIMONIALS_FORM_MODEL.coversUploader.upload({ key: testimonial._id, file })}
                isLoading={checkTestimonialImageLoading(testimonial._id)}
              />
            </div>

            <div className="flex flex-col">
              <label htmlFor={getTestimonialFieldId('review', testimonial._id)}>Review</label>
              <TextArea
                id={getTestimonialFieldId('review', testimonial._id)}
                minRows={4}
                maxRows={10}
                {...bindTestimonialField(testimonial, 'review')}
              />
            </div>
          </div>
        ))}

        <div className={cls('-ml-2 flex items-center justify-center', isTestimonialsEmpty && 'flex-grow')}>
          <Button className="!p-4" color="primary" onClick={handleAddTestimonial}>
            <IconPlus size={24} />
          </Button>
        </div>
      </div>
    </div>
  );
};
