import isEqual from 'lodash/isEqual';
import {
  forwardRef,
  ForwardRefExoticComponent,
  ForwardRefRenderFunction,
  MutableRefObject,
  PropsWithoutRef,
  Ref,
  RefAttributes,
  RefCallback,
  RefObject,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { generateUniqueString } from './string-helpers';

export function useId(): string {
  return useRef(generateUniqueString()).current;
}

export function usePrevious<V>(value: V) {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef<V>();
  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes
  // Return previous value (happens before update in useEffect above)
  return ref.current;
}

export function useRefWithCallback<Node = Element>(
  onMount: (node: Node) => void,
  onUnmount: (node: Node) => void,
): [(node: Node | null) => void, RefObject<Node | null>] {
  const nodeRef = useRef<Node | null>(null);

  const callback = useCallback(
    node => {
      const previous = nodeRef.current;

      if (previous) {
        onUnmount(previous);
      }

      nodeRef.current = node;

      if (nodeRef.current) {
        onMount(nodeRef.current);
      }
    },
    [onMount, onUnmount],
  );

  return [callback, nodeRef];
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function forwardRefWithDefault<Ref, Props = {}>(
  render: ForwardRefRenderFunction<Ref, Props>,
): ForwardRefExoticComponent<PropsWithoutRef<Props> & RefAttributes<Ref>> {
  const forwardedComponent = forwardRef<Ref, Props>((props, externalRef) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const localRef = useRef<Ref>(null);
    const ref = externalRef || localRef;

    return render(props, ref);
  });

  forwardedComponent.displayName = `forwardRefWithLocal(${render.displayName || render.name || 'Component'})`;

  return forwardedComponent;
}

export function useDeepCompareMemoize<T>(value: T): T {
  const ref = useRef<T>(value);

  if (!isEqual(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
}

export function updateRef<T>(ref: Ref<T> | undefined, value: T) {
  if (ref == null) return;

  if (ref instanceof Function) {
    (ref as RefCallback<T>)(value);
  } else {
    (ref as MutableRefObject<T>).current = value;
  }
}

export function combineRefs<T>(...refs: (RefCallback<T> | MutableRefObject<T> | undefined | null)[]): RefCallback<T> {
  return (current: T) => {
    for (const ref of refs) {
      if (ref == null) return;
      updateRef(ref, current);
    }
  };
}

export function useIsFirstRender(): boolean {
  const isFirst = useRef(true);

  if (isFirst.current) {
    isFirst.current = false;
    return true;
  }

  return false;
}

export function useOnFirstRender(fn: VoidFunction): void {
  useIsFirstRender() && fn();
}
