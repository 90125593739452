import { ChatMessageUploadableAttachment, ChatMessageViewAttachment } from '@sp/util/chat-attachments';
import { ChatAttachment } from '@sp/util/stream-chat';
import { PlatformApiFactory } from '../api/admin';
import { apiResponseAdHoc } from './helpers';

const adminApi = PlatformApiFactory();

export enum PlatformPostType {
  onboarding = 'onboarding',
  afterEvent = 'afterEvent',
  realtime = 'realtime',
  deferred = 'deferred',
}

export type PlatformPostTypeUnion = `${PlatformPostType}`;

export enum FormType {
  permission = 'permission',
  rate = 'rate',
}

export enum EventType {
  tripleMarkAsComplete = 'tripleMarkAsComplete',
}

export type EventTypeUnion = `${EventType}`;

export interface PlatformPost {
  id: number;

  createdAt?: number;

  updatedAt?: number | null;

  publishedAt?: number | null;
  publishAt?: number | null;

  content: {
    text?: string;
    attachments?: (ChatMessageViewAttachment | ChatMessageUploadableAttachment)[];
    selfplusMeta?: { formType?: FormType; isMuted?: boolean };
  };

  order: number;

  type: PlatformPostTypeUnion;

  event?: EventTypeUnion;

  sentAt?: number | null;

  eventParams?: object | null;
}

export interface PlatformPostData {
  content: { text?: string; attachments?: ChatAttachment[]; selfplusMeta?: { formType?: FormType; isMuted?: boolean } };
  order?: number;
  type: PlatformPostTypeUnion;
  event?: EventTypeUnion;
  eventParams?: object | null;
  publishAt?: number | null;
  sentAt?: number | null;
}

export async function getPosts(): Promise<PlatformPost[]> {
  return adminApi.platformPostsGet().then(r => apiResponseAdHoc(r.data));
}

export async function createPost(data: PlatformPostData): Promise<PlatformPost> {
  return adminApi.platformPostsPost(data).then(r => apiResponseAdHoc(r.data));
}

export async function updatePost(postId: number, data: PlatformPostData): Promise<PlatformPost> {
  return adminApi.platformPostsPostIdPatch(postId, data).then(r => apiResponseAdHoc(r.data));
}

export async function deletePost(postId: number) {
  return adminApi.platformPostsPostIdDelete(postId).then(r => apiResponseAdHoc(r.data));
}

export async function publishPost(postId: number) {
  return adminApi.platformPostsPostIdPublishPost(postId).then(r => apiResponseAdHoc(r.data));
}

export async function publishTestPost(postId: number) {
  return adminApi.platformPostsPostIdTestPost(postId).then(r => apiResponseAdHoc(r.data));
}

export async function reorderPosts(posts: { id: number; order: number }[]) {
  return adminApi.platformPostsOrderPost({ posts }).then(r => apiResponseAdHoc(r.data));
}
