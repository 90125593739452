import { ChatMessageBodyView } from '@sp/ui/chat';
import { isEmptyDoc, parseMessageContent } from '@sp/util/text-editor';
import { createEvent } from 'effector';
import { useEvent } from 'effector-react';
import { FC, MouseEvent } from 'react';
import { useChatMessage } from './chat-message-context';

export const messageClicked = createEvent<MouseEvent<HTMLDivElement>>();

export const ChatMessageBody: FC = () => {
  const { message } = useChatMessage();

  const handleMessageClick = useEvent(messageClicked);

  const doc = parseMessageContent(message.text ?? '');
  const content = isEmptyDoc(doc) ? null : message.text;

  return content ? <ChatMessageBodyView onClick={handleMessageClick} text={content} /> : null;
};
