import { isInApp } from './helpers';

export function trackEvent(eventName: string, properties: Record<string, string>) {
  if (isInApp()) {
    window.native.analytics.event('track', { eventName, properties });
  }
}

export function trackTime(eventName: string) {
  if (isInApp()) {
    window.native.analytics.event('time', { eventName });
  }
}

export function identifyUser(userId: string) {
  if (isInApp()) {
    window.native.analytics.people('identify', { userId });
  }
}

export function setUserProperty(property: string, value: string) {
  if (isInApp()) {
    window.native.analytics.people('set', { property, value });
  }
}

export function unsetUserProperty(property: string) {
  if (isInApp()) {
    window.native.analytics.people('unset', { property });
  }
}

export function appendToUserListProperty(list: string, value: string) {
  if (isInApp()) {
    window.native.analytics.people('append', { list, value });
  }
}

export function incrementUserProperty(property: string, by: number) {
  if (isInApp()) {
    window.native.analytics.people('increment', { property, by });
  }
}

export function chargeUser(amount: number) {
  if (isInApp()) {
    window.native.analytics.people('charge', { amount });
  }
}

export function addUserToGroup(group: string, id: string) {
  if (isInApp()) {
    window.native.analytics.group('add', { group, id });
  }
}

export function removeUserFromGroup(group: string, id: string) {
  if (isInApp()) {
    window.native.analytics.group('remove', { group, id });
  }
}
