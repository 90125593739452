import { AUTH_MODEL, USER_MODEL } from '@sp/data/auth';
import { getStreamChatToken, MeUser } from '@sp/data/bif';
import { ENV_MODE, EnvMode, STREAM_CHAT_API_KEY } from '@sp/data/env';
import { isNotNullish } from '@sp/util/helpers';
import { ChatClient, ChatUser, ConnectUserData, SelfPlusExtendedGenerics } from '@sp/util/stream-chat';
import { createEffect, createEvent, createStore, merge, restore, sample } from 'effector';
import { condition } from 'patronum';
import { StreamChat } from 'stream-chat';
import { streamChatLogger, webSocketConnectionStuck } from './logger';

const client = new StreamChat<SelfPlusExtendedGenerics>(STREAM_CHAT_API_KEY, {
  timeout: 10000,
  logger: streamChatLogger,
});

declare global {
  interface Window {
    streamChat?: ChatClient;
  }
}

if (ENV_MODE !== EnvMode.production) {
  window.streamChat = client;
  console.info('Stream Chat Client API: window.streamChat');
}

const connectUserFx = createEffect((user: ConnectUserData) => client.connectUser(user as ChatUser, getStreamChatToken));

const disconnectUserFx = createEffect(() => client.disconnectUser());

const $isInitialized = restore(merge([connectUserFx.done.map(() => true), disconnectUserFx.map(() => false)]), false);

const init = createEvent<MeUser | null>();

const $connectUserData = createStore<ConnectUserData | null>(null).on([init, USER_MODEL.$user], (data, user) => {
  if (user === null) return null;

  if (data?.id === user.id.toString()) return data;

  return {
    id: user.id.toString(),
    name: user.name,
    image: user.avatar?.url ?? '',
  };
});

condition({
  source: $connectUserData,
  if: isNotNullish,
  then: connectUserFx,
  else: disconnectUserFx,
});

sample({
  source: USER_MODEL.$user,
  clock: AUTH_MODEL.$isReady,
  target: init,
});

export const STREAM_CHAT_MODEL = {
  client,
  $isInitialized,
} as const;

export type StreamChatModel = typeof STREAM_CHAT_MODEL;

// Init connection
const closeFx = createEffect(() => STREAM_CHAT_MODEL.client.closeConnection());
const openFx = createEffect(() => STREAM_CHAT_MODEL.client.openConnection());

const restartFx = createEffect(async () => {
  await closeFx();
  await openFx();
});

sample({
  clock: restartFx.failData,
  target: createEffect(console.error),
});

sample({
  clock: webSocketConnectionStuck,
  target: restartFx,
});
