import { createSpace, SPACE_PAYMENT_TYPE, SPACE_TYPE } from '@sp/data/bif';
import { createShowAlertFx } from '@sp/util/helpers';
import { createEffect, sample } from 'effector';
import { createGate } from 'effector-react';
import { LIVE_SPACE_FORM_MODEL, LiveSpaceFormState } from './live-space-form';

export const CreateLiveGate = createGate();

const createLiveSpaceFx = createEffect(({ details, testimonials }: LiveSpaceFormState) => {
  return createSpace({
    space: {
      title: details.title,
      slug: details.slug,
      type: SPACE_TYPE.live,
      coverFileId: details.cover.id,
      price: details.price,
      payment_type: SPACE_PAYMENT_TYPE.fixed_price,
      config: {
        live: {
          startAt: details.startAt,
          record: details.videoRecording
            ? {
                videoFileId: details.videoRecording.id,
                coverFileId: details.videoRecordingCover?.id ?? null,
                meta: { duration: details.videoRecordingDuration },
              }
            : null,
          linkToZoom: details.linkToZoom,
          creatorDescription: details.creatorDescription,
          description: details.description,
          duration: details.duration,
          requirements: details.requirements,
          offerDescription: details.offerDescription,
          offerSummary: details.offerSummary,
          testimonials: testimonials.map(t => ({
            title: t.title,
            authorName: t.authorName,
            review: t.review,
            avatarFileId: t.avatar?.id ?? null,
          })),
        },
      },
    },
  });
});

sample({
  clock: CreateLiveGate.status,
  target: LIVE_SPACE_FORM_MODEL.reset,
});

sample({
  source: CreateLiveGate.status,
  clock: LIVE_SPACE_FORM_MODEL.validFormSubmitted,
  filter: isOpen => isOpen,
  fn: (_, state) => state,
  target: createLiveSpaceFx,
});

sample({
  clock: createLiveSpaceFx.fail,
  target: createShowAlertFx('Failed to create Live space'),
});

export const CREATE_LIVE_MODEL = {
  $isLoading: createLiveSpaceFx.pending,
  created: createLiveSpaceFx.doneData,
};
