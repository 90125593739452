import { FileAttachmentIconPart, FileAttachmentNamePart } from '@sp/ui/chat';
import { isUploadable, UploadableFileAttachment } from '@sp/util/chat-attachments';
import { FileAttachment } from '@sp/util/stream-chat';
import { FC } from 'react';

const WRAP_CLASSNAME =
  'w-full flex flex-col items-start justify-center gap-1 h-16 p-2 overflow-hidden rounded-1.5lg bg-secondary border-stroke border cursor-pointer no-underline';

export const EditorAttachmentFileView: FC<{
  attachment: UploadableFileAttachment | FileAttachment;
}> = ({ attachment }) => {
  const isUploadableAttachment = isUploadable(attachment);

  if ((isUploadableAttachment && attachment.status !== 'ready') || !attachment.url) {
    return (
      <div className={WRAP_CLASSNAME}>
        <FileAttachmentIconPart type={attachment.fileType} size={24} />
        <FileAttachmentNamePart name={attachment.name} />
      </div>
    );
  }

  return (
    <a
      href={attachment.url}
      rel="noopener noreferrer"
      download={attachment.name}
      target="_blank"
      className={WRAP_CLASSNAME}
    >
      <FileAttachmentIconPart type={attachment.fileType} size={24} />
      <FileAttachmentNamePart name={attachment.name} />
    </a>
  );
};
