import { ConnectedField } from 'effector-forms';
import { ChangeEventHandler } from 'react';

export function bindFieldValue<Value>(field: ConnectedField<Value>): {
  value: Value;
  onChange: ChangeEventHandler<{ value: Value }>;
} {
  return {
    value: field.value,
    onChange: e => field.onChange(e.target.value),
  };
}

export type BindFieldAdapter<Value, Source> = {
  input: (value: Value) => Source;
  output: (source: Source) => Value;
};

export function bindFieldValueWithAdapter<Value, Source>(
  field: ConnectedField<Value>,
  { input, output }: BindFieldAdapter<Value, Source>,
): { value: Source; onChange: ChangeEventHandler<{ value: Source }> } {
  return { value: input(field.value), onChange: e => field.onChange(output(e.target.value)) };
}
