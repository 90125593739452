import { cls } from '@sp/util/helpers';
import { parseMessageContent, renderDocToHtml } from '@sp/util/text-editor';
import { useMemo } from 'react';

export const HtmlDocRenderer = ({ text: content, quoted = false }: Readonly<{ text: string; quoted?: boolean }>) => {
  const doc = useMemo(() => parseMessageContent(content), [content]);
  const innerHtml = useMemo(() => ({ __html: renderDocToHtml(doc) }), [doc]);

  return (
    <div className={cls('remirror-theme sp-remirror', quoted ? 'sp-remirror-quoted' : null)}>
      <div className="remirror-editor-wrapper">
        <div className="ProseMirror remirror-editor" dangerouslySetInnerHTML={innerHtml} />
      </div>
    </div>
  );
};
