import { IconGhost } from '@sp/ui/icons';
import { cls } from '@sp/util/helpers';
import { FC } from 'react';

export const ErrorMessage: FC<{ className?: string }> = ({ children, className = '' }) => {
  return (
    <div
      className={cls(className, 'border border-[#EA553A] bg-[#FCEDE8] rounded-xl flex flex-row items-start gap-2 p-4')}
    >
      <IconGhost size={32} />
      <div className="flex-1 leading-5">{children}</div>
    </div>
  );
};
