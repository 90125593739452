import {
  ChatMessageBodyContainer,
  ChatMessageContainer,
  ChatMessageFooterContainer,
  ChatMessageLeftContainer,
  ChatMessageRightContainer,
} from '@sp/ui/chat';
import { cls, isNotNullish } from '@sp/util/helpers';
import { ChatFormatMessage } from '@sp/util/stream-chat';
import { memo, ReactElement, useCallback, useMemo } from 'react';
import { ChatMessageBottomAttachments, ChatMessageTopAttachments } from './elements/chat-message-attachments';
import { ChatMessageAvatar } from './elements/chat-message-avatar';
import { ChatMessageBody } from './elements/chat-message-body';
import { ChatMessageModel, ChatMessageProvider } from './elements/chat-message-context';
import { ChatMessageHeader } from './elements/chat-message-header';
import { ChatMessageReactions } from './elements/chat-message-reactions';
import { ChatMessageReply } from './elements/chat-message-reply';
import { useMessageExtraContent } from './use-message-extra-content';
import { useMessageTemplate } from './use-message-template';

export const ChatMessageListItem = memo(function ChatMessageListItem({
  model,
  message,
  onLongPress,
}: Readonly<{
  message: ChatFormatMessage;
  model: ChatMessageModel;
  onLongPress: (message: ChatFormatMessage) => void;
}>): ReactElement {
  const { beforeText, afterAvatar, afterUserName, platformForm, showEditButton } = useMessageExtraContent();

  const handleLongPress = useCallback(() => onLongPress(message), [onLongPress, message]);
  const getCustomTemplate = useMessageTemplate();
  const customTemplate = getCustomTemplate ? getCustomTemplate(message) : undefined;

  return (
    <ChatMessageProvider value={useMemo(() => ({ message, model }), [message, model])}>
      <ChatMessageContainer
        className={cls('transition duration-500 bg-white pl-3 pr-6 py-3 longpress')}
        onLongPress={handleLongPress}
      >
        {isNotNullish(customTemplate) ? (
          customTemplate
        ) : (
          <>
            <ChatMessageLeftContainer>
              <ChatMessageAvatar />
              {afterAvatar?.(message)}
            </ChatMessageLeftContainer>

            <ChatMessageRightContainer>
              <ChatMessageHeader
                afterUserNameContent={afterUserName?.(message.user)}
                showEditButton={showEditButton?.(message)}
                onEditClick={() => onLongPress(message)}
              />
              {beforeText?.(message)}

              <ChatMessageBodyContainer>
                <ChatMessageReply />

                <ChatMessageTopAttachments />

                <ChatMessageBody />
              </ChatMessageBodyContainer>

              <ChatMessageBottomAttachments />
              {platformForm?.(message)}

              <ChatMessageFooterContainer>
                <ChatMessageReactions />
              </ChatMessageFooterContainer>
            </ChatMessageRightContainer>
          </>
        )}
      </ChatMessageContainer>
    </ChatMessageProvider>
  );
});
