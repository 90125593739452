import { USER_MODEL } from '@sp/data/auth';
import { NotificationSettings, patchUserProfile } from '@sp/data/bif';
import { createEffect, createEvent, sample } from 'effector';
import { debounce } from 'patronum';

const PERSONAL_NOTIFICATIONS: (keyof NotificationSettings)[] = [
  'newMentionsInChallenges',
  'newMessagesInDMs',
  'newRepliesInChallenges',
];
const IMPORTANT_NOTIFICATIONS: (keyof NotificationSettings)[] = [
  'newCreatorsMessagesInChallenges',
  'newPlatformMessages',
];
const OTHER_NOTIFICATIONS: (keyof NotificationSettings)[] = ['newMessagesInChallenges'];

export type NotificationsGroup = 'personal' | 'important' | 'other';

const notificationSwitched = createEvent<{ value: boolean; type: NotificationsGroup }>();
const $notificationsMap = USER_MODEL.$user.map(
  user =>
    user?.notifications ?? {
      newMessagesInChallenges: false,
      newMentionsInChallenges: false,
      newCreatorsMessagesInChallenges: false,
      newMessagesInDMs: false,
      newPlatformMessages: false,
      newRepliesInChallenges: false,
    },
);

const $personalNotifications = $notificationsMap.map(notifications =>
  PERSONAL_NOTIFICATIONS.every(n => notifications[n]),
);
const $importantNotifications = $notificationsMap.map(notifications =>
  IMPORTANT_NOTIFICATIONS.every(n => notifications[n]),
);
const $otherNotifications = $notificationsMap.map(notifications => OTHER_NOTIFICATIONS.every(n => notifications[n]));

const switchNotificationFx = createEffect(
  async (data: { notificationsMap: NotificationSettings; req: { value: boolean; type: NotificationsGroup } }) => {
    let notifications: NotificationSettings;

    if (data.req.type === 'personal') {
      notifications = {
        ...data.notificationsMap,
        ...PERSONAL_NOTIFICATIONS.reduce((a, v) => ({ ...a, [v]: data.req.value }), {}),
      };
    } else if (data.req.type === 'important') {
      notifications = {
        ...data.notificationsMap,
        ...IMPORTANT_NOTIFICATIONS.reduce((a, v) => ({ ...a, [v]: data.req.value }), {}),
      };
    } else if (data.req.type === 'other') {
      notifications = {
        ...data.notificationsMap,
        ...OTHER_NOTIFICATIONS.reduce((a, v) => ({ ...a, [v]: data.req.value }), {}),
      };
    } else {
      notifications = { ...data.notificationsMap };
    }
    return { notifications };
  },
);

const loadNotificationFx = createEffect((notifications: NotificationSettings) => {
  return patchUserProfile({ notifications });
});

debounce({
  source: $notificationsMap,
  target: loadNotificationFx,
  timeout: 1500,
});

sample({
  source: $notificationsMap,
  clock: notificationSwitched,
  target: switchNotificationFx,
  fn: (notificationsMap, req) => ({ notificationsMap, req }),
});
sample({ source: switchNotificationFx.doneData, target: USER_MODEL.patch });

export const NOTIFICATION_MODEL = {
  $personalNotifications,
  $importantNotifications,
  $otherNotifications,
  notificationSwitched,
};
