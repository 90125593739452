import { ChatMessageReactionsView } from '@sp/ui/chat';
import { ReactElement, useCallback, useMemo } from 'react';
import { REACTIONS_MODAL_MODEL } from '../reactions-modal/reactions-modal-model';
import { useChatMessage } from './chat-message-context';

export function ChatMessageReactions(): ReactElement {
  const { model, message } = useChatMessage();

  const onToggleReaction = useCallback(
    (type: string) => {
      if (message.own_reactions?.find(r => r.type === type)) {
        model.reactionDeleted({ messageId: message.id, type });
      } else {
        model.reactionAdded({ messageId: message.id, type });
      }
    },
    [message.own_reactions, message.id, model],
  );

  const reactions = useMemo(
    () =>
      message.reaction_counts == null
        ? []
        : Object.entries(message.reaction_counts).map(([type, count]) => ({
            type,
            count,
            isActive: message.own_reactions?.find(r => r.type === type) !== undefined,
          })),
    [message.own_reactions, message.reaction_counts],
  );

  const onShowDetails = useCallback(() => {
    REACTIONS_MODAL_MODEL.isModalOpenSet(true);
    REACTIONS_MODAL_MODEL.reactionLoadStarted(message.id);
  }, [message.id]);

  return (
    <ChatMessageReactionsView reactions={reactions} onShowDetails={onShowDetails} onToggleReaction={onToggleReaction} />
  );
}
