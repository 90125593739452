import { GetMarkRange, MarkType, ProsemirrorNode } from '@remirror/core';

type NodeIterator = (
  node: ProsemirrorNode,
  pos: number,
  parent: ProsemirrorNode | null,
  index: number,
) => void | boolean;

export function createGetAllMarksByTypeIterator(matches: GetMarkRange[], markType: MarkType): NodeIterator {
  return (node, pos) => {
    const marks = node.marks ?? [];
    const mark = marks.find(({ type }) => type === markType);

    if (mark) {
      matches.push({
        from: pos,
        to: pos + node.nodeSize,
        mark,
        text: node.textContent,
      });
    }

    return true;
  };
}

export function getAllMarksByType(doc: ProsemirrorNode, markType: MarkType): GetMarkRange[] {
  const matches: GetMarkRange[] = [];
  doc.descendants(createGetAllMarksByTypeIterator(matches, markType));
  return matches;
}
