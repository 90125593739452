export const enum SPACE_TYPE {
  post = 'post',
  live = 'live',
  challenge = 'challenge',
  trailer = 'trailer',
  channel = 'channel',
  community = 'community',
}

export const enum SPACE_STATUS {
  draft = 'draft',
  published = 'published',
}

export const enum SPACE_PAYMENT_TYPE {
  fixed_price = 'fixed_price',
  subscribers_only = 'subscribers_only',
  fixed_price_subscribers_only = 'fixed_price_subscribers_only',
  monthly_price = 'monthly_price',
  free = 'free',
}

export const enum CHALLENGE_POST_TYPE {
  step = 'step',
  intro = 'intro',
  finish = 'finish',
  deferred = 'deferred',
}

export type SpaceCover = Readonly<{ id: number; url: string }> | null;

export const enum SubscriptionType {
  foreverFree = 'foreverFree',
  freeTrial = 'freeTrial',
  paidTrial = 'paidTrial',
  fullPrice = 'fullPrice',
}

export type SubscriptionInfo = {
  readonly id: number;
  readonly createdAt: number;
  readonly lastPaymentDate: number | null;
  readonly startedAt: number | null;
  readonly cancelledAt: number | null;
  readonly price: number;
  readonly isActive: true;
  readonly nextPaymentDate: number | null;
  readonly inApp: boolean;
  readonly type: SubscriptionType;
};
