import { format, startOfDay, subDays } from 'date-fns';
import { ReactElement } from 'react';

const DAY_SEPARATOR_FORMAT = 'd MMM';
const TODAY_START_OF_DAY = startOfDay(new Date()).getTime();
const YESTERDAY_START_OF_DAY = startOfDay(subDays(new Date(), 1)).getTime();

function getFormattedDate(timestamp: number): string {
  if (TODAY_START_OF_DAY === timestamp) {
    return 'Today';
  } else if (YESTERDAY_START_OF_DAY === timestamp) {
    return 'Yesterday';
  }

  return format(timestamp, DAY_SEPARATOR_FORMAT);
}

export function DaySeparator({ timestamp }: Readonly<{ timestamp: number }>): ReactElement {
  const formatDay = getFormattedDate(timestamp);
  return <div className="text-primary text-xs font-semibold text-center whitespace-nowrap">{formatDay}</div>;
}

// export function DayInformer({
//   message,
//   stepWithUI,
// }: Readonly<{ message: ChatFormatMessage; stepWithUI: ChallengeStepWithUI | null }>): ReactElement {
//   const formatDay = getFormattedDate(startOfDay(message.created_at).getTime());
//   const dayName = stepWithUI?.step.dayName;
//   return (
//     <div className="text-primary text-xs font-semibold text-center whitespace-nowrap">
//       {dayName} – {formatDay}
//     </div>
//   );
// }
