import { XANO_DATASOURCE } from '@sp/data/env';
import { isAudio, isImage, isVideo } from '@sp/util/files';
import axios from 'axios';
import { UploadApiFactory } from '../../api/default';
import { apiResponseAdHoc } from '../helpers';

const uploadApi = UploadApiFactory();

enum UploadFileType {
  audio = 'audio',
  video = 'video',
  image = 'image',
  other = 'other',
}

interface UploadUrlResponse {
  file: {
    id: number;
    createdAt: number;
    userId: number;
    name: string;
    type: UploadFileType;
    mime: string;
    url: string;
    originalUrl?: string;
    previewUrl?: string;
  };
  uploadUrl: string;
}

export async function getUploadUrl(fileParams: {
  name: string;
  type: UploadFileType;
  mime: string;
}): Promise<UploadUrlResponse> {
  return uploadApi.uploadUrlPost(fileParams).then(r => apiResponseAdHoc(r.data) as UploadUrlResponse);
}

export async function uploadFileToAWS(
  file: File,
  signal: AbortSignal = new AbortController().signal,
  onUploadProgress: (percentageProgress: number) => void = () => null,
): Promise<UploadUrlResponse['file']> {
  try {
    const { uploadUrl: url, file: uploadedFileData } = await getUploadUrl({
      name: encodeURIComponent(file.name),
      type: isAudio(file)
        ? UploadFileType.audio
        : isVideo(file)
        ? UploadFileType.video
        : isImage(file)
        ? UploadFileType.image
        : UploadFileType.other,
      mime: file.type,
    });
    const axiosCustom = axios.create({});
    return axiosCustom({
      url,
      data: file,
      method: 'PUT',
      headers: {
        'X-Amz-Meta-FileId': uploadedFileData.id,
        'X-Amz-Meta-DataSource': XANO_DATASOURCE,
        'content-type': file.type,
        'Content-Disposition': `attachment; filename="${encodeURIComponent(file.name)}"`,
        'Cache-Control': 'max-age=31536000,public',
      },
      signal,
      onUploadProgress: event => {
        onUploadProgress(Math.round((event.loaded * 100) / event.total));
      },
    }).then(() => uploadedFileData);
  } catch (e) {
    return Promise.reject(e);
  }
}
