import { ReactElement } from 'react';
import { ICON_DEFAULTS, IconProps } from './types';

export function IconCircleWavyCheck({
  size = ICON_DEFAULTS.size,
  color = ICON_DEFAULTS.color,
  className = ICON_DEFAULTS.className,
}: IconProps): ReactElement {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.40625 12.5938C2.83125 12.0188 3.2125 10.8125 2.91875 10.1062C2.625 9.4 1.5 8.78125 1.5 8C1.5 7.21875 2.6125 6.625 2.91875 5.89375C3.225 5.1625 2.83125 3.98125 3.40625 3.40625C3.98125 2.83125 5.1875 3.2125 5.89375 2.91875C6.6 2.625 7.21875 1.5 8 1.5C8.78125 1.5 9.375 2.6125 10.1062 2.91875C10.8375 3.225 12.0188 2.83125 12.5938 3.40625C13.1687 3.98125 12.7875 5.1875 13.0813 5.89375C13.375 6.6 14.5 7.21875 14.5 8C14.5 8.78125 13.3875 9.375 13.0813 10.1062C12.775 10.8375 13.1687 12.0188 12.5938 12.5938C12.0188 13.1687 10.8125 12.7875 10.1062 13.0813C9.4 13.375 8.78125 14.5 8 14.5C7.21875 14.5 6.625 13.3875 5.89375 13.0813C5.1625 12.775 3.98125 13.1687 3.40625 12.5938Z"
        fill={color}
      />
      <path d="M10.75 6.5L7.08125 10L5.25 8.25" stroke="#F9F9F9" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
