import { ChallengePost, ChallengeSpaceMeta } from '@sp/data/bif';
import { CHAT_CHANNEL } from '@sp/feature/chat-channel';
import { isNullish, switchMap } from '@sp/util/helpers';
import { ChatEvent, ChatEventTypes, PostType } from '@sp/util/stream-chat';
import { createDomain } from 'effector';
import update from 'immutability-helper';
import { CHALLENGE_MODEL } from '../../challenge-model';
import { ChallengePostMap } from './posts.types';

const CHAT_EVENTS_TO_LISTEN: ChatEventTypes[] = [
  'message.new',
  // 'message.updated', //TODO[Aleksei Kataev] надо ли давать редактировать?
  'reaction.new',
  'reaction.updated',
  'reaction.deleted',
];
const postsDomain = createDomain();

const channelEventFired = postsDomain.createEvent<ChatEvent>();

function parseStepsRawPosts(meta: ChallengeSpaceMeta): ChallengePostMap {
  return {
    intro: meta.intro.posts,
    finish: meta.finish.posts,
    ...meta.steps.reduce((acc, step) => {
      acc[step.id] = step.posts;
      return acc;
    }, {} as Record<number, readonly ChallengePost[]>),
  };
}

function channelEventReducer(map: ChallengePostMap, event: ChatEvent): ChallengePostMap {
  if (
    isNullish(event.message) ||
    isNullish(event.message.selfplusPostMeta) ||
    event.message.selfplusPostMeta.type === PostType.deferred
  ) {
    return map;
  }

  const { message: gsMessage } = event;
  const { id: gsMessageId, created_at } = gsMessage;
  const { type, postId } = event.message.selfplusPostMeta;

  const stepId = type === PostType.step ? event.message.selfplusPostMeta.stepId : type;

  const postIndex = map[stepId]?.findIndex(post => post.id === postId) ?? -1;

  if (postIndex === -1 || isNullish(created_at)) {
    return map;
  }

  return update(map, {
    [stepId]: {
      [postIndex]: {
        $merge: {
          gsMessageId,
          gsMessage,
          publishedAt: new Date(created_at).getTime(),
        },
      },
    },
  });
}

const $postsMap = postsDomain
  .createStore<ChallengePostMap>({ intro: [], finish: [] })
  .on(CHALLENGE_MODEL.$challenge, (_, { meta }) => parseStepsRawPosts(meta))
  .on(channelEventFired, channelEventReducer)
  .reset(CHALLENGE_MODEL.ChallengeGate.close);

switchMap({
  source: CHAT_CHANNEL.$instance,
  fn: instance => instance?.pickEvent(...CHAT_EVENTS_TO_LISTEN),
  target: channelEventFired,
});

export const POSTS_MODEL = { $postsMap };
