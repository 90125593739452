import { Button } from '@sp/ui/elements';
import {
  CROP_TAILWIND_ASPECT_RATIOS,
  FullScreenImageCropper,
  ImageCropperMode,
  useSelectCropModel,
} from '@sp/ui/image-cropper';
import { dataURItoBlob, GRAY_PIXEL_IMG_SRC } from '@sp/util/files';
import { cls, isNotNullish } from '@sp/util/helpers';
import { useCallback, useEffect, useState, VFC } from 'react';

export const ImagePicker: VFC<
  Readonly<{ currentUrl?: string; isLoading?: boolean; onChange: (file: File) => void; cropMode?: ImageCropperMode }>
> = ({ currentUrl, isLoading = false, onChange, cropMode = ImageCropperMode.squareImage }) => {
  const isSet = isNotNullish(currentUrl);

  const { selectImage, reset, isSelected, selectedImage, selectedImageDataUri } = useSelectCropModel();

  const [latestResult, setLatestResult] = useState<string | null>(null);

  useEffect(() => {
    setLatestResult(null);
  }, [currentUrl]);

  const handleSelect = useCallback(() => {
    if (isLoading) return;
    selectImage();
  }, [isLoading, selectImage]);

  const handleResized = useCallback(
    (resizedImageUri: string) => {
      if (isNotNullish(selectedImage)) {
        const name = selectedImage.name;
        const type = selectedImage.type;
        const newImage = new File([dataURItoBlob(resizedImageUri)], name, { type });
        onChange(newImage);
        reset();
        setLatestResult(resizedImageUri);
      }
    },
    [onChange, reset, selectedImage],
  );

  return (
    <>
      <div
        role="button"
        onClick={handleSelect}
        className={cls(
          `w-full ${CROP_TAILWIND_ASPECT_RATIOS[cropMode]} aspect-ratio-wrap`,
          isLoading && 'animate-pulse',
        )}
      >
        <img
          src={isLoading ? latestResult ?? GRAY_PIXEL_IMG_SRC : currentUrl ?? GRAY_PIXEL_IMG_SRC}
          alt="selected image"
          className="rounded-xl"
        />
      </div>

      <Button
        color={isSet ? undefined : 'primary'}
        className="mt-2"
        block
        loading={isLoading}
        disableOnLoad
        onClick={handleSelect}
      >
        {isSet ? 'update' : 'add'}
      </Button>

      <FullScreenImageCropper
        mode={cropMode}
        isOpen={isSelected}
        src={selectedImageDataUri}
        onResult={handleResized}
        onCancel={reset}
      />
    </>
  );
};
