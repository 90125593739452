import { IconLinkThin } from '@sp/ui/icons';
import { sanitizeUrl } from '@sp/util/links';
import { LinkAttachment } from '@sp/util/stream-chat';
import { ReactElement, useMemo, useState } from 'react';

export function EditorAttachmentLinkView({ attachment }: { attachment: LinkAttachment }): ReactElement | null {
  const [isFaviconError, setIsFaviconError] = useState(false);
  const sanitizedUrl = useMemo(() => sanitizeUrl(attachment.url), [attachment.url]);

  return (
    <a
      className="w-full no-underline flex flex-col justify-center text-xs p-2 h-16 overflow-hidden rounded-1.5lg bg-secondary border-stroke border block"
      href={sanitizedUrl}
      target="_blank"
      rel="noopener noreferrer"
    >
      <header className="mb-1">
        {attachment.previewUrl && !isFaviconError ? (
          <img
            src={attachment.previewUrl}
            alt={attachment.name}
            onError={() => setIsFaviconError(true)}
            className="w-5 h-5 overflow-hidden"
          />
        ) : (
          <IconLinkThin size={20} />
        )}
      </header>
      <div className="font-semibold text-ellipsis overflow-hidden whitespace-nowrap">{attachment.name}</div>
    </a>
  );
}
