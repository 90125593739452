import { getUserPurchases } from '@sp/data/bif';
import { BackButton, useSetPreviousUrl } from '@sp/feature/back-button';
import { LoaderBlock, SpinnerPage } from '@sp/ui/elements';
import { formatPriceRaw } from '@sp/util/format';
import { createResource, waitUntilTrue } from '@sp/util/helpers';
import { ROUTES } from '@sp/util/router';
import { format } from 'date-fns';
import { useStore } from 'effector-react';
import { not } from 'patronum';
import { FC, useEffect } from 'react';
import PullToRefresh from 'react-simple-pull-to-refresh';

const PURCHASES_MODEL = createResource(getUserPurchases);

function refresh() {
  PURCHASES_MODEL.load();
  return waitUntilTrue(not(PURCHASES_MODEL.$isLoading));
}

function formatDate(timestamp: number): string {
  return format(timestamp, 'P p');
}

// TODO[Dmitriy Teplov] handle loading error.
export const PurchasesPage: FC = () => {
  useSetPreviousUrl(ROUTES.PROFILE_URL);
  useEffect(() => PURCHASES_MODEL.load(), []);
  const purchases = useStore(PURCHASES_MODEL.$data);
  const isLoaded = useStore(PURCHASES_MODEL.$isFirstLoadFinished);
  const hasPurchases = purchases && purchases.length > 0;

  return (
    <div className="h-full flex flex-col overflow-y-hidden">
      <div className="flex justify-start items-center gap-2 px-2 py-5 pr-6 w-full">
        <BackButton />
        <h1 className="m-0 text-base tracking-normal">Purchases</h1>
      </div>
      <PullToRefresh
        onRefresh={refresh}
        pullingContent=""
        refreshingContent={
          <div className="flex items-center justify-center h-10">
            <LoaderBlock />
          </div>
        }
        pullDownThreshold={40}
      >
        <div className="flex-1 mobile-pan">
          {!isLoaded ? (
            <SpinnerPage />
          ) : hasPurchases ? (
            <table className="bg-transparent border-spacing-0 border-collapse table-auto w-full">
              <thead>
                <tr>
                  <th className="px-6 py-3">Purchase</th>
                  <th className="px-6 py-3 text-right">Price</th>
                </tr>
              </thead>
              <tbody>
                {purchases?.map(purchase => (
                  <tr key={purchase.space.id}>
                    <td className="border-t border-stroke p-6">
                      <div className="leading-6">{purchase.space.title}</div>
                      <div className="text-secondary text-sm mt-1">{formatDate(purchase.created_at)}</div>
                    </td>
                    <td className="border-t border-stroke p-6 text-right">
                      <span>{formatPriceRaw(purchase.price)}</span>
                      {purchase.inApp && <span> +&nbsp;VAT</span>}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div className="flex h-full items-center justify-center text-lg">No Purchases Yet</div>
          )}
        </div>
      </PullToRefresh>
    </div>
  );
};
