import { cloneElement, FC, useEffect, useState } from 'react';
import { Tab, TabPaneProps } from './types';

export const TabPane: FC<TabPaneProps> = ({ children, active, destroyInactiveTabPane }) => {
  const [visited, setVisited] = useState(false);

  useEffect(() => {
    if (active) {
      setVisited(true);
    } else if (destroyInactiveTabPane) {
      setVisited(false);
    }
  }, [active, destroyInactiveTabPane]);

  return <div className={`flex flex-col h-full ${active ? '' : 'hidden'}`}>{visited || active ? children : null}</div>;
};

export const TabPaneList: FC<{ tabs: Tab[]; activeKey: string; destroyInactiveTabPane?: boolean }> = ({
  tabs,
  activeKey,
  destroyInactiveTabPane,
}) => {
  return (
    <div className="flex-1">
      {tabs.map(tab => {
        return cloneElement(tab.node, {
          key: tab.key,
          tabKey: tab.key,
          active: tab.key === activeKey,
          destroyInactiveTabPane,
        });
      })}
    </div>
  );
};
