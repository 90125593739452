import {
  MessageFormContainer,
  MessageFormHandle,
  MessageFormTooltip,
  MessageModal,
  useMessageForm,
} from '@sp/feature/chat';
import { ChatChannelModel } from '@sp/feature/stream-chat';
import { Nullable } from '@sp/util/helpers';
import { ChatFormatMessage } from '@sp/util/stream-chat';
import { FC, useRef } from 'react';

export const FeedMessageFormWrapper: FC<{
  activeMessage: ChatFormatMessage | null;
  setActiveMessage: (message: ChatFormatMessage | null) => void;
  messageFormTooltip?: Nullable<MessageFormTooltip>;
  keyMapExtension?: (props: { submit: VoidFunction }) => null;
  readonly chatModel: ChatChannelModel;
}> = ({ messageFormTooltip, chatModel, setActiveMessage, activeMessage, keyMapExtension }) => {
  const messageFormRef = useRef<MessageFormHandle>(null);
  const formModel = useMessageForm(`feed-${chatModel.channel.id}`, { defaultFields: { pinned: true } });

  return (
    <>
      <MessageFormContainer
        keyMapExtension={keyMapExtension}
        formModel={formModel}
        messageFormRef={messageFormRef}
        chatModel={chatModel}
        messageFormTooltip={messageFormTooltip}
        withPreview={true}
      />
      <MessageModal
        formModel={formModel}
        chatModel={chatModel}
        messageFormRef={messageFormRef}
        activeMessage={activeMessage}
        setActiveMessage={setActiveMessage}
        config={{ reactions: true, feed: true, reply: true, copy: true, edit: true, delete: true }}
      />
    </>
  );
};
