import { AudioHistogram } from '@sp/ui/elements';
import { IconCheck, IconClose } from '@sp/ui/icons';
import { AudioRecorder, createAudioRecorder } from '@sp/util/files';
import { getMediaDurationString } from '@sp/util/format';
import { FC, useEffect, useRef, useState } from 'react';

const AUDIO_TIME_SLICE = 1000 / 15;
const RECORDER_OPTIONS: MediaRecorderOptions = {
  mimeType: MediaRecorder.isTypeSupported('audio/webm') ? 'audio/webm' : 'audio/mp4', // фолбэк для safari
};

interface ChatAudioRecorderProps {
  onResult: (blob: Blob) => void;
  onCancel: () => void;
}

export const ChatAudioRecorder: FC<ChatAudioRecorderProps> = ({ onResult, onCancel }) => {
  const recorderRef = useRef<AudioRecorder>();
  const [peaks, setPeaks] = useState<number[]>([]);
  const [duration, setDuration] = useState(0);
  const [isInitError, setIsInitError] = useState(false);

  useEffect(() => {
    let interval: number;

    const initRecorder = async () => {
      const recorder = createAudioRecorder(RECORDER_OPTIONS);
      recorderRef.current = recorder;
      recorder.onVolumeUpdate(v => {
        setPeaks(prevState => [...prevState, v].slice(-50)); // ограничим длину до 50 чтобы не жрать память
      });
      await recorder.start(AUDIO_TIME_SLICE);
      // TODO: fix this
      interval = setInterval(() => {
        setDuration(prevState => prevState + 1);
      }, 1000) as unknown as number;
    };

    try {
      initRecorder().then();
    } catch (e) {
      console.error(e);
      setIsInitError(true);
    }

    return () => {
      recorderRef.current?.cancel();
      interval && clearInterval(interval);
    };
  }, []);

  function stopRecord() {
    if (recorderRef.current) {
      const recorder = recorderRef.current;
      recorder
        .stop()
        .then(blob => {
          onResult(blob);
        })
        .catch(e => {
          console.error(e);
        });
    }
  }

  function cancelRecord() {
    if (recorderRef.current) {
      const recorder = recorderRef.current;
      recorder.cancel();
      onCancel();
    }
  }

  return (
    <div className="w-full flex justify-between items-center gap-1">
      <button
        type="button"
        onClick={cancelRecord}
        className="flex flex-none items-center justify-center h-8 w-8 -ml-1 bg-stripe text-secondary rounded-full"
      >
        <IconClose size={20} />
      </button>

      {isInitError ? (
        <span className="text-alert text-xs">Error</span>
      ) : (
        <>
          <div className="w-full flex gap-2 items-center py-1 px-2 bg-secondary h-10 rounded-1.5lg">
            <AudioHistogram peaks={peaks} barHeight={24} barWidth={4} barGap={4} recorderMode />

            <span className="text-xs min-w-[2rem]">{getMediaDurationString(duration)}</span>
          </div>

          <button
            type="button"
            onClick={stopRecord}
            className="flex flex-none items-center justify-center h-10 w-10 bg-brand-main text-active rounded-full"
          >
            <IconCheck size={24} />
          </button>
        </>
      )}
    </div>
  );
};
