import { ReactElement } from 'react';
import { ICON_DEFAULTS, IconProps } from './types';

export function IconSocialDefault({
  size = ICON_DEFAULTS.size,
  className = ICON_DEFAULTS.className,
}: IconProps): ReactElement {
  return (
    <svg
      width={size}
      height={size}
      className={className}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_6893_13465"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="32"
        height="32"
      >
        <rect width="32" height="32" rx="16" fill="url(#paint0_radial_6893_13465)" />
        <rect width="32" height="32" rx="16" fill="#1D0D1B" />
      </mask>
      <g mask="url(#mask0_6893_13465)">
        <rect width="32" height="32" rx="16" fill="#25262A" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.8409 9.37981L15.9975 11.2232C15.7046 11.5161 15.2298 11.5161 14.9369 11.2232C14.644 10.9303 14.644 10.4554 14.9369 10.1625L16.7931 8.30626C16.8013 8.29807 16.8097 8.29008 16.8182 8.28228C17.7467 7.43602 18.9656 6.98005 20.2215 7.00912C21.4775 7.0382 22.6739 7.55009 23.5622 8.43841C24.4506 9.32674 24.9625 10.5232 24.9915 11.7791C25.0206 13.0351 24.5646 14.2539 23.7184 15.1824C23.7103 15.1913 23.702 15.2 23.6935 15.2085L21.0423 17.8503C21.042 17.8506 21.0417 17.8509 21.0414 17.8512C20.5895 18.3045 20.0526 18.6642 19.4615 18.9097C18.87 19.1554 18.2358 19.2818 17.5953 19.2818C16.9548 19.2818 16.3206 19.1554 15.7291 18.9097C15.1376 18.6641 14.6004 18.304 14.1484 17.8503C13.856 17.5569 13.8569 17.082 14.1504 16.7896C14.4438 16.4973 14.9187 16.4982 15.211 16.7916C15.5237 17.1055 15.8953 17.3545 16.3044 17.5245C16.7136 17.6944 17.1523 17.7818 17.5953 17.7818C18.0384 17.7818 18.477 17.6944 18.8862 17.5245C19.2953 17.3545 19.6669 17.1055 19.9796 16.7916L19.9815 16.7897L22.6213 14.1593C23.2003 13.5176 23.5119 12.6784 23.4919 11.8139C23.4718 10.9436 23.1171 10.1146 22.5016 9.49907C21.8861 8.88355 21.057 8.52887 20.1868 8.50872C19.322 8.4887 18.4827 8.80054 17.8409 9.37981Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.4053 14.2188C13.9623 14.2188 13.5236 14.3062 13.1145 14.4761C12.7053 14.6461 12.3337 14.8951 12.021 15.209L12.0191 15.2109L12.0191 15.2109L9.37939 17.8413C8.80038 18.483 8.48871 19.3222 8.50872 20.1867C8.52887 21.057 8.88355 21.886 9.49907 22.5015C10.1146 23.117 10.9436 23.4717 11.8139 23.4919C12.6786 23.5119 13.518 23.2001 14.1598 22.6208L16.0031 20.7774C16.296 20.4845 16.7709 20.4845 17.0638 20.7774C17.3567 21.0703 17.3567 21.5452 17.0638 21.8381L15.2075 23.6943C15.1994 23.7025 15.191 23.7105 15.1824 23.7183C14.2539 24.5646 13.0351 25.0206 11.7791 24.9915C10.5232 24.9624 9.32674 24.4505 8.43841 23.5622C7.55009 22.6739 7.0382 21.4774 7.00912 20.2215C6.98005 18.9655 7.43602 17.7467 8.28228 16.8182C8.29038 16.8093 8.29868 16.8006 8.3072 16.7921L10.9594 14.1493C11.4112 13.696 11.9481 13.3363 12.5391 13.0909C13.1306 12.8452 13.7648 12.7188 14.4053 12.7188C15.0458 12.7188 15.68 12.8452 16.2715 13.0909C16.863 13.3365 17.4002 13.6966 17.8523 14.1503C18.1446 14.4437 18.1437 14.9186 17.8503 15.211C17.5569 15.5033 17.082 15.5024 16.7896 15.209C16.477 14.8951 16.1054 14.6461 15.6962 14.4761C15.2871 14.3062 14.8484 14.2188 14.4053 14.2188Z"
          fill="white"
        />
      </g>
      <defs>
        <radialGradient
          id="paint0_radial_6893_13465"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(27.04 36.32) rotate(-115.776) scale(43.6379 64.9064)"
        >
          <stop offset="0.24392" stopColor="#FF1B90" />
          <stop offset="0.436673" stopColor="#F80261" />
          <stop offset="0.688476" stopColor="#ED00C0" />
          <stop offset="0.776787" stopColor="#C500E9" />
          <stop offset="0.893155" stopColor="#7017FF" />
        </radialGradient>
      </defs>
    </svg>
  );
}
