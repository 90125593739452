import { USER_PROFILE_CARD_SHOW_MODEL } from '@sp/data/user-profile-card';
import { REACTION_REGISTRY } from '@sp/ui/chat';
import { Avatar } from '@sp/ui/elements';
import { Modal } from '@sp/ui/modal';
import { TabPane, Tabs } from '@sp/ui/tabs';
import { ChatUser, KnownReaction } from '@sp/util/stream-chat';
import { useGate, useStore } from 'effector-react';
import { FC } from 'react';
import { MessageReactions, REACTIONS_MODAL_MODEL } from './reactions-modal-model';

const ReactionTitle: FC<{ type: KnownReaction; count: number }> = ({ type, count }) => {
  return (
    <div className="flex items-center justify-center gap-2">
      <span>{REACTION_REGISTRY[type].body}</span>
      <span>{count}</span>
    </div>
  );
};

const ReactionOwner: FC<{ userName?: string; url?: string | null; onClick: () => void }> = ({
  userName = '',
  url,
  onClick,
}) => {
  return (
    <div role="button" onClick={onClick} className="flex items-center font-medium py-2 gap-2">
      <Avatar src={url} size="sm" />
      <span>{userName}</span>
    </div>
  );
};

const TabsView: FC<{ reactions: MessageReactions }> = ({ reactions }) => (
  <Tabs>
    {Object.entries(reactions).map(([t, r]) => {
      const type = t as KnownReaction;
      const handleClick = (user: ChatUser) => {
        USER_PROFILE_CARD_SHOW_MODEL.show({ userId: user.id, avatar: { url: user.image } });
      };

      return (
        <TabPane key={type} title={<ReactionTitle type={type} count={r.length} />}>
          <div className="py-2">
            {r.map(reaction => (
              <ReactionOwner
                onClick={() => reaction.user && handleClick(reaction.user)}
                key={reaction.created_at}
                userName={reaction.user?.name}
                url={reaction.user?.image}
              />
            ))}
          </div>
        </TabPane>
      );
    })}
  </Tabs>
);

export const ReactionsModal: FC = () => {
  const reactions = useStore(REACTIONS_MODAL_MODEL.$reactions);
  const isOpen = useStore(REACTIONS_MODAL_MODEL.$isModalOpen);
  useGate(REACTIONS_MODAL_MODEL.ReactionGate);

  return (
    <Modal
      isOpen={isOpen}
      closeOnBackdropClick
      withBackdrop
      onClose={() => REACTIONS_MODAL_MODEL.isModalOpenSet(false)}
    >
      {Object.keys(reactions).length ? <TabsView reactions={reactions} /> : null}
    </Modal>
  );
};
