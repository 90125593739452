import { GlobalMediaPlayerModel } from '@sp/data/global';
import { ChatMessageList, MessageExtraContentContext } from '@sp/feature/chat';
import { ChatChannelModel } from '@sp/feature/stream-chat';
import { Avatar, SpinnerPage } from '@sp/ui/elements';
import { IconCaretLeft } from '@sp/ui/icons';
import { noop, Nullable } from '@sp/util/helpers';
import { ChatFormatMessage, PLATFORM_USER, PlatformFormType } from '@sp/util/stream-chat';
import { useStore } from 'effector-react';
import { FC, useEffect, useMemo } from 'react';
import { PLATFORM_CHANNEL } from './platform-channel-model';
import { PermissionForm, RateForm } from './platform-form';

const Header: FC<{
  name: string;
  avatar?: Nullable<string>;
  onClick: () => void;
}> = ({ name, avatar, onClick }) => {
  return (
    <div className="flex border-b border-stripe justify-start items-center px-2 py-2 pr-6 w-full">
      <button type="button" title="Back" onClick={onClick} className="text-secondary py-2 pl-3 pr-2">
        <IconCaretLeft size={24} />
      </button>
      <div className="mr-2">
        <Avatar size="sm" src={avatar} />
      </div>
      <div className="flex flex-col flex-1 overflow-x-hidden">
        <h1 className="m-0 text-ellipsis whitespace-nowrap overflow-x-hidden text-base">{name}</h1>
      </div>
    </div>
  );
};

const platformExtraContent = (message: ChatFormatMessage) => {
  if (!message.selfplusMeta?.formType) {
    return null;
  }

  if (message.selfplusMeta.formType === PlatformFormType.permission) {
    return <PermissionForm />;
  } else if (message.selfplusMeta.formType === PlatformFormType.rate) {
    return <RateForm />;
  }
  return null;
};

const PlatformChat: FC<{ chatModel: ChatChannelModel }> = ({ chatModel }) => {
  const extraContent = useMemo(
    () => ({
      platformForm: platformExtraContent ?? (_ => null),
    }),
    [],
  );
  return (
    <MessageExtraContentContext.Provider value={extraContent}>
      <div className="flex-1">
        <div className="flex flex-col-reverse flex-grow justify-end overflow-y-hidden h-full">
          <div className="flex-grow">
            <ChatMessageList model={chatModel} onShowMessageActions={noop} />
          </div>
        </div>
      </div>
    </MessageExtraContentContext.Provider>
  );
};

export const PlatformChannel: FC<{ onBackClick: () => void }> = ({ onBackClick }) => {
  const platformChannel = useStore(PLATFORM_CHANNEL.$platformInstance);

  useEffect(() => {
    PLATFORM_CHANNEL.init();
  }, []);
  useEffect(() => GlobalMediaPlayerModel.close, []);

  return platformChannel ? (
    <div className="h-full flex flex-col">
      <Header avatar={PLATFORM_USER.image} name={PLATFORM_USER.name} onClick={onBackClick} />
      <PlatformChat chatModel={platformChannel} />
    </div>
  ) : (
    <SpinnerPage />
  );
};
