import { addTrailerToHome, startChallengeTrial } from '@sp/data/bif';
import { createDomain, forward } from 'effector';
import { CHAT_TEASERS_GLOBAL_MODEL } from './chat-teasers-global-model';

type GetTrialParams = { challengeId: number; trailer?: boolean };

const trialDomain = createDomain('trial-domain');

const getTrialStarted = trialDomain.createEvent<GetTrialParams>();

const getTrialFx = trialDomain.createEffect<GetTrialParams, unknown>(({ challengeId, trailer = false }) => {
  if (trailer) {
    return addTrailerToHome({ id: challengeId });
  }
  return startChallengeTrial({ id: challengeId });
});

forward({ from: getTrialStarted, to: getTrialFx });

const $challengesIdsInProgress = trialDomain
  .createStore<ReadonlyArray<number>>([])
  .on(getTrialStarted, (state, { challengeId }) => [...new Set([...state, challengeId])])
  .on(getTrialFx.finally, (state, { params: { challengeId } }) => state.filter(id => id !== challengeId));

const challengeTrialReceived = getTrialFx.done.map(({ params: { challengeId } }) => challengeId);
const challengeTrialFailed = getTrialFx.failData;

forward({ from: getTrialFx.done.map(() => undefined), to: CHAT_TEASERS_GLOBAL_MODEL.load });

export const TRIAL_MODEL = { getTrialStarted, $challengesIdsInProgress, challengeTrialReceived, challengeTrialFailed };
