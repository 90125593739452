import { ReactElement } from 'react';
import { ICON_DEFAULTS, IconProps } from './types';

export function IconPlus({
  size = ICON_DEFAULTS.size,
  color = ICON_DEFAULTS.color,
  className = ICON_DEFAULTS.className,
}: IconProps): ReactElement {
  return (
    <svg
      width={size}
      height={size}
      className={className}
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.7633 4.10017C11.7633 3.6687 11.4136 3.31892 10.9821 3.31892C10.5506 3.31892 10.2008 3.6687 10.2008 4.10017V10.1955H4.10547C3.674 10.1955 3.32422 10.5453 3.32422 10.9768C3.32422 11.4083 3.674 11.758 4.10547 11.758H10.2008V17.8502C10.2008 18.2816 10.5506 18.6314 10.9821 18.6314C11.4136 18.6314 11.7633 18.2816 11.7633 17.8502V11.758H17.8555C18.2869 11.758 18.6367 11.4083 18.6367 10.9768C18.6367 10.5453 18.2869 10.1955 17.8555 10.1955H11.7633V4.10017Z"
        fill={color}
      />
    </svg>
  );
}
