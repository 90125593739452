import axios from 'axios';
import { createEvent, restore } from 'effector';

const enable = createEvent();

export const $isEnabled = restore(
  enable.map(() => true),
  false,
);

export const FATAL_ERROR_MODEL = {
  $isEnabled,
};

axios.interceptors.response.use(undefined, error => {
  if (axios.isAxiosError(error) && error.response?.status === 500) {
    enable();
  }

  return Promise.reject(error);
});
