import {
  ChallengeSpaceConfig,
  ChallengeSpaceStep,
  CommunityLive,
  LiveSpaceConfig,
} from './space-types/concrete-space.types';
import { SpaceCreator } from './space-types/protected-space.types';
import { SPACE_PAYMENT_TYPE, SPACE_STATUS, SPACE_TYPE, SpaceCover } from './space-types/shared-space.types';
import { UserAvatar } from './user.types';

export const enum ChatType {
  space = 'space',
  dm = 'dm',
  platform = 'platform',
}

interface ChatBase {
  readonly id: number;
  readonly createdAt: number;
  readonly updatedAt: number | null;
  readonly type: ChatType;
  readonly gsChannelId: number;
}

export interface Creator {
  readonly id: number;
  readonly name: string;
  readonly avatar: UserAvatar | null;
}

export type ChallengeChatTeaserStep = Pick<ChallengeSpaceStep, 'id' | 'title' | 'isSignificant' | 'startAt'>;

interface SpaceChatTeaserBase extends ChatBase {
  readonly type: ChatType.space;
  readonly club: {
    readonly owner: number; // user id
    readonly title: string | null;
    readonly subscriptionPrice: number;
  };
}

export interface SpaceChallengeChatTeaser extends SpaceChatTeaserBase {
  readonly space: {
    readonly id: number;
    readonly title: string;
    readonly price: number;
    readonly type: SPACE_TYPE.challenge;
    readonly status: SPACE_STATUS;
    readonly payment_type: SPACE_PAYMENT_TYPE;
    readonly meta: {
      readonly steps: readonly ChallengeChatTeaserStep[];
    };
    readonly config: ChallengeSpaceConfig;
    readonly cover?: SpaceCover;
    readonly userSlug: string;
    readonly slug: string;
    readonly chatId: number;
  };
}

export interface SpaceLiveChatTeaser extends SpaceChatTeaserBase {
  readonly space: {
    readonly id: number;
    readonly title: string;
    readonly price: number;
    readonly type: SPACE_TYPE.live;
    readonly status: SPACE_STATUS;
    readonly payment_type: SPACE_PAYMENT_TYPE;
    readonly config: LiveSpaceConfig;
    readonly cover?: SpaceCover;
    readonly userSlug: string;
    readonly slug: string;
    readonly chatId: number;
  };
}

export interface SpaceTrailerChatTeaser extends SpaceChatTeaserBase {
  readonly space: {
    readonly id: number;
    readonly title: string;
    readonly price: number;
    readonly type: SPACE_TYPE.trailer;
    readonly teaser: string;
    readonly status: SPACE_STATUS;
    readonly cover?: SpaceCover;
    readonly userSlug: string;
    readonly slug: string;
    readonly chatId: number;
  };
}

export interface SpaceChannelChatTeaser extends SpaceChatTeaserBase {
  readonly space: {
    readonly id: number;
    readonly title: string;
    readonly price: number;
    readonly type: SPACE_TYPE.channel;
    readonly teaser: string;
    readonly status: SPACE_STATUS;
    readonly cover?: SpaceCover;
    readonly userSlug: string;
    readonly slug: string;
    readonly chatId: number;
  };
}

export interface SpaceCommunityChatTeaser extends SpaceChatTeaserBase {
  readonly space: {
    readonly id: number;
    readonly title: string;
    readonly price: number;
    readonly type: SPACE_TYPE.community;
    readonly teaser: string;
    readonly status: SPACE_STATUS;
    readonly cover?: SpaceCover;
    readonly userSlug: string;
    readonly slug: string;
    readonly chatId: number;
    readonly live: CommunityLive | null;
    readonly creator: SpaceCreator;
  };
}

export interface DirectMessageUser {
  readonly id: number;
  readonly name: string;
  readonly isAvatar: boolean;
  readonly avatar: UserAvatar | null;
}

export interface DmChatTeaser extends ChatBase {
  readonly type: ChatType.dm;
  readonly dmUsers: DirectMessageUser[];
}

export interface PlatformChatTeaser extends ChatBase {
  readonly type: ChatType.platform;
}

export type ChatTeaser =
  | SpaceChallengeChatTeaser
  | SpaceTrailerChatTeaser
  | SpaceLiveChatTeaser
  | DmChatTeaser
  | PlatformChatTeaser
  | SpaceChannelChatTeaser
  | SpaceCommunityChatTeaser;
