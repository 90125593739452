import { Avatar, Button, Input, TextArea } from '@sp/ui/elements';
import { FullScreenImageCropper, ImageCropperMode, useSelectCropModel } from '@sp/ui/image-cropper';
import { ToggleSwitch } from '@sp/ui/switch';
import { dataURItoBlob, GRAY_PIXEL_IMG_SRC } from '@sp/util/files';
import { cls, isNotNullish, isNullish } from '@sp/util/helpers';
import { addDays, format, startOfToday } from 'date-fns';
import { useStore } from 'effector-react';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { CHALLENGE_FORM_MODEL } from './challenge-form-model';
import { formatDateForInput, parseDateInputValue } from './utils';

const MIN_START_DATE = formatDateForInput(startOfToday());

export const ChallengeParamsForm: FC = () => {
  const title = useStore(CHALLENGE_FORM_MODEL.$title);
  const startAt = useStore(CHALLENGE_FORM_MODEL.$startAt);
  const stepsCount = useStore(CHALLENGE_FORM_MODEL.$stepsCount);
  const slug = useStore(CHALLENGE_FORM_MODEL.$slug);
  const price = useStore(CHALLENGE_FORM_MODEL.$price);
  const cover = useStore(CHALLENGE_FORM_MODEL.$cover);
  const offerDescription = useStore(CHALLENGE_FORM_MODEL.$offerDescription);
  const offerSummary = useStore(CHALLENGE_FORM_MODEL.$offerSummary);
  const isProgram = useStore(CHALLENGE_FORM_MODEL.$isProgram);
  const owner = useStore(CHALLENGE_FORM_MODEL.$owner);
  const coverUploading = useStore(CHALLENGE_FORM_MODEL.$coverUploadingInProgress);
  const isExistsChallenge = useStore(CHALLENGE_FORM_MODEL.$isExistsChallenge);
  const isChallengePublished = useStore(CHALLENGE_FORM_MODEL.$isChallengePublished);

  const startEndDates = useMemo(
    () => format(startAt, 'do  MMMM') + ' - ' + format(addDays(startAt, stepsCount), 'do  MMMM'),
    [startAt, stepsCount],
  );
  const coverUrl = useMemo(() => cover?.url ?? GRAY_PIXEL_IMG_SRC, [cover?.url]);

  const [startDate, setStartDate] = useState(formatDateForInput(startAt));
  const [isDateValid, setIsDateValid] = useState(true);
  const { selectImage, reset, isSelected, selectedImage, selectedImageDataUri } = useSelectCropModel();

  const onResizeCoverResult = useCallback(
    (resizedCoverUri: string) => {
      if (isNotNullish(selectedImage)) {
        const name = selectedImage.name;
        const type = selectedImage.type;
        const newCover = new File([dataURItoBlob(resizedCoverUri)], name, { type });
        CHALLENGE_FORM_MODEL.updateCoverStarted(newCover);
        reset();
      }
    },
    [reset, selectedImage],
  );

  useEffect(() => {
    const ts = parseDateInputValue(startDate);
    console.log(ts);
    if (isNotNullish(ts)) {
      CHALLENGE_FORM_MODEL.startAtChanged(ts.getTime());
      setIsDateValid(true);
    } else {
      setIsDateValid(false);
    }
  }, [startDate]);

  function onCancelResizeCover() {
    reset();
  }

  return (
    <div className="grid grid-cols-3 gap-6">
      <div className="col-span-2 flex flex-col gap-4">
        {isNotNullish(owner) && (
          <div className="flex items-center gap-2">
            <Avatar size="xs" src={owner.avatar?.url} />
            <span>Owner - {owner.name}</span>
          </div>
        )}

        <div>
          <label className="text-xs text-secondary pl-3">Title</label>
          <Input
            placeholder="Title"
            value={title}
            disabled={isChallengePublished}
            onChange={({ target }) => CHALLENGE_FORM_MODEL.titleChanged(target.value)}
            block
          />
        </div>

        <div>
          <label className="text-xs text-secondary pl-3">Start date (timezone {format(Date.now(), 'zzzz')})</label>
          <Input
            placeholder="Start date"
            min={MIN_START_DATE}
            value={startDate}
            disabled={isChallengePublished}
            onChange={({ target: { value } }) => setStartDate(value)}
            type="date"
            block
            className={cls(isDateValid ? '' : '!border !border-solid !border-alert')}
          />
        </div>

        {isExistsChallenge ? (
          <div className="text-secondary px-3">
            {stepsCount} days, {startEndDates}
          </div>
        ) : (
          <div>
            <label className="text-xs text-secondary pl-3">Days count</label>
            <Input
              placeholder="Days count"
              min={1}
              step={1}
              value={stepsCount}
              disabled={isChallengePublished}
              onChange={({ target }) => CHALLENGE_FORM_MODEL.stepsCountChanged(Number(target.value))}
              type="number"
              block
            />
          </div>
        )}

        <div>
          <label className="text-xs text-secondary pl-3">Slug</label>
          <Input
            required
            value={slug}
            disabled={isChallengePublished}
            onChange={({ target }) => CHALLENGE_FORM_MODEL.slugChanged(target.value)}
            type="text"
            block
          />
        </div>

        <div>
          <label className="text-xs text-secondary pl-3">Price ($)</label>
          <Input
            min={0}
            step={0.25}
            value={price / 100}
            disabled={isChallengePublished}
            onChange={({ target }) => CHALLENGE_FORM_MODEL.priceChanged(Number(target.value) * 100)}
            type="number"
            block
          />
        </div>

        <div className="flex items-center">
          <label htmlFor="is-program" className="text-xs text-secondary pl-3">
            Program
          </label>
          <ToggleSwitch
            id="is-program"
            onChange={CHALLENGE_FORM_MODEL.isProgramChanged}
            checked={isProgram}
            className="ml-2"
          />
        </div>

        <div>
          <label htmlFor="offer-description" className="text-xs text-secondary pl-3">
            Offer Description
          </label>
          <TextArea
            id="offer-description"
            minRows={2}
            maxRows={10}
            block
            value={offerDescription}
            onChange={({ target }) => CHALLENGE_FORM_MODEL.offerDescriptionChanged(target.value)}
          />
        </div>

        <div>
          <label htmlFor="offer-summary" className="text-xs text-secondary pl-3">
            Offer Summary
          </label>
          <TextArea
            id="offer-summary"
            minRows={2}
            maxRows={10}
            block
            value={offerSummary}
            onChange={({ target }) => CHALLENGE_FORM_MODEL.offerSummaryChanged(target.value)}
          />
        </div>
      </div>

      <div className="col-span-1">
        <div className="w-full aspect-square aspect-ratio-wrap">
          <img src={coverUrl} alt="cover" className="rounded-xl mb-6" />
        </div>

        <Button block loading={coverUploading} disabled={coverUploading || isChallengePublished} onClick={selectImage}>
          Update cover
        </Button>

        {isNullish(cover) && !coverUploading ? <span className="text-alert text-xs">need cover</span> : null}
      </div>

      <FullScreenImageCropper
        mode={ImageCropperMode.squareImage}
        isOpen={isSelected}
        src={selectedImageDataUri}
        onResult={onResizeCoverResult}
        onCancel={onCancelResizeCover}
      />
    </div>
  );
};
