import { IS_IOS } from '@sp/data/env';
import { ANALYTICS } from '@sp/feature/analytics';
import { PhotoswipeItem } from '@sp/ui/gallery';
import { IconCloudArrowDown, IconFilmStrip, IconPlay } from '@sp/ui/icons';
import { getResponsiveAWSImageSrc, useUnplayableVideoLoader } from '@sp/util/files';
import { getMediaDurationString } from '@sp/util/format';
import { cls, forwardRefWithDefault, noop } from '@sp/util/helpers';
import { VideoAttachment } from '@sp/util/stream-chat';
import {
  ForwardedRef,
  MutableRefObject,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  VFC,
} from 'react';
import './attachment-video-view.pcss';

export const useFormattedMediaDuration = (initialDuration: number | null = null) => {
  const [duration, setDuration] = useState<number | null>(initialDuration);
  const formattedDuration = useMemo(() => (duration === null ? null : getMediaDurationString(duration)), [duration]);
  return useMemo(() => [formattedDuration, setDuration] as const, [formattedDuration]);
};

const reloadImageOnError = (src: string, onReload: VoidFunction): VoidFunction => {
  let intervalId: number | null = null;
  let isError = false;

  if (src.length === 0) return noop;

  const img = new Image();
  img.src = src;

  const releaseImg = () => img.removeAttribute('src');

  if (img.complete) {
    releaseImg();
    return noop;
  }

  const stopReloading = () => {
    if (intervalId === null) return;
    window.clearInterval(intervalId);
    intervalId = null;
  };

  const startReloading = () => {
    if (intervalId !== null) return;
    intervalId = window.setInterval(() => {
      img.src = src;
      onReload();
    }, 3000);
  };

  const handleLoad = () => {
    if (!isError) return;
    isError = false;
    stopReloading();
  };

  const handleError = () => {
    if (isError) return;
    isError = true;
    startReloading();
  };

  img.addEventListener('error', handleError);
  img.addEventListener('load', handleLoad);

  return () => {
    img.removeEventListener('error', handleError);
    img.removeEventListener('load', handleLoad);
    stopReloading();
    releaseImg();
  };
};

export const useVideoPreviewUrl = (attachmentPreviewUrl: string): string => {
  const previewUrl = useMemo(() => getResponsiveAWSImageSrc(attachmentPreviewUrl, 800), [attachmentPreviewUrl]);
  const [forcedPreviewUrl, setForcedPreviewUrl] = useState(previewUrl);

  useEffect(() => {
    setForcedPreviewUrl(previewUrl);

    return reloadImageOnError(previewUrl, () => {
      // Reset src for a single render to force poster re-render.
      setForcedPreviewUrl('');
      Promise.resolve().then(() => setForcedPreviewUrl(previewUrl));
    });
  }, [previewUrl]);

  return forcedPreviewUrl;
};

const UnplayableVideoView: VFC<Readonly<{ attachment: VideoAttachment; big?: boolean }>> = ({
  attachment,
  big = true,
}) => (
  <div className="aspect-square aspect-ratio-wrap w-full rounded-2.5xl bg-stripe">
    {big ? (
      <div className="flex flex-col items-center justify-center">
        <IconFilmStrip filled size={72} className="text-secondary" />

        <span className="mt-2 text-center text-secondary font-medium">
          We&apos;re processing this video
          <br />
          Please check back later
        </span>

        <a
          href={attachment.originalUrl}
          rel="noopener noreferrer"
          download={attachment.name}
          target="_blank"
          className="mt-4 flex items-center rounded-[10px] cursor-pointer no-underline px-4 py-2.5 bg-stroke text-primary font-semibold"
        >
          <IconCloudArrowDown size={20} />
          <span className="ml-2">Tap to Download</span>
        </a>
      </div>
    ) : (
      <div className="flex flex-col items-start justify-center p-4">
        <IconFilmStrip filled size={32} className="text-secondary" />

        <span className="mt-1 text-secondary font-semibold text-xs">
          Processing&hellip;
          <br />
          Check back later
        </span>

        <a
          href={attachment.originalUrl}
          rel="noopener noreferrer"
          download={attachment.name}
          target="_blank"
          className="mt-2 rounded-[8px] cursor-pointer no-underline px-2 py-1.5 bg-stroke text-primary font-semibold text-xs"
        >
          Download
        </a>
      </div>
    )}
  </div>
);

const VideoPreview = forwardRefWithDefault(function VideoPreview(
  {
    attachment,
    onDurationUpdate,
  }: {
    attachment: VideoAttachment;
    onDurationUpdate: (duration: number) => void;
  },
  ref: ForwardedRef<HTMLVideoElement>,
) {
  const poster = useVideoPreviewUrl(attachment.previewUrl);

  return (
    <div className="w-full aspect-square aspect-ratio-wrap">
      <video
        ref={ref}
        preload="metadata"
        tabIndex={-1}
        controls={false}
        poster={poster}
        onDurationChange={e => onDurationUpdate((e.target as HTMLMediaElement).duration)}
        className="object-cover bg-accent overflow-hidden rounded-2.5xl"
      >
        <source src={attachment.url} />
        {attachment.originalUrl && <source src={attachment.originalUrl} />}
      </video>
    </div>
  );
});

function PhotoswipeVideoContent({ attachment }: { attachment: VideoAttachment }): ReactElement {
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    ANALYTICS.openMediaTracked({
      'Media Type': attachment.fileType,
      'Media Name': attachment.url,
    });
    ANALYTICS.playMediaCompleteTrackStarted();
    return () => {
      ANALYTICS.playMediaCompleteTracked({
        'Media Type': attachment.fileType,
        'Media Name': attachment.url,
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attachment.url]);

  return (
    <video
      ref={videoRef}
      autoPlay
      controls
      controlsList="nodownload nofullscreen"
      tabIndex={-1}
      className="w-full h-full object-contain bg-accent"
    >
      <source src={attachment.url} />
      {attachment.originalUrl && <source src={attachment.originalUrl} />}
    </video>
  );
}

type AttachmentVideoViewProps = Readonly<{
  attachment: VideoAttachment;
  big?: boolean;
}>;

function AttachmentIOsVideoView({ big = true, attachment }: AttachmentVideoViewProps): ReactElement {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const poster = useVideoPreviewUrl(attachment.previewUrl);
  const [duration, setDuration] = useFormattedMediaDuration();
  const [isShowPlayBtn, setIsShowPlayBtn] = useState(true);

  const controlsHandler = useCallback(() => {
    videoRef.current?.removeAttribute('controls');
    setIsShowPlayBtn(true);
    ANALYTICS.playMediaCompleteTracked({
      'Media Type': attachment.fileType,
      'Media Name': attachment.url,
    });
  }, [attachment.fileType, attachment.url]);

  useEffect(() => {
    if (!videoRef.current) return;
    const video = videoRef.current;
    video.addEventListener('webkitendfullscreen', controlsHandler);
    return () => video?.removeEventListener('webkitendfullscreen', controlsHandler);
  }, [controlsHandler]);

  const unplayableVideoLoader = useUnplayableVideoLoader();

  const setVideoRef = useCallback(
    (video: HTMLVideoElement) => {
      videoRef.current = video;
      unplayableVideoLoader.setVideo(video);
    },
    [unplayableVideoLoader],
  );

  const handlePlay = useCallback(() => {
    if (!videoRef.current) return;
    const video = videoRef.current;
    ANALYTICS.openMediaTracked({
      'Media Type': attachment.fileType,
      'Media Name': attachment.url,
    });
    ANALYTICS.playMediaCompleteTrackStarted();
    video.play();
    video.setAttribute('controls', '');
    setIsShowPlayBtn(false);
  }, [attachment.fileType, attachment.url]);

  return (
    <div className="relative w-full aspect-square aspect-ratio-wrap overflow-hidden rounded-2.5xl">
      <video
        ref={setVideoRef}
        onClick={handlePlay}
        onDurationChange={e => {
          const video = e.target as HTMLVideoElement;
          setDuration(video.duration);
        }}
        poster={poster || undefined}
        preload="metadata"
        tabIndex={-1}
        controlsList="nodownload"
        className="object-cover overflow-hidden rounded-2.5xl bg-accent"
      >
        <source src={attachment.url} />
        {attachment.originalUrl && <source src={attachment.originalUrl} />}
      </video>

      {isShowPlayBtn ? (
        <button
          type="button"
          onClick={handlePlay}
          className="!w-auto !h-auto absolute bg-accent bg-opacity-80 backdrop-blur-sm text-active flex items-center !left-4 !bottom-4 !top-auto px-4 py-3 rounded-lg"
        >
          <IconPlay filled size={20} />
          {big && <span className="ml-2">Play</span>}
        </button>
      ) : null}

      {duration && (
        <div
          className={cls(
            '!w-auto !h-auto absolute py-1 px-1 rounded bg-accent bg-opacity-80 backdrop-blur-sm text-active text-xs',
            big ? '!top-4 !right-4 !left-auto' : '!top-2 !right-2 !left-auto',
          )}
        >
          {duration}
        </div>
      )}

      {unplayableVideoLoader.isUnplayable && (
        <div className="absolute inset-0">
          <UnplayableVideoView attachment={attachment} big={big} />
        </div>
      )}
    </div>
  );
}

function AttachmentPhotoswipeVideoView({ attachment, big = false }: AttachmentVideoViewProps): ReactElement {
  const [duration, setDuration] = useFormattedMediaDuration();
  const unplayableVideoLoader = useUnplayableVideoLoader();

  return (
    <PhotoswipeItem width="100%" height="100%" content={<PhotoswipeVideoContent attachment={attachment} />}>
      {({ ref, open }) => (
        <div
          ref={ref as MutableRefObject<HTMLDivElement>}
          onClick={e => !unplayableVideoLoader.isUnplayable && open(e)}
          className="relative overflow-hidden rounded-2.5xl bg-accent cursor-pointer"
        >
          <VideoPreview ref={unplayableVideoLoader.setVideo} attachment={attachment} onDurationUpdate={setDuration} />

          <div className="absolute bg-accent bg-opacity-80 backdrop-blur-sm text-active flex items-center left-4 bottom-4 px-4 py-3 rounded-lg">
            <IconPlay filled size={20} />
            {big && <span className="ml-2">Play</span>}
          </div>

          {duration && (
            <div
              className={cls(
                'absolute py-1 px-1 rounded bg-accent bg-opacity-80 backdrop-blur-sm text-active text-xs',
                big ? 'top-4 right-4' : 'top-2 right-2',
              )}
            >
              {duration}
            </div>
          )}

          {unplayableVideoLoader.isUnplayable && (
            <div className="absolute inset-0">
              <UnplayableVideoView attachment={attachment} big={big} />
            </div>
          )}
        </div>
      )}
    </PhotoswipeItem>
  );
}

export function AttachmentVideoView({ attachment, big = true }: AttachmentVideoViewProps): ReactElement {
  return IS_IOS ? (
    <AttachmentIOsVideoView attachment={attachment} big={big} />
  ) : (
    <AttachmentPhotoswipeVideoView attachment={attachment} big={big} />
  );
}
