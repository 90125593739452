import { ROUTES } from '@sp/util/router';
import { OauthApiFactory } from '../api/google-oauth';
import { apiResponseAdHoc } from './helpers';

const oauthOauthApi = OauthApiFactory();

export async function getGoogleOauthUrl(): Promise<{ authUrl: string }> {
  return oauthOauthApi
    .oauthGoogleInitGet(`${window.location.origin}${ROUTES.CONTINUE_GOOGLE_OAUTH_PATH}`)
    .then(r => apiResponseAdHoc(r.data as { authUrl: string }));
}

export async function loginWithGoogleOauthCode({ code }: { code: string }): Promise<{ token: string }> {
  return oauthOauthApi
    .oauthGoogleContinueGet(code, `${window.location.origin}${ROUTES.CONTINUE_GOOGLE_OAUTH_PATH}`)
    .then(r => apiResponseAdHoc(r.data));
}

export async function loginWithMobileGoogleOauthCode({ code }: { code: string }): Promise<{ token: string }> {
  return oauthOauthApi.oauthGoogleMobileGet(code).then(r => apiResponseAdHoc(r.data));
}

export async function getFacebookOauthUrl(): Promise<{ authUrl: string }> {
  return oauthOauthApi
    .oauthFbInitGet(`${window.location.origin}${ROUTES.CONTINUE_FACEBOOK_OAUTH_PATH}`)
    .then(r => apiResponseAdHoc(r.data as { authUrl: string }));
}

export async function loginWithFacebookOauthCode({ code }: { code: string }): Promise<{ token: string }> {
  return oauthOauthApi
    .oauthFbContinueGet(code, `${window.location.origin}${ROUTES.CONTINUE_FACEBOOK_OAUTH_PATH}`)
    .then(r => apiResponseAdHoc(r.data));
}

export async function loginWithMobileFacebookOauthCode({ code }: { code: string }): Promise<{ token: string }> {
  return oauthOauthApi.oauthFbMobileGet(code).then(r => apiResponseAdHoc(r.data));
}

export async function getAppleOauthUrl(): Promise<{ authUrl: string }> {
  return oauthOauthApi
    .oauthAppleInitGet(
      `${window.location.origin}${ROUTES.CONTINUE_MAGIC_LINK_PATH}?magic-code=`,
      `${window.location.origin}${ROUTES.CONTINUE_MAGIC_LINK_PATH}`,
    )
    .then(r => apiResponseAdHoc(r.data as { authUrl: string }));
}

export async function loginWithMobileAppleOauthCode({ code }: { code: string }): Promise<{ token: string }> {
  return oauthOauthApi.oauthAppleMobileGet(code).then(r => apiResponseAdHoc(r.data));
}
