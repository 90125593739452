import {
  getSpace,
  OwnedLivestreamSpace,
  patchSpace,
  SPACE_STATUS,
  SPACE_TYPE,
  UpdateLivestreamSpaceDto,
  UploadedFile,
} from '@sp/data/bif';
import { createResourceWithParams, createShowAlertFx, isNotNullish } from '@sp/util/helpers';
import { combine, createEffect, createEvent, merge, sample } from 'effector';
import { createGate } from 'effector-react';
import { or } from 'patronum';
import { InvalidSpaceError } from '../utils/errors';
import { LIVE_SPACE_FORM_MODEL, LiveSpaceFormState, SetLiveSpaceFormStatePayload } from './live-space-form';

export const UpdateLiveGate = createGate<{ spaceId: number }>();

export const LIVE_SPACE_RESOURCE_MODEL = createResourceWithParams<OwnedLivestreamSpace | null, [number | null]>({
  loadOnArgsChange: true,
  $args: UpdateLiveGate.state.map(({ spaceId }) => [spaceId] as [number | null]),
  fetchResource: async spaceId => {
    if (spaceId) {
      const space = await getSpace({ spaceId });
      if (space.type !== SPACE_TYPE.live) throw new InvalidSpaceError(space);
      return space;
    } else {
      return null;
    }
  },
});

const $invalidSpaceError = LIVE_SPACE_RESOURCE_MODEL.$loadingError.map(error =>
  error instanceof InvalidSpaceError ? error : null,
);

const publish = createEvent();

const updateLiveSpaceFx = createEffect(
  ({ space, state: { details, testimonials } }: { space: OwnedLivestreamSpace; state: LiveSpaceFormState }) => {
    return patchSpace({
      spaceId: space.id,
      patch: {
        title: details.title,
        slug: details.slug,
        type: SPACE_TYPE.live,
        price: details.price,
        coverFileId: details.cover.id,
        config: {
          live: {
            startAt: details.startAt,
            linkToZoom: details.linkToZoom,
            duration: details.duration,
            requirements: details.requirements,
            description: details.description,
            creatorDescription: details.creatorDescription,
            offerDescription: details.offerDescription,
            offerSummary: details.offerSummary,
            record: details.videoRecording
              ? {
                  videoFileId: details.videoRecording.id,
                  coverFileId: details.videoRecordingCover?.id ?? null,
                  meta: { duration: details.videoRecordingDuration },
                }
              : null,
            testimonials: testimonials.map(t => ({
              title: t.title,
              authorName: t.authorName,
              review: t.review,
              avatarFileId: t.avatar?.id ?? null,
            })),
          },
        },
      },
    });
  },
);

const publishFx = createEffect((spaceId: number) =>
  patchSpace({ spaceId, patch: { status: SPACE_STATUS.published } as UpdateLivestreamSpaceDto }),
);

const updateEffects = [updateLiveSpaceFx, publishFx];

const $isLoading = or(...updateEffects.map(fx => fx.pending));

const $isPublishing = publishFx.pending;

const $isPublished = LIVE_SPACE_RESOURCE_MODEL.$data.map(space => space?.status === SPACE_STATUS.published ?? false);

sample({
  clock: [UpdateLiveGate.state, UpdateLiveGate.status],
  target: [LIVE_SPACE_FORM_MODEL.reset, LIVE_SPACE_RESOURCE_MODEL.reset],
});

sample({
  source: combine({
    isOpen: UpdateLiveGate.status,
    space: LIVE_SPACE_RESOURCE_MODEL.$data,
  }),
  clock: publish,
  filter: ({ isOpen, space }) => isOpen && space !== null && space.status === SPACE_STATUS.draft,
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  fn: ({ space }) => space!.id,
  target: publishFx,
});

sample({
  source: combine({
    isOpen: UpdateLiveGate.status,
    space: LIVE_SPACE_RESOURCE_MODEL.$data,
  }),
  clock: LIVE_SPACE_FORM_MODEL.validFormSubmitted,
  filter: ({ isOpen, space }) => isOpen && space !== null,
  fn: ({ space }, state) => ({ space: space as OwnedLivestreamSpace, state }),
  target: updateLiveSpaceFx,
});

sample({
  source: UpdateLiveGate.status,
  clock: merge(updateEffects.map(fx => fx.doneData)),
  filter: isOpen => isOpen,
  fn: (_, space) => space,
  target: LIVE_SPACE_RESOURCE_MODEL.replace,
});

sample({
  clock: merge(updateEffects.map(fx => fx.fail)),
  target: createShowAlertFx('Failed to update Live space'),
});

const setStateFromResource = sample({
  source: UpdateLiveGate.status,
  clock: LIVE_SPACE_RESOURCE_MODEL.$data.updates.filter({ fn: isNotNullish }),
  filter: isOpened => isOpened,
  fn: (_, space) => space,
});

function mapSpaceToFormState(space: OwnedLivestreamSpace): SetLiveSpaceFormStatePayload {
  return {
    details: {
      title: space.title,
      slug: space.slug,
      cover: space.cover as UploadedFile,
      price: space.price,
      startAt: space.config.live.startAt,
      linkToZoom: space.config.live.linkToZoom ?? '',
      duration: space.config.live.duration,
      description: space.config.live.description,
      creatorDescription: space.config.live.creatorDescription,
      requirements: space.config.live.requirements ?? '',
      videoRecording: space.config.live.record?.video ?? null,
      videoRecordingCover: space.config.live.record?.cover ?? null,
      videoRecordingDuration: space.config.live.record?.meta.duration ?? 0,
      offerDescription: space.config.live.offerDescription ?? '',
      offerSummary: space.config.live.offerSummary ?? '',
    },
    testimonials: space.config.live.testimonials as SetLiveSpaceFormStatePayload['testimonials'],
  };
}

sample({
  clock: setStateFromResource,
  fn: mapSpaceToFormState,
  target: LIVE_SPACE_FORM_MODEL.set,
});

export const UPDATE_LIVE_MODEL = {
  $invalidSpaceError,
  $isLoading,
  $isPublishing,
  $isPublished,
  publish,
};
