import { createEvent, sample } from 'effector';

const messageSentToFeed = createEvent();
const shouldAnimateTab = createEvent();
sample({ source: messageSentToFeed, target: shouldAnimateTab });

export const COMMUNITY_NOTIFIER_MODEL = {
  messageSentToFeed,
  shouldAnimateTab,
};
