import { ChatTeaser, ChatType, PlatformChatTeaser } from '@sp/data/bif';
import { CHAT_TEASERS_GLOBAL_MODEL } from '@sp/data/global';
import { ChatChannelModel, createChatChannelModel, STREAM_CHAT_MODEL } from '@sp/feature/stream-chat';
import { isNotNullish, Nullable, waitUntil } from '@sp/util/helpers';
import { attach, createDomain, forward, sample } from 'effector';

const platformDomain = createDomain();

const init = platformDomain.createEvent();
const destroy = platformDomain.createEvent();

const createPlatformFx = attach({
  source: CHAT_TEASERS_GLOBAL_MODEL.$data,
  mapParams: (_, chats) => {
    return { chats: chats ?? [] };
  },
  // TODO[Dmitriy Teplov] freshen up globally stored chat.
  effect: platformDomain.createEffect(({ chats }: Readonly<{ chats: readonly ChatTeaser[] }>) => {
    return (chats?.find(chat => chat.type === ChatType.platform) as PlatformChatTeaser) ?? null;
  }),
});

const $activePlatformId = platformDomain.createStore<Nullable<number>>(null).reset(destroy);

const $platformInstance = platformDomain.createStore<ChatChannelModel | null>(null).reset(destroy);

const $platformChat = platformDomain
  .createStore<PlatformChatTeaser | null>(null)
  .reset(destroy)
  .reset(init)
  .on(createPlatformFx.doneData, (_, chat) => chat);

const destroyFx = platformDomain.createEffect(async (channel: ChatChannelModel | null) => await channel?.destroy());

const createFx = platformDomain.createEffect(
  async (channelId: string) => await createChatChannelModel(STREAM_CHAT_MODEL, channelId, 'platform'),
);

sample({
  clock: waitUntil<void>({
    clock: init,
    until: CHAT_TEASERS_GLOBAL_MODEL.$isFirstLoadFinished,
  }),
  source: $platformInstance.map(model => model != null),
  filter: isPlatformCreated => !isPlatformCreated,
  target: createPlatformFx,
});

sample({
  source: createPlatformFx.doneData,
  fn: data => data.gsChannelId,
  filter: isNotNullish,
  target: $activePlatformId,
});

forward({
  from: $activePlatformId,
  to: attach({
    source: $platformInstance,
    effect: destroyFx,
  }),
});

forward({
  from: destroy,
  to: attach({
    source: $platformInstance,
    effect: destroyFx,
  }),
});

sample({
  clock: destroyFx.done,
  source: $activePlatformId,
  filter: isNotNullish,
  fn: (id: number) => id.toString(),
  target: createFx,
});

forward({
  from: createFx.doneData,
  to: $platformInstance,
});

export const PLATFORM_CHANNEL = { init, destroy, $platformInstance, $platformChat };
