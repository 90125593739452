import { GlobalMediaPlayerModel } from '@sp/data/global';
import { AudioPlayer } from '@sp/feature/challenge';
import { SpinnerPage } from '@sp/ui/elements';
import { useStore } from 'effector-react';
import { FC, useEffect } from 'react';
import { COMMUNITY_MODEL } from './community-model';
import { FeedLayout } from './feed/feed';
import { FEED_MODEL } from './feed/feed-model';

export const STANDALONE_FEED_TAB_KEY = 'standalone-feed';

export const StandaloneFeedPage: FC<{
  communityId: number;
}> = ({ communityId }) => {
  const isOwner = useStore(COMMUNITY_MODEL.$isOwner);
  const chatModel = useStore(COMMUNITY_MODEL.$chatModel);
  const community = useStore(COMMUNITY_MODEL.$community);
  const isLoading = useStore(COMMUNITY_MODEL.$isLoading);
  const activeAudioPlayer = useStore(GlobalMediaPlayerModel.$player);

  useEffect(() => {
    COMMUNITY_MODEL.init(communityId);
  }, [communityId]);

  useEffect(() => {
    return () => {
      COMMUNITY_MODEL.destroy();
      GlobalMediaPlayerModel.close();
    };
  }, []);

  useEffect(() => {
    FEED_MODEL.getPosts(chatModel?.channel);
    return () => FEED_MODEL.resetPosts();
  }, [chatModel?.channel]);

  if (!community || !chatModel || isLoading) return <SpinnerPage />;

  return (
    <div className="relative h-full w-full">
      <AudioPlayer player={activeAudioPlayer} />
      <FeedLayout isOwner={isOwner} />
    </div>
  );
};
