import { USER_MODEL } from '@sp/data/auth';
import {
  LiveSpaceConfig,
  loadSpaceById,
  loadSpaceMembers,
  patchUserProfile,
  SPACE_TYPE,
  SpaceCreator,
  SpaceMember,
} from '@sp/data/bif';
import { USER_PROFILE_CARD_MEMBERS_MODEL } from '@sp/data/user-profile-card';
import { CHAT_CHANNEL } from '@sp/feature/chat-channel';
import { PriceConfig } from '@sp/util/format';
import { isNotNullish, isNullish, Nullable } from '@sp/util/helpers';
import { createDomain, createStore, restore, sample, split } from 'effector';
import { createGate } from 'effector-react';

export type LiveInfo = {
  id: number;
  creator: Nullable<SpaceCreator>;
  price: number;
  priceConfig: PriceConfig;
  config: LiveSpaceConfig;
  iosProductId: string;
  isPurchased: boolean;
  publishedAt: number;
  isTrial: Nullable<boolean>;
  slug: string;
  userSlug: string;
  title: string;
  membersCount?: number;
  members: SpaceMember[];
  chatId: Nullable<number>;
  cover: Nullable<{ url: string }>;
  startAt: number;
};

const liveDomain = createDomain();

const liveVisited = liveDomain.createEvent();
const refreshLiveStarted = liveDomain.createEvent();

const liveReducer = (ch: Partial<LiveInfo> | null, data: Partial<LiveInfo>) => {
  if (isNullish(ch)) return data;
  return { ...ch, ...data };
};

const LiveGate = createGate<number>({ domain: liveDomain });

const loadLiveFx = liveDomain.createEffect((id: number) => {
  return loadSpaceById(id).then(space => {
    if (space.type !== SPACE_TYPE.live) {
      return Promise.reject(new Error('Space is not live'));
    }
    return space as unknown as LiveInfo;
  });
});

const loadLiveMembersFx = liveDomain.createEffect((id: number) =>
  loadSpaceMembers(id).then(({ membersCountNumber, members }) => ({
    membersCount: membersCountNumber,
    members: members.sort(m => (m.isTrial ? 1 : -1)),
  })),
);

const loadDataFx = liveDomain.createEffect((id: number) => {
  return Promise.all([loadLiveMembersFx(id), loadLiveFx(id)]);
});

const setFirstLiveVisitedFx = liveDomain.createEffect(async () => {
  const patch = { isFirstChallengeVisited: true };
  return await patchUserProfile(patch);
});

const $isLoading = loadDataFx.pending;
const $loadingError = liveDomain
  .createStore<Nullable<Error>>(null)
  .on(loadDataFx.failData, (_, error) => error)
  .reset(LiveGate.close);

const $live = liveDomain
  .createStore<Partial<LiveInfo> | null>(null)
  .on(loadLiveFx.doneData, liveReducer)
  .on(loadLiveMembersFx.doneData, liveReducer)
  .reset(LiveGate.close);

const $liveId = restore(LiveGate.open, null).reset(LiveGate.close);

const $isLoaded = createStore(false)
  .on(loadDataFx.doneData, () => true)
  .reset(LiveGate.close, $liveId, loadDataFx.fail);

sample({
  source: $liveId,
  target: loadDataFx,
  filter: isNotNullish,
});

const chatIdReset = liveDomain.createEvent();
const $activeChatId = liveDomain.createStore<Nullable<number>>(null).reset(chatIdReset);
sample({
  source: $live,
  target: $activeChatId,
  fn: c => {
    return c?.chatId || null;
  },
});

sample({
  source: $live,
  fn: c => {
    return c?.chatId || null;
  },
  filter: c => isNotNullish(c?.chatId),
  target: CHAT_CHANNEL.activeChatIdGet,
});

// TODO May be helpful for analytics purposes

/*sample({
  clock: liveVisited,
  source: USER_MODEL.$meUser,
  filter: user => !user.isFirstChallengeVisited,
  target: setFirstLiveVisitedFx,
});*/

sample({
  source: setFirstLiveVisitedFx.doneData,
  target: USER_MODEL.patch,
});

sample({
  clock: sample({
    clock: refreshLiveStarted,
    source: loadDataFx.pending,
    filter: isPending => !isPending,
  }),
  source: $liveId,
  filter: isNotNullish,
  target: loadDataFx,
});

export const LIVE_MODEL = {
  $live,
  $liveId,
  $loadingError,
  $isLoading,
  $isLoaded,
  liveVisited,
  LiveGate,
  $activeChatId,
  chatIdReset,
  refreshLiveStarted,
};

// Init UserProfileCard
{
  const users = USER_PROFILE_CARD_MEMBERS_MODEL.users.createItemAdapter('live');
  const creators = USER_PROFILE_CARD_MEMBERS_MODEL.creators.createItemAdapter('live');

  split({
    source: $live,
    match: live => (live?.id !== 0 ? 'set' : 'unset'),
    cases: {
      set: [
        creators.set.prepend((c: Partial<LiveInfo> | null) => (c?.creator ? [c.creator] : [])),
        users.set.prepend((c: Partial<LiveInfo> | null) => c?.members || []),
      ],
      unset: [users.unset, creators.unset],
    },
  });
}
