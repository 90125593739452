import { MyEarnings, MyEarningsDetails } from '@sp/data/bif';
import { Avatar, LoaderBlock } from '@sp/ui/elements';
import { Modal } from '@sp/ui/modal';
import { cls } from '@sp/util/helpers';
import { sample } from 'effector';
import { createGate, useStore } from 'effector-react';
import { or } from 'patronum';
import { VFC } from 'react';
import { CREATOR_EARNINGS_MODEL } from './creator-earnings-model';
import { formatPercentage, formatSpentMoney } from './utils';

export const creatorIncomeDetailsGate = createGate();

sample({
  clock: creatorIncomeDetailsGate.open,
  target: [CREATOR_EARNINGS_MODEL.earnings.load, CREATOR_EARNINGS_MODEL.details.load],
});

const Header: VFC<Readonly<{ earnings: MyEarnings }>> = ({ earnings }) => {
  const buyersPercentage = earnings.purchasedCount / earnings.involvedCount;

  return (
    <>
      <div className="px-6">
        <div className="flex flex-row justify-between text-primary font-semibold">
          <span className="leading-5">Participants: {earnings.involvedCount}</span>
          <span className="leading-5">Paid users: {earnings.purchasedCount}</span>
        </div>

        <div className="flex flex-row justify-between text-secondary text-xs">
          <span>Overall</span>
          <span>({formatPercentage(buyersPercentage)})</span>
        </div>
      </div>
      <hr className="border-stripe w-full my-4" />
    </>
  );
};

const Body: VFC<Readonly<{ details: MyEarningsDetails[]; isLoading: boolean }>> = ({ details, isLoading }) => {
  return (
    <div className={cls('flex flex-col gap-4 overflow-y-scroll', isLoading && 'animate-pulse')}>
      {details.map(d => (
        <div className="flex flex-row items-center" key={d.customer?.id}>
          <Avatar size="sm" src={d.customer?.avatar?.url} name={d.customer?.name} className="inline-block" />
          <span className="ml-2 font-medium">{d.customer?.name || <em>Newcomer</em>}</span>
          {d.earns > 0 && <span className="ml-auto font-medium">{formatSpentMoney(d.earns)}</span>}
        </div>
      ))}
    </div>
  );
};

export const CreatorIncomeDetailsModal: VFC = () => {
  const isOpen = useStore(creatorIncomeDetailsGate.status);
  const earnings = useStore(CREATOR_EARNINGS_MODEL.earnings.$data);
  const details = useStore(CREATOR_EARNINGS_MODEL.details.$data);
  const isLoading = useStore(or(CREATOR_EARNINGS_MODEL.details.$isLoading, CREATOR_EARNINGS_MODEL.earnings.$isLoading));

  return (
    <Modal
      isOpen={isOpen}
      withBackdrop
      closeOnBackdropClick
      onClose={creatorIncomeDetailsGate.close}
      header={earnings && <Header earnings={earnings} />}
    >
      {details ? <Body details={details} isLoading={isLoading} /> : <LoaderBlock />}
    </Modal>
  );
};
