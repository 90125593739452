import { ChatChannelModel } from '@sp/feature/stream-chat';
import {
  ChatMessageBodyContainer,
  ChatMessageContainer,
  ChatMessageFooterContainer,
  ChatMessageLeftContainer,
  ChatMessageRightContainer,
} from '@sp/ui/chat';
import { Modal } from '@sp/ui/modal';
import { isNotNullish } from '@sp/util/helpers';
import { ChatFormatMessage } from '@sp/util/stream-chat';
import { MessagePreview } from '@sp/util/teaser';
import { useCallback, useMemo, VFC } from 'react';
import { ChatMessageAvatar } from '../elements/chat-message-avatar';
import { ChatMessageProvider } from '../elements/chat-message-context';
import { ChatMessageHeader } from '../elements/chat-message-header';
import { useMessageExtraContent } from '../use-message-extra-content';
import { CopyMessage } from './message-modal';
import { MessageModalReactions } from './message-modal-reactions';

export const ReadonlyMessageModal: VFC<
  Readonly<{
    activeMessage: ChatFormatMessage | null;
    chatModel: ChatChannelModel;
    setActiveMessage: (message: ChatFormatMessage | null) => void;
  }>
> = ({ setActiveMessage, activeMessage, chatModel }) => {
  const isMessageActionsModalOpen = activeMessage !== null;
  const closeMessageActionsModal = useCallback(() => setActiveMessage(null), [setActiveMessage]);

  const messageContext = useMemo(
    () => (activeMessage ? { message: activeMessage, model: chatModel } : null),
    [activeMessage, chatModel],
  );

  const messageExtraContent = useMessageExtraContent();

  return (
    <Modal isOpen={isMessageActionsModalOpen} onClose={closeMessageActionsModal} closeOnBackdropClick withBackdrop>
      <div>
        {isNotNullish(messageContext) && isNotNullish(activeMessage) ? (
          <ChatMessageProvider value={messageContext}>
            <ChatMessageContainer>
              <ChatMessageLeftContainer>
                <ChatMessageAvatar />
              </ChatMessageLeftContainer>
              <ChatMessageRightContainer>
                <ChatMessageHeader afterUserNameContent={messageExtraContent.afterUserName?.(activeMessage.user)} />
                <ChatMessageBodyContainer>
                  {activeMessage ? <MessagePreview message={activeMessage} showName={false} /> : null}
                </ChatMessageBodyContainer>
              </ChatMessageRightContainer>
            </ChatMessageContainer>
            <ChatMessageFooterContainer>
              <MessageModalReactions
                activeMessage={activeMessage}
                chatModel={chatModel}
                onToggle={closeMessageActionsModal}
              />
            </ChatMessageFooterContainer>
          </ChatMessageProvider>
        ) : null}
        <div className="flex flex-col">
          <CopyMessage
            activeMessage={activeMessage}
            isActive={isMessageActionsModalOpen}
            onSuccess={closeMessageActionsModal}
          />
        </div>
      </div>
    </Modal>
  );
};
