import { cls } from '@sp/util/helpers';
import { Outlet } from 'react-router-dom';
import { MainNavigation } from './main-navigation';
import { useIsShowNav } from './use-is-show-nav';

export function MainLayout() {
  const isShowNav = useIsShowNav();

  return (
    <div className={cls('flex flex-col w-full h-full')}>
      <div className="container flex-1 overflow-hidden mx-auto">
        <Outlet />
      </div>
      {isShowNav && <MainNavigation />}
    </div>
  );
}
