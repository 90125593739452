import { IS_IOS } from '@sp/data/env';
import { $isNativeIos, registerNativeAppKeyboardCallback } from '@sp/util/native';
import { useStore } from 'effector-react';
import { RefObject, useCallback, useEffect, useState } from 'react';
import { noop } from '../helpers';

export const NATIVE_KEYBOARD_CBS: Set<(status: 'willShow' | 'didShow' | 'willHide') => void> = new Set();

export function resetScrollWithOpenKeyboardIosAdhoc() {
  if (!IS_IOS) return;
  window.scrollTo(0, 0);
  document.getElementById('root')?.scrollTo(0, 0);
}

function nCb(status: 'willShow' | 'didShow' | 'willHide') {
  NATIVE_KEYBOARD_CBS.forEach(handler => {
    handler(status);
  });
}

export function stopScroll(scroller: HTMLDivElement) {
  if (scroller) {
    scroller.style.overflow = 'hidden';
    setTimeout(() => {
      if (scroller) {
        scroller.style.overflow = '';
      }
    }, 50);
  }
}

export function useNativeKeyboardCb() {
  useEffect(() => {
    registerNativeAppKeyboardCallback(nCb);
    return () => {
      registerNativeAppKeyboardCallback(noop);
    };
  }, []);
}

export function useResponsiveViewPort(scrollerRef: RefObject<HTMLDivElement | null>) {
  const [lastSize, setLastSize] = useState(window.innerHeight);
  const isIOs = useStore($isNativeIos);

  const viewportScrollResizeHandler = useCallback((status: 'willShow' | 'didShow' | 'willHide') => {
    const viewport = window.visualViewport;

    requestAnimationFrame(() => {
      const height = status === 'willHide' ? window.innerHeight : viewport.height;
      document.body.style.height = `${height}px`;
      window.scrollTo(0, 0);
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 100);
    });
  }, []);

  const viewportScrollerHandlerIOs = useCallback(
    (status: 'willShow' | 'didShow' | 'willHide') => {
      if (status === 'willShow') return;
      const scroller = scrollerRef.current;
      const viewport = window.visualViewport;

      if (!viewport || !scroller) return;
      const fromBottom = scroller.scrollHeight - scroller.scrollTop - scroller.offsetHeight;
      viewportScrollResizeHandler(status);
      // ресайзим вьюпорт и скроллим его
      requestAnimationFrame(() => {
        // скроллим контент
        stopScroll(scroller);
        const height = status === 'willHide' ? window.innerHeight : viewport.height;
        scroller.scrollBy({ top: Math.max(-fromBottom, lastSize - height) });
        setLastSize(height);
      });
    },
    [lastSize, scrollerRef, viewportScrollResizeHandler],
  );

  const viewportScrollerHandlerAndroid = useCallback(() => {
    const scroller = scrollerRef.current;
    const viewport = window.visualViewport;
    if (!viewport || !scroller) return;
    const fromBottom = scroller.scrollHeight - scroller.scrollTop - scroller.offsetHeight;
    requestAnimationFrame(() => {
      stopScroll(scroller);
      const height = viewport.height;
      scroller.scrollBy({ top: Math.max(-fromBottom, lastSize - height) });
      setLastSize(height);
    });
  }, [lastSize, scrollerRef]);

  useEffect(() => {
    isIOs
      ? NATIVE_KEYBOARD_CBS.add(viewportScrollerHandlerIOs)
      : window.visualViewport.addEventListener('resize', viewportScrollerHandlerAndroid);
    return () => {
      isIOs
        ? NATIVE_KEYBOARD_CBS.delete(viewportScrollerHandlerIOs)
        : window.visualViewport.removeEventListener('resize', viewportScrollerHandlerAndroid);
    };
  }, [isIOs, viewportScrollerHandlerAndroid, viewportScrollerHandlerIOs]);

  useEffect(() => {
    if (!isIOs) return;
    const scrollHandler = (event: Event) => {
      if (window.scrollY > 0) {
        event.preventDefault();
        window.scrollTo(0, 0);
      }
    };
    document.body.addEventListener('touchend', scrollHandler, { passive: false }); // Если юзер смог проскроллить руками
    return () => {
      document.body.removeEventListener('touchend', scrollHandler);
    };
  }, [isIOs, viewportScrollResizeHandler]);

  useEffect(
    () => () => {
      document.body.style.height = ``;
    },
    [],
  );
}
