import { ReactElement } from 'react';
import { ICON_DEFAULTS, IconProps } from './types';

export function IconCoins({
  size = ICON_DEFAULTS.size,
  color = ICON_DEFAULTS.color,
  className = ICON_DEFAULTS.className,
}: IconProps): ReactElement {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.75 8.4375C9.8566 8.4375 12.375 7.30422 12.375 5.90625C12.375 4.50828 9.8566 3.375 6.75 3.375C3.6434 3.375 1.125 4.50828 1.125 5.90625C1.125 7.30422 3.6434 8.4375 6.75 8.4375Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.125 5.90625V8.71875C1.125 10.118 3.64219 11.25 6.75 11.25C9.85781 11.25 12.375 10.118 12.375 8.71875V5.90625"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M4.5 8.22656V11.0391" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M12.375 6.79834C14.9414 7.0374 16.875 8.05693 16.875 9.28037C16.875 10.6796 14.3578 11.8116 11.25 11.8116C9.87187 11.8116 8.60625 11.5866 7.62891 11.221"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.625 11.2008V12.0938C5.625 13.493 8.14219 14.625 11.25 14.625C14.3578 14.625 16.875 13.493 16.875 12.0938V9.28125"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M13.5 11.6016V14.4141" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M9 8.22656V14.4141" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
