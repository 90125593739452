import { ReactElement } from 'react';
import { ICON_DEFAULTS, IconProps } from './types';

export function IconAddLink({
  size = ICON_DEFAULTS.size,
  className = ICON_DEFAULTS.className,
}: IconProps): ReactElement {
  return (
    <svg
      width={size}
      height={size}
      className={className}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle opacity="0.15" cx="12" cy="12" r="12" fill="" />
      <path
        d="M7.05078 11.998H16.9508"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 7.04883V16.9488"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
