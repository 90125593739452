import { PlatformPost, PlatformPostType } from '@sp/data/bif';
import { IconArrowSendMessage, IconCaretUp, IconPencil, IconTrashSimple } from '@sp/ui/icons';
import { TabPane, Tabs } from '@sp/ui/tabs';
import { isNullish } from '@sp/util/helpers';
import { useStore } from 'effector-react';
import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { PreviewPost } from './platform-editor-create-message';
import { PLATFORM_EDITOR_MODEL } from './platform-editor-create-message-model';
import { Button } from 'libs/ui/elements/button';

const PostActions: FC<{ post: PlatformPost }> = ({ post }) => {
  const navigate = useNavigate();
  const publishing = useStore(PLATFORM_EDITOR_MODEL.publishing);
  const publishingTest = useStore(PLATFORM_EDITOR_MODEL.testPublishing);
  const deleting = useStore(PLATFORM_EDITOR_MODEL.deleting);
  const orderingUp = useStore(PLATFORM_EDITOR_MODEL.orderingUp);
  const orderingDawn = useStore(PLATFORM_EDITOR_MODEL.orderingDawn);
  const canPublish = isNullish(post.publishedAt);
  const canEdit =
    ((post.type === PlatformPostType.realtime || post.type === PlatformPostType.deferred) && isNullish(post.sentAt)) ||
    (post.type !== PlatformPostType.realtime && post.type !== PlatformPostType.deferred);
  return (
    <div className="flex flex-col gap-4 w-20 pr-4 pt-4 items-start">
      {canPublish ? (
        <Button loading={publishing} onClick={() => PLATFORM_EDITOR_MODEL.postPublished(post.id)} size="xs">
          <IconArrowSendMessage size={16} />
        </Button>
      ) : null}
      <Button
        loading={publishingTest}
        onClick={() => PLATFORM_EDITOR_MODEL.postPublishedTest(post.id)}
        className="text-sm"
        size="xs"
      >
        Test <IconArrowSendMessage size={16} />
      </Button>

      {canEdit ? (
        <>
          <Button
            onClick={() => {
              PLATFORM_EDITOR_MODEL.postToEditSet(post);
              navigate('../edit');
            }}
            size="xs"
          >
            <IconPencil size={16} />
          </Button>
          <Button
            loading={deleting}
            onClick={() => PLATFORM_EDITOR_MODEL.postDeleted(post.id)}
            color="danger"
            size="xs"
          >
            <IconTrashSimple size={16} />
          </Button>

          <Button
            loading={orderingUp}
            onClick={() => {
              const data = { postId: post.id, type: post.type, event: post.event };
              PLATFORM_EDITOR_MODEL.upOrder(data);
            }}
            size="xs"
          >
            <IconCaretUp size={16} />
          </Button>
          <Button
            loading={orderingDawn}
            onClick={() => {
              const data = { postId: post.id, type: post.type, event: post.event };
              PLATFORM_EDITOR_MODEL.dawnOrder(data);
            }}
            size="xs"
          >
            <IconCaretUp size={16} className="rotate-180" />
          </Button>
        </>
      ) : null}
    </div>
  );
};

const PostView: FC<{ post: PlatformPost }> = ({ post }) => {
  return (
    <div
      className={`flex items-center justify-between pb-4 border-b border-stripe ${
        isNullish(post.publishedAt) ? 'bg-light' : 'border-b-brand'
      } `}
    >
      <PreviewPost
        attachments={post.content.attachments}
        message={post.content.text}
        formType={post.content.selfplusMeta?.formType}
      />
      <PostActions post={post} />
    </div>
  );
};

export const PlatformMessagesEditor: FC = () => {
  const navigate = useNavigate();
  useEffect(() => {
    PLATFORM_EDITOR_MODEL.postsGot();
  }, []);
  const posts = useStore(PLATFORM_EDITOR_MODEL.$groupedPosts);

  return (
    <div className="overflow-hidden h-full flex flex-col">
      <div className="flex-1">
        <Tabs>
          <TabPane title="Onboarding" key="Onboarding">
            <div className="relative h-full w-full">
              <div className="absolute chat-scroller left-0 top-0 w-full h-full overflow-y-visible mobile-pan">
                {posts.onboarding.map(post => (
                  <PostView key={post.id} post={post} />
                ))}{' '}
              </div>
            </div>
          </TabPane>
          <TabPane title="Event" key="Event">
            <div className="relative h-full w-full">
              <div className="absolute chat-scroller left-0 top-0 w-full h-full overflow-y-visible mobile-pan">
                {Object.entries(posts.afterEvent).map(([name, eventGroup]) => (
                  <div className="pb-4" key={name}>
                    <div className="p-4">{name}</div>
                    {eventGroup.map(post => (
                      <PostView key={post.id} post={post} />
                    ))}
                  </div>
                ))}
              </div>
            </div>
          </TabPane>
          <TabPane title="Realtime" key="Realtime">
            <div className="relative h-full w-full">
              <div className="absolute chat-scroller left-0 top-0 w-full h-full overflow-y-visible mobile-pan">
                {posts.realtime.map(post => (
                  <PostView key={post.id} post={post} />
                ))}
              </div>
            </div>
          </TabPane>
          <TabPane title="Deferred" key="Deferred">
            <div className="relative h-full w-full">
              <div className="absolute chat-scroller left-0 top-0 w-full h-full overflow-y-visible mobile-pan">
                {posts.deferred.map(post => (
                  <PostView key={post.id} post={post} />
                ))}
              </div>
            </div>
          </TabPane>
        </Tabs>
      </div>
      <Button color="primary" onClick={() => navigate('../edit')}>
        New message
      </Button>
    </div>
  );
};
