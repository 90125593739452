import { EditorState, getMarkRange, GetMarkRange } from '@remirror/core';
import { useEditorState } from '@remirror/react';
import { createGetAllMarksByTypeIterator } from '@sp/util/text-editor';
import { useMemo } from 'react';

function getLinkMarksInSelection(state: EditorState): GetMarkRange[] {
  const linkMarks: GetMarkRange[] = [];

  const markType = state.schema.marks['link'];
  const { doc } = state;
  const { from, to, $from } = state.selection;

  if (from === to) {
    const mark = getMarkRange($from, markType);
    if (mark) {
      linkMarks.push(mark);
    }
  } else {
    doc.nodesBetween(from, to, createGetAllMarksByTypeIterator(linkMarks, markType));
  }

  return linkMarks;
}

export function useActiveEditorLink(): GetMarkRange | undefined {
  const state = useEditorState();

  const linksInSelection = useMemo(() => getLinkMarksInSelection(state), [state]);

  const link = useMemo(() => (linksInSelection.length === 1 ? linksInSelection[0] : undefined), [linksInSelection]);

  const isLinkActive = useMemo(() => {
    if (!link) {
      return false;
    }

    if (state.selection.from === state.selection.to) {
      return true;
    }

    return state.selection.from === link.from && state.selection.to === link.to;
  }, [link, state.selection.from, state.selection.to]);

  return useMemo(() => (isLinkActive ? link : undefined), [isLinkActive, link]);
}
