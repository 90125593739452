import { PURCHASE_MODEL } from '@sp/data/global';
import { GoToAccountButton } from '@sp/feature/native-elements';
import { Modal } from '@sp/ui/modal';
import { $isNativeIos } from '@sp/util/native';
import { createEvent, restore } from 'effector';
import { useStore } from 'effector-react';
import { and } from 'patronum/and';
import { not } from 'patronum/not';
import { VFC } from 'react';

const reset = createEvent();

const $isOpened = restore(
  PURCHASE_MODEL.purchaseWithoutCardAttached.map(() => true),
  false,
).reset(reset);

// Prevent accidentally showing modal on iOS.
const $isShown = and($isOpened, not($isNativeIos));

export const ManageProfileModal: VFC = () => {
  const isShown = useStore($isShown);

  return (
    <Modal isOpen={isShown} onClose={reset} withBackdrop closeOnBackdropClick>
      <div className="text-center">
        <h1>Almost there</h1>
        <p className="my-8">
          Just one more step.
          <br />
          <br />
          <GoToAccountButton type="manage" onGoToAccount={reset} />
        </p>
      </div>
    </Modal>
  );
};
