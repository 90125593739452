import { MeUserLink, UserLink } from '@sp/data/bif';
import { BackButton, useSetPreviousUrl } from '@sp/feature/back-button';
import {
  IconAddLink,
  IconFacebook,
  IconInstagram,
  IconPencil,
  IconSnapchat,
  IconSocialDefault,
  IconTiktok,
  IconTrashSimple,
  IconTwitter,
} from '@sp/ui/icons';
import { isNotNullish, isNullish, noop, Nullable } from '@sp/util/helpers';
import { getLinkHostName, normalizeUrlProtocol } from '@sp/util/links';
import { ROUTES } from '@sp/util/router';
import { useStore } from 'effector-react';
import { FC, ReactElement, useCallback, useEffect, useState, VFC } from 'react';
import { linkResolver, SocialServices } from './link-resolver';
import { LINKS_FORM_MODEL } from './links-form-model';
import { EditLinksModal } from './links-modal';
import { LINKS_MODEL } from './links-model';

export const socialServiceIcon: Record<SocialServices, ReactElement> = {
  [SocialServices.instagram]: <IconInstagram className="text-brand" />,
  [SocialServices.facebook]: <IconFacebook className="text-brand" />,
  [SocialServices.snapchat]: <IconSnapchat className="text-brand" />,
  [SocialServices.twitter]: <IconTwitter className="text-brand" />,
  [SocialServices.tiktok]: <IconTiktok className="text-brand" />,
};
export const socialServicePrefix: Record<SocialServices, string> = {
  [SocialServices.instagram]: 'instagram.com/',
  [SocialServices.facebook]: 'facebook.com/',
  [SocialServices.snapchat]: 'snapchat.com/',
  [SocialServices.twitter]: 'twitter.com/',
  [SocialServices.tiktok]: 'tiktok.com/@',
};
export const socialServicePlaceholder: Record<SocialServices, string> = {
  [SocialServices.instagram]: 'nickname',
  [SocialServices.facebook]: 'username',
  [SocialServices.snapchat]: 'nickname',
  [SocialServices.twitter]: 'nickname',
  [SocialServices.tiktok]: 'nickname',
};

const LinkBody: FC<UserLink> = ({ href, title }) => {
  const linkType = linkResolver(href);
  const hostName = getLinkHostName(href);
  return (
    <div className="flex items-center overflow-hidden flex-1">
      {linkType ? socialServiceIcon[linkType] : <IconSocialDefault className="text-brand" />}
      <div className="ml-3 overflow-hidden flex-1">
        <div className="text-ellipsis overflow-hidden whitespace-nowrap text-base font-semibold">{title}</div>
        <div className="text-ellipsis overflow-hidden whitespace-nowrap text-secondary text-xs">{hostName}</div>
      </div>
    </div>
  );
};

const InputWithPrefix: VFC<{
  placeholder: string;
  prefix: string;
  value?: string;
  socialType: SocialServices;
  onChange?: (v: string) => void;
  onBlur?: (v: string) => void;
}> = ({ prefix, placeholder, socialType, value, onChange = noop, onBlur = noop }) => {
  const [inputValue, setInputValue] = useState('');
  useEffect(() => {
    setInputValue(value || '');
  }, [value]);
  return (
    <div className="w-full">
      <label className="flex items-center py-2 px-3 border border-stroke rounded-lg focus-within:border-accent">
        <span className="mr-2">{socialServiceIcon[socialType]}</span>

        <span className="text-primary font-normal">{prefix}</span>

        <input
          className="flex-1 overflow-hidden border-none font-normal text-primary p-0 placeholder:text-secondary focus:border-none focus:outline-none focus:ring-0"
          type="url"
          value={inputValue}
          onChange={event => {
            setInputValue(event.target.value);
            onChange(event.target.value);
          }}
          onBlur={() => onBlur(inputValue)}
          placeholder={placeholder}
        />
      </label>
    </div>
  );
};

const EditableSocialLink: FC<{ socialType: SocialServices; link: Nullable<MeUserLink> }> = ({ socialType, link }) => {
  const prefix = socialServicePrefix[socialType];

  const blurHandler = (value: string) => {
    const data = { title: value, href: normalizeUrlProtocol(prefix + value) };
    if (value.length > 0 && isNullish(link)) {
      LINKS_MODEL.socialLinkCreated(data);
    } else if (value.length > 0 && isNotNullish(link)) {
      LINKS_MODEL.socialLinkUpdated({ ...link, ...data });
    } else if (value.length === 0 && isNotNullish(link)) {
      LINKS_MODEL.socialLinkDeleted(link.id);
    }
  };
  return (
    <InputWithPrefix
      prefix={prefix}
      socialType={socialType}
      placeholder={socialServicePlaceholder[socialType]}
      value={link?.title || ''}
      onBlur={blurHandler}
    />
  );
};

export const EditableCustomLink: FC<{ link: MeUserLink }> = ({ link }) => {
  const handleEditLink = useCallback(() => {
    LINKS_FORM_MODEL.linkEdited(link);
    LINKS_MODEL.modalContentChanged('editLink');
    LINKS_MODEL.setModalVisible(true);
  }, [link]);
  return (
    <div className="flex flex-1 py-1 px-3 border border-stroke rounded-lg justify-between">
      <span className="flex flex-1 overflow-hidden items-center gap-2">
        <LinkBody {...link} />
      </span>
      <span className="flex items-start">
        <button className="p-2 px-1" onClick={handleEditLink} type="button">
          <IconPencil className="text-primary" size={24} />
        </button>
        <>
          <button
            type="button"
            onClick={() => {
              if (link.id) LINKS_MODEL.linkDeleted(link.id);
            }}
            className="p-2 px-1 pr-0"
          >
            <IconTrashSimple size={24} />
          </button>
        </>
      </span>
    </div>
  );
};

export const EditLinksPage: FC = () => {
  useSetPreviousUrl(ROUTES.PROFILE_URL);
  const socialLinks = useStore(LINKS_MODEL.$socialLinks);
  const customLinks = useStore(LINKS_MODEL.$customLinks);

  useEffect(() => LINKS_MODEL.linksLoadStarted(), []);

  return (
    <div className="h-full flex flex-col mobile-pan overflow-y-hidden">
      <div className="flex justify-start items-center border-b border-b-stroke gap-2 px-2 py-2 pr-6 w-full">
        <BackButton />
        <h1 className="m-0 text-base tracking-normal">Social accounts</h1>
      </div>
      <div className="p-6 overflow-y-auto">
        <div className="flex items-center justify-center w-full">
          <div className="w-60 h-48 mb-4">
            <img className="w-full h-full object-cover overflow-hidden" src="/images/links.svg" />
          </div>
        </div>
        <div>Add your socials to find like-minded people and share your journey with them</div>
        <div className="flex flex-col gap-4 py-4">
          {Object.entries(socialLinks).map(([type, link]) => (
            <EditableSocialLink key={type} link={link} socialType={type as SocialServices} />
          ))}
          {customLinks.map(link => (
            <EditableCustomLink key={link.id} link={link} />
          ))}
        </div>
        <button
          onClick={() => {
            LINKS_MODEL.setModalVisible(true);
            LINKS_MODEL.modalContentChanged('addLink');
          }}
          className="flex gap-4 items-center py-3 w-full"
          type="button"
        >
          <IconAddLink className="fill-stroke text-primary" />
          <span className="text-primary font-semibold">Add a custom link</span>
        </button>
      </div>
      <EditLinksModal />
    </div>
  );
};
