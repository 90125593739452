import { useStore } from 'effector-react';
import { useEffect, VFC } from 'react';
import { CREATOR_EARNINGS_MODEL } from './creator-earnings-model';
import { creatorIncomeDetailsGate, CreatorIncomeDetailsModal } from './creator-income-details-modal';
import { IncomeList, IncomeListSkeleton } from './income-list';

export const CreatorIncome: VFC = () => {
  const earnings = useStore(CREATOR_EARNINGS_MODEL.earnings.$data);

  useEffect(() => {
    CREATOR_EARNINGS_MODEL.earnings.load();
  }, []);

  return (
    <div className="px-6 mt-6 -mb-6">
      <h4 className="my-4 font-semibold text-xl leading-6">Your Income</h4>
      {earnings ? (
        <>
          <IncomeList earnings={earnings} onShowDetails={creatorIncomeDetailsGate.open} />
          <CreatorIncomeDetailsModal />
        </>
      ) : (
        <IncomeListSkeleton />
      )}
    </div>
  );
};
