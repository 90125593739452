import { USER_MODEL } from '@sp/data/auth';
import { USER_PROFILE_CARD_MEMBERS_MODEL, USER_PROFILE_CARD_SHOW_MODEL } from '@sp/data/user-profile-card';
import { combine, sample } from 'effector';
import flatten from 'lodash/flatten';

const $userData = combine({
  creators: USER_PROFILE_CARD_MEMBERS_MODEL.creators.$list.map(list => flatten(list)),
  users: USER_PROFILE_CARD_MEMBERS_MODEL.users.$list.map(list => flatten(list)),
});

const showNotMe = sample({
  source: USER_MODEL.$user,
  clock: USER_PROFILE_CARD_SHOW_MODEL.changed,
  filter: (meUser, user) => meUser?.id !== user.userId,
  fn: (_, user) => user,
});

export const show = sample({
  source: $userData,
  clock: showNotMe,
  fn: ({ creators, users }, { userId }) => {
    const creator = creators.find(c => c.id === userId);
    if (creator) return creator;
    const user = users.find(user => user.id === userId) ?? null;
    if (!user) console.error(`Can't find user ${userId} to show in profile card.`);
    return user;
  },
}).filterMap(payload => (payload ? payload : undefined));
