import { ArrayValues } from '@sp/util/helpers';
import { MeApiFactory } from '../../api/default';
import { Purchase } from '../../types';
import { Skill } from '../../user.types';
import { apiResponseAdHoc } from '../helpers';
import { isValidProtectedSpace } from '../space-helpers';

const defaultMeApi = MeApiFactory();

type ApiPurchaseDto = ArrayValues<Awaited<ReturnType<typeof defaultMeApi.mePurchasesGet>>['data']>;

// Добавлен каст потому что на бэке не получается сделать поля expertId и subscriptionPrice не nullable
function isValidPurchase(dto: ApiPurchaseDto): dto is Purchase {
  return dto.space != null && isValidProtectedSpace(dto.space);
}

function castApiPurchaseDto(dto: ApiPurchaseDto): Purchase {
  if (isValidPurchase(dto)) {
    return dto;
  }

  throw new Error(`${JSON.stringify(dto, undefined, 2)} is not a valid Purchase.`);
}

export async function getUserPurchases(): Promise<readonly Purchase[]> {
  return defaultMeApi.mePurchasesGet().then(r => r.data.map(castApiPurchaseDto));
}

export async function getMySkillPoints(): Promise<ReadonlyArray<Skill>> {
  return defaultMeApi.mePointsGet().then(r => apiResponseAdHoc(r.data));
}
