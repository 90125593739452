import { API_BASE_PATH } from '@sp/data/env';
import { createBrowserHistory } from 'history';
import { useCallback, useMemo, useRef } from 'react';
import { useHref, useLocation, useNavigate } from 'react-router-dom';
import useLocalStorageState from 'use-local-storage-state';

export * from './navigate-with-search';
export * from './parse-params';

export const LOGIN_PATH = '/login';

export function useRedirectAfterLogin(): Readonly<{
  redirectUrl: string;
  setRedirect: (url: string) => void;
  resetRedirect: VoidFunction;
}> {
  const [redirectUrl, setRedirectUrl] = useLocalStorageState('sp_redirect_after_login', { defaultValue: '/' });
  const setRedirect = useCallback(
    (path: string) => setRedirectUrl(path.replace(window.location.origin, '')),
    [setRedirectUrl],
  );
  const resetRedirect = useCallback(() => setRedirectUrl('/'), [setRedirectUrl]);
  return useMemo(() => ({ redirectUrl, setRedirect, resetRedirect }), [redirectUrl, resetRedirect, setRedirect]);
}

const useLocationHref = () => useHref(useLocation());

export function useNavigateToLogin(replace = false): VoidFunction {
  const { setRedirect } = useRedirectAfterLogin();

  // https://github.com/remix-run/react-router/issues/7634
  //  useNavigate causes a new callback to be created on each route change,
  //  wrapped into a ref it can be accessed lazily.
  const navigateRef = useRef(useNavigate());
  navigateRef.current = useNavigate();

  const locationHrefRef = useRef(useLocationHref());
  locationHrefRef.current = useLocationHref();

  return useCallback(() => {
    setRedirect(locationHrefRef.current);
    navigateRef.current(LOGIN_PATH, { replace });
  }, [replace, setRedirect]);
}

export function isApiUrl(url?: string) {
  return url?.startsWith(API_BASE_PATH) ?? false;
}

export const ROUTES = {
  LOGIN_PATH,
  HOME_URL: '/home',
  DM: '/dm',
  DM_PLATFORM: 'platform_channel',
  DM_USER_ID_PARAM: 'userId',
  DISCOVER_URL: '/discover',
  PEOPLE_URL: '/people',
  EXPERT_PAGE_URL: '/creator',
  CREATOR_ID_PARAM: 'creatorId',
  CHALLENGE_PAGE_URL: '/challenge',
  TRAILER_PAGE_URL: '/trailer',
  CHANNEL_PAGE_URL: '/channel',
  COMMUNITY_PAGE_URL: '/community',
  LIVE_PAGE_URL: '/live',
  SPACE_ID_PARAM: 'spaceId',
  CHALLENGE_LAYOUT_V2: 'v2',
  PROFILE_URL: '/profile',
  PROFILE_URL_V2: 'v2',

  LIVE: {
    EMBEDDED_CHAT_URL: 'embedded_chat',
  },

  STUDIO_URL: '/studio',

  STUDIO: {
    EDIT_CHALLENGE_URL: 'edit-challenge',
    EXPLORE_URL: 'explore',
    PLATFORM_MESSAGE_EDITOR: 'platform',
    CREATOR_CHANNEL_EDITOR: 'channel',
    CREATE_LIVE: 'live',
    SPACE_ID_PARAM: 'spaceId',
  },

  PROFILE: {
    USER_PROFILE_NESTED_URL: 'user',
    PROFILE_MENU_NESTED_URL: 'menu',
    EXPERT_DASHBOARD_NESTED_URL: 'expert-dashboard',
    CLUB_SETTINGS_NESTED_URL: 'club-settings',
    PURCHASES_NESTED_URL: 'purchases',
    EXPERT_USERS_NESTED_URL: 'users',
    EXPERT_SALES_NESTED_URL: 'sales',
    USER_DETAILS_NESTED_URL: 'details',
    NOTIFICATIONS_SETTINGS: 'notifications',
    LINKS_SETTINGS: 'links',
  },

  DISCOVER: {
    CREATORS: 'creators',
    CHALLENGES: 'challenges',
  },

  CONTINUE_GOOGLE_OAUTH_PATH: '/continue-google-oauth',
  CONTINUE_FACEBOOK_OAUTH_PATH: '/continue-facebook-oauth',
  CONTINUE_MAGIC_LINK_PATH: '/continue-magic-link-auth',
} as const;

Object.freeze(ROUTES);

export const browserHistory = createBrowserHistory();
