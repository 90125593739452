import { followClub, unfollowClub } from '@sp/data/bif';
import { createDomain, forward, merge } from 'effector';

const followingDomain = createDomain('following-domain');

const followClubStarted = followingDomain.createEvent<number>();
const unfollowClubStarted = followingDomain.createEvent<number>();

const followClubFx = followingDomain.createEffect<number, unknown>(followClub);
const unfollowClubFx = followingDomain.createEffect<number, unknown>(unfollowClub);

forward({ from: followClubStarted, to: followClubFx });
forward({ from: unfollowClubStarted, to: unfollowClubFx });

// В UI не должно быть ситуации когда одновременно отоборажается одна и таже сущьность как followed и unfollowed,
// поэтому объеденим здесь два состояния загрузки followingInProgress и unfollowingInProgress в одно.
const $clubIdsInProgress = followingDomain
  .createStore<ReadonlyArray<number>>([])
  .on(merge([followClubStarted, unfollowClubStarted]), (state, id) => [...new Set([...state, id])])
  .on(merge([followClubFx.finally, unfollowClubFx.finally]), (state, { params: clubId }) =>
    state.filter(id => id !== clubId),
  );

const clubFollowed = followClubFx.done.map(({ params: clubId }) => clubId);
const clubFollowFailed = followClubFx.failData;
const clubUnfollowed = unfollowClubFx.done.map(({ params: clubId }) => clubId);
const clubUnfollowFailed = unfollowClubFx.failData;

export const FOLLOWING_MODEL = {
  followClubStarted,
  unfollowClubStarted,
  $clubIdsInProgress,
  clubFollowed,
  clubFollowFailed,
  clubUnfollowed,
  clubUnfollowFailed,
};
