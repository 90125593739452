import { IS_IOS } from '@sp/data/env';
import { PERMISSION_MODEL } from '@sp/feature/permissions';
import { AppRouter } from '@sp/feature/routes';
import { hideNativeLoader } from '@sp/util/native';
import { useNativeKeyboardCb } from '@sp/util/viewport';
import { useEffect } from 'react';
import { FatalErrorGuard } from './fatal-error-guard';
import { NativeAppGuard } from './native-app-guard';
import './styles/styles.pcss';

export function App() {
  useEffect(() => hideNativeLoader(), []);

  useEffect(() => {
    PERMISSION_MODEL.permissionChecked();
    const interval = setInterval(() => PERMISSION_MODEL.permissionChecked(), 4000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const platformClass = IS_IOS ? 'platform-ios' : 'platform-android';
    document.body.classList.add(platformClass);
    return () => document.body.classList.remove(platformClass);
  }, []);

  useNativeKeyboardCb();

  return (
    <NativeAppGuard>
      <FatalErrorGuard>
        <AppRouter />
      </FatalErrorGuard>
    </NativeAppGuard>
  );
}
