import { PERMISSION_MODEL } from '@sp/feature/permissions';
import { Button } from '@sp/ui/elements';
import { isNotNullish } from '@sp/util/helpers';
import { openSettings } from '@sp/util/native';
import { useGate, useStore } from 'effector-react';
import { useEffect, useMemo, useState, VFC } from 'react';
import { PLATFORM_FORM } from './platform-form-madel';

const RATE_FORM_COLORS = [
  '#E13F2C',
  '#E1582C',
  '#E1832C',
  '#E1992C',
  '#F2BC2F',
  '#E9C60D',
  '#CCD003',
  '#B5CF15',
  '#8FCC0D',
  '#61CC0D',
  '#0FC843',
];

export const PermissionForm: VFC = () => {
  const tokenLoading = useStore(PERMISSION_MODEL.tokenLoading);
  const notificationStatus = useStore(PERMISSION_MODEL.$notificationPermission);
  useEffect(() => {
    PERMISSION_MODEL.permissionChecked();
  });

  const handleNotificationClick = () => {
    if (notificationStatus === 'denied') {
      openSettings();
    } else {
      PERMISSION_MODEL.requestNotificationDone();
    }
  };

  // TODO нормальный текст и диз
  return (
    <div className="pb-3 pt-1">
      <div className="rounded-2.5xl py-4  text-active">
        {notificationStatus === 'granted' ? (
          <Button color="black" disabled outline block>
            Notifications allowed
          </Button>
        ) : (
          <Button color="black" loading={tokenLoading} onClick={handleNotificationClick} block>
            {notificationStatus === 'denied' ? 'Open settings' : 'Allow notifications'}
          </Button>
        )}
      </div>
    </div>
  );
};

const Star: VFC<{ filled: boolean; handleClick: () => void; disabled?: boolean; value: number }> = ({
  filled,
  handleClick,
  disabled = false,
  value,
}) => {
  const color = RATE_FORM_COLORS[value];
  const style = filled
    ? { border: `1px solid ${color}`, background: color }
    : { color: color, border: `1px solid ${color}` };
  return (
    <div
      onClick={() => !disabled && handleClick()}
      style={style}
      className={`w-9 h-9 rounded-full flex items-center justify-center`}
    >
      {value}
    </div>
  );
};

export const RateForm: VFC = () => {
  const [rating, setRating] = useState<number | null>(null);
  const [checked, setChecked] = useState(true);

  const rateForm = useStore(PLATFORM_FORM.$rateForm);

  useGate(PLATFORM_FORM.RateFormGate);

  const ratingArr = useMemo(
    () =>
      Array(11)
        .fill(1)
        .map((_, i) => i),
    [],
  );

  useEffect(() => {
    if (rateForm !== null) {
      setRating(rateForm.rate);
      setChecked(rateForm.readyToTalk);
    }
  }, [rateForm]);

  return (
    <div className="pb-3 pt-4">
      <div className="rounded-[28px] overflow-hidden bg-light text-active">
        <div className="flex justify-center">
          <div className="w-full h-42">
            <img src="/images/nps.svg" className="w-full h-full object-cover overflow-hidden" />
          </div>
        </div>
        <div className="p-4 pt-2 pb-6">
          <div className="text-xl font-semibold text-primary mb-4 leading-6">
            Based on your experience so far, how likely are you to recommend Self+ to a friend?
          </div>
          <div className="flex justify-center gap-2 flex-wrap mb-4">
            {ratingArr.map(i => {
              return (
                <Star
                  key={i}
                  value={i}
                  disabled={rateForm !== null}
                  filled={isNotNullish(rating) && i === rating}
                  handleClick={() => {
                    setRating(i);
                  }}
                />
              );
            })}
          </div>
          <div className="flex text-primary gap-2 mb-6 items-start">
            <input
              className="w-6 h-6 text-primary focus:outline-none focus:ring-0"
              type="checkbox"
              checked={checked}
              disabled={rateForm !== null}
              onChange={v => setChecked(v.target.checked)}
            />
            <span className="font-semibold leading-5">I don’t mind having a quick word with you!</span>
          </div>
          <div className="flex gap-2 items-center">
            <Button
              disabled={rateForm !== null || rating === null}
              onClick={() => isNotNullish(rating) && PLATFORM_FORM.ratingSent({ readyToTalk: checked, rate: rating })}
              color="black"
              block
            >
              Send
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
