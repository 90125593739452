import { useAuth } from '@sp/data/auth';
import { Button } from '@sp/ui/elements';

export function OauthGoogleForm() {
  const { startLoginWithGoogle, isLoginWithGoogleInProgress: isLoading } = useAuth();

  return (
    <Button
      onClick={startLoginWithGoogle}
      color="google"
      size="md"
      className="!py-2 h-[2.625rem] w-full"
      replaceWithSpinner={true}
      loading={isLoading}
    >
      <img src="/images/login/g-logo.svg" alt="Google" />
    </Button>
  );
}
