import { getChatTeasers } from '@sp/data/bif';
import { STREAM_CHAT_MODEL } from '@sp/feature/stream-chat';
import { createLocalStorageCache, registerLocalStorageCache } from '@sp/util/cache';
import { createResource, waitUntilTrue } from '@sp/util/helpers';
import { createEffect, sample } from 'effector';
import { z } from 'zod';

const cache = createLocalStorageCache(z.array(z.any()), 'sp_chat_teasers');
registerLocalStorageCache(cache.key);

export const CHAT_TEASERS_GLOBAL_MODEL = createResource((params?: { force: boolean }) => getChatTeasers(params));

const cachedValue = cache.getValue();
if (cachedValue) CHAT_TEASERS_GLOBAL_MODEL.replace(cachedValue);

sample({
  clock: CHAT_TEASERS_GLOBAL_MODEL.$data,
  target: createEffect(cache.setValue),
});

waitUntilTrue(STREAM_CHAT_MODEL.$isInitialized).then(() => {
  STREAM_CHAT_MODEL.client.on('notification.added_to_channel', () => CHAT_TEASERS_GLOBAL_MODEL.load());
  STREAM_CHAT_MODEL.client.on('notification.removed_from_channel', () => CHAT_TEASERS_GLOBAL_MODEL.load());
});
