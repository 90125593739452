import { ZodSchema } from 'zod';

export function createLocalStorageCache<T>(schema: ZodSchema<T>, key: string) {
  function getValue(): T | null {
    try {
      const stored = localStorage.getItem(key);
      if (!stored) return null;
      return schema.parse(JSON.parse(stored));
    } catch (e) {
      localStorage.removeItem(key);
      return null;
    }
  }

  function setValue(value: T | null): void {
    try {
      if (value) {
        localStorage.setItem(key, JSON.stringify(value));
      } else {
        localStorage.removeItem(key);
      }
    } catch (e) {
      console.error(e);
    }
  }

  return {
    getValue,
    setValue,
    key,
  } as const;
}
