import { getResponsiveAWSImageSrc } from '@sp/util/files';
import { cls, isNotNullish, Nullable } from '@sp/util/helpers';
import { FC } from 'react';
import { PROFILE_AVATAR_SIZE } from './edit-user-info-form';

const ProfileAvatar: FC<{ url?: Nullable<string> }> = ({ url }) => {
  return (
    <div className="px-6 pt-6">
      <img
        src={url ?? '/images/avatar-placeholder-profile.png'}
        alt="Avatar"
        className="w-full object-cover overflow-hidden rounded-t-[60px]"
        style={{ aspectRatio: (327 / 296).toString() }}
      />
    </div>
  );
};

export const ProfileHeader: FC<{
  isLoading: boolean;
  avatar?: Nullable<string>;
  userName?: string;
  bio?: string | null;
  created_at?: number;
  owned?: boolean;
  onEditProfile?: () => void;
}> = ({ avatar, userName = '', isLoading, bio, owned = false, onEditProfile = () => null }) => {
  const src = avatar ? getResponsiveAWSImageSrc(avatar, PROFILE_AVATAR_SIZE) : avatar;
  return (
    <div className={cls('mb-4', isLoading && 'animate-pulse')}>
      <button type="button" onClick={onEditProfile} className="w-full block">
        <ProfileAvatar url={src} />
      </button>
      <div className="px-6 mt-4">
        <div className="flex justify-between items-center">
          <h1 className="m-0 break-words overflow-hidden">{userName}</h1>
        </div>
        {isNotNullish(bio) && <p className="mb-0 mt-1 overflow-hidden break-words whitespace-pre-line">{bio}</p>}
        {owned ? (
          <button onClick={onEditProfile} className="text-brand p-0 mt-2 font-medium">
            Edit profile
          </button>
        ) : null}
      </div>
    </div>
  );
};
