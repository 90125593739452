import { ChatChannelModel } from '@sp/feature/stream-chat';
import { cls, isNullish } from '@sp/util/helpers';
import { PostMeta, PostType } from '@sp/util/stream-chat';
import { isAfter, isBefore } from 'date-fns';
import { useStore } from 'effector-react';
import { FC, useMemo } from 'react';
import { useScheduledActualDate } from '../challenge-helpers';
import { STEPS_MODEL } from '../steps/steps-model';

export const MessageHighlightLine: FC<{
  active?: boolean;
}> = ({ active = false }) => {
  return <div className={cls('flex-grow w-0.5 rounded-sm', active ? 'bg-brand-dirty-light' : 'bg-stroke')} />;
};

export const MessagePostHighlightLine: FC<{
  selfplusPostMeta: PostMeta;
  chatModel: ChatChannelModel;
  messageId: string;
}> = ({ selfplusPostMeta, chatModel, messageId }) => {
  const { stepId, type } = selfplusPostMeta;
  const stepsWithUI = useStore(STEPS_MODEL.$stepsWithUI);
  const messages = useStore(chatModel.$messages);
  const now = useScheduledActualDate(
    useMemo(() => stepsWithUI.map(({ step }) => new Date(step.startAt)), [stepsWithUI]),
  );
  const messageIndex = messages.findIndex(m => m.id === messageId);

  if (messageIndex === -1) return null;
  // Здесь находим следующее сообщение в списке и проверяем того же оно типа или к тому-же степу относится,
  // если нет, то ограничиваем полоску снизу
  let isLastMessage = false;
  const nextMessage = messages[messageIndex + 1];
  if (isNullish(nextMessage)) {
    isLastMessage = true;
  } else {
    const isNextIntroMessage = nextMessage.selfplusPostMeta?.type === PostType.intro && type === PostType.intro;
    const isNextFinishMessage = nextMessage.selfplusPostMeta?.type === PostType.finish && type === PostType.finish;
    const isNextSameStepMessage = nextMessage.selfplusPostMeta?.stepId === stepId && type === PostType.step;
    if (!isNextIntroMessage && !isNextFinishMessage && !isNextSameStepMessage) isLastMessage = true;
  }

  if (type === PostType.intro || type === PostType.finish) {
    return (
      <>
        <MessageHighlightLine />
        {isLastMessage ? <div className="h-2" /> : null}
      </>
    );
  }

  const stepWithUI = stepsWithUI.find(s => s.step.id === stepId);
  if (isNullish(stepWithUI)) {
    return null;
  }

  const isStepActive = isAfter(now, stepWithUI.step.startAt) && isBefore(now, stepWithUI.step.endAt);

  return (
    <>
      <MessageHighlightLine active={isStepActive} />
      {isLastMessage ? <div className="h-2" /> : null}
    </>
  );
};
