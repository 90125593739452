import { Button } from '@sp/ui/elements';
import { useStore } from 'effector-react';
import { FormEvent, useCallback, VFC } from 'react';
import { DetailsForm } from './inner-forms/details-form';
import { TestimonialsForm } from './inner-forms/testimonials-form';
import { LIVE_SPACE_FORM_MODEL } from './live-space-form-model';

export const LiveSpaceForm: VFC<Readonly<{ canUploadRecording: boolean; isLoading: boolean }>> = ({
  canUploadRecording,
  isLoading,
}) => {
  const isValid = useStore(LIVE_SPACE_FORM_MODEL.$isValid);
  const isDirty = useStore(LIVE_SPACE_FORM_MODEL.$isDirty);

  const handleSubmit = useCallback((e: FormEvent) => {
    e.preventDefault();
    LIVE_SPACE_FORM_MODEL.submit();
  }, []);

  return (
    <>
      <form className={isLoading ? 'animate-pulse pointer-events-none' : undefined} onSubmit={handleSubmit}>
        <div className="m-4">
          <DetailsForm canUploadRecording={canUploadRecording} />

          <TestimonialsForm />

          <div className="flex flex-col md:justify-start md:flex-row-reverse mt-4 gap-4">
            <Button type="submit" color="primary" loading={isLoading} disableOnLoad disabled={!isDirty || !isValid}>
              save
            </Button>

            {/* TODO[Dmitriy Teplov] handle reset of an existing space. */}
            {/*<Button color="warning" onClick={handleReset} disabled={!isDirty}>*/}
            {/*  reset*/}
            {/*</Button>*/}
          </div>
        </div>
      </form>
    </>
  );
};
