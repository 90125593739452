import { $isNativeIos } from '@sp/util/native';
import { useStore } from 'effector-react';

export type PriceConfig = {
  ios: number;
  web: number;
};

export function formatPriceRaw(cents: number): string {
  return `$${(cents / 100).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
}

function formatPriceWithVAT(cents: number): string {
  return `${formatPriceRaw(cents)} + VAT`;
}

export const $formatPrice = $isNativeIos.map(isNativeIos =>
  isNativeIos ? ({ ios }: PriceConfig) => formatPriceWithVAT(ios) : ({ web }: PriceConfig) => formatPriceRaw(web),
);

export const useFormatPrice = () => useStore($formatPrice);
