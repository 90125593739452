import { Timer } from '@sp/ui/elements';
import { FC } from 'react';
import { useTime } from 'react-time-sync';
import { Interval } from 'time-sync/constants';

export const LiveCountdown: FC<{
  startAt: number; // Start date in milliseconds
  duration: number; // in minutes
}> = ({ startAt, duration }) => {
  const now = useTime({ interval: Interval.SECONDS }) * 1000;
  const isStarted = now >= startAt;
  const endAt = startAt + duration * 60 * 1000;
  const isEnded = now >= endAt;

  if (isEnded) return <Timer refPoint={startAt} diffPoint={endAt} />;

  if (isStarted) return <Timer refPoint={startAt} />;

  return <Timer refPoint={startAt} />;
};
