import { ChannelState } from 'stream-chat';
import { ChatFormatMessage, ChatNonFormatMessage } from './types';

export function formatChatMessage(message: ChatNonFormatMessage): ChatFormatMessage {
  return ChannelState.prototype.formatMessage(message);
}

export function unformatChatMessage(message: ChatFormatMessage): ChatNonFormatMessage {
  return {
    ...message,
    pinned_at: message.pinned_at ? message.pinned_at.toISOString() : undefined,
    created_at: message.created_at ? message.created_at.toISOString() : undefined,
    updated_at: message.updated_at ? message.updated_at.toISOString() : undefined,
  };
}
