import { ReactElement } from 'react';
import { ICON_DEFAULTS, IconProps } from './types';

export function IconTimer({
  size = ICON_DEFAULTS.size,
  color = ICON_DEFAULTS.color,
  className = ICON_DEFAULTS.className,
}: IconProps): ReactElement {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M6.5 11.125C8.77817 11.125 10.625 9.27817 10.625 7C10.625 4.72183 8.77817 2.875 6.5 2.875C4.22183 2.875 2.375 4.72183 2.375 7C2.375 9.27817 4.22183 11.125 6.5 11.125Z"
        stroke={color}
        strokeMiterlimit="10"
      />
      <path d="M6.5 7.00078L8.35625 5.14453" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5.375 1.375H7.625" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
