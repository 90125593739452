import { STREAM_CHAT_MODEL } from '@sp/feature/stream-chat';

export interface OpenGraphMetadata {
  readonly title: string;
  readonly image_url?: string;
}

const streamClient = STREAM_CHAT_MODEL.client;

export async function getOgMetadata(url: string): Promise<OpenGraphMetadata> {
  if (!STREAM_CHAT_MODEL.$isInitialized.getState()) {
    await new Promise<void>(resolve => {
      const sub = STREAM_CHAT_MODEL.$isInitialized.watch(isInitialized => {
        if (isInitialized) {
          sub.unsubscribe();
          resolve();
        }
      });
    });
  }

  return streamClient.get<OpenGraphMetadata>(streamClient.baseURL + `/og`, { url });
}
