import { FATAL_ERROR_MODEL } from '@sp/data/global';
import { Button, SpinnerPage } from '@sp/ui/elements';
import { openGrooveWidget } from '@sp/util/groovehq';
import { createEffect, createEvent, restore, sample } from 'effector';
import { useEvent, useStore } from 'effector-react';
import { FC } from 'react';

const reloadFx = createEffect(() => window.location.reload());

const reload = createEvent();

const $isReloading = restore(
  reload.map(() => true),
  false,
);

sample({
  clock: reload,
  target: reloadFx,
});

export const FatalErrorGuard: FC = ({ children }) => {
  const isEnabled = useStore(FATAL_ERROR_MODEL.$isEnabled);
  const isReloading = useStore($isReloading);
  const handleReloadClick = useEvent(reload);
  const handleSupportClick = useEvent(openGrooveWidget);

  if (!isEnabled) return <>{children}</>;

  if (isReloading) return <SpinnerPage />;

  return (
    <div className="w-screen h-screen flex items-center p-6">
      <div>
        <h2>🛠️ System update</h2>
        <p>The app is currently undergoing scheduled maintenance and updates, but will be live shortly.</p>
        <p>🙌 Please check very soon.</p>
        <p>Sorry for the inconvenience.</p>
        <p>
          <a role="button" onClick={handleSupportClick}>
            Ping us
          </a>{' '}
          if you keep getting this message, and we&apos;ll look into that.
        </p>
        <Button onClick={handleReloadClick} loading={isReloading} disableOnLoad block color="black" className="mt-12">
          Try again
        </Button>
      </div>
    </div>
  );
};
