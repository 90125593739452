import {
  deleteNotificationsTokens,
  getAppleOauthUrl,
  getFacebookOauthUrl,
  getGoogleOauthUrl,
  loginWithFacebookOauthCode,
  loginWithGoogleOauthCode,
  loginWithMagicLink,
  loginWithMobileAppleOauthCode,
  loginWithMobileFacebookOauthCode,
  loginWithMobileGoogleOauthCode,
  loginWithPassword,
  sendMagicLink,
} from '@sp/data/bif';
import { ANALYTICS } from '@sp/feature/analytics';
import { flushLocalStorageCaches } from '@sp/util/cache';
import { isNotNullish } from '@sp/util/helpers';
import { isInApp } from '@sp/util/native';
import { useNavigateToLogin, useRedirectAfterLogin } from '@sp/util/router';
import { createEffect, createEvent, createStore, forward, restore, sample, split } from 'effector';
import { useStore } from 'effector-react';
import { createContext, ReactElement, useCallback, useContext, useEffect, useMemo } from 'react';
import { ejectInterceptors, injectInterceptors } from './axios-auth-interceptors';
import { USER_MODEL } from './user-model';

const AUTH_TOKEN_STORAGE_KEY = 'sp_auth_token';
type AuthMethod = 'Apple' | 'Fb' | 'Google' | 'MagicLink' | 'Password';

// Email and password
const loginWithPasswordStarted = createEvent<{ email: string; password: string }>('loginWithPasswordStarted');
// Google
const loginWithGoogleStarted = createEvent('loginWithGoogleStarted');
const loginWithGoogleContinued = createEvent<{ code: string }>('loginWithGoogleContinued');
const loginWithGoogleMobileContinued = createEvent<{ code: string }>('loginWithGoogleMobileContinued');
// Facebook
const loginWithFacebookStarted = createEvent('loginWithFacebookStarted');
const loginWithFacebookContinued = createEvent<{ code: string }>('loginWithFacebookContinued');
const loginWithFacebookMobileContinued = createEvent<{ code: string }>('loginWithFacebookMobileContinued');
// Apple (continued by magic link)
const loginWithAppleStarted = createEvent('loginWithAppleStarted');
const loginWithAppleMobileContinued = createEvent<{ code: string }>('loginWithAppleMobileContinued');
// Magic link
const loginWithMagicLinkStarted = createEvent<{ email: string }>('loginWithMagicLinkStarted');
const loginWithMagicLinkContinued = createEvent<{ code: string }>('loginWithMagicLinkContinued');

const tokenSet = createEvent<string>('tokenSet');
const tokenReset = createEvent('tokenReset');

const loginWithPasswordFx = createEffect({ name: 'loginWithPasswordFx', handler: loginWithPassword });

const sendMagicLinkFx = createEffect({ name: 'sendMagicLinkFx', handler: sendMagicLink });
const loginWithMagicLinkFx = createEffect({ name: 'loginWithMagicLinkFx', handler: loginWithMagicLink });

const getGoogleOauthUrlFx = createEffect({ name: 'getGoogleOauthUrlFx', handler: () => getGoogleOauthUrl() });
const getFacebookOauthUrlFx = createEffect({ name: 'getFacebookOauthUrlFx', handler: () => getFacebookOauthUrl() });
const getAppleOauthUrlFx = createEffect({ name: 'getAppleOauthUrlFx', handler: () => getAppleOauthUrl() });
const redirectToOauthFx = createEffect({
  name: 'redirectToOauthFx',
  handler: (url: string) => {
    window.location.href = url;
  },
});
const startNativeGoogleOauthFx = createEffect(() => window.native?.signin.authenticate.google());
const loginWithGoogleOauthCodeFx = createEffect(loginWithGoogleOauthCode);
const loginWithGoogleMobileOauthCodeFx = createEffect(loginWithMobileGoogleOauthCode);
const startNativeFacebookOauthFx = createEffect(() => window.native?.signin.authenticate.facebook());
const loginWithFacebookOauthCodeFx = createEffect(loginWithFacebookOauthCode);
const loginWithFacebookMobileOauthCodeFx = createEffect(loginWithMobileFacebookOauthCode);
const startNativeAppleOauthFx = createEffect(() => window.native?.signin.authenticate.apple());
const loginWithAppleMobileOauthCodeFx = createEffect(loginWithMobileAppleOauthCode);

// Analytics effects
const trackAuthStartFx = createEffect((Method: AuthMethod) => {
  ANALYTICS.authenticationStartTracked({ Method });
  ANALYTICS.authenticationCompleteTrackingStart();
});
const trackAuthSuccessFx = createEffect((Method: AuthMethod) => {
  ANALYTICS.authenticationCompleteTracked({ Method });
});

const initialToken = localStorage.getItem(AUTH_TOKEN_STORAGE_KEY);
const $token = createStore<string | null>(initialToken, { name: '$token' })
  .on(tokenSet, (_, token) => token)
  .on(tokenReset, () => null);

sample({
  clock: $token,
  target: USER_MODEL.reset,
});

const $isTokenSet = $token.map(Boolean);

const setupFinished = createEvent();
const $isReady = createStore(false)
  .on(setupFinished, () => true)
  .reset(tokenReset);

forward({ from: loginWithPasswordStarted, to: loginWithPasswordFx });
forward({ from: loginWithPasswordStarted.map(() => 'Password' as AuthMethod), to: trackAuthStartFx });
forward({ from: loginWithPasswordFx.doneData.map(({ authToken }) => authToken), to: tokenSet });
forward({ from: loginWithPasswordFx.doneData.map(() => 'Password' as AuthMethod), to: trackAuthSuccessFx });

forward({ from: loginWithMagicLinkStarted, to: sendMagicLinkFx });
forward({ from: loginWithMagicLinkStarted.map(() => 'MagicLink' as AuthMethod), to: trackAuthStartFx });
forward({ from: loginWithMagicLinkContinued, to: loginWithMagicLinkFx });
forward({ from: loginWithMagicLinkFx.doneData.map(({ authToken }) => authToken), to: tokenSet });
forward({ from: loginWithMagicLinkFx.doneData.map(() => 'MagicLink' as AuthMethod), to: trackAuthSuccessFx });

split({
  source: loginWithGoogleStarted,
  match: () => (isInApp() ? 'inApp' : 'web'),
  cases: { inApp: startNativeGoogleOauthFx, web: getGoogleOauthUrlFx },
});
forward({ from: loginWithGoogleStarted.map(() => 'Google' as AuthMethod), to: trackAuthStartFx });
forward({ from: getGoogleOauthUrlFx.doneData.map(({ authUrl }) => authUrl), to: redirectToOauthFx });
forward({ from: loginWithGoogleContinued, to: loginWithGoogleOauthCodeFx });
forward({ from: loginWithGoogleOauthCodeFx.doneData.map(({ token }) => token), to: tokenSet });
forward({ from: loginWithGoogleMobileContinued, to: loginWithGoogleMobileOauthCodeFx });
forward({ from: loginWithGoogleMobileOauthCodeFx.doneData.map(({ token }) => token), to: tokenSet });
forward({ from: loginWithGoogleMobileOauthCodeFx.doneData.map(() => 'Google' as AuthMethod), to: trackAuthSuccessFx });

split({
  source: loginWithFacebookStarted,
  match: () => (isInApp() ? 'inApp' : 'web'),
  cases: { inApp: startNativeFacebookOauthFx, web: getFacebookOauthUrlFx },
});
forward({ from: loginWithFacebookStarted.map(() => 'Facebook' as AuthMethod), to: trackAuthStartFx });
forward({ from: getFacebookOauthUrlFx.doneData.map(({ authUrl }) => authUrl), to: redirectToOauthFx });
forward({ from: loginWithFacebookContinued, to: loginWithFacebookOauthCodeFx });
forward({ from: loginWithFacebookOauthCodeFx.doneData.map(({ token }) => token), to: tokenSet });
forward({ from: loginWithFacebookMobileContinued, to: loginWithFacebookMobileOauthCodeFx });
forward({ from: loginWithFacebookMobileOauthCodeFx.doneData.map(({ token }) => token), to: tokenSet });
forward({
  from: loginWithFacebookMobileOauthCodeFx.doneData.map(() => 'Facebook' as AuthMethod),
  to: trackAuthSuccessFx,
});

split({
  source: loginWithAppleStarted,
  match: () => (isInApp() ? 'inApp' : 'web'),
  cases: { inApp: startNativeAppleOauthFx, web: getAppleOauthUrlFx },
});
forward({ from: loginWithAppleStarted.map(() => 'Apple' as AuthMethod), to: trackAuthStartFx });
forward({ from: getAppleOauthUrlFx.doneData.map(({ authUrl }) => authUrl), to: redirectToOauthFx });
forward({ from: loginWithAppleMobileContinued, to: loginWithAppleMobileOauthCodeFx });
forward({ from: loginWithAppleMobileOauthCodeFx.doneData.map(({ token }) => token), to: tokenSet });
forward({ from: loginWithAppleMobileOauthCodeFx.doneData.map(() => 'Apple' as AuthMethod), to: trackAuthSuccessFx });

sample({
  clock: sendMagicLinkFx.doneData,
  filter: ({ authToken }) => isNotNullish(authToken),
  fn: ({ authToken }) => authToken as string,
  target: tokenSet,
});

const $loginWithPasswordInProgress = loginWithPasswordFx.pending;
const $loginWithPasswordError = restore(loginWithPasswordFx.failData, null).reset(loginWithPasswordStarted);
const $sendMagicLinkInProgress = sendMagicLinkFx.pending;
const $sendMagicLinkSuccess = restore(
  sendMagicLinkFx.done.map(() => true),
  false,
).reset(tokenReset);
const $sendMagicLinkError = restore(sendMagicLinkFx.failData, null).reset(loginWithMagicLinkStarted);
const $loginWithMagicLinkInProgress = loginWithMagicLinkFx.pending;
const $loginWithMagicLinkError = restore(loginWithMagicLinkFx.failData, null).reset(loginWithMagicLinkContinued);
const $getGoogleOauthUrlInProgress = getGoogleOauthUrlFx.pending;
const $getGoogleOauthUrlError = restore(getGoogleOauthUrlFx.failData, null).reset(loginWithGoogleStarted);
const $loginWithGoogleOauthCodeInProgress = loginWithGoogleOauthCodeFx.pending;
const $loginWithGoogleOauthCodeError = restore(loginWithGoogleOauthCodeFx.failData, null).reset(
  loginWithGoogleContinued,
);
const $loginWithGoogleMobileOauthCodeInProgress = loginWithGoogleMobileOauthCodeFx.pending;
const $loginWithGoogleMobileOauthCodeError = restore(loginWithGoogleMobileOauthCodeFx.failData, null).reset(
  loginWithGoogleMobileContinued,
);
const $getFacebookOauthUrlInProgress = getFacebookOauthUrlFx.pending;
const $getFacebookOauthUrlError = restore(getFacebookOauthUrlFx.failData, null).reset(loginWithFacebookStarted);
const $loginWithFacebookOauthCodeInProgress = loginWithFacebookOauthCodeFx.pending;
const $loginWithFacebookOauthCodeError = restore(loginWithFacebookOauthCodeFx.failData, null).reset(
  loginWithFacebookContinued,
);
const $loginWithFacebookMobileOauthCodeInProgress = loginWithFacebookMobileOauthCodeFx.pending;
const $loginWithFacebookMobileOauthCodeError = restore(loginWithFacebookMobileOauthCodeFx.failData, null).reset(
  loginWithFacebookMobileContinued,
);
const $getAppleOauthUrlInProgress = getAppleOauthUrlFx.pending;
const $getAppleOauthUrlError = restore(getAppleOauthUrlFx.failData, null).reset(loginWithAppleStarted);
const $loginWithAppleMobileOauthCodeInProgress = loginWithAppleMobileOauthCodeFx.pending;
const $loginWithAppleMobileOauthCodeError = restore(loginWithAppleMobileOauthCodeFx.failData, null).reset(
  loginWithAppleMobileContinued,
);

export const AUTH_MODEL = {
  $token,
  tokenReset,
  $isTokenSet,
  $isReady,
  loginWithPasswordStarted,
  loginWithGoogleStarted,
  loginWithGoogleContinued,
  loginWithMagicLinkStarted,
  loginWithMagicLinkContinued,
  loginWithFacebookStarted,
  loginWithFacebookContinued,
  loginWithAppleStarted,
  $sendMagicLinkSuccess,
  $loginWithPasswordInProgress,
  $loginWithPasswordError,
  $sendMagicLinkInProgress,
  $sendMagicLinkError,
  $loginWithMagicLinkInProgress,
  $loginWithMagicLinkError,
  $getGoogleOauthUrlInProgress,
  $getGoogleOauthUrlError,
  $loginWithGoogleOauthCodeInProgress,
  $loginWithGoogleOauthCodeError,
  $loginWithGoogleMobileOauthCodeInProgress,
  $loginWithGoogleMobileOauthCodeError,
  $getFacebookOauthUrlInProgress,
  $getFacebookOauthUrlError,
  $loginWithFacebookOauthCodeInProgress,
  $loginWithFacebookOauthCodeError,
  $loginWithFacebookMobileOauthCodeInProgress,
  $loginWithFacebookMobileOauthCodeError,
  $getAppleOauthUrlInProgress,
  $getAppleOauthUrlError,
  $loginWithAppleMobileOauthCodeInProgress,
  $loginWithAppleMobileOauthCodeError,
};

export interface AuthApi {
  loginWithPassword: (params: { email: string; password: string }) => void;
  isLoginWithPasswordInProgress: boolean;
  startLoginWithGoogle: () => void;
  continueLoginWithGoogle: (params: { code: string }) => void;
  isLoginWithGoogleInProgress: boolean;
  startLoginWithMagicLink: (params: { email: string }) => void;
  continueLoginWithMagicLink: (params: { code: string }) => void;
  isLoginWithMagicLinkInProgress: boolean;
  magicLinkSuccess: boolean;
  magicLinkError: Error | null;
  startLoginWithFacebook: () => void;
  continueLoginWithFacebook: (params: { code: string }) => void;
  isLoginWithFacebookInProgress: boolean;
  startLoginWithApple: () => void;
  isLoginWithAppleInProgress: boolean;
  logout: () => void;
  logoutWithReload: VoidFunction;
}

const AuthContext = createContext<AuthApi>({} as AuthApi);

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }: { children: ReactElement | ReactElement[] }): ReactElement {
  const token = useStore(AUTH_MODEL.$token);
  const isLoginWithPasswordInProgress = useStore(AUTH_MODEL.$loginWithPasswordInProgress);
  const sendMagicLinkInProgress = useStore(AUTH_MODEL.$sendMagicLinkInProgress);
  const magicLinkError = useStore(AUTH_MODEL.$loginWithMagicLinkError);
  const loginWithMagicLinkInProgress = useStore(AUTH_MODEL.$loginWithMagicLinkInProgress);
  const getGoogleOauthUrlInProgress = useStore(AUTH_MODEL.$getGoogleOauthUrlInProgress);
  const loginWithGoogleOauthCodeInProgress = useStore(AUTH_MODEL.$loginWithGoogleOauthCodeInProgress);
  const loginWithGoogleMobileOauthCodeInProgress = useStore(AUTH_MODEL.$loginWithGoogleMobileOauthCodeInProgress);
  const getFacebookOauthUrlInProgress = useStore(AUTH_MODEL.$getFacebookOauthUrlInProgress);
  const loginWithFacebookOauthCodeInProgress = useStore(AUTH_MODEL.$loginWithFacebookOauthCodeInProgress);
  const loginWithFacebookMobileOauthCodeInProgress = useStore(AUTH_MODEL.$loginWithFacebookMobileOauthCodeInProgress);
  const getAppleOauthUrlInProgress = useStore(AUTH_MODEL.$getAppleOauthUrlInProgress);
  const loginWithAppleMobileOauthCodeInProgress = useStore(AUTH_MODEL.$loginWithAppleMobileOauthCodeInProgress);
  const magicLinkSuccess = useStore(AUTH_MODEL.$sendMagicLinkSuccess);

  const navigateToLogin = useNavigateToLogin();
  const { resetRedirect } = useRedirectAfterLogin();

  const logout = useCallback(async () => {
    try {
      await deleteNotificationsTokens();
    } catch (e) {
      console.error('logout deleteNotificationsTokens', e);
    }
    AUTH_MODEL.tokenReset();
    localStorage.removeItem(AUTH_TOKEN_STORAGE_KEY);
    flushLocalStorageCaches();
    ejectInterceptors();
    navigateToLogin();
  }, [navigateToLogin]);

  const logoutWithReload = useCallback(async () => {
    await logout();
    resetRedirect();
    window.location.reload();
  }, [logout, resetRedirect]);

  useEffect(() => {
    if (isNotNullish(token)) {
      localStorage.setItem(AUTH_TOKEN_STORAGE_KEY, token);
      injectInterceptors(token, logout);
      USER_MODEL.fetch();
      setupFinished();
    }
  }, [logout, token]);

  const authApi: AuthApi = useMemo(
    () => ({
      logout,
      logoutWithReload,
      loginWithPassword: AUTH_MODEL.loginWithPasswordStarted,
      isLoginWithPasswordInProgress,
      startLoginWithMagicLink: AUTH_MODEL.loginWithMagicLinkStarted,
      continueLoginWithMagicLink: AUTH_MODEL.loginWithMagicLinkContinued,
      isLoginWithMagicLinkInProgress: sendMagicLinkInProgress || loginWithMagicLinkInProgress,
      magicLinkSuccess,
      magicLinkError,
      startLoginWithGoogle: AUTH_MODEL.loginWithGoogleStarted,
      continueLoginWithGoogle: AUTH_MODEL.loginWithGoogleContinued,
      isLoginWithGoogleInProgress:
        getGoogleOauthUrlInProgress || loginWithGoogleOauthCodeInProgress || loginWithGoogleMobileOauthCodeInProgress,
      startLoginWithFacebook: AUTH_MODEL.loginWithFacebookStarted,
      continueLoginWithFacebook: AUTH_MODEL.loginWithFacebookContinued,
      isLoginWithFacebookInProgress:
        getFacebookOauthUrlInProgress ||
        loginWithFacebookOauthCodeInProgress ||
        loginWithFacebookMobileOauthCodeInProgress,
      startLoginWithApple: AUTH_MODEL.loginWithAppleStarted,
      isLoginWithAppleInProgress: getAppleOauthUrlInProgress || loginWithAppleMobileOauthCodeInProgress,
    }),
    [
      logout,
      logoutWithReload,
      isLoginWithPasswordInProgress,
      sendMagicLinkInProgress,
      loginWithMagicLinkInProgress,
      magicLinkSuccess,
      magicLinkError,
      getGoogleOauthUrlInProgress,
      loginWithGoogleOauthCodeInProgress,
      loginWithGoogleMobileOauthCodeInProgress,
      getFacebookOauthUrlInProgress,
      loginWithFacebookOauthCodeInProgress,
      loginWithFacebookMobileOauthCodeInProgress,
      getAppleOauthUrlInProgress,
      loginWithAppleMobileOauthCodeInProgress,
    ],
  );

  return <AuthContext.Provider value={authApi}>{children}</AuthContext.Provider>;
}

function buildNativeAuthHandler(
  handler: (args: { code: string }) => void,
  provider: string,
): (status: number, code: string) => void {
  return (status, code) => {
    if (status >= 0) {
      handler({ code });
    } else if (status === -1) {
      // -1 status is "cancel" so can be ignored.
      console.log(`${provider} native auth cancelled.`);
    } else {
      console.error(`${provider} native auth error ${status}`);
    }
  };
}

export function registerNativeAuthApi() {
  window.nativeCallbackRegisterGoogleToken = buildNativeAuthHandler(loginWithGoogleMobileContinued, 'Google');
  window.nativeCallbackRegisterFacebookToken = buildNativeAuthHandler(loginWithFacebookMobileContinued, 'Facebook');
  window.nativeCallbackRegisterAppleToken = buildNativeAuthHandler(loginWithAppleMobileContinued, 'Apple');
}
