import { limitCanvasSize, releaseCanvas } from '@sp/util/helpers';
import { createImage } from './files';

export async function getAverageColor(url: string): Promise<[number, number, number]> {
  // Only visit every 5 pixels
  const blockSize = 5;
  const defaultRGB: [number, number, number] = [0, 0, 0];
  let data;
  let i = -4;
  const rgb: [number, number, number] = [0, 0, 0];
  let count = 0;

  const canvas = document.createElement('canvas');
  const context = canvas.getContext && canvas.getContext('2d');
  if (!context) {
    return defaultRGB;
  }

  const image = await createImage(url);

  const { width, height } = limitCanvasSize({
    width: image.naturalWidth || image.offsetWidth || image.width,
    height: image.naturalHeight || image.offsetHeight || image.height,
  });

  canvas.height = height;
  canvas.width = width;

  context.drawImage(image, 0, 0);

  try {
    data = context.getImageData(0, 0, width, height);
    releaseCanvas(canvas);
  } catch (e) {
    releaseCanvas(canvas);
    return defaultRGB;
  }

  const length = data.data.length;

  while ((i += blockSize * 4) < length) {
    if (data.data[i + 3] === 0) continue; // Ignore fully transparent pixels
    ++count;
    rgb[0] += data.data[i];
    rgb[1] += data.data[i + 1];
    rgb[2] += data.data[i + 2];
  }

  rgb[0] = Math.floor(rgb[0] / count);
  rgb[1] = Math.floor(rgb[1] / count);
  rgb[2] = Math.floor(rgb[2] / count);

  return rgb;
}

function getColorLuma(rgbColor: [number, number, number]) {
  const [r, g, b] = rgbColor;
  const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b;
  return luma;
}

const LUMA_THRESHOLD = 240;

async function scale(
  img: HTMLImageElement,
  width: number,
  height: number,
  outputType = 'image/png',
): Promise<Blob | null> {
  // Safari does not have built-in resize method with quality control
  if ('createImageBitmap' in window) {
    try {
      const bitmap = await window.createImageBitmap(img, {
        resizeWidth: width,
        resizeHeight: height,
        resizeQuality: 'high',
      });
      if (bitmap.height !== height || bitmap.width !== width) {
        throw new Error('Image bitmap resize not supported!'); // FF93 added support for options, but not resize
      }
      const averageColor = await getAverageColor(img.src);
      const fillColor = getColorLuma(averageColor) < LUMA_THRESHOLD ? '#fff' : '#000';
      return await new Promise(res => {
        const canvas = document.createElement('canvas');
        canvas.width = bitmap.width;
        canvas.height = bitmap.height;
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const ctx2D = canvas.getContext('2d')!;
        ctx2D.fillStyle = fillColor;
        ctx2D.fillRect(0, 0, canvas.width, canvas.height);
        const ctx = canvas.getContext('bitmaprenderer');
        if (ctx) {
          ctx.transferFromImageBitmap(bitmap);
        } else {
          ctx2D.drawImage(bitmap, 0, 0);
        }
        canvas.toBlob(blob => {
          releaseCanvas(canvas);
          res(blob);
        }, outputType);
      });
    } catch (e) {
      console.error(e);
      // Fallback. Firefox below 93 does not recognize `createImageBitmap` with 2 parameters
      return steppedScale(img, width, height, undefined, outputType);
    }
  } else {
    return steppedScale(img, width, height, undefined, outputType);
  }
}

async function steppedScale(
  img: HTMLImageElement,
  width: number,
  height: number,
  step = 0.5,
  outputType = 'image/png',
): Promise<Blob | null> {
  const canvas = document.createElement('canvas');
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const ctx = canvas.getContext('2d')!;
  const oc = document.createElement('canvas');
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const octx = oc.getContext('2d')!;

  const limitedSize = limitCanvasSize({ width, height });
  width = limitedSize.width;
  height = limitedSize.height;

  canvas.width = width;
  canvas.height = height;

  if (img.width * step > width) {
    // For performance avoid unnecessary drawing
    const mul = 1 / step;
    let cur = limitCanvasSize({
      width: Math.floor(img.width * step),
      height: Math.floor(img.height * step),
    });

    oc.width = cur.width;
    oc.height = cur.height;

    octx.drawImage(img, 0, 0, cur.width, cur.height);

    while (cur.width * step > width) {
      cur = {
        width: Math.floor(cur.width * step),
        height: Math.floor(cur.height * step),
      };
      octx.drawImage(oc, 0, 0, cur.width * mul, cur.height * mul, 0, 0, cur.width, cur.height);
    }

    ctx.drawImage(oc, 0, 0, cur.width, cur.height, 0, 0, canvas.width, canvas.height);
  } else {
    ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
  }

  const averageColor = await getAverageColor(img.src);
  ctx.fillStyle = getColorLuma(averageColor) < LUMA_THRESHOLD ? '#fff' : '#000';
  ctx.globalCompositeOperation = 'destination-over';
  ctx.fillRect(0, 0, canvas.width, canvas.height);
  return new Promise(res => {
    canvas.toBlob(blob => {
      releaseCanvas(canvas);
      releaseCanvas(oc);
      res(blob);
    }, outputType);
  });
}

export function scaleImage(image: string | Blob, ratio: number, outputType = 'image/png'): Promise<Blob> {
  const url = image instanceof Blob ? URL.createObjectURL(image) : image;
  const img = new Image();
  return new Promise(resolve => {
    img.onload = () => {
      scale(img, Math.floor(img.width * ratio), Math.floor(img.height * ratio), outputType)
        .then(blob => {
          if (!blob) throw new Error('Image resize failed!');
          return blob;
        })
        .then(resolve)
        .finally(() => {
          if (image instanceof Blob) {
            URL.revokeObjectURL(url); // Revoke blob url that we created
          }
        });
    };
    img.src = url;
  });
}
