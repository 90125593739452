import { MeUser } from '@sp/data/bif';
import { Button, Input, TextArea } from '@sp/ui/elements';
import { FullScreenImageCropper, IMAGE_CROP_FILE_TYPES, ImageCropperMode } from '@sp/ui/image-cropper';
import { dataURItoBlob, getResponsiveAWSImageSrc } from '@sp/util/files';
import { isNotNullish, isNullish, Nullable } from '@sp/util/helpers';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useFilePicker } from 'use-file-picker';
import { UserFieldsPatch } from './owned-profile-model';

export const PROFILE_AVATAR_SIZE = 600;

export const UserAvatar: FC<{ url: Nullable<string>; size?: number }> = ({ url, size = 120 }) => {
  // 600px is used both in profile header & edit form, so it's supposed to be cached already.
  const responsiveUrl = useMemo(() => (url ? getResponsiveAWSImageSrc(url, PROFILE_AVATAR_SIZE) : url), [url]);

  if (isNullish(responsiveUrl)) {
    return (
      <div className="w-full aspect-square aspect-ratio-wrap">
        <img
          src={isNullish(url) ? '/images/avatar-placeholder.svg' : url}
          alt="user avatar"
          width={size}
          height={size}
          className="block rounded-full object-cover overflow-hidden"
        />
      </div>
    );
  }

  return (
    <img src={responsiveUrl} alt="user avatar" className="block w-30 h-30 rounded-full object-cover overflow-hidden" />
  );
};

export const EditUserInfoForm: FC<{
  user: MeUser;
  onSubmit: (fieldsToPatch: UserFieldsPatch) => void;
  onCancel: VoidFunction;
  isUpdating: boolean;
}> = ({ onSubmit, onCancel, isUpdating, user }) => {
  const [nameField, setNameField] = useState(user.name);
  const [bioField, setBioField] = useState(user.bio ?? '');
  const [selectedAvatar, setSelectedAvatar] = useState<Nullable<File>>(null);
  const isNewAvatarSelected = isNotNullish(selectedAvatar);
  const selectedAvatarDataUri = useMemo(
    () => (isNewAvatarSelected ? URL.createObjectURL(selectedAvatar) : ''),
    [isNewAvatarSelected, selectedAvatar],
  );
  const [resizedAvatar, setResizedAvatar] = useState<Nullable<File>>(null);
  const isAvatarChanged = isNotNullish(resizedAvatar);
  const resizedAvatarDataUri = useMemo(
    () => (isAvatarChanged ? URL.createObjectURL(resizedAvatar) : null),
    [isAvatarChanged, resizedAvatar],
  );
  const [openFilePicker, { plainFiles, clear }] = useFilePicker({
    accept: IMAGE_CROP_FILE_TYPES,
    multiple: false,
    readFilesContent: false,
  });

  const isNameChanged = nameField != user.name;
  const isBioChanged = bioField != user.bio;
  const isChanged = isNameChanged || isAvatarChanged || isBioChanged;

  useEffect(() => {
    if (plainFiles.length > 0) {
      setSelectedAvatar(plainFiles[0]);
      clear();
    }
  }, [clear, plainFiles]);

  const submit = useCallback(() => {
    if (isChanged) {
      const patch: UserFieldsPatch = {};
      if (isNameChanged) {
        patch.name = nameField;
      }
      if (isBioChanged) {
        patch.bio = bioField;
      }
      if (isAvatarChanged) {
        patch.avatar = resizedAvatar;
      }
      onSubmit(patch);
    }
  }, [bioField, isAvatarChanged, isBioChanged, isChanged, isNameChanged, nameField, onSubmit, resizedAvatar]);

  const onResizeAvatarResult = useCallback(
    (resizedAvatarUri: string) => {
      if (isNotNullish(selectedAvatar)) {
        const name = selectedAvatar.name;
        const type = selectedAvatar.type;
        const newAvatar = new File([dataURItoBlob(resizedAvatarUri)], name, { type });
        setResizedAvatar(newAvatar);
        setSelectedAvatar(null);
      }
    },
    [selectedAvatar],
  );

  function onCancelResizeAvatar() {
    setSelectedAvatar(null);
    setResizedAvatar(null);
  }

  function reset() {
    clear();
    setSelectedAvatar(null);
    setResizedAvatar(null);
    setNameField(user.name);
  }

  function cancel() {
    reset();
    onCancel();
  }

  return (
    <>
      <div>
        <div className="flex flex-col gap-2 items-center mb-4">
          <button type="button" onClick={openFilePicker}>
            <UserAvatar url={resizedAvatarDataUri ?? user.avatar?.url ?? null} />
          </button>

          <button className="w-fit text-brand font-semibold" type="button" onClick={openFilePicker}>
            {isNotNullish(user.avatar?.url) ? 'Change profile photo' : 'Upload a photo'}
          </button>
        </div>

        <div className="mb-6">
          <label htmlFor="edit-user-name" className="block mb-2">
            Name
          </label>
          <Input
            type="text"
            placeholder="Type name here"
            value={nameField}
            onChange={({ target }) => setNameField(target.value)}
            block
            id="edit-user-name"
          />
        </div>

        <div className="mb-6">
          <label htmlFor="edit-user-bio" className="block mb-2">
            Bio
          </label>
          <TextArea
            placeholder="Tell about yourself"
            value={bioField}
            onChange={({ target }) => setBioField(target.value)}
            rows={1}
            id="edit-user-bio"
            block
          ></TextArea>
        </div>

        <div className="mb-6">
          <label htmlFor="edit-user-name" className="block mb-2">
            Email
          </label>
          <Input type="text" value={user?.email ?? ''} block disabled />
          <span className="text-xs text-secondary">linked to the account through authorization</span>
        </div>

        <Button
          disabled={!isChanged || isUpdating}
          loading={isUpdating}
          onClick={submit}
          color="black"
          block
          className="mb-2"
        >
          Save
        </Button>

        <Button onClick={cancel} color="transparent" block>
          Cancel
        </Button>
      </div>

      <FullScreenImageCropper
        isOpen={isNewAvatarSelected}
        src={selectedAvatarDataUri}
        onResult={onResizeAvatarResult}
        onCancel={onCancelResizeAvatar}
        mode={ImageCropperMode.avatar}
      />
    </>
  );
};
