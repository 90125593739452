const cacheKeys: string[] = [];

export function registerLocalStorageCache(key: string): void {
  cacheKeys.push(key);
}

export function flushLocalStorageCaches(): void {
  for (const key of cacheKeys) {
    localStorage.removeItem(key);
  }
}
