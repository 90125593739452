import { ReactElement } from 'react';
import { ICON_DEFAULTS, IconProps } from './types';

export function IconMicrophone({
  size = ICON_DEFAULTS.size,
  color = ICON_DEFAULTS.color,
  className = ICON_DEFAULTS.className,
  filled = ICON_DEFAULTS.filled,
}: IconProps): ReactElement {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      {filled ? (
        <>
          <path
            d="M15.75 6C15.75 3.92893 14.0711 2.25 12 2.25C9.92893 2.25 8.25 3.92893 8.25 6V12C8.25 14.0711 9.92893 15.75 12 15.75C14.0711 15.75 15.75 14.0711 15.75 12V6Z"
            stroke={color}
            fill={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path d="M12 18.75V21.75" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          <path
            d="M18.7121 12.75C18.5175 14.3936 17.7267 15.9087 16.4895 17.0081C15.2523 18.1075 13.6547 18.7147 11.9996 18.7147C10.3445 18.7147 8.74695 18.1075 7.50975 17.0081C6.27254 15.9087 5.48169 14.3936 5.28711 12.75"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </>
      ) : (
        <>
          <path
            d="M15.75 6C15.75 3.92893 14.0711 2.25 12 2.25C9.92893 2.25 8.25 3.92893 8.25 6V12C8.25 14.0711 9.92893 15.75 12 15.75C14.0711 15.75 15.75 14.0711 15.75 12V6Z"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path d="M12 18.75V21.75" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          <path
            d="M18.7121 12.75C18.5175 14.3936 17.7267 15.9087 16.4895 17.0081C15.2523 18.1075 13.6547 18.7147 11.9996 18.7147C10.3445 18.7147 8.74695 18.1075 7.50975 17.0081C6.27254 15.9087 5.48169 14.3936 5.28711 12.75"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </>
      )}
    </svg>
  );
}
