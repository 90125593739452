import { FC } from 'react';

export const NewMessagesSeparator: FC = () => {
  return (
    <div className="flex items-center gap-2 px-2 text-secondary">
      <div className="w-full h-px bg-stroke" />
      <span className="whitespace-nowrap">Unread messages</span>
      <div className="w-full h-px bg-stroke" />
    </div>
  );
};
