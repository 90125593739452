import { FC, ReactNode } from 'react';

export const SpaceDescriptionMultiline: FC<{
  text: string;
  heading?: ReactNode;
}> = ({ text, heading }) => {
  if (text.length === 0) return null;
  return (
    <div>
      {heading && <h4>{heading}</h4>}
      <p className="overflow-hidden break-words whitespace-pre-line">{text}</p>
    </div>
  );
};
