import { IconFilmStrip, IconImage, IconMicrophone } from '@sp/ui/icons';
import { FC } from 'react';

export const AttachmentAudioPlug: FC = () => (
  <div className="w-full h-16 flex items-center justify-center bg-light rounded-2.5xl">
    <IconMicrophone filled size={72} className="text-disabled" />
  </div>
);
export const AttachmentImagePlug: FC = () => (
  <div className="aspect-square aspect-ratio-wrap w-full">
    <div className="flex items-center justify-center bg-light rounded-2.5xl">
      <IconImage filled size={72} className="text-disabled" />
    </div>
  </div>
);
export const AttachmentVideoPlug: FC = () => (
  <div className="aspect-square aspect-ratio-wrap w-full">
    <div className="flex items-center justify-center bg-light rounded-2.5xl">
      <IconFilmStrip filled size={72} className="text-disabled" />
    </div>
  </div>
);
