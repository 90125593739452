import { RemirrorJSON } from '@remirror/core';
import { useHelpers, useRemirrorContext } from '@remirror/react';
import isEqual from 'lodash/isEqual';
import { useEffect } from 'react';

export const OnChangeInput = ({ input }: Readonly<{ input: RemirrorJSON }>) => {
  const { setContent } = useRemirrorContext();
  const { getJSON } = useHelpers();

  useEffect(() => {
    if (isEqual(getJSON(), input)) {
      return;
    }

    setContent(input);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [input]);

  return null;
};
