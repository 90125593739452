import { MeApiFactory } from '../api/expert/api';
import { UploadedFile } from '../space-types/concrete-space.types';
import { apiResponseAdHoc } from './helpers';

const expertMeApi = MeApiFactory();

export type MyEarnings = {
  readonly earned: number;
  readonly involvedCount: number;
  readonly purchasedCount: number;
};

export type MyEarningsDetails = {
  readonly earns: number;
  readonly customer: null | {
    readonly id: number;
    readonly name: string;
    readonly avatar: null | UploadedFile;
  };
};

export async function getMyCreatorEarnings(): Promise<MyEarnings> {
  return expertMeApi.meEarnsGet().then(r => apiResponseAdHoc(r.data));
}

export async function getMyCreatorEarningsDetails(): Promise<MyEarningsDetails[]> {
  return expertMeApi.meEarnsDetailedGet().then(r => apiResponseAdHoc(r.data));
}
