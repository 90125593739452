import { $isNativeAppInitialized, hideNativeLoader, showNativeLoader } from '@sp/util/native';
import { useStore } from 'effector-react';
import { ReactElement, useEffect } from 'react';

export function SpinnerPage(): ReactElement {
  const isNativeAppInitialized = useStore($isNativeAppInitialized);
  useEffect(() => {
    if (isNativeAppInitialized) showNativeLoader('self');
    return () => {
      if (isNativeAppInitialized) hideNativeLoader();
    };
  }, [isNativeAppInitialized]);
  return <div className="fixed z-100 inset-0 w-full h-full bg-white" />;
}
