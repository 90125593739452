import { NAVIGATE_BACK_MODEL, useSetPreviousUrl } from '@sp/feature/back-button';
import { ROUTES } from '@sp/util/router';
import { VFC } from 'react';
import { Navigate, useLocation, useParams } from 'react-router-dom';
import { CommunityLayout } from './community-layout';
import { STANDALONE_FEED_TAB_KEY, StandaloneFeedPage } from './standalone-feed-page';

const CommunityTabKeys: Record<string, string> = {
  chat: 'chat',
  feed: 'feed',
  live: 'live',
  mentions: 'mentions',
  [STANDALONE_FEED_TAB_KEY]: STANDALONE_FEED_TAB_KEY,
};

const CommunityScreen: VFC<{ tabKey: string }> = ({ tabKey }) => {
  useSetPreviousUrl(ROUTES.HOME_URL);

  const params = useParams<typeof ROUTES.SPACE_ID_PARAM>();
  const spaceId = Number(params.spaceId);

  if (isNaN(spaceId)) {
    console.error(`Invalid community space id: ${params.spaceId}`);
    return <Navigate to={ROUTES.HOME_URL} />;
  }

  if (tabKey === STANDALONE_FEED_TAB_KEY) {
    return <StandaloneFeedPage communityId={spaceId} />;
  }

  return <CommunityLayout communityId={spaceId} onBackClick={NAVIGATE_BACK_MODEL.navigatedBack} tabKey={tabKey} />;
};

export const ValidateCommunityScreen = () => {
  const { tabKey } = useParams();
  const { search } = useLocation();
  if (!tabKey || !CommunityTabKeys[tabKey]) {
    return <Navigate replace to={`../сhat${search}`} />;
  }
  return <CommunityScreen tabKey={tabKey} />;
};
