import {
  ATTACHMENT_TYPE,
  AudioAttachment,
  ChatMessageAttachment,
  FileAttachment,
  ImageAttachment,
  LinkAttachment,
  VideoAttachment,
} from '@sp/util/stream-chat';

export type MediaAttachment = ImageAttachment | VideoAttachment;

export interface GroupedAttachments {
  readonly media?: readonly MediaAttachment[];
  readonly audio?: readonly AudioAttachment[];
  readonly files?: readonly FileAttachment[];
  readonly links?: readonly LinkAttachment[];
}

export function groupAttachments(
  attachments: readonly ChatMessageAttachment[],
  types: Array<keyof GroupedAttachments>,
): GroupedAttachments {
  const media: MediaAttachment[] = [];
  const audio: AudioAttachment[] = [];
  const files: FileAttachment[] = [];
  const links: LinkAttachment[] = [];

  for (const attachment of attachments) {
    switch (attachment.type) {
      case ATTACHMENT_TYPE.image: {
        if (types.includes('media')) media.push(attachment);
        break;
      }
      case ATTACHMENT_TYPE.video: {
        if (types.includes('media')) media.push(attachment);
        break;
      }
      case ATTACHMENT_TYPE.audio: {
        if (types.includes('audio')) audio.push(attachment);
        break;
      }
      case ATTACHMENT_TYPE.file: {
        if (types.includes('files')) files.push(attachment);
        break;
      }
      case ATTACHMENT_TYPE.link: {
        if (types.includes('links')) links.push(attachment);
        break;
      }
    }
  }

  return {
    media,
    audio,
    files,
    links,
  } as const;
}
