import { createTrackEvent, createTrackTimeEvent, createTrackWithContextEvent } from './tracking';

export const ANALYTICS = {
  authenticationCompleteTrackingStart: createTrackTimeEvent('Authentication Complete'),
  authenticationStartTracked: createTrackEvent<{ Method: string }>('Authentication Start'),
  authenticationCompleteTracked: createTrackEvent<{ Method: string }>('Authentication Complete'),
  spaceOpenTrackingStart: createTrackTimeEvent('Space Open'),
  spaceOpenTracked: createTrackWithContextEvent('Space Open'),
  spaceCloseTracked: createTrackWithContextEvent('Space Close'),
  purchaseViewedTracked: createTrackEvent<{
    'Creator Name': string;
    'Creator ID': string;
    'Space Name': string;
    'Space ID': string;
    Value: string;
    Source: string;
    'Transaction ID'?: string;
  }>('Purchase viewed'),
  journeyCheckTracked: createTrackWithContextEvent('Journey Check'),
  feedbackLeftTracked: createTrackWithContextEvent<{ 'Challenge day Number': string }>('Feedback Left'),
  enterLiveRoomTracked: createTrackWithContextEvent('Enter Live Room'),
  openInDiscoverTracked: createTrackEvent<{ 'Space Name': string; 'Space ID': string; Type: string }>(
    'Open in Discover',
  ),
  platformChannelCheckTracked: createTrackEvent<{ From: string }>('Platform Channel Check'),
  viewProfileFromChatTracked: createTrackWithContextEvent<{
    'Chat profile View': string; //boolean
    'Profile ID': string;
  }>('View profile from chat'),
  messageSentTracked: createTrackWithContextEvent<{
    'Message status': string;
    Text: string;
    Audio: string;
    'Attached Video': string;
    'Attached Photo': string;
  }>('Message sent'),
  reactOnMessageTracked: createTrackWithContextEvent<{ 'Chat reaction Type': string }>('Message sent'),
  playMediaCompleteTrackStarted: createTrackTimeEvent('Play Media Complete'),
  openMediaTracked: createTrackWithContextEvent<{ 'Media Type': string; 'Media Name': string }>('Open Media'),
  playMediaCompleteTracked: createTrackWithContextEvent<{ 'Media Type': string; 'Media Name': string }>(
    'Play Media Complete',
  ),
} as const;
