import { isInApp } from './helpers';

export function requestNotifications() {
  if (isInApp()) {
    window.native.service.push.request();
  }
}

export function requestMedia(type: 'video' | 'audio') {
  if (isInApp()) {
    window.native.service.media.request(type);
  }
}

export function setNativeCallbackRegisterNotificationToken(cb: (token: string) => void) {
  window.nativeCallbackRegisterNotificationToken = cb;
}

export function setNativeCallbackNotificationsDenied(cb: () => void) {
  window.nativeCallbackNotificationsDenied = cb;
}

export function setNativeCallbackMediaPermissionUpdate(cb: (res: boolean, type: 'audio' | 'video') => void) {
  window.nativeCallbackMediaPermissionUpdate = cb;
}

export function openSettings() {
  window.native.service.openSettings();
}

export function checkNotifications() {
  if (isInApp()) {
    return window.native.permissions.notifications;
  }
  return 'unknown';
}

export function checkMicrophone() {
  if (isInApp()) {
    return window.native.permissions.microphone;
  }
  return 'unknown';
}
export function checkCamera() {
  if (isInApp()) {
    return window.native.permissions.camera;
  }
  return 'unknown';
}
