import { EditorComponent } from '@remirror/react';
import { SyntheticEvent, useRef, VFC } from 'react';
import './editor.pcss';

// This is needed to prevent external navigation while editing on mobile.
function stopAnchorClickPropagation(event: SyntheticEvent) {
  if (event.type === 'click' && event.target instanceof HTMLAnchorElement) {
    event.stopPropagation();
  }
}

async function handleScroll(elem: HTMLDivElement | null) {
  if (!elem) return;
  await new Promise(resolve => requestAnimationFrame(resolve));
  const { scrollTop, scrollLeft, scrollHeight, clientHeight } = elem;
  const atTop = scrollTop === 0;
  const beforeTop = 1;
  const atBottom = scrollTop === scrollHeight - clientHeight;
  const beforeBottom = scrollHeight - clientHeight - 1;

  if (atTop) {
    elem.scrollTo(scrollLeft, beforeTop);
  } else if (atBottom) {
    elem.scrollTo(scrollLeft, beforeBottom);
  }
}

export const TextEditor: VFC<Readonly<{ maxHeight: string }>> = ({ maxHeight }) => {
  const scrollContainer = useRef<HTMLDivElement>(null);

  const isScrollable = () => {
    if (!scrollContainer.current) return false;
    return scrollContainer.current.scrollHeight > scrollContainer.current.clientHeight;
  };

  return (
    <div
      onClick={stopAnchorClickPropagation}
      onScroll={() => handleScroll(scrollContainer.current)}
      ref={scrollContainer}
      className={`remirror-theme break-words sp-remirror overflow-y-auto overscroll-contain focus:outline-none focus:ring-0 focus:border-none ${
        isScrollable() ? 'mobile-pan' : ''
      }`}
      style={{ wordBreak: 'break-word', maxHeight }}
    >
      <EditorComponent />
    </div>
  );
};
