import { PaymentsApiFactory } from '../../api/default';
import { apiResponseAdHoc } from '../helpers';

const paymentsApi = PaymentsApiFactory();

export enum PaymentStatus {
  'requires_payment_method' = 'requires_payment_method',
  'requires_confirmation' = 'requires_confirmation',
  'requires_action' = 'requires_action',
  'processing' = 'processing',
  'requires_capture' = 'requires_capture',
  'canceled' = 'canceled',
  'succeeded' = 'succeeded',
  'check_payment_error' = 'check_payment_error',
  'skipped' = 'skipped',
}

export type Payment = {
  paymentId: string;
  status: PaymentStatus;
  error: string;
};

export function getPayment(paymentId: string): Promise<Payment> {
  return paymentsApi.paymentsPaymentIdGet(paymentId).then(r =>
    apiResponseAdHoc({
      ...r.data,
      paymentId: r.data.id,
    } as Payment),
  );
}
