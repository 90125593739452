import { useCloseModalOnBack } from '@sp/feature/back-button';
import { isNotNullish } from '@sp/util/helpers';
import { FC } from 'react';
import { createPortal } from 'react-dom';
import { ImageCropper, ImageCropperProps } from './image-cropper';

const root = document.getElementById('root');

export const FullScreenImageCropper: FC<
  {
    isOpen: boolean;
  } & ImageCropperProps
> = ({ isOpen, ...props }) => {
  useCloseModalOnBack({ isOpen, onCloseCallback: () => props.onCancel() });

  return isOpen && isNotNullish(root)
    ? createPortal(
        <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-full z-80 bg-accent">
          <ImageCropper {...props} />
        </div>,
        root,
      )
    : null;
};
