import { ReactElement } from 'react';
import { ICON_DEFAULTS, IconProps } from './types';

export function IconPause({
  size = ICON_DEFAULTS.size,
  color = ICON_DEFAULTS.color,
  className = ICON_DEFAULTS.className,
}: IconProps): ReactElement {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M13.5 3V13C13.5 13.2652 13.3946 13.5196 13.2071 13.7071C13.0196 13.8946 12.7652 14 12.5 14H10.25C9.98478 14 9.73043 13.8946 9.54289 13.7071C9.35536 13.5196 9.25 13.2652 9.25 13V3C9.25 2.73478 9.35536 2.48043 9.54289 2.29289C9.73043 2.10536 9.98478 2 10.25 2H12.5C12.7652 2 13.0196 2.10536 13.2071 2.29289C13.3946 2.48043 13.5 2.73478 13.5 3ZM5.75 2H3.5C3.23478 2 2.98043 2.10536 2.79289 2.29289C2.60536 2.48043 2.5 2.73478 2.5 3V13C2.5 13.2652 2.60536 13.5196 2.79289 13.7071C2.98043 13.8946 3.23478 14 3.5 14H5.75C6.01522 14 6.26957 13.8946 6.45711 13.7071C6.64464 13.5196 6.75 13.2652 6.75 13V3C6.75 2.73478 6.64464 2.48043 6.45711 2.29289C6.26957 2.10536 6.01522 2 5.75 2Z"
        fill={color}
      />
    </svg>
  );
}
