import { ReactElement } from 'react';
import { ICON_DEFAULTS, IconProps } from './types';

export function IconSmileyHappy({
  size = ICON_DEFAULTS.size,
  color = ICON_DEFAULTS.color,
  className = ICON_DEFAULTS.className,
}: IconProps): ReactElement {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.7075 7.40369C24.5071 3.31089 17.0045 3.79043 11.9953 6.84864C3.36215 12.1196 2.24224 25.2774 8.40872 31.2011C15.2082 37.7322 24.4887 36.1582 29.1135 31.9796C35.1077 26.5639 37.67 17.6469 32.5102 11.005"
        fill="none"
      />
      <path
        d="M29.7075 7.40369C24.5071 3.31089 17.0045 3.79043 11.9953 6.84864C3.36215 12.1196 2.24224 25.2774 8.40872 31.2011C15.2082 37.7322 24.4887 36.1582 29.1135 31.9796C35.1077 26.5639 37.67 17.6469 32.5102 11.005"
        stroke={color}
        strokeWidth="2"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.9366 15.9445C15.8018 16.7753 15.746 17.6308 15.7246 18.4725"
        stroke={color}
        strokeWidth="2"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.3757 15.7109C23.3816 16.7144 23.4439 17.7619 23.3438 18.7642"
        stroke={color}
        strokeWidth="2"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.9863 22.9878C12.2353 23.9219 12.7916 24.7393 13.4722 25.4117C16.8097 28.7095 23.0423 27.6791 25.4211 23.6987"
        stroke={color}
        strokeWidth="2"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
