import { ANALYTICS } from '@sp/feature/analytics';
import { Modal, MODAL_ANIMATION_DURATION } from '@sp/ui/modal';
import { isNotNullish } from '@sp/util/helpers';
import { useStore } from 'effector-react';
import { FC, useEffect, useMemo, useState } from 'react';
import { CHALLENGE_MODEL } from '../challenge-model';
import { StepFeedbackFormView } from './step-feedback-form-view';
import { STEPS_MODEL } from './steps-model';

export const StepFeedbackFormModal: FC = () => {
  const challenge = useStore(CHALLENGE_MODEL.$challenge);
  const { $stepsWithUI, skipFeedback, sendFeedback } = STEPS_MODEL;
  const [stepId, setStepId] = useState<number | null>(null);
  const [isShowFeedbackForm, setIsShowFeedbackForm] = useState(false);
  const stepsWithUI = useStore($stepsWithUI);
  const stepWithUI = useMemo(() => stepsWithUI.find(({ step }) => step.id === stepId), [stepId, stepsWithUI]);

  function close() {
    setIsShowFeedbackForm(false);
    setTimeout(() => setStepId(null), MODAL_ANIMATION_DURATION);
  }

  useEffect(() => {
    const { unsubscribe } = STEPS_MODEL.completeStepSuccess.watch(id => {
      const step = stepsWithUI.find(({ step }) => step.id === id);
      if (step?.step.isSignificant) {
        setStepId(id);
        setIsShowFeedbackForm(true);
      }
    });
    return unsubscribe;
  }, [stepsWithUI]);

  useEffect(() => {
    const { unsubscribe } = STEPS_MODEL.feedbackSuccess.watch(() => {
      if (isNotNullish(stepWithUI)) {
        const dayIndex = stepsWithUI.findIndex(({ step }) => step.id === stepWithUI.step.id);
        const properties = {
          'Challenge day Number': (dayIndex + 1).toString(),
        };
        ANALYTICS.feedbackLeftTracked(properties);
      }

      close();
    });
    return unsubscribe;
  }, [challenge.id, challenge.title, stepWithUI, stepsWithUI]);

  return (
    <Modal isOpen={isShowFeedbackForm} onClose={() => close()} closeOnBackdropClick withBackdrop>
      {isNotNullish(stepWithUI) ? (
        <>
          <div className="text-xs text-secondary mb-0.5">{stepWithUI.step.dayName}</div>
          <div className="font-semibold mb-4">{stepWithUI.step.title}</div>
          <StepFeedbackFormView
            isFeedbackSending={stepWithUI.ui.isFeedbackSending}
            isFeedbackSkipping={stepWithUI.ui.isFeedbackSkipping}
            onSkipFeedback={() => skipFeedback({ stepId: stepWithUI.step.id })}
            onSendFeedback={feedback => sendFeedback({ ...feedback, stepId: stepWithUI.step.id })}
          />
        </>
      ) : null}
    </Modal>
  );
};
