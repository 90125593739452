import { ReactElement } from 'react';
import { ICON_DEFAULTS, IconProps } from './types';

export function IconTrashSimple({
  size = ICON_DEFAULTS.size,
  className = ICON_DEFAULTS.className,
}: IconProps): ReactElement {
  return (
    <svg
      width={size}
      height={size}
      className={className}
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M16.875 4.69531H3.125" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M6.875 2.19531H13.125" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M15.625 4.69531V16.5703C15.625 16.7361 15.5592 16.895 15.4419 17.0123C15.3247 17.1295 15.1658 17.1953 15 17.1953H5C4.83424 17.1953 4.67527 17.1295 4.55806 17.0123C4.44085 16.895 4.375 16.7361 4.375 16.5703V4.69531"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
