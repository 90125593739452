import {
  Attachment,
  Channel,
  ChannelMemberResponse,
  DefaultGenerics,
  Event,
  EventTypes,
  ExtendableGenerics,
  FormatMessageResponse,
  Message,
  MessageResponse,
  MessageResponseBase,
  PartialMessageUpdate,
  StreamChat,
  UpdatedMessage,
  UserResponse,
} from 'stream-chat';
import { ChatMessageAttachment } from './attachment-types';

export const enum ChatChannelType {
  messaging = 'messaging',
  platform = 'platform',
}

export enum KnownReaction {
  thumbs_up = 'thumbs_up',
  thumbs_down = 'thumbs_down',
  fire = 'fire',
  heart = 'heart',
  broken_heart = 'broken_heart',
  smiling_with_hearts = 'smiling_with_hearts',
  partying = 'partying',
  smiling_with_tear = 'smiling_with_tear',
  sunglasses = 'sunglasses',
  slightly_smiling = 'slightly_smiling',
  relieved = 'relieved',
  cry = 'cry',
  scream = 'scream',
  rage = 'rage',
  angry = 'angry',
  laughing = 'laughing',
  zany_face = 'zany_face',
  hug = 'hug',
  sunny = 'sunny',
  plus = 'plus',
  checkmark = 'checkmark',
  apple = 'apple',
  pray = 'pray',
  muscle = 'muscle',
  selfplus = 'selfplus',
}

type ExtendedGenerics<Extension extends Partial<ExtendableGenerics>> = Omit<DefaultGenerics, keyof Extension> &
  Extension;

export enum PostType {
  step = 'step',
  intro = 'intro',
  finish = 'finish',
  deferred = 'deferred',
}

export enum PlatformFormType {
  permission = 'permission',
  rate = 'rate',
}

export enum SelfPlusCustomEventType {
  ConvertVideoSuccess = 'sp_convert_video_success',
  ConvertVideoError = 'sp_convert_video_error',
  ConvertAudioSuccess = 'sp_convert_audio_success',
  ConvertAudioError = 'sp_convert_audio_error',
}

type CustomEvent<Type extends SelfPlusCustomEventType, Data> = Readonly<{ type: Type; data: Readonly<Data> }>;
type SelfPlusCustomEvents =
  | CustomEvent<SelfPlusCustomEventType.ConvertAudioSuccess, { url: string; id: number }>
  | CustomEvent<SelfPlusCustomEventType.ConvertVideoSuccess, { url: string; id: number }>
  | CustomEvent<SelfPlusCustomEventType.ConvertAudioError, { id: number }>
  | CustomEvent<SelfPlusCustomEventType.ConvertVideoError, { id: number }>;
export type PostMeta = {
  readonly postId: number | null;
  readonly spaceId: number;
  readonly type: PostType;
  readonly stepId: number | null;
};
export type SelfplusExtraMessageFields = {
  selfplusCommunityPost?: boolean;
};
export type SelfPlusExtendedGenerics = ExtendedGenerics<{
  eventType: Readonly<{
    type: ChatEventTypes;
  }>;
  messageType: {
    // Let's use this field to store all the message meta that can be edited in message form
    selfplusMessageMeta?: {
      onlyPremium?: boolean;
    };
    readonly selfplusMeta?: {
      formType: PlatformFormType;
    };
    readonly selfplusPostMeta?: {
      readonly postId: number;
      readonly spaceId: number;
    } & (
      | {
          readonly type: PostType.step;
          readonly stepId: number;
        }
      | {
          readonly type: PostType.finish | PostType.intro | PostType.deferred;
          readonly stepId: null;
        }
    );
  } & SelfplusExtraMessageFields;
  attachmentType: {
    readonly selfplusAttachment?: ChatMessageAttachment;
  };
  reactionType: {
    type: KnownReaction | string;
  };
  userType: {
    readonly image: string | null;
    readonly isCreator: boolean;
  };
}>;

export interface ConnectUserData {
  readonly id: string;
  readonly name: string;
  readonly image: string;
}

export type JumpEvent = { messageId: string | 'latest'; cb?: (id: string) => void };
export type ChatClient = StreamChat<SelfPlusExtendedGenerics>;
export type ChatChannel = Channel<SelfPlusExtendedGenerics>;
export type ChatMessage = Message<SelfPlusExtendedGenerics>;
export type ChatNonFormatMessage = MessageResponse<SelfPlusExtendedGenerics>;
export type ChatFormatMessage = FormatMessageResponse<SelfPlusExtendedGenerics>;
export type ChatUpdatedMessage = UpdatedMessage<SelfPlusExtendedGenerics>;
export type ChatQuotedMessage = MessageResponseBase<SelfPlusExtendedGenerics>;
export type ChatAttachment = Attachment<SelfPlusExtendedGenerics>;
export type ChatUser = UserResponse<SelfPlusExtendedGenerics>;
export type ChatChannelMember = ChannelMemberResponse<SelfPlusExtendedGenerics>;
export type ChatEventTypes = EventTypes | SelfPlusCustomEventType;
export type ChatEvent =
  | Event<SelfPlusExtendedGenerics>
  | (Omit<Event<SelfPlusExtendedGenerics>, 'type'> & SelfPlusCustomEvents);
export type ChatPartialMessageUpdate = PartialMessageUpdate<SelfPlusExtendedGenerics>;
