import { ANALYTICS } from '@sp/feature/analytics';
import { ResponsiveImage } from '@sp/ui/elements';
import { PhotoswipeItem } from '@sp/ui/gallery';
import {
  getImagePreviewUrl,
  isUploadable,
  UploadableImageAttachment,
  ViewImageAttachment,
} from '@sp/util/chat-attachments';
import { getAWSImageFullSrc } from '@sp/util/files';
import { FC, useCallback, useState } from 'react';

export const EditAttachmentImageView: FC<{
  attachment: UploadableImageAttachment | ViewImageAttachment;
}> = ({ attachment }) => {
  const isUploadableAttachment = isUploadable(attachment);
  const previewUrl = getImagePreviewUrl(attachment) ?? attachment.url;
  const fullSizeUrl = getAWSImageFullSrc(attachment.url);
  const [thumbnailSrc, setThumbnailSrc] = useState<string | undefined>(undefined);

  const handleThumbnailChange = useCallback((src: string | null) => {
    setThumbnailSrc(src ?? undefined);
  }, []);

  return isUploadableAttachment ? (
    <div className="w-full relative">
      {previewUrl && (
        <div className="aspect-square aspect-ratio-wrap w-full">
          <ResponsiveImage
            src={previewUrl}
            className="object-cover overflow-hidden rounded-1.5lg bg-secondary border-stroke border"
            alt={attachment.name}
            maxWidth={attachment.width}
          />
        </div>
      )}
    </div>
  ) : (
    <PhotoswipeItem
      cropped
      original={fullSizeUrl}
      thumbnail={thumbnailSrc}
      width={attachment.width}
      height={attachment.height}
      alt={attachment.name}
    >
      {({ ref, open }) => (
        <div className="w-full relative cursor-pointer">
          <div className="aspect-square aspect-ratio-wrap w-full">
            <ResponsiveImage
              ref={ref as any}
              srcRef={handleThumbnailChange}
              role="button"
              onClick={event => {
                ANALYTICS.openMediaTracked({
                  'Media Type': attachment.fileType,
                  'Media Name': attachment.url,
                });
                open(event);
              }}
              src={previewUrl}
              className="object-cover overflow-hidden rounded-1.5lg bg-secondary border-stroke border"
              alt={attachment.name}
            />
          </div>
        </div>
      )}
    </PhotoswipeItem>
  );
};
