import { IconArrowCounterClockwise, IconClose } from '@sp/ui/icons';
import { useFocusTextEditor } from '@sp/ui/text-editor';
import {
  ChatMessageUploadableAttachment,
  ChatMessageViewAttachment,
  isUploadable,
  isUploadRejected,
  UploadingStatus,
} from '@sp/util/chat-attachments';
import { cls, generateUniqueString } from '@sp/util/helpers';
import { createEvent, restore } from 'effector';
import { useStore } from 'effector-react';
import { FC, useCallback, useMemo, useRef } from 'react';

const BTN_STYLES = 'w-6 h-6 flex rounded-full items-center justify-center border-2 border-light';
const PROGRESS_SIZE = 24;
const PROGRESS_WIDTH = 2;
const RADIUS = PROGRESS_SIZE / 2;
const PROGRESS_CIRCLE_RADIUS = PROGRESS_SIZE / 2 - PROGRESS_WIDTH / 2;
const PROGRESS_CIRCLE_LENGTH = Math.PI * PROGRESS_CIRCLE_RADIUS * 2;
const strokeDasharray = PROGRESS_CIRCLE_LENGTH;

const setActiveBtnId = createEvent<string>();
const $activeBtnId = restore(setActiveBtnId, '');

function useConfirmState() {
  const id = useRef(generateUniqueString()).current;
  const activeBtnId = useStore($activeBtnId);
  const isConfirm = useMemo(() => activeBtnId === id, [activeBtnId, id]);

  return [isConfirm, (isActive: boolean) => setActiveBtnId(isActive ? id : '')] as const;
}

export const AttachmentStatusButton: FC<{
  attachment: ChatMessageViewAttachment | ChatMessageUploadableAttachment;
  onCancel: () => void;
}> = ({ attachment, onCancel }) => {
  const progressWrap = useRef<HTMLButtonElement>(null);
  const isUploadableAttachment = isUploadable(attachment);
  const [isConfirmActive, setIsConfirmActive] = useConfirmState();

  const focusCommands = useFocusTextEditor();

  const onProgressClick = useCallback(
    e => {
      focusCommands.refocus();
      e.stopPropagation();
      e.preventDefault();
      if (!isConfirmActive) {
        setIsConfirmActive(true);
      } else {
        onCancel();
      }
    },
    [focusCommands, isConfirmActive, onCancel, setIsConfirmActive],
  );

  if (isUploadableAttachment && attachment.status !== UploadingStatus.ready) {
    // REJECTED STATE
    if (isUploadRejected(attachment)) {
      return (
        <button type="button" className={`${BTN_STYLES} bg-alert text-active`} onClick={attachment.start}>
          <IconArrowCounterClockwise size={24} />
        </button>
      );
    }

    // UPLOADING STATE
    const progress = attachment.progress ?? 0;
    let realProgress;

    if (isNaN(progress) || progress < 1) {
      realProgress = 1;
    } else if (progress > 100) {
      realProgress = 100;
    } else {
      realProgress = progress;
    }

    const strokeDashoffset = ((100 - realProgress) / 100) * PROGRESS_CIRCLE_LENGTH;

    return (
      <button
        ref={progressWrap}
        tabIndex={0}
        type="button"
        onClick={onProgressClick}
        className={cls(BTN_STYLES, 'bg-primary', isConfirmActive && 'bg-accent text-active')}
      >
        <svg width={PROGRESS_SIZE} height={PROGRESS_SIZE} className={cls(isConfirmActive && 'hidden')}>
          <circle
            cx={RADIUS}
            cy={RADIUS}
            r={PROGRESS_CIRCLE_RADIUS}
            fillOpacity={0}
            strokeWidth={PROGRESS_WIDTH}
            strokeDasharray={strokeDasharray}
            strokeDashoffset={strokeDashoffset}
            strokeLinecap="round"
            stroke="#63CE30"
            className="origin-center duration-100 -rotate-90"
          />
        </svg>
        <IconClose size={24} className={cls(isConfirmActive ? 'block' : 'hidden')} />
      </button>
    );
  }

  // UPLOADED STATE
  return (
    <button type="button" className={`${BTN_STYLES} bg-primary text-secondary`} onClick={onCancel}>
      <IconClose size={24} />
    </button>
  );
};
