import { checkFeatureFlag, ENV_MODE, EnvMode, FeatureFlag } from '@sp/data/env';
import { useScheduledCallback } from '@sp/util/helpers';
import { add } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';

declare global {
  interface Window {
    setLocalDateOffset?: (days: number) => void;
  }
}

// Возможность сдвинуть текущую дату
const OFFSET_STORAGE_KEY = 'sp_actual_local_date_offset';
const OFFSET_SET_EVENT_KEY = 'sp_actual_local_date_offset_set';
let getNow = () => new Date();

if (ENV_MODE !== EnvMode.production && checkFeatureFlag(FeatureFlag.enableScheduledActualDateDebugger)) {
  let offset = parseInt(localStorage.getItem(OFFSET_STORAGE_KEY) ?? '0');
  getNow = () => add(new Date(), { days: offset });

  console.groupCollapsed('ScheduledActualDate debugger');
  console.info('==== THIS IS ONLY INTENDED FOR TESTING PURPOSES!');
  console.info(`==== Actual local date is ${getNow()} with shift ${offset} days`);
  console.info(`==== To shift actual local date use setLocalDateOffset()`);
  console.groupEnd();

  window.setLocalDateOffset = (daysOffset = 0) => {
    localStorage.setItem(OFFSET_STORAGE_KEY, daysOffset.toString());
    offset = daysOffset;
    document.dispatchEvent(new CustomEvent(OFFSET_SET_EVENT_KEY));

    console.info(`==== Now actual local date is ${getNow()} with shift ${offset} days`);
  };
}

export function useScheduledActualDate(scheduledAt: (Date | number)[]): Date {
  const now = useScheduledCallback(getNow, scheduledAt);
  const [correctNow, setCorrectNow] = useState(now);
  const handleSetOffset = useCallback(() => setCorrectNow(getNow()), []);

  useEffect(() => setCorrectNow(now), [now]);

  useEffect(() => {
    if (ENV_MODE === EnvMode.production) return;
    document.addEventListener(OFFSET_SET_EVENT_KEY, handleSetOffset);
    return () => document.removeEventListener(OFFSET_SET_EVENT_KEY, handleSetOffset);
  }, [handleSetOffset]);

  return correctNow;
}
