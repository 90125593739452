import {
  InvalidContentBlock,
  InvalidContentHandler,
  InvalidContentHandlerProps,
  RemirrorJSON,
  Shape,
} from '@remirror/core';
import { assert } from '@sp/util/helpers';
import clone from 'lodash/clone';
import isNumber from 'lodash/isNumber';
import isString from 'lodash/isString';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function setClone(obj: any, key: string | number, value: any) {
  const newObj = clone(obj);
  newObj[key] = value;
  return newObj;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function setPropInternal<Type extends object = any>(
  path: Array<string | number>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  obj: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any,
  index: number,
): Type {
  if (path.length === index) {
    return value;
  }

  // Create things as we go down if they don't exist
  obj = obj || {};

  const key = path[index];

  assert(!!key);
  return setClone(obj, key, setPropInternal(path, obj[key], value, ++index));
}

// This is set function from Remirror helpers with switched out clone method because the original can't handle arrays.
// https://github.com/remirror/remirror/blob/remirror%402.0.0-beta.13/packages/remirror__core-helpers/src/core-helpers.ts#L675-L687
function set(path: number | string | Array<string | number>, obj: Shape, value: unknown): Shape {
  if (isNumber(path)) {
    return setClone(obj, path, value);
  }

  if (isString(path)) {
    path = path.split('.');
  }

  return setPropInternal(path, obj, value, 0);
}

function createUnsupportedPlaceholderNode(): RemirrorJSON {
  return {
    type: 'text',
    text: `[Unsupported]`,
  };
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function replaceWithUnsupportedPlaceholderTransformer(
  json: RemirrorJSON,
  invalidContent: InvalidContentBlock[],
): RemirrorJSON {
  let newJSON = json;

  for (const block of invalidContent) {
    if (block.invalidParentNode) {
      continue;
    }

    newJSON = set(block.path, newJSON, createUnsupportedPlaceholderNode()) as RemirrorJSON;
  }

  return newJSON;
}

export const handleRemirrorError: InvalidContentHandler = ({
  transformers,
  invalidContent,
  error,
  json,
}: InvalidContentHandlerProps) => {
  console.log('Remirror:', error.message, { invalidContent, json });
  // return replaceWithUnsupportedPlaceholderTransformer(json, invalidContent);
  return transformers.remove(json, invalidContent);
};
