import { createEffect, createEvent, restore, sample } from 'effector';
import { MediaPlayer } from './media-players-cache';

export interface GlobalMediaPlayer {
  readonly player: MediaPlayer;
  readonly meta: {
    readonly authorName: string;
  };
}

const setPlayer = createEvent<GlobalMediaPlayer>();
const clear = createEvent<void>();

const pause = createEvent<void>();
const pausePlayerFx = createEffect<GlobalMediaPlayer | null, void>(globalPlayer => {
  globalPlayer?.player.pause();
});

const $player = restore(setPlayer, null).reset(clear);

function close() {
  pause();
  clear();
}

sample({
  clock: pause,
  source: $player,
  target: pausePlayerFx,
});

export const GlobalMediaPlayerModel = { setPlayer, clear, pause, close, $player };
