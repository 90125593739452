import { USER_MODEL } from '@sp/data/auth';
import {
  loadSpaceById,
  loadSpaceMembers,
  MeUser,
  patchUserProfile,
  ProtectedChallengeSpace,
  SPACE_TYPE,
} from '@sp/data/bif';
import { USER_PROFILE_CARD_MEMBERS_MODEL } from '@sp/data/user-profile-card';
import { CHAT_CHANNEL } from '@sp/feature/chat-channel';
import { isNotNullish, Nullable } from '@sp/util/helpers';
import { differenceInMilliseconds } from 'date-fns';
import { combine, createDomain, createStore, restore, sample, split } from 'effector';
import { createGate } from 'effector-react';
import { ChallengeInfo } from './challenge.types';
import { isChallengePurchased } from './utils';

const nullChallenge = {
  slug: '',
  userSlug: '',
  id: 0,
  title: '',
  isPurchased: false,
  isTrial: null,
  isProgram: false,
  chatId: null,
  price: 0,
  priceConfig: {
    ios: 0,
    web: 0,
  },
  iosProductId: '',
  members: [],
  creator: null,
  startAt: 0,
  finishAt: 0,
  publishedAt: 0,
  cover: null,
  meta: {
    steps: [],
    intro: { posts: [] },
    finish: { posts: [] },
  },
} as ChallengeInfo;

const challengeDomain = createDomain();

const challengeVisited = challengeDomain.createEvent();
const refreshChallengeStarted = challengeDomain.createEvent();

const challengeReducer = (ch: ChallengeInfo, data: Partial<ChallengeInfo>) => ({ ...ch, ...data });

const ChallengeGate = createGate<number>({ domain: challengeDomain });

const loadChallengeFx = challengeDomain.createEffect((id: number) => {
  return loadSpaceById(id)
    .then(space => {
      if (space.type !== SPACE_TYPE.challenge) {
        return Promise.reject(new Error('Space is not challenge'));
      }
      return space as ProtectedChallengeSpace;
    })
    .then(
      challenge =>
        ({
          slug: challenge.slug || '',
          userSlug: challenge.userSlug || '',
          title: challenge.title,
          isTrial: challenge.isTrial,
          isProgram: challenge.config.challenge.isProgram,
          isPurchased: isChallengePurchased(challenge.payment_type, challenge.isSubscribed, challenge.isPurchased),
          price: challenge.price,
          priceConfig: {
            web: challenge.price,
            ios: challenge.marketingPriceConfig.iosPrice,
          },
          iosProductId: challenge.iosProductId,
          id: challenge.id,
          chatId: challenge.chatId,
          creator: challenge.creator,
          cover: challenge.cover,
          startAt: challenge.meta.steps[0]?.startAt ?? challenge.publishedAt,
          finishAt: challenge.config.challenge.finishTimestamp,
          publishedAt: challenge.publishedAt,
          meta: {
            steps: challenge.meta.steps || { posts: [] },
            intro: challenge.meta.intro || { posts: [] },
            finish: challenge.meta.finish || [],
          },
        } as Omit<ChallengeInfo, 'members' | 'membersCount'>),
    );
});

const loadChallengeMembersFx = challengeDomain.createEffect((id: number) =>
  loadSpaceMembers(id).then(({ membersCountNumber, members }) => ({
    membersCount: membersCountNumber,
    members: members.sort(m => (m.isTrial ? 1 : -1)),
  })),
);

const loadDataFx = challengeDomain.createEffect((id: number) => {
  return Promise.all([loadChallengeMembersFx(id), loadChallengeFx(id)]);
});

const setFirstChallengeVisitedFx = challengeDomain.createEffect(async () => {
  const patch = { isFirstChallengeVisited: true };
  return await patchUserProfile(patch);
});

const $isLoading = loadDataFx.pending;
const $loadingError = challengeDomain
  .createStore<Nullable<Error>>(null)
  .on(loadDataFx.failData, (_, error) => error)
  .reset(ChallengeGate.close);

const $challenge = challengeDomain
  .createStore<ChallengeInfo>(nullChallenge)
  .on(loadChallengeFx.doneData, challengeReducer)
  .on(loadChallengeMembersFx.doneData, challengeReducer)
  .reset(ChallengeGate.close);

const $challengeId = restore(ChallengeGate.open, null).reset(ChallengeGate.close);

const $isLoaded = createStore(false)
  .on(loadDataFx.doneData, () => true)
  .reset(ChallengeGate.close, $challengeId, loadDataFx.fail);

sample({
  source: $challengeId,
  target: loadDataFx,
  filter: isNotNullish,
});

const chatIdReset = challengeDomain.createEvent();
const $activeChatId = challengeDomain.createStore<Nullable<number>>(null).reset(chatIdReset);
sample({
  source: $challenge,
  target: $activeChatId,
  fn: c => {
    return c.chatId;
  },
});

function hasChallengeStarted(challenge: ChallengeInfo): boolean {
  return differenceInMilliseconds(challenge.meta.steps[0]?.startAt || 0, Date.now()) <= 0;
}

function hasAccessToChallengeChat(challenge: ChallengeInfo, user: MeUser | null): boolean {
  if (!user) return false;

  return (
    challenge.isPurchased ||
    challenge.creator?.id === user.id ||
    (challenge.isTrial === true && !hasChallengeStarted(challenge))
  );
}

sample({
  source: combine({
    challenge: $challenge,
    user: USER_MODEL.$user,
  }).map(({ challenge, user }) => (hasAccessToChallengeChat(challenge, user) ? challenge.chatId : null)),
  target: CHAT_CHANNEL.activeChatIdGet,
});

sample({
  clock: challengeVisited,
  source: USER_MODEL.$user,
  filter: user => !user?.isFirstChallengeVisited ?? false,
  target: setFirstChallengeVisitedFx,
});

sample({
  source: setFirstChallengeVisitedFx.doneData,
  target: USER_MODEL.patch,
});

sample({
  clock: sample({
    clock: refreshChallengeStarted,
    source: loadDataFx.pending,
    filter: isPending => !isPending,
  }),
  source: $challengeId,
  filter: isNotNullish,
  target: loadDataFx,
});

export const CHALLENGE_MODEL = {
  $challenge,
  $challengeId,
  $loadingError,
  $isLoading,
  $isLoaded,
  challengeVisited,
  ChallengeGate,
  $activeChatId,
  chatIdReset,
  refreshChallengeStarted,
};

// Init UserProfileCard
{
  const users = USER_PROFILE_CARD_MEMBERS_MODEL.users.createItemAdapter('challenge');
  const creators = USER_PROFILE_CARD_MEMBERS_MODEL.creators.createItemAdapter('challenge');

  split({
    source: $challenge,
    match: challenge => (challenge.id !== 0 ? 'set' : 'unset'),
    cases: {
      set: [
        creators.set.prepend((c: ChallengeInfo) => (c.creator ? [c.creator] : [])),
        users.set.prepend((c: ChallengeInfo) => c.members),
      ],
      unset: [users.unset, creators.unset],
    },
  });
}
