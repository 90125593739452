import { ChatChannelModel } from '@sp/feature/stream-chat';
import { REACTION_REGISTRY } from '@sp/ui/chat';
import { Button } from '@sp/ui/elements';
import { isNotNullish } from '@sp/util/helpers';
import { ChatFormatMessage } from '@sp/util/stream-chat';
import { useCallback, useEffect, useState } from 'react';

export const MessageModalReactions = ({
  activeMessage,
  chatModel,
  onToggle,
}: Readonly<{
  activeMessage: ChatFormatMessage | null;
  chatModel: ChatChannelModel;
  onToggle: VoidFunction;
}>) => {
  const [ownReactions, setOwnReactions] = useState(
    Object.entries(REACTION_REGISTRY).reduce<Record<string, number>>((acc, [type]) => {
      acc[type] = 0;
      return acc;
    }, {}),
  );

  useEffect(() => {
    setOwnReactions(
      Object.entries(REACTION_REGISTRY).reduce<Record<string, number>>((acc, [reaction]) => {
        const reactionIndex = activeMessage?.own_reactions?.findIndex(r => r.type === reaction);
        if (isNotNullish(reactionIndex) && reactionIndex >= 0) {
          acc[reaction] = 1;
        } else {
          acc[reaction] = 0;
        }
        return acc;
      }, {}),
    );
  }, [activeMessage?.own_reactions]);

  const onToggleReaction = useCallback(
    (type: string) => {
      if (!activeMessage) return;
      const ownReactionsCopy = { ...ownReactions };
      if (ownReactions[type] > 0) {
        chatModel.reactionDeleted({ messageId: activeMessage.id, type });

        ownReactionsCopy[type] = 0;
      } else {
        chatModel.reactionAdded({ messageId: activeMessage.id, type });
        ownReactionsCopy[type] = 1;
      }
      setOwnReactions(ownReactionsCopy);
      onToggle();
    },
    [activeMessage, chatModel, onToggle, ownReactions],
  );

  return (
    <div className="mobile-pan flex mt-6 pb-4 -mb-4 gap-3 overflow-x-auto">
      {Object.entries(REACTION_REGISTRY).map(([reaction, config]) => {
        const active = ownReactions[reaction] > 0;
        return (
          <Button
            key={reaction}
            rounded
            circle
            color={'gray'}
            onClick={() => {
              onToggleReaction(reaction);
            }}
            size="md"
            className={`!p-0 flex-none ${active ? '!bg-brand-medium' : '!bg-stripe'}`}
          >
            {config.body}
          </Button>
        );
      })}
    </div>
  );
};
