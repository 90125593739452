import { Button } from '@sp/ui/elements';
import { cls } from '@sp/util/helpers';
import { FC, MouseEventHandler } from 'react';

export const CardTitle: FC<{
  title: string;
  small?: boolean;
  oneLine?: boolean;
  secondary?: boolean;
  white?: boolean;
}> = ({ title, small = false, oneLine = false, secondary = false, white = '' }) => {
  return (
    <h4
      className={cls(
        'mb-3 mt-0',
        small ? 'text-base' : 'text-2xl leading-6',
        oneLine ? 'truncate' : 'inline whitespace-pre-wrap',
        secondary ? 'text-secondary' : '',
        white ? 'text-active' : '',
      )}
    >
      {title}
    </h4>
  );
};

export const CardButton: FC<{
  inHome: boolean;
  creator?: boolean;
  loading?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
}> = ({ inHome, creator, loading, onClick, disabled }) => {
  return (
    <Button
      loading={loading}
      disabled={disabled}
      onClick={onClick}
      className="w-fit !px-3 !py-2"
      size="sm"
      color="black"
    >
      {inHome ? 'Open' : creator ? 'Follow' : 'Join'}
    </Button>
  );
};
export const SmallCardButton: FC<{
  inHome: boolean;
  creator?: boolean;
  loading?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
}> = ({ inHome, creator = false, loading, onClick, disabled }) => {
  return (
    <Button
      loading={loading}
      onClick={onClick}
      disabled={disabled}
      className="w-full !px-3 !py-2 rounded-t-none rounded-b-3xl"
      outline={inHome}
      color="black"
    >
      {inHome ? 'Open' : creator ? 'Follow' : 'Join'}
    </Button>
  );
};
