import { createEvent } from 'effector';

export type ShowUserCardPayload = { readonly userId: string | number; avatar?: { url: string | null } };

const show = createEvent<ShowUserCardPayload>();

const changed = show.filterMap(payload => {
  const userId = typeof payload.userId === 'number' ? payload.userId : parseInt(payload.userId);
  if (isNaN(userId) || userId === 0) return undefined;
  return { ...payload, userId };
});

export const USER_PROFILE_CARD_SHOW_MODEL = {
  show,
  changed,
};
