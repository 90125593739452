import { Button } from '@sp/ui/elements';
import { IconTimer } from '@sp/ui/icons';
import { cls, isNotNullish } from '@sp/util/helpers';
import { FC } from 'react';
import { formatEstimateTime } from '../journey/format';

export const StepCompleteFormView: FC<{
  stepTitle: string;
  estimatedTime: number;
  isCanComplete: boolean;
  isCompleted: boolean;
  isStepCompleting: boolean;
  onCompleteStep: () => void;
  isActive?: boolean;
}> = ({ stepTitle, estimatedTime, isCanComplete, isCompleted, isStepCompleting, onCompleteStep, isActive = false }) => {
  return (
    <>
      <div className="text-xl font-semibold mb-4 flex items-center">{stepTitle}</div>

      <Button
        loading={isStepCompleting}
        disabled={!isCanComplete || isStepCompleting}
        onClick={onCompleteStep}
        color={isCanComplete && !isCompleted ? 'primary' : 'gray'}
        block
        //TODO: заменить на нормальный токен цвета текста
        className={cls('mb-2', isActive && isCompleted && 'bg-brand-substrate !text-[#79A894]')}
      >
        {isCompleted ? 'Completed' : 'Mark as complete'}
      </Button>

      {isNotNullish(estimatedTime) && estimatedTime !== 0 ? (
        //TODO: заменить на нормальный токен цвета текста
        <div
          className={cls(
            'text-xs flex justify-center items-center gap-2',
            isActive ? 'text-[#C5DFD5]' : 'text-secondary',
          )}
        >
          <span>Estimated time:</span>
          <span>
            <IconTimer size={13} className="inline" />
            {formatEstimateTime(estimatedTime)}
          </span>
        </div>
      ) : null}
    </>
  );
};
