import { Navigate, useLocation, useParams } from 'react-router-dom';
import { LiveScreen } from './live-screen';

const LiveTabKeys: Record<string, string> = {
  chat: 'chat',
  journey: 'live',
  mentions: 'mentions',
};

export const ValidateLiveScreen = () => {
  const { tabKey } = useParams();
  const { search } = useLocation();
  if (!tabKey || !LiveTabKeys[tabKey]) {
    return <Navigate replace to={`../live${search}`} />;
  }
  return <LiveScreen tabKey={tabKey} />;
};
