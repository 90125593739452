import { ChatFormatMessage } from '@sp/util/stream-chat';
import { startOfDay } from 'date-fns';
import groupBy from 'lodash/groupBy';
import { useMemo, useRef } from 'react';

export enum RawItemType {
  message = 'message',
  daySeparator = 'daySeparator',
  newMessagesSeparator = 'newMessagesSeparator',
}

export type RawItem =
  | { type: RawItemType.message; key: string; message: ChatFormatMessage }
  | { type: RawItemType.daySeparator; key: string; timestamp: number }
  | { type: RawItemType.newMessagesSeparator; key: string };

export const MESSAGE_ITEM_KEY_PREFIX = 'message';
export const DAY_SEPARATOR_ITEM_KEY_PREFIX = 'day-separator';
export const NEW_MESSAGES_ITEM_KEY_PREFIX = 'new-messages-separator';

function isMessageItem(item: RawItem): item is { type: RawItemType.message; key: string; message: ChatFormatMessage } {
  return item.type === RawItemType.message;
}

function getMessageRawItem(message: ChatFormatMessage): {
  type: RawItemType.message;
  key: string;
  message: ChatFormatMessage;
} {
  return {
    type: RawItemType.message,
    key: `${MESSAGE_ITEM_KEY_PREFIX}-${message.id}`,
    message,
  };
}

function expandRawItems([timestamp, messages]: [timestamp: string, messages: readonly ChatFormatMessage[]]): RawItem[] {
  return [
    {
      type: RawItemType.daySeparator,
      key: `${DAY_SEPARATOR_ITEM_KEY_PREFIX}-${timestamp}`,
      timestamp: parseInt(timestamp),
    },
    ...messages.map(getMessageRawItem),
  ];
}

export function insertDaySeparators(messages: readonly ChatFormatMessage[]): RawItem[][] {
  const groupedByDay = groupBy(messages, ({ created_at }) => startOfDay(created_at).getTime());
  return Object.entries(groupedByDay).map(expandRawItems);
}

export function useMessageListItems(messages: readonly ChatFormatMessage[], lastRead: Date): RawItem[][] {
  // Do not draw on new.message event
  const drawNewMessageSeparator = useRef(
    messages.length > 0 ? messages[messages.length - 1].created_at > lastRead : false,
  ).current;

  const rawItems: RawItem[][] = useMemo(() => insertDaySeparators(messages), [messages]);
  const rawItemsWithNewMessagesSeparator = useMemo(() => {
    if (!drawNewMessageSeparator) return rawItems;
    let firstUnreadIndex = -1;
    let firstUnreadGroup = -1;
    rawItems.forEach((group, groupI) => {
      if (firstUnreadIndex !== -1) return;
      firstUnreadIndex = group.findIndex(item => isMessageItem(item) && item.message.created_at > lastRead);
      if (firstUnreadIndex !== -1) firstUnreadGroup = groupI;
    });
    if (firstUnreadIndex === -1) {
      return rawItems;
    }
    const newItems = [...rawItems];
    newItems[firstUnreadGroup].splice(firstUnreadIndex, 0, {
      type: RawItemType.newMessagesSeparator,
      key: `${NEW_MESSAGES_ITEM_KEY_PREFIX}-${lastRead.getTime()}`,
    });
    return newItems;
  }, [drawNewMessageSeparator, rawItems, lastRead]);

  return useMemo(() => {
    return rawItemsWithNewMessagesSeparator;
  }, [rawItemsWithNewMessagesSeparator]);
}
