import { ReactElement } from 'react';
import { ICON_DEFAULTS, IconProps } from './types';

export function IconPencil({
  size = ICON_DEFAULTS.size,
  color = ICON_DEFAULTS.color,
  className = ICON_DEFAULTS.className,
}: IconProps): ReactElement {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.69063 20.248H4.5C4.30109 20.248 4.11033 20.1689 3.96967 20.0283C3.82902 19.8876 3.75 19.6969 3.75 19.498V15.3073C3.74966 15.21 3.76853 15.1135 3.80553 15.0234C3.84253 14.9333 3.89694 14.8514 3.96563 14.7823L15.2156 3.53234C15.2854 3.46148 15.3686 3.4052 15.4603 3.36679C15.5521 3.32838 15.6505 3.30859 15.75 3.30859C15.8495 3.30859 15.9479 3.32838 16.0397 3.36679C16.1314 3.4052 16.2146 3.46148 16.2844 3.53234L20.4656 7.71359C20.5365 7.78338 20.5928 7.86656 20.6312 7.9583C20.6696 8.05004 20.6894 8.14851 20.6894 8.24797C20.6894 8.34742 20.6696 8.44589 20.6312 8.53763C20.5928 8.62937 20.5365 8.71255 20.4656 8.78234L9.21563 20.0323C9.1466 20.101 9.06469 20.1554 8.9746 20.1924C8.88452 20.2294 8.78802 20.2483 8.69063 20.248V20.248Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M12.75 6L18 11.25" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M15.375 8.625L6.375 17.625" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8.95312 20.2031L3.79688 15.0469" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
