import { combine, createEvent, EventPayload, sample } from 'effector';
import { and } from 'patronum';
import { DETAILS_FORM_MODEL, DetailsFormState } from './inner-forms/details-form-model';
import { TESTIMONIALS_FORM_MODEL, TestimonialsFormState } from './inner-forms/testimonials-form-model';

export type LiveSpaceFormState = { details: DetailsFormState; testimonials: TestimonialsFormState };

const reset = createEvent();
const submit = createEvent();

sample({
  clock: submit,
  target: DETAILS_FORM_MODEL.form.validate,
});

sample({
  clock: reset,
  target: [DETAILS_FORM_MODEL.form.reset, TESTIMONIALS_FORM_MODEL.form.reset],
});

const set = createEvent<{
  details: Required<EventPayload<typeof DETAILS_FORM_MODEL['form']['set']>>;
  testimonials: EventPayload<typeof TESTIMONIALS_FORM_MODEL['form']['set']>;
}>();

export type SetLiveSpaceFormStatePayload = EventPayload<typeof set>;

sample({
  clock: set,
  target: [
    DETAILS_FORM_MODEL.form.setForm.prepend(({ details }: SetLiveSpaceFormStatePayload) => details),
    TESTIMONIALS_FORM_MODEL.form.set.prepend(({ testimonials }: SetLiveSpaceFormStatePayload) => testimonials),
  ],
});

const validFormSubmitted = sample({
  source: {
    details: combine({ state: DETAILS_FORM_MODEL.form.$values, isValid: DETAILS_FORM_MODEL.form.$isValid }),
    testimonials: combine({
      state: TESTIMONIALS_FORM_MODEL.form.$state,
      isValid: TESTIMONIALS_FORM_MODEL.form.$isValid,
    }),
  },
  clock: submit,
  filter: ({ details, testimonials }) => details.isValid && testimonials.isValid,
  fn: ({ details, testimonials }) => ({
    details: details.state,
    testimonials: testimonials.state,
  }),
});

export const LIVE_SPACE_FORM_MODEL = {
  set,
  $state: combine({ details: DETAILS_FORM_MODEL.form.$values, testimonials: TESTIMONIALS_FORM_MODEL.form.$state }),
  $isValid: and(DETAILS_FORM_MODEL.form.$isValid, TESTIMONIALS_FORM_MODEL.form.$isValid),
  // TODO[Dmitriy Teplov] implement testimonials dirty state.
  $isDirty: and(DETAILS_FORM_MODEL.form.$isDirty),
  submit,
  validFormSubmitted,
  reset,
};
