import {
  createSpace,
  getSpace,
  OwnedChannelSpace,
  patchSpace,
  SPACE_PAYMENT_TYPE,
  SPACE_STATUS,
  SPACE_TYPE,
  UpdateChannelSpaceDto,
  UploadedFile,
} from '@sp/data/bif';
import { createResourceWithParams, isNotNullish, isNullish } from '@sp/util/helpers';
import { attach, createEffect, createEvent, sample } from 'effector';
import { createGate } from 'effector-react';
import { or } from 'patronum';
import { condition } from 'patronum/condition';
import { InvalidSpaceError } from '../utils/errors';
import { CHANNEL_FORM_MODEL, ChannelFormState } from './editor-form-model';

function channelToFormState(ch: OwnedChannelSpace): ChannelFormState {
  return {
    title: ch.title,
    slug: ch.slug,
    price: ch.price,
    cover: ch.cover as UploadedFile,
    creatorDescription: ch.config.channel.creatorDescription,
    description: ch.config.channel.description,
    targetAudience: ch.config.channel.targetAudience,
    updateFrequency: ch.config.channel.updateFrequency,
    contentFormat: ch.config.channel.contentFormat,
  };
}

function formStateToChannel(state: ChannelFormState) {
  return {
    title: state.title,
    slug: state.slug,
    type: SPACE_TYPE.channel,
    coverFileId: state.cover.id,
    price: state.price,
    payment_type: SPACE_PAYMENT_TYPE.monthly_price,
    config: {
      channel: {
        creatorDescription: state.creatorDescription,
        description: state.description,
        contentFormat: state.contentFormat,
        targetAudience: state.targetAudience,
        updateFrequency: state.updateFrequency,
      },
    },
  } as const;
}

export const ChannelGate = createGate<number | null>();

export const CHANNEL_SPACE_RESOURCE_MODEL = createResourceWithParams<OwnedChannelSpace | null, [number | null]>({
  loadOnArgsChange: true,
  $args: ChannelGate.state.map(v => [v]),
  fetchResource: async spaceId => {
    if (spaceId) {
      const space = await getSpace({ spaceId });
      if (space.type !== SPACE_TYPE.channel) throw new InvalidSpaceError(space);
      return space;
    } else {
      return null;
    }
  },
});

sample({
  clock: CHANNEL_SPACE_RESOURCE_MODEL.$data.updates.filter({ fn: isNotNullish }),
  fn: space => channelToFormState(space),
  target: CHANNEL_FORM_MODEL.set,
});

sample({ clock: ChannelGate.close, target: CHANNEL_FORM_MODEL.reset });

const createChannelSpaceFx = createEffect((details: ChannelFormState) => {
  return createSpace({
    space: formStateToChannel(details),
  });
});

const updateChannelSpaceFx = createEffect(({ details, spaceId }: { details: ChannelFormState; spaceId: number }) => {
  return patchSpace({
    spaceId: spaceId,
    patch: formStateToChannel(details),
  });
});

condition({
  source: CHANNEL_FORM_MODEL.validFormSubmitted,
  if: ChannelGate.state.map(isNullish),
  then: createChannelSpaceFx,
  else: attach({
    effect: updateChannelSpaceFx,
    source: ChannelGate.state,
    mapParams: (details: ChannelFormState, id) => {
      const spaceId = id as number;
      return { details, spaceId };
    },
  }),
});

const publish = createEvent();

const publishFx = createEffect((spaceId: number) =>
  patchSpace({ spaceId, patch: { status: SPACE_STATUS.published } as UpdateChannelSpaceDto }),
);

sample({
  clock: publishFx.doneData,
  target: CHANNEL_SPACE_RESOURCE_MODEL.replace,
});

sample({ source: ChannelGate.state, target: publishFx, filter: isNotNullish, clock: publish });

const $isLoading = or(...[createChannelSpaceFx, updateChannelSpaceFx].map(fx => fx.pending));
export const CHANNEL_EDITOR_MODEL = {
  $isLoading,
  created: createChannelSpaceFx.doneData,
  publish,
  $isPublished: CHANNEL_SPACE_RESOURCE_MODEL.$data.map(space => space?.status === SPACE_STATUS.published ?? false),
  $isPublishing: publishFx.pending,
};
