import { isNullish } from '@sp/util/helpers';

export const RETINA_PIXEL_DENSITY = 2;

// В середине регулярки опционально указан регинон
const AWS_IMAGES_ORIGIN_REGEX = /^https:\/\/(selfplus-images\.s3(\..*)?\.amazonaws|images\.selfplus)\.com/;
const AWS_RESIZE_SERVICE_ORIGIN = 'https://images.selfplus.com';
export const AWS_IMAGE_SIZES: ReadonlyArray<number> = [32, 50, 200, 600, 800, 1920];

export function isAWSSrc(src: string): boolean {
  return AWS_IMAGES_ORIGIN_REGEX.test(src);
}

export function getAWSImageFullSrc(src: string): string {
  if (!isAWSSrc(src)) {
    return src;
  }
  const imagePath = src.replace(AWS_IMAGES_ORIGIN_REGEX, '');
  return `${AWS_RESIZE_SERVICE_ORIGIN}${imagePath}`;
}

export function getAWSImageCroppedSrc(src: string, width: number, height: number): string {
  if (!isAWSSrc(src)) {
    return src;
  }
  const imagePath = src.replace(AWS_IMAGES_ORIGIN_REGEX, '');
  return `${AWS_RESIZE_SERVICE_ORIGIN}/${width}x${height}${imagePath}`;
}

export function getAWSImageResizedSrc(src: string, size: number): string {
  if (!isAWSSrc(src)) {
    return src;
  }
  const imagePath = src.replace(AWS_IMAGES_ORIGIN_REGEX, '');
  return `${AWS_RESIZE_SERVICE_ORIGIN}/fit-in/${size}x${size}/filters:cover()${imagePath}`;
}

export function getResponsiveAWSImageSrc(src: string, width: number): string {
  const size = [...AWS_IMAGE_SIZES, Infinity].find(
    (max, index) => width > (AWS_IMAGE_SIZES[index - 1] ?? -1) && width <= max,
  );
  if (isNullish(size) || size === Infinity) {
    return getAWSImageFullSrc(src);
  }
  return getAWSImageResizedSrc(src, size);
}
