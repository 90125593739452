import { getMarkRange } from '@remirror/core';
import { useChainedCommands, useCurrentSelection, useEditorState, useUpdateReason } from '@remirror/react';
import { normalizeUrlProtocol } from '@sp/util/links';
import { createContext, FC, useCallback, useContext, useLayoutEffect, useMemo, useState } from 'react';
import { LinkAttributes } from 'remirror/extensions';
import { useActiveEditorLink } from './use-active-editor-link';

function useLinkEditorState() {
  const [isEditing, setIsEditing] = useState(false);
  const canEdit = !isEditing;

  const chain = useChainedCommands();
  const state = useEditorState();
  const selection = useCurrentSelection();
  const activeLink = useActiveEditorLink();

  const isLinkActive = useMemo(() => activeLink != null, [activeLink]);

  const activeLinkAttrs = useMemo(
    () => (activeLink?.mark.attrs ? (activeLink.mark.attrs as LinkAttributes) : undefined),
    [activeLink?.mark.attrs],
  );

  const [href, setHref] = useState('');
  const [title, setTitle] = useState('');
  const resultingHref = href.replace(/\s/g, '');

  const trimmedTitle = title.trim();
  const selectionText = state.doc.textBetween(selection.from, selection.to).trim();
  const resultingTitle =
    trimmedTitle.length > 0 ? trimmedTitle : selectionText.length > 0 ? selectionText : resultingHref;

  const isValid = resultingHref !== '';

  const initForm = useCallback(() => {
    setHref(activeLinkAttrs?.href ?? '');
    const linkText = isLinkActive ? getMarkRange(state.doc.resolve(selection.anchor), 'link')?.text ?? '' : '';
    setTitle(selectionText.length > 0 ? selectionText : linkText);
  }, [isLinkActive, activeLinkAttrs?.href, selection.anchor, selectionText, state.doc]);

  const resetForm = useCallback(() => {
    setHref('');
    setTitle('');
  }, []);

  const startEditing = useCallback(() => {
    if (canEdit && !isEditing) {
      initForm();
      setIsEditing(true);
    }
  }, [canEdit, initForm, isEditing]);

  const cancelEditing = useCallback(() => {
    resetForm();
    setIsEditing(false);
    chain.focus().run();
  }, [chain, resetForm]);

  const updateReason = useUpdateReason();

  useLayoutEffect(() => {
    if (!isEditing) {
      return;
    }

    if (updateReason.doc || updateReason.selection) {
      cancelEditing();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateReason.doc, updateReason.selection]);

  const upsertLink = useCallback(() => {
    if (!isValid) {
      return;
    }

    const nextLink: LinkAttributes = { href: normalizeUrlProtocol(resultingHref), auto: false, target: '_blank' };

    if (activeLinkAttrs) {
      chain.selectLink().replaceText({ content: resultingTitle }).selectLink().updateLink(nextLink).focus(selection.to);
    } else if (!selection.empty) {
      chain
        .selectText(selection)
        .replaceText({ content: resultingTitle })
        .updateLink(nextLink)
        .selectLink()
        .focus(selection.to);
    } else {
      chain
        .insertText(resultingTitle, {
          marks: {
            link: nextLink,
          },
        })
        .focus(selection.anchor + resultingTitle.length)
        .insertText(' ')
        .focus(selection.anchor + resultingTitle.length + 1);
      // .selectLink();
      // .selectText({ from: selection.from, to: selection.from + resultingTitle.length });
    }

    chain.run();

    setIsEditing(false);
  }, [isValid, resultingHref, activeLinkAttrs, selection, chain, resultingTitle]);

  const removeLink = useCallback(() => {
    if (!isLinkActive) {
      return;
    }

    chain.selectLink().removeLink().focus().run();
    setIsEditing(false);
  }, [chain, isLinkActive]);

  return useMemo(
    () => ({
      canEdit,
      isValid,
      isLinkActive,
      href,
      setHref,
      title,
      setTitle,
      isEditing,
      startEditing,
      removeLink,
      upsertLink,
      cancelEditing,
    }),
    [canEdit, isValid, isLinkActive, href, title, isEditing, startEditing, removeLink, upsertLink, cancelEditing],
  );
}

const LinkEditorContext = createContext<ReturnType<typeof useLinkEditorState> | null>(null);

export const LinkEditorProvider: FC = ({ children }) => (
  <LinkEditorContext.Provider value={useLinkEditorState()}>{children}</LinkEditorContext.Provider>
);

export function useLinkEditor() {
  const context = useContext(LinkEditorContext);

  if (context == null) {
    throw new Error('useLinkEditor must be used within a LinkEditorProvider');
  }

  return context;
}
