import pickBy from 'lodash/pickBy';
import { z } from 'zod';
import { ENV_MODE, EnvMode } from './env';
import { isStorageAvailable } from './is-storage-available';

const FEATURE_FLAGS_STORAGE_KEY = 'spFlags';

const FeatureFlagEnum = z.enum([
  'bypassNativeAppGuard',
  'enableStreamChatInfoLogLevel',
  'enableScheduledActualDateDebugger',
]);
export const FeatureFlag = FeatureFlagEnum.enum;
type FeatureFlag = z.infer<typeof FeatureFlagEnum>;
const FeatureFlags = z.record(FeatureFlagEnum, z.boolean());
type FeatureFlags = Required<z.infer<typeof FeatureFlags>>;

const FEATURE_FLAGS: FeatureFlags = FeatureFlagEnum.options.reduce(
  (acc, key) => ({ ...acc, [key]: false }),
  {} as FeatureFlags,
);

function write() {
  try {
    if (Object.values(FEATURE_FLAGS).some(Boolean)) {
      window.localStorage.setItem(FEATURE_FLAGS_STORAGE_KEY, JSON.stringify(pickBy(FEATURE_FLAGS, Boolean)));
    } else {
      window.localStorage.removeItem(FEATURE_FLAGS_STORAGE_KEY);
    }
  } catch (e) {
    console.error(`Failed to write local storage feature flags.`);
    console.error(e);
  }
}

(function read() {
  try {
    if (!isStorageAvailable('localStorage')) return;
    const serialized = localStorage.getItem(FEATURE_FLAGS_STORAGE_KEY);
    if (serialized == null) return;
    const config = z.record(z.any()).parse(JSON.parse(serialized));
    const ConfigTuple = z.tuple([FeatureFlagEnum, z.boolean()]);
    for (const entry of Object.entries(config)) {
      const result = ConfigTuple.safeParse(entry);
      if (result.success) {
        const [key, value] = result.data;
        FEATURE_FLAGS[key] = value;
      }
    }
  } catch (e) {
    console.warn(e);
  } finally {
    write();
  }
})();

export function checkFeatureFlag(flag: FeatureFlag) {
  return FEATURE_FLAGS[flag] ?? false;
}

// Developer helpers
declare global {
  interface Window {
    setFeatureFlag: (name: string, isEnabled: boolean) => void;
  }
}

window.setFeatureFlag = (name: unknown, value: unknown) => {
  try {
    FEATURE_FLAGS[FeatureFlagEnum.parse(name)] = z.boolean().parse(value);
    write();
  } catch (e) {
    if (e instanceof z.ZodError) return console.log(e.issues[0].message);
    throw e;
  }
};

if ([EnvMode.development, EnvMode.staging].includes(ENV_MODE)) {
  console.groupCollapsed('Feature Flags:');
  console.log('Use window.setFeatureFlag(name, isEnabled) to toggle flags.');
  console.table(FEATURE_FLAGS);
  console.groupEnd();
}
