import { AxiosInterceptorBatchManager, RequestFulfilledInterceptor, ResponseRejectedInterceptor } from '@sp/util/axios';
import { isApiUrl } from '@sp/util/router';
import axios from 'axios';

const authInterceptorsManager = new AxiosInterceptorBatchManager(axios.interceptors);

export function ejectInterceptors() {
  authInterceptorsManager.clear();
}

export function injectInterceptors(token: string, logout: VoidFunction) {
  const addAuthToken: RequestFulfilledInterceptor = config => {
    if (!isApiUrl(config.url)) {
      return config;
    }

    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${token}`,
    };

    return config;
  };

  const invalidAuthToken: ResponseRejectedInterceptor = error => {
    if (!isApiUrl(error.config.url)) {
      throw error;
    }

    if (error.response?.status === 401) {
      console.error('Invalid auth token.');
      logout();
    }

    throw error;
  };

  authInterceptorsManager.set({
    requestFulfilled: [addAuthToken],
    responseRejected: [invalidAuthToken],
  });
}
