import { BackButton } from '@sp/feature/back-button';
import { Avatar } from '@sp/ui/elements';
import { IconExport } from '@sp/ui/icons';
import { cls, noop } from '@sp/util/helpers';
import { shareUrl } from '@sp/util/native';
import { FC, useCallback } from 'react';

export const Header: FC<{
  title: string;
  name?: string;
  members?: number;
  avatar?: string;
  creatorId?: number;
  urlToShare?: string;
  onClick?: () => void;
  className?: string;
}> = ({ title, members, avatar, urlToShare, onClick = noop, className = '', name }) => {
  const handleShareClick = useCallback(() => {
    if (urlToShare) {
      shareUrl({ url: urlToShare, text: title, title });
    }
  }, [urlToShare, title]);

  return (
    <div className={cls('flex justify-start items-start gap-2 pl-2 pr-6 pt-4 pb-1 w-full', className)}>
      <BackButton />
      <span role="button" onClick={onClick} className="-ml-2">
        <Avatar size="sm" src={avatar} />
      </span>
      <div role="button" onClick={onClick} className="flex flex-col flex-1 overflow-x-hidden">
        <h1 className="m-0 text-ellipsis whitespace-nowrap overflow-x-hidden text-base">{title}</h1>

        <span className="text-secondary text-xs">
          {name ? (
            <>
              <span className="font-semibold">{name}</span>
              <span> · </span>
            </>
          ) : null}
          <span>{members} participants</span>
        </span>
      </div>
      <button type="button" title="Back" onClick={handleShareClick} className="text-secondary p-2">
        <IconExport size={24} />
      </button>
    </div>
  );
};
