import { isNotNullish } from '@sp/util/helpers';
import { Logger } from 'stream-chat';

export const consoleLogger: Logger = (logLevel, message, extraData) => {
  if (extraData) {
    const { error, ...restExtraData } = extraData;
    let serializedExtraData: unknown;

    if (Object.keys(restExtraData).length > 0) {
      try {
        serializedExtraData = JSON.stringify(restExtraData);
      } catch (e) {
        serializedExtraData = restExtraData;
      }
    }

    console[logLevel]('stream-chat:', message, serializedExtraData);
    // Log errors separately for proper capturing by CaptureConsole Sentry integration.
    if (isNotNullish(error)) console[logLevel](error);
  } else {
    console[logLevel]('stream-chat:', message);
  }
};
