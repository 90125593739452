import { cls } from '@sp/util/helpers';
import { VFC } from 'react';

export const PremiumSwitch: VFC<{
  value: boolean;
  onChange: (v: boolean) => void;
}> = ({ value, onChange }) => {
  return (
    <label className="inline-flex relative items-center gap-2 cursor-pointer font-medium">
      <input
        type="checkbox"
        checked={value}
        onChange={({ target }) => {
          onChange?.(Boolean(target.checked));
        }}
        value=""
        className="sr-only peer m-0"
      />
      <div
        className={cls(
          'w-12 h-6',
          'bg-secondary rounded-full',
          'peer dark:bg-stroke peer-checked:bg-brand-substrate',
          'peer-checked:after:translate-x-6 peer-checked:after:border-accent peer-checked:after:bg-accent peer-checked:after:bg-[url("/images/dollar-active.svg")]',
          `after:content-[""] after:absolute`,
          'after:top-[2px] after:left-[2px] after:h-5 after:w-5',
          'after:bg-[#D0D0D0] after:bg-[url("/images/dollar.svg")] after:bg-center',
          'after:border-gray-300 after:border after:rounded-full after:transition-all',
        )}
      />
      <span className="align-middle">{value ? 'premium followers only' : 'for all followers'}</span>
    </label>
  );
};
