import { ENV_MODE, EnvMode } from '@sp/data/env';
import { ROUTES } from '@sp/util/router';
import { AuthApiFactory } from '../../api/default';
import { MeUser } from '../../user.types';
import { apiResponseAdHoc } from '../helpers';

const defaultAuthApi = AuthApiFactory();

export async function getMe({ force } = { force: false }): Promise<MeUser> {
  return defaultAuthApi.authMeGet(force).then(
    r =>
      apiResponseAdHoc({
        ...r.data,
        isAdmin: r.data.isAdmin ?? false,
      }) as MeUser,
  );
}

export async function sendMagicLink({ email }: { email: string }): Promise<{ authToken?: string }> {
  const allowToPass =
    ENV_MODE === EnvMode.development
      ? { local_dev_token: 'eyJrdHkiOiJvY3QiLCJrIjoidUxVXzlCeUp6d2t0OW1SOUxlT2xQUSJ9' }
      : {};

  return defaultAuthApi
    .authMagicPost({
      ...allowToPass,
      email,
      redirect_uri: `${window.location.origin}${ROUTES.CONTINUE_MAGIC_LINK_PATH}`,
    })
    .then(r => r.data as { authToken?: string });
}

export async function loginWithMagicLink({ code }: { code: string }): Promise<{ authToken: string }> {
  return defaultAuthApi.authMagicKeyPost(code).then(r => apiResponseAdHoc(r.data as { authToken: string }));
}

export async function loginWithPassword({
  email,
  password,
}: {
  email: string;
  password: string;
}): Promise<{ authToken: string }> {
  return defaultAuthApi.authLoginPost({ email, password }).then(r => apiResponseAdHoc(r.data as { authToken: string }));
}
