import { AuthProvider, UserProvider } from '@sp/data/auth';
import { InitGlobalModels } from '@sp/data/global';
import {
  ConfirmCardModal,
  InAppPurchaseFailedModal,
  ManageProfileModal,
  PaymentFailedModal,
} from '@sp/feature/buy-button';
import { ValidateChallengeScreen } from '@sp/feature/challenge';
import { ValidateCommunityScreen } from '@sp/feature/community';
import { ChannelScreen } from '@sp/feature/creator-channel';
import { InfoModal } from '@sp/feature/info-modal';
import { EmbeddedLiveChat, ValidateLiveScreen } from '@sp/feature/live-channel';
import { ContinueFacebookOauth, ContinueGoogleOauth, ContinueMagicLink, LoginPage } from '@sp/feature/login';
import { PlatformMessageScreen } from '@sp/feature/platform-channel';
import { CompleteRegistrationScreen, ProfileScreen } from '@sp/feature/profile';
import { StudioScreen } from '@sp/feature/studio';
import { SpinnerPage } from '@sp/ui/elements';
import { MainLayout } from '@sp/ui/layout';
import { browserHistory, NavigateWithSearch, ROUTES } from '@sp/util/router';
import { BrowserHistory } from 'history';
import { FC, lazy, Suspense, useLayoutEffect, useState } from 'react';
import { Navigate, Route, Router, Routes } from 'react-router-dom';
import { AuthenticatedRoute, NonAuthenticatedRoute } from './auth-router-helpers';
import { useScrollToTopOnNavigate } from './scroll-to-top-on-navigate';

// Gitlab Pages only allows deployment to a subdir.
// react-router-dom doesn't handle base tag automatically,
// so we parse & set basename manually.
// Can be removed if subdir deployment isn't needed.
const baseURL = import.meta.env['BASE_URL'];
const basename = baseURL && new URL(baseURL, window.location.origin).pathname;

const ScrollToTopOnNavigate: FC = ({ children }) => {
  useScrollToTopOnNavigate();
  return <>{children}</>;
};

const HomePage = lazy(() => import('@sp/feature/home').then(m => ({ default: m.HomePage })));
const DiscoverScreen = lazy(() => import('@sp/feature/discover').then(m => ({ default: m.DiscoverScreen })));
const DirectMessageScreen = lazy(() =>
  import('@sp/feature/direct-message-screen').then(m => ({ default: m.DirectMessageScreen })),
);
const TrailerScreen = lazy(() => import('@sp/feature/trailer-channel').then(m => ({ default: m.TrailerScreen })));

const CustomRouter: FC<{ basename: string; history: BrowserHistory }> = ({ basename, children, history }) => {
  const [state, setState] = useState({
    action: history.action,
    location: history.location,
  });

  useLayoutEffect(() => history.listen(setState), [history]);

  return (
    <Router basename={basename} location={state.location} navigationType={state.action} navigator={history}>
      {children}
    </Router>
  );
};

export function AppRouter() {
  return (
    <CustomRouter basename={basename} history={browserHistory}>
      <ScrollToTopOnNavigate>
        <Suspense fallback={<SpinnerPage />}>
          <AuthProvider>
            <InitGlobalModels />
            <InfoModal />
            <PaymentFailedModal />
            <ManageProfileModal />
            <InAppPurchaseFailedModal />
            <ConfirmCardModal />
            <Routes>
              <Route
                element={
                  <AuthenticatedRoute>
                    <UserProvider>
                      <CompleteRegistrationScreen>
                        <MainLayout />
                      </CompleteRegistrationScreen>
                    </UserProvider>
                  </AuthenticatedRoute>
                }
              >
                <Route path={`${ROUTES.CHALLENGE_PAGE_URL}/:${ROUTES.SPACE_ID_PARAM}/*`}>
                  <Route path=":tabKey" element={<ValidateChallengeScreen />} />
                  <Route path="*" element={<NavigateWithSearch to="chat" replace={true} />} />
                </Route>

                <Route path={`${ROUTES.LIVE_PAGE_URL}/:${ROUTES.SPACE_ID_PARAM}/*`}>
                  <Route path={ROUTES.LIVE.EMBEDDED_CHAT_URL} element={<EmbeddedLiveChat />} />
                  <Route path=":tabKey" element={<ValidateLiveScreen />} />
                  <Route path="*" element={<NavigateWithSearch to="chat" replace={true} />} />
                </Route>

                <Route path={ROUTES.HOME_URL} element={<HomePage />} />

                <Route path={`${ROUTES.DM}/${ROUTES.DM_PLATFORM}`} element={<PlatformMessageScreen />} />

                <Route path={`${ROUTES.DM}/:${ROUTES.DM_USER_ID_PARAM}`} element={<DirectMessageScreen />} />

                <Route path={`${ROUTES.TRAILER_PAGE_URL}/:${ROUTES.SPACE_ID_PARAM}`} element={<TrailerScreen />} />

                <Route path={`${ROUTES.CHANNEL_PAGE_URL}/:${ROUTES.SPACE_ID_PARAM}`} element={<ChannelScreen />} />

                <Route path={`${ROUTES.COMMUNITY_PAGE_URL}/:${ROUTES.SPACE_ID_PARAM}/*`}>
                  <Route path=":tabKey" element={<ValidateCommunityScreen />} />
                  <Route path="*" element={<NavigateWithSearch to="chat" replace={true} />} />
                </Route>

                <Route path={`${ROUTES.DISCOVER_URL}/*`} element={<DiscoverScreen />} />

                <Route path={`${ROUTES.PROFILE_URL}/*`} element={<ProfileScreen />} />

                <Route path={`${ROUTES.STUDIO_URL}/*`} element={<StudioScreen />} />
              </Route>

              <Route
                path={ROUTES.LOGIN_PATH}
                element={
                  <NonAuthenticatedRoute>
                    <LoginPage />
                  </NonAuthenticatedRoute>
                }
              />

              <Route
                path={ROUTES.CONTINUE_GOOGLE_OAUTH_PATH}
                element={
                  <NonAuthenticatedRoute>
                    <ContinueGoogleOauth />
                  </NonAuthenticatedRoute>
                }
              />

              <Route
                path={ROUTES.CONTINUE_FACEBOOK_OAUTH_PATH}
                element={
                  <NonAuthenticatedRoute>
                    <ContinueFacebookOauth />
                  </NonAuthenticatedRoute>
                }
              />

              <Route
                path={ROUTES.CONTINUE_MAGIC_LINK_PATH}
                element={
                  <NonAuthenticatedRoute>
                    <ContinueMagicLink />
                  </NonAuthenticatedRoute>
                }
              />
              <Route path="/" element={<Navigate to={ROUTES.HOME_URL} replace={true} />} />
              <Route path="*" element={<Navigate to="/" replace={true} />} />
            </Routes>
          </AuthProvider>
        </Suspense>
      </ScrollToTopOnNavigate>
    </CustomRouter>
  );
}
