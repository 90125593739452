import { XANO_DATASOURCE } from '@sp/data/env';
import { AxiosInterceptorBatchManager } from '@sp/util/axios';
import { isApiUrl } from '@sp/util/router';
import axios from 'axios';

const manager = new AxiosInterceptorBatchManager(axios.interceptors);

export function initXanoDatasourceInterceptor() {
  if (XANO_DATASOURCE === 'test') {
    manager.set({
      requestFulfilled: [
        config => {
          if (!isApiUrl(config.url)) {
            return config;
          }

          config.headers = {
            ...config.headers,
            'X-Data-Source': XANO_DATASOURCE,
          };

          return config;
        },
      ],
    });
  }
}
