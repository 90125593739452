import { UploadedFile } from '@sp/data/bif';
import { FC } from 'react';

export const LiveDescriptionTestimonials: FC<{
  heading: string;
  testimonials: Array<{
    authorName: string;
    avatar?: UploadedFile | null;
    title?: string;
    review: string;
  }>;
}> = ({ heading, testimonials }) => {
  return (
    <div>
      <h4 className="font-semibold">{heading}</h4>
      {testimonials.map((testimonial, index) => (
        <div key={index} className="flex flex-row items-start gap-2 mb-4">
          <div className="flex-none w-10 aspect-square aspect-ratio-wrap">
            <img
              src={testimonial.avatar?.url ?? '/images/avatar-placeholder.png'}
              alt={testimonial.authorName}
              className="rounded-full object-cover overflow-hidden"
            />
          </div>
          <div className="flex flex-col gap-2">
            <div className="m-0 text-lg flex flex-row items-center">{testimonial.authorName}</div>
            <div className="p-2 rounded-lg rounded-tl-none bg-[#EFF2F7] w-fit overflow-hidden break-words whitespace-pre-line">
              {testimonial.title && <h5 className="m-0">{testimonial.title}</h5>}
              {testimonial.review}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
