import { ArrayValues } from '@sp/util/helpers';
import { BoldExtension, ItalicExtension, LinkExtension, MentionAtomExtension } from 'remirror/extensions';

export const MENTION_ATOM_ID_DATASET_KEY = 'mentionAtomId';

function kebabToCamelCase(str: string) {
  return str.replace(/-([a-z])/g, (_, g) => g.toUpperCase());
}

function dataAttributeToDatasetKey(attrKey: string) {
  return kebabToCamelCase(attrKey.replace(/^data-/, ''));
}

export const MENTION_TYPE_DATA_ATTR_KEY = 'data-mention-type';
export const MENTION_TYPE_DATASET_KEY = dataAttributeToDatasetKey(MENTION_TYPE_DATA_ATTR_KEY);

export const enum MentionType {
  User = 'user',
}

export const extensions = () => [
  new LinkExtension({
    autoLink: true,
    defaultProtocol: 'http:',
    defaultTarget: '_blank',
  }),

  new MentionAtomExtension({
    extraAttributes: {
      [MENTION_TYPE_DATA_ATTR_KEY]: MentionType.User,
      role: 'button',
    },
    matchers: [{ name: 'at', char: '@', matchOffset: 0 }],
    appendText: ' ',
  }),

  new BoldExtension(),
  new ItalicExtension(),
];

export type SelfPlusRemirrorExtensions = ArrayValues<ReturnType<typeof extensions>>;
