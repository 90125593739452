import { isNullish } from '@sp/util/helpers';
import { ROUTES } from '@sp/util/router';
import { FC } from 'react';
import { Navigate, Route, Routes, useParams } from 'react-router-dom';
import { CreateChallengePage } from './challenge-editor/create-challenge-page';
import { EditChallengePage } from './challenge-editor/edit-challenge-page';
import { CreateChannelScreen } from './channel-editor/channel-editor';
import { CreateLiveScreen, UpdateLiveScreen } from './live';
import { PlatformEditorCreateMessage } from './platform-messages-editor/platform-editor-create-message';
import { PlatformMessagesEditor } from './platform-messages-editor/platform-messages-editor';

const ValidateChallengeId: FC = () => {
  const { challengeId } = useParams();

  if (isNullish(challengeId) || isNaN(Number(challengeId)) || Number(challengeId) < 1) {
    return <>invalid challenge id</>;
  }

  return <EditChallengePage challengeId={Number(challengeId)} />;
};

export const StudioScreen: FC = () => {
  return (
    <Routes>
      <Route path="/" element={<>work in progress</>} />

      {/*<Route path={`${ROUTES.STUDIO.EXPLORE_URL}/:tabKey/:period`} element={<ValidateExploreTabs />} />*/}

      <Route path={ROUTES.STUDIO.EDIT_CHALLENGE_URL} element={<CreateChallengePage />} />
      <Route path={`${ROUTES.STUDIO.EDIT_CHALLENGE_URL}/:challengeId`} element={<ValidateChallengeId />} />
      <Route path={`${ROUTES.STUDIO.PLATFORM_MESSAGE_EDITOR}/*`}>
        <Route path={`edit`} element={<PlatformEditorCreateMessage />} />
        <Route path={`*`} element={<PlatformMessagesEditor />} />
      </Route>
      <Route path={ROUTES.STUDIO.CREATOR_CHANNEL_EDITOR} element={<CreateChannelScreen />} />
      <Route
        path={`${ROUTES.STUDIO.CREATOR_CHANNEL_EDITOR}/:${ROUTES.STUDIO.SPACE_ID_PARAM}`}
        element={<CreateChannelScreen />}
      />
      <Route path={ROUTES.STUDIO.CREATE_LIVE} element={<CreateLiveScreen />} />
      <Route path={`${ROUTES.STUDIO.CREATE_LIVE}/:${ROUTES.STUDIO.SPACE_ID_PARAM}`} element={<UpdateLiveScreen />} />
      <Route path="*" element={<Navigate to={'/'} replace />} />
    </Routes>
  );
};
