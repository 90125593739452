import { AlreadyPurchasedError, initSpacePurchase, SpacePurchaseType } from '@sp/data/bif';
import { Button } from '@sp/ui/elements';
import { useCheckMounted } from '@sp/util/helpers';
import { ReactChild, ReactFragment, useCallback, useState, VFC } from 'react';
import { BuyButtonResultFn } from './buy-button';

export const RenewWebSubscriptionButton: VFC<
  Readonly<{
    spaceId: number;
    onResult: BuyButtonResultFn;
    children: ReactChild | ReactFragment;
  }>
> = ({ spaceId, onResult, children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const checkMounted = useCheckMounted();

  const handleClick = useCallback(async () => {
    setIsLoading(true);

    try {
      // TODO: track subscription renew.
      // TODO: handle non-null result etc.
      const result = await initSpacePurchase(spaceId, SpacePurchaseType.subscription);
      onResult({ success: true });
    } catch (e) {
      if (e instanceof AlreadyPurchasedError) {
        onResult({ success: true });
      } else {
        // TODO: move error logging outside?
        console.error(e);
        onResult({ success: false });
      }
    } finally {
      checkMounted() && setIsLoading(false);
    }
  }, [checkMounted, onResult, spaceId]);

  return (
    <Button loading={isLoading} disableOnLoad={true} onClick={handleClick} color="black">
      {children}
    </Button>
  );
};
