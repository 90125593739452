import { USER_MODEL } from '@sp/data/auth';
import {
  CommunityLive,
  CommunitySpaceConfig,
  loadSpaceById,
  loadSpaceMembers,
  SPACE_TYPE,
  SpaceCreator,
  SpaceMember,
  SubscriptionInfo,
  UploadedFile,
} from '@sp/data/bif';
import {
  mapStreamChatMembersToProfileCardUsers,
  USER_PROFILE_CARD_MEMBERS_MODEL,
  USER_PROFILE_CARD_SHOW_MODEL,
} from '@sp/data/user-profile-card';
import { CHAT_CHANNEL } from '@sp/feature/chat-channel';
import { PriceConfig } from '@sp/util/format';
import { isNotNullish, Nullable, snd, switchMap } from '@sp/util/helpers';
import { KnownSkillColor } from '@sp/util/skills';
import { combine, createDomain, createEvent, restore, sample } from 'effector';

export type CommunityInfo = {
  id: number;
  creator: Nullable<SpaceCreator>;
  price: number;
  priceConfig: PriceConfig;
  config: CommunitySpaceConfig;
  description: string | null;
  iosProductId: string;
  isPurchased: boolean;
  publishedAt: number | null;
  slug: string;
  userSlug: string;
  title: string;
  membersCount?: number;
  members: SpaceMember[];
  chatId: Nullable<number>;
  tags: ReadonlyArray<{
    id: number;
    name: string;
    weight: number;
    color: KnownSkillColor;
  }>;
  cover: Nullable<UploadedFile>;
  subscription: SubscriptionInfo | null;
  lives: CommunityLive[];
};

const nullCommunityInfo: CommunityInfo = {
  id: 0,
  creator: null,
  price: 0,
  priceConfig: {
    ios: 0,
    web: 0,
  },
  config: {},
  description: null,
  iosProductId: '',
  isPurchased: false,
  publishedAt: null,
  slug: '',
  userSlug: '',
  title: '',
  membersCount: 0,
  members: [],
  chatId: null,
  tags: [],
  cover: null,
  subscription: null,
  lives: [],
};

const domain = createDomain('community-model');

const init = domain.createEvent<number>();
const destroy = domain.createEvent();

domain.onCreateStore(store => store.reset(destroy));

const $spaceId = restore(init, null);

const loadCommunityFx = domain.createEffect((id: number) => loadSpaceById(id));

const loadChannelMembersFx = domain.createEffect((id: number) =>
  loadSpaceMembers(id).then(({ membersCountNumber, members }) => ({
    membersCount: membersCountNumber,
    members: members.sort(m => (m.isTrial ? 1 : -1)),
  })),
);

const loadDataFx = domain.createEffect(async (id: number) => {
  const [{ members, membersCount }, community] = await Promise.all([loadChannelMembersFx(id), loadCommunityFx(id)]);
  if (community.type !== SPACE_TYPE.community) {
    throw new Error('Invalid space type');
  }
  const channelInfo: CommunityInfo = {
    id: community.id,
    creator: community.creator,
    price: community.price,
    description: community.description,
    priceConfig: { web: community.price, ios: community.marketingPriceConfig.iosPrice },
    config: community.config,
    iosProductId: community.iosProductId,
    isPurchased: community.isPurchased,
    publishedAt: community.publishedAt,
    slug: community.slug,
    userSlug: community.userSlug,
    title: community.title,
    membersCount: membersCount,
    members: members,
    chatId: community.chatId,
    cover: community.cover ?? null,
    subscription: community.subscription,
    tags: community.tags ?? [],
    lives: community.lives,
  };
  return channelInfo;
});

const refetchData = createEvent();

sample({
  source: $spaceId,
  clock: [$spaceId, refetchData],
  filter: isNotNullish,
  target: loadDataFx,
});

const $community = domain.createStore<CommunityInfo>(nullCommunityInfo).on(loadDataFx.doneData, snd).reset(destroy);

const $ownerId = $community.map(c => c?.creator?.id ?? null);
const $isOwner = sample({
  source: combine({ user: USER_MODEL.$user, ownerId: $ownerId }),
  fn: ({ user, ownerId }) => ownerId === user?.id,
});

const $isLoading = loadDataFx.pending;

sample({
  clock: [destroy, init],
  target: CHAT_CHANNEL.instanceDestroyed,
});

sample({
  source: $community,
  fn: c => c?.chatId ?? null,
  target: CHAT_CHANNEL.activeChatIdGet,
});

export const COMMUNITY_MODEL = {
  init,
  destroy,
  refetchData,
  $chatModel: CHAT_CHANNEL.$instance,
  $community,
  $isOwner,
  $ownerId,
  $isLoading,
};

// Init UserProfileCard
{
  const users = USER_PROFILE_CARD_MEMBERS_MODEL.users.createItemAdapter('community');

  switchMap({
    source: CHAT_CHANNEL.$instance,
    fn: i => i?.$members.map(mapStreamChatMembersToProfileCardUsers),
    target: users.set,
  });

  sample({
    clock: destroy,
    target: users.unset,
  });

  switchMap({
    source: CHAT_CHANNEL.$instance,
    fn: i => i?.showUserCard,
    target: USER_PROFILE_CARD_SHOW_MODEL.show,
  });
}
