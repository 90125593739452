import { useCloseModalOnBack } from '@sp/feature/back-button';
import { isNotNullish } from '@sp/util/helpers';
import { motion, Transition, useAnimation, useMotionValue, Variants } from 'framer-motion';
import { FC, useCallback, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

const rootNode = document.getElementById('root');
const OPEN = 'open';
const CLOSE = 'close';

const modalVariants: Variants = {
  [OPEN]: {
    x: 0,
  },
  [CLOSE]: {
    x: '100%',
  },
};

const transition: Transition = {
  duration: 0.3,
  ease: 'easeOut',
};

const ModalContent: FC<{ isOpen: boolean; onClose: () => void }> = ({ isOpen, onClose, children }) => {
  const modalAnimation = useAnimation();
  const x = useMotionValue(0);

  const open = useCallback(() => {
    modalAnimation.start(OPEN);
  }, [modalAnimation]);

  const close = useCallback(() => {
    modalAnimation.start(CLOSE).then(() => {
      onClose();
    });
  }, [modalAnimation, onClose]);

  useEffect(() => {
    isOpen ? open() : close();
  }, [isOpen, open, close]);
  return (
    <div className="fullscreen-modal absolute w-full h-full bottom-0 z-[60]">
      <motion.div
        animate={modalAnimation}
        initial={CLOSE}
        variants={modalVariants}
        transition={transition}
        style={{ x }}
        className="absolute w-full h-full left-0 bottom-0 bg-white mobile-pan"
      >
        <div className="w-full h-full">{children}</div>
      </motion.div>
    </div>
  );
};

export const FullscreenModal: FC<{ isOpen: boolean; onClose?: () => void }> = ({ isOpen, onClose, ...props }) => {
  const [isShowPortal, setIsShowPortal] = useState(false);

  useCloseModalOnBack({ isOpen, onCloseCallback: () => onClose?.() });

  useEffect(() => {
    isOpen && setIsShowPortal(isOpen);
  }, [isOpen]);

  function handleClose() {
    setIsShowPortal(false);
    onClose?.();
  }

  return isShowPortal && isNotNullish(rootNode)
    ? createPortal(<ModalContent {...props} isOpen={isOpen} onClose={handleClose} />, rootNode)
    : null;
};
