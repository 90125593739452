import { useUser } from '@sp/data/auth';
import { MeUser, SpaceCreator } from '@sp/data/bif';
import { SELFPLUS_WEBSITE_URL } from '@sp/data/env';
import { GlobalMediaPlayerModel } from '@sp/data/global';
import { ANALYTICS, useSetAnalyticsContext } from '@sp/feature/analytics';
import { AudioPlayer } from '@sp/feature/challenge';
import { Chat, MessageExtraContent, ReactionsModal } from '@sp/feature/chat';
import { CHAT_CHANNEL } from '@sp/feature/chat-channel';
import { MEMBERS_MODEL, MembersModal } from '@sp/feature/members';
import { MENTIONS_MODEL, MentionsLayout } from '@sp/feature/mentions';
import { UserProfileCard } from '@sp/feature/profile';
import { ChatTitle, Header, LiveTitle, MentionsTitle, UseSpacialBadge } from '@sp/feature/space';
import { SpinnerPage } from '@sp/ui/elements';
import { TabPane, Tabs } from '@sp/ui/tabs';
import { GRAY_PIXEL_IMG_SRC } from '@sp/util/files';
import { isNotNullish } from '@sp/util/helpers';
import { parseFromParam, ROUTES } from '@sp/util/router';
import { useGate, useStore } from 'effector-react';
import { useEffect, useMemo, useState, VFC } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { LiveLayout } from './live-layout';
import { LIVE_MODEL } from './live-model';

export const InCallView: VFC<{ user: MeUser; linkToZoom?: string | null; creator?: SpaceCreator | null }> = ({
  user,
  linkToZoom,
  creator,
}) => {
  const LIVE_URL =
    linkToZoom && linkToZoom + (user.id === creator!.id ? `/leader?name=${user.name}` : `/follower?name=${user.name}`);

  return LIVE_URL ? (
    <iframe
      src={LIVE_URL}
      className="w-screen h-full"
      allow="camera;microphone;display-capture;autoplay;clipboard-write;"
      allowFullScreen
    />
  ) : null;
};

export const LivePage: VFC<{ liveId: number; tabKey: string }> = ({ liveId, tabKey }) => {
  const chatModel = useStore(CHAT_CHANNEL.$instance);
  const live = useStore(LIVE_MODEL.$live);
  const navigate = useNavigate();
  const activeAudioPlayer = useStore(GlobalMediaPlayerModel.$player);
  const user = useUser();
  const [searchParams] = useSearchParams();
  const fromParam = parseFromParam(searchParams);
  useGate(LIVE_MODEL.LiveGate, liveId);
  const [isOpenTracked, setIsOpenTracked] = useState(false);

  useSetAnalyticsContext(
    isNotNullish(live)
      ? {
          'Space ID': live?.id?.toString() ?? '',
          'Space Name': live?.title?.toString() ?? '',
          'Creator ID': live?.creator?.id.toString() ?? '',
          'Creator Name': live?.creator?.name.toString() ?? '',
          From: fromParam,
          Type: 'Live',
        }
      : null,
  );

  function onTabChange(key: string) {
    navigate(`${ROUTES.LIVE_PAGE_URL}/${liveId}/${key}`, { replace: true });
  }

  useEffect(() => {
    ANALYTICS.spaceOpenTrackingStart();
    return () => {
      ANALYTICS.spaceCloseTracked();
    };
  }, []);

  useEffect(() => {
    if (isNotNullish(live) && isNotNullish(chatModel) && !isOpenTracked) {
      ANALYTICS.spaceOpenTracked();
      setIsOpenTracked(true);
    }
  }, [live, chatModel, isOpenTracked]);

  const isMembersModalOpen = useStore(MEMBERS_MODEL.$isOpen);
  const fullAcceptedFileAttachments = user.isAdmin || user.id === live?.creator?.id;
  const [specialBadgeContent] = UseSpacialBadge(live?.creator || null);
  const extraContent: MessageExtraContent = useMemo(
    () => ({
      afterUserName: specialBadgeContent,
    }),
    [specialBadgeContent],
  );
  const mentionsUnviewedCount = useStore(MENTIONS_MODEL.$unviewedCount);

  const [isInCall, setIsInCall] = useState(false);

  return live && chatModel ? (
    <div className="h-full flex flex-col">
      <Header
        creatorId={live.creator?.id}
        title={live?.title || ''}
        members={live.membersCount}
        avatar={live.cover?.url ?? GRAY_PIXEL_IMG_SRC}
        urlToShare={`${SELFPLUS_WEBSITE_URL}/live/?id=${live.id}/`}
        onClick={MEMBERS_MODEL.open}
      />
      <div className="flex-1">
        <Tabs onChange={onTabChange} activeKey={tabKey} activeTabTextColor="text-primary">
          <TabPane title={<ChatTitle />} key="chat">
            <Chat
              messageExtraContent={extraContent}
              fullAcceptedFileAttachments={fullAcceptedFileAttachments}
              chatModel={chatModel}
            />
          </TabPane>
          <TabPane
            title={<LiveTitle duration={live.config?.live.duration} date={live.config?.live.startAt} />}
            key="journey"
          >
            <div className="relative h-full w-full">
              {isInCall ? (
                <InCallView linkToZoom={live.config?.live.linkToZoom} creator={live.creator} user={user} />
              ) : (
                <>
                  <AudioPlayer player={activeAudioPlayer} />
                  <LiveLayout onEnterCall={() => setIsInCall(true)} />
                </>
              )}
            </div>
          </TabPane>
          <TabPane title={<MentionsTitle unviewedCount={mentionsUnviewedCount} />} key="mentions">
            <div className="relative h-full w-full">
              <AudioPlayer player={activeAudioPlayer} />
              <MentionsLayout chatId={live?.chatId || null} />
            </div>
          </TabPane>
        </Tabs>
      </div>
      <ReactionsModal />
      <UserProfileCard cardContext="space" />
      <MembersModal
        creator={live.creator || null}
        title={live.title || ''}
        avatar={live.cover?.url ?? GRAY_PIXEL_IMG_SRC}
        urlToShare={`${SELFPLUS_WEBSITE_URL}/live/?id=${live.id}/`}
        members={live.members || []}
        isOpen={isMembersModalOpen}
      />
    </div>
  ) : (
    <SpinnerPage />
  );
};
