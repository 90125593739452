import { USER_MODEL } from '@sp/data/auth';
import { ChatTeaser, ChatType } from '@sp/data/bif';
import { GLOBAL_CHANNELS_INIT, STREAM_CHAT_MODEL, waitUntilChatInitialized } from '@sp/feature/stream-chat';
import { createResourceWithParams } from '@sp/util/helpers';
import { ChatChannel, ChatChannelType, ChatEvent, ChatEventTypes } from '@sp/util/stream-chat';
import { combine, createEvent, createStore, sample, Store } from 'effector';
import chunk from 'lodash/chunk';
import flatten from 'lodash/flatten';
import isEqual from 'lodash/isEqual';
import { CHAT_TEASERS_GLOBAL_MODEL } from './chat-teasers-global-model';

const STREAM_CHAT_CHANNELS_QUERY_LIMIT = 30;

function chatToChannelType(type: ChatType): ChatChannelType {
  if (type === ChatType.platform) {
    return ChatChannelType.platform;
  } else {
    return ChatChannelType.messaging;
  }
}

export function getChatChannelCid(chat: ChatTeaser): string {
  return `${chatToChannelType(chat.type)}:${chat.gsChannelId.toString()}`;
}

const $chatChannelsParams: Store<[string[] | null, string | null]> = combine({
  chats: CHAT_TEASERS_GLOBAL_MODEL.$data,
  user: USER_MODEL.$user,
}).map(({ chats, user }) => [chats?.map(getChatChannelCid) ?? null, user ? user.id.toString() : null]);

export const STREAM_CHAT_CHANNELS_MODEL = createResourceWithParams({
  loadOnArgsChange: true,
  isArgsEqual: isEqual,
  $args: $chatChannelsParams,
  fetchResource: async (chatIds: string[] | null, userId: string | null): Promise<ChatChannel[] | null> => {
    if (chatIds == null || userId == null) return null;
    if (chatIds.length === 0) return [];

    await waitUntilChatInitialized(STREAM_CHAT_MODEL);

    // Stream Chat API can only handle 30 channels at once
    // thus the channels are queried in chunks of 30.
    const chunkedChatIds = chunk(chatIds, STREAM_CHAT_CHANNELS_QUERY_LIMIT);

    const channels = await Promise.all(
      chunkedChatIds.map(ids =>
        STREAM_CHAT_MODEL.client.queryChannels(
          {
            members: { $in: [userId] },
            cid: { $in: ids },
          },
          [{ last_message_at: -1 }],
          {
            message_limit: 1,
            member_limit: 1,
            state: false,
            watch: true,
            presence: false,
            limit: STREAM_CHAT_CHANNELS_QUERY_LIMIT,
          },
        ),
      ),
    );

    return flatten(channels);
  },
});

sample({
  clock: STREAM_CHAT_MODEL.$isInitialized,
  filter: Boolean,
  target: STREAM_CHAT_CHANNELS_MODEL.load,
});

sample({
  clock: STREAM_CHAT_CHANNELS_MODEL.$data,
  filter: data => data != null,
  target: GLOBAL_CHANNELS_INIT.globalChannelsInitialized,
});

const channelEventReceived = createEvent<Readonly<{ channel: ChatChannel; event: ChatEvent }>>();

function pickEvent(...types: readonly ChatEventTypes[]): typeof channelEventReceived {
  return channelEventReceived.filterMap(channelEvent =>
    types.includes(channelEvent.event.type) ? channelEvent : undefined,
  );
}

const subscriptions: { unsubscribe: VoidFunction }[] = [];

function clearSubscriptions() {
  for (const { unsubscribe } of subscriptions) {
    unsubscribe();
  }
}

STREAM_CHAT_CHANNELS_MODEL.$data.watch(channels => {
  clearSubscriptions();

  if (channels == null) return;

  for (const channel of channels) {
    subscriptions.push(channel.on(event => channelEventReceived({ channel, event })));
  }
});

type GlobalChannelsState = {
  [cid: string]: {
    channel: ChatChannel;
  };
};

export const $globalChannels = createStore<Readonly<GlobalChannelsState> | null>(null)
  .on(STREAM_CHAT_CHANNELS_MODEL.$data, (_, channels) => {
    const state: GlobalChannelsState = {};
    if (channels == null) return state;

    for (const channel of channels) {
      state[channel.cid] = { channel };
    }

    return state;
  })
  .on(pickEvent('message.new', 'message.updated', 'message.deleted', 'message.read'), (state, { channel }) => {
    return {
      ...state,
      [channel.cid]: { channel },
    };
  });

export const $totalUnreadMessagesCount = $globalChannels.map(channels =>
  channels
    ? Object.values(channels)
        .map(({ channel }) => channel.countUnread())
        .reduce((acc, cur) => acc + cur, 0)
    : 0,
);
