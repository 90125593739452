import { ChatTeaser, ChatType, DmChatTeaser, getDm } from '@sp/data/bif';
import { CHAT_TEASERS_GLOBAL_MODEL } from '@sp/data/global';
import { USER_PROFILE_CARD_MEMBERS_MODEL, USER_PROFILE_CARD_SHOW_MODEL } from '@sp/data/user-profile-card';
import { ChatChannelModel, createChatChannelModel, STREAM_CHAT_MODEL } from '@sp/feature/stream-chat';
import { isNotNullish, Nullable, switchMap } from '@sp/util/helpers';
import { attach, createDomain, forward, sample, split } from 'effector';

const dmDomain = createDomain();
const dmCreated = dmDomain.createEvent<number>();

const createDmFx = attach({
  source: CHAT_TEASERS_GLOBAL_MODEL.$data,
  mapParams: (id: number, chats) => {
    return { id, chats: chats ?? [] };
  },
  // TODO[Dmitriy Teplov] freshen up globally stored chat.
  effect: dmDomain.createEffect(({ id, chats }: Readonly<{ id: number; chats: readonly ChatTeaser[] }>) => {
    const current =
      (chats?.find(chat => chat.type === ChatType.dm && chat.dmUsers.find(u => u.id === id)) as DmChatTeaser) ?? null;

    return current ?? getDm([id]);
  }),
});

const instanceDestroyed = dmDomain.createEvent();

const $activeDmId = dmDomain.createStore<Nullable<number>>(null).reset(instanceDestroyed);

const $dmInstance = dmDomain.createStore<ChatChannelModel | null>(null).reset(instanceDestroyed);

const $dmChat = dmDomain
  .createStore<DmChatTeaser | null>(null)
  .reset(instanceDestroyed)
  .reset(dmCreated)
  .on(createDmFx.doneData, (_, chat) => chat);

const destroyFx = dmDomain.createEffect(async (channel: ChatChannelModel | null) => await channel?.destroy());

const createFx = dmDomain.createEffect(
  async (channelId: string) => await createChatChannelModel(STREAM_CHAT_MODEL, channelId),
);

sample({
  clock: dmCreated,
  target: createDmFx,
});

sample({
  source: createDmFx.doneData,
  fn: data => data.gsChannelId,
  filter: isNotNullish,
  target: $activeDmId,
});

forward({
  from: $activeDmId,
  to: attach({
    source: $dmInstance,
    effect: destroyFx,
  }),
});

forward({
  from: instanceDestroyed,
  to: attach({
    source: $dmInstance,
    effect: destroyFx,
  }),
});

sample({
  clock: destroyFx.done,
  source: $activeDmId,
  filter: isNotNullish,
  fn: (id: number) => id.toString(),
  target: createFx,
});

forward({
  from: createFx.doneData,
  to: $dmInstance,
});

export const DM_CHANNEL = { $dmInstance, instanceDestroyed, dmCreated, $dmChat };

// Init UserProfileCard
{
  const users = USER_PROFILE_CARD_MEMBERS_MODEL.users.createItemAdapter('dm');

  split({
    source: $dmChat.map(chat => chat?.dmUsers),
    match: dmUsers => (dmUsers ? 'set' : 'unset'),
    cases: {
      set: users.set,
      unset: users.unset,
    },
  });

  switchMap({
    source: $dmInstance,
    fn: i => i?.showUserCard,
    target: USER_PROFILE_CARD_SHOW_MODEL.show,
  });
}
