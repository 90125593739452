import { LiveSpaceConfig, SpaceCreator } from '@sp/data/bif';
import { ANALYTICS } from '@sp/feature/analytics';
import { openGalleryHandler } from '@sp/feature/back-button';
import { AttachmentVideoView } from '@sp/ui/chat';
import { Button } from '@sp/ui/elements';
import { PHOTO_GALLERY_OPTIONS, PhotoswipeGallery } from '@sp/ui/gallery';
import {
  LiveDescriptionCard,
  LiveDescriptionTestimonials,
  SpaceDescriptionAuthor,
  SpaceDescriptionMultiline,
  SpaceDescriptionTable,
} from '@sp/ui/spaces-shared';
import { ViewVideoAttachment } from '@sp/util/chat-attachments';
import { isNotNullish, isNullish, Nullable, WithOptional } from '@sp/util/helpers';
import { ATTACHMENT_TYPE } from '@sp/util/stream-chat';
import { addHours, isAfter } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { enUS } from 'date-fns/locale';
import { useStore } from 'effector-react';
import { VFC } from 'react';
import { useTime } from 'react-time-sync';
import { Interval } from 'time-sync/constants';
import { LIVE_MODEL } from './live-model';

export const LiveContent: VFC<{
  live: WithOptional<LiveSpaceConfig['live'], 'testimonials' | 'linkToZoom'> & { link?: string };
  title?: string;
  creator?: Nullable<SpaceCreator>;
  onEnterCall: VoidFunction;
  showTestimonials: boolean;
  cover?: Nullable<{ url: string }>;
}> = ({ live, title, creator, onEnterCall, showTestimonials, cover }) => {
  const now = useTime({ interval: Interval.SECONDS }) * 1000;
  const { startAt, duration, description, testimonials, creatorDescription, requirements, record, linkToZoom } = live;
  const link = linkToZoom || live.link;
  const endAt = startAt + duration * 60 * 1000;
  // FIXME[Dmitriy Teplov] this is an Ad Hoc solution
  //  to allow users to enter live call after it ends.
  const isEnded = isAfter(now, addHours(endAt, 3));
  const isLive = !isEnded && isAfter(now, startAt);
  const isCanOpenRoom = isNotNullish(link) && isAfter(now, startAt - 5 * 60 * 1000);

  const video: ViewVideoAttachment | null = isNotNullish(record)
    ? {
        url: record.video.url,
        name: title ?? '',
        type: ATTACHMENT_TYPE.video,
        width: 1080,
        height: 1080,
        duration: record.meta.duration,
        fileType: 'video/mp4',
        previewUrl: record.cover?.url ?? cover?.url ?? '',
      }
    : null;

  const recordElement = isNotNullish(video) ? (
    <>
      <h1 className="text-2xl font-normal mt-0 mb-1">🎬 This live stream has ended</h1>
      <p className="my-2">You can watch the recording below anytime</p>
      <PhotoswipeGallery onOpen={openGalleryHandler} options={PHOTO_GALLERY_OPTIONS}>
        <AttachmentVideoView attachment={video} />
      </PhotoswipeGallery>
    </>
  ) : (
    <LiveDescriptionCard
      name={creator?.name ?? ''}
      duration={duration}
      startAt={startAt}
      title={
        <>
          <h1 className="text-2xl font-normal mt-0 mb-1">🎬 This live stream has ended</h1>
          <p className="m-0">
            But the recording will be up soon!
            <br />
            Check back later
          </p>
        </>
      }
      cover={cover?.url ?? ''}
    />
  );

  return (
    <div className="absolute left-0 top-0 w-full h-full overflow-y-auto mobile-pan py-4 px-6">
      {isEnded ? (
        recordElement
      ) : (
        <section>
          <LiveDescriptionCard
            name={creator?.name ?? ''}
            duration={duration}
            startAt={startAt}
            title={<h2 className="mt-0 mb-1">{isLive ? <>On air now</> : <>We start in</>}</h2>}
            cover={cover?.url ?? ''}
          >
            <Button
              onClick={() => {
                if (isNotNullish(link)) {
                  ANALYTICS.enterLiveRoomTracked();
                  onEnterCall();
                  // navigateExternal(linkToZoom);
                }
              }}
              disabled={!isCanOpenRoom}
              block
              color="black"
            >
              Join live stream
            </Button>
            {!isCanOpenRoom && (
              <div className="mt-2 text-xs text-secondary text-center">
                You will be able to join a few minutes before the start
              </div>
            )}
          </LiveDescriptionCard>
        </section>
      )}

      <div className="mt-6">
        <SpaceDescriptionTable
          rows={[
            {
              label: <span className="font-semibold">Date&nbsp;and&nbsp;time:</span>,
              value: formatInTimeZone(
                startAt,
                Intl.DateTimeFormat().resolvedOptions().timeZone,
                'MMMM d, hh:mm a zzz',
                {
                  locale: enUS,
                },
              ),
            },
            { label: <span className="font-semibold">Duration:</span>, value: `${duration} min` },
          ]}
        />

        <div className="mt-6">
          <SpaceDescriptionMultiline text={description} />
        </div>
        {requirements && <SpaceDescriptionMultiline text={requirements} heading="Requirements" />}
      </div>

      <section className="">
        <SpaceDescriptionAuthor
          heading="Your Expert"
          name={creator?.name ?? ''}
          avatar={creator?.avatar?.url}
          description={creatorDescription}
        />

        {testimonials && testimonials.length > 0 && showTestimonials ? (
          <LiveDescriptionTestimonials heading="The community's perspective" testimonials={testimonials} />
        ) : null}
      </section>
    </div>
  );
};

export const LiveLayout: VFC<{ onEnterCall: VoidFunction; showTestimonials?: boolean }> = ({
  onEnterCall,
  showTestimonials = true,
}) => {
  const live = useStore(LIVE_MODEL.$live);

  if (isNullish(live) || isNullish(live.config)) {
    return null;
  }

  const { title, creator, cover } = live;
  return (
    <LiveContent
      live={live.config.live}
      cover={cover}
      title={title}
      showTestimonials={showTestimonials}
      creator={creator}
      onEnterCall={onEnterCall}
    />
  );
};
