/* tslint:disable */
/* eslint-disable */
/**
 * Expert
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 *
 * @export
 * @interface MeEarnsDetailedGet200ResponseInner
 */
export interface MeEarnsDetailedGet200ResponseInner {
  /**
   *
   * @type {number}
   * @memberof MeEarnsDetailedGet200ResponseInner
   */
  earns?: number;
  /**
   *
   * @type {MeEarnsDetailedGet200ResponseInnerCustomer}
   * @memberof MeEarnsDetailedGet200ResponseInner
   */
  customer?: MeEarnsDetailedGet200ResponseInnerCustomer | null;
}
/**
 *
 * @export
 * @interface MeEarnsDetailedGet200ResponseInnerCustomer
 */
export interface MeEarnsDetailedGet200ResponseInnerCustomer {
  /**
   *
   * @type {number}
   * @memberof MeEarnsDetailedGet200ResponseInnerCustomer
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof MeEarnsDetailedGet200ResponseInnerCustomer
   */
  name?: string;
  /**
   *
   * @type {MeEarnsDetailedGet200ResponseInnerCustomerAvatar}
   * @memberof MeEarnsDetailedGet200ResponseInnerCustomer
   */
  avatar?: MeEarnsDetailedGet200ResponseInnerCustomerAvatar | null;
}
/**
 *
 * @export
 * @interface MeEarnsDetailedGet200ResponseInnerCustomerAvatar
 */
export interface MeEarnsDetailedGet200ResponseInnerCustomerAvatar {
  /**
   *
   * @type {number}
   * @memberof MeEarnsDetailedGet200ResponseInnerCustomerAvatar
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof MeEarnsDetailedGet200ResponseInnerCustomerAvatar
   */
  url?: string;
}
/**
 *
 * @export
 * @interface MeEarnsGet200Response
 */
export interface MeEarnsGet200Response {
  /**
   *
   * @type {number}
   * @memberof MeEarnsGet200Response
   */
  earned?: number;
  /**
   *
   * @type {number}
   * @memberof MeEarnsGet200Response
   */
  purchasedCount?: number;
  /**
   *
   * @type {number}
   * @memberof MeEarnsGet200Response
   */
  involvedCount?: number;
}
/**
 *
 * @export
 * @interface MePurchasesGet200ResponseInner
 */
export interface MePurchasesGet200ResponseInner {
  /**
   *
   * @type {number}
   * @memberof MePurchasesGet200ResponseInner
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof MePurchasesGet200ResponseInner
   */
  created_at?: number;
  /**
   *
   * @type {number}
   * @memberof MePurchasesGet200ResponseInner
   */
  price?: number;
  /**
   *
   * @type {MePurchasesGet200ResponseInnerCustomer}
   * @memberof MePurchasesGet200ResponseInner
   */
  customer?: MePurchasesGet200ResponseInnerCustomer | null;
  /**
   *
   * @type {MePurchasesGet200ResponseInnerSpace}
   * @memberof MePurchasesGet200ResponseInner
   */
  space?: MePurchasesGet200ResponseInnerSpace | null;
}
/**
 *
 * @export
 * @interface MePurchasesGet200ResponseInnerCustomer
 */
export interface MePurchasesGet200ResponseInnerCustomer {
  /**
   *
   * @type {number}
   * @memberof MePurchasesGet200ResponseInnerCustomer
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof MePurchasesGet200ResponseInnerCustomer
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof MePurchasesGet200ResponseInnerCustomer
   */
  description?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof MePurchasesGet200ResponseInnerCustomer
   */
  isAvatar?: boolean;
  /**
   *
   * @type {MeEarnsDetailedGet200ResponseInnerCustomerAvatar}
   * @memberof MePurchasesGet200ResponseInnerCustomer
   */
  avatar?: MeEarnsDetailedGet200ResponseInnerCustomerAvatar | null;
}
/**
 *
 * @export
 * @interface MePurchasesGet200ResponseInnerSpace
 */
export interface MePurchasesGet200ResponseInnerSpace {
  /**
   *
   * @type {number}
   * @memberof MePurchasesGet200ResponseInnerSpace
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof MePurchasesGet200ResponseInnerSpace
   */
  title?: string;
  /**
   *
   * @type {number}
   * @memberof MePurchasesGet200ResponseInnerSpace
   */
  price?: number;
  /**
   *
   * @type {string}
   * @memberof MePurchasesGet200ResponseInnerSpace
   */
  type?: MePurchasesGet200ResponseInnerSpaceTypeEnum;
  /**
   *
   * @type {string}
   * @memberof MePurchasesGet200ResponseInnerSpace
   */
  status?: MePurchasesGet200ResponseInnerSpaceStatusEnum;
  /**
   *
   * @type {string}
   * @memberof MePurchasesGet200ResponseInnerSpace
   */
  payment_type?: MePurchasesGet200ResponseInnerSpacePaymentTypeEnum;
  /**
   *
   * @type {MePurchasesGet200ResponseInnerSpaceConfig}
   * @memberof MePurchasesGet200ResponseInnerSpace
   */
  config?: MePurchasesGet200ResponseInnerSpaceConfig | null;
  /**
   *
   * @type {MeEarnsDetailedGet200ResponseInnerCustomerAvatar}
   * @memberof MePurchasesGet200ResponseInnerSpace
   */
  cover?: MeEarnsDetailedGet200ResponseInnerCustomerAvatar | null;
}

export const MePurchasesGet200ResponseInnerSpaceTypeEnum = {
  Post: 'post',
  Live: 'live',
  Challenge: 'challenge',
  Trailer: 'trailer',
  Channel: 'channel',
  Community: 'community',
} as const;

export type MePurchasesGet200ResponseInnerSpaceTypeEnum =
  typeof MePurchasesGet200ResponseInnerSpaceTypeEnum[keyof typeof MePurchasesGet200ResponseInnerSpaceTypeEnum];
export const MePurchasesGet200ResponseInnerSpaceStatusEnum = {
  Draft: 'draft',
  Published: 'published',
} as const;

export type MePurchasesGet200ResponseInnerSpaceStatusEnum =
  typeof MePurchasesGet200ResponseInnerSpaceStatusEnum[keyof typeof MePurchasesGet200ResponseInnerSpaceStatusEnum];
export const MePurchasesGet200ResponseInnerSpacePaymentTypeEnum = {
  FixedPrice: 'fixed_price',
  FixedPriceSubscribersOnly: 'fixed_price_subscribers_only',
  SubscribersOnly: 'subscribers_only',
  Free: 'free',
  MonthlyPrice: 'monthly_price',
} as const;

export type MePurchasesGet200ResponseInnerSpacePaymentTypeEnum =
  typeof MePurchasesGet200ResponseInnerSpacePaymentTypeEnum[keyof typeof MePurchasesGet200ResponseInnerSpacePaymentTypeEnum];

/**
 *
 * @export
 * @interface MePurchasesGet200ResponseInnerSpaceConfig
 */
export interface MePurchasesGet200ResponseInnerSpaceConfig {
  /**
   *
   * @type {MePurchasesGet200ResponseInnerSpaceConfigChallenge}
   * @memberof MePurchasesGet200ResponseInnerSpaceConfig
   */
  challenge?: MePurchasesGet200ResponseInnerSpaceConfigChallenge | null;
}
/**
 *
 * @export
 * @interface MePurchasesGet200ResponseInnerSpaceConfigChallenge
 */
export interface MePurchasesGet200ResponseInnerSpaceConfigChallenge {
  /**
   *
   * @type {number}
   * @memberof MePurchasesGet200ResponseInnerSpaceConfigChallenge
   */
  finishTimestamp?: number | null;
}
/**
 *
 * @export
 * @interface MeSubscriptionsGet200ResponseInner
 */
export interface MeSubscriptionsGet200ResponseInner {
  /**
   *
   * @type {number}
   * @memberof MeSubscriptionsGet200ResponseInner
   */
  subscribed_at?: number;
  /**
   *
   * @type {number}
   * @memberof MeSubscriptionsGet200ResponseInner
   */
  subscriptionPrice?: number | null;
  /**
   *
   * @type {number}
   * @memberof MeSubscriptionsGet200ResponseInner
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof MeSubscriptionsGet200ResponseInner
   */
  name?: string;
  /**
   *
   * @type {boolean}
   * @memberof MeSubscriptionsGet200ResponseInner
   */
  isAvatar?: boolean;
  /**
   *
   * @type {MeEarnsDetailedGet200ResponseInnerCustomerAvatar}
   * @memberof MeSubscriptionsGet200ResponseInner
   */
  avatar?: MeEarnsDetailedGet200ResponseInnerCustomerAvatar | null;
}

/**
 * MeApi - axios parameter creator
 * @export
 */
export const MeApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    meEarnsDetailedGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/me/earns/detailed`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Получение суммы заработка эксперта (за всё время) <br /><br /> <b>Authentication:</b> required
     * @summary Получение суммы заработка эксперта (за всё время)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    meEarnsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/me/earns`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mePurchasesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/me/purchases`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Список подписок на эксперта, с указанием стоимости подписки <br /><br /> <b>Authentication:</b> required
     * @summary Список подписок на эксперта, с указанием стоимости подписки
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    meSubscriptionsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/me/subscriptions`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {string} [period]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    meTransactionsGet: async (period?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/me/transactions`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (period !== undefined) {
        localVarQueryParameter['period'] = period;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * MeApi - functional programming interface
 * @export
 */
export const MeApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = MeApiAxiosParamCreator(configuration);
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async meEarnsDetailedGet(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MeEarnsDetailedGet200ResponseInner>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.meEarnsDetailedGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Получение суммы заработка эксперта (за всё время) <br /><br /> <b>Authentication:</b> required
     * @summary Получение суммы заработка эксперта (за всё время)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async meEarnsGet(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MeEarnsGet200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.meEarnsGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async mePurchasesGet(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MePurchasesGet200ResponseInner>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.mePurchasesGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Список подписок на эксперта, с указанием стоимости подписки <br /><br /> <b>Authentication:</b> required
     * @summary Список подписок на эксперта, с указанием стоимости подписки
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async meSubscriptionsGet(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MeSubscriptionsGet200ResponseInner>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.meSubscriptionsGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {string} [period]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async meTransactionsGet(
      period?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.meTransactionsGet(period, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * MeApi - factory interface
 * @export
 */
export const MeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = MeApiFp(configuration);
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    meEarnsDetailedGet(options?: any): AxiosPromise<Array<MeEarnsDetailedGet200ResponseInner>> {
      return localVarFp.meEarnsDetailedGet(options).then(request => request(axios, basePath));
    },
    /**
     * Получение суммы заработка эксперта (за всё время) <br /><br /> <b>Authentication:</b> required
     * @summary Получение суммы заработка эксперта (за всё время)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    meEarnsGet(options?: any): AxiosPromise<MeEarnsGet200Response> {
      return localVarFp.meEarnsGet(options).then(request => request(axios, basePath));
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mePurchasesGet(options?: any): AxiosPromise<Array<MePurchasesGet200ResponseInner>> {
      return localVarFp.mePurchasesGet(options).then(request => request(axios, basePath));
    },
    /**
     * Список подписок на эксперта, с указанием стоимости подписки <br /><br /> <b>Authentication:</b> required
     * @summary Список подписок на эксперта, с указанием стоимости подписки
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    meSubscriptionsGet(options?: any): AxiosPromise<Array<MeSubscriptionsGet200ResponseInner>> {
      return localVarFp.meSubscriptionsGet(options).then(request => request(axios, basePath));
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {string} [period]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    meTransactionsGet(period?: string, options?: any): AxiosPromise<object> {
      return localVarFp.meTransactionsGet(period, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * MeApi - object-oriented interface
 * @export
 * @class MeApi
 * @extends {BaseAPI}
 */
export class MeApi extends BaseAPI {
  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MeApi
   */
  public meEarnsDetailedGet(options?: AxiosRequestConfig) {
    return MeApiFp(this.configuration)
      .meEarnsDetailedGet(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Получение суммы заработка эксперта (за всё время) <br /><br /> <b>Authentication:</b> required
   * @summary Получение суммы заработка эксперта (за всё время)
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MeApi
   */
  public meEarnsGet(options?: AxiosRequestConfig) {
    return MeApiFp(this.configuration)
      .meEarnsGet(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MeApi
   */
  public mePurchasesGet(options?: AxiosRequestConfig) {
    return MeApiFp(this.configuration)
      .mePurchasesGet(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Список подписок на эксперта, с указанием стоимости подписки <br /><br /> <b>Authentication:</b> required
   * @summary Список подписок на эксперта, с указанием стоимости подписки
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MeApi
   */
  public meSubscriptionsGet(options?: AxiosRequestConfig) {
    return MeApiFp(this.configuration)
      .meSubscriptionsGet(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {string} [period]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MeApi
   */
  public meTransactionsGet(period?: string, options?: AxiosRequestConfig) {
    return MeApiFp(this.configuration)
      .meTransactionsGet(period, options)
      .then(request => request(this.axios, this.basePath));
  }
}
