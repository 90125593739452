import { MeUser } from '@sp/data/bif';
import {
  AcceptFileTypesProvider,
  ChatMessageAvatar,
  ChatMessageBody,
  ChatMessageBottomAttachments,
  ChatMessageProvider,
  ChatMessageTopAttachments,
  mapAttachmentsToClient,
  mapAttachmentsToStream,
  MessageForm,
  mockChatModel,
  useMessageForm,
} from '@sp/feature/chat';
import {
  ChatMessageBodyContainer,
  ChatMessageContainer,
  ChatMessageLeftContainer,
  ChatMessageRightContainer,
} from '@sp/ui/chat';
import { Button, Input } from '@sp/ui/elements';
import { IconCaretUp, IconExport, IconPencil, IconTrashSimple } from '@sp/ui/icons';
import { Modal } from '@sp/ui/modal';
import { cls, isNotNullish, isNullish, Nullable, useId } from '@sp/util/helpers';
import { ChatFormatMessage } from '@sp/util/stream-chat';
import { addDays, format } from 'date-fns';
import { useStore } from 'effector-react';
import { FC, ReactElement, useCallback, useMemo, useState } from 'react';
import { CHALLENGE_FORM_MODEL } from './challenge-form-model';
import { Post, PostMapKeys, Seed } from './types';
import { formatDateTimeForInput, getPostType, isDeferredPost, isNotSeed, parseDateTimeInputValue } from './utils';

function mockChatFormatMessage(
  { content: { text, attachments } }: Seed<Post>,
  id: string,
  user: MeUser,
): ChatFormatMessage {
  return {
    id,
    created_at: new Date(),
    updated_at: new Date(),
    pinned_at: null,
    status: 'published',
    text,
    attachments,
    user: { id: user.id.toString(), name: user.name, image: user.avatar?.url ?? null, isCreator: user.isExpert },
  };
}

export const PostMessage: FC<{
  post: Seed<Post>;
  actions: ReactElement;
}> = ({ post, actions }) => {
  const id = useId();
  const owner = useStore(CHALLENGE_FORM_MODEL.$owner);
  const message = useMemo<ChatFormatMessage>(() => mockChatFormatMessage(post, id, owner as MeUser), [id, post, owner]);

  return (
    <div>
      <ChatMessageProvider value={{ message, model: mockChatModel() }}>
        <ChatMessageContainer>
          <ChatMessageLeftContainer>
            <ChatMessageAvatar />
          </ChatMessageLeftContainer>

          <ChatMessageRightContainer>
            <div className="flex items-center">
              <span className="font-semibold text-ellipsis whitespace-nowrap overflow-hidden">Owner</span>

              <div className="flex-grow" />

              {actions}
            </div>

            <ChatMessageTopAttachments />

            <ChatMessageBodyContainer>
              <ChatMessageBody />
            </ChatMessageBodyContainer>

            <ChatMessageBottomAttachments />
          </ChatMessageRightContainer>
        </ChatMessageContainer>
      </ChatMessageProvider>
    </div>
  );
};

export const ChallengePostsList: FC<{
  stepKey: PostMapKeys;
}> = ({ stepKey }) => {
  const postsMap = useStore(CHALLENGE_FORM_MODEL.$postsMap);
  const steps = useStore(CHALLENGE_FORM_MODEL.$steps);
  const posts = useMemo(() => postsMap[stepKey] ?? [], [postsMap, stepKey]);
  const [editedPostIndex, setEditedPostIndex] = useState<Nullable<number | 'new'>>(null);
  const [movedPostIndex, setMovedPostIndex] = useState<Nullable<number>>(null);
  const isEditorOpen = useMemo(() => isNotNullish(editedPostIndex), [editedPostIndex]);
  const formModel = useMessageForm('challenge-editor');
  const [editedPublishAt, setEditedPublishAt] = useState<string | null>(null);
  const isValidEditedPublishAt = useMemo(
    () => isNotNullish(editedPublishAt) && isNotNullish(parseDateTimeInputValue(editedPublishAt)),
    [editedPublishAt],
  );
  const isPublished = useStore(CHALLENGE_FORM_MODEL.$isChallengePublished);

  const moveTo = useCallback(
    (key: PostMapKeys) => {
      if (isNotNullish(movedPostIndex)) {
        CHALLENGE_FORM_MODEL.postMovedToStep({ stepKey, postIndex: movedPostIndex, nextStepKey: key });
      }
      setMovedPostIndex(null);
    },
    [movedPostIndex, stepKey],
  );

  const addNewPost = useCallback(() => {
    const { resetAttachments } = formModel.attachmentManager;
    formModel.setText('');
    resetAttachments();
    if (stepKey === 'deferred') {
      setEditedPublishAt(formatDateTimeForInput(addDays(Date.now(), 1)));
    }
    setEditedPostIndex('new');
  }, [formModel, stepKey]);

  const editPost = useCallback(
    (postIndex: number) => {
      const { resetAttachments } = formModel.attachmentManager;
      const post = posts[postIndex];
      formModel.setText(post.content.text ?? '');
      resetAttachments(mapAttachmentsToClient(post.content.attachments ?? []));
      const editedPost = posts[postIndex];
      if (stepKey === 'deferred' && isNotNullish(editedPost) && isDeferredPost(editedPost)) {
        setEditedPublishAt(formatDateTimeForInput(editedPost.publishAt));
      }
      setEditedPostIndex(postIndex);
    },
    [formModel, posts, stepKey],
  );

  const reset = useCallback(() => {
    setEditedPostIndex(null);
    setEditedPublishAt(null);
    formModel.attachmentManager.resetAttachments();
    formModel.setText('');
  }, [formModel]);

  const closeEditor = useCallback(() => {
    const { attachments } = formModel.attachmentManager;
    const { text } = formModel.state;
    const hasMessageContent = text.length > 0 || attachments.length > 0;
    if (isNotNullish(editedPostIndex) && hasMessageContent) {
      const content = { text, attachments: mapAttachmentsToStream(attachments) };
      const publishAt = isNotNullish(editedPublishAt) ? parseDateTimeInputValue(editedPublishAt) : null;
      const isDeferred = stepKey === 'deferred';
      if (isNullish(publishAt) && isDeferred) {
        reset();
        return;
      }

      if (editedPostIndex === 'new') {
        const type = getPostType(stepKey);
        const post = isDeferred
          ? {
              type,
              content,
              publishAt,
            }
          : {
              type,
              content,
            };
        CHALLENGE_FORM_MODEL.postAdded({ stepKey, post });
      } else {
        const post = isDeferred
          ? {
              ...posts[editedPostIndex],
              content,
              publishAt,
            }
          : { ...posts[editedPostIndex], content };
        CHALLENGE_FORM_MODEL.postUpdated({ stepKey, post, postIndex: editedPostIndex });
      }
    }
    reset();
  }, [editedPostIndex, editedPublishAt, formModel.attachmentManager, formModel.state, posts, reset, stepKey]);

  return (
    <div>
      <div className="flex flex-col gap-4 mb-4">
        {posts.map((post, index) => (
          <PostMessage
            key={index}
            post={post}
            actions={
              <div className="flex gap-2 items-center">
                {!isDeferredPost(post) && (
                  <>
                    <Button
                      className="!p-2"
                      title="move up"
                      disabled={isPublished || index === 0}
                      onClick={() => CHALLENGE_FORM_MODEL.postMoved({ stepKey, postIndex: index, direction: 'up' })}
                    >
                      <IconCaretUp size={16} />
                    </Button>
                    <Button
                      className="!p-2"
                      title="move down"
                      disabled={isPublished || index === posts.length - 1}
                      onClick={() => CHALLENGE_FORM_MODEL.postMoved({ stepKey, postIndex: index, direction: 'down' })}
                    >
                      <IconCaretUp size={16} className="rotate-180" />
                    </Button>
                  </>
                )}

                {isDeferredPost(post) && (
                  <span className="text-secondary text-xs">{format(post.publishAt, `do MMM 'at' HH:mm`)}</span>
                )}

                <Button
                  title="move post to step"
                  disabled={isPublished}
                  onClick={() => setMovedPostIndex(index)}
                  className="!p-2"
                >
                  <IconExport size={16} />
                </Button>

                <Button className="!p-2" title="edit post" onClick={() => editPost(index)}>
                  <IconPencil size={16} />
                </Button>
                <Button
                  className="!p-2"
                  title="remove post"
                  onClick={() => CHALLENGE_FORM_MODEL.postRemoved({ stepKey, postIndex: index })}
                >
                  <IconTrashSimple size={16} />
                </Button>
              </div>
            }
          />
        ))}
      </div>

      <Button onClick={addNewPost} color="primary" block>
        New Post
      </Button>

      <Modal isOpen={isEditorOpen} onClose={closeEditor} withBackdrop closeOnBackdropClick maxWidth={375}>
        {isNotNullish(editedPublishAt) ? (
          <div className="mb-4">
            <label className="text-xs text-secondary pl-3">Publish time</label>
            <Input
              placeholder="Start time"
              type="datetime-local"
              min={formatDateTimeForInput(Date.now())}
              value={editedPublishAt}
              onChange={({ target: { value } }) => setEditedPublishAt(value)}
              block
              className={cls(isValidEditedPublishAt ? '' : '!border !border-solid !border-alert')}
            />
          </div>
        ) : null}
        <div className="-mx-6 overflow-visible">
          <AcceptFileTypesProvider fullAccepted={true}>
            <MessageForm submit={closeEditor} isLoading={false} model={formModel} editorMaxHeight="60vh" />
          </AcceptFileTypesProvider>
        </div>
      </Modal>

      <Modal
        isOpen={isNotNullish(movedPostIndex)}
        onClose={() => setMovedPostIndex(null)}
        withBackdrop
        closeOnBackdropClick
        maxWidth={375}
      >
        <h3 className="">Move to:</h3>
        <div className="flex flex-col gap-2 mb-2">
          <Button block onClick={() => moveTo('intro')}>
            To Intro
          </Button>
          {steps.filter(isNotSeed).map((step, index) => (
            <Button key={index} block onClick={() => moveTo(step.id)}>
              {step.title}
            </Button>
          ))}
          <Button block onClick={() => moveTo('finish')}>
            To Finish
          </Button>
          <Button block onClick={() => moveTo('deferred')}>
            To Deferred
          </Button>
        </div>
      </Modal>
    </div>
  );
};
