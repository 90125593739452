import { differenceInDays, format, startOfDay } from 'date-fns';

export function formatMinutesOrSeconds(timeUnit: number): string {
  return `${timeUnit === 0 ? '00' : timeUnit < 10 ? `0${timeUnit}` : timeUnit}`;
}

export function getMediaDurationString(duration: number): string {
  if (duration <= 60) {
    const seconds = Math.floor(duration);
    return `0:${formatMinutesOrSeconds(seconds)}`;
  } else if (duration <= 3600) {
    const minutes = Math.floor(duration / 60);
    const seconds = Math.floor(duration - minutes * 60);
    return `${minutes}:${formatMinutesOrSeconds(seconds)}`;
  } else {
    const hours = Math.floor(duration / 3600);
    const minutes = Math.floor((duration - hours * 3600) / 60);
    const seconds = Math.floor(duration - hours * 3600 - minutes * 60);
    const hoursStr = hours > 0 ? `${hours}:` : '';
    return `${hoursStr}${formatMinutesOrSeconds(minutes)}:${formatMinutesOrSeconds(seconds)}`;
  }
}

export function humanizeDate(timestamp: number, formatStr?: string) {
  // const time = format(timestamp, 'H:mm a');
  const daysDiff = differenceInDays(startOfDay(timestamp), startOfDay(new Date()));

  let date;

  if (daysDiff === -1) {
    date = 'Yesterday';
  }
  if (daysDiff === 0) {
    date = 'Today';
  }
  if (daysDiff >= 1) {
    date = 'Tomorrow';
  }
  if (daysDiff >= 2) {
    date = format(timestamp, formatStr || 'EEEE');
  }
  if (daysDiff >= 7 || daysDiff < -1) {
    date = format(timestamp, formatStr || 'EEEE, MMM d');
  }

  return `${date}`;
}
