import { ProfileCardUser } from '@sp/data/user-profile-card';
import { isNotNullish } from '@sp/util/helpers';
import { createDomain, restore, sample } from 'effector';
import { show } from './user-change-model';

type UserCardType = 'dm' | 'card';

const userCardDomain = createDomain();

const resetUserCard = userCardDomain.createEvent();

const close = userCardDomain.createEvent();
const $isOpen = userCardDomain.createStore(false).reset(close);

const $activeUser = userCardDomain.createStore<ProfileCardUser | null>(null).reset(resetUserCard);

const typeSet = userCardDomain.createEvent<UserCardType>();
const $type = restore(typeSet, 'card').reset(resetUserCard);

sample({ clock: $activeUser.map(() => 'card' as const), target: $type });
sample({ clock: $activeUser.map(user => isNotNullish(user)), target: $isOpen });

sample({ clock: show, target: $activeUser });

export const USER_PROFILE_CARD = {
  $activeUser,
  typeSet,
  $type,
  resetUserCard,
  close,
  $isOpen,
};
