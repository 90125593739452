import { USER_MODEL } from '@sp/data/auth';
import {
  ChannelSpaceConfig,
  loadSpaceById,
  loadSpaceMembers,
  SPACE_TYPE,
  SpaceCreator,
  SpaceMember,
} from '@sp/data/bif';
import {
  mapStreamChatMembersToProfileCardUsers,
  USER_PROFILE_CARD_MEMBERS_MODEL,
  USER_PROFILE_CARD_SHOW_MODEL,
} from '@sp/data/user-profile-card';
import { ChatChannelModel, createChatChannelModel, STREAM_CHAT_MODEL } from '@sp/feature/stream-chat';
import { PriceConfig } from '@sp/util/format';
import { isNotNullish, Nullable, snd, switchMap } from '@sp/util/helpers';
import { KnownSkillColor } from '@sp/util/skills';
import { attach, combine, createDomain, createEvent, restore, sample } from 'effector';

export type ChannelInfo = {
  id: number;
  creator: SpaceCreator;
  price: number;
  priceConfig: PriceConfig;
  config: ChannelSpaceConfig;
  iosProductId: string;
  isPurchased: boolean;
  publishedAt: number | null;
  isTrial: Nullable<boolean>;
  slug: string;
  userSlug: string;
  title: string;
  membersCount?: number;
  members: SpaceMember[];
  chatId: Nullable<number>;
  tags: ReadonlyArray<{
    id: number;
    name: string;
    weight: number;
    color: KnownSkillColor;
  }>;
  cover: Nullable<{ url: string }>;
  subscription: {
    id: number;
    createdAt: number;
    lastPaymentDate: number | null;
    startedAt: number | null;
    cancelledAt: number | null;
    price: number;
    isActive: true;
    nextPaymentDate: number | null;
    inApp: boolean;
  } | null;
};

const domain = createDomain();

const init = domain.createEvent<number>();
const destroy = domain.createEvent();

domain.onCreateStore(store => store.reset(destroy));

const $spaceId = restore(init, null);

const loadChannelFx = domain.createEffect((id: number) => {
  return loadSpaceById(id);
});

const loadChannelMembersFx = domain.createEffect((id: number) =>
  loadSpaceMembers(id).then(({ membersCountNumber, members }) => ({
    membersCount: membersCountNumber,
    members: members.sort(m => (m.isTrial ? 1 : -1)),
  })),
);

const loadDataFx = domain.createEffect(async (id: number) => {
  const [{ members, membersCount }, channel] = await Promise.all([loadChannelMembersFx(id), loadChannelFx(id)]);
  if (channel.type !== SPACE_TYPE.channel) {
    throw new Error('Space is not live');
  }
  const channelInfo: ChannelInfo = {
    id: channel.id,
    creator: channel.creator,
    price: channel.price,
    priceConfig: { web: channel.price, ios: channel.marketingPriceConfig.iosPrice },
    config: channel.config,
    iosProductId: channel.iosProductId,
    isPurchased: channel.isPurchased,
    publishedAt: channel.publishedAt,
    isTrial: channel.isTrial,
    slug: channel.slug,
    userSlug: channel.userSlug,
    title: channel.title,
    membersCount: membersCount,
    members: members,
    chatId: channel.chatId,
    cover: channel.cover ?? null,
    subscription: channel.subscription,
    tags: channel.tags ?? [],
  };
  return channelInfo;
});

const refetchData = createEvent();

sample({
  source: $spaceId,
  clock: [$spaceId, refetchData],
  filter: isNotNullish,
  target: loadDataFx,
});

const $channel = domain.createStore<ChannelInfo | null>(null).on(loadDataFx.doneData, snd);

const $channelId = domain.createStore<string | null>(null).on(
  $channel.map(ch => ch?.chatId?.toString() ?? null),
  snd,
);

const $isOwner = sample({
  source: combine({ user: USER_MODEL.$user, channel: $channel }),
  fn: ({ user, channel }) => channel?.creator?.id === user?.id,
});

const $ownerId = $channel.map(ch => ch?.creator?.id ?? null);

const createChatModelFx = domain.createEffect((channelId: string) =>
  createChatChannelModel(STREAM_CHAT_MODEL, channelId, 'messaging'),
);

const $chatModel = restore(createChatModelFx.doneData, null).reset(init);

const destroyChatModelFx = attach({
  source: $chatModel,
  effect: domain.createEffect((channel: ChatChannelModel | null) => channel?.destroy()),
});

sample({
  clock: [$channelId, destroy],
  target: destroyChatModelFx,
});

sample({
  clock: destroyChatModelFx.done,
  source: $channelId,
  filter: isNotNullish,
  target: createChatModelFx,
});

const $isLoading = loadDataFx.pending;

export const CHANNEL_MODEL = {
  init,
  destroy,
  $chatModel,
  refetchData,
  $channel,
  $isOwner,
  $ownerId,
  $isLoading,
};

// Init UserProfileCard
{
  const users = USER_PROFILE_CARD_MEMBERS_MODEL.users.createItemAdapter('channel');

  switchMap({
    source: $chatModel,
    fn: i => i?.$members.map(mapStreamChatMembersToProfileCardUsers),
    target: users.set,
  });

  sample({
    clock: destroy,
    target: users.unset,
  });

  switchMap({
    source: $chatModel,
    fn: i => i?.showUserCard,
    target: USER_PROFILE_CARD_SHOW_MODEL.show,
  });
}
