import { ReactElement } from 'react';
import { ICON_DEFAULTS, IconProps } from './types';

export function IconFeedActive({
  size = ICON_DEFAULTS.size,
  className = ICON_DEFAULTS.className,
}: IconProps): ReactElement {
  return (
    <svg
      width={size}
      height={size}
      className={className}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.25 4.5H5.25C4.85218 4.5 4.47064 4.65804 4.18934 4.93934C3.90804 5.22064 3.75 5.60218 3.75 6V17.25C3.75 17.4489 3.67098 17.6397 3.53033 17.7803C3.38968 17.921 3.19891 18 3 18C2.80109 18 2.61032 17.921 2.46967 17.7803C2.32902 17.6397 2.25 17.4489 2.25 17.25V8.25C2.25 8.05109 2.17098 7.86032 2.03033 7.71967C1.88968 7.57902 1.69891 7.5 1.5 7.5C1.30109 7.5 1.11032 7.57902 0.96967 7.71967C0.829018 7.86032 0.75 8.05109 0.75 8.25V17.25C0.75 17.8467 0.987053 18.419 1.40901 18.841C1.83097 19.2629 2.40326 19.5 3 19.5H19.5C20.096 19.4975 20.6668 19.2597 21.0883 18.8383C21.5097 18.4168 21.7475 17.846 21.75 17.25V6C21.75 5.60218 21.592 5.22064 21.3107 4.93934C21.0294 4.65804 20.6478 4.5 20.25 4.5ZM16.5 14.25H9C8.80109 14.25 8.61032 14.171 8.46967 14.0303C8.32902 13.8897 8.25 13.6989 8.25 13.5C8.25 13.3011 8.32902 13.1103 8.46967 12.9697C8.61032 12.829 8.80109 12.75 9 12.75H16.5C16.6989 12.75 16.8897 12.829 17.0303 12.9697C17.171 13.1103 17.25 13.3011 17.25 13.5C17.25 13.6989 17.171 13.8897 17.0303 14.0303C16.8897 14.171 16.6989 14.25 16.5 14.25ZM16.5 11.25H9C8.80109 11.25 8.61032 11.171 8.46967 11.0303C8.32902 10.8897 8.25 10.6989 8.25 10.5C8.25 10.3011 8.32902 10.1103 8.46967 9.96967C8.61032 9.82902 8.80109 9.75 9 9.75H16.5C16.6989 9.75 16.8897 9.82902 17.0303 9.96967C17.171 10.1103 17.25 10.3011 17.25 10.5C17.25 10.6989 17.171 10.8897 17.0303 11.0303C16.8897 11.171 16.6989 11.25 16.5 11.25Z"
        fill="currentColor"
      />
    </svg>
  );
}
