import { type SpacesApiFactory } from '../api/private';
import { ProtectedSpace } from '../space-types/protected-space.types';

type ApiSpaceDto = Awaited<ReturnType<ReturnType<typeof SpacesApiFactory>['spacesSpaceIdGet']>>['data'];
// TODO[Dmitriy Teplov] actually validate properties.
// Space
export function isValidProtectedSpace(dto: ApiSpaceDto): dto is ProtectedSpace {
  return true;
}

export function castApiProtectedSpaceDto(dto: ApiSpaceDto): ProtectedSpace {
  if (isValidProtectedSpace(dto)) {
    return dto;
  }

  throw new Error(`${JSON.stringify(dto, undefined, 2)} is not a valid Space.`);
}
