import { combine, createApi, createDomain, Domain, StoreValue } from 'effector';
import update from 'immutability-helper';
import without from 'lodash/without';
import { moveArrayItem } from './array';

const getInitialValue = () => ({});

export function createKeyValueStore<T>(name?: string, domain: Domain = createDomain()) {
  const $store = domain.store<Record<string, T | undefined>>(getInitialValue(), { name });
  const $order = domain.store<readonly string[]>([]);

  const { set, unset, reset, replace } = createApi($store, {
    set: (state, { key, value }: { key: string; value: T }) => update(state, { [key]: { $set: value } }),
    unset: (state, key: string) => update(state, { $unset: [key] }),
    reset: () => getInitialValue(),
    replace: (_, newState: StoreValue<typeof $store>) => newState,
  });

  $order
    .on(
      set.map(({ key }) => key),
      (state, key: string) => (state.includes(key) ? state : [...state, key]),
    )
    .on(unset, (state, key: string) => (state.includes(key) ? without(state, key) : state))
    .on(reset, () => [])
    .on(
      replace.map(payload => Object.keys(payload)),
      (_, newState: string[]) => newState,
    );

  const { move } = createApi($order, {
    move: (state, { key, index }: { key: string; index: number }) => {
      if (!state.includes(key)) return state;
      return moveArrayItem(state, state.indexOf(key), index) ?? state;
    },
  });

  function createItemAdapter(key: string) {
    return {
      set: set.prepend((value: T) => ({ key, value })),
      unset: unset.prepend(() => key),
      $item: $store.map(state => state[key]),
    } as const;
  }

  return {
    $store,
    set,
    unset,
    reset,
    replace,
    move,
    $list: combine({
      state: $store,
      order: $order,
    }).map(({ state, order }) => {
      if (Object.keys(state).length !== order.length) {
        console.error(`KeyValue store and order are not in sync.`);
      }

      const ordered: T[] = [];

      for (const key of order) {
        const item = state[key];

        if (item === undefined) {
          console.error(`KeyValue store doesn't contain an item with key "${key}"`);
          continue;
        }

        ordered.push(item);
      }

      return ordered as readonly T[];
    }),
    $keys: $store.map(state => Object.keys(state)),
    createItemAdapter,
  } as const;
}
