import { releaseCanvas } from '@sp/util/helpers';

type FileTypeContainer = {
  type: string;
};

export enum RESOLVED_FILE_TYPE {
  image = 'image',
  video = 'video',
  audio = 'audio',
  pdf = 'pdf',
  zip = 'zip',
  xls = 'xls',
  text = 'text',
  unknownFile = 'unknownFile',
}

export function resolveFileType(file: FileTypeContainer): RESOLVED_FILE_TYPE {
  if (file.type.startsWith('image/')) {
    return RESOLVED_FILE_TYPE.image;
  }
  if (file.type.startsWith('video/')) {
    return RESOLVED_FILE_TYPE.video;
  }
  if (file.type.startsWith('audio/')) {
    return RESOLVED_FILE_TYPE.audio;
  }
  if (file.type === 'application/pdf') {
    return RESOLVED_FILE_TYPE.pdf;
  }
  if (file.type === 'application/zip') {
    return RESOLVED_FILE_TYPE.zip;
  }
  if (
    ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'].includes(
      file.type,
    )
  ) {
    return RESOLVED_FILE_TYPE.xls;
  }
  if (file.type === 'text/plain') {
    return RESOLVED_FILE_TYPE.text;
  }
  return RESOLVED_FILE_TYPE.unknownFile;
}

export function isVideo(file: FileTypeContainer): boolean {
  return resolveFileType(file) === RESOLVED_FILE_TYPE.video;
}

export function isImage(file: FileTypeContainer): boolean {
  return resolveFileType(file) === RESOLVED_FILE_TYPE.image;
}

export function isAudio(file: FileTypeContainer): boolean {
  return resolveFileType(file) === RESOLVED_FILE_TYPE.audio;
}

export function createImage(url: string): Promise<HTMLImageElement> {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.onload = () => resolve(image);
    image.onerror = e => reject(e);
    image.crossOrigin = 'Anonymous'; // без этого с созданной картинкой не работает canvas в случае если картинка находится в другом домене
    image.src = url;
  });
}

export function createVideo(url: string): Promise<HTMLVideoElement> {
  return new Promise((resolve, reject) => {
    const video = document.createElement('video');
    video.onloadedmetadata = () => resolve(video);
    video.onerror = e => reject(e);
    video.src = url;
    video.load();
  });
}

export function createAudio(url: string): Promise<HTMLAudioElement> {
  return new Promise((resolve, reject) => {
    const audio = document.createElement('audio');
    audio.onloadedmetadata = () => resolve(audio);
    audio.onerror = e => reject(e);
    audio.src = url;
    audio.load();
  });
}

export function dataURItoBlob(dataURI: string): Blob {
  // convert base64/URLEncoded data component to raw binary data held in a string
  let byteString;
  if (dataURI.split(',')[0].indexOf('base64') >= 0) byteString = atob(dataURI.split(',')[1]);
  else byteString = unescape(dataURI.split(',')[1]);

  // separate out the mime component
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to a typed array
  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ia], { type: mimeString });
}

// Video preview
export async function getVideoPreviewDataURI(file: File): Promise<string> {
  if (!isVideo(file)) {
    throw new Error(`File ${file.name} is not video`);
  }

  const video = await createVideo(URL.createObjectURL(file));
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  canvas.width = video.videoWidth;
  canvas.height = video.videoHeight;

  if (!ctx) {
    throw new Error('Canvas context is not available');
  }

  return new Promise<string>(resolve => {
    video.onseeked = () => {
      ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
      const dataUrl = canvas.toDataURL('image/jpeg', 1.0);
      releaseCanvas(canvas);
      resolve(dataUrl);
    };
    video.currentTime = video.duration < 1 ? video.duration : 1;
  });
}

// Media metadata
export async function getAudioMeta(file: File): Promise<{
  readonly duration: number;
  size: number;
  objectURL: string;
}> {
  if (!isAudio(file)) {
    throw new Error(`File ${file.name} is not audio`);
  }
  const objectURL = URL.createObjectURL(file);
  const audio = await createAudio(objectURL);

  // бывают случаи когда длительность не определена сразу и равна Infinity
  // но нормально рассчитывается если установить заведомо огромный currentTime
  if (audio.duration === Infinity) {
    return new Promise(resolve => {
      audio.ontimeupdate = () => resolve({ duration: audio.duration, size: file.size, objectURL });
      audio.currentTime = 1e101;
    });
  }

  return { duration: audio.duration, size: file.size, objectURL };
}

export async function getVideoMeta(file: File): Promise<{
  readonly duration: number;
  readonly width: number;
  readonly height: number;
  size: number;
  objectURL: string;
}> {
  if (!isVideo(file)) {
    throw new Error(`File ${file.name} is not video`);
  }
  const objectURL = URL.createObjectURL(file);
  const video = await createVideo(objectURL);
  return { duration: video.duration, width: video.videoWidth, height: video.videoHeight, size: file.size, objectURL };
}

// Image crop
function getRadianAngle(degreeValue: number) {
  return (degreeValue * Math.PI) / 180;
}

function rotateSize(width: number, height: number, rotation: number) {
  const rotRad = getRadianAngle(rotation);

  return {
    width: Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
    height: Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
  };
}

export async function getCroppedImg(
  imageSrc: string,
  pixelCrop: {
    width: number;
    height: number;
    x: number;
    y: number;
  },
  rotation = 0,
  flip = { horizontal: false, vertical: false },
): Promise<string> {
  const image = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    return Promise.reject();
  }

  const rotRad = getRadianAngle(rotation);

  // calculate bounding box of the rotated image
  const { width: bBoxWidth, height: bBoxHeight } = rotateSize(image.width, image.height, rotation);

  // set canvas size to match the bounding box
  canvas.width = bBoxWidth;
  canvas.height = bBoxHeight;

  // translate canvas context to a central location to allow rotating and flipping around the center
  ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
  ctx.rotate(rotRad);
  ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
  ctx.translate(-image.width / 2, -image.height / 2);

  // draw rotated image
  ctx.drawImage(image, 0, 0);

  // croppedAreaPixels values are bounding box relative
  // extract the cropped image using these values
  const data = ctx.getImageData(pixelCrop.x, pixelCrop.y, pixelCrop.width, pixelCrop.height);

  // set canvas width to final desired crop size - this will clear existing context
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  // paste generated rotate image at the top left corner
  ctx.putImageData(data, 0, 0);

  // As Base64 string
  const dataUrl = canvas.toDataURL('image/jpeg');
  releaseCanvas(canvas);
  return dataUrl;
}

export async function getAudioAbsolutePeaks(
  file: File,
  options?: { chanel?: number; normalize?: boolean; frequency?: number; maxPeaks?: number; accuracy?: number },
): Promise<number[]> {
  if (!isAudio(file)) {
    throw new Error(`File ${file.name} is not audio`);
  }

  const { chanel, normalize, frequency, maxPeaks, accuracy } = {
    chanel: 0,
    normalize: true,
    frequency: 30,
    maxPeaks: 200,
    accuracy: 100,
    ...options,
  };

  const audioCtx = new AudioContext();
  const buffer = await file.arrayBuffer();
  const decodedBuffer = await audioCtx.decodeAudioData(buffer);

  const data = decodedBuffer.getChannelData(chanel);
  const resultLength = Math.min(maxPeaks, Math.floor(decodedBuffer.duration * frequency));
  const floatArray = new Float32Array(resultLength);
  const sampleStep = Math.floor(decodedBuffer.length / resultLength);
  let sample = 0;

  for (let i = 0; i < resultLength; i++) {
    floatArray[i] = Math.abs(data[sample]);
    sample += sampleStep;
  }

  const max = Math.max(...floatArray);
  const normalized = floatArray.map(value => (normalize ? value / max : value));

  const result = new Array(resultLength);
  for (let i = 0; i < resultLength; i++) {
    result[i] = Math.round(normalized[i] * accuracy) / accuracy;
  }

  return result;
}

export const GRAY_PIXEL_IMG_SRC = 'data:image/gif;base64,R0lGODlhAQABAIAAAMLCwgAAACH5BAAAAAAALAAAAAABAAEAAAICRAEAOw==';
