import { ReactElement } from 'react';
import { ICON_DEFAULTS, IconProps } from './types';

export function IconHourglass({
  size = ICON_DEFAULTS.size,
  className = ICON_DEFAULTS.className,
}: IconProps): ReactElement {
  return (
    <svg
      width={size}
      height={size}
      className={className}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 8L4.2 5.15C4.13826 5.1031 4.08811 5.04263 4.05344 4.97328C4.01876 4.90393 4.00048 4.82754 4 4.75V2.5C4 2.36739 4.05268 2.24021 4.14645 2.14645C4.24021 2.05268 4.36739 2 4.5 2H11.5C11.6326 2 11.7598 2.05268 11.8536 2.14645C11.9473 2.24021 12 2.36739 12 2.5V4.725C11.9995 4.80254 11.9812 4.87893 11.9466 4.94828C11.9119 5.01763 11.8617 5.0781 11.8 5.125L8 8Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 8L4.2 10.85C4.13826 10.8969 4.08811 10.9574 4.05344 11.0267C4.01876 11.0961 4.00048 11.1725 4 11.25V13.5C4 13.6326 4.05268 13.7598 4.14645 13.8536C4.24021 13.9473 4.36739 14 4.5 14H11.5C11.6326 14 11.7598 13.9473 11.8536 13.8536C11.9473 13.7598 12 13.6326 12 13.5V11.275C11.9995 11.1975 11.9812 11.1211 11.9466 11.0517C11.9119 10.9824 11.8617 10.9219 11.8 10.875L8 8Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M11.3064 5.5H4.66895L8 8L11.3064 5.5Z" fill="currentColor" />
    </svg>
  );
}
