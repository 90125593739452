import { OwnedChallengeSpace, SPACE_STATUS, uploadFileToAWS } from '@sp/data/bif';
import { optimizeImage } from '@sp/util/files';
import { isNotNullish, ShallowNonNullable, snd } from '@sp/util/helpers';
import { add, addDays, getHours, getMinutes, startOfDay, subDays } from 'date-fns';
import { combine, createDomain, forward, merge, sample } from 'effector';
import update from 'immutability-helper';
import orderBy from 'lodash/orderBy';
import uniq from 'lodash/uniq';
import { CHALLENGE_EDITOR_MODEL } from './challenge-editor-model';
import { CreateChallengeDto, Post, PostMapKeys, PostsMap, SaveChallengeDto, Seed, Step, StepsList } from './types';
import {
  fieldReducer,
  getNewChallengeStartAt,
  getPostKey,
  getPostType,
  getStepSeed,
  isDeferredPost,
  isExistingStep,
  isNotSeed,
  postToUpdatePostDto,
  stepToUpdateStepDto,
} from './utils';

const domain = createDomain('challenge-form-model');

const existsChallengeUpdated = domain.createEvent<OwnedChallengeSpace>();
const editorClosed = merge([
  CHALLENGE_EDITOR_MODEL.ChallengeEditorGate.close,
  CHALLENGE_EDITOR_MODEL.ChallengeCreationGate.close,
]);

const createChallengeStarted = domain.createEvent();
const saveChallengeStarted = domain.createEvent();
const publishChallengeStarted = domain.createEvent();

const titleChanged = domain.createEvent<string>();
const startAtChanged = domain.createEvent<number>();
const stepsCountChanged = domain.createEvent<number>();
const slugChanged = domain.createEvent<string>();
const priceChanged = domain.createEvent<number>();
const finishAtChanged = domain.createEvent<number>();
const updateCoverStarted = domain.createEvent<File>();
const offerDescriptionChanged = domain.createEvent<string>();
const offerSummaryChanged = domain.createEvent<string>();
const isProgramChanged = domain.createEvent<boolean>();

const stepTitleChanged = domain.createEvent<{ stepIndex: number; title: string }>();
const stepStartTimeChanged = domain.createEvent<{ stepIndex: number; startTime: Step['startTime'] }>();
const stepEstimatedEffortChanged = domain.createEvent<{ stepIndex: number; estimatedEffort: number }>();
const stepIsSignificantChanged = domain.createEvent<{ stepIndex: number; isSignificant: boolean }>();
const stepAdded = domain.createEvent<number>();
const stepRemoved = domain.createEvent<number>();

const postAdded = domain.createEvent<{ stepKey: PostMapKeys; post: Post | Seed<Post> }>();
const postRemoved = domain.createEvent<{ stepKey: PostMapKeys; postIndex: number }>();
const postUpdated = domain.createEvent<{ stepKey: PostMapKeys; postIndex: number; post: Post | Seed<Post> }>();
const postMoved = domain.createEvent<{ stepKey: PostMapKeys; postIndex: number; direction: 'up' | 'down' }>();
const postMovedToStep = domain.createEvent<{ stepKey: PostMapKeys; postIndex: number; nextStepKey: PostMapKeys }>();

const updateCoverFx = domain.createEffect(async (file: File) => {
  const { url, id } = await uploadFileToAWS(await optimizeImage(file));
  return { url, id };
});

// Challenge fields stores
const $title = domain
  .createStore<string>('')
  .on(titleChanged, fieldReducer)
  .on(CHALLENGE_EDITOR_MODEL.ChallengeCreationGate.open, () => 'Challenge title')
  .reset(editorClosed);
const $startAt = domain
  .createStore<number>(Date.now())
  .on(startAtChanged, fieldReducer)
  .on(CHALLENGE_EDITOR_MODEL.ChallengeCreationGate.open, () => getNewChallengeStartAt())
  .reset(merge([CHALLENGE_EDITOR_MODEL.ChallengeEditorGate.close, CHALLENGE_EDITOR_MODEL.ChallengeCreationGate.close]));
const $stepsCount = domain
  .createStore<number>(0)
  .on(stepsCountChanged, fieldReducer)
  .on(CHALLENGE_EDITOR_MODEL.ChallengeCreationGate.open, () => 2)
  .reset(editorClosed);
const $slug = domain
  .createStore<string>('')
  .on(slugChanged, fieldReducer)
  .on(CHALLENGE_EDITOR_MODEL.ChallengeCreationGate.open, () => '')
  .reset(editorClosed);
const $price = domain
  .createStore<number>(0)
  .on(priceChanged, fieldReducer)
  .on(CHALLENGE_EDITOR_MODEL.ChallengeCreationGate.open, () => 1000)
  .reset(editorClosed);
const $cover = domain
  .createStore<OwnedChallengeSpace['cover']>(null)
  .on(updateCoverFx.doneData, fieldReducer)
  .reset(updateCoverStarted)
  .reset(editorClosed);
const $finishAt = domain
  .createStore<number>(0)
  .on(finishAtChanged, fieldReducer)
  .on(stepAdded, finishAt => addDays(finishAt, 1).getTime())
  .on(stepRemoved, finishAt => subDays(finishAt, 1).getTime())
  .reset(editorClosed);

const $offerDescription = domain
  .createStore<string>('')
  .on(offerDescriptionChanged, (_, v) => v)
  .reset(editorClosed);
const $offerSummary = domain.createStore<string>('').on(offerSummaryChanged, (_, v) => v);

const $isProgram = domain.createStore<boolean>(false).on(isProgramChanged, snd).reset(editorClosed);

sample({
  source: combine({ finishAt: $finishAt, stepsCount: $stepsCount, startAt: $startAt }),
  clock: [startAtChanged, stepsCountChanged],
  fn: ({ finishAt, stepsCount, startAt }) => {
    const shiftedFinishAt = startOfDay(addDays(startAt, stepsCount)).getTime();
    const finishPublishTime = finishAt - startOfDay(finishAt).getTime();
    return shiftedFinishAt + finishPublishTime;
  },
  target: finishAtChanged,
});

//Steps store
const $steps = domain
  .createStore<StepsList>([])
  .on(CHALLENGE_EDITOR_MODEL.$steps, (_, steps) =>
    orderBy(steps, 'startAt').map(({ id, title, isSignificant, estimatedEffort, startAt }) => ({
      id,
      title,
      isSignificant,
      estimatedEffort,
      startTime: { hours: getHours(startAt), minutes: getMinutes(startAt) },
    })),
  )
  .on(stepTitleChanged, (steps, { stepIndex, title }) => update(steps, { [stepIndex]: { title: { $set: title } } }))
  .on(stepStartTimeChanged, (steps, { stepIndex, startTime }) =>
    update(steps, { [stepIndex]: { $merge: { startTime } } }),
  )
  .on(stepEstimatedEffortChanged, (steps, { stepIndex, estimatedEffort }) =>
    update(steps, { [stepIndex]: { $merge: { estimatedEffort } } }),
  )
  .on(stepIsSignificantChanged, (steps, { stepIndex, isSignificant }) =>
    update(steps, { [stepIndex]: { $merge: { isSignificant } } }),
  )
  .on(stepAdded, (steps, stepIndex) => update(steps, { $splice: [[stepIndex + 1, 0, getStepSeed()]] }))
  .on(stepRemoved, (steps, stepIndex) => update(steps, { $splice: [[stepIndex, 1]] }))
  .on(stepTitleChanged, (steps, { stepIndex, title }) => update(steps, { [stepIndex]: { title: { $set: title } } }))
  .reset(editorClosed);

//Posts map store
const $postsMap = domain
  .createStore<PostsMap>({ intro: [], finish: [], deferred: [] })
  .on(CHALLENGE_EDITOR_MODEL.$posts, (_, posts) => {
    const uniqKeys: PostMapKeys[] = uniq(
      posts.map(post => getPostKey(post)).filter(key => typeof key === 'string' || !isNaN(key)),
    );
    return uniqKeys.reduce(
      (acc, key) => ({
        ...acc,
        [key]: orderBy(
          posts.filter(post => getPostKey(post) === key),
          ({ order, publishAt }) => (key === 'deferred' ? publishAt : order),
        ).map(({ id, content, type, publishAt }) => ({
          id,
          content,
          type,
          publishAt,
        })),
      }),
      { intro: [], finish: [], deferred: [] } as PostsMap,
    );
  })
  .on(postAdded, (postsMap, { stepKey, post }) => ({ ...postsMap, [stepKey]: [...(postsMap[stepKey] ?? []), post] }))
  .on(postRemoved, (postsMap, { stepKey, postIndex }) => ({
    ...postsMap,
    [stepKey]: (postsMap[stepKey] ?? []).filter((_, index) => index !== postIndex),
  }))
  .on(postUpdated, (postsMap, { stepKey, postIndex, post }) => {
    if (isNotNullish(postsMap[stepKey]) && isNotNullish(postsMap[stepKey][postIndex])) {
      let newPosts = update(postsMap[stepKey] ?? [], { [postIndex]: { $set: post } });
      if (stepKey === 'deferred') {
        newPosts = orderBy(newPosts, 'publishAt');
      }
      return {
        ...postsMap,
        [stepKey]: newPosts,
      };
    }

    return postsMap;
  })
  .on(postMoved, (postsMap, { stepKey, postIndex, direction }) => {
    const posts = postsMap[stepKey] ?? [];
    if (direction === 'up' && postIndex > 0 && isNotNullish(posts[postIndex - 1]) && isNotNullish(posts[postIndex])) {
      return {
        ...postsMap,
        [stepKey]: update(posts, {
          [postIndex]: { $set: posts[postIndex - 1] },
          [postIndex - 1]: { $set: posts[postIndex] },
        }),
      };
    }
    if (
      direction === 'down' &&
      postIndex < posts.length - 1 &&
      isNotNullish(posts[postIndex + 1]) &&
      isNotNullish(posts[postIndex])
    ) {
      return {
        ...postsMap,
        [stepKey]: update(posts, {
          [postIndex]: { $set: posts[postIndex + 1] },
          [postIndex + 1]: { $set: posts[postIndex] },
        }),
      };
    }
    return postsMap;
  })
  .on(stepRemoved, (postsMap, stepKey) => update(postsMap, { [stepKey]: { $set: [] } }))
  .on(postMovedToStep, (postsMap, { stepKey, postIndex, nextStepKey }) => {
    if (isNotNullish(postsMap[stepKey] && postsMap[stepKey][postIndex])) {
      const post = { ...postsMap[stepKey][postIndex], type: getPostType(nextStepKey) };
      if (isDeferredPost(post)) {
        post.publishAt = addDays(new Date(), 1).getTime();
      }
      let newPosts = [...(postsMap[nextStepKey] ?? []), post];
      if (nextStepKey === 'deferred') {
        newPosts = orderBy(newPosts, 'publishAt');
      }
      return {
        ...postsMap,
        [nextStepKey]: newPosts,
        [stepKey]: update(postsMap[stepKey], { $splice: [[postIndex, 1]] }),
      };
    }
    return postsMap;
  })
  .reset(editorClosed);

const $owner = CHALLENGE_EDITOR_MODEL.$challenge.map(challenge => challenge?.owner ?? null);

const $coverUploadingInProgress = updateCoverFx.pending;

const $isExistsChallenge = CHALLENGE_EDITOR_MODEL.$challenge.map(isNotNullish);
const $isChallengePublished = CHALLENGE_EDITOR_MODEL.$challenge.map(
  challenge => challenge?.status === SPACE_STATUS.published,
);
const $isCanCreateChallenge = combine(
  $title,
  $cover,
  $stepsCount,
  $slug,
  $price,
  (title, cover, stepsCount, slug, price) => {
    return title !== '' && isNotNullish(cover) && stepsCount > 0 && slug !== '' && price > 0;
  },
);

sample({
  clock: CHALLENGE_EDITOR_MODEL.$challenge,
  target: existsChallengeUpdated,
  filter: isNotNullish,
});

forward({ from: existsChallengeUpdated.map(({ title }) => title), to: $title });
forward({ from: existsChallengeUpdated.map(({ slug }) => slug), to: $slug });
forward({ from: existsChallengeUpdated.map(({ price }) => price), to: $price });
forward({ from: existsChallengeUpdated.map(({ cover }) => cover), to: $cover });

forward({
  from: existsChallengeUpdated.map(space => space.config.challenge.offerDescription ?? ''),
  to: $offerDescription,
});

forward({
  from: existsChallengeUpdated.map(space => space.config.challenge.isProgram ?? false),
  to: $isProgram,
});

forward({
  from: existsChallengeUpdated.map(space => space.config.challenge.offerSummary ?? ''),
  to: $offerSummary,
});

forward({
  from: CHALLENGE_EDITOR_MODEL.$steps.map(steps => steps[0]?.startAt ?? 0),
  to: $startAt,
});
forward({ from: $steps.map(steps => steps.length), to: $stepsCount });
forward({ from: existsChallengeUpdated.map(({ config }) => config.challenge.finishTimestamp), to: $finishAt });

forward({ from: updateCoverStarted, to: updateCoverFx });

type CreateChallengeFields = {
  title: string;
  startAt: number;
  stepsCount: number;
  slug: string;
  price: number;
  cover: OwnedChallengeSpace['cover'];
  offerDescription: string;
  offerSummary: string;
  isProgram: boolean;
};

sample({
  clock: createChallengeStarted,
  source: combine({
    title: $title,
    startAt: $startAt,
    stepsCount: $stepsCount,
    slug: $slug,
    price: $price,
    cover: $cover,
    offerDescription: $offerDescription,
    offerSummary: $offerSummary,
    isProgram: $isProgram,
  }),
  filter: (data: CreateChallengeFields): data is ShallowNonNullable<CreateChallengeFields> => isNotNullish(data.cover),
  target: CHALLENGE_EDITOR_MODEL.createChallengeStarted,
  fn: ({
    title,
    startAt,
    stepsCount,
    slug,
    price,
    cover,
    offerDescription,
    offerSummary,
    isProgram,
  }): CreateChallengeDto => ({
    title,
    startAt,
    stepsCount,
    slug,
    price,
    coverFileId: cover.id,
    offerDescription,
    offerSummary,
    isProgram,
  }),
});

type UpdateChallengeFields = {
  prevState: OwnedChallengeSpace | null;
  title: string;
  startAt: number;
  finishAt: number;
  slug: string;
  price: number;
  cover: OwnedChallengeSpace['cover'];
  offerDescription: string;
  offerSummary: string;
  isProgram: boolean;
  stepsList: StepsList;
  postsMap: PostsMap;
};
sample({
  clock: merge([saveChallengeStarted.map(() => false), publishChallengeStarted.map(() => true)]),
  source: combine({
    prevState: CHALLENGE_EDITOR_MODEL.$challenge,
    title: $title,
    startAt: $startAt,
    finishAt: $finishAt,
    slug: $slug,
    price: $price,
    cover: $cover,
    offerDescription: $offerDescription,
    offerSummary: $offerSummary,
    isProgram: $isProgram,
    stepsList: $steps,
    postsMap: $postsMap,
  }),
  filter: (data: UpdateChallengeFields): data is ShallowNonNullable<UpdateChallengeFields> =>
    isNotNullish(data.prevState),
  fn: (
    {
      prevState,
      title,
      startAt,
      finishAt,
      slug,
      price,
      cover,
      offerDescription,
      offerSummary,
      isProgram,
      stepsList,
      postsMap,
    },
    needPublish,
  ): SaveChallengeDto => {
    const steps = stepsList.map((step, index) => stepToUpdateStepDto(step, addDays(startAt, index).getTime()));
    const posts = [
      ...postsMap.intro.map((post, index) => postToUpdatePostDto(post, 'intro', index + 1)),
      ...steps
        .filter(isExistingStep)
        .map(({ id, startAt }) =>
          (postsMap[id] ?? []).map((post, index) => postToUpdatePostDto(post, id, index + 1, startAt)),
        )
        .flat(),
      ...postsMap.finish.map((post, index) => postToUpdatePostDto(post, 'finish', index + 1, finishAt)),
      ...postsMap.deferred.map((post, index) => postToUpdatePostDto(post, 'deferred', index + 1)),
    ];
    return {
      challenge: {
        id: prevState.id,
        status: needPublish && prevState.status !== SPACE_STATUS.published ? SPACE_STATUS.published : prevState.status,
        config: {
          challenge: {
            finishTimestamp: add(startOfDay(startAt), {
              days: steps.length,
              hours: getHours(finishAt),
              minutes: getMinutes(finishAt),
            }).getTime(),
            offerDescription,
            offerSummary,
            isProgram,
          },
        },
        slug,
        price,
        cover,
        title,
      },
      steps,
      posts,
    };
  },
  target: CHALLENGE_EDITOR_MODEL.saveChallengeStarted,
});
sample({
  clock: $steps,
  filter: steps => steps.some(s => !isNotSeed(s)),
  target: saveChallengeStarted,
});

export const CHALLENGE_FORM_MODEL = {
  $title,
  $startAt,
  $stepsCount,
  $slug,
  $price,
  $cover,
  $steps,
  $postsMap,
  $finishAt,
  $owner,
  $offerDescription,
  $offerSummary,
  $isProgram,

  createChallengeStarted,
  saveChallengeStarted,
  publishChallengeStarted,

  titleChanged,
  startAtChanged,
  stepsCountChanged,
  slugChanged,
  priceChanged,
  finishAtChanged,
  updateCoverStarted,
  offerDescriptionChanged,
  offerSummaryChanged,
  isProgramChanged,

  stepStartTimeChanged,
  stepTitleChanged,
  stepEstimatedEffortChanged,
  stepIsSignificantChanged,
  stepAdded,
  stepRemoved,

  postAdded,
  postRemoved,
  postUpdated,
  postMoved,
  postMovedToStep,

  $coverUploadingInProgress,
  $isExistsChallenge,
  $isChallengePublished,
  $isCanCreateChallenge,
};
