import { SpaceCreator } from '@sp/data/bif';
import { SELFPLUS_ADMINS_MODEL } from '@sp/data/global';
import { IconCircleWavyCheck } from '@sp/ui/icons';
import { ChatFormatMessage } from '@sp/util/stream-chat';
import { useStore } from 'effector-react';
import { useCallback } from 'react';

export const UseSpacialBadge = (creator: SpaceCreator | null) => {
  const selfplusAdmins = useStore(SELFPLUS_ADMINS_MODEL.$data);
  const specialBadgeContent = useCallback(
    (user: ChatFormatMessage['user']) => {
      const userId = Number(user?.id);
      if (isNaN(userId)) return null;

      if (userId === creator?.id || selfplusAdmins?.find(admin => userId === admin.id)) {
        return <IconCircleWavyCheck size={16} className="text-brand self-center" />;
      }

      return null;
    },
    [creator?.id, selfplusAdmins],
  );
  return [specialBadgeContent];
};
