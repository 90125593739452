import { IS_IOS } from '@sp/data/env';
import { GlobalMediaPlayerModel } from '@sp/data/global';
import { ChatChannelModel } from '@sp/feature/stream-chat';
import { LoaderBlock } from '@sp/ui/elements';
import { CaretDown } from '@sp/ui/icons';
import { isNotNullish, Nullable } from '@sp/util/helpers';
import { ChatFormatMessage } from '@sp/util/stream-chat';
import { stopScroll, useResponsiveViewPort } from '@sp/util/viewport';
import { useStore } from 'effector-react';
import debounce from 'lodash/debounce';
import { FC, memo, RefObject, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { ChatActiveAudioPanel } from './chat-active-audio-panel';
import { ChatMessageListItem } from './chat-message-list-item';
import { DaySeparator } from './day-separator';
import { NewMessagesSeparator } from './new-messages-separator';
import { RawItem, RawItemType, useMessageListItems } from './use-message-list-items';

const BOTTOM_THRESHOLD = 300;

const observersUnobserve = (
  elem: Nullable<HTMLDivElement>,
  observers: Record<string, Nullable<IntersectionObserver>>,
) => {
  if (!elem) return;
  Object.values(observers).forEach(obs => {
    obs?.unobserve(elem);
  });
};

function scrollToMessage(container: Element, highlightedMessageId: string, afterScroll: () => void, highlight = true) {
  const selector = `[data-message-id="${highlightedMessageId}"]`;
  const message = container.querySelector<HTMLDivElement>(selector);
  if (message) {
    message.scrollIntoView({ block: 'nearest' });
    const child = message.children.item(0);
    if (!child) return;
    highlight && child.classList.add('highlighted');
    afterScroll();
    setTimeout(() => {
      highlight && child.classList.remove('highlighted');
    }, 500);
  }
}

function scrollToBottom(container: Nullable<HTMLDivElement>) {
  if (container) {
    stopScroll(container);
    container.scrollTop = container.scrollHeight;
  }
}

const Scroller: FC<{
  model: ChatChannelModel;
  containerRef: RefObject<HTMLDivElement>;
  atBottom?: (bottom: boolean) => void;
  atTop?: (bottom: boolean) => void;
  onShowMessageActions: (m: ChatFormatMessage) => void;
}> = ({ model, containerRef, atBottom, atTop, onShowMessageActions }) => {
  const messages = useStore(model.$messages);
  const [yOffset, setYOffset] = useState(0);
  const highlightedMessageId = useStore(model.$highlightedMessageId);
  const [shouldScroll, setShouldScroll] = useState(false);
  const [isScroll, setIsScroll] = useState(true);
  const lastRead = useStore(model.$lastRead);
  const lastReadAtEnter = useRef(lastRead).current;
  const rawItems = useMessageListItems(messages, lastReadAtEnter);
  const [itemsToRender, setItemsToRender] = useState(rawItems);
  const hasMoreNewer = useStore(model.$hasMoreNewer);
  const fakeTopGroup = useRef(null);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loadPreviousMessages = useCallback(
    debounce(() => model.loadPreviousMessages(), 500, { leading: true, trailing: false }),
    [model.loadPreviousMessages],
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loadNewerMessages = useCallback(
    debounce(() => model.loadNewerMessages(), 500, { leading: true, trailing: false }),
    [model.loadPreviousMessages],
  );

  // Scroll to highlighted message
  useLayoutEffect(() => {
    if (!highlightedMessageId || !containerRef.current) return;
    if (highlightedMessageId === 'latest') {
      // wait until media will render
      setTimeout(() => scrollToBottom(containerRef.current), 50);
      return;
    }
    if (highlightedMessageId) {
      scrollToMessage(containerRef.current, highlightedMessageId, model.highlightedMessageIdReset);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [highlightedMessageId, itemsToRender]);

  const latestMessageId = useRef(messages[messages.length - 1]?.id ?? null);

  // Scroll to new message
  useLayoutEffect(() => {
    const nextLatestMessageId = messages[messages.length - 1]?.id ?? null;
    // Only scroll if it's a different message.
    if (latestMessageId.current === nextLatestMessageId) return;
    latestMessageId.current = nextLatestMessageId;

    if (!hasMoreNewer && containerRef.current && !shouldScroll) {
      const fromBottom =
        containerRef.current.scrollHeight - containerRef.current.scrollTop - containerRef.current.offsetHeight;
      if (fromBottom <= BOTTOM_THRESHOLD) {
        scrollToBottom(containerRef.current);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemsToRender]);

  useEffect(() => {
    setItemsToRender(rawItems);
    if (containerRef.current) {
      setYOffset(containerRef.current.scrollHeight - containerRef.current.scrollTop);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rawItems]);

  // Scroll on load prev messages
  useLayoutEffect(() => {
    if (containerRef.current && shouldScroll) {
      stopScroll(containerRef.current);
      containerRef.current.scrollTo({ top: containerRef.current.scrollHeight - yOffset });
      setYOffset(0);
      setShouldScroll(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemsToRender]);

  const topObserver = useMemo(() => {
    if (containerRef.current) {
      return new IntersectionObserver(
        entries => {
          if (entries[0].isIntersecting) {
            setShouldScroll(true);
            loadPreviousMessages();
            atTop?.(true);
          } else {
            atTop?.(false);
          }
        },
        { root: containerRef.current, threshold: 0.1 },
      );
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerRef.current]);

  const bottomObserver = useMemo(() => {
    if (containerRef.current) {
      return new IntersectionObserver(
        entries => {
          if (entries[0].isIntersecting) {
            loadNewerMessages();
            atBottom?.(true);
          } else {
            atBottom?.(false);
          }
        },
        { root: containerRef.current, threshold: 0 },
      );
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerRef.current]);

  const groupObserver = useMemo(() => {
    if (containerRef.current) {
      return new IntersectionObserver(
        entries => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              entry.target.classList.add('into-view');
            } else {
              entry.target.classList.remove('into-view');
            }
          });

          containerRef.current?.querySelectorAll('.into-view').forEach((el, i) => {
            if (i === 0) {
              el.classList.add('stuck');
            } else {
              el.classList.remove('stuck');
            }
          });
        },
        { root: containerRef.current, threshold: 0 },
      );
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerRef.current]);

  const messageObserver = useMemo(() => {
    if (containerRef.current) {
      return new IntersectionObserver(
        entries => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              const id = entry.target.getAttribute('data-message-id');
              if (isNotNullish(id)) {
                model.updateLastRead(id);
              }
            }
          });
        },
        { root: containerRef.current, threshold: 0.01 },
      );
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerRef.current, model]);

  useEffect(
    () => () => {
      topObserver?.disconnect();
      bottomObserver?.disconnect();
      messageObserver?.disconnect();
      groupObserver?.disconnect();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const scrollHandlerOn = useMemo(
    () =>
      debounce(
        () => {
          setIsScroll(true);
        },
        500,
        { trailing: false, leading: true },
      ),
    [],
  );

  const scrollHandlerOff = useMemo(
    () =>
      debounce(
        () => {
          setIsScroll(false);
        },
        500,
        { trailing: true, leading: false },
      ),
    [],
  );

  useEffect(() => {
    if (isScroll) {
      document.body.classList.add('chat-scrolling');
    } else {
      document.body.classList.remove('chat-scrolling');
    }
    return () => document.body.classList.remove('chat-scrolling');
  }, [isScroll]);
  const observers = useMemo(
    () => ({ top: topObserver, bottom: bottomObserver, group: groupObserver, message: messageObserver }),
    [bottomObserver, groupObserver, messageObserver, topObserver],
  );
  useEffect(() => {
    if (fakeTopGroup.current) {
      groupObserver?.observe(fakeTopGroup.current);
    }
  }, [groupObserver]);

  return (
    <div
      onScroll={() => {
        scrollHandlerOn();
        scrollHandlerOff();
      }}
      ref={containerRef}
      className="absolute flex flex-col chat-scroller left-0 top-0 w-full h-full overflow-y-scroll mobile-pan will-change-scroll overscroll-contain"
    >
      <div ref={fakeTopGroup} className="h-[1px]"></div>
      {itemsToRender.map((group, index) => (
        <ChatItemsGroup
          onLongPress={onShowMessageActions}
          observers={observers}
          key={group[0].key}
          group={group}
          groupsCount={itemsToRender.length}
          index={rawItems.length - 1 === index ? 'last' : index}
          model={model}
        />
      ))}
      {/*Всегда велючаем эластик скролл для IOs (на андроиде нет эластик скролла)*/}
      {IS_IOS && <div className="flex-1 after:block after:h-full after:m-[1px]"></div>}
    </div>
  );
};

const ChatItemsGroup: FC<{
  group: RawItem[];
  groupsCount: number;
  index: number | 'last';
  model: ChatChannelModel;
  observers: {
    top: Nullable<IntersectionObserver>;
    bottom: Nullable<IntersectionObserver>;
    group: Nullable<IntersectionObserver>;
    message: Nullable<IntersectionObserver>;
  };
  onLongPress: (message: ChatFormatMessage) => void;
}> = memo(function ChatItemsGroup({ group, model, index, groupsCount, observers, onLongPress }) {
  const groupContainer = useRef<HTMLDivElement>(null);
  const getObserverPos = useCallback(
    (itemIndex: number | 'last', groupIndex: number | 'last'): 'top' | 'bottom' | null => {
      if (
        (itemIndex === 1 || (itemIndex === 'last' && group.length === 2)) &&
        (groupsCount === 1 || groupIndex === 0)
      ) {
        return 'top';
      }
      if (itemIndex === 'last' && groupIndex === 'last') return 'bottom';
      return null;
    },
    [group.length, groupsCount],
  );

  useEffect(() => {
    if (groupContainer.current && observers.group) {
      observers.group.observe(groupContainer.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [observers.group, groupContainer.current]);

  const itemObservers = useMemo(
    () => ({ top: observers.top, bottom: observers.bottom, message: observers.message }),
    [observers.bottom, observers.message, observers.top],
  );

  return (
    <div ref={groupContainer} className="relative">
      {group.map((item, i) => {
        return (
          <ChatItem
            onLongPress={onLongPress}
            observers={itemObservers}
            observerPos={getObserverPos(i === group.length - 1 ? 'last' : i, index)}
            key={item.key}
            item={item}
            model={model}
          />
        );
      })}
    </div>
  );
});

const ChatItem: FC<{
  item: RawItem;
  model: ChatChannelModel;
  onLongPress: (message: ChatFormatMessage) => void;
  observers: {
    top: Nullable<IntersectionObserver>;
    bottom: Nullable<IntersectionObserver>;
    message: Nullable<IntersectionObserver>;
  };
  observerPos: 'top' | 'bottom' | null;
}> = ({ item, model, observers, observerPos, onLongPress }) => {
  const elem = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!elem.current) return;

    const element = elem.current;

    if (observerPos === 'top') {
      observers.top?.observe(element);
    }
    if (observerPos === 'bottom') {
      observers.bottom?.observe(element);
    }
    return () => observersUnobserve(element, { top: observers.top, bottom: observers.bottom });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [observerPos, observers.bottom, observers.top, elem.current]);

  useLayoutEffect(() => {
    if (!elem.current) return;
    const element = elem.current;
    observers.message?.observe(element);
    return () => observers.message?.unobserve(element);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [observers.message, elem.current]);

  switch (item.type) {
    case RawItemType.message:
      return (
        <div data-message-id={item.message.id} ref={elem}>
          <ChatMessageListItem onLongPress={onLongPress} message={item.message} model={model} />
        </div>
      );
    case RawItemType.newMessagesSeparator:
      return (
        <div className="py-2">
          <NewMessagesSeparator />
        </div>
      );
    case RawItemType.daySeparator:
      return (
        <div ref={elem} className="sticky day-separator-wrapper flex justify-center z-20 top-3 mx-16 py-3">
          <div
            className="px-2 py-1
            w-fit
            bg-primary  text-primary rounded-lg bg-opacity-90
            duration-200 shadow-white transition ease-out opacity-100 day-separator"
          >
            <DaySeparator timestamp={item.timestamp} />
          </div>
        </div>
      );
  }
};

export const ChatMessageList = ({
  model,
  onShowMessageActions,
}: Readonly<{
  model: ChatChannelModel;
  onShowMessageActions: (message: ChatFormatMessage) => void;
}>) => {
  const [atBottom, setAtBottom] = useState(false);
  const [atTop, setAtTop] = useState(false);
  const wrapper = useRef<HTMLDivElement>(null);
  const [showBottomBtn, setShowBottomBtn] = useState(false);

  const messages = useStore(model.$messages);

  const hasMorePrevious = useStore(model.$hasMorePrevious);
  const hasMoreNewer = useStore(model.$hasMoreNewer);

  const lastRead = useStore(model.$lastRead);

  const activeAudioPlayer = useStore(GlobalMediaPlayerModel.$player);

  const unreadCount = useStore(model.$unreadCount);

  const firstUnreadMessageIndex = useMemo(() => {
    const index = messages.findIndex(m => m.created_at > lastRead);
    return index >= 0 ? index : messages.length - 1;
  }, [lastRead, messages]);

  const scroller = useRef<HTMLDivElement>(null);
  useResponsiveViewPort(scroller);
  // Scroll to right pos on mount
  useLayoutEffect(() => {
    if (!scroller.current) return;
    if (firstUnreadMessageIndex >= 0) {
      scrollToMessage(scroller.current, messages[firstUnreadMessageIndex].id, model.highlightedMessageIdReset, false);
    } else {
      scrollToBottom(scroller.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (atBottom && !hasMoreNewer) {
      model.allRead();
    }
  }, [hasMoreNewer, atBottom, model]);

  return (
    <div
      ref={wrapper}
      className={`${activeAudioPlayer ? 'audio-player-active' : ''} relative h-full w-full flex flex-col`}
    >
      {hasMorePrevious && atTop ? (
        <div className="absolute, -top-18 left-0 w-full h-16">
          <LoaderBlock />
        </div>
      ) : null}
      <div className="absolute z-40 left-0 top-0 w-full flex flex-col items-center">
        {activeAudioPlayer && (
          <ChatActiveAudioPanel
            onClose={GlobalMediaPlayerModel.close}
            player={activeAudioPlayer.player}
            authorName={activeAudioPlayer.meta.authorName}
          />
        )}
      </div>
      <Scroller
        onShowMessageActions={onShowMessageActions}
        atTop={setAtTop}
        atBottom={v => {
          setShowBottomBtn(!v);
          setAtBottom(v);
        }}
        model={model}
        containerRef={scroller}
      />
      {showBottomBtn && (
        <>
          <button
            type="button"
            className="absolute right-6 bottom-6 z-50
            h-12 w-12
            flex items-center justify-center
            rounded-full
            bg-blur-secondary border border-stroke text-primary"
            onClick={() => {
              model.jumpedToMessage({ messageId: 'latest' });
            }}
          >
            <CaretDown size={28} />
            {unreadCount > 0 ? (
              <span
                className="absolute -top-2 right-0
                           h-5 w-5
                           flex items-center justify-center
                           text-active text-xs rounded bg-new-message"
              >
                {/*TODO: заменить на реальный токен фона*/}
                {unreadCount}
              </span>
            ) : null}
          </button>
        </>
      )}
    </div>
  );
};
