import { getSkillClassNameAndColor, KnownSkillColor } from '@sp/util/skills';
import { FC } from 'react';

export const SpaceTag: FC<{
  text: string;
  color?: string;
}> = ({ text, color = 'gray' }) => {
  return (
    <div
      className="px-1 py-px rounded text-active text-xs font-semibold"
      style={{ backgroundColor: getSkillClassNameAndColor(color as KnownSkillColor).color }}
    >
      {text}
    </div>
  );
};
