/* tslint:disable */
/* eslint-disable */
/**
 * Chat
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 *
 * @export
 * @interface ChatsChatIdEventsGet200Response
 */
export interface ChatsChatIdEventsGet200Response {
  /**
   *
   * @type {Array<ChatsChatIdEventsGet200ResponseEventsInner>}
   * @memberof ChatsChatIdEventsGet200Response
   */
  events?: Array<ChatsChatIdEventsGet200ResponseEventsInner>;
  /**
   *
   * @type {number}
   * @memberof ChatsChatIdEventsGet200Response
   */
  unviewedCount?: number;
}
/**
 *
 * @export
 * @interface ChatsChatIdEventsGet200ResponseEventsInner
 */
export interface ChatsChatIdEventsGet200ResponseEventsInner {
  /**
   *
   * @type {number}
   * @memberof ChatsChatIdEventsGet200ResponseEventsInner
   */
  userId?: number;
  /**
   *
   * @type {number}
   * @memberof ChatsChatIdEventsGet200ResponseEventsInner
   */
  chatId?: number;
  /**
   *
   * @type {string}
   * @memberof ChatsChatIdEventsGet200ResponseEventsInner
   */
  messageId?: string;
  /**
   *
   * @type {number}
   * @memberof ChatsChatIdEventsGet200ResponseEventsInner
   */
  createdAt?: number;
  /**
   *
   * @type {Array<ChatsChatIdEventsGet200ResponseEventsInnerEventsInner>}
   * @memberof ChatsChatIdEventsGet200ResponseEventsInner
   */
  events?: Array<ChatsChatIdEventsGet200ResponseEventsInnerEventsInner>;
}
/**
 *
 * @export
 * @interface ChatsChatIdEventsGet200ResponseEventsInnerEventsInner
 */
export interface ChatsChatIdEventsGet200ResponseEventsInnerEventsInner {
  /**
   *
   * @type {number}
   * @memberof ChatsChatIdEventsGet200ResponseEventsInnerEventsInner
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof ChatsChatIdEventsGet200ResponseEventsInnerEventsInner
   */
  createdAt?: number;
  /**
   *
   * @type {string}
   * @memberof ChatsChatIdEventsGet200ResponseEventsInnerEventsInner
   */
  reactionType?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof ChatsChatIdEventsGet200ResponseEventsInnerEventsInner
   */
  isViewed?: boolean;
  /**
   *
   * @type {string}
   * @memberof ChatsChatIdEventsGet200ResponseEventsInnerEventsInner
   */
  type?: ChatsChatIdEventsGet200ResponseEventsInnerEventsInnerTypeEnum;
}

export const ChatsChatIdEventsGet200ResponseEventsInnerEventsInnerTypeEnum = {
  Reaction: 'reaction',
  Mention: 'mention',
  Reply: 'reply',
} as const;

export type ChatsChatIdEventsGet200ResponseEventsInnerEventsInnerTypeEnum =
  typeof ChatsChatIdEventsGet200ResponseEventsInnerEventsInnerTypeEnum[keyof typeof ChatsChatIdEventsGet200ResponseEventsInnerEventsInnerTypeEnum];

/**
 *
 * @export
 * @interface ChatsGet200ResponseInner
 */
export interface ChatsGet200ResponseInner {
  /**
   *
   * @type {number}
   * @memberof ChatsGet200ResponseInner
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof ChatsGet200ResponseInner
   */
  createdAt?: number;
  /**
   *
   * @type {number}
   * @memberof ChatsGet200ResponseInner
   */
  updatedAt?: number | null;
  /**
   *
   * @type {string}
   * @memberof ChatsGet200ResponseInner
   */
  type?: ChatsGet200ResponseInnerTypeEnum;
  /**
   *
   * @type {number}
   * @memberof ChatsGet200ResponseInner
   */
  gsChannelId?: number | null;
  /**
   *
   * @type {Array<ChatsGet200ResponseInnerDmUsersInner>}
   * @memberof ChatsGet200ResponseInner
   */
  dmUsers?: Array<ChatsGet200ResponseInnerDmUsersInner> | null;
  /**
   *
   * @type {ChatsGet200ResponseInnerClub}
   * @memberof ChatsGet200ResponseInner
   */
  club?: ChatsGet200ResponseInnerClub | null;
  /**
   *
   * @type {ChatsGet200ResponseInnerSpace}
   * @memberof ChatsGet200ResponseInner
   */
  space?: ChatsGet200ResponseInnerSpace | null;
}

export const ChatsGet200ResponseInnerTypeEnum = {
  Space: 'space',
  Club: 'club',
  Dm: 'dm',
  Platform: 'platform',
} as const;

export type ChatsGet200ResponseInnerTypeEnum =
  typeof ChatsGet200ResponseInnerTypeEnum[keyof typeof ChatsGet200ResponseInnerTypeEnum];

/**
 *
 * @export
 * @interface ChatsGet200ResponseInnerClub
 */
export interface ChatsGet200ResponseInnerClub {
  /**
   *
   * @type {number}
   * @memberof ChatsGet200ResponseInnerClub
   */
  owner?: number;
  /**
   *
   * @type {string}
   * @memberof ChatsGet200ResponseInnerClub
   */
  title?: string | null;
  /**
   *
   * @type {number}
   * @memberof ChatsGet200ResponseInnerClub
   */
  subscriptionPrice?: number | null;
}
/**
 *
 * @export
 * @interface ChatsGet200ResponseInnerDmUsersInner
 */
export interface ChatsGet200ResponseInnerDmUsersInner {
  /**
   *
   * @type {number}
   * @memberof ChatsGet200ResponseInnerDmUsersInner
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof ChatsGet200ResponseInnerDmUsersInner
   */
  name?: string;
  /**
   *
   * @type {boolean}
   * @memberof ChatsGet200ResponseInnerDmUsersInner
   */
  isAvatar?: boolean;
  /**
   *
   * @type {ChatsGet200ResponseInnerDmUsersInnerAvatar}
   * @memberof ChatsGet200ResponseInnerDmUsersInner
   */
  avatar?: ChatsGet200ResponseInnerDmUsersInnerAvatar | null;
}
/**
 *
 * @export
 * @interface ChatsGet200ResponseInnerDmUsersInnerAvatar
 */
export interface ChatsGet200ResponseInnerDmUsersInnerAvatar {
  /**
   *
   * @type {number}
   * @memberof ChatsGet200ResponseInnerDmUsersInnerAvatar
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof ChatsGet200ResponseInnerDmUsersInnerAvatar
   */
  url?: string;
}
/**
 *
 * @export
 * @interface ChatsGet200ResponseInnerSpace
 */
export interface ChatsGet200ResponseInnerSpace {
  /**
   *
   * @type {number}
   * @memberof ChatsGet200ResponseInnerSpace
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof ChatsGet200ResponseInnerSpace
   */
  title?: string;
  /**
   *
   * @type {number}
   * @memberof ChatsGet200ResponseInnerSpace
   */
  price?: number;
  /**
   *
   * @type {object}
   * @memberof ChatsGet200ResponseInnerSpace
   */
  teaser?: object;
  /**
   *
   * @type {string}
   * @memberof ChatsGet200ResponseInnerSpace
   */
  type?: ChatsGet200ResponseInnerSpaceTypeEnum;
  /**
   *
   * @type {string}
   * @memberof ChatsGet200ResponseInnerSpace
   */
  status?: ChatsGet200ResponseInnerSpaceStatusEnum;
  /**
   *
   * @type {string}
   * @memberof ChatsGet200ResponseInnerSpace
   */
  payment_type?: ChatsGet200ResponseInnerSpacePaymentTypeEnum;
  /**
   *
   * @type {object}
   * @memberof ChatsGet200ResponseInnerSpace
   */
  meta?: object | null;
  /**
   *
   * @type {ChatsGet200ResponseInnerSpaceConfig}
   * @memberof ChatsGet200ResponseInnerSpace
   */
  config?: ChatsGet200ResponseInnerSpaceConfig | null;
  /**
   *
   * @type {string}
   * @memberof ChatsGet200ResponseInnerSpace
   */
  userSlug?: string | null;
  /**
   *
   * @type {string}
   * @memberof ChatsGet200ResponseInnerSpace
   */
  slug?: string;
  /**
   *
   * @type {number}
   * @memberof ChatsGet200ResponseInnerSpace
   */
  chatId?: number | null;
  /**
   *
   * @type {ChatsGet200ResponseInnerDmUsersInnerAvatar}
   * @memberof ChatsGet200ResponseInnerSpace
   */
  cover?: ChatsGet200ResponseInnerDmUsersInnerAvatar | null;
  /**
   *
   * @type {ChatsGet200ResponseInnerSpaceLive}
   * @memberof ChatsGet200ResponseInnerSpace
   */
  live?: ChatsGet200ResponseInnerSpaceLive | null;
  /**
   *
   * @type {ChatsGet200ResponseInnerSpaceCreator}
   * @memberof ChatsGet200ResponseInnerSpace
   */
  creator?: ChatsGet200ResponseInnerSpaceCreator | null;
}

export const ChatsGet200ResponseInnerSpaceTypeEnum = {
  Post: 'post',
  Live: 'live',
  Challenge: 'challenge',
  Trailer: 'trailer',
  Channel: 'channel',
  Community: 'community',
} as const;

export type ChatsGet200ResponseInnerSpaceTypeEnum =
  typeof ChatsGet200ResponseInnerSpaceTypeEnum[keyof typeof ChatsGet200ResponseInnerSpaceTypeEnum];
export const ChatsGet200ResponseInnerSpaceStatusEnum = {
  Draft: 'draft',
  Published: 'published',
} as const;

export type ChatsGet200ResponseInnerSpaceStatusEnum =
  typeof ChatsGet200ResponseInnerSpaceStatusEnum[keyof typeof ChatsGet200ResponseInnerSpaceStatusEnum];
export const ChatsGet200ResponseInnerSpacePaymentTypeEnum = {
  FixedPrice: 'fixed_price',
  FixedPriceSubscribersOnly: 'fixed_price_subscribers_only',
  SubscribersOnly: 'subscribers_only',
  Free: 'free',
  MonthlyPrice: 'monthly_price',
} as const;

export type ChatsGet200ResponseInnerSpacePaymentTypeEnum =
  typeof ChatsGet200ResponseInnerSpacePaymentTypeEnum[keyof typeof ChatsGet200ResponseInnerSpacePaymentTypeEnum];

/**
 *
 * @export
 * @interface ChatsGet200ResponseInnerSpaceConfig
 */
export interface ChatsGet200ResponseInnerSpaceConfig {
  /**
   *
   * @type {ChatsGet200ResponseInnerSpaceConfigChallenge}
   * @memberof ChatsGet200ResponseInnerSpaceConfig
   */
  challenge?: ChatsGet200ResponseInnerSpaceConfigChallenge | null;
}
/**
 *
 * @export
 * @interface ChatsGet200ResponseInnerSpaceConfigChallenge
 */
export interface ChatsGet200ResponseInnerSpaceConfigChallenge {
  /**
   *
   * @type {number}
   * @memberof ChatsGet200ResponseInnerSpaceConfigChallenge
   */
  finishTimestamp?: number | null;
}
/**
 *
 * @export
 * @interface ChatsGet200ResponseInnerSpaceCreator
 */
export interface ChatsGet200ResponseInnerSpaceCreator {
  /**
   *
   * @type {number}
   * @memberof ChatsGet200ResponseInnerSpaceCreator
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof ChatsGet200ResponseInnerSpaceCreator
   */
  name?: string;
}
/**
 *
 * @export
 * @interface ChatsGet200ResponseInnerSpaceLive
 */
export interface ChatsGet200ResponseInnerSpaceLive {
  /**
   *
   * @type {number}
   * @memberof ChatsGet200ResponseInnerSpaceLive
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof ChatsGet200ResponseInnerSpaceLive
   */
  startAt?: number;
  /**
   *
   * @type {number}
   * @memberof ChatsGet200ResponseInnerSpaceLive
   */
  duration?: number;
}
/**
 *
 * @export
 * @interface CreatorsGet200ResponseInner
 */
export interface CreatorsGet200ResponseInner {
  /**
   *
   * @type {number}
   * @memberof CreatorsGet200ResponseInner
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof CreatorsGet200ResponseInner
   */
  name?: string;
  /**
   *
   * @type {ChatsGet200ResponseInnerDmUsersInnerAvatar}
   * @memberof CreatorsGet200ResponseInner
   */
  avatar?: ChatsGet200ResponseInnerDmUsersInnerAvatar | null;
}
/**
 *
 * @export
 * @interface GsTokenGet200Response
 */
export interface GsTokenGet200Response {
  /**
   *
   * @type {string}
   * @memberof GsTokenGet200Response
   */
  accessToken?: string;
}
/**
 *
 * @export
 * @interface UsersDmPost200Response
 */
export interface UsersDmPost200Response {
  /**
   *
   * @type {number}
   * @memberof UsersDmPost200Response
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof UsersDmPost200Response
   */
  createdAt?: number;
  /**
   *
   * @type {number}
   * @memberof UsersDmPost200Response
   */
  updatedAt?: number | null;
  /**
   *
   * @type {string}
   * @memberof UsersDmPost200Response
   */
  type?: UsersDmPost200ResponseTypeEnum;
  /**
   *
   * @type {number}
   * @memberof UsersDmPost200Response
   */
  gsChannelId?: number | null;
  /**
   *
   * @type {Array<CreatorsGet200ResponseInner>}
   * @memberof UsersDmPost200Response
   */
  dmUsers?: Array<CreatorsGet200ResponseInner> | null;
}

export const UsersDmPost200ResponseTypeEnum = {
  Space: 'space',
  Club: 'club',
  Dm: 'dm',
  Platform: 'platform',
} as const;

export type UsersDmPost200ResponseTypeEnum =
  typeof UsersDmPost200ResponseTypeEnum[keyof typeof UsersDmPost200ResponseTypeEnum];

/**
 *
 * @export
 * @interface UsersDmPostRequest
 */
export interface UsersDmPostRequest {
  /**
   *
   * @type {Array<number>}
   * @memberof UsersDmPostRequest
   */
  usersIds?: Array<number>;
}

/**
 * ChatApi - axios parameter creator
 * @export
 */
export const ChatApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} chatId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    chatChatIdMembersGet: async (chatId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'chatId' is not null or undefined
      assertParamExists('chatChatIdMembersGet', 'chatId', chatId);
      const localVarPath = `/chat/{chatId}/members`.replace(`{${'chatId'}}`, encodeURIComponent(String(chatId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ChatApi - functional programming interface
 * @export
 */
export const ChatApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ChatApiAxiosParamCreator(configuration);
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} chatId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async chatChatIdMembersGet(
      chatId: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.chatChatIdMembersGet(chatId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * ChatApi - factory interface
 * @export
 */
export const ChatApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = ChatApiFp(configuration);
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} chatId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    chatChatIdMembersGet(chatId: number, options?: any): AxiosPromise<object> {
      return localVarFp.chatChatIdMembersGet(chatId, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * ChatApi - object-oriented interface
 * @export
 * @class ChatApi
 * @extends {BaseAPI}
 */
export class ChatApi extends BaseAPI {
  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {number} chatId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ChatApi
   */
  public chatChatIdMembersGet(chatId: number, options?: AxiosRequestConfig) {
    return ChatApiFp(this.configuration)
      .chatChatIdMembersGet(chatId, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * ChatsApi - axios parameter creator
 * @export
 */
export const ChatsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} chatId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    chatsChatIdEventsGet: async (chatId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'chatId' is not null or undefined
      assertParamExists('chatsChatIdEventsGet', 'chatId', chatId);
      const localVarPath = `/chats/{chatId}/events`.replace(`{${'chatId'}}`, encodeURIComponent(String(chatId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} chatId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    chatsChatIdEventsReadPost: async (chatId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'chatId' is not null or undefined
      assertParamExists('chatsChatIdEventsReadPost', 'chatId', chatId);
      const localVarPath = `/chats/{chatId}/events/read`.replace(`{${'chatId'}}`, encodeURIComponent(String(chatId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {boolean} [force]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    chatsGet: async (force?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/chats`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (force !== undefined) {
        localVarQueryParameter['force'] = force;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ChatsApi - functional programming interface
 * @export
 */
export const ChatsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ChatsApiAxiosParamCreator(configuration);
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} chatId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async chatsChatIdEventsGet(
      chatId: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChatsChatIdEventsGet200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.chatsChatIdEventsGet(chatId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} chatId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async chatsChatIdEventsReadPost(
      chatId: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.chatsChatIdEventsReadPost(chatId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {boolean} [force]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async chatsGet(
      force?: boolean,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ChatsGet200ResponseInner>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.chatsGet(force, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * ChatsApi - factory interface
 * @export
 */
export const ChatsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = ChatsApiFp(configuration);
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} chatId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    chatsChatIdEventsGet(chatId: number, options?: any): AxiosPromise<ChatsChatIdEventsGet200Response> {
      return localVarFp.chatsChatIdEventsGet(chatId, options).then(request => request(axios, basePath));
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} chatId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    chatsChatIdEventsReadPost(chatId: number, options?: any): AxiosPromise<object> {
      return localVarFp.chatsChatIdEventsReadPost(chatId, options).then(request => request(axios, basePath));
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {boolean} [force]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    chatsGet(force?: boolean, options?: any): AxiosPromise<Array<ChatsGet200ResponseInner>> {
      return localVarFp.chatsGet(force, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * ChatsApi - object-oriented interface
 * @export
 * @class ChatsApi
 * @extends {BaseAPI}
 */
export class ChatsApi extends BaseAPI {
  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {number} chatId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ChatsApi
   */
  public chatsChatIdEventsGet(chatId: number, options?: AxiosRequestConfig) {
    return ChatsApiFp(this.configuration)
      .chatsChatIdEventsGet(chatId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {number} chatId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ChatsApi
   */
  public chatsChatIdEventsReadPost(chatId: number, options?: AxiosRequestConfig) {
    return ChatsApiFp(this.configuration)
      .chatsChatIdEventsReadPost(chatId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {boolean} [force]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ChatsApi
   */
  public chatsGet(force?: boolean, options?: AxiosRequestConfig) {
    return ChatsApiFp(this.configuration)
      .chatsGet(force, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * CreatorsApi - axios parameter creator
 * @export
 */
export const CreatorsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    creatorsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/creators`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * CreatorsApi - functional programming interface
 * @export
 */
export const CreatorsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = CreatorsApiAxiosParamCreator(configuration);
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async creatorsGet(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CreatorsGet200ResponseInner>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.creatorsGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * CreatorsApi - factory interface
 * @export
 */
export const CreatorsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = CreatorsApiFp(configuration);
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    creatorsGet(options?: any): AxiosPromise<Array<CreatorsGet200ResponseInner>> {
      return localVarFp.creatorsGet(options).then(request => request(axios, basePath));
    },
  };
};

/**
 * CreatorsApi - object-oriented interface
 * @export
 * @class CreatorsApi
 * @extends {BaseAPI}
 */
export class CreatorsApi extends BaseAPI {
  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CreatorsApi
   */
  public creatorsGet(options?: AxiosRequestConfig) {
    return CreatorsApiFp(this.configuration)
      .creatorsGet(options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * GsApi - axios parameter creator
 * @export
 */
export const GsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    gsTokenGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/gs/token`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * GsApi - functional programming interface
 * @export
 */
export const GsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = GsApiAxiosParamCreator(configuration);
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async gsTokenGet(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GsTokenGet200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.gsTokenGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * GsApi - factory interface
 * @export
 */
export const GsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = GsApiFp(configuration);
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    gsTokenGet(options?: any): AxiosPromise<GsTokenGet200Response> {
      return localVarFp.gsTokenGet(options).then(request => request(axios, basePath));
    },
  };
};

/**
 * GsApi - object-oriented interface
 * @export
 * @class GsApi
 * @extends {BaseAPI}
 */
export class GsApi extends BaseAPI {
  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GsApi
   */
  public gsTokenGet(options?: AxiosRequestConfig) {
    return GsApiFp(this.configuration)
      .gsTokenGet(options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * PlatformApi - axios parameter creator
 * @export
 */
export const PlatformApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    platformPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/platform`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PlatformApi - functional programming interface
 * @export
 */
export const PlatformApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = PlatformApiAxiosParamCreator(configuration);
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async platformPost(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.platformPost(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * PlatformApi - factory interface
 * @export
 */
export const PlatformApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = PlatformApiFp(configuration);
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    platformPost(options?: any): AxiosPromise<object> {
      return localVarFp.platformPost(options).then(request => request(axios, basePath));
    },
  };
};

/**
 * PlatformApi - object-oriented interface
 * @export
 * @class PlatformApi
 * @extends {BaseAPI}
 */
export class PlatformApi extends BaseAPI {
  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlatformApi
   */
  public platformPost(options?: AxiosRequestConfig) {
    return PlatformApiFp(this.configuration)
      .platformPost(options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {UsersDmPostRequest} [usersDmPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersDmPost: async (
      usersDmPostRequest?: UsersDmPostRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/users/dm`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(usersDmPostRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration);
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {UsersDmPostRequest} [usersDmPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async usersDmPost(
      usersDmPostRequest?: UsersDmPostRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UsersDmPost200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.usersDmPost(usersDmPostRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = UsersApiFp(configuration);
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {UsersDmPostRequest} [usersDmPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersDmPost(usersDmPostRequest?: UsersDmPostRequest, options?: any): AxiosPromise<UsersDmPost200Response> {
      return localVarFp.usersDmPost(usersDmPostRequest, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {UsersDmPostRequest} [usersDmPostRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public usersDmPost(usersDmPostRequest?: UsersDmPostRequest, options?: AxiosRequestConfig) {
    return UsersApiFp(this.configuration)
      .usersDmPost(usersDmPostRequest, options)
      .then(request => request(this.axios, this.basePath));
  }
}
