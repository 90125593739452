import { ChatMessageAvatarView } from '@sp/ui/chat';
import { trackTime } from '@sp/util/native';
import { ReactElement, useCallback } from 'react';
import { useChatMessage } from './chat-message-context';

export const ChatMessageAvatar = (): ReactElement => {
  const { message, model } = useChatMessage();

  const onUserClick = useCallback(() => {
    if (message.user?.id != null) {
      trackTime('View profile from chat');
      model.showUserCard({
        userId: message.user.id,
        avatar: { url: message.user.image },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message.user?.id, model.showUserCard]);

  return (
    <div role="button" onClick={onUserClick}>
      <ChatMessageAvatarView author={message.user?.name ?? 'Unknown'} avatar={message.user?.image ?? null} />
    </div>
  );
};
