import { createFileUploader } from '@sp/feature/file-uploader';
import { createZodValidator } from '@sp/util/effector-forms';
import { optimizeImage } from '@sp/util/files';
import { createKeyValueStore, createShowAlertFx, generateUniqueString, isNotNullish } from '@sp/util/helpers';
import { createEvent, createStore, sample } from 'effector';
import { z } from 'zod';

const Testimonial = z.strictObject({
  _id: z.string(),
  title: z.string(),
  authorName: z.string().trim().min(1),
  review: z.string().trim().min(1),
  avatar: z
    .object({
      url: z.string().url(),
      id: z.number().int().positive(),
    })
    .nullable(),
});

export type TestimonialFormItemState = z.infer<typeof Testimonial>;
export type TestimonialsFormState = readonly TestimonialFormItemState[];

const validateTestimonial = createZodValidator(Testimonial);

function getNewTestimonialDto(id: string): TestimonialFormItemState {
  return {
    _id: id,
    title: '',
    review: '',
    authorName: '',
    avatar: null,
  };
}

const kvTestimonials = createKeyValueStore<TestimonialFormItemState>('live space testimonials form');

const form = {
  add: createEvent(),
  set: createEvent<Omit<TestimonialFormItemState, '_id'>[]>(),
  remove: createEvent<string>(),
  patch: createEvent<{ key: string; patch: Partial<TestimonialFormItemState> }>(),
  reset: createEvent(),
  moveItem: kvTestimonials.move,
  $state: kvTestimonials.$list,
  $isValid: createStore<boolean>(false),
  validSubmitted: createEvent<TestimonialsFormState>(),
};

sample({
  clock: form.add,
  // TODO[Dmitriy Teplov] extract side effects.
  fn: () => {
    const key = generateUniqueString();
    return { key, value: getNewTestimonialDto(key) };
  },
  target: kvTestimonials.set,
});

sample({
  clock: form.set,
  fn: list =>
    Object.fromEntries(
      list.map(value => {
        const key = generateUniqueString();
        return [key, { ...value, _id: key } as TestimonialFormItemState];
      }),
    ),
  target: kvTestimonials.replace,
});

sample({
  source: kvTestimonials.$store,
  clock: form.remove,
  filter: (store, key) => isNotNullish(store[key]),
  fn: (store, key) => key,
  target: kvTestimonials.unset,
});

sample({
  source: kvTestimonials.$store,
  clock: form.patch,
  filter: (store, { key }) => isNotNullish(store[key]),
  fn: (store, { key, patch }) => {
    const state = store[key];
    if (!state) throw new Error();
    return { key, value: { ...state, ...patch } };
  },
  target: kvTestimonials.set,
});

sample({
  clock: form.reset,
  target: kvTestimonials.reset,
});

sample({
  source: form.$state,
  fn: state => state.map(validateTestimonial).every(result => result.isValid),
  target: form.$isValid,
});

sample({
  source: form.$state,
  clock: form.$isValid,
  filter: (_, isValid) => isValid,
  fn: state => state,
  target: form.validSubmitted,
});

const coversUploader = createFileUploader(optimizeImage);

sample({
  clock: coversUploader.uploadFailed,
  target: createShowAlertFx('Failed to upload file'),
});

sample({
  clock: form.reset,
  target: coversUploader.resetAll,
});

sample({
  clock: form.remove,
  target: coversUploader.reset,
});

sample({
  source: kvTestimonials.$store,
  clock: coversUploader.uploadFinished,
  filter: (store, { key }) => isNotNullish(store[key]),
  fn: (_, { key, result: { data } }) => ({ key, patch: { avatar: data } }),
  target: form.patch,
});

export const TESTIMONIALS_FORM_MODEL = {
  form,
  coversUploader,
} as const;
