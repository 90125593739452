import {
  AcceptFileTypesProvider,
  ChatMessageList,
  MessageExtraContentContext,
  MessageTemplateContext,
} from '@sp/feature/chat';
import { UseSpacialBadge } from '@sp/feature/space';
import { ChatChannelModel, CreateMessagePayload } from '@sp/feature/stream-chat';
import { noop } from '@sp/util/helpers';
import { ChatFormatMessage, JumpEvent } from '@sp/util/stream-chat';
import { createEffect, createEvent, createStore, EventPayload, sample } from 'effector';
import { useStore, useStoreMap } from 'effector-react';
import { FC, useState } from 'react';
import { COMMUNITY_MODEL } from '../community-model';
import { FeedMessageFormWrapper } from './feed-message-form';
import { FEED_MODEL } from './feed-model';

function createMockJumpModel() {
  const simulateLoadMessageIntoStateFx = createEffect((payload: JumpEvent) => Promise.resolve().then(() => payload));
  const afterLoadMessageIntoStateFx = createEffect(
    ({ messageId, cb }: JumpEvent) =>
      new Promise<void>(function (resolve) {
        // Расчет на то, что сообщение быстрее чем за 500мс запостилось, прилетело по вебсокету и отрендерелось
        setTimeout(() => {
          cb?.(messageId);
          resolve();
        }, 500);
      }),
  );
  const simulateMessagePosted = createEvent<CreateMessagePayload>();

  const jumpToMessage = createEvent<JumpEvent>();
  const highlightedMessageIdReset = createEvent();
  const $highlightedMessageId = createStore<string | null>(null);

  $highlightedMessageId.reset(highlightedMessageIdReset);

  sample({
    source: simulateMessagePosted,
    fn: () => ({ messageId: 'latest' }),
    target: jumpToMessage,
  });

  sample({
    clock: jumpToMessage,
    target: simulateLoadMessageIntoStateFx,
  });

  sample({
    clock: simulateLoadMessageIntoStateFx.doneData,
    fn: ({ messageId }) => messageId,
    target: $highlightedMessageId,
  });

  sample({
    clock: simulateLoadMessageIntoStateFx.doneData,
    target: afterLoadMessageIntoStateFx,
  });

  sample({
    clock: afterLoadMessageIntoStateFx.done,
    target: highlightedMessageIdReset,
  });

  return {
    $highlightedMessageId,
    highlightedMessageIdReset,
    jumpToMessage,
    simulateMessagePosted,
  } as const;
}

// Last read date must be in the far future to always
// count the latest message as lastRead.
const $mockLastRead = createStore(new Date(99999999999999));
const $mockUnreadCount = createStore(0);
const $mockHasMorePrevious = createStore(false);
const $mockHasMoreNewer = createStore(false);
const $mockIsLoadingPrevious = createStore(false);
const $mockRead = createStore({});
const mockPreviousLoaded = createEvent<EventPayload<ChatChannelModel['previousLoaded']>>();
const mockJumpModel = createMockJumpModel();

const $chatModel = COMMUNITY_MODEL.$chatModel.map(m =>
  m == null
    ? null
    : ({
        $messages: FEED_MODEL.$viewedPosts,

        $highlightedMessageId: mockJumpModel.$highlightedMessageId,
        highlightedMessageIdReset: mockJumpModel.highlightedMessageIdReset,
        jumpedToMessage: mockJumpModel.jumpToMessage,

        channel: m.channel,
        $members: m.$members,
        $user: m.$user,
        $isPostingMessage: m.$isPostingMessage,
        messageDeleted: m.messageDeleted,
        messagePosted: (data: CreateMessagePayload) => {
          m.messagePosted(data);
          mockJumpModel.simulateMessagePosted(data);
        },
        messageUpdated: m.messageUpdated,
        sendMessageSuccess: m.sendMessageSuccess,
        updateMessageSuccess: m.updateMessageSuccess,
        pickEvent: m.pickEvent,
        pinMessage: m.pinMessage,
        reactionDeleted: m.reactionDeleted,
        reactionAdded: m.reactionAdded,
        showUserCard: m.showUserCard,

        allRead: noop,
        destroy: noop,
        loadNewerMessages: noop,
        loadPreviousMessages: noop,
        updateLastRead: noop,
        previousLoaded: mockPreviousLoaded,
        $read: $mockRead,
        $isLoadingPrevious: $mockIsLoadingPrevious,
        $lastRead: $mockLastRead,
        $unreadCount: $mockUnreadCount,
        $hasMorePrevious: $mockHasMorePrevious,
        $hasMoreNewer: $mockHasMoreNewer,
      } as ChatChannelModel),
);

export const FeedLayout: FC<{ isOwner: boolean }> = ({ isOwner }) => {
  const chatModel = useStore($chatModel);
  const community = useStore(COMMUNITY_MODEL.$community);
  const [activeMessage, setActiveMessage] = useState<ChatFormatMessage | null>(null);
  const [specialBadgeContent] = UseSpacialBadge(community.creator);
  const isEmpty = useStoreMap(FEED_MODEL.$viewedPosts, posts => posts.length === 0);

  return chatModel ? (
    <>
      <div className="flex flex-col-reverse flex-grow justify-end overflow-y-hidden h-full">
        {isOwner ? (
          <AcceptFileTypesProvider fullAccepted={true}>
            <FeedMessageFormWrapper
              activeMessage={activeMessage}
              setActiveMessage={setActiveMessage}
              chatModel={chatModel}
            />
          </AcceptFileTypesProvider>
        ) : null}
        <div className="flex-grow">
          <MessageExtraContentContext.Provider
            value={{ afterUserName: specialBadgeContent, showEditButton: () => isOwner }}
          >
            <MessageTemplateContext.Provider value={undefined}>
              <div className="h-full mobile-pan">
                <div className="relative h-full w-full flex flex-col">
                  {!isEmpty && <ChatMessageList model={chatModel} onShowMessageActions={setActiveMessage} />}
                </div>
              </div>
            </MessageTemplateContext.Provider>
          </MessageExtraContentContext.Provider>
        </div>
      </div>
    </>
  ) : null;
};
