import { cancelChannelSubscription } from '@sp/data/bif';
import { createEffect, createEvent, sample } from 'effector';
import { CHANNEL_MODEL } from './channel-model';

const cancel = createEvent<number>();
const cancelFx = createEffect(cancelChannelSubscription);

sample({
  clock: cancel,
  target: cancelFx,
});

sample({
  clock: cancelFx.finally,
  target: CHANNEL_MODEL.refetchData,
});

export const CANCEL_SUBSCRIPTION_MODEL = {
  cancel,
  isPending: cancelFx.pending,
  finished: cancelFx.finally,
} as const;
