import { SpaceCreator, SpaceMember } from '@sp/data/bif';
import { SELFPLUS_ADMINS_MODEL } from '@sp/data/global';
import { USER_PROFILE_CARD_SHOW_MODEL } from '@sp/data/user-profile-card';
import { Avatar } from '@sp/ui/elements';
import { IconCircleWavyCheck, IconExport } from '@sp/ui/icons';
import { Modal } from '@sp/ui/modal';
import { isNullish } from '@sp/util/helpers';
import { shareUrl } from '@sp/util/native';
import { useStore } from 'effector-react';
import { FC, Fragment, ReactNode, useCallback } from 'react';
import { MEMBERS_MODEL } from './members-model';

export const Header: FC<{
  title: string;
  urlToShare?: string;
  members?: number;
  avatar?: string;
}> = ({ title, members, avatar, urlToShare }) => {
  const handleShareClick = useCallback(() => {
    if (urlToShare) {
      shareUrl({ url: urlToShare, text: title, title });
    }
  }, [urlToShare, title]);

  return (
    <div className="flex justify-start items-start px-6 gap-2 pb-4 border-b border-stripe w-full">
      <span>
        <Avatar size="sm" src={avatar} />
      </span>
      <div className="flex flex-col flex-1 overflow-x-hidden">
        <h1 className="m-0 text-ellipsis whitespace-nowrap overflow-x-hidden text-base">{title}</h1>
        <span className="text-secondary text-xs">{members} participants</span>
      </div>
      <button type="button" title="Back" onClick={handleShareClick} className="text-secondary p-2">
        <IconExport size={24} />
      </button>
    </div>
  );
};

export const Member: FC<{ userName?: string; url?: string | null; onClick: () => void; withBadge: boolean }> = ({
  userName = '',
  url,
  onClick,
  withBadge,
}) => {
  return (
    <div onClick={onClick} role="button" className="flex items-center font-medium py-2 gap-2">
      <Avatar src={url} size="sm" />
      <span>{userName}</span>
      {withBadge && <IconCircleWavyCheck size={16} className="text-brand -ml-1 self-center" />}
    </div>
  );
};

export const MembersModal: FC<{
  isOpen: boolean;
  members: SpaceMember[];
  title: string;
  creator: SpaceCreator | null;
  urlToShare?: string;
  header?: ReactNode;
  avatar?: string;
}> = ({ isOpen, members, title, avatar, creator, header, urlToShare }) => {
  const handleClick = (user: { id: number; avatar: { url: string | null } | null }) => {
    USER_PROFILE_CARD_SHOW_MODEL.show({ userId: user.id, avatar: user.avatar || undefined });
  };

  const selfplusAdmins = useStore(SELFPLUS_ADMINS_MODEL.$data);
  const checkBadge = useCallback(
    (userId: number) => {
      if (!creator) return false;
      return !!(userId === creator.id || selfplusAdmins?.find(admin => userId === admin.id));
    },
    [creator, selfplusAdmins],
  );

  if (isNullish(header)) {
    header = <Header title={title} members={members.length} avatar={avatar} urlToShare={urlToShare} />;
  }

  return (
    <Modal header={header} closeOnBackdropClick onClose={() => MEMBERS_MODEL.close()} withBackdrop isOpen={isOpen}>
      <div className="mt-4">
        {creator && (
          <Member
            withBadge={checkBadge(creator.id)}
            onClick={() => handleClick(creator)}
            key={creator.id}
            userName={creator.name}
            url={creator.avatar?.url}
          />
        )}
        {members.map((member, i, array) => (
          <Fragment key={member.id}>
            {/* the array must be sorted "buyers – first, trial participants – last"  */}
            {member.isTrial && i > 0 && !array[i - 1].isTrial && <hr className="my-4" />}
            <Member
              withBadge={checkBadge(member.id)}
              onClick={() => handleClick(member)}
              userName={member.name || 'Newcomer'}
              url={member.avatar?.url}
            />
          </Fragment>
        ))}
      </div>
    </Modal>
  );
};
