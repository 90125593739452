import { ReactElement } from 'react';
import { ICON_DEFAULTS, IconProps } from './types';

export function IconFileXls({
  size = ICON_DEFAULTS.size,
  color = ICON_DEFAULTS.color,
  className = ICON_DEFAULTS.className,
}: IconProps): ReactElement {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M9.375 13.125V16.875H11.5625" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M4.0625 13.125L6.875 16.875" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M6.875 13.125L4.0625 16.875" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M13.75 16.5625C14.0886 16.8151 14.4995 16.952 14.9219 16.9531C15.625 16.9531 16.25 16.7188 16.25 15.9375C16.25 14.6875 13.75 15.2344 13.75 14.0625C13.75 13.4375 14.2188 13.0469 14.9219 13.0469C15.3443 13.048 15.7551 13.1849 16.0938 13.4375"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.75 10V3.125C3.75 2.95924 3.81585 2.80027 3.93306 2.68306C4.05027 2.56585 4.20924 2.5 4.375 2.5H11.875L16.25 6.875V10"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M11.875 2.5V6.875H16.25" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
