import { createEvent, createStore } from 'effector';

const open = createEvent();
const close = createEvent();
const modalReset = createEvent();

const $isOpen = createStore<boolean>(false)
  .reset(modalReset)
  .on(open, () => true)
  .on(close, () => false);

export const MEMBERS_MODEL = { open, close, $isOpen };
