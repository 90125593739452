import { z } from 'zod';
import { createZodRule } from './zod-validator';

function isValidTimestamp(timestamp: number): boolean {
  return !isNaN(new Date(timestamp).getTime());
}

const uploadedFileSchema = z.object({
  url: z.string().url(),
  id: z.number().int().positive(),
});

type StandardEnum = {
  [id: string]: number | string;
  [nu: number]: string;
};

export const RULES = {
  url: () => createZodRule({ name: 'url', schema: z.string().url() }),
  nonEmptyString: () => createZodRule({ name: 'nonEmptyString', schema: z.string().trim().min(1) }),
  positiveInt: () => createZodRule({ name: 'positiveInt', schema: z.number().int().positive() }),
  timestamp: () => createZodRule({ name: 'timestamp', schema: z.number().int().refine(isValidTimestamp) }),
  enum: <T extends StandardEnum>(e: T) => createZodRule({ name: 'enum', schema: z.nativeEnum(e) }),
  uploadedFile: (isNullable = false) =>
    createZodRule(
      isNullable
        ? { name: 'nullableUploadedFile', schema: uploadedFileSchema.nullable() }
        : { name: 'uploadedFile', schema: uploadedFileSchema },
    ),
} as const;
