import { createLink, deleteLink, getLinks, MeUserLink, reorderLinks, updateLink, UserLink } from '@sp/data/bif';
import { isNotNullish, isNullish, Nullable } from '@sp/util/helpers';
import { normalizeUrlProtocol } from '@sp/util/links';
import { combine, createDomain, restore, sample, split } from 'effector';
import { createGate } from 'effector-react';
import { debounce, some } from 'patronum';
import { PROFILE_MODEL } from '../profile-model';
import { linkResolver, SocialServices, SocialServicesOrder } from './link-resolver';
import { LINKS_FORM_MODEL } from './links-form-model';

type ModalContent = 'addLink' | 'editLink';

const sortLinks = (a: UserLink, b: UserLink) => {
  const socialTypeA = linkResolver(a.href);
  const socialTypeB = linkResolver(b.href);
  if (socialTypeA === null) return 1;
  if (socialTypeB === null) return -1;
  return SocialServicesOrder.indexOf(socialTypeA) - SocialServicesOrder.indexOf(socialTypeB);
};

const linksDomain = createDomain();
const $links = linksDomain.createStore<MeUserLink[]>([]);
const $socialLinks = $links.map(links =>
  links.reduce(
    (acc, v) => {
      const socialType = linkResolver(v.href);
      if (isNotNullish(socialType)) {
        acc[socialType] = v;
      }
      return acc;
    },
    <Record<SocialServices, Nullable<MeUserLink>>>Object.keys(SocialServices).reduce((acc, key) => {
      return { ...acc, [key]: null };
    }, {}),
  ),
);
const $customLinks = $links.map(links => links.filter(link => isNullish(linkResolver(link.href))));
const $sortedEditableLinks = $links.map(links => {
  links.sort(sortLinks);
  return links;
});
const $staticLinks = linksDomain.createStore<UserLink[]>([]);
const $sortedStaticLinks = $staticLinks.map(links => {
  links.sort(sortLinks);
  return links;
});
const LinksGate = createGate();

const setModalVisible = linksDomain.createEvent<boolean>();
const $modalVisible = restore(setModalVisible, false);

const modalContentChanged = linksDomain.createEvent<ModalContent>();
const $modalContent = restore(modalContentChanged, 'addLink');

const linkDeleted = linksDomain.createEvent<number>();
const linkUpdated = linksDomain.createEvent<MeUserLink>();
const linksLoadStarted = linksDomain.createEvent();
const linkCreated = linksDomain.createEvent<null>();
const linkReordered = linksDomain.createEvent<MeUserLink[]>();
const linksReset = linksDomain.createEvent();

const socialLinkDeleted = linksDomain.createEvent<number>();
const socialLinkUpdated = linksDomain.createEvent<MeUserLink>();
const socialLinkCreated = linksDomain.createEvent<{ href: string; title: string }>();

const deleteLinkFx = linksDomain.createEffect(deleteLink);
const loadLinksFx = linksDomain.createEffect(() => getLinks());
const updateLinkFx = linksDomain.createEffect((data: MeUserLink) => updateLink(data.id, data));
const createLinkFx = linksDomain.createEffect(createLink);
const reorderLinkFx = linksDomain.createEffect(reorderLinks);

// links updates
sample({ source: PROFILE_MODEL.$user, fn: data => data?.links || [], target: $staticLinks });
sample({ clock: loadLinksFx.doneData, target: $links });
$links.on(createLinkFx.doneData, (links, data) => [...links, data]);
$links.on(updateLinkFx.doneData, (links, data) => links.map(link => (link.id === data.id ? data : link)));
// optimistic updates
$links.on([linkDeleted, socialLinkDeleted], (links, id) => links.filter(l => l.id !== id));
sample({
  clock: linkReordered,
  target: $links,
  fn: links =>
    links.map((link, i) => {
      return { ...link, order: i };
    }),
});
$links.reset(linksReset);

// Fire FXs
sample({ clock: linksLoadStarted, target: loadLinksFx });
sample({ clock: linkDeleted, target: deleteLinkFx });
sample({
  clock: linkCreated,
  source: combine({ href: LINKS_FORM_MODEL.$href, title: LINKS_FORM_MODEL.$title }),
  fn: data => ({ ...data, href: normalizeUrlProtocol(data.href) }),
  target: createLinkFx,
});
sample({
  clock: linkUpdated,
  source: combine({ href: LINKS_FORM_MODEL.$href, title: LINKS_FORM_MODEL.$title }),
  fn: (data, link) => ({ ...link, ...data, href: normalizeUrlProtocol(data.href) }),
  target: updateLinkFx,
});
sample({ clock: debounce({ source: linkReordered, timeout: 400 }), target: reorderLinkFx });

sample({ clock: socialLinkUpdated, target: updateLinkFx });
sample({ clock: socialLinkCreated, target: createLinkFx });
sample({ clock: socialLinkDeleted, target: deleteLinkFx });

split({
  source: LINKS_FORM_MODEL.linkFormSubmitted,
  match: { update: data => isNotNullish(data), create: data => data === null },
  cases: { update: linkUpdated, create: linkCreated },
});

const $isLinkLoading = some({ stores: [updateLinkFx.pending, createLinkFx.pending], predicate: val => val });

sample({ source: setModalVisible, filter: v => !v, target: LINKS_FORM_MODEL.formReset });
sample({ source: $isLinkLoading, filter: v => !v, target: setModalVisible });

export const LINKS_MODEL = {
  socialLinkCreated,
  socialLinkUpdated,
  socialLinkDeleted,
  linksLoadStarted,
  $modalVisible,
  setModalVisible,
  $modalContent,
  modalContentChanged,
  linkReordered,
  linkDeleted,
  $isLinkLoading,
  LinksGate,
  $socialLinks,
  $customLinks,
  $sortedStaticLinks,
  $sortedEditableLinks,
};
