import { MeUserLink } from '@sp/data/bif';
import { Nullable } from '@sp/util/helpers';
import { createDomain, restore } from 'effector';

const linksFormModelDomain = createDomain();
const titleChanged = linksFormModelDomain.createEvent<string>();
const hrefChanged = linksFormModelDomain.createEvent<string>();
const linkEdited = linksFormModelDomain.createEvent<MeUserLink>();
const formReset = linksFormModelDomain.createEvent();
const linkFormSubmitted = linksFormModelDomain.createEvent<Nullable<MeUserLink>>();

const $title = restore(titleChanged, '')
  .on(linkEdited, (_, { title }) => title)
  .reset(formReset);
const $href = restore(hrefChanged, '')
  .on(linkEdited, (_, { href }) => href)
  .reset(formReset);
const $link = restore(linkEdited, null).reset(formReset);

export const LINKS_FORM_MODEL = {
  $title,
  $href,
  $link,
  titleChanged,
  hrefChanged,
  formReset,
  linkEdited,
  linkFormSubmitted,
};
