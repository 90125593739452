import { ReactElement } from 'react';
import { FaEllipsisH } from 'react-icons/all';

export function LoaderBlock(): ReactElement {
  return (
    <div className="text-center leading-4">
      <FaEllipsisH className="inline-block w-10 h-10 animate-pulse" />
    </div>
  );
}
