import {
  Chat,
  ChatExtraControls,
  ChatMessageAvatar,
  ChatMessageBody,
  ChatMessageBottomAttachments,
  ChatMessageHeader,
  ChatMessageReactions,
  ChatMessageReply,
  ChatMessageTopAttachments,
  ReadonlyChat,
} from '@sp/feature/chat';
import { ChatChannelModel } from '@sp/feature/stream-chat';
import {
  ChatMessageBodyContainer,
  ChatMessageFooterContainer,
  ChatMessageLeftContainer,
  ChatMessageRightContainer,
} from '@sp/ui/chat';
import { IconCircleWavyCheck, IconLockOpen } from '@sp/ui/icons';
import { cls } from '@sp/util/helpers';
import { useStore } from 'effector-react';
import { FC, useCallback } from 'react';
import { MessageTemplateProvider } from '../chat/use-message-template';
import { CHANNEL_MODEL } from './channel-model';
import { PremiumSwitch } from './premium-switch';

const extraControls: ChatExtraControls = {
  beforeQuoted: formModel => {
    const {
      state: { meta },
      setMetaField,
    } = formModel;
    return (
      <div className="px-2 pt-3">
        <PremiumSwitch value={meta?.onlyPremium ?? false} onChange={value => setMetaField('onlyPremium', value)} />
      </div>
    );
  },
};

const MessageTemplate: FC<{ isOwner: boolean; isPremium: boolean; showReactions: boolean }> = ({
  isOwner,
  isPremium,
  showReactions,
}) => (
  <>
    <ChatMessageLeftContainer>
      <ChatMessageAvatar />
    </ChatMessageLeftContainer>

    <ChatMessageRightContainer>
      <ChatMessageHeader
        afterUserNameContent={isOwner ? <IconCircleWavyCheck size={16} className="text-brand self-center" /> : null}
      />
      {isPremium && (
        <div className="text-xs">
          for <span className="font-semibold">premium followers</span> only
        </div>
      )}

      <ChatMessageBodyContainer>
        <ChatMessageReply />

        <ChatMessageTopAttachments />

        <ChatMessageBody />
      </ChatMessageBodyContainer>

      <ChatMessageBottomAttachments />

      {showReactions && (
        <ChatMessageFooterContainer>
          <ChatMessageReactions />
        </ChatMessageFooterContainer>
      )}
    </ChatMessageRightContainer>
  </>
);

const LockedMessageTemplate: FC<{ isOwner: boolean; showReactions: boolean; onClickUnlock: VoidFunction }> = ({
  isOwner,
  showReactions,
  onClickUnlock,
}) => (
  <>
    <ChatMessageLeftContainer>
      <ChatMessageAvatar />
    </ChatMessageLeftContainer>

    <ChatMessageRightContainer>
      <ChatMessageHeader
        afterUserNameContent={isOwner ? <IconCircleWavyCheck size={16} className="text-brand self-center" /> : null}
      />
      <div className="text-xs">
        for <span className="font-semibold">premium followers</span> only
      </div>

      <ChatMessageReply />

      <ChatMessageTopAttachments locked />

      <div
        className={cls(
          'relative max-h-[3.75rem] overflow-hidden pointer-events-none',
          'after:block after:absolute after:top-0 after:left-0 after:right-0 after:bottom-0',
          'after:bg-gradient-to-b after:from-white/0 after:via-white/80 after:to-white',
        )}
      >
        <ChatMessageBodyContainer>
          <ChatMessageBody />
        </ChatMessageBodyContainer>
      </div>

      <button
        type="button"
        onClick={onClickUnlock}
        className="w-full p-4 flex items-center justify-center gap-2 bg-light rounded-lg text-secondary font-bold"
      >
        <IconLockOpen size={24} />
        <span>Unlock content</span>
      </button>

      {showReactions && (
        <ChatMessageFooterContainer>
          <ChatMessageReactions />
        </ChatMessageFooterContainer>
      )}
    </ChatMessageRightContainer>
  </>
);

export const ChannelChat: FC<{
  chatModel: ChatChannelModel;
  onClickUnlock: VoidFunction;
}> = ({ chatModel, onClickUnlock }) => {
  const channel = useStore(CHANNEL_MODEL.$channel);
  const isOwner = useStore(CHANNEL_MODEL.$isOwner);
  const ownerId = useStore(CHANNEL_MODEL.$ownerId);

  const messageTemplate: MessageTemplateProvider = useCallback(
    message => {
      const isOnlyPremium = message.selfplusMessageMeta?.onlyPremium ?? false;
      const isMessageOwner = ownerId?.toString() === message.user?.id;
      const showReactions = Boolean(
        message.reaction_counts && Object.values(message.reaction_counts).reduce((a, b) => a + b, 0),
      );

      if (!isOnlyPremium)
        return <MessageTemplate isOwner={isMessageOwner} isPremium={false} showReactions={showReactions} />;

      return channel?.isPurchased || isOwner ? (
        <MessageTemplate isOwner={isMessageOwner} isPremium={true} showReactions={showReactions} />
      ) : (
        <LockedMessageTemplate isOwner={isMessageOwner} showReactions={showReactions} onClickUnlock={onClickUnlock} />
      );
    },
    [channel?.isPurchased, isOwner, ownerId],
  );

  return isOwner ? (
    <Chat chatModel={chatModel} extraControls={extraControls} messageTemplate={messageTemplate} />
  ) : (
    <ReadonlyChat chatModel={chatModel} messageTemplate={messageTemplate} />
  );
};
