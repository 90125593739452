import { useUser } from '@sp/data/auth';
import { SpinnerPage } from '@sp/ui/elements';
import { useStore } from 'effector-react';
import { FC } from 'react';
import { CHALLENGE_MODEL } from '../challenge-model';
import { STEPS_MODEL } from '../steps';
import { StepsListView } from './steps-list-view';

export const JourneyLayout: FC = () => {
  const stepsWithUI = useStore(STEPS_MODEL.$stepsWithUI);
  const challenge = useStore(CHALLENGE_MODEL.$challenge);
  const isLoaded = useStore(CHALLENGE_MODEL.$isLoaded);
  const user = useUser();
  const showPaywall = !challenge.isPurchased && challenge.creator?.id !== user.id;

  return !isLoaded ? (
    <SpinnerPage />
  ) : (
    <div className="h-full w-full relative">
      <div className="absolute left-0 top-0 w-full h-full overflow-y-auto mobile-pan">
        <StepsListView showPaywall={showPaywall} stepsWithUI={stepsWithUI} />
      </div>
    </div>
  );
};
