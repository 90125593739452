import { USER_MODEL } from '@sp/data/auth';
import { isNotNullish, usePrevious } from '@sp/util/helpers';
import { useStore } from 'effector-react';
import { useEffect } from 'react';
import { CHAT_TEASERS_GLOBAL_MODEL } from './chat-teasers-global-model';
import { SELFPLUS_ADMINS_MODEL } from './selfplus-admins-model';

export function InitGlobalModels() {
  const user = useStore(USER_MODEL.$user);
  const previousUser = usePrevious(user);

  useEffect(() => {
    const isUserChanged = !isNotNullish(previousUser) || user?.id !== previousUser.id;
    const isNeedLoad = isNotNullish(user) && isUserChanged;

    if (isNeedLoad) {
      CHAT_TEASERS_GLOBAL_MODEL.load();
      SELFPLUS_ADMINS_MODEL.load();
    }
  }, [previousUser, user]);

  return null;
}
