import { DeepReadonly, Nullable } from '@sp/util/helpers';
import { ReadonlyDeep } from 'remirror';

export enum IncomeType {
  challenge = 'challenge',
  subscription = 'subscription',
  follow = 'follow',
}

export type IncomeBase = DeepReadonly<{
  id: number;
  createdAt: number;
  user: {
    id: number;
    name: string;
    avatar: Nullable<{
      id: number;
      url: string;
    }>;
  };
}>;

export type IncomeChallenge = IncomeBase &
  ReadonlyDeep<{
    type: IncomeType.challenge;
    amount: number;
    challenge: {
      id: number;
      title: string;
      startAt: number;
      stepsCount: number;
    };
  }>;

export type IncomeSubscription = IncomeBase &
  Readonly<{
    type: IncomeType.subscription;
    amount: number;
    chanel: {
      id: number;
      title: string;
    };
  }>;

export type IncomeFollow = IncomeBase &
  Readonly<{
    type: IncomeType.follow;
    chanel: {
      id: number;
      title: string;
    };
  }>;

export type Income = IncomeChallenge | IncomeSubscription | IncomeFollow;

type Month = '01' | '02' | '03' | '04' | '05' | '06' | '07' | '08' | '09' | '10' | '11' | '12';
type SingleNumber = '0' | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9';
type Year = `20${SingleNumber}${SingleNumber}`;

export type Period = 'overall' | 'this-month' | `${Month}-${Year}`;

export type AudienceItem = IncomeBase['user'] & {
  isFollowed: boolean;
  total: number;
};

export type ProceedsItem =
  | {
      incomes: IncomeChallenge[];
      type: IncomeType.challenge;
      challenge: IncomeChallenge['challenge'];
    }
  | {
      incomes: IncomeSubscription[];
      type: IncomeType.subscription;
      chanel: IncomeSubscription['chanel'];
    };
