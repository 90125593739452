import { createContext, FC, useContext } from 'react';

type AcceptFileTypes = string[];

const ACCEPTED_FILE_TYPES = ['image/*', 'video/*', 'audio/*'];
const FULL_ACCEPTED_FILE_TYPES = [
  ...ACCEPTED_FILE_TYPES,
  'application/pdf',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'text/plain',
  'application/zip',
];

const AcceptFileTypesContext = createContext<AcceptFileTypes>(ACCEPTED_FILE_TYPES);

export function useAcceptFileTypes() {
  return useContext(AcceptFileTypesContext);
}

export const AcceptFileTypesProvider: FC<{ fullAccepted: boolean }> = ({ children, fullAccepted }) => {
  return (
    <AcceptFileTypesContext.Provider value={fullAccepted ? FULL_ACCEPTED_FILE_TYPES : ACCEPTED_FILE_TYPES}>
      {children}
    </AcceptFileTypesContext.Provider>
  );
};
