import { ReactElement } from 'react';
import { ICON_DEFAULTS, IconProps } from './types';

export const IconLinkThin = ({
  size = ICON_DEFAULTS.size,
  color = ICON_DEFAULTS.color,
  className = ICON_DEFAULTS.className,
}: IconProps): ReactElement => (
  <svg
    width={size}
    height={size}
    className={className}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.4657 6.69381L13.322 4.83756C14.1079 4.12124 15.1396 3.73529 16.2027 3.7599C17.2658 3.78451 18.2785 4.2178 19.0304 4.96972C19.7824 5.72164 20.2157 6.73438 20.2403 7.79748C20.2649 8.86057 19.8789 9.89227 19.1626 10.6782L16.5095 13.3219C16.1271 13.7057 15.6727 14.0103 15.1724 14.2181C14.6721 14.4259 14.1356 14.5328 13.5938 14.5328C13.0521 14.5328 12.5156 14.4259 12.0153 14.2181C11.515 14.0103 11.0606 13.7057 10.6782 13.3219"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.5344 17.3063L10.6782 19.1625C9.89227 19.8789 8.86057 20.2648 7.79748 20.2402C6.73438 20.2156 5.72164 19.7823 4.96972 19.0304C4.2178 18.2785 3.78451 17.2657 3.7599 16.2026C3.73529 15.1395 4.12124 14.1078 4.83756 13.3219L7.49069 10.6782C7.87306 10.2944 8.32744 9.98984 8.82778 9.78204C9.32811 9.57425 9.86455 9.46729 10.4063 9.46729C10.9481 9.46729 11.4845 9.57425 11.9849 9.78204C12.4852 9.98984 12.9396 10.2944 13.3219 10.6782"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
