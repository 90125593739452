import { FC } from 'react';
import { StepFormPaneHeader } from './step-header';

export const FinishPane: FC<{ finishAt: number }> = ({ finishAt }) => {
  return (
    <>
      <div className="mt-1 p-4 rounded-2.5xl bg-brand-main text-active bg-[url('/images/challenges/finish-step-form-background.svg')] bg-cover bg-[center_top_1rem]">
        <div className="mb-3">
          <StepFormPaneHeader
            dayName="Challenge completed"
            isCompleted={false}
            isStarted={true}
            startTimestamp={finishAt}
            isActive={true}
          />
        </div>
        <img src="/images/challenges/finish-step-form-cup.svg" width="32px" height="32px" />

        <div className="text-xl font-semibold mt-3 mb-1 leading-6">Hold for applause 👏</div>

        <p className="mt-0 leading-5">
          Your did great! Now, start applying your new&nbsp;skills&nbsp;and&nbsp;knowledge.
        </p>
      </div>
      <p className="mb-0">
        <b>Here are some tips on how to use this momentum to continue your journey:</b>
        <br />
        💖 share your <b>feedback</b>
        <br />
        🙌 <b>help other learners</b> in the chat
        <br />
        📲 <b>post</b> about your achievement on social media
        <br />
        👀 <b>explore the app</b> for more challenges!
      </p>
    </>
  );
};
