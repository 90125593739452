import { uploadFileToAWS } from '@sp/data/bif';
import { AttachmentVideoView, useVideoPreviewUrl } from '@sp/ui/chat';
import { Button } from '@sp/ui/elements';
import { IconPlay } from '@sp/ui/icons';
import { FullScreenImageCropper, useSelectCropModel } from '@sp/ui/image-cropper';
import { Modal } from '@sp/ui/modal';
import { isUploadable, UploadableVideoAttachment, ViewVideoAttachment } from '@sp/util/chat-attachments';
import { dataURItoBlob, optimizeImage } from '@sp/util/files';
import { isNotNullish } from '@sp/util/helpers';
import { VideoAttachment } from '@sp/util/stream-chat';
import { ReactElement, useCallback, useState, VFC } from 'react';

const VideoPreview: VFC<{
  attachment: VideoAttachment;
  controls?: boolean;
  onDurationUpdate?: (duration: number) => void;
}> = ({ attachment, onDurationUpdate, controls = false }) => {
  const videoUrl = attachment.originalUrl || attachment.url;
  const poster = useVideoPreviewUrl(attachment.previewUrl);

  return (
    <div className="w-full aspect-square aspect-ratio-wrap">
      <video
        src={videoUrl ?? ''}
        preload="metadata"
        tabIndex={-1}
        controls={controls}
        controlsList="nodownload"
        poster={poster}
        onDurationChange={e => onDurationUpdate?.((e.target as HTMLMediaElement).duration)}
        className="object-cover bg-accent overflow-hidden rounded-1.5lg"
      />
    </div>
  );
};

export function EditorAttachmentVideoView({
  attachment,
  onUpdate,
}: {
  attachment: UploadableVideoAttachment | ViewVideoAttachment;
  onUpdate: (attachment: ViewVideoAttachment) => void;
}): ReactElement {
  const isUploadableAttachment = isUploadable(attachment);
  const [isChangeCoverModalOpen, setIsChangeCoverModalOpen] = useState(false);
  const [isNewCoverUploading, setIsNewCoverUploading] = useState(false);
  const { selectImage, reset, isSelected, selectedImage, selectedImageDataUri } = useSelectCropModel();

  const handleChangeCoverClick = useCallback(() => {
    if (isUploadableAttachment) {
      return;
    }
    setIsChangeCoverModalOpen(true);
  }, [isUploadableAttachment]);

  const handleChangeCoverResult = useCallback(
    async (resizedCoverUri: string) => {
      if (isNotNullish(selectedImage)) {
        const name = selectedImage.name;
        const type = selectedImage.type;
        const newCover = await optimizeImage(new File([dataURItoBlob(resizedCoverUri)], name, { type }));
        reset();
        setIsNewCoverUploading(true);
        try {
          const { url } = await uploadFileToAWS(newCover);
          onUpdate({ ...attachment, previewUrl: url });
          setIsChangeCoverModalOpen(false);
        } finally {
          setIsNewCoverUploading(false);
        }
      }
    },
    [attachment, onUpdate, reset, selectedImage],
  );

  if (isUploadableAttachment) {
    return (
      <div className="relative overflow-hidden rounded-1.5lg bg-secondary border border-stroke w-full">
        <VideoPreview attachment={attachment} />
      </div>
    );
  }

  return (
    <>
      <div
        role="button"
        onClick={handleChangeCoverClick}
        className="relative overflow-hidden rounded-1.5lg bg-secondary border border-stroke w-full"
      >
        <VideoPreview attachment={attachment} />

        <div className="absolute left-0 top-0 w-full h-full flex items-center justify-center bg-accent bg-opacity-30 text-active overflow-hidden rounded-1.5lg">
          <IconPlay size={24} filled className="opacity-80" />
        </div>
      </div>

      <Modal
        isOpen={isChangeCoverModalOpen}
        onClose={() => setIsChangeCoverModalOpen(false)}
        withBackdrop
        closeOnBackdropClick
      >
        <div className="max-w-[260px] mx-auto">
          <AttachmentVideoView attachment={attachment} />

          <Button
            block
            disabled={isNewCoverUploading}
            loading={isNewCoverUploading}
            color="primary"
            onClick={selectImage}
            className="mt-4"
          >
            Change cover
          </Button>
        </div>
      </Modal>

      <FullScreenImageCropper
        isOpen={isSelected}
        src={selectedImageDataUri}
        onResult={handleChangeCoverResult}
        onCancel={reset}
      />
    </>
  );
}
