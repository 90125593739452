import { $isNativeIos } from '@sp/util/native';
import { useStore } from 'effector-react';
import { useState } from 'react';
import { LoginForm } from './login-form';
import { MagicLinkForm } from './magic-link-form';
import { OauthAppleForm } from './oauth-apple-form';
import { OauthFacebookForm } from './oauth-facebook-form';
import { OauthGoogleForm } from './oauth-google-form';

export function LoginPage() {
  const [isPasswordVisible] = useState(false);
  const isNativeIos = useStore($isNativeIos);

  return (
    <div className="h-full flex flex-col">
      <div className="flex-1 py-8 mobile-pan">
        <div className="overflow-hidden h-[11rem]">
          <div className="whitespace-nowrap leading-[0] animate-login-avatars">
            <div className="bg-creator-photo-1 bg-cover inline-block ml-5 bg-stroke w-[11rem] h-[11rem] rounded-r-[4rem]"></div>
            <div className="bg-creator-photo-2 bg-cover inline-block ml-4 bg-stroke w-[11rem] h-[11rem] rounded-b-[4rem]"></div>
            <div className="bg-creator-photo-3 bg-cover inline-block ml-4 bg-stroke w-[11rem] h-[11rem] rounded-r-[4rem]"></div>
            <div className="bg-creator-photo-4 bg-cover inline-block ml-4 bg-stroke w-[11rem] h-[11rem] rounded-b-[4rem]"></div>
          </div>
        </div>

        <div className="px-5 mt-8">
          {/* Do not remove implicit image size. It prevents blinking */}
          <img src="images/login/title.svg" alt="Welcome to Self+" height="107" width="207" />
          <p className="m-0">
            Become better with exceptional <b>creators</b>
          </p>
          <div className="flex flex-row justify-between gap-4 mt-8">
            {isNativeIos ? (
              <div className="flex-1">
                <OauthAppleForm />
              </div>
            ) : null}
            <div className="flex-1">
              <OauthGoogleForm />
            </div>
            <div className="flex-1">
              <OauthFacebookForm />
            </div>
          </div>

          <div className="my-6 relative text-center leading-4">
            <span className="inline-block bg-primary text-disabled text-xs px-3">or</span>
            <hr className="absolute border-disabled w-full m-0 top-[0.55rem] -z-10" />
          </div>

          {isPasswordVisible ? <LoginForm /> : <MagicLinkForm />}
        </div>

        <div className="pt-6 px-5">
          <p className="m-0 text-xs text-secondary">
            By using our app or services provided by us , you agree to our{' '}
            {/* eslint-disable-next-line react/jsx-no-target-blank */}
            <a
              href="https://selfplus.com/legal/terms_of_service"
              target="_blank"
              rel="noopener"
              className="text-brand hover:underline"
            >
              Terms of Service
            </a>{' '}
            and acknowledge our {/* eslint-disable-next-line react/jsx-no-target-blank */}
            <a
              href="https://selfplus.com/legal/privacy_policy"
              target="_blank"
              rel="noopener"
              className="text-brand hover:underline"
            >
              Privacy&nbsp;Policy
            </a>
            , and represent that you are at least 18 years of age.
          </p>
        </div>
      </div>
    </div>
  );
}
