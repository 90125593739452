import { Button } from '@sp/ui/elements';
import { cls, isNotNullish } from '@sp/util/helpers';
import { ROUTES } from '@sp/util/router';
import { format } from 'date-fns';
import { useGate, useStore } from 'effector-react';
import { FormEvent, useCallback, useEffect, useMemo, VFC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ChannelEditorForm } from './channel-editor-form';
import { CHANNEL_EDITOR_MODEL, CHANNEL_SPACE_RESOURCE_MODEL, ChannelGate } from './channel-editor-model';
import { CHANNEL_FORM_MODEL } from './editor-form-model';

export const CreateChannelScreen: VFC = () => {
  const params = useParams<typeof ROUTES.STUDIO.SPACE_ID_PARAM>();
  const spaceId = isNotNullish(params.spaceId) ? Number(params.spaceId) : null;
  useGate(ChannelGate, spaceId);
  const isLoading = useStore(CHANNEL_EDITOR_MODEL.$isLoading);
  const isPublishing = useStore(CHANNEL_EDITOR_MODEL.$isPublishing);
  const isPublished = useStore(CHANNEL_EDITOR_MODEL.$isPublished);
  const isValid = useStore(CHANNEL_FORM_MODEL.$isValid);
  const isDirty = useStore(CHANNEL_FORM_MODEL.$isDirty);
  const handleSubmit = useCallback((e: FormEvent) => {
    e.preventDefault();
    CHANNEL_FORM_MODEL.submit();
  }, []);

  const space = useStore(CHANNEL_SPACE_RESOURCE_MODEL.$data);
  const updatedAt = useMemo(() => format(space?.updated_at ?? 0, `yyyy-MM-dd HH:mm:ss`), [space?.updated_at]);

  const navigate = useNavigate();
  useEffect(() => CHANNEL_EDITOR_MODEL.created.watch(channelSpace => navigate(`./${channelSpace.id}`)), [navigate]);

  return (
    <form
      className={cls(isLoading ? 'animate-pulse pointer-events-none' : undefined, 'h-full overflow-y-auto mobile-pan')}
      onSubmit={handleSubmit}
    >
      <div className="p-4">
        {space ? (
          <div className="m-4 flex flex-row justify-between items-start">
            <span className="mr-2">
              Space ID: <strong>{space.id}</strong>
              <br />
              Creator:{' '}
              <strong>
                {space.owner.name} ({space.owner.id})
              </strong>
              <br />
              Last Update: <strong>{updatedAt}</strong>
            </span>
            {isPublished ? (
              <Button color="primary" disabled>
                Published
              </Button>
            ) : (
              <Button
                color="primary"
                loading={isPublishing}
                disableOnLoad
                onClick={() => CHANNEL_EDITOR_MODEL.publish()}
              >
                Publish
              </Button>
            )}
          </div>
        ) : null}
        <ChannelEditorForm />
        <div className="flex flex-col md:justify-start md:flex-row-reverse mt-4 gap-4">
          <Button type="submit" color="primary" loading={isLoading} disableOnLoad disabled={!isDirty || !isValid}>
            save
          </Button>
        </div>
      </div>
    </form>
  );
};
