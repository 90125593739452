import { getResponsiveAWSImageSrc, GRAY_PIXEL_IMG_SRC, RETINA_PIXEL_DENSITY } from '@sp/util/files';
import { cls, combineRefs, forwardRefWithDefault, isNotNullish, updateRef } from '@sp/util/helpers';
import {
  ForwardedRef,
  ImgHTMLAttributes,
  memo,
  MutableRefObject,
  ReactElement,
  Ref,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import useResizeObserver from 'use-resize-observer';

function useAWSResponsiveSrc(src: string, ref: MutableRefObject<HTMLImageElement> | null, maxWidth: number): string {
  const { width = 0 } = useResizeObserver({ ref });
  const responsiveSrc = useMemo(
    () => getResponsiveAWSImageSrc(src, Math.min(width, maxWidth) * RETINA_PIXEL_DENSITY),
    [maxWidth, src, width],
  );
  return responsiveSrc;
}

function isMutableRefObject<T>(ref: ForwardedRef<T>): ref is MutableRefObject<T> {
  return isNotNullish(ref) && typeof ref === 'object' && 'current' in ref;
}

function AWSResponsiveImageComponent(
  props: ImgHTMLAttributes<HTMLImageElement> & {
    src: string;
    maxWidth?: number;
    srcRef?: Ref<string | null>;
  },
  externalRef?: ForwardedRef<HTMLImageElement>,
): ReactElement {
  const ref = useRef<HTMLImageElement>(null);
  const setRef = useMemo(() => combineRefs(externalRef, ref), [externalRef]);
  const { src, alt, className, maxWidth = Infinity, srcRef, ...rest } = props;
  const responsiveSrc = useAWSResponsiveSrc(src, isMutableRefObject(ref) ? ref : null, maxWidth);
  const realSrc = isMutableRefObject(ref) && ref.current ? responsiveSrc : GRAY_PIXEL_IMG_SRC;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => updateRef(srcRef, null), []);
  useEffect(() => updateRef(srcRef, realSrc), [srcRef, realSrc]);

  return (
    <img ref={setRef} src={realSrc} alt={alt} className={cls(className, 'image-rendering-crisp-edges')} {...rest} />
  );
}

const AWSResponsiveImageForwarded = forwardRefWithDefault(AWSResponsiveImageComponent);

export const AWSResponsiveImage = memo(AWSResponsiveImageForwarded);
