import { ENV_MODE } from '@sp/data/env';
import { registerLocalStorageCache } from '@sp/util/cache';
import { ChatMessageViewAttachment } from '@sp/util/chat-attachments';
import { generateUniqueString, isNotNullish } from '@sp/util/helpers';
import { ChatFormatMessage, ChatNonFormatMessage, SelfplusExtraMessageFields } from '@sp/util/stream-chat';
import debounce from 'lodash/debounce';

const MESSAGE_DRAFTS_STORAGE_KEY = 'sp_message_drafts';
registerLocalStorageCache(MESSAGE_DRAFTS_STORAGE_KEY);

export type ChatMessageDraft = {
  draftId: string;
  text: string;
  attachments: ChatMessageViewAttachment[];
  pinned: boolean;
  extraFields: SelfplusExtraMessageFields;
  meta: ChatFormatMessage['selfplusMessageMeta'];
  mentionedUserIds: string[];
  editedMessage: ChatNonFormatMessage | null;
  quotedMessage: ChatNonFormatMessage | null;
};

export type ChatMessageDraftKey = string;
export type ChatMessageDraftsMap = Record<ChatMessageDraftKey, ChatMessageDraft>;

function getInitialDraft(data?: {
  defaultExtraFields?: SelfplusExtraMessageFields;
  defaultFields?: Partial<ChatFormatMessage>;
}): ChatMessageDraft {
  const prefix = ENV_MODE !== 'production' ? 'dev_' : 'prod_';
  return {
    draftId: `${prefix}${generateUniqueString()}`,
    text: '',
    attachments: [],
    pinned: data?.defaultFields?.pinned ?? false,
    extraFields: data?.defaultExtraFields ?? {},
    meta: data?.defaultFields?.selfplusMessageMeta ?? {},
    mentionedUserIds: [],
    editedMessage: null,
    quotedMessage: null,
  };
}

let DRAFTS_MAP_CACHE: ChatMessageDraftsMap | null = null;

const saveDraftsDebounced = debounce(() => {
  if (DRAFTS_MAP_CACHE) {
    localStorage.setItem(MESSAGE_DRAFTS_STORAGE_KEY, JSON.stringify(DRAFTS_MAP_CACHE));
  }
}, 100);

function getDraftsMap(): ChatMessageDraftsMap {
  if (!DRAFTS_MAP_CACHE) {
    try {
      DRAFTS_MAP_CACHE = JSON.parse(
        window.localStorage.getItem(MESSAGE_DRAFTS_STORAGE_KEY) ?? '{}',
      ) as ChatMessageDraftsMap;
    } catch (e) {
      console.error(e);
      return {} as ChatMessageDraftsMap;
    }
  }
  return DRAFTS_MAP_CACHE;
}

export function updateDraft(formId: string, draft: Partial<ChatMessageDraft>) {
  const draftsMap = getDraftsMap();
  draftsMap[formId] = { ...(draftsMap[formId] ?? getInitialDraft()), ...draft };
  DRAFTS_MAP_CACHE = draftsMap;
  saveDraftsDebounced();
}

export function resetDraft(
  formId: string,
  data?: { defaultExtraFields?: SelfplusExtraMessageFields; defaultFields?: Partial<ChatFormatMessage> },
): ChatMessageDraft {
  const draft = getInitialDraft(data);
  updateDraft(formId, draft);
  return draft;
}

export function getDraft(
  formId: string,
  data?: { defaultExtraFields?: SelfplusExtraMessageFields; defaultFields?: Partial<ChatFormatMessage> },
): ChatMessageDraft {
  const draftsMap = getDraftsMap();
  const draft = draftsMap[formId];
  return isNotNullish(draft) ? draft : resetDraft(formId, data);
}
