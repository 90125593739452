import {
  attach,
  clearNode,
  createEffect,
  createEvent,
  createStore,
  Domain,
  Effect,
  Event,
  forward,
  is,
  merge,
  restore,
  sample,
  Store,
  Subscription,
  Unit,
} from 'effector';
import isEqual from 'lodash/isEqual';
import { condition } from 'patronum';

function createSubscription(unsubscribe: VoidFunction): Subscription {
  const result: Subscription = unsubscribe as Subscription;
  result.unsubscribe = result;
  return result;
}

export function createOnceEvent<E>(event: Event<E>, domain?: Domain): Event<E> {
  const to = domain ? domain.event<E>() : createEvent<E>();
  const unsubscribe = forward({ from: event, to });
  event.watch(unsubscribe);
  return to;
}

// export function createOnceEffect<Params, Done, Fail = Error>(effect: Effect<Params, Done, Fail>) {
//   const trigger = createEffect({ handler: effect });
//   trigger.watch(() => trigger.use(() => {}));
//   return trigger;
// }

export function createAbortableEffect<Params, Done, Fail = Error>(
  handler: (params: Params, signal: AbortSignal) => Promise<Done>,
): Effect<Params, Done, Fail> {
  const $controller = createStore(new AbortController());

  const abortFx = attach({
    source: $controller,
    effect(controller) {
      controller.abort();
      return new AbortController();
    },
  });

  $controller.on(abortFx.doneData, (_, controller) => controller);

  return createEffect<Params, Done, Fail>(async (params: Params): Promise<Done> => {
    const { signal } = await abortFx();
    return handler(params, signal);
  });
}

export function bufferEvent<T>({ source, open }: { source: Event<T>; open: Event<boolean> }): Event<T> {
  const output = createEvent<T>();

  const $buffer = createStore<T[]>([]);

  const flushBuffer = sample({
    clock: [source, open],
    source: restore(open, false),
    filter: isOpen => isOpen,
    fn: () => {
      return;
    },
  });

  const flushBufferFx = attach({
    source: $buffer,
    effect: buffer => {
      for (const item of buffer) {
        output(item);
      }
    },
  });

  forward({ from: flushBuffer, to: flushBufferFx });

  $buffer.on(source, (buffer, item) => [...buffer, item]);

  $buffer.on(flushBufferFx.done, () => []);

  return output;
}

type EventOrStore<T> = Store<T> | Event<T>;

export function switchMap<SourceValue, TargetValue>({
  source,
  fn,
  target,
}: {
  source: EventOrStore<SourceValue>;
  fn: (value: SourceValue) => EventOrStore<TargetValue> | undefined;
  target: EventOrStore<TargetValue>;
}): Subscription {
  let innerSub: Subscription | null = null;

  const outerSub = source.watch(value => {
    innerSub?.unsubscribe();
    innerSub = null;

    const inner = fn(value);

    if (inner) {
      if (is.event(target)) {
        innerSub = inner.watch(target);
      } else if (is.store(target)) {
        innerSub = forward({ from: inner, to: target });
      }

      if (is.store(inner)) {
        const firstChange = createEvent<TargetValue>();
        const firstChangeSub = forward({ from: firstChange, to: target });
        firstChange(inner.getState());
        firstChangeSub.unsubscribe();
        clearNode(firstChange);
      }
    }
  });

  const unsubscribe = () => {
    innerSub?.unsubscribe();
    outerSub.unsubscribe();
  };

  return createSubscription(unsubscribe);
}

function _createResource<P, T>(loadFx: Effect<P, T>) {
  const load = createEvent<P>();
  const reset = createEvent();
  const replace = createEvent<T>();

  const $data = restore(loadFx.doneData, null)
    .on(replace, (state, newState) => newState)
    .reset([reset, loadFx.fail]);
  const $isLoading = loadFx.pending;
  const $loadingError = restore(loadFx.failData, null).reset([reset, loadFx.done, replace]);
  const $isFirstLoadFinished = restore(
    merge([loadFx.finally, replace]).map(() => true),
    false,
  ).reset(reset);

  sample({
    clock: load,
    source: loadFx.pending,
    filter: pending => !pending,
    fn: (_, params) => params,
    target: loadFx,
  });

  return {
    load,
    reset,
    replace,
    $data,
    $isLoading,
    $loadingError,
    $isFirstLoadFinished,
  } as const;
}

export function createResource<P, T>(fetchResource: (params?: P) => Promise<T>) {
  return _createResource(createEffect(fetchResource));
}

export function createResourceWithParams<T, Args extends ReadonlyArray<unknown>>({
  fetchResource,
  $args,
  loadOnArgsChange = false,
  isArgsEqual = isEqual,
}: {
  fetchResource: (...args: Args) => Promise<T>;
  $args: Store<Args>;
  loadOnArgsChange?: boolean;
  isArgsEqual?: (a: Args, b: Args) => boolean;
}) {
  const loadFx = createEffect((args: Args) => fetchResource(...args));

  const $memoizedArgs = $args.map<Args>((next, current) => {
    if (current === undefined) return next;
    return isArgsEqual(current, next) ? current : next;
  });

  const curriedLoadFx = attach({
    source: $memoizedArgs,
    mapParams: (_: void, args: Args) => args,
    effect: loadFx,
  });

  const res = _createResource(curriedLoadFx);

  if (loadOnArgsChange) {
    sample({
      clock: $memoizedArgs,
      target: res.load,
    });
  }

  return res;
}

function unwrapData<T>(target: Unit<T>) {
  const e = createEvent<{ data: T; isReady: boolean }>();

  sample({
    clock: e,
    fn: ({ data }) => data,
    target,
  });

  return e;
}

export function waitUntil<T = void>({ clock, until }: { clock: Event<T>; until: Store<boolean> }): Event<T> {
  const newEvent = createEvent<T>();

  const wait = createEffect((data: T) => {
    return new Promise<T>(resolve => {
      const sub = until.watch(isReady => {
        if (!isReady) return;
        sub.unsubscribe();
        resolve(data);
      });
    });
  });

  sample({
    clock: wait.doneData,
    target: newEvent,
  });

  condition({
    source: sample({ clock, source: until, fn: (isReady, data) => ({ isReady, data }) }),
    if: v => v.isReady,
    then: unwrapData(newEvent),
    else: unwrapData(wait),
  });

  return newEvent;
}

export async function waitUntilTrue(store: Store<boolean>): Promise<void> {
  return new Promise<void>(resolve => {
    if (store.getState()) {
      resolve();
    }

    const sub = store.watch(value => {
      if (value) {
        setTimeout(() => sub.unsubscribe());
        resolve();
      }
    });
  });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function forwardMap<V>({ from, to }: { from: Event<V[]>; to: Event<V> | Effect<V, any> }): Subscription {
  return from.watch(values => {
    for (const value of values) {
      to(value);
    }
  });
}
