import { useUser } from '@sp/data/auth';
import { isAfter } from 'date-fns';
import { useStore } from 'effector-react';
import { FC, useEffect, useMemo, useState } from 'react';
import { useScheduledActualDate } from '../challenge-helpers';
import { CHALLENGE_MODEL } from '../challenge-model';
import { ChallengeStepWithUI } from '../steps/';
import { FinishPosts, IntroPosts, StepPosts } from './posts';
import { StepDayHeader, StepFinishHeader, StepIntroHeader } from './step-header';
import { StepView } from './step-view';

const FIRST_RENDER_ACTIVE_INDEX = -2;

export const StepsListView: FC<{ showPaywall: boolean; stepsWithUI: ChallengeStepWithUI[] }> = ({
  stepsWithUI,
  showPaywall,
}) => {
  const user = useUser();
  const challenge = useStore(CHALLENGE_MODEL.$challenge);
  const now = useScheduledActualDate(
    useMemo(() => stepsWithUI.map(({ step }) => new Date(step.startAt)), [stepsWithUI]),
  );
  const isOwner = challenge.creator?.id === user.id;
  const isPurchased = challenge.isPurchased;
  // Индексы первой и последеней вкладок
  const introIndex = 0;
  const finishIndex = stepsWithUI.length + 1;
  // Даты начала и конца челенджа
  const finishTimestamp = stepsWithUI[stepsWithUI.length - 1]?.step.endAt ?? 0;
  const startTimestamp = stepsWithUI[0]?.step.startAt ?? 0;
  const isFinished = isAfter(now, finishTimestamp);
  const isStarted = isAfter(now, startTimestamp);
  const isIntroActive = !isStarted && (isPurchased || isOwner);
  const isFinishActive = isFinished && (isPurchased || isOwner);
  const [activeStepIndex, setActiveStepIndex] = useState(FIRST_RENDER_ACTIVE_INDEX);

  useEffect(() => {
    if (activeStepIndex === FIRST_RENDER_ACTIVE_INDEX && (isPurchased || isOwner)) {
      if (!isStarted) {
        return setActiveStepIndex(introIndex);
      }
      if (isFinished) {
        return setActiveStepIndex(finishIndex);
      }
      if (stepsWithUI.length > 0) {
        const index = stepsWithUI.findIndex(({ step }) => !step.result.isCompleted && isAfter(now, step.startAt)) + 1; // +1 because of intro
        return setActiveStepIndex(index === 0 ? -1 : index);
      }
    }
  }, [activeStepIndex, finishIndex, isFinished, isOwner, isPurchased, isStarted, now, stepsWithUI]);

  function toggleStep(index: number) {
    setActiveStepIndex(activeStepIndex => (activeStepIndex === index ? -1 : index));
  }

  return (
    <div className="flex flex-col divide-y divide-stroke">
      {/*Intro view*/}
      <StepView
        isActive={isIntroActive}
        header={<StepIntroHeader />}
        isOpen={introIndex === activeStepIndex}
        onToggle={() => toggleStep(introIndex)}
      >
        <IntroPosts />
      </StepView>

      {/*Steps views*/}
      {stepsWithUI.map((stepWithUI, index) => {
        const { step } = stepWithUI;
        const stepIndex = index + 1; // +1 because of intro
        const isStepStarted = isAfter(now, step.startAt);
        const isStepFinished = isAfter(now, step.endAt);
        const isStepActive = isStepStarted && !isStepFinished && !step.result.isCompleted && (isPurchased || isOwner);
        const header = (
          <StepDayHeader
            dayName={step.dayName}
            isCompleted={step.result.isCompleted}
            startTimestamp={step.startAt}
            isStarted={isStepStarted}
            isActive={isStepActive}
            isProgramPost={!!challenge.isProgram}
          />
        );

        return (
          <StepView
            header={header}
            isActive={isStepActive}
            key={stepIndex}
            isOpen={stepIndex === activeStepIndex}
            onToggle={() => toggleStep(stepIndex)}
          >
            <StepPosts stepWithUI={stepWithUI} />
          </StepView>
        );
      })}

      {/*Finish view*/}
      {!challenge.isProgram ? (
        <StepView
          disabled={showPaywall || !isFinished}
          isActive={isFinishActive}
          header={<StepFinishHeader finishTimestamp={finishTimestamp} isActive={isFinishActive} />}
          isOpen={finishIndex === activeStepIndex}
          onToggle={() => toggleStep(finishIndex)}
        >
          <FinishPosts />
        </StepView>
      ) : null}
    </div>
  );
};
