import { ChatQuotedMessageBodyView, QuotedMessageContentType, QuotedMessagePreview } from '@sp/ui/chat';
import { getImagePreviewUrl } from '@sp/util/chat-attachments';
import { noop } from '@sp/util/helpers';
import { ATTACHMENT_TYPE, ChatQuotedMessage } from '@sp/util/stream-chat';
import {
  EMPTY_MESSAGE_CONTENT,
  getAttachmentLabel,
  getChallengeJourneyMessageLabel,
  UNKNOWN_MESSAGE_AUTHOR,
} from '@sp/util/teaser';
import { isEmptyDoc, parseMessageContent } from '@sp/util/text-editor';
import { FC, ReactElement } from 'react';
import { useChatMessage } from './chat-message-context';

export const ChatMessageQuote = ({
  message,
  onClick = noop,
}: Readonly<{
  message: Pick<ChatQuotedMessage, 'text' | 'attachments' | 'user' | 'type' | 'id' | 'selfplusPostMeta'>;
  onClick?: (id: string) => void;
}>): ReactElement => {
  const user = message.user?.name;

  let content: { type: QuotedMessageContentType; value: string } | null = null;

  const doc = message.text ? parseMessageContent(message.text) : null;
  const text = doc == null || isEmptyDoc(doc) ? null : message.text;

  if (text != null) {
    content = { type: QuotedMessageContentType.MessageText, value: text };
  }

  let preview: QuotedMessagePreview | undefined;

  const attachment = message.attachments?.[0]?.selfplusAttachment;

  if (attachment) {
    if (content == null) {
      content = { type: QuotedMessageContentType.Attachment, value: getAttachmentLabel(attachment) };
    }

    if (attachment.type === ATTACHMENT_TYPE.image) {
      const previewUrl = getImagePreviewUrl(attachment);

      if (previewUrl) {
        preview = {
          url: previewUrl,
          name: attachment.name,
        };
      }
    }

    if (attachment.type === ATTACHMENT_TYPE.video) {
      const url = attachment.previewUrl;

      if (url) {
        preview = {
          url,
          name: attachment.name,
        };
      }
    }
  }

  if (content == null && message.selfplusPostMeta != null) {
    const label = getChallengeJourneyMessageLabel(message.selfplusPostMeta);
    if (label != null) {
      content = { type: QuotedMessageContentType.MessageText, value: label };
    }
  }

  if (content == null) {
    content = { type: QuotedMessageContentType.MessageText, value: EMPTY_MESSAGE_CONTENT };
  }

  return (
    <ChatQuotedMessageBodyView
      onClick={() => onClick(message.id)}
      content={content}
      user={user ?? UNKNOWN_MESSAGE_AUTHOR}
      preview={preview}
    />
  );
};

export const ChatMessageReply: FC = () => {
  const { message, model } = useChatMessage();

  let quotedMessage: ChatQuotedMessage | undefined;
  if (
    message.quoted_message_id != null &&
    message.quoted_message != null &&
    message.quoted_message.type !== 'deleted'
  ) {
    quotedMessage = message.quoted_message;
  }
  const onQuoteClick = (id: string) => {
    message.quoted_message_id && model.jumpedToMessage({ messageId: id });
  };

  return quotedMessage ? <ChatMessageQuote onClick={onQuoteClick} message={quotedMessage} /> : null;
};
