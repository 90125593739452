export interface IconProps {
  size?: number;
  color?: string;
  className?: string;
  filled?: boolean;
}

export const ICON_DEFAULTS: Required<IconProps> = {
  size: 32,
  color: 'currentColor',
  className: '',
  filled: false,
};
