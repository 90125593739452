import { checkFeatureFlag, FeatureFlag, SELFPLUS_ACCOUNT_URL } from '@sp/data/env';
import { SpinnerPage } from '@sp/ui/elements';
import { $isNativeApp } from '@sp/util/native';
import { createEffect, sample } from 'effector';
import { useStore } from 'effector-react';
import { FC } from 'react';

const initEmbeddedPageStorageKey = 'initEmbeddedPage';

let isEmbeddedPage = false;

function isInIframe() {
  return window.self !== window.parent;
}

try {
  isEmbeddedPage = localStorage.getItem(initEmbeddedPageStorageKey) === '1';
} finally {
  localStorage.removeItem(initEmbeddedPageStorageKey);
}

const isGuardBypassed = isEmbeddedPage || isInIframe() || checkFeatureFlag(FeatureFlag.bypassNativeAppGuard);

sample({
  source: $isNativeApp,
  filter: isNativeApp => !isGuardBypassed && isNativeApp === false,
  target: createEffect(() => {
    console.warn('NativeAppGuard test not passed, redirecting to account.');
    window.location.href = SELFPLUS_ACCOUNT_URL;
  }),
});

export const NativeAppGuard: FC = ({ children }) => {
  const isNativeApp = useStore($isNativeApp);
  return isGuardBypassed || isNativeApp ? <>{children}</> : <SpinnerPage />;
};
