import { Button, TextArea } from '@sp/ui/elements';
import { cls } from '@sp/util/helpers';
import { FC, useCallback, useMemo, useState } from 'react';

const EMOJI_RATE_LIST: { emoji: string; rate: number }[] = [
  { emoji: '😡', rate: 1 },
  { emoji: '🙁️', rate: 2 },
  { emoji: '😐', rate: 3 },
  { emoji: '😊', rate: 4 },
  { emoji: '😍', rate: 5 },
];

export const StepFeedbackFormView: FC<{
  isFeedbackSending: boolean;
  isFeedbackSkipping: boolean;
  onSkipFeedback: () => void;
  onSendFeedback: (feedback: { rate: number; message: string }) => void;
}> = ({ isFeedbackSending, onSkipFeedback, onSendFeedback, isFeedbackSkipping }) => {
  const [selectedRate, setSelectedRate] = useState(0);
  const [feedbackText, setFeedbackText] = useState('');
  const isCanSubmit = useMemo(() => selectedRate > 0 && selectedRate <= 5, [selectedRate]);
  const nothingSelected = selectedRate === 0;

  const submitFeedback = useCallback(() => {
    if (isCanSubmit) {
      onSendFeedback({ rate: selectedRate, message: feedbackText });
    }
  }, [feedbackText, isCanSubmit, onSendFeedback, selectedRate]);
  return (
    <>
      <div className="text-xl font-semibold mb-2 leading-6">
        Thoughts? Likes and dislikes?
        <br /> Any feedback is much appreciated.
      </div>
      <div className="text-sm text-secondary mb-3">Anything you share with us here is private and anonymous.</div>

      <div className="flex w-full justify-between mb-6">
        {EMOJI_RATE_LIST.map(({ emoji, rate }) => {
          const isSelected = selectedRate === rate;
          return (
            <button
              type="button"
              key={rate}
              onClick={() => setSelectedRate(rate)}
              className={cls(
                'relative flex items-center justify-center rounded-full h-14 w-14 text-[40px] -mx-2',
                isSelected && 'bg-brand-dirty-light opacity-100',
                !isSelected && !nothingSelected && 'opacity-50',
              )}
            >
              <span>{emoji}</span>
            </button>
          );
        })}
      </div>

      {nothingSelected ? null : (
        <TextArea
          value={feedbackText}
          onChange={({ target }) => {
            setFeedbackText(target.value);
          }}
          placeholder="Use this field to type up your feedback"
          block
          className="mb-6"
        />
      )}

      <Button
        loading={isFeedbackSending}
        disabled={!isCanSubmit || isFeedbackSending || isFeedbackSkipping}
        onClick={submitFeedback}
        color={!isCanSubmit || isFeedbackSending || isFeedbackSkipping ? 'gray' : 'primary'}
        block
        className="mb-2"
      >
        Submit
      </Button>

      <Button
        loading={isFeedbackSkipping}
        disabled={isFeedbackSending || isFeedbackSkipping}
        onClick={onSkipFeedback}
        color="transparent"
        block
      >
        Skip
      </Button>
    </>
  );
};
