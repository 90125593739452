import { ReactElement } from 'react';
import { ICON_DEFAULTS, IconProps } from './types';

export function IconLockOpen({
  size = ICON_DEFAULTS.size,
  color = ICON_DEFAULTS.color,
  className = ICON_DEFAULTS.className,
}: IconProps): ReactElement {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.5 7.5H9.375V4.875C9.375 4.17881 9.65156 3.51113 10.1438 3.01884C10.6361 2.52656 11.3038 2.25 12 2.25C12.6962 2.25 13.3639 2.52656 13.8562 3.01884C14.3484 3.51113 14.625 4.17881 14.625 4.875C14.625 5.07391 14.704 5.26468 14.8447 5.40533C14.9853 5.54598 15.1761 5.625 15.375 5.625C15.5739 5.625 15.7647 5.54598 15.9053 5.40533C16.046 5.26468 16.125 5.07391 16.125 4.875C16.125 3.78098 15.6904 2.73177 14.9168 1.95818C14.1432 1.1846 13.094 0.75 12 0.75C10.906 0.75 9.85677 1.1846 9.08318 1.95818C8.3096 2.73177 7.875 3.78098 7.875 4.875V7.5H4.5C4.10218 7.5 3.72064 7.65804 3.43934 7.93934C3.15804 8.22064 3 8.60218 3 9V19.5C3 19.8978 3.15804 20.2794 3.43934 20.5607C3.72064 20.842 4.10218 21 4.5 21H19.5C19.8978 21 20.2794 20.842 20.5607 20.5607C20.842 20.2794 21 19.8978 21 19.5V9C21 8.60218 20.842 8.22064 20.5607 7.93934C20.2794 7.65804 19.8978 7.5 19.5 7.5ZM12 15.375C11.7775 15.375 11.56 15.309 11.375 15.1854C11.19 15.0618 11.0458 14.8861 10.9606 14.6805C10.8755 14.475 10.8532 14.2488 10.8966 14.0305C10.94 13.8123 11.0472 13.6118 11.2045 13.4545C11.3618 13.2972 11.5623 13.19 11.7805 13.1466C11.9988 13.1032 12.225 13.1255 12.4305 13.2106C12.6361 13.2958 12.8118 13.44 12.9354 13.625C13.059 13.81 13.125 14.0275 13.125 14.25C13.125 14.5484 13.0065 14.8345 12.7955 15.0455C12.5845 15.2565 12.2984 15.375 12 15.375Z"
        fill={color}
      />
    </svg>
  );
}
