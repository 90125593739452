import { ActiveFromExtensions, AnyExtension, ChainedFromExtensions } from '@remirror/core';
import { useActive, useChainedCommands } from '@remirror/react';
import { cls, forwardRefWithDefault } from '@sp/util/helpers';
import { ForwardedRef, ReactElement, ReactNode, useCallback } from 'react';

export interface TextEditorCommandButtonProps<Extension extends AnyExtension = Remirror.Extensions> {
  readonly command?: keyof ChainedFromExtensions<Extension>;
  readonly name?: keyof ActiveFromExtensions<Extension>;
}

export const TextEditorCommandButton = forwardRefWithDefault(function TextEditorCommandButton(
  { name, command, children }: TextEditorCommandButtonProps & { children: ReactNode },
  ref: ForwardedRef<HTMLButtonElement>,
): ReactElement {
  const active = useActive();
  const chain = useChainedCommands();

  const isActive = name == null ? false : active[name]();

  const handleClick = useCallback(() => {
    if (command == null) {
      return;
    }

    // Too complex union type causes TS to fail.
    // https://github.com/microsoft/TypeScript/issues/33130
    (chain[command] as VoidFunction)();
    chain.focus();
    chain.run();
  }, [chain, command]);

  return (
    <button
      ref={ref}
      type="button"
      className={cls(
        'px-2 h-6 flex items-center justify-center',
        isActive ? 'text-brand' : 'bg-transparent text-disabled',
      )}
      onClick={handleClick}
    >
      {children}
    </button>
  );
});
