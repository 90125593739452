import { createEffect, restore, sample } from 'effector';
import { whenNativeAppInitialized } from './helpers';

const waitForInitFx = createEffect(whenNativeAppInitialized);
const readIsIosFx = createEffect(() => window.native.isiOs ?? false);
const readIsInAppFx = createEffect(() => window.isApp ?? false);

export const $isNativeIos = restore(readIsIosFx.doneData, false);
export const $isNativeApp = restore(readIsInAppFx.doneData, null);
export const $isNativeAppInitialized = restore(
  waitForInitFx.doneData.map(() => true),
  false,
);

sample({ clock: waitForInitFx.finally, target: [readIsIosFx, readIsInAppFx] });

waitForInitFx();
