import { ReactElement } from 'react';
import { ICON_DEFAULTS, IconProps } from './types';

export function IconHome({
  size = ICON_DEFAULTS.size,
  color = ICON_DEFAULTS.color,
  filled = ICON_DEFAULTS.filled,
  className = ICON_DEFAULTS.className,
}: IconProps): ReactElement {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      {filled ? (
        <path
          d="M27.3503 12.9625L17.3503 3.87495C16.9816 3.53765 16.5 3.35059 16.0003 3.35059C15.5006 3.35059 15.019 3.53765 14.6503 3.87495L4.65027 12.9625C4.44552 13.1498 4.282 13.3776 4.17009 13.6316C4.05819 13.8855 4.00035 14.16 4.00027 14.4375V25.95C3.99214 26.4542 4.17015 26.9437 4.50027 27.325C4.68763 27.538 4.91843 27.7085 5.17716 27.8249C5.43589 27.9413 5.71655 28.001 6.00027 28H12.0003C12.2655 28 12.5198 27.8946 12.7074 27.7071C12.8949 27.5195 13.0003 27.2652 13.0003 27V21C13.0003 20.7347 13.1056 20.4804 13.2932 20.2928C13.4807 20.1053 13.7351 20 14.0003 20H18.0003C18.2655 20 18.5198 20.1053 18.7074 20.2928C18.8949 20.4804 19.0003 20.7347 19.0003 21V27C19.0003 27.2652 19.1056 27.5195 19.2932 27.7071C19.4807 27.8946 19.7351 28 20.0003 28H26.0003C26.332 28.0029 26.659 27.9211 26.9503 27.7625C27.2673 27.5898 27.532 27.3351 27.7167 27.025C27.9014 26.715 27.9994 26.3609 28.0003 26V14.4375C28.0002 14.16 27.9423 13.8855 27.8304 13.6316C27.7185 13.3776 27.555 13.1498 27.3503 12.9625V12.9625Z"
          fill={color}
        />
      ) : (
        <path
          d="M19 25.9998V19.9998C19 19.7346 18.8946 19.4802 18.7071 19.2927C18.5196 19.1051 18.2652 18.9998 18 18.9998H14C13.7348 18.9998 13.4804 19.1051 13.2929 19.2927C13.1054 19.4802 13 19.7346 13 19.9998V25.9998C13 26.265 12.8946 26.5193 12.7071 26.7069C12.5196 26.8944 12.2652 26.9998 12 26.9998H6C5.73478 26.9998 5.48043 26.8944 5.29289 26.7069C5.10536 26.5193 5 26.265 5 25.9998V14.4373C5.00224 14.2989 5.03215 14.1623 5.08796 14.0357C5.14378 13.909 5.22437 13.7948 5.325 13.6998L15.325 4.61228C15.5093 4.44363 15.7501 4.3501 16 4.3501C16.2499 4.3501 16.4907 4.44363 16.675 4.61228L26.675 13.6998C26.7756 13.7948 26.8562 13.909 26.912 14.0357C26.9679 14.1623 26.9978 14.2989 27 14.4373V25.9998C27 26.265 26.8946 26.5193 26.7071 26.7069C26.5196 26.8944 26.2652 26.9998 26 26.9998H20C19.7348 26.9998 19.4804 26.8944 19.2929 26.7069C19.1054 26.5193 19 26.265 19 25.9998Z"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      )}
    </svg>
  );
}
