import { ReactElement } from 'react';
import { ICON_DEFAULTS, IconProps } from './types';

export function IconAdd({
  size = ICON_DEFAULTS.size,
  color = ICON_DEFAULTS.color,
  className = ICON_DEFAULTS.className,
}: IconProps): ReactElement {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.0024 7.49912L7.19298 15.4397C6.93445 15.7251 6.79553 16.0989 6.80501 16.4838C6.81449 16.8688 6.97163 17.2353 7.2439 17.5076C7.51617 17.7799 7.88271 17.937 8.26764 17.9465C8.65257 17.9559 9.0264 17.817 9.31174 17.5585L18.6211 8.11787C19.1382 7.5472 19.416 6.79953 19.3971 6.02968C19.3781 5.25982 19.0638 4.52673 18.5193 3.9822C17.9748 3.43766 17.2417 3.12338 16.4718 3.10443C15.7019 3.08547 14.9543 3.36329 14.3836 3.88037L5.07423 13.321C4.23134 14.1639 3.75781 15.3071 3.75781 16.4991C3.75781 17.6911 4.23134 18.8344 5.07423 19.6772C5.91713 20.5201 7.06033 20.9937 8.25236 20.9937C9.44439 20.9937 10.5876 20.5201 11.4305 19.6772L19.1274 11.9991"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
