import { noop } from '@sp/util/helpers';
import { useEffect, useMemo, useState } from 'react';

const DEFAULT_POLLING_RATE = 5000;

export function reloadVideoOnSourceError(
  video: HTMLVideoElement,
  onChangeUnplayable: (isUnplayable: boolean) => void,
  pollingRate = DEFAULT_POLLING_RATE,
): VoidFunction {
  let intervalId: number | null = null;
  let isUnplayable = false;

  const stopReloading = () => {
    if (intervalId === null) return;
    window.clearInterval(intervalId);
    intervalId = null;
  };

  const startReloading = () => {
    if (intervalId !== null) return;
    video.addEventListener('loadedmetadata', handleSuccess);
    intervalId = window.setInterval(() => video.load(), pollingRate);
  };

  const handleSuccess = () => {
    if (!isUnplayable) return;
    isUnplayable = false;
    stopReloading();
    onChangeUnplayable(isUnplayable);
  };

  const handleError = () => {
    if (isUnplayable) return;
    isUnplayable = true;
    startReloading();
    onChangeUnplayable(isUnplayable);
  };

  // If video src is set then sources won't be used.
  // We're only listening to the last source errors because
  // that means that all sources failed to play.
  const target = video.src !== '' ? video : video.querySelector<HTMLSourceElement>('source:last-child');

  if (!target) return noop;

  target.addEventListener('error', handleError);

  return () => {
    target.removeEventListener('error', handleError);
    video.removeEventListener('loadedmetadata', handleSuccess);
    stopReloading();
  };
}

export function useUnplayableVideoLoader(pollingRate = DEFAULT_POLLING_RATE) {
  const [video, setVideo] = useState<HTMLVideoElement | null>(null);
  const [isUnplayable, setIsUnplayable] = useState(false);

  useEffect(() => {
    if (!video) return;
    return reloadVideoOnSourceError(video, setIsUnplayable, pollingRate);
  }, [pollingRate, video]);

  return useMemo(() => ({ isUnplayable, setVideo } as const), [isUnplayable]);
}

// TODO: handle src change.
// const currentSrcRef = useRef<string>('');
//
// const reset = useCallback((src = '') => {
//   currentSrcRef.current = src;
//   setIsUnplayable(false);
// }, []);
//
// const handleEmptied = useCallback(() => {
//   if (!video) {
//     reset();
//     return;
//   }
//
//   if (currentSrcRef.current !== video.src) {
//     reset(video.src);
//   }
// }, [reset, video]);
//
// useEffect(() => {
//   if (!video) {
//     reset();
//     return;
//   }
//
//   reset(video.currentSrc);
//
//   video.addEventListener('emptied', handleEmptied);
//   return () => video?.removeEventListener('emptied', handleEmptied);
// }, [handleEmptied, reset, video]);
