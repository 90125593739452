import {
  CHALLENGE_POST_TYPE,
  CreateChallengePostDto,
  CreateChallengeStepDto,
  OwnedChallengeSpace,
  UpdateChallengePostDto,
  UpdateChallengeStepDto,
} from '@sp/data/bif';
import { Mutable } from '@sp/util/helpers';
import { ChatNonFormatMessage } from '@sp/util/stream-chat';

export type CreateChallengeDto = {
  title: string;
  startAt: number;
  stepsCount: number;
  slug: string;
  price: number;
  coverFileId: number;
  offerDescription: string;
  offerSummary: string;
  isProgram: boolean;
};

export type UpdatedChallengeDto = Mutable<
  Partial<
    Omit<OwnedChallengeSpace, 'id' | 'created_at' | 'updated_at' | 'type'> & {
      coverFileId: number;
    }
  > & { id: number }
>;

export const CHANGEABLE_CHALLENGE_FIELDS: (keyof UpdatedChallengeDto)[] = [
  'title',
  'price',
  'slug',
  'payment_type',
  'status',
  'config',
];
export const CHANGEABLE_STEP_FIELDS: (keyof UpdateChallengeStepDto)[] = [
  'title',
  'startAt',
  'isEnabled',
  'isSignificant',
  'estimatedEffort',
];
export const CHANGEABLE_POSTS_FIELDS: (keyof UpdateChallengePostDto)[] = [
  'type',
  'order',
  'content',
  'publishAt',
  'isDraft',
];

export type SaveChallengeDto = {
  challenge: UpdatedChallengeDto;
  steps: (UpdateChallengeStepDto | CreateChallengeStepDto)[];
  posts: (UpdateChallengePostDto | CreateChallengePostDto)[];
};

export type Step = Readonly<{
  id: number;
  title: string;
  startTime: { hours: number; minutes: number };
  isSignificant: boolean;
  estimatedEffort: number;
}>;

type PostBase = Readonly<{
  id: number;
  content: Pick<ChatNonFormatMessage, 'text' | 'attachments'>;
}>;

export type IntroPost = PostBase & {
  type: CHALLENGE_POST_TYPE.intro;
};

export type StepPost = PostBase & {
  type: CHALLENGE_POST_TYPE.step;
};

export type FinishPost = PostBase & {
  type: CHALLENGE_POST_TYPE.finish;
};

export type DeferredPost = PostBase & {
  type: CHALLENGE_POST_TYPE.deferred;
  publishAt: number;
};

export type Post = IntroPost | StepPost | FinishPost | DeferredPost;

export type Seed<T> = Omit<T, 'id'>;

export type StepsList = ReadonlyArray<Step | Seed<Step>>;
export type PostMapKeys = 'intro' | 'finish' | 'deferred' | Step['id'];
export type PostsMap = {
  intro: ReadonlyArray<IntroPost | Seed<IntroPost>>;
  finish: ReadonlyArray<FinishPost | Seed<FinishPost>>;
  deferred: ReadonlyArray<DeferredPost | Seed<DeferredPost>>;
  [key: Step['id']]: ReadonlyArray<Post | Seed<Post>>;
};
