import { ReactElement } from 'react';
import { ICON_DEFAULTS, IconProps } from './types';

export function IconArrowCounterClockwise({
  size = ICON_DEFAULTS.size,
  color = ICON_DEFAULTS.color,
  className = ICON_DEFAULTS.className,
}: IconProps): ReactElement {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M7.48145 9.34668H2.98145V4.84668"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.16895 17.8313C7.32246 18.9857 8.79246 19.7721 10.393 20.091C11.9935 20.4099 13.6527 20.2469 15.1605 19.6227C16.6684 18.9984 17.9573 17.941 18.8641 16.5842C19.7709 15.2273 20.255 13.632 20.255 12C20.255 10.368 19.7709 8.7727 18.8641 7.41585C17.9573 6.059 16.6684 5.00158 15.1605 4.37735C13.6527 3.75313 11.9935 3.59014 10.393 3.90902C8.79246 4.22789 7.32246 5.0143 6.16895 6.16875L2.98145 9.34688"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
