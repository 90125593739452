import { BuyButton } from '@sp/feature/buy-button';
import { IconLockedKey } from '@sp/ui/icons';
import { useFormatPrice } from '@sp/util/format';
import { ROUTES } from '@sp/util/router';
import { CHALLENGE_MODEL } from './challenge-model';
import { ChallengeInfo } from './challenge.types';

export const TrialHeader = ({ challenge }: Readonly<{ challenge: ChallengeInfo }>) => {
  const formatPrice = useFormatPrice();
  return (
    <div className="flex flex-row justify-between items-center h-14 px-6 bg-brand-medium text-active">
      <div className="flex-1">
        <p className="m-0 font-medium">
          <IconLockedKey size={16} className="inline align-[-2px] mr-2" />
          {`Join this ${challenge.isProgram ? 'program' : 'challenge'} for `}
          <b>{formatPrice(challenge.priceConfig)}</b>
        </p>
      </div>
      <BuyButton
        openUrl={`${ROUTES.CHALLENGE_PAGE_URL}/${challenge.id}`}
        price={challenge.priceConfig}
        title={challenge.title}
        bought={false}
        onResult={({ success }) => success && CHALLENGE_MODEL.refreshChallengeStarted()}
        id={challenge.id}
        iosProductId={challenge.iosProductId}
        creatorId={challenge.creator?.id ?? 0}
        creatorName={challenge.creator?.name ?? ''}
        color="black"
        size="xs"
        className="py-2 px-4"
      >
        Join
      </BuyButton>
    </div>
  );
};
