import { SPACE_PAYMENT_TYPE } from '@sp/data/bif';

export function isChallengePurchased(
  paymentType: SPACE_PAYMENT_TYPE,
  isSubscribed: boolean,
  isPurchased: boolean,
): boolean {
  if (paymentType === SPACE_PAYMENT_TYPE.fixed_price_subscribers_only && (isSubscribed || isPurchased)) {
    return true;
  }
  return (
    (paymentType === SPACE_PAYMENT_TYPE.subscribers_only && isSubscribed) ||
    (paymentType === SPACE_PAYMENT_TYPE.fixed_price && isPurchased)
  );
}
