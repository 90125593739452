import { ReactElement } from 'react';
import { ICON_DEFAULTS, IconProps } from './types';

export const IconLink = ({
  size = ICON_DEFAULTS.size,
  color = ICON_DEFAULTS.color,
  className = ICON_DEFAULTS.className,
}: IconProps): ReactElement => (
  <svg
    width={size}
    height={size}
    className={className}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.64316 4.46254L8.88066 3.22504C9.40461 2.74749 10.0924 2.49019 10.8011 2.5066C11.5099 2.52301 12.185 2.81186 12.6863 3.31315C13.1876 3.81443 13.4764 4.48959 13.4929 5.19832C13.5093 5.90705 13.252 6.59485 12.7744 7.11879L11.0057 8.88129C10.7508 9.13716 10.4478 9.34018 10.1143 9.47871C9.78072 9.61724 9.42309 9.68855 9.06191 9.68855C8.70074 9.68855 8.34311 9.61724 8.00956 9.47871C7.676 9.34018 7.37308 9.13716 7.11816 8.88129"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.35629 11.5385L7.11879 12.776C6.59485 13.2536 5.90705 13.5109 5.19832 13.4945C4.48959 13.478 3.81443 13.1892 3.31315 12.6879C2.81186 12.1866 2.52301 11.5115 2.5066 10.8027C2.49019 10.094 2.74749 9.4062 3.22504 8.88226L4.99379 7.11976C5.24871 6.86389 5.55163 6.66087 5.88518 6.52234C6.21874 6.38381 6.57636 6.3125 6.93754 6.3125C7.29872 6.3125 7.65634 6.38381 7.9899 6.52234C8.32346 6.66087 8.62638 6.86389 8.88129 7.11976"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
