import { differenceInMilliseconds } from 'date-fns';
import isAfter from 'date-fns/isAfter';
import { useEffect, useState } from 'react';

/**
 * Calls a callback and returns the value immediately and on the dates passed in the array
 * @param callback
 * @param scheduledAt - array of Date or timestamps
 */
export function useScheduledCallback<T>(callback: () => T, scheduledAt: (Date | number)[] = []): T {
  const [returnState, setReturnState] = useState<T>(callback());

  useEffect(() => {
    const timeoutIds: ReturnType<typeof setTimeout>[] = [];
    const now = new Date();

    scheduledAt.forEach(date => {
      const scheduledDate = date instanceof Date ? date : new Date(date);
      if (!isAfter(now, scheduledDate)) {
        timeoutIds.push(setTimeout(() => setReturnState(callback()), differenceInMilliseconds(date, now)));
      }
    });

    return () => timeoutIds.forEach(id => clearTimeout(id));
  }, [callback, scheduledAt]);

  return returnState;
}
