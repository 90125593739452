export function linearInterpolate(before: number, after: number, atPoint: number): number {
  return before + (after - before) * atPoint;
}

export function interpolateArray(data: readonly number[], fitCount: number): number[] {
  if (data.length === 0) {
    return [];
  }
  if (data.length === 1) {
    return Array(fitCount).fill(data[0]);
  }
  const newData: number[] = [];
  const springFactor = (data.length - 1) / (fitCount - 1);
  newData[0] = data[0];
  for (let i = 1; i < fitCount - 1; i++) {
    const tmp = i * springFactor;
    const before = Math.floor(tmp);
    const after = Math.ceil(tmp);
    const atPoint = tmp - before;
    newData[i] = linearInterpolate(data[before], data[after], atPoint);
  }
  newData[fitCount - 1] = data[data.length - 1];
  return newData;
}
