import { ReactElement } from 'react';
import { ICON_DEFAULTS, IconProps } from './types';

export function IconPlay({
  size = ICON_DEFAULTS.size,
  color = ICON_DEFAULTS.color,
  filled = ICON_DEFAULTS.filled,
  className = ICON_DEFAULTS.className,
}: IconProps): ReactElement {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      {filled ? (
        <path
          d="M14.2563 7.57445L5.2625 2.07445C5.18679 2.02775 5.09999 2.00209 5.01106 2.00012C4.92212 1.99816 4.83427 2.01995 4.75657 2.06326C4.67887 2.10657 4.61413 2.16982 4.56903 2.2465C4.52393 2.32317 4.5001 2.41049 4.5 2.49945V13.4994C4.5001 13.5884 4.52393 13.6757 4.56903 13.7524C4.61413 13.8291 4.67887 13.8923 4.75657 13.9356C4.83427 13.9789 4.92212 14.0007 5.01106 13.9988C5.09999 13.9968 5.18679 13.9712 5.2625 13.9244L14.2563 8.42445C14.3301 8.38078 14.3913 8.31862 14.4338 8.24409C14.4763 8.16956 14.4987 8.08525 14.4987 7.99945C14.4987 7.91365 14.4763 7.82934 14.4338 7.75481C14.3913 7.68028 14.3301 7.61812 14.2563 7.57445V7.57445Z"
          stroke={color}
          fill={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      ) : (
        <path
          d="M14.2563 7.57445L5.2625 2.07445C5.18679 2.02775 5.09999 2.00209 5.01106 2.00012C4.92212 1.99816 4.83427 2.01995 4.75657 2.06326C4.67887 2.10657 4.61413 2.16982 4.56903 2.2465C4.52393 2.32317 4.5001 2.41049 4.5 2.49945V13.4994C4.5001 13.5884 4.52393 13.6757 4.56903 13.7524C4.61413 13.8291 4.67887 13.8923 4.75657 13.9356C4.83427 13.9789 4.92212 14.0007 5.01106 13.9988C5.09999 13.9968 5.18679 13.9712 5.2625 13.9244L14.2563 8.42445C14.3301 8.38078 14.3913 8.31862 14.4338 8.24409C14.4763 8.16956 14.4987 8.08525 14.4987 7.99945C14.4987 7.91365 14.4763 7.82934 14.4338 7.75481C14.3913 7.68028 14.3301 7.61812 14.2563 7.57445V7.57445Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      )}
    </svg>
  );
}
