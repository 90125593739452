import { useUser } from '@sp/data/auth';
import { ANALYTICS } from '@sp/feature/analytics';
import { MessageExtraContent } from '@sp/feature/chat';
import { CHAT_CHANNEL } from '@sp/feature/chat-channel';
import { UseSpacialBadge } from '@sp/feature/space';
import { SpinnerPage } from '@sp/ui/elements';
import { isNotNullish, isNullish } from '@sp/util/helpers';
import { ChatFormatMessage } from '@sp/util/stream-chat';
import { isAfter } from 'date-fns';
import { useStore } from 'effector-react';
import { lazy, ReactElement, useEffect, useMemo, useState } from 'react';
import { useScheduledActualDate } from '../challenge-helpers';
import { CHALLENGE_MODEL } from '../challenge-model';
import { TrialHeader } from '../trial-header';
import { MessagePostExtraContent } from './message-post-extra-content';
import { MessagePostHighlightLine } from './message-post-highlight-line';

const CHAT_MESSAGE_FORM_TOOLTIP = {
  tooltipText: `Say “Hi! 👋” to everybody.
      Write your first message below!`,
  textForInput: `Hi! 👋`,
};

const Chat = lazy(() => import('@sp/feature/chat').then(m => ({ default: m.Chat })));
const TrialChat = lazy(() => import('./trial-chat').then(m => ({ default: m.TrialChat })));

export const ChatPage = (): ReactElement => {
  const chatModel = useStore(CHAT_CHANNEL.$instance);
  const challenge = useStore(CHALLENGE_MODEL.$challenge);
  const user = useUser();
  const now = useScheduledActualDate(useMemo(() => [new Date(challenge.startAt)], [challenge.startAt]));
  const isStarted = isAfter(now, challenge.startAt);
  const [isOpenTracked, setIsOpenTracked] = useState(false);
  useEffect(() => {
    CHALLENGE_MODEL.challengeVisited();
  }, []);

  useEffect(() => {
    if (isNotNullish(challenge) && isNotNullish(chatModel) && !isOpenTracked) {
      ANALYTICS.spaceOpenTracked();
      setIsOpenTracked(true);
    }
  }, [challenge, chatModel, isOpenTracked, user.isFirstChallengeVisited]);

  const [specialBadgeContent] = UseSpacialBadge(challenge.creator);
  const extraContent: MessageExtraContent = useMemo(
    () => ({
      beforeText: (message: ChatFormatMessage) => {
        if (isNullish(message.selfplusPostMeta)) return null;
        return <MessagePostExtraContent selfplusPostMeta={message.selfplusPostMeta} />;
      },
      afterAvatar: (message: ChatFormatMessage) => {
        if (isNullish(message.selfplusPostMeta) || isNullish(chatModel)) return null;
        return (
          <MessagePostHighlightLine
            selfplusPostMeta={message.selfplusPostMeta}
            chatModel={chatModel}
            messageId={message.id}
          />
        );
      },
      afterUserName: specialBadgeContent,
    }),
    [chatModel, specialBadgeContent],
  );

  const messageFormTooltip = !user.isFirstChallengeVisited ? CHAT_MESSAGE_FORM_TOOLTIP : null;
  const showTrialHeader = challenge.creator?.id !== user.id && !challenge.isPurchased && challenge.isTrial;
  const showPaywall = showTrialHeader && isStarted;

  const fullAcceptedFileAttachments = user.isAdmin || user.id === challenge.creator?.id;

  if (showPaywall) return <TrialChat chatModel={chatModel} challenge={challenge} />;

  return chatModel ? (
    <>
      {showTrialHeader && <TrialHeader challenge={challenge} />}
      <Chat
        chatModel={chatModel}
        messageFormTooltip={messageFormTooltip}
        messageExtraContent={extraContent}
        fullAcceptedFileAttachments={fullAcceptedFileAttachments}
      />
    </>
  ) : (
    <SpinnerPage />
  );
};
