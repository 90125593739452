import { IconCheck, IconFlagCheckered, IconHourglass } from '@sp/ui/icons';
import { humanizeDate } from '@sp/util/format';
import { cls } from '@sp/util/helpers';
import { FC } from 'react';

export const StepDayHeader: FC<{
  dayName: string;
  isCompleted: boolean;
  isStarted: boolean;
  startTimestamp: number;
  isActive?: boolean;
  isProgramPost?: boolean;
}> = ({ dayName, isCompleted = false, startTimestamp, isStarted, isActive = false, isProgramPost = false }) => {
  const icon = isCompleted ? (
    <div className="w-4 h-4 flex items-center justify-center rounded-full bg-brand-main text-active self-center">
      <IconCheck size={10} />
    </div>
  ) : !isStarted ? (
    <IconHourglass size={16} className="self-center" />
  ) : !isActive ? (
    <div className="w-4 h-4 flex items-center justify-center rounded-full bg-stroke text-active self-center">
      <IconCheck size={10} />
    </div>
  ) : null;

  return (
    <div className="flex flex-grow gap-1 items-baseline">
      {icon}
      <span
        className={cls(
          'font-medium text-left text-ellipsis whitespace-nowrap overflow-x-hidden',
          isActive ? 'text-active' : 'text-secondary',
        )}
      >
        {isProgramPost ? dayName : humanizeDate(startTimestamp)}
      </span>

      <div className="flex-grow" />

      <span className={cls('text-xs', 'whitespace-nowrap', isActive ? 'text-active' : 'text-secondary')}>
        {!isProgramPost ? dayName : null}
      </span>
    </div>
  );
};

export const StepFormPaneHeader: FC<{
  dayName: string;
  isCompleted: boolean;
  isStarted: boolean;
  startTimestamp: number;
  isActive?: boolean;
  isProgramPost?: boolean;
}> = ({ dayName, isCompleted = false, startTimestamp, isStarted, isActive = false, isProgramPost = false }) => {
  const icon = isCompleted ? (
    <div className="w-4 h-4 flex items-center justify-center rounded-full bg-brand-main text-active self-center">
      <IconCheck size={10} />
    </div>
  ) : !isStarted ? (
    <IconHourglass size={16} className="self-center" />
  ) : null;

  return (
    <div className="flex flex-grow gap-1 items-baseline">
      {icon}
      <span
        className={cls(
          'font-medium text-left text-ellipsis whitespace-nowrap overflow-x-hidden',
          isActive ? 'text-active' : 'text-secondary',
        )}
      >
        {isProgramPost ? dayName : humanizeDate(startTimestamp)}
      </span>

      <div className="flex-grow" />

      <span className={cls('text-xs', 'whitespace-nowrap', isActive ? 'text-active' : 'text-secondary')}>
        {!isProgramPost ? dayName : null}
      </span>
    </div>
  );
};

export const StepIntroHeader: FC = () => {
  return <div className="flex items-center gap-1 font-medium">Getting started</div>;
};

export const StepFinishHeader: FC<{
  finishTimestamp: number;
  isActive?: boolean;
}> = ({ finishTimestamp, isActive = false }) => {
  return (
    <div className="flex items-center gap-1">
      <IconFlagCheckered size={20} />
      <span className="font-semibold">{humanizeDate(finishTimestamp)}</span>

      <div className="flex-grow" />

      <span className={cls('text-xs', isActive ? 'text-active' : 'text-secondary')}>Finishing line</span>
    </div>
  );
};
