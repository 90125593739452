import { ENV_MODE, EnvMode } from '@sp/data/env';
import { ShowUserCardPayload } from '@sp/data/user-profile-card';
import { ANALYTICS } from '@sp/feature/analytics';
import { asyncWait, isNotNullish, Nullable, waitUntilTrue } from '@sp/util/helpers';
import { trackTime } from '@sp/util/native';
import {
  ATTACHMENT_TYPE,
  ChatChannel,
  ChatEvent,
  ChatEventTypes,
  ChatFormatMessage,
  ChatMessage,
  ChatPartialMessageUpdate,
  JumpEvent,
  KnownReaction,
  PLATFORM_USER,
} from '@sp/util/stream-chat';
import { combine, createEffect, createEvent, createStore, Event as EffectorEvent, forward, sample } from 'effector';
import { debounce } from 'patronum';
import { MessagePaginationOptions } from 'stream-chat';
import { GLOBAL_CHANNELS_INIT } from './global-channels-init-state';
import { StreamChatModel } from './stream-chat-model';

declare global {
  interface Window {
    streamChatChannel?: ChatChannel;
  }
}

export type CreateMessagePayload = {
  message: ChatMessage;
  formId: string;
};

function getMessageTrackEventProperties(message: ChatMessage) {
  return {
    Text: (message.text?.length ?? '') > 0 ? 'true' : 'false',
    Audio: message.attachments?.some(attachment => attachment.selfplusAttachment?.type === ATTACHMENT_TYPE.audio)
      ? 'true'
      : 'false',
    'Attached Video':
      message.attachments
        ?.filter(attachment => attachment.selfplusAttachment?.type === ATTACHMENT_TYPE.video)
        .length.toString() ?? '0',
    'Attached Photo':
      message.attachments
        ?.filter(attachment => attachment.selfplusAttachment?.type === ATTACHMENT_TYPE.image)
        .length.toString() ?? '0',
  };
}

export async function waitUntilChatInitialized(chat: StreamChatModel): Promise<void> {
  return waitUntilTrue(chat.$isInitialized);
}

async function createChannel(chat: StreamChatModel, channelId: string, type: 'messaging' | 'platform' = 'messaging') {
  await waitUntilChatInitialized(chat);

  const channel = chat.client.channel(type, channelId);

  await waitUntilTrue(GLOBAL_CHANNELS_INIT.$isGlobalChannelsInitialized);

  const messagesPaginationOptions: MessagePaginationOptions = {
    limit: 50,
  };

  let lastReadMessageDate: Date | null = null;
  const userId = channel.getClient().user?.id;

  if (userId != null) {
    lastReadMessageDate = channel.state.read[userId]?.last_read ?? null;
  }

  if (lastReadMessageDate != null) {
    messagesPaginationOptions.created_at_around = lastReadMessageDate;
  } else {
    console.warn(
      `Querying latest chat messages because read state is not available.`,
      JSON.stringify({ userId, readState: channel.state.read, channelId: channel.cid }),
    );
  }

  const MAX_RETRIES = 5;
  let triesCount = 0;
  const RETRY_INTERVAL = 500;

  function isNotAllowedError(e: unknown): boolean {
    return e != null && typeof e === 'object' && 'status' in e && (e as { status: number }).status === 403;
  }

  async function watchChannel() {
    triesCount++;
    try {
      await channel.watch({
        messages: messagesPaginationOptions,
        state: true,
        watch: true,
        presence: false,
      });
    } catch (e) {
      if (triesCount <= MAX_RETRIES && isNotAllowedError(e)) {
        await asyncWait(triesCount * RETRY_INTERVAL);
        await watchChannel();
      } else {
        throw e;
      }
    }
  }

  await watchChannel();

  if (ENV_MODE !== EnvMode.production) {
    window.streamChatChannel = channel;
    console.info('Stream Chat Channel API: window.streamChatChannel');
  }

  return channel;
}

interface ReactionData {
  readonly messageId: string;
  readonly type: KnownReaction | string;
}

export type MessageUpdate = Readonly<{ id: string; update: ChatPartialMessageUpdate }>;

export async function createChatChannelModel(
  chat: StreamChatModel,
  channelId: string,
  channelType?: 'messaging' | 'platform',
) {
  const channel = await createChannel(chat, channelId, channelType);

  const subscriptions: Array<(() => Promise<void> | void) | { unsubscribe: () => void }> = [];

  const destroy = async () =>
    Promise.all(subscriptions.map(sub => (typeof sub === 'function' ? sub() : sub.unsubscribe()))).then(() => void 0);

  // FIXME[Dmitriy Teplov] channels are reused in home so
  //  the reactive updates will break
  //  if we stop watching the channel on exit.
  // subscriptions.push(async () => {
  //   await channel.stopWatching();
  // });

  const channelEventReceived = createEvent<ChatEvent>();

  function pickEvent(...types: readonly ChatEventTypes[]): EffectorEvent<ChatEvent> {
    return channelEventReceived.filterMap(event => (types.includes(event.type) ? event : undefined));
  }

  // TODO[Dmitriy Teplov] react to user changes?
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const $user = createStore(channel.getClient().user!);

  const $allMessages = createStore(channel.state.messages);
  const $messages = $allMessages.map(messages =>
    messages
      .filter(message => message.type !== 'deleted')
      .map(message =>
        message.user?.id === PLATFORM_USER.id ? { ...message, user: { ...message.user, ...PLATFORM_USER } } : message,
      ),
  );
  const $members = createStore(channel.state.members);
  const $read = createStore(channel.state.read);
  const $unreadCount = createStore(channel.state.unreadCount);
  const $lastRead = createStore<Date>(channel.state.read[channel.getClient().user?.id || 0].last_read);
  const highlightedMessageIdReset = createEvent();
  const $highlightedMessageId = createStore<Nullable<string>>(null).reset(highlightedMessageIdReset);

  sample({
    clock: pickEvent(
      'message.new',
      'message.updated',
      'message.deleted',
      'reaction.new',
      'reaction.updated',
      'reaction.deleted',
    ),
    fn: () => [...channel.state.messages],
    target: $allMessages,
  });

  const unreadCountInc = createEvent();

  $unreadCount.on(unreadCountInc, prev => prev + 1);

  sample({
    clock: pickEvent('message.new'),
    filter: e => e.user?.id !== channel.getClient().user?.id,
    target: unreadCountInc,
  });

  sample({
    clock: pickEvent('user.updated', 'user.deleted', 'user.banned', 'user.unbanned'),
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    fn: () => ({ ...channel.getClient().user! }),
    target: $user,
  });

  sample({
    clock: pickEvent('member.added', 'member.updated', 'member.removed'),
    fn: () => ({ ...channel.state.members }),
    target: $members,
  });

  const messagePosted = createEvent<CreateMessagePayload>();
  const sendMessageFx = createEffect(async ({ message, formId }: CreateMessagePayload) => {
    const resp = await channel.sendMessage(message, { skip_enrich_url: true });
    return { ...resp, formId };
  });
  const trackSendMessageTimeFx = createEffect(() => trackTime('Message sent'));
  const sendMessageSuccess = sendMessageFx.doneData;

  type UpdateMessagePayload = {
    message: MessageUpdate;
    formId: string;
  };
  const messageUpdated = createEvent<UpdateMessagePayload>();
  const updateMessageFx = createEffect(async ({ message: { id, update }, formId }: UpdateMessagePayload) => {
    const resp = await channel.getClient().partialUpdateMessage(id, update, undefined, { skip_enrich_url: true });
    return { ...resp, formId };
  });
  const updateMessageSuccess = updateMessageFx.doneData;

  const pinMessage = createEvent<{ id: string; pin: boolean }>();
  const pinMessageFx = createEffect(({ id, pin }: { id: string; pin: boolean }) => {
    pin ? channel.getClient().pinMessage(id, null) : channel.getClient().unpinMessage(id);
  });

  const messageDeleted = createEvent<string>();
  const deleteMessageFx = createEffect((messageId: string) => channel.getClient().deleteMessage(messageId, true));

  const reactionAdded = createEvent<ReactionData>();
  const addReactionFx = createEffect(({ messageId, type }: ReactionData) => channel.sendReaction(messageId, { type }));

  const reactionDeleted = createEvent<ReactionData>();
  const deleteReactionFx = createEffect(({ messageId, type }: ReactionData) => channel.deleteReaction(messageId, type));

  const $isPostingMessage = sendMessageFx.pending;

  const $hasMorePrevious = createStore(true);
  const $hasMoreNewer = createStore(false);

  const loadPreviousMessages = createEvent();
  const previousMessagesLoaderFx = createEffect(async () => {
    const limit = 50;
    return channel.query({
      messages: { id_lt: channel.state.messages[0].id, limit },
      watchers: { limit },
    });
  });
  const loadPreviousMessagesFx = createEffect(async () => {
    const limit = 50;
    // prevent duplicate loading events...
    const oldestMessage = channel.state.messages[0] ?? null;

    if (oldestMessage?.status !== 'received') {
      return null;
    }

    // initial state loads with up to 25 messages, so if less than 25 no need for additional query
    if (channel.state.messages.length < 25) {
      return false;
    }

    const queryResponse = await previousMessagesLoaderFx();

    return queryResponse.messages.length === limit;
  });

  const loadNewerMessages = createEvent();
  const loadNewerMessagesFx = createEffect(async () => {
    const limit = 100;
    // prevent duplicate loading events...
    const newestMessage = channel.state.messages[channel.state.messages.length - 1] ?? null;

    if (newestMessage?.status !== 'received') {
      return null;
    }

    // initial state loads with up to 25 messages, so if less than 25 no need for additional query
    if (channel.state.messages.length < 25) {
      return false;
    }

    const newestID = newestMessage.id;

    const queryResponse = await channel.query({
      messages: { id_gt: newestID, limit },
      watchers: { limit },
    });

    return queryResponse.messages.length === limit;
  });

  const jumpedToMessage = createEvent<JumpEvent>();
  const loadMessageIntoStateFx = createEffect(async (data: JumpEvent) => {
    await channel.state.loadMessageIntoState(data.messageId);
    return data;
  });
  const afterLoadMessageIntoStateFx = createEffect(async ({ messageId, cb }: JumpEvent) => {
    cb?.(messageId);
  });

  const $isLoadingPrevious = loadPreviousMessagesFx.pending;
  const previousLoaded = previousMessagesLoaderFx.doneData;

  const updateLastRead = createEvent<string>();

  const updateLastReadFx = createEffect(
    async (
      data: Nullable<{ id: string; message: ChatFormatMessage; messages: ChatFormatMessage[]; messageIndex: number }>,
    ) => {
      if (!data) return;

      await channel.markRead({
        message_id: data.id,
      });
      return data;
    },
  );

  const unreadUpdate = createEvent<{ diff: number; date: Date } | undefined>();

  const allRead = createEvent();
  const readAllMessagesFx = createEffect(async (_: undefined) => {
    await channel.markRead();
    return { diff: 99999999, date: new Date() };
  });
  debounce({
    source: sample({
      clock: allRead,
      source: $unreadCount,
      filter: unreadCount => unreadCount > 0,
      fn: () => undefined,
    }),
    target: readAllMessagesFx,
    timeout: 500,
  });
  sample({ clock: readAllMessagesFx.doneData, target: unreadUpdate });

  sample({
    source: $lastRead,
    clock: updateLastReadFx.doneData,
    fn: (lastRead, data) => {
      if (!data) return;
      const prevIndex = data.messages.findIndex(m => m.created_at > lastRead);
      return { diff: data.messageIndex - prevIndex + 1, date: data.message.created_at };
    },
    target: unreadUpdate,
  });

  $lastRead.on(unreadUpdate, (_, data) => {
    if (!data) return;
    return data.date;
  });
  $unreadCount.on(unreadUpdate, (prev, data) => {
    if (!data) return;
    return Math.max(prev - (data?.diff || 0), 0);
  });

  sample({
    clock: previousLoaded,
    source: $user,
    filter: (user, data) => !!data.read?.find(u => u.user.id === user.id)?.unread_messages,
    fn: (user, data) => {
      return data.read?.find(u => u.user.id === user.id)?.unread_messages as number;
    },
    target: $unreadCount,
  });

  forward({ from: messagePosted, to: sendMessageFx });
  forward({ from: messageUpdated, to: updateMessageFx });
  forward({ from: pinMessage, to: pinMessageFx });
  forward({ from: messageDeleted, to: deleteMessageFx });
  forward({ from: reactionAdded, to: addReactionFx });
  forward({ from: reactionDeleted, to: deleteReactionFx });

  sample({
    clock: addReactionFx.done.map(({ params }) => ({ 'Chat reaction Type': params.type })),
    target: ANALYTICS.reactOnMessageTracked,
  });

  sample({ clock: jumpedToMessage, target: loadMessageIntoStateFx });

  sample({
    clock: sendMessageFx.done,
    fn: () => ({ messageId: 'latest' } as JumpEvent),
    target: jumpedToMessage,
  });

  sample({
    source: loadMessageIntoStateFx.doneData,
    fn: data => data.messageId !== 'latest',
    target: $hasMoreNewer,
  });
  sample({
    clock: loadMessageIntoStateFx.doneData,
    target: afterLoadMessageIntoStateFx,
  });
  sample({
    clock: loadMessageIntoStateFx.doneData,
    fn: data => data.messageId,
    target: $highlightedMessageId,
  });
  sample({
    clock: loadMessageIntoStateFx.doneData,
    fn: () => [...channel.state.messages],
    target: $allMessages,
  });

  sample({
    clock: afterLoadMessageIntoStateFx.doneData,
    target: highlightedMessageIdReset,
  });

  sample({
    source: $hasMorePrevious,
    clock: loadPreviousMessages,
    filter: hasMorePrevious => hasMorePrevious,
    target: loadPreviousMessagesFx,
  });

  sample({
    clock: loadPreviousMessagesFx.doneData,
    filter: isNotNullish,
    target: $hasMorePrevious,
  });

  sample({
    clock: loadPreviousMessagesFx.doneData,
    fn: () => [...channel.state.messages],
    target: $allMessages,
  });

  sample({
    source: $hasMoreNewer,
    clock: loadNewerMessages,
    filter: hasMoreNewer => hasMoreNewer,
    target: loadNewerMessagesFx,
  });

  sample({
    clock: loadNewerMessagesFx.doneData,
    filter: isNotNullish,
    target: $hasMoreNewer,
  });

  sample({
    clock: loadNewerMessagesFx.doneData,
    fn: () => [...channel.state.messages],
    target: $allMessages,
  });

  forward({ from: messagePosted, to: trackSendMessageTimeFx });
  sample({
    clock: sendMessageFx.done.map(({ params }) => params),
    target: ANALYTICS.messageSentTracked.prepend(({ message }: CreateMessagePayload) => ({
      'Message status': 'true',
      ...getMessageTrackEventProperties(message),
    })),
  });
  sample({
    clock: sendMessageFx.fail.map(({ params }) => params),
    target: ANALYTICS.messageSentTracked.prepend(({ message }: CreateMessagePayload) => ({
      'Message status': 'false',
      ...getMessageTrackEventProperties(message),
    })),
  });

  debounce({
    source: sample({
      clock: updateLastRead,
      source: combine({ messages: $messages, lastRead: $lastRead }),
      fn: ({ messages, lastRead }, id) => {
        const messageIndex = messages.findIndex(m => m.id === id);
        const message = messageIndex >= 0 ? messages[messageIndex] : null;
        if (isNotNullish(message) && message.created_at > lastRead) {
          return { id, messages, message, messageIndex };
        }
        return null;
      },
    }),
    timeout: 500,
    target: updateLastReadFx,
  });

  subscriptions.push(channel.on(channelEventReceived));

  return {
    channel,
    destroy,
    $user,
    $messages,
    $members,
    $unreadCount,
    $lastRead,
    $isPostingMessage,
    messagePosted,
    messageUpdated,
    sendMessageSuccess,
    updateMessageSuccess,
    pinMessage,
    messageDeleted,
    $isLoadingPrevious,
    previousLoaded,
    loadPreviousMessages,
    updateLastRead,
    reactionAdded,
    reactionDeleted,
    jumpedToMessage,
    loadNewerMessages,
    $highlightedMessageId,
    highlightedMessageIdReset,
    $hasMoreNewer,
    $hasMorePrevious,
    $read,
    allRead,
    showUserCard: createEvent<ShowUserCardPayload>(),
    pickEvent,
  } as const;
}

export type ChatChannelModel = Awaited<ReturnType<typeof createChatChannelModel>>;
