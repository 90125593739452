import { ReactElement } from 'react';
import { ICON_DEFAULTS, IconProps } from './types';

export function IconFacebook({
  size = ICON_DEFAULTS.size,
  className = ICON_DEFAULTS.className,
}: IconProps): ReactElement {
  return (
    <svg
      width={size}
      height={size}
      className={className}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" rx="16" fill="url(#paint0_radial_7051_9738)" />
      <rect width="32" height="32" rx="16" fill="#3975EA" />
      <path
        d="M16 25C20.9706 25 25 20.9706 25 16C25 11.0294 20.9706 7 16 7C11.0294 7 7 11.0294 7 16C7 20.9706 11.0294 25 16 25Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.75 12.25H18.25C17.9542 12.2488 17.6611 12.3061 17.3875 12.4188C17.114 12.5314 16.8654 12.6971 16.6563 12.9063C16.4471 13.1154 16.2814 13.364 16.1688 13.6375C16.0561 13.9111 15.9988 14.2042 16 14.5V25"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M13 17.5H19" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <defs>
        <radialGradient
          id="paint0_radial_7051_9738"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(27.04 36.32) rotate(-115.776) scale(43.6379 64.9064)"
        >
          <stop offset="0.24392" stopColor="#FF1B90" />
          <stop offset="0.436673" stopColor="#F80261" />
          <stop offset="0.688476" stopColor="#ED00C0" />
          <stop offset="0.776787" stopColor="#C500E9" />
          <stop offset="0.893155" stopColor="#7017FF" />
        </radialGradient>
      </defs>
    </svg>
  );
}
