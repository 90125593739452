import { BackButton, useSetPreviousUrl } from '@sp/feature/back-button';
import { PERMISSION_MODEL } from '@sp/feature/permissions';
import { Button } from '@sp/ui/elements';
import { ToggleSwitch } from '@sp/ui/switch';
import { openSettings } from '@sp/util/native';
import { ROUTES } from '@sp/util/router';
import { useStore } from 'effector-react';
import { VFC } from 'react';
import { NOTIFICATION_MODEL, NotificationsGroup } from './notification-model';

const NotificationAlert: VFC<{ title: string; description: string; btnText: string; onClick: () => void }> = ({
  title,
  btnText,
  description,
  onClick,
}) => {
  return (
    <div className="px-6 flex flex-col justify-center items-center py-4">
      <div className="flex justify-center mb-12">
        <img src="/images/notifications.svg" alt="empty-mentions" className="w-64 h-[12rem]" />
      </div>
      <div className="text-primary font-semibold text-center text-2xl mb-4">{title}</div>
      <div className="text-secondary font-medium text-center mb-4">{description}</div>
      <Button size="md" color="black" onClick={onClick}>
        <span>{btnText}</span>
      </Button>
    </div>
  );
};

const NotificationToggler: VFC<{
  title: string;
  description: string;
  defaultChecked: boolean;
  onChange: (v: boolean) => void;
}> = ({ title, defaultChecked, description, onChange }) => {
  return (
    <div className={`flex gap-4 justify-between py-4 border-b border-b-stroke`}>
      <div className="flex flex-col">
        <span className="text-primary font-medium">{title}</span>
        <span className="text-secondary text-xs">{description}</span>
      </div>
      <ToggleSwitch defaultChecked={defaultChecked} onChange={onChange} className="mb-5" />
    </div>
  );
};

export const NotificationsSettings: VFC = () => {
  useSetPreviousUrl(ROUTES.PROFILE_URL);
  const notificationStatus = useStore(PERMISSION_MODEL.$notificationPermission);
  const personalNotifications = useStore(NOTIFICATION_MODEL.$personalNotifications);
  const importantNotifications = useStore(NOTIFICATION_MODEL.$importantNotifications);
  const otherNotifications = useStore(NOTIFICATION_MODEL.$otherNotifications);

  const handleNotificationClick = () => {
    if (notificationStatus === 'denied') {
      openSettings();
    } else {
      PERMISSION_MODEL.requestNotificationDone();
    }
  };
  const onChange = (value: boolean, type: NotificationsGroup) => {
    if (notificationStatus === 'unknown') {
      PERMISSION_MODEL.requestNotificationDone();
    } else if (notificationStatus === 'granted') {
      NOTIFICATION_MODEL.notificationSwitched({ value, type });
    }
  };

  return (
    <div className="h-full flex flex-col mobile-pan overflow-y-hidden">
      <div className="flex justify-start items-center border-b border-b-stroke gap-2 px-2 py-2 pr-6 w-full">
        <BackButton />
        <h1 className="m-0 text-base tracking-normal">Notifications</h1>
      </div>
      <div className="flex flex-col justify-center flex-1">
        {notificationStatus === 'denied' ? (
          <NotificationAlert
            title="Notifications are disabled"
            description="Please allow Self+ to send notifications in your device settings"
            btnText="Open settings"
            onClick={handleNotificationClick}
          />
        ) : notificationStatus === 'unknown' ? (
          <NotificationAlert
            title="Allow notifications"
            description="Please allow Self+ to send notifications, we’ll use it to let you know when something new happens in the
                app!"
            btnText="Allow Notifications"
            onClick={handleNotificationClick}
          />
        ) : (
          <div className="px-4 h-full">
            <NotificationToggler
              title={'Important'}
              description={'Creators reminders & alerts, platform messages'}
              defaultChecked={importantNotifications}
              onChange={v => onChange(v, 'important')}
            />
            <NotificationToggler
              title={'Personal'}
              description={'Direct Messages, mentions and replies'}
              defaultChecked={personalNotifications}
              onChange={v => onChange(v, 'personal')}
            />
            <NotificationToggler
              title={'Other'}
              description={'All messages'}
              defaultChecked={otherNotifications}
              onChange={v => onChange(v, 'other')}
            />
          </div>
        )}
      </div>
    </div>
  );
};
