import { useUser } from '@sp/data/auth';
import { openGalleryHandler } from '@sp/feature/back-button';
import { PHOTO_GALLERY_OPTIONS, PhotoswipeGallery } from '@sp/ui/gallery';
import { ChatMessageUploadableAttachment, ChatMessageViewAttachment } from '@sp/util/chat-attachments';
import { ATTACHMENT_TYPE } from '@sp/util/stream-chat';
import { FC } from 'react';
import { AttachmentStatusButton } from './attachment-status-button';
import { EditorAttachmentAudioView } from './editor-attachment-audio-view';
import { EditorAttachmentFileView } from './editor-attachment-file-view';
import { EditAttachmentImageView } from './editor-attachment-image-view';
import { EditorAttachmentLinkView } from './editor-attachment-link-view';
import { EditorAttachmentVideoView } from './editor-attachment-video-view';

export const ChatEditorAttachments: FC<{
  attachments: readonly (ChatMessageViewAttachment | ChatMessageUploadableAttachment)[];
  onRemoveAttachment: (index: number) => void;
  onRemoveLinkAttachment: (url: string) => void;
  onUpdateAttachment: (index: number, attachment: ChatMessageViewAttachment) => void;
}> = ({ attachments, onRemoveAttachment, onRemoveLinkAttachment, onUpdateAttachment }) => {
  const meUser = useUser();
  return (
    <PhotoswipeGallery onOpen={openGalleryHandler} options={PHOTO_GALLERY_OPTIONS}>
      {attachments.map((attachment, index) => (
        <div key={`${index}-${attachment.name}`} className="relative">
          {attachment.type === ATTACHMENT_TYPE.image && (
            <div className="w-16">
              <EditAttachmentImageView attachment={attachment} />
            </div>
          )}

          {attachment.type === ATTACHMENT_TYPE.video && (
            <div className="w-16">
              <EditorAttachmentVideoView
                attachment={attachment}
                onUpdate={attachment => onUpdateAttachment(index, attachment)}
              />
            </div>
          )}

          {attachment.type === ATTACHMENT_TYPE.audio && (
            <div className="w-36">
              <EditorAttachmentAudioView attachment={attachment} authorName={meUser.name} />
            </div>
          )}

          {attachment.type === ATTACHMENT_TYPE.file && (
            <div className="w-16">
              <EditorAttachmentFileView attachment={attachment} />
            </div>
          )}

          {attachment.type === ATTACHMENT_TYPE.link && (
            <div className="w-16">
              <EditorAttachmentLinkView attachment={attachment} />
            </div>
          )}

          <div className="absolute -top-1 -right-1">
            <AttachmentStatusButton
              attachment={attachment}
              onCancel={() => {
                onRemoveAttachment(index);
                if (attachment.type === ATTACHMENT_TYPE.link) {
                  onRemoveLinkAttachment(attachment.url);
                }
              }}
            />
          </div>
        </div>
      ))}
    </PhotoswipeGallery>
  );
};
