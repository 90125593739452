import { ReactElement } from 'react';
import { ICON_DEFAULTS, IconProps } from './types';

export const IconLockedKey = ({
  size = ICON_DEFAULTS.size,
  color = ICON_DEFAULTS.color,
  className = ICON_DEFAULTS.className,
}: IconProps): ReactElement => (
  <svg
    width={size}
    height={size}
    className={className}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 11.25C9.77665 11.25 10.4062 10.6204 10.4062 9.84375C10.4062 9.0671 9.77665 8.4375 9 8.4375C8.22335 8.4375 7.59375 9.0671 7.59375 9.84375C7.59375 10.6204 8.22335 11.25 9 11.25Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M9 11.25V12.9375" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M14.625 6.1875H3.375C3.06434 6.1875 2.8125 6.43934 2.8125 6.75V14.625C2.8125 14.9357 3.06434 15.1875 3.375 15.1875H14.625C14.9357 15.1875 15.1875 14.9357 15.1875 14.625V6.75C15.1875 6.43934 14.9357 6.1875 14.625 6.1875Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.46875 6.1875V3.65625C6.46875 2.98492 6.73543 2.34109 7.21014 1.86639C7.68484 1.39168 8.32867 1.125 9 1.125C9.67133 1.125 10.3152 1.39168 10.7899 1.86639C11.2646 2.34109 11.5312 2.98492 11.5312 3.65625V6.1875"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
