import { uploadFileToAWS } from '@sp/data/bif';
import { getOgMetadata } from '@sp/data/open-graph';
import { STREAM_CHAT_MODEL } from '@sp/feature/stream-chat';
import {
  ChatMessageUploadableAttachment,
  ChatMessageViewAttachment,
  isUploadable,
  sanitizeDataUrlInAttachment,
  UploadableAudioAttachment,
  UploadableFileAttachment,
  UploadableImageAttachment,
  UploadableVideoAttachment,
  UploadingStatus,
} from '@sp/util/chat-attachments';
import {
  AWS_IMAGE_SIZES,
  createImage,
  getAudioAbsolutePeaks,
  getAudioMeta,
  getAWSImageFullSrc,
  getAWSImageResizedSrc,
  GRAY_PIXEL_IMG_SRC,
  isAudio,
  isImage,
  isVideo,
  optimizeImage,
  RESOURCE_PRELOAD_SERVICE,
} from '@sp/util/files';
import { isNotNullish } from '@sp/util/helpers';
import { ATTACHMENT_TYPE, ChatEvent, LinkAttachment, SelfPlusCustomEventType } from '@sp/util/stream-chat';
import update from 'immutability-helper';
import omit from 'lodash/omit';
import { useEffect, useMemo, useState } from 'react';

function getInitialVideoAttachment(): UploadableVideoAttachment {
  return {
    uploadId: 0,
    type: ATTACHMENT_TYPE.video,
    status: UploadingStatus.ready,
    progress: 0,
    size: 0,
    name: '',
    // All videos are converted to mp4 after upload.
    fileType: 'video/mp4',
    url: '',
    previewUrl: '',
    previewDataUrl: GRAY_PIXEL_IMG_SRC,
    width: 100,
    height: 100,
    duration: 0,
    start: async () => void null,
    cancel: () => null,
  };
}

function getInitialAudioAttachment(): UploadableAudioAttachment {
  return {
    uploadId: 0,
    type: ATTACHMENT_TYPE.audio,
    status: UploadingStatus.ready,
    progress: 0,
    size: 0,
    name: '',
    fileType: '',
    url: '',
    duration: 0,
    start: async () => void null,
    cancel: () => null,
  };
}

function getInitialImageAttachment(): UploadableImageAttachment {
  return {
    uploadId: 0,
    type: ATTACHMENT_TYPE.image,
    status: UploadingStatus.ready,
    progress: 0,
    size: 0,
    name: '',
    fileType: '',
    url: '',
    previewUrl: '',
    previewDataUrl: GRAY_PIXEL_IMG_SRC,
    width: 100,
    height: 100,
    start: async () => void null,
    cancel: () => null,
  };
}

function getInitialFileAttachment(): UploadableFileAttachment {
  return {
    uploadId: 0,
    type: ATTACHMENT_TYPE.file,
    status: UploadingStatus.ready,
    progress: 0,
    name: '',
    fileType: '',
    url: '',
    size: 0,
    start: async () => void null,
    cancel: () => null,
  };
}

function excludeUploadableParams(attachment: ChatMessageUploadableAttachment): ChatMessageViewAttachment {
  return omit(attachment, ['progress', 'size', 'status', 'start', 'cancel', 'uploadId']) as ChatMessageViewAttachment;
}

export function prepareAttachmentsForLocalSave(
  attachments: ReadonlyArray<ChatMessageViewAttachment | ChatMessageUploadableAttachment>,
): ChatMessageViewAttachment[] {
  return attachments
    .map(attachment => {
      if (!isUploadable(attachment)) return attachment;
      if (attachment.status === UploadingStatus.ready) return excludeUploadableParams(attachment);
      return null;
    })
    .filter(isNotNullish)
    .map(sanitizeDataUrlInAttachment);
}

let id = 0;

function generateUniqKey(): number {
  return id++;
}

function getInitialAttachmentsMap(
  attachments: ReadonlyArray<ChatMessageViewAttachment>,
): Record<number, ChatMessageViewAttachment> {
  return attachments.reduce((map, value) => ({ ...map, [generateUniqKey()]: value }), {});
}

export function useAttachmentManager(existsAttachments: ReadonlyArray<ChatMessageViewAttachment> = []): {
  attachments: ReadonlyArray<ChatMessageViewAttachment | ChatMessageUploadableAttachment>;
  addFileAttachments: (files: File[]) => void;
  addLinkAttachment: (url: string) => void;
  removeAttachmentAt: (index: number) => void;
  removeAttachment: (attachment: ChatMessageViewAttachment) => boolean;
  updateAttachmentAt: (index: number, attachment: ChatMessageViewAttachment | ChatMessageUploadableAttachment) => void;
  resetAttachments: (attachments?: ReadonlyArray<ChatMessageViewAttachment>) => void;
} {
  const [attachmentsMap, setAttachmentsMap] = useState<
    Record<number | string, ChatMessageViewAttachment | ChatMessageUploadableAttachment>
  >(getInitialAttachmentsMap(existsAttachments));
  const attachments = useMemo(() => Object.values(attachmentsMap), [attachmentsMap]);

  function setAttachmentField<T>(key: number, field: keyof T, value: T[keyof T]): void {
    setAttachmentsMap(state => {
      if (isNotNullish(state[key])) {
        return update(state, { [key]: { [field]: { $set: value } } });
      } else {
        return state;
      }
    });
  }

  // Завершаем загрузку только после того как все фалы загрузились
  // для этого после окончания загрузки каждого файла мы проверяем остались ли еще недогруженные
  // если нет, то вызываем excludeUploadableParams() и делаем все файлы "не Uploadable"
  // это нужно чтобы после загрузки файлы не пропадали из общего прогрессбара
  function maybeCompleteUpload(): void {
    setAttachmentsMap(state => {
      const isUploadingInProgress = Object.values(state).some(
        a => isUploadable(a) && a.status !== UploadingStatus.ready && a.status !== UploadingStatus.error,
      );
      if (isUploadingInProgress) return state;

      return Object.fromEntries(
        Object.entries(state).map(([key, attachment]) => {
          if (attachment && isUploadable(attachment)) {
            const uploaded = excludeUploadableParams(attachment);
            return [key, uploaded];
          }
          return [key, attachment];
        }),
      );
    });
  }

  function addFileAttachments(files: File[]) {
    files.forEach(file => {
      const abortController = new AbortController();
      const cancel = () => abortController.abort();
      const attachmentKey = generateUniqKey();
      let newAttachment: ChatMessageUploadableAttachment;

      // Upload helper functions
      const uploadFile = async () => {
        const { url, id, name, type, mime, previewUrl, originalUrl } = await uploadFileToAWS(
          file,
          abortController.signal,
          progress => setAttachmentField<ChatMessageUploadableAttachment>(attachmentKey, 'progress', progress),
        );
        setAttachmentField<ChatMessageUploadableAttachment>(attachmentKey, 'uploadId', id);
        return { url, name, type, mime, previewUrl, originalUrl };
      };

      const setVideoMetaData = async () => {
        const objectURL = URL.createObjectURL(file);
        setAttachmentField<UploadableVideoAttachment>(attachmentKey, 'previewDataUrl', objectURL);
      };

      const setAudioMetaData = async () => {
        const { duration, size, objectURL } = await getAudioMeta(file);
        setAttachmentField<UploadableAudioAttachment>(attachmentKey, 'fileDataUrl', objectURL);
        const peaks = await getAudioAbsolutePeaks(file);
        setAttachmentField<UploadableAudioAttachment>(attachmentKey, 'duration', duration);
        setAttachmentField<UploadableAudioAttachment>(attachmentKey, 'histogram', peaks);
        return { duration, size };
      };

      const setImageMetaData = async () => {
        const image = await createImage(URL.createObjectURL(file));

        setAttachmentsMap(attachments =>
          update(attachments, {
            [attachmentKey]: {
              $merge: {
                width: image.width,
                height: image.height,
                fileType: file.type,
                size: file.size,
                name: file.name,
              },
            },
          }),
        );
      };

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const preloadImageAllSizes = async (url: string, aspectRatio: number) => {
        const awsImageSizesUrls = AWS_IMAGE_SIZES.map(size => getAWSImageResizedSrc(url, size));
        await RESOURCE_PRELOAD_SERVICE.preloadResources([...awsImageSizesUrls, getAWSImageFullSrc(url)]);
      };
      // End of upload helper functions

      if (isVideo(file)) {
        newAttachment = {
          ...getInitialVideoAttachment(),
          name: file.name,
          size: file.size,
          start: async () => {
            setAttachmentField<ChatMessageUploadableAttachment>(attachmentKey, 'status', UploadingStatus.uploading);
            try {
              const { url, originalUrl, previewUrl } = await uploadFile();
              setAttachmentField<UploadableVideoAttachment>(attachmentKey, 'url', url);
              if (originalUrl) setAttachmentField<UploadableVideoAttachment>(attachmentKey, 'originalUrl', originalUrl);
              if (previewUrl) setAttachmentField<UploadableVideoAttachment>(attachmentKey, 'previewUrl', previewUrl);
              setAttachmentField<ChatMessageUploadableAttachment>(attachmentKey, 'status', UploadingStatus.ready);
              maybeCompleteUpload();
            } catch (e) {
              setAttachmentField<ChatMessageUploadableAttachment>(attachmentKey, 'status', UploadingStatus.error);
              console.error(e);
            }
          },
          cancel,
        };
      } else if (isAudio(file)) {
        newAttachment = {
          ...getInitialAudioAttachment(),
          name: file.name,
          size: file.size,
          fileType: 'audio/mpeg',
          start: async () => {
            setAttachmentField<ChatMessageUploadableAttachment>(attachmentKey, 'status', UploadingStatus.uploading);
            try {
              const [{ url }] = await Promise.all([uploadFile(), setAudioMetaData()]);
              setAttachmentField<ChatMessageUploadableAttachment>(attachmentKey, 'url', url);
              setAttachmentField<UploadableAudioAttachment>(attachmentKey, 'status', UploadingStatus.postProcessing);
            } catch (e) {
              setAttachmentField<ChatMessageUploadableAttachment>(attachmentKey, 'status', UploadingStatus.error);
              console.error(e);
            }
          },
          cancel,
        };
      } else if (isImage(file)) {
        newAttachment = {
          ...getInitialImageAttachment(),
          size: file.size,
          previewDataUrl: URL.createObjectURL(file),
          start: async () => {
            try {
              setAttachmentField<UploadableImageAttachment>(attachmentKey, 'status', UploadingStatus.preProcessing);

              file = await optimizeImage(file);
              if (abortController.signal.aborted) return;

              setAttachmentField<UploadableImageAttachment>(attachmentKey, 'status', UploadingStatus.uploading);
              const [{ url }] = await Promise.all([uploadFile(), setImageMetaData()]);
              setAttachmentField<ChatMessageUploadableAttachment>(attachmentKey, 'url', url);
              setAttachmentField<UploadableImageAttachment>(attachmentKey, 'previewUrl', url);
              setAttachmentField<ChatMessageUploadableAttachment>(attachmentKey, 'status', UploadingStatus.ready);
              maybeCompleteUpload();
            } catch (e) {
              setAttachmentField<ChatMessageUploadableAttachment>(attachmentKey, 'status', UploadingStatus.error);
              console.error(e);
            }
          },
          cancel,
        };
      } else {
        newAttachment = {
          ...getInitialFileAttachment(),
          name: file.name,
          fileType: file.type,
          size: file.size,
          start: async () => {
            setAttachmentField<ChatMessageUploadableAttachment>(attachmentKey, 'status', UploadingStatus.uploading);
            try {
              const { url } = await uploadFile();
              setAttachmentField<ChatMessageUploadableAttachment>(attachmentKey, 'url', url);
              setAttachmentField<ChatMessageUploadableAttachment>(attachmentKey, 'status', UploadingStatus.ready);
              maybeCompleteUpload();
            } catch (e) {
              setAttachmentField<ChatMessageUploadableAttachment>(attachmentKey, 'status', UploadingStatus.error);
              console.error(e);
            }
          },
          cancel: () => {
            abortController.abort();
          },
        };
      }

      setAttachmentsMap(state => ({ ...state, [attachmentKey]: newAttachment }));
      newAttachment.start().then();
    });
  }

  async function addLinkAttachment(url: string) {
    const id = generateUniqKey();

    setAttachmentsMap(state => ({
      ...state,
      [id]: {
        type: ATTACHMENT_TYPE.link,
        url,
        name: '',
        previewUrl: null,
      } as LinkAttachment,
    }));

    try {
      const meta = await getOgMetadata(url);

      setAttachmentField(id, 'name', meta.title);
      setAttachmentField(id, 'previewUrl', meta.image_url ?? null);
    } catch (e) {
      console.log(`Can't resolve og metadata for ${url}`);
      setAttachmentsMap(state =>
        update(state, {
          $unset: [id],
        }),
      );
    }
  }

  function removeAttachmentAt(index: number) {
    const attachmentKeyToRemove = parseInt(Object.keys(attachmentsMap)[index] ?? '0');
    const attachmentToRemove = attachmentsMap[attachmentKeyToRemove];
    if (attachmentToRemove) {
      if (isUploadable(attachmentToRemove)) {
        attachmentToRemove.cancel();
      }

      setAttachmentsMap(state => update(state, { $unset: [attachmentKeyToRemove] }));
      maybeCompleteUpload();
    }
  }

  function updateAttachmentAt(index: number, attachment: ChatMessageUploadableAttachment | ChatMessageViewAttachment) {
    const attachmentKey = Number(Object.keys(attachmentsMap)[index] ?? '');
    if (!isNaN(attachmentKey)) {
      setAttachmentsMap(state => ({ ...state, [attachmentKey]: attachment }));
    }
  }

  function resetAttachments(attachments: ReadonlyArray<ChatMessageViewAttachment> = []) {
    setAttachmentsMap(getInitialAttachmentsMap(attachments));
  }

  useEffect(() => {
    let subs: { unsubscribe: () => void };
    try {
      subs = STREAM_CHAT_MODEL.client.on((event: ChatEvent) => {
        const { type } = event;
        if (type === SelfPlusCustomEventType.ConvertVideoSuccess) {
          const { url, id } = event.data;
          const [attachmentKey, attachment] = Object.entries(attachmentsMap).find(
            ([, attachment]) =>
              isUploadable(attachment) && attachment.type === ATTACHMENT_TYPE.video && attachment.uploadId === id,
          ) ?? [null, null];
          if (attachmentKey && attachment) {
            const key = parseInt(attachmentKey);
            setAttachmentField<UploadableVideoAttachment>(key, 'url', url);
            setAttachmentField<UploadableVideoAttachment>(key, 'status', UploadingStatus.ready);
            maybeCompleteUpload();
          }
        }

        if (type === SelfPlusCustomEventType.ConvertVideoError) {
          const { id } = event.data;
          const [attachmentKey, attachment] = Object.entries(attachmentsMap).find(
            ([, attachment]) =>
              isUploadable(attachment) && attachment.type === ATTACHMENT_TYPE.video && attachment.uploadId === id,
          ) ?? [null, null];
          if (attachmentKey && attachment) {
            setAttachmentField<UploadableVideoAttachment>(parseInt(attachmentKey), 'status', UploadingStatus.error);
          }
        }

        if (type === SelfPlusCustomEventType.ConvertAudioSuccess) {
          const { url, id } = event.data;
          const [attachmentKey, attachment] = Object.entries(attachmentsMap).find(
            ([, attachment]) =>
              isUploadable(attachment) && attachment.type === ATTACHMENT_TYPE.audio && attachment.uploadId === id,
          ) ?? [null, null];
          if (attachmentKey && attachment) {
            const key = parseInt(attachmentKey);
            setAttachmentField<UploadableAudioAttachment>(key, 'url', url);
            setAttachmentField<UploadableAudioAttachment>(key, 'status', UploadingStatus.ready);
            maybeCompleteUpload();
          }
        }

        if (type === SelfPlusCustomEventType.ConvertAudioError) {
          const { id } = event.data;
          const [attachmentKey, attachment] = Object.entries(attachmentsMap).find(
            ([, attachment]) =>
              isUploadable(attachment) && attachment.type === ATTACHMENT_TYPE.audio && attachment.uploadId === id,
          ) ?? [null, null];
          if (attachmentKey && attachment) {
            setAttachmentField<UploadableAudioAttachment>(parseInt(attachmentKey), 'status', UploadingStatus.error);
          }
        }
      });
    } catch (e) {
      console.error(e);
    }
    return () => subs && subs.unsubscribe();
  }, [attachmentsMap]);

  function removeAttachment(removedAttachment: ChatMessageViewAttachment): boolean {
    const key = Object.entries(attachmentsMap).find(([_, attachment]) => attachment === removedAttachment)?.[0] ?? null;

    if (key === null) {
      return false;
    }

    if (isUploadable(removedAttachment) && removedAttachment.status === UploadingStatus.uploading) {
      removedAttachment.cancel();
    }

    setAttachmentsMap(state => update(state, { $unset: [key] }));

    return true;
  }

  return {
    attachments,
    addFileAttachments,
    addLinkAttachment,
    removeAttachmentAt,
    removeAttachment,
    resetAttachments,
    updateAttachmentAt,
  };
}
