import { useCommands, useEditorView } from '@remirror/react';
import { useCheckMounted } from '@sp/util/helpers';
import { useCallback, useEffect, useMemo, useState } from 'react';

const useWasEditorFocused = (delay: number) => {
  const checkMounted = useCheckMounted();
  const editorView = useEditorView();

  const [isFocused, setIsFocused] = useState(editorView.hasFocus());

  const handleFocus = useCallback(() => setIsFocused(true), []);

  const handleBlur = useCallback(
    () =>
      setTimeout(() => {
        if (!checkMounted()) return;
        if (editorView.hasFocus()) return;
        setIsFocused(false);
      }, delay),
    [checkMounted, delay, editorView],
  );

  useEffect(() => {
    editorView.dom.addEventListener('focus', handleFocus);
    editorView.dom.addEventListener('blur', handleBlur);

    return () => {
      editorView.dom.removeEventListener('focus', handleFocus);
      editorView.dom.removeEventListener('blur', handleBlur);
    };
  });

  return isFocused;
};

export function useFocusTextEditor(delay = 100) {
  const commands = useCommands();
  const wasEditorFocused = useWasEditorFocused(delay);

  const focus = useCallback(() => commands.focus(), [commands]);
  const refocus = useCallback(() => wasEditorFocused && focus(), [focus, wasEditorFocused]);

  return useMemo(() => ({ focus, refocus }), [focus, refocus]);
}
