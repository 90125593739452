import { SpinnerPage } from '@sp/ui/elements';
import { IconCaretLeft } from '@sp/ui/icons';
import { FullscreenModal } from '@sp/ui/modal';
import { isNotNullish } from '@sp/util/helpers';
import { useStore } from 'effector-react';
import { FC, useEffect } from 'react';
import { CardButton } from '../discover/card/components'; // FIXME do not use in real design
import { CHALLENGE_INFO_MODEL } from './challenge-info-model';
import { CREATOR_INFO_MODEL } from './creator-info-model';
import { INFO_MODAL_MODEL } from './info-modal-model';

const ChallengeInfoContent: FC = () => {
  const challenge = useStore(CHALLENGE_INFO_MODEL.$challenge);
  const creator = useStore(CREATOR_INFO_MODEL.$creator);
  const loading = useStore(CHALLENGE_INFO_MODEL.$challengeLoading);
  return (
    <>
      {loading || !challenge ? (
        <SpinnerPage />
      ) : (
        <div className="p-6">
          <h1>{challenge.title}</h1>
          <div className="mb-4 flex gap-2">
            <CardButton inHome={challenge.isTrial || challenge.isSubscribed} />
            {/*  <BuyButton bought={challenge.isPurchased} id={challenge.id} price={challenge.price} />*/}
          </div>
        </div>
      )}
      <button
        type="button"
        onClick={e => {
          if (isNotNullish(creator)) {
            INFO_MODAL_MODEL.infoModalTypeSet('creator');
          } else {
            INFO_MODAL_MODEL.closeInfoModal();
          }
          e.stopPropagation();
        }}
        className="absolute top-1 left-1 bg-blur-secondary flex gap-2 text-primary rounded p-1"
      >
        <IconCaretLeft size={24} />
        {creator ? creator.name : ''}
      </button>
    </>
  );
};

const CreatorInfoContent: FC = () => {
  const creator = useStore(CREATOR_INFO_MODEL.$creator);
  const loading = useStore(CREATOR_INFO_MODEL.$creatorLoading);

  return (
    <>
      {loading || !creator ? (
        <SpinnerPage />
      ) : (
        <div className="p-6">
          <h1>{creator.name}</h1>
          <div className="mb-4">
            <CardButton creator inHome={creator.isFollowed || creator.isSubscribed} />
            {/* TODO[Dmitriy Teplov] iOS/web subscription prices. */}
            {/*<BuyButton bought={creator.isSubscribed} id={creator.id} price={creator.subscriptionPrice || 0} />*/}
          </div>
          {creator.spaces.map(space => {
            return (
              <div
                onClick={() => {
                  CHALLENGE_INFO_MODEL.activeChallengeIdSet(space.id);
                  INFO_MODAL_MODEL.infoModalTypeSet('challenge');
                }}
                className=" flex justify-between p-4 rounded-2xl mb-4 bg-brand-light"
                key={space.id}
              >
                <div className="mr-2">{space.title}</div>
                {/* TODO[Dmitriy Teplov] iOS/web space prices. */}
                {/*<BuyButton bought={space.isPurchased} id={space.id} price={space.price || 0} />*/}
              </div>
            );
          })}
        </div>
      )}
      <button
        type="button"
        onClick={e => {
          INFO_MODAL_MODEL.closeInfoModal();
          e.stopPropagation();
        }}
        className="absolute top-1 left-1 bg-blur-secondary text-primary rounded p-1"
      >
        <IconCaretLeft size={24} />
      </button>
    </>
  );
};

const InfoModalLayout: FC = () => {
  const type = useStore(INFO_MODAL_MODEL.$infoModalType);
  useEffect(() => {
    return function () {
      CREATOR_INFO_MODEL.creatorReset();
      CHALLENGE_INFO_MODEL.challengeReset();
    };
  }, []);

  if (type === 'creator') {
    return <CreatorInfoContent />;
  }
  if (type === 'challenge') {
    return <ChallengeInfoContent />;
  }
  return null;
};

export const InfoModal: FC = () => {
  const isOpen = useStore(INFO_MODAL_MODEL.$isInfoModalOpen);
  return (
    <FullscreenModal isOpen={isOpen} onClose={INFO_MODAL_MODEL.closeInfoModal}>
      <InfoModalLayout />
    </FullscreenModal>
  );
};
