import { ChallengeSpaceStep, updateChallengeStepResult } from '@sp/data/bif';
import { combine, createDomain, forward, merge } from 'effector';
import update from 'immutability-helper';
import { CHALLENGE_MODEL } from '../challenge-model';
import { ChallengeStep, ChallengeStepsUpdatingState, ChallengeStepWithUI } from './steps.types';

const stepsDomain = createDomain();

const completeStep = stepsDomain.createEvent<{ stepId: number }>();
const uncompleteStep = stepsDomain.createEvent<{ stepId: number }>();
const sendFeedback = stepsDomain.createEvent<{ stepId: number; rate: number; message: string }>();
const skipFeedback = stepsDomain.createEvent<{ stepId: number }>();

const completeStepFx = stepsDomain.createEffect(async ({ stepId }: { stepId: number }) => {
  await updateChallengeStepResult({ stepId, isCompleted: true });
  return { stepId };
});
const uncompleteStepFx = stepsDomain.createEffect(async ({ stepId }: { stepId: number }) => {
  await updateChallengeStepResult({ stepId, isCompleted: false });
  return { stepId };
});

const sendFeedbackFx = stepsDomain.createEffect(async (feedback: { stepId: number; rate: number; message: string }) => {
  await updateChallengeStepResult(feedback);
  return feedback;
});

const skipFeedbackFx = stepsDomain.createEffect(async ({ stepId }: { stepId: number }) => {
  await updateChallengeStepResult({ stepId, isSkipped: true });
  return { stepId };
});

function parseRawSteps(rawSteps: readonly ChallengeSpaceStep[], finishAt: number): ChallengeStep[] {
  return rawSteps.map((step, index, all) => ({
    id: step.id,
    dayName: `Day ${index + 1}`,
    isSignificant: step.isSignificant,
    firstPostId: step.posts[0]?.id,
    startAt: step.startAt,
    endAt: all[index + 1]?.startAt ?? finishAt,
    title: step.title,
    estimatedTime: step.estimatedEffort,
    result: step.result,
  }));
}

const $steps = stepsDomain
  .createStore<ChallengeStep[]>([])
  .on(CHALLENGE_MODEL.$challenge, (_, { meta, finishAt }) => parseRawSteps(meta.steps, finishAt))
  .on(completeStepFx.doneData, (state, { stepId }) => {
    const stepIndex = state.findIndex(step => step.id === stepId);
    if (stepIndex >= 0) {
      return update(state, { [stepIndex]: { result: { isCompleted: { $set: true } } } });
    }
    return state;
  })
  .on(uncompleteStepFx.doneData, (state, { stepId }) => {
    const stepIndex = state.findIndex(step => step.id === stepId);
    if (stepIndex >= 0) {
      return update(state, { [stepIndex]: { result: { isCompleted: { $set: false } } } });
    }
    return state;
  })
  .on(sendFeedbackFx.doneData, (state, { stepId, rate, message }) => {
    const stepIndex = state.findIndex(step => step.id === stepId);
    if (stepIndex >= 0) {
      return update(state, { [stepIndex]: { result: { rate: { $set: rate }, message: { $set: message } } } });
    }
    return state;
  })
  .on(skipFeedbackFx.doneData, (state, { stepId }) => {
    const stepIndex = state.findIndex(step => step.id === stepId);
    if (stepIndex >= 0) {
      return update(state, { [stepIndex]: { result: { isSkipped: { $set: true } } } });
    }
    return state;
  })
  .reset(CHALLENGE_MODEL.ChallengeGate.close);

const $stepsIdsInUpdating = stepsDomain
  .createStore<ChallengeStepsUpdatingState>({
    inCompleting: [],
    inFeedbackSkipping: [],
    inFeedbackSending: [],
  })
  .on(completeStep, getStepInUpdatingReducer('inCompleting'))
  .on(uncompleteStep, getStepInUpdatingReducer('inCompleting'))
  .on(sendFeedback, getStepInUpdatingReducer('inFeedbackSending'))
  .on(skipFeedback, getStepInUpdatingReducer('inFeedbackSkipping'))
  .on(completeStepFx.finally, getStepNotInUpdatingReducer('inCompleting'))
  .on(uncompleteStepFx.finally, getStepNotInUpdatingReducer('inCompleting'))
  .on(sendFeedbackFx.finally, getStepNotInUpdatingReducer('inFeedbackSending'))
  .on(skipFeedbackFx.finally, getStepNotInUpdatingReducer('inFeedbackSkipping'))
  .reset(CHALLENGE_MODEL.ChallengeGate.close);

function getStepInUpdatingReducer(type: keyof ChallengeStepsUpdatingState) {
  return (state: ChallengeStepsUpdatingState, { stepId }: { stepId: number }) => {
    if (!state[type].includes(stepId)) {
      return update(state, { [type]: { $push: [stepId] } });
    }
    return state;
  };
}

function getStepNotInUpdatingReducer(type: keyof ChallengeStepsUpdatingState) {
  return (state: ChallengeStepsUpdatingState, data: { params: { stepId: number } }) => {
    const { stepId } = data.params;
    if (state[type].includes(stepId)) {
      return update(state, { [type]: { $splice: [[state[type].indexOf(stepId), 1]] } });
    }
    return state;
  };
}

const $stepsWithUI = combine($steps, $stepsIdsInUpdating, (steps, updatingState) => {
  return steps.map<ChallengeStepWithUI>(step => ({
    step,
    ui: {
      isCompleting: updatingState.inCompleting.includes(step.id),
      isFeedbackSending: updatingState.inFeedbackSending.includes(step.id),
      isFeedbackSkipping: updatingState.inFeedbackSkipping.includes(step.id),
    },
  }));
});

forward({ from: completeStep, to: completeStepFx });
forward({ from: uncompleteStep, to: uncompleteStepFx });
forward({ from: sendFeedback, to: sendFeedbackFx });
forward({ from: skipFeedback, to: skipFeedbackFx });

const completeStepSuccess = completeStepFx.doneData.map(({ stepId }) => stepId);
const feedbackSuccess = merge([sendFeedbackFx.doneData, skipFeedbackFx.doneData]).map(({ stepId }) => stepId);

export const STEPS_MODEL = {
  $stepsWithUI,
  completeStep,
  uncompleteStep,
  sendFeedback,
  skipFeedback,
  completeStepSuccess,
  feedbackSuccess,
};
