import { FloatingWrapper, MentionAtomNodeAttributes, useMentionAtom } from '@remirror/react';
import { Avatar } from '@sp/ui/elements';
import { cls } from '@sp/util/helpers';
import { FC, useEffect, useState } from 'react';

export interface MentionOption {
  readonly id: string;
  readonly label: string;
}

// TODO[Dmitriy Teplov] provide a single item's view as a dependency
//  to decouple it's meta data from the generic component.
interface OptionData {
  readonly image: string | null;
}

export interface MentionOptionsData {
  [id: string]: OptionData | undefined;
}

export const TextEditorMentionSuggester: FC<
  Readonly<{
    options: readonly MentionOption[];
    optionsData?: MentionOptionsData;
  }>
> = ({ options, optionsData = {} }) => {
  const [items, setItems] = useState<MentionAtomNodeAttributes[]>([]);
  const { state, getMenuProps, getItemProps, indexIsHovered, indexIsSelected } = useMentionAtom({
    items,
  });

  useEffect(() => {
    if (!state) {
      return;
    }

    const searchTerm = state.query.full.toLowerCase();
    const filteredOptions = options.filter(option => option.label.toLowerCase().startsWith(searchTerm));
    setItems(filteredOptions as MentionAtomNodeAttributes[]);
  }, [options, state]);

  const enabled = !!state;

  return (
    <FloatingWrapper
      positioner="cursor"
      enabled={enabled}
      renderOutsideEditor
      containerClass="absolute inset-x-0 !bottom-[calc(100%+1px)] w-full z-[51] !transform-none"
    >
      <div {...getMenuProps()} className="max-h-[8.75rem] overflow-auto mobile-pan bg-light">
        {enabled &&
          items.map((item, index) => {
            const isHighlighted = indexIsSelected(index);
            const isHovered = indexIsHovered(index);
            const optionData = optionsData[item.id];

            // TODO[Dmitriy Teplov] provide a single item's view as a dependency
            //  to decouple it's styling from the logic.
            return (
              <div
                key={item.id}
                className={cls(
                  'flex items-center px-3 py-1 cursor-pointer',
                  isHighlighted && 'bg-brand-dirty-light',
                  isHovered && !isHighlighted && 'bg-brand-light',
                )}
                {...getItemProps({
                  item,
                  index,
                })}
              >
                <Avatar size="xs" src={optionData?.image} />
                <span className="ml-2 text-primary">{item.label}</span>
              </div>
            );
          })}
      </div>
    </FloatingWrapper>
  );
};
