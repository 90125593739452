import { UpdateFrequency } from '@sp/data/bif';

export const channelUpdateFrequencyFormat: Record<UpdateFrequency, string> = {
  [UpdateFrequency.daily]: 'Daily',
  [UpdateFrequency.weekly]: 'Weekly',
  [UpdateFrequency.twoThreePerWeek]: 'Couple times a week',
  [UpdateFrequency.twoThreePerMonth]: 'Couple times a month',
  [UpdateFrequency.occasionally]: 'Occasionally',
} as const;

Object.freeze(channelUpdateFrequencyFormat);
