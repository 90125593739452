import { KnownReaction } from '@sp/util/stream-chat';
import { ReactElement, VFC } from 'react';

export interface ReactionConfig {
  name: string;
  body: ReactElement; // Возможно будем использовать кастомные изображения вместо стандартных emoji
}

const KNOWN_REACTION_ALIASES = Object.values(KnownReaction);

export function isKnownReaction(reaction: string): reaction is KnownReaction {
  return KNOWN_REACTION_ALIASES.includes(reaction as KnownReaction);
}

const ImageReaction: VFC<Readonly<{ src: string; name: string }>> = ({ src, name }) => (
  <img alt={name} src={src} className="block flex-none h-[1.3em] w-[1.3em] object-cover overflow-hidden" />
);

export const REACTION_REGISTRY: Record<KnownReaction, ReactionConfig> = {
  [KnownReaction.fire]: {
    name: 'fire',
    body: <>🔥</>,
  },
  [KnownReaction.heart]: {
    name: 'heart',
    body: <>❤️</>,
  },
  [KnownReaction.broken_heart]: {
    name: 'broken heart',
    body: <>💔</>,
  },
  [KnownReaction.thumbs_up]: {
    name: 'thumbs up',
    body: <>👍</>,
  },
  [KnownReaction.thumbs_down]: {
    name: 'thumbs down',
    body: <>👎</>,
  },
  [KnownReaction.smiling_with_hearts]: {
    name: 'smiling with hearts',
    body: <>🥰</>,
  },
  [KnownReaction.partying]: {
    name: 'partying',
    body: <>🥳</>,
  },
  [KnownReaction.smiling_with_tear]: {
    name: 'smiling with tear',
    body: <>🥲</>,
  },
  [KnownReaction.sunglasses]: {
    name: 'sunglasses',
    body: <>😎</>,
  },
  [KnownReaction.slightly_smiling]: {
    name: 'slightly smiling',
    body: <>🙂</>,
  },
  [KnownReaction.relieved]: {
    name: 'relieved',
    body: <>😌</>,
  },
  [KnownReaction.cry]: {
    name: 'cry',
    body: <>😢</>,
  },
  [KnownReaction.scream]: {
    name: 'scream',
    body: <>😱</>,
  },
  [KnownReaction.rage]: {
    name: 'rage',
    body: <>😡</>,
  },
  [KnownReaction.angry]: {
    name: 'angry',
    body: <>🤬</>,
  },
  [KnownReaction.laughing]: {
    name: 'laughing',
    body: <>🤣</>,
  },
  [KnownReaction.zany_face]: {
    name: 'zany face',
    body: <>🤪</>,
  },
  [KnownReaction.hug]: {
    name: 'hug',
    body: <ImageReaction name="hug" src="/images/reactions/hug_bear.webp" />,
  },
  [KnownReaction.sunny]: {
    name: 'sunny',
    body: <>☀️</>,
  },
  [KnownReaction.plus]: {
    name: 'plus',
    body: <>➕</>,
  },
  [KnownReaction.checkmark]: {
    name: 'checkmark',
    body: <>✅</>,
  },
  [KnownReaction.apple]: {
    name: 'apple',
    body: <>🍏</>,
  },
  [KnownReaction.pray]: {
    name: 'pray',
    body: <>🙏</>,
  },
  [KnownReaction.muscle]: {
    name: 'muscle',
    body: <>💪</>,
  },
  [KnownReaction.selfplus]: {
    name: 'selfplus',
    body: <ImageReaction name="selfplus" src="/images/reactions/selfplus.webp" />,
  },
};
