// Release canvas elements to get around Safari canvas memory limit.
// https://pqina.nl/blog/total-canvas-memory-use-exceeds-the-maximum-limit/
import { IS_IOS } from '@sp/data/env';

export function releaseCanvas(canvas: HTMLCanvasElement) {
  canvas.width = 1;
  canvas.height = 1;
  const ctx = canvas.getContext('2d');
  ctx && ctx.clearRect(0, 0, 1, 1);
}

export type Size = Readonly<{ width: number; height: number }>;

const CANVAS_MAX_PIXELS_SQRT = IS_IOS ? 4096 : 65_535;
const CANVAS_MAX_PIXELS = CANVAS_MAX_PIXELS_SQRT ** 2;

// Prevent creating too big canvas elements Safari can't handle.
// https://pqina.nl/blog/canvas-area-exceeds-the-maximum-limit/
export function limitCanvasSize({ width, height }: Size): Size {
  const requiredPixels = width * height;

  if (requiredPixels <= CANVAS_MAX_PIXELS) {
    return { width, height };
  }

  const ratio = CANVAS_MAX_PIXELS_SQRT / Math.sqrt(requiredPixels);

  return {
    width: Math.floor(width * ratio),
    height: Math.floor(height * ratio),
  };
}
