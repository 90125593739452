import { ChatMessageAvatar, ChatMessageHeader, ChatMessageProvider, mockChatModel } from '@sp/feature/chat';
import {
  ChatMessageBodyContainer,
  ChatMessageContainer,
  ChatMessageLeftContainer,
  ChatMessageRightContainer,
} from '@sp/ui/chat';
import { formatChatMessage } from '@sp/util/stream-chat';
import { formatISO } from 'date-fns';
import { useStore } from 'effector-react';
import { FC, useMemo } from 'react';
import { CHALLENGE_MODEL } from '../../challenge-model';
import { FinishPane } from '../finish-pane';
import { POSTS_MODEL } from './posts-model';
import { createMockMessage, PostsView } from './posts-view';

const PostWithFinishPane: FC = () => {
  const challenge = useStore(CHALLENGE_MODEL.$challenge);
  const mockMessage = createMockMessage({ text: '' }, challenge?.creator, formatISO(challenge.finishAt));
  const message = useMemo(() => formatChatMessage(mockMessage), [mockMessage]);
  const contextValue = useMemo(() => ({ message, model: mockChatModel() }), [message]);
  return (
    <div>
      <ChatMessageProvider value={contextValue}>
        <ChatMessageContainer>
          <ChatMessageLeftContainer>
            <ChatMessageAvatar />
          </ChatMessageLeftContainer>

          <ChatMessageRightContainer>
            <ChatMessageHeader />

            <ChatMessageBodyContainer>
              <FinishPane finishAt={challenge.finishAt} />
            </ChatMessageBodyContainer>
          </ChatMessageRightContainer>
        </ChatMessageContainer>
      </ChatMessageProvider>
    </div>
  );
};

export const FinishPosts: FC = () => {
  const posts = useStore(POSTS_MODEL.$postsMap)['finish'] ?? [];
  const challenge = useStore(CHALLENGE_MODEL.$challenge);
  return (
    <>
      <PostWithFinishPane />
      <PostsView posts={posts} startAt={challenge.finishAt} />
    </>
  );
};
