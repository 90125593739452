import { NpsApiFactory } from '../../api/default';
import { apiResponseAdHoc } from '../helpers';

const npsApi = NpsApiFactory();

export type NpsForm = { rate: number; readyToTalk: boolean };

export function getNps(): Promise<NpsForm> {
  return npsApi.npsGet().then(r => apiResponseAdHoc(r.data));
}

export function postNps(data: NpsForm): Promise<NpsForm> {
  return npsApi.npsPost(data).then(r => apiResponseAdHoc(r.data));
}
