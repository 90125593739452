import { IconLinkThin } from '@sp/ui/icons';
import { sanitizeUrl } from '@sp/util/links';
import { LinkAttachment } from '@sp/util/stream-chat';
import { ReactElement, useMemo, useState } from 'react';

export function AttachmentLinkView({ attachment }: { attachment: LinkAttachment }): ReactElement | null {
  const [isFaviconError, setIsFaviconError] = useState(false);
  const sanitizedUrl = useMemo(() => sanitizeUrl(attachment.url), [attachment.url]);

  let host: string | null = null;

  try {
    host = new URL(sanitizedUrl).host;
  } catch (e) {
    console.error('original:', attachment.url, 'sanitized:', sanitizedUrl, e);
  }

  if (host == null) {
    return null;
  }

  return (
    <a
      className="w-full no-underline hover:underline text-xs relative before:h-full before:w-0.5 before:bg-brand-main before:absolute before:top-0 before:left-0 before:rounded px-2 py-1"
      href={sanitizedUrl}
      target="_blank"
      rel="noopener noreferrer"
    >
      <header className="flex items-center gap-1 mb-1">
        {attachment.previewUrl && !isFaviconError ? (
          <img
            src={attachment.previewUrl}
            alt={attachment.name}
            onError={() => setIsFaviconError(true)}
            className="w-4 h-4 overflow-hidden"
          />
        ) : (
          <IconLinkThin size={16} />
        )}
        <div className="text-secondary text-ellipsis">{host}</div>
      </header>
      <div className="font-semibold text-ellipsis overflow-hidden whitespace-nowrap">{attachment.name}</div>
    </a>
  );
}
