import { ReactElement } from 'react';
import { ICON_DEFAULTS, IconProps } from './types';

export function IconCloudArrowDown({
  size = ICON_DEFAULTS.size,
  color = ICON_DEFAULTS.color,
  className = ICON_DEFAULTS.className,
}: IconProps): ReactElement {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.22656 13.6016L11.875 16.25L14.5234 13.6016"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M11.875 10V16.25" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M7.5 16.25H5.625C4.46468 16.25 3.35188 15.7891 2.53141 14.9686C1.71094 14.1481 1.25 13.0353 1.25 11.875C1.25 10.7147 1.71094 9.60188 2.53141 8.78141C3.35188 7.96094 4.46468 7.5 5.625 7.5C5.99104 7.49986 6.35572 7.54446 6.71094 7.63281"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.25 10C6.25 9.00968 6.48533 8.03353 6.9366 7.152C7.38788 6.27047 8.04217 5.50879 8.84556 4.92974C9.64895 4.35068 10.5784 3.97083 11.5574 3.82148C12.5364 3.67213 13.5369 3.75756 14.4764 4.07073C15.4159 4.3839 16.2676 4.91584 16.9612 5.62272C17.6547 6.3296 18.1704 7.19118 18.4657 8.13645C18.761 9.08173 18.8274 10.0836 18.6595 11.0596C18.4916 12.0356 18.0942 12.9577 17.5 13.75"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
