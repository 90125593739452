import { useGate, useStore } from 'effector-react';
import { useEffect, VFC } from 'react';
import { useNavigate } from 'react-router-dom';
import { CREATE_LIVE_MODEL, CreateLiveGate } from './create-live-model';
import { LiveSpaceForm } from './live-space-form';

export const CreateLiveScreen: VFC = () => {
  useGate(CreateLiveGate);
  const navigate = useNavigate();
  const isLoading = useStore(CREATE_LIVE_MODEL.$isLoading);

  useEffect(() => CREATE_LIVE_MODEL.created.watch(liveSpace => navigate(`./${liveSpace.id}`)), [navigate]);

  return (
    <div className="h-full overflow-y-auto overflow-x-hidden mobile-pan">
      <LiveSpaceForm canUploadRecording={false} isLoading={isLoading} />
    </div>
  );
};
