import { createEvent, restore } from 'effector';

const globalChannelsInitialized = createEvent();

const $isGlobalChannelsInitialized = restore(
  globalChannelsInitialized.map(() => true),
  false,
);

export const GLOBAL_CHANNELS_INIT = {
  globalChannelsInitialized,
  $isGlobalChannelsInitialized,
};
