import { BuyButtonResultFn, SubscribeButton } from '@sp/feature/buy-button';
import { Avatar, Button } from '@sp/ui/elements';
import { IconArrowCounterClockwise, IconCoins, IconTimer } from '@sp/ui/icons';
import { Modal } from '@sp/ui/modal';
import { SpaceTag } from '@sp/ui/spaces-shared';
import { formatPriceRaw } from '@sp/util/format';
import { isNotNullish, isNullish } from '@sp/util/helpers';
import { format } from 'date-fns';
import { useStore } from 'effector-react';
import { FC, useCallback, useState } from 'react';
import { CANCEL_SUBSCRIPTION_MODEL } from './cancel-subscription-model';
import { CHANNEL_MODEL } from './channel-model';

export const ChannelSubscriptionDetailsModal: FC<{ isOpen: boolean; onClose: VoidFunction }> = ({
  isOpen,
  onClose,
}) => {
  const [isCancelSubscriptionConformationModalOpen, setIsCancelSubscriptionConformationModalOpen] = useState(false);
  const channel = useStore(CHANNEL_MODEL.$channel);

  const onSubscribeResult: BuyButtonResultFn = useCallback(() => CHANNEL_MODEL.refetchData(), []);

  const isCancelPending = useStore(CANCEL_SUBSCRIPTION_MODEL.isPending);

  const handleClickCancel = useCallback(() => {
    if (!channel) return;
    CANCEL_SUBSCRIPTION_MODEL.cancel(channel.id);
  }, [channel]);

  if (isNullish(channel) || isNullish(channel.subscription) || isNullish(channel.priceConfig)) return null;
  const { subscription, title, cover, creator, tags } = channel;
  const isCanceled = isNotNullish(subscription.cancelledAt);
  const price = formatPriceRaw(subscription.price) + (subscription.inApp ? ' + VAT' : '');

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnBackdropClick withBackdrop>
      <>
        <div className="flex justify-start items-start gap-2 pb-2 w-full">
          <span>
            <Avatar size="sm" src={cover?.url} />
          </span>
          <div className="flex flex-col flex-1 overflow-x-hidden">
            <h1 className="m-0 text-ellipsis whitespace-nowrap overflow-x-hidden text-base">{title}</h1>
            <span className="text-secondary text-xs font-semibold">{creator?.name}</span>
          </div>
        </div>

        {tags && tags.length > 0 ? (
          <div className="mb-2">
            <span className="font-semibold text-xs">Monthly Skills up:</span>
            {tags.map(({ name, id, weight, color }) => (
              <SpaceTag key={id} color={color} text={`+${weight} ${name}`} />
            ))}
          </div>
        ) : null}

        <hr className="mt-2 mb-4" />

        {isCanceled ? (
          <>
            <div className="flex flex-col gap-2 mb-6">
              <span className="font-semibold">You’ve canceled the subscription</span>
              <div className="flex items-center gap-1">
                <IconCoins size={18} />
                <span className="font-semibold">{price}</span>
                Monthly
              </div>
              <div className="flex items-center gap-1">
                <IconTimer size={18} />
                Expires
                <span className="font-semibold">{format(subscription.nextPaymentDate ?? 0, 'd MMMM')}</span>
              </div>
            </div>

            <SubscribeButton
              id={channel.id}
              title={channel.title}
              price={channel.priceConfig}
              iosProductId={channel.iosProductId}
              creatorId={channel.creator.id}
              creatorName={channel.creator.name}
              bought={false}
              openUrl="."
              onResult={onSubscribeResult}
            >
              Renew Subscription
            </SubscribeButton>
          </>
        ) : (
          <>
            <div className="flex flex-col gap-2 mb-10">
              <div className="flex items-center gap-1">
                <IconCoins size={18} />
                <span className="font-semibold">{price}</span>
                Monthly
              </div>
              <div className="flex items-center gap-1">
                <IconArrowCounterClockwise size={18} />
                Renews
                <span className="font-semibold">{format(subscription.nextPaymentDate ?? 0, 'd MMMM')}</span>
              </div>
            </div>

            <button
              type="button"
              className="text-secondary font-medium"
              onClick={() => setIsCancelSubscriptionConformationModalOpen(true)}
            >
              Cancel Premium Subscription
            </button>

            <Modal
              isOpen={isCancelSubscriptionConformationModalOpen}
              onClose={() => setIsCancelSubscriptionConformationModalOpen(false)}
              closeOnBackdropClick
              withBackdrop
            >
              <>
                <h3 className="mt-0 mb-1 text-xl leading-6">
                  Are you sure in canceling Premium Suscribtion on this channel?
                </h3>
                <p className="mt-0 mb-6">
                  Because of this you will not see the all content for premium followers anymore
                </p>
                <Button
                  color="black"
                  block
                  onClick={() => setIsCancelSubscriptionConformationModalOpen(false)}
                  className="mb-2"
                >
                  Not Now
                </Button>
                <Button color="transparent" loading={isCancelPending} disableOnLoad block onClick={handleClickCancel}>
                  Cancel the Subscription
                </Button>
              </>
            </Modal>
          </>
        )}
      </>
    </Modal>
  );
};
