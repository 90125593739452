import { ReactElement } from 'react';
import { ICON_DEFAULTS, IconProps } from './types';

export function IconGhost({
  size = ICON_DEFAULTS.size,
  color = ICON_DEFAULTS.color,
  className = ICON_DEFAULTS.className,
}: IconProps): ReactElement {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5 16C13.3284 16 14 15.3284 14 14.5C14 13.6716 13.3284 13 12.5 13C11.6716 13 11 13.6716 11 14.5C11 15.3284 11.6716 16 12.5 16Z"
        fill={color}
      />
      <path
        d="M19.5 16C20.3284 16 21 15.3284 21 14.5C21 13.6716 20.3284 13 19.5 13C18.6716 13 18 13.6716 18 14.5C18 15.3284 18.6716 16 19.5 16Z"
        fill={color}
      />
      <path
        d="M27 27L23.3375 24L19.6625 27L16 24L12.3375 27L8.6625 24L5 27V15C5 12.0826 6.15893 9.28473 8.22183 7.22183C10.2847 5.15893 13.0826 4 16 4C18.9174 4 21.7153 5.15893 23.7782 7.22183C25.8411 9.28473 27 12.0826 27 15V27Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
