import { RemirrorJSON } from '@remirror/core';
import { useRemirrorContext } from '@remirror/react';
import { Fragment, Node, Slice } from 'prosemirror-model';

export const OnPaste = () => {
  const { view } = useRemirrorContext();

  view.setProps({
    // https://github.com/ueberdosis/tiptap/issues/775#issuecomment-762971612
    clipboardTextParser: (text, $context) => {
      const nodes: Node[] = [];
      const lines = text.split(/(?:\r\n?|\n)/);

      lines.forEach(line => {
        const nodeJson: RemirrorJSON = { type: 'paragraph' };
        if (line.length > 0) {
          nodeJson.content = [{ type: 'text', text: line }];
        }
        const node = Node.fromJSON($context.doc.type.schema, nodeJson);
        nodes.push(node);
      });

      const fragment = Fragment.fromArray(nodes);
      return Slice.maxOpen(fragment);
    },
    transformPastedHTML: html => {
      const isParagraphOnly = html.match(/<br ?\/?>/) === null;

      // e.g. Notion.
      if (isParagraphOnly) {
        const hasEmptyParagraphs = html.match(/<p>\s*<\/p>/g) !== null;
        if (hasEmptyParagraphs) {
          return html;
        } else {
          return html.replace(/\/p>\s*<p/g, '/p><p></p><p');
        }
      }

      // e.g. Google Docs.
      return (
        html
          // normalize <br />, <br>, <br/>
          .replace(/<br ?\/?>/g, '<br>')
          // collapse whitespaces between tags.
          .replace(/>\s+</g, '><')
          // Additional line break for Google Docs span's by breaking paragraphs.
          // input:  <p>one<span><br></span>two</p>
          // output: <p>one</p><p>two</p>
          .replace(/<span[^>]*><br><\/span>/g, '</p><p>')
          // replace each <br> with an empty paragraph.
          .replace(/(<br>)+/g, match => '<p></p>'.repeat(match.split('><').length))
      );
    },
  });

  return null;
};
