/* tslint:disable */
/* eslint-disable */
/**
 * Admin
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 *
 * @export
 * @interface AvatarsAvatarIdReplacePost200Response
 */
export interface AvatarsAvatarIdReplacePost200Response {
  /**
   *
   * @type {number}
   * @memberof AvatarsAvatarIdReplacePost200Response
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof AvatarsAvatarIdReplacePost200Response
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof AvatarsAvatarIdReplacePost200Response
   */
  name?: string;
  /**
   *
   * @type {boolean}
   * @memberof AvatarsAvatarIdReplacePost200Response
   */
  isAvatar?: boolean;
  /**
   *
   * @type {AvatarsAvatarIdReplacePost200ResponseClub}
   * @memberof AvatarsAvatarIdReplacePost200Response
   */
  club?: AvatarsAvatarIdReplacePost200ResponseClub | null;
  /**
   *
   * @type {AvatarsAvatarIdReplacePost200ResponseAvatar}
   * @memberof AvatarsAvatarIdReplacePost200Response
   */
  avatar?: AvatarsAvatarIdReplacePost200ResponseAvatar | null;
}
/**
 *
 * @export
 * @interface AvatarsAvatarIdReplacePost200ResponseAvatar
 */
export interface AvatarsAvatarIdReplacePost200ResponseAvatar {
  /**
   *
   * @type {number}
   * @memberof AvatarsAvatarIdReplacePost200ResponseAvatar
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof AvatarsAvatarIdReplacePost200ResponseAvatar
   */
  url?: string;
}
/**
 *
 * @export
 * @interface AvatarsAvatarIdReplacePost200ResponseClub
 */
export interface AvatarsAvatarIdReplacePost200ResponseClub {
  /**
   *
   * @type {number}
   * @memberof AvatarsAvatarIdReplacePost200ResponseClub
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof AvatarsAvatarIdReplacePost200ResponseClub
   */
  title?: string | null;
  /**
   *
   * @type {number}
   * @memberof AvatarsAvatarIdReplacePost200ResponseClub
   */
  subscriptionPrice?: number | null;
}
/**
 *
 * @export
 * @interface AvatarsAvatarIdReplacePostRequest
 */
export interface AvatarsAvatarIdReplacePostRequest {
  /**
   *
   * @type {number}
   * @memberof AvatarsAvatarIdReplacePostRequest
   */
  realUserId?: number;
}
/**
 *
 * @export
 * @interface BuyForFreePostRequest
 */
export interface BuyForFreePostRequest {
  /**
   *
   * @type {number}
   * @memberof BuyForFreePostRequest
   */
  spaceId?: number;
  /**
   *
   * @type {number}
   * @memberof BuyForFreePostRequest
   */
  customerId?: number;
  /**
   *
   * @type {boolean}
   * @memberof BuyForFreePostRequest
   */
  force?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof BuyForFreePostRequest
   */
  foreverFree?: boolean;
}
/**
 *
 * @export
 * @interface CategoriesClubsCategoryIdDelete200Response
 */
export interface CategoriesClubsCategoryIdDelete200Response {
  /**
   *
   * @type {number}
   * @memberof CategoriesClubsCategoryIdDelete200Response
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof CategoriesClubsCategoryIdDelete200Response
   */
  createdAt?: number;
  /**
   *
   * @type {number}
   * @memberof CategoriesClubsCategoryIdDelete200Response
   */
  updatedAt?: number | null;
  /**
   *
   * @type {number}
   * @memberof CategoriesClubsCategoryIdDelete200Response
   */
  deletedAt?: number | null;
  /**
   *
   * @type {string}
   * @memberof CategoriesClubsCategoryIdDelete200Response
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof CategoriesClubsCategoryIdDelete200Response
   */
  madeBy?: CategoriesClubsCategoryIdDelete200ResponseMadeByEnum;
  /**
   *
   * @type {number}
   * @memberof CategoriesClubsCategoryIdDelete200Response
   */
  order?: number;
  /**
   *
   * @type {object}
   * @memberof CategoriesClubsCategoryIdDelete200Response
   */
  config?: object | null;
  /**
   *
   * @type {boolean}
   * @memberof CategoriesClubsCategoryIdDelete200Response
   */
  isCompilation?: boolean;
  /**
   *
   * @type {number}
   * @memberof CategoriesClubsCategoryIdDelete200Response
   */
  coverFileId?: number | null;
  /**
   *
   * @type {string}
   * @memberof CategoriesClubsCategoryIdDelete200Response
   */
  size?: CategoriesClubsCategoryIdDelete200ResponseSizeEnum;
  /**
   *
   * @type {AvatarsAvatarIdReplacePost200ResponseAvatar}
   * @memberof CategoriesClubsCategoryIdDelete200Response
   */
  cover?: AvatarsAvatarIdReplacePost200ResponseAvatar | null;
}

export const CategoriesClubsCategoryIdDelete200ResponseMadeByEnum = {
  Manual: 'manual',
  Popular: 'popular',
} as const;

export type CategoriesClubsCategoryIdDelete200ResponseMadeByEnum =
  typeof CategoriesClubsCategoryIdDelete200ResponseMadeByEnum[keyof typeof CategoriesClubsCategoryIdDelete200ResponseMadeByEnum];
export const CategoriesClubsCategoryIdDelete200ResponseSizeEnum = {
  Small: 'small',
  Big: 'big',
} as const;

export type CategoriesClubsCategoryIdDelete200ResponseSizeEnum =
  typeof CategoriesClubsCategoryIdDelete200ResponseSizeEnum[keyof typeof CategoriesClubsCategoryIdDelete200ResponseSizeEnum];

/**
 *
 * @export
 * @interface CategoriesClubsCategoryIdDeleteRequest
 */
export interface CategoriesClubsCategoryIdDeleteRequest {
  /**
   *
   * @type {string}
   * @memberof CategoriesClubsCategoryIdDeleteRequest
   */
  title?: string | null;
  /**
   *
   * @type {number}
   * @memberof CategoriesClubsCategoryIdDeleteRequest
   */
  order?: number | null;
  /**
   *
   * @type {object}
   * @memberof CategoriesClubsCategoryIdDeleteRequest
   */
  config?: object | null;
  /**
   *
   * @type {boolean}
   * @memberof CategoriesClubsCategoryIdDeleteRequest
   */
  isCompilation?: boolean | null;
  /**
   *
   * @type {number}
   * @memberof CategoriesClubsCategoryIdDeleteRequest
   */
  coverFileId?: number | null;
  /**
   *
   * @type {string}
   * @memberof CategoriesClubsCategoryIdDeleteRequest
   */
  size?: CategoriesClubsCategoryIdDeleteRequestSizeEnum;
}

export const CategoriesClubsCategoryIdDeleteRequestSizeEnum = {
  Small: 'small',
  Big: 'big',
} as const;

export type CategoriesClubsCategoryIdDeleteRequestSizeEnum =
  typeof CategoriesClubsCategoryIdDeleteRequestSizeEnum[keyof typeof CategoriesClubsCategoryIdDeleteRequestSizeEnum];

/**
 *
 * @export
 * @interface CategoriesClubsCategoryIdManualGet200Response
 */
export interface CategoriesClubsCategoryIdManualGet200Response {
  /**
   *
   * @type {string}
   * @memberof CategoriesClubsCategoryIdManualGet200Response
   */
  created?: string;
  /**
   *
   * @type {string}
   * @memberof CategoriesClubsCategoryIdManualGet200Response
   */
  deleted?: string;
  /**
   *
   * @type {string}
   * @memberof CategoriesClubsCategoryIdManualGet200Response
   */
  updated?: string;
}
/**
 *
 * @export
 * @interface CategoriesClubsCategoryIdManualGet200ResponseInner
 */
export interface CategoriesClubsCategoryIdManualGet200ResponseInner {
  /**
   *
   * @type {number}
   * @memberof CategoriesClubsCategoryIdManualGet200ResponseInner
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof CategoriesClubsCategoryIdManualGet200ResponseInner
   */
  createdAt?: number;
  /**
   *
   * @type {number}
   * @memberof CategoriesClubsCategoryIdManualGet200ResponseInner
   */
  deletedAt?: number | null;
  /**
   *
   * @type {number}
   * @memberof CategoriesClubsCategoryIdManualGet200ResponseInner
   */
  clubId?: number;
  /**
   *
   * @type {number}
   * @memberof CategoriesClubsCategoryIdManualGet200ResponseInner
   */
  categoryId?: number;
  /**
   *
   * @type {number}
   * @memberof CategoriesClubsCategoryIdManualGet200ResponseInner
   */
  order?: number | null;
  /**
   *
   * @type {CategoriesClubsCategoryIdManualGet200ResponseInnerClub}
   * @memberof CategoriesClubsCategoryIdManualGet200ResponseInner
   */
  club?: CategoriesClubsCategoryIdManualGet200ResponseInnerClub | null;
}
/**
 *
 * @export
 * @interface CategoriesClubsCategoryIdManualGet200ResponseInnerClub
 */
export interface CategoriesClubsCategoryIdManualGet200ResponseInnerClub {
  /**
   *
   * @type {string}
   * @memberof CategoriesClubsCategoryIdManualGet200ResponseInnerClub
   */
  title?: string | null;
  /**
   *
   * @type {number}
   * @memberof CategoriesClubsCategoryIdManualGet200ResponseInnerClub
   */
  subscriptionPrice?: number | null;
  /**
   *
   * @type {CategoriesClubsCategoryIdManualGet200ResponseInnerClubOwner}
   * @memberof CategoriesClubsCategoryIdManualGet200ResponseInnerClub
   */
  owner?: CategoriesClubsCategoryIdManualGet200ResponseInnerClubOwner | null;
}
/**
 *
 * @export
 * @interface CategoriesClubsCategoryIdManualGet200ResponseInnerClubOwner
 */
export interface CategoriesClubsCategoryIdManualGet200ResponseInnerClubOwner {
  /**
   *
   * @type {number}
   * @memberof CategoriesClubsCategoryIdManualGet200ResponseInnerClubOwner
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof CategoriesClubsCategoryIdManualGet200ResponseInnerClubOwner
   */
  name?: string;
  /**
   *
   * @type {boolean}
   * @memberof CategoriesClubsCategoryIdManualGet200ResponseInnerClubOwner
   */
  isAvatar?: boolean;
  /**
   *
   * @type {AvatarsAvatarIdReplacePost200ResponseAvatar}
   * @memberof CategoriesClubsCategoryIdManualGet200ResponseInnerClubOwner
   */
  avatar?: AvatarsAvatarIdReplacePost200ResponseAvatar | null;
}
/**
 *
 * @export
 * @interface CategoriesClubsCategoryIdManualGetRequest
 */
export interface CategoriesClubsCategoryIdManualGetRequest {
  /**
   *
   * @type {Array<CategoriesClubsCategoryIdManualGetRequestCreateInner>}
   * @memberof CategoriesClubsCategoryIdManualGetRequest
   */
  create?: Array<CategoriesClubsCategoryIdManualGetRequestCreateInner>;
  /**
   *
   * @type {Array<CategoriesClubsCategoryIdManualGetRequestCreateInner>}
   * @memberof CategoriesClubsCategoryIdManualGetRequest
   */
  update?: Array<CategoriesClubsCategoryIdManualGetRequestCreateInner>;
  /**
   *
   * @type {Array<CategoriesClubsCategoryIdManualGetRequestDeleteInner>}
   * @memberof CategoriesClubsCategoryIdManualGetRequest
   */
  delete?: Array<CategoriesClubsCategoryIdManualGetRequestDeleteInner>;
}
/**
 *
 * @export
 * @interface CategoriesClubsCategoryIdManualGetRequestCreateInner
 */
export interface CategoriesClubsCategoryIdManualGetRequestCreateInner {
  /**
   *
   * @type {number}
   * @memberof CategoriesClubsCategoryIdManualGetRequestCreateInner
   */
  order?: number | null;
  /**
   *
   * @type {number}
   * @memberof CategoriesClubsCategoryIdManualGetRequestCreateInner
   */
  id?: number;
}
/**
 *
 * @export
 * @interface CategoriesClubsCategoryIdManualGetRequestDeleteInner
 */
export interface CategoriesClubsCategoryIdManualGetRequestDeleteInner {
  /**
   *
   * @type {number}
   * @memberof CategoriesClubsCategoryIdManualGetRequestDeleteInner
   */
  id?: number;
}
/**
 *
 * @export
 * @interface CategoriesClubsGetRequest
 */
export interface CategoriesClubsGetRequest {
  /**
   *
   * @type {string}
   * @memberof CategoriesClubsGetRequest
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof CategoriesClubsGetRequest
   */
  madeBy?: CategoriesClubsGetRequestMadeByEnum;
  /**
   *
   * @type {number}
   * @memberof CategoriesClubsGetRequest
   */
  order?: number;
  /**
   *
   * @type {object}
   * @memberof CategoriesClubsGetRequest
   */
  config?: object | null;
  /**
   *
   * @type {boolean}
   * @memberof CategoriesClubsGetRequest
   */
  isCompilation?: boolean;
  /**
   *
   * @type {number}
   * @memberof CategoriesClubsGetRequest
   */
  coverFileId?: number | null;
  /**
   *
   * @type {string}
   * @memberof CategoriesClubsGetRequest
   */
  size?: CategoriesClubsGetRequestSizeEnum;
}

export const CategoriesClubsGetRequestMadeByEnum = {
  Manual: 'manual',
  Popular: 'popular',
} as const;

export type CategoriesClubsGetRequestMadeByEnum =
  typeof CategoriesClubsGetRequestMadeByEnum[keyof typeof CategoriesClubsGetRequestMadeByEnum];
export const CategoriesClubsGetRequestSizeEnum = {
  Small: 'small',
  Big: 'big',
} as const;

export type CategoriesClubsGetRequestSizeEnum =
  typeof CategoriesClubsGetRequestSizeEnum[keyof typeof CategoriesClubsGetRequestSizeEnum];

/**
 *
 * @export
 * @interface CategoriesSpacesCategoryIdDelete200Response
 */
export interface CategoriesSpacesCategoryIdDelete200Response {
  /**
   *
   * @type {number}
   * @memberof CategoriesSpacesCategoryIdDelete200Response
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof CategoriesSpacesCategoryIdDelete200Response
   */
  createdAt?: number;
  /**
   *
   * @type {number}
   * @memberof CategoriesSpacesCategoryIdDelete200Response
   */
  updatedAt?: number | null;
  /**
   *
   * @type {number}
   * @memberof CategoriesSpacesCategoryIdDelete200Response
   */
  deletedAt?: number | null;
  /**
   *
   * @type {string}
   * @memberof CategoriesSpacesCategoryIdDelete200Response
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof CategoriesSpacesCategoryIdDelete200Response
   */
  madeBy?: CategoriesSpacesCategoryIdDelete200ResponseMadeByEnum;
  /**
   *
   * @type {number}
   * @memberof CategoriesSpacesCategoryIdDelete200Response
   */
  order?: number;
  /**
   *
   * @type {object}
   * @memberof CategoriesSpacesCategoryIdDelete200Response
   */
  config?: object | null;
  /**
   *
   * @type {boolean}
   * @memberof CategoriesSpacesCategoryIdDelete200Response
   */
  isCompilation?: boolean;
  /**
   *
   * @type {number}
   * @memberof CategoriesSpacesCategoryIdDelete200Response
   */
  coverFileId?: number | null;
  /**
   *
   * @type {string}
   * @memberof CategoriesSpacesCategoryIdDelete200Response
   */
  size?: CategoriesSpacesCategoryIdDelete200ResponseSizeEnum;
  /**
   *
   * @type {AvatarsAvatarIdReplacePost200ResponseAvatar}
   * @memberof CategoriesSpacesCategoryIdDelete200Response
   */
  cover?: AvatarsAvatarIdReplacePost200ResponseAvatar | null;
}

export const CategoriesSpacesCategoryIdDelete200ResponseMadeByEnum = {
  Manual: 'manual',
  Popular: 'popular',
  All: 'all',
} as const;

export type CategoriesSpacesCategoryIdDelete200ResponseMadeByEnum =
  typeof CategoriesSpacesCategoryIdDelete200ResponseMadeByEnum[keyof typeof CategoriesSpacesCategoryIdDelete200ResponseMadeByEnum];
export const CategoriesSpacesCategoryIdDelete200ResponseSizeEnum = {
  Small: 'small',
  Big: 'big',
} as const;

export type CategoriesSpacesCategoryIdDelete200ResponseSizeEnum =
  typeof CategoriesSpacesCategoryIdDelete200ResponseSizeEnum[keyof typeof CategoriesSpacesCategoryIdDelete200ResponseSizeEnum];

/**
 *
 * @export
 * @interface CategoriesSpacesCategoryIdManualGet200ResponseInner
 */
export interface CategoriesSpacesCategoryIdManualGet200ResponseInner {
  /**
   *
   * @type {number}
   * @memberof CategoriesSpacesCategoryIdManualGet200ResponseInner
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof CategoriesSpacesCategoryIdManualGet200ResponseInner
   */
  createdAt?: number;
  /**
   *
   * @type {number}
   * @memberof CategoriesSpacesCategoryIdManualGet200ResponseInner
   */
  deletedAt?: number | null;
  /**
   *
   * @type {number}
   * @memberof CategoriesSpacesCategoryIdManualGet200ResponseInner
   */
  spaceId?: number;
  /**
   *
   * @type {number}
   * @memberof CategoriesSpacesCategoryIdManualGet200ResponseInner
   */
  categoryId?: number;
  /**
   *
   * @type {number}
   * @memberof CategoriesSpacesCategoryIdManualGet200ResponseInner
   */
  order?: number | null;
  /**
   *
   * @type {CategoriesSpacesCategoryIdManualGet200ResponseInnerSpace}
   * @memberof CategoriesSpacesCategoryIdManualGet200ResponseInner
   */
  space?: CategoriesSpacesCategoryIdManualGet200ResponseInnerSpace | null;
}
/**
 *
 * @export
 * @interface CategoriesSpacesCategoryIdManualGet200ResponseInnerSpace
 */
export interface CategoriesSpacesCategoryIdManualGet200ResponseInnerSpace {
  /**
   *
   * @type {number}
   * @memberof CategoriesSpacesCategoryIdManualGet200ResponseInnerSpace
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof CategoriesSpacesCategoryIdManualGet200ResponseInnerSpace
   */
  expert?: number;
  /**
   *
   * @type {string}
   * @memberof CategoriesSpacesCategoryIdManualGet200ResponseInnerSpace
   */
  title?: string;
  /**
   *
   * @type {number}
   * @memberof CategoriesSpacesCategoryIdManualGet200ResponseInnerSpace
   */
  price?: number;
  /**
   *
   * @type {string}
   * @memberof CategoriesSpacesCategoryIdManualGet200ResponseInnerSpace
   */
  type?: CategoriesSpacesCategoryIdManualGet200ResponseInnerSpaceTypeEnum;
  /**
   *
   * @type {string}
   * @memberof CategoriesSpacesCategoryIdManualGet200ResponseInnerSpace
   */
  status?: CategoriesSpacesCategoryIdManualGet200ResponseInnerSpaceStatusEnum;
  /**
   *
   * @type {string}
   * @memberof CategoriesSpacesCategoryIdManualGet200ResponseInnerSpace
   */
  payment_type?: CategoriesSpacesCategoryIdManualGet200ResponseInnerSpacePaymentTypeEnum;
  /**
   *
   * @type {AvatarsAvatarIdReplacePost200ResponseAvatar}
   * @memberof CategoriesSpacesCategoryIdManualGet200ResponseInnerSpace
   */
  cover?: AvatarsAvatarIdReplacePost200ResponseAvatar | null;
}

export const CategoriesSpacesCategoryIdManualGet200ResponseInnerSpaceTypeEnum = {
  Post: 'post',
  Live: 'live',
  Challenge: 'challenge',
  Trailer: 'trailer',
  Channel: 'channel',
  Community: 'community',
} as const;

export type CategoriesSpacesCategoryIdManualGet200ResponseInnerSpaceTypeEnum =
  typeof CategoriesSpacesCategoryIdManualGet200ResponseInnerSpaceTypeEnum[keyof typeof CategoriesSpacesCategoryIdManualGet200ResponseInnerSpaceTypeEnum];
export const CategoriesSpacesCategoryIdManualGet200ResponseInnerSpaceStatusEnum = {
  Draft: 'draft',
  Published: 'published',
} as const;

export type CategoriesSpacesCategoryIdManualGet200ResponseInnerSpaceStatusEnum =
  typeof CategoriesSpacesCategoryIdManualGet200ResponseInnerSpaceStatusEnum[keyof typeof CategoriesSpacesCategoryIdManualGet200ResponseInnerSpaceStatusEnum];
export const CategoriesSpacesCategoryIdManualGet200ResponseInnerSpacePaymentTypeEnum = {
  FixedPrice: 'fixed_price',
  FixedPriceSubscribersOnly: 'fixed_price_subscribers_only',
  SubscribersOnly: 'subscribers_only',
  Free: 'free',
  MonthlyPrice: 'monthly_price',
} as const;

export type CategoriesSpacesCategoryIdManualGet200ResponseInnerSpacePaymentTypeEnum =
  typeof CategoriesSpacesCategoryIdManualGet200ResponseInnerSpacePaymentTypeEnum[keyof typeof CategoriesSpacesCategoryIdManualGet200ResponseInnerSpacePaymentTypeEnum];

/**
 *
 * @export
 * @interface CategoriesSpacesCategoryIdManualGetRequest
 */
export interface CategoriesSpacesCategoryIdManualGetRequest {
  /**
   *
   * @type {Array<CategoriesSpacesCategoryIdManualGetRequestCreateInner>}
   * @memberof CategoriesSpacesCategoryIdManualGetRequest
   */
  create?: Array<CategoriesSpacesCategoryIdManualGetRequestCreateInner> | null;
  /**
   *
   * @type {Array<CategoriesSpacesCategoryIdManualGetRequestCreateInner>}
   * @memberof CategoriesSpacesCategoryIdManualGetRequest
   */
  update?: Array<CategoriesSpacesCategoryIdManualGetRequestCreateInner>;
  /**
   *
   * @type {Array<CategoriesClubsCategoryIdManualGetRequestDeleteInner>}
   * @memberof CategoriesSpacesCategoryIdManualGetRequest
   */
  delete?: Array<CategoriesClubsCategoryIdManualGetRequestDeleteInner>;
}
/**
 *
 * @export
 * @interface CategoriesSpacesCategoryIdManualGetRequestCreateInner
 */
export interface CategoriesSpacesCategoryIdManualGetRequestCreateInner {
  /**
   *
   * @type {number}
   * @memberof CategoriesSpacesCategoryIdManualGetRequestCreateInner
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof CategoriesSpacesCategoryIdManualGetRequestCreateInner
   */
  order?: number | null;
}
/**
 *
 * @export
 * @interface CategoriesSpacesGetRequest
 */
export interface CategoriesSpacesGetRequest {
  /**
   *
   * @type {string}
   * @memberof CategoriesSpacesGetRequest
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof CategoriesSpacesGetRequest
   */
  madeBy?: CategoriesSpacesGetRequestMadeByEnum;
  /**
   *
   * @type {number}
   * @memberof CategoriesSpacesGetRequest
   */
  order?: number;
  /**
   *
   * @type {object}
   * @memberof CategoriesSpacesGetRequest
   */
  config?: object | null;
  /**
   *
   * @type {boolean}
   * @memberof CategoriesSpacesGetRequest
   */
  isCompilation?: boolean;
  /**
   *
   * @type {number}
   * @memberof CategoriesSpacesGetRequest
   */
  coverFileId?: number | null;
  /**
   *
   * @type {string}
   * @memberof CategoriesSpacesGetRequest
   */
  size?: CategoriesSpacesGetRequestSizeEnum;
}

export const CategoriesSpacesGetRequestMadeByEnum = {
  Manual: 'manual',
  Popular: 'popular',
  All: 'all',
} as const;

export type CategoriesSpacesGetRequestMadeByEnum =
  typeof CategoriesSpacesGetRequestMadeByEnum[keyof typeof CategoriesSpacesGetRequestMadeByEnum];
export const CategoriesSpacesGetRequestSizeEnum = {
  Small: 'small',
  Big: 'big',
} as const;

export type CategoriesSpacesGetRequestSizeEnum =
  typeof CategoriesSpacesGetRequestSizeEnum[keyof typeof CategoriesSpacesGetRequestSizeEnum];

/**
 *
 * @export
 * @interface ClubsClubIdPost200Response
 */
export interface ClubsClubIdPost200Response {
  /**
   *
   * @type {number}
   * @memberof ClubsClubIdPost200Response
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof ClubsClubIdPost200Response
   */
  owner?: number;
  /**
   *
   * @type {string}
   * @memberof ClubsClubIdPost200Response
   */
  title?: string | null;
  /**
   *
   * @type {number}
   * @memberof ClubsClubIdPost200Response
   */
  subscriptionPrice?: number | null;
}
/**
 *
 * @export
 * @interface ClubsClubIdPostRequest
 */
export interface ClubsClubIdPostRequest {
  /**
   *
   * @type {string}
   * @memberof ClubsClubIdPostRequest
   */
  title?: string | null;
  /**
   *
   * @type {number}
   * @memberof ClubsClubIdPostRequest
   */
  subscriptionPrice?: number | null;
}
/**
 *
 * @export
 * @interface CreatorsCreatorIdChallengesGet200ResponseInner
 */
export interface CreatorsCreatorIdChallengesGet200ResponseInner {
  /**
   *
   * @type {number}
   * @memberof CreatorsCreatorIdChallengesGet200ResponseInner
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof CreatorsCreatorIdChallengesGet200ResponseInner
   */
  created_at?: number;
  /**
   *
   * @type {number}
   * @memberof CreatorsCreatorIdChallengesGet200ResponseInner
   */
  updated_at?: number | null;
  /**
   *
   * @type {string}
   * @memberof CreatorsCreatorIdChallengesGet200ResponseInner
   */
  title?: string;
  /**
   *
   * @type {number}
   * @memberof CreatorsCreatorIdChallengesGet200ResponseInner
   */
  price?: number;
  /**
   *
   * @type {string}
   * @memberof CreatorsCreatorIdChallengesGet200ResponseInner
   */
  type?: CreatorsCreatorIdChallengesGet200ResponseInnerTypeEnum;
  /**
   *
   * @type {string}
   * @memberof CreatorsCreatorIdChallengesGet200ResponseInner
   */
  status?: CreatorsCreatorIdChallengesGet200ResponseInnerStatusEnum;
  /**
   *
   * @type {string}
   * @memberof CreatorsCreatorIdChallengesGet200ResponseInner
   */
  payment_type?: CreatorsCreatorIdChallengesGet200ResponseInnerPaymentTypeEnum;
  /**
   *
   * @type {object}
   * @memberof CreatorsCreatorIdChallengesGet200ResponseInner
   */
  meta?: object | null;
  /**
   *
   * @type {CreatorsCreatorIdChallengesGet200ResponseInnerConfig}
   * @memberof CreatorsCreatorIdChallengesGet200ResponseInner
   */
  config?: CreatorsCreatorIdChallengesGet200ResponseInnerConfig | null;
  /**
   *
   * @type {CreatorsCreatorIdChallengesGet200ResponseInnerMarketingPriceConfig}
   * @memberof CreatorsCreatorIdChallengesGet200ResponseInner
   */
  marketingPriceConfig?: CreatorsCreatorIdChallengesGet200ResponseInnerMarketingPriceConfig;
  /**
   *
   * @type {AvatarsAvatarIdReplacePost200ResponseAvatar}
   * @memberof CreatorsCreatorIdChallengesGet200ResponseInner
   */
  cover?: AvatarsAvatarIdReplacePost200ResponseAvatar | null;
}

export const CreatorsCreatorIdChallengesGet200ResponseInnerTypeEnum = {
  Post: 'post',
  Live: 'live',
  Challenge: 'challenge',
  Trailer: 'trailer',
  Channel: 'channel',
  Community: 'community',
} as const;

export type CreatorsCreatorIdChallengesGet200ResponseInnerTypeEnum =
  typeof CreatorsCreatorIdChallengesGet200ResponseInnerTypeEnum[keyof typeof CreatorsCreatorIdChallengesGet200ResponseInnerTypeEnum];
export const CreatorsCreatorIdChallengesGet200ResponseInnerStatusEnum = {
  Draft: 'draft',
  Published: 'published',
} as const;

export type CreatorsCreatorIdChallengesGet200ResponseInnerStatusEnum =
  typeof CreatorsCreatorIdChallengesGet200ResponseInnerStatusEnum[keyof typeof CreatorsCreatorIdChallengesGet200ResponseInnerStatusEnum];
export const CreatorsCreatorIdChallengesGet200ResponseInnerPaymentTypeEnum = {
  FixedPrice: 'fixed_price',
  FixedPriceSubscribersOnly: 'fixed_price_subscribers_only',
  SubscribersOnly: 'subscribers_only',
  Free: 'free',
  MonthlyPrice: 'monthly_price',
} as const;

export type CreatorsCreatorIdChallengesGet200ResponseInnerPaymentTypeEnum =
  typeof CreatorsCreatorIdChallengesGet200ResponseInnerPaymentTypeEnum[keyof typeof CreatorsCreatorIdChallengesGet200ResponseInnerPaymentTypeEnum];

/**
 *
 * @export
 * @interface CreatorsCreatorIdChallengesGet200ResponseInnerConfig
 */
export interface CreatorsCreatorIdChallengesGet200ResponseInnerConfig {
  /**
   *
   * @type {CreatorsCreatorIdChallengesGet200ResponseInnerConfigChallenge}
   * @memberof CreatorsCreatorIdChallengesGet200ResponseInnerConfig
   */
  challenge?: CreatorsCreatorIdChallengesGet200ResponseInnerConfigChallenge | null;
}
/**
 *
 * @export
 * @interface CreatorsCreatorIdChallengesGet200ResponseInnerConfigChallenge
 */
export interface CreatorsCreatorIdChallengesGet200ResponseInnerConfigChallenge {
  /**
   *
   * @type {number}
   * @memberof CreatorsCreatorIdChallengesGet200ResponseInnerConfigChallenge
   */
  finishTimestamp?: number | null;
}
/**
 *
 * @export
 * @interface CreatorsCreatorIdChallengesGet200ResponseInnerMarketingPriceConfig
 */
export interface CreatorsCreatorIdChallengesGet200ResponseInnerMarketingPriceConfig {
  /**
   *
   * @type {number}
   * @memberof CreatorsCreatorIdChallengesGet200ResponseInnerMarketingPriceConfig
   */
  previousPrice?: number | null;
}
/**
 *
 * @export
 * @interface CreatorsCreatorIdLinksGet200Response
 */
export interface CreatorsCreatorIdLinksGet200Response {
  /**
   *
   * @type {number}
   * @memberof CreatorsCreatorIdLinksGet200Response
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof CreatorsCreatorIdLinksGet200Response
   */
  createdAt?: number;
  /**
   *
   * @type {string}
   * @memberof CreatorsCreatorIdLinksGet200Response
   */
  href?: string;
  /**
   *
   * @type {string}
   * @memberof CreatorsCreatorIdLinksGet200Response
   */
  title?: string;
  /**
   *
   * @type {number}
   * @memberof CreatorsCreatorIdLinksGet200Response
   */
  order?: number;
}
/**
 *
 * @export
 * @interface CreatorsCreatorIdLinksLinkIdDelete200Response
 */
export interface CreatorsCreatorIdLinksLinkIdDelete200Response {
  /**
   *
   * @type {number}
   * @memberof CreatorsCreatorIdLinksLinkIdDelete200Response
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof CreatorsCreatorIdLinksLinkIdDelete200Response
   */
  createdAt?: number;
  /**
   *
   * @type {number}
   * @memberof CreatorsCreatorIdLinksLinkIdDelete200Response
   */
  updatedAt?: number | null;
  /**
   *
   * @type {string}
   * @memberof CreatorsCreatorIdLinksLinkIdDelete200Response
   */
  href?: string;
  /**
   *
   * @type {string}
   * @memberof CreatorsCreatorIdLinksLinkIdDelete200Response
   */
  title?: string;
  /**
   *
   * @type {number}
   * @memberof CreatorsCreatorIdLinksLinkIdDelete200Response
   */
  order?: number;
}
/**
 *
 * @export
 * @interface CreatorsCreatorIdLinksLinkIdDeleteRequest
 */
export interface CreatorsCreatorIdLinksLinkIdDeleteRequest {
  /**
   *
   * @type {string}
   * @memberof CreatorsCreatorIdLinksLinkIdDeleteRequest
   */
  href?: string;
  /**
   *
   * @type {string}
   * @memberof CreatorsCreatorIdLinksLinkIdDeleteRequest
   */
  title?: string;
  /**
   *
   * @type {number}
   * @memberof CreatorsCreatorIdLinksLinkIdDeleteRequest
   */
  order?: number;
}
/**
 *
 * @export
 * @interface CreatorsCreatorIdLinksOrderPatchRequest
 */
export interface CreatorsCreatorIdLinksOrderPatchRequest {
  /**
   *
   * @type {Array<CreatorsCreatorIdLinksOrderPatchRequestLinksInner>}
   * @memberof CreatorsCreatorIdLinksOrderPatchRequest
   */
  links?: Array<CreatorsCreatorIdLinksOrderPatchRequestLinksInner>;
}
/**
 *
 * @export
 * @interface CreatorsCreatorIdLinksOrderPatchRequestLinksInner
 */
export interface CreatorsCreatorIdLinksOrderPatchRequestLinksInner {
  /**
   *
   * @type {number}
   * @memberof CreatorsCreatorIdLinksOrderPatchRequestLinksInner
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof CreatorsCreatorIdLinksOrderPatchRequestLinksInner
   */
  order?: number;
}
/**
 *
 * @export
 * @interface CreatorsCreatorIdPatch200Response
 */
export interface CreatorsCreatorIdPatch200Response {
  /**
   *
   * @type {string}
   * @memberof CreatorsCreatorIdPatch200Response
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof CreatorsCreatorIdPatch200Response
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof CreatorsCreatorIdPatch200Response
   */
  description?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof CreatorsCreatorIdPatch200Response
   */
  isAvatar?: boolean;
  /**
   *
   * @type {AvatarsAvatarIdReplacePost200ResponseAvatar}
   * @memberof CreatorsCreatorIdPatch200Response
   */
  avatar?: AvatarsAvatarIdReplacePost200ResponseAvatar | null;
  /**
   *
   * @type {number}
   * @memberof CreatorsCreatorIdPatch200Response
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof CreatorsCreatorIdPatch200Response
   */
  created_at?: number;
  /**
   *
   * @type {number}
   * @memberof CreatorsCreatorIdPatch200Response
   */
  updatedAt?: number | null;
  /**
   *
   * @type {number}
   * @memberof CreatorsCreatorIdPatch200Response
   */
  user?: number;
  /**
   *
   * @type {string}
   * @memberof CreatorsCreatorIdPatch200Response
   */
  slug?: string;
  /**
   *
   * @type {string}
   * @memberof CreatorsCreatorIdPatch200Response
   */
  userSlug?: string | null;
  /**
   *
   * @type {AvatarsAvatarIdReplacePost200ResponseClub}
   * @memberof CreatorsCreatorIdPatch200Response
   */
  club?: AvatarsAvatarIdReplacePost200ResponseClub | null;
}
/**
 *
 * @export
 * @interface CreatorsCreatorIdPatchRequest
 */
export interface CreatorsCreatorIdPatchRequest {
  /**
   *
   * @type {number}
   * @memberof CreatorsCreatorIdPatchRequest
   */
  updatedAt?: number | null;
  /**
   *
   * @type {string}
   * @memberof CreatorsCreatorIdPatchRequest
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof CreatorsCreatorIdPatchRequest
   */
  name?: string;
  /**
   *
   * @type {number}
   * @memberof CreatorsCreatorIdPatchRequest
   */
  deletedAt?: number | null;
  /**
   *
   * @type {string}
   * @memberof CreatorsCreatorIdPatchRequest
   */
  description?: string | null;
  /**
   *
   * @type {number}
   * @memberof CreatorsCreatorIdPatchRequest
   */
  avatarId?: number;
  /**
   *
   * @type {string}
   * @memberof CreatorsCreatorIdPatchRequest
   */
  slug?: string;
}
/**
 *
 * @export
 * @interface CreatorsGet200Response
 */
export interface CreatorsGet200Response {
  /**
   *
   * @type {string}
   * @memberof CreatorsGet200Response
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof CreatorsGet200Response
   */
  name?: string;
  /**
   *
   * @type {boolean}
   * @memberof CreatorsGet200Response
   */
  isAvatar?: boolean;
  /**
   *
   * @type {AvatarsAvatarIdReplacePost200ResponseAvatar}
   * @memberof CreatorsGet200Response
   */
  avatar?: AvatarsAvatarIdReplacePost200ResponseAvatar | null;
  /**
   *
   * @type {number}
   * @memberof CreatorsGet200Response
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof CreatorsGet200Response
   */
  created_at?: number;
  /**
   *
   * @type {number}
   * @memberof CreatorsGet200Response
   */
  updatedAt?: number | null;
  /**
   *
   * @type {number}
   * @memberof CreatorsGet200Response
   */
  user?: number;
  /**
   *
   * @type {string}
   * @memberof CreatorsGet200Response
   */
  slug?: string;
  /**
   *
   * @type {string}
   * @memberof CreatorsGet200Response
   */
  userSlug?: string | null;
  /**
   *
   * @type {AvatarsAvatarIdReplacePost200ResponseClub}
   * @memberof CreatorsGet200Response
   */
  club?: AvatarsAvatarIdReplacePost200ResponseClub | null;
}
/**
 *
 * @export
 * @interface CreatorsGet200ResponseInner
 */
export interface CreatorsGet200ResponseInner {
  /**
   *
   * @type {number}
   * @memberof CreatorsGet200ResponseInner
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof CreatorsGet200ResponseInner
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof CreatorsGet200ResponseInner
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof CreatorsGet200ResponseInner
   */
  description?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof CreatorsGet200ResponseInner
   */
  isAvatar?: boolean;
  /**
   *
   * @type {AvatarsAvatarIdReplacePost200ResponseAvatar}
   * @memberof CreatorsGet200ResponseInner
   */
  avatar?: AvatarsAvatarIdReplacePost200ResponseAvatar | null;
  /**
   *
   * @type {AvatarsAvatarIdReplacePost200ResponseClub}
   * @memberof CreatorsGet200ResponseInner
   */
  club?: AvatarsAvatarIdReplacePost200ResponseClub | null;
  /**
   *
   * @type {string}
   * @memberof CreatorsGet200ResponseInner
   */
  slug?: string;
}
/**
 *
 * @export
 * @interface CreatorsGetRequest
 */
export interface CreatorsGetRequest {
  /**
   *
   * @type {string}
   * @memberof CreatorsGetRequest
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof CreatorsGetRequest
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof CreatorsGetRequest
   */
  description?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof CreatorsGetRequest
   */
  isAvatar?: boolean;
  /**
   *
   * @type {number}
   * @memberof CreatorsGetRequest
   */
  avatarId?: number;
  /**
   *
   * @type {string}
   * @memberof CreatorsGetRequest
   */
  slug?: string;
}
/**
 *
 * @export
 * @interface LivePost200Response
 */
export interface LivePost200Response {
  /**
   *
   * @type {number}
   * @memberof LivePost200Response
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof LivePost200Response
   */
  creatorId?: number;
  /**
   *
   * @type {number}
   * @memberof LivePost200Response
   */
  spaceId?: number;
  /**
   *
   * @type {number}
   * @memberof LivePost200Response
   */
  startAt?: number;
  /**
   *
   * @type {number}
   * @memberof LivePost200Response
   */
  duration?: number;
  /**
   *
   * @type {string}
   * @memberof LivePost200Response
   */
  link?: string;
  /**
   *
   * @type {string}
   * @memberof LivePost200Response
   */
  creatorDescription?: string;
  /**
   *
   * @type {string}
   * @memberof LivePost200Response
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof LivePost200Response
   */
  requirements?: string;
  /**
   *
   * @type {string}
   * @memberof LivePost200Response
   */
  offerDescription?: string;
  /**
   *
   * @type {string}
   * @memberof LivePost200Response
   */
  offerSummary?: string;
}
/**
 *
 * @export
 * @interface LivePostRequest
 */
export interface LivePostRequest {
  /**
   *
   * @type {number}
   * @memberof LivePostRequest
   */
  creatorId?: number;
  /**
   *
   * @type {number}
   * @memberof LivePostRequest
   */
  spaceId?: number;
  /**
   *
   * @type {number}
   * @memberof LivePostRequest
   */
  startAt?: number;
  /**
   *
   * @type {number}
   * @memberof LivePostRequest
   */
  duration?: number;
  /**
   *
   * @type {string}
   * @memberof LivePostRequest
   */
  creatorDescription?: string | null;
  /**
   *
   * @type {string}
   * @memberof LivePostRequest
   */
  description?: string | null;
  /**
   *
   * @type {string}
   * @memberof LivePostRequest
   */
  requirements?: string | null;
  /**
   *
   * @type {string}
   * @memberof LivePostRequest
   */
  offerDescription?: string | null;
  /**
   *
   * @type {string}
   * @memberof LivePostRequest
   */
  offerSummary?: string | null;
  /**
   *
   * @type {string}
   * @memberof LivePostRequest
   */
  link?: string;
  /**
   *
   * @type {string}
   * @memberof LivePostRequest
   */
  title?: string;
}
/**
 *
 * @export
 * @interface LivesLiveIdDelete200Response
 */
export interface LivesLiveIdDelete200Response {
  /**
   *
   * @type {number}
   * @memberof LivesLiveIdDelete200Response
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof LivesLiveIdDelete200Response
   */
  title?: string;
  /**
   *
   * @type {number}
   * @memberof LivesLiveIdDelete200Response
   */
  startAt?: number;
  /**
   *
   * @type {number}
   * @memberof LivesLiveIdDelete200Response
   */
  duration?: number;
  /**
   *
   * @type {string}
   * @memberof LivesLiveIdDelete200Response
   */
  link?: string;
  /**
   *
   * @type {string}
   * @memberof LivesLiveIdDelete200Response
   */
  creatorDescription?: string;
  /**
   *
   * @type {string}
   * @memberof LivesLiveIdDelete200Response
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof LivesLiveIdDelete200Response
   */
  requirements?: string;
  /**
   *
   * @type {string}
   * @memberof LivesLiveIdDelete200Response
   */
  offerDescription?: string;
  /**
   *
   * @type {string}
   * @memberof LivesLiveIdDelete200Response
   */
  offerSummary?: string;
  /**
   *
   * @type {LivesLiveIdDelete200ResponseRecord}
   * @memberof LivesLiveIdDelete200Response
   */
  record?: LivesLiveIdDelete200ResponseRecord | null;
}
/**
 *
 * @export
 * @interface LivesLiveIdDelete200ResponseRecord
 */
export interface LivesLiveIdDelete200ResponseRecord {
  /**
   *
   * @type {number}
   * @memberof LivesLiveIdDelete200ResponseRecord
   */
  videoFileId?: number;
  /**
   *
   * @type {number}
   * @memberof LivesLiveIdDelete200ResponseRecord
   */
  coverFileId?: number;
  /**
   *
   * @type {object}
   * @memberof LivesLiveIdDelete200ResponseRecord
   */
  meta?: object | null;
}
/**
 *
 * @export
 * @interface LivesLiveIdDeleteRequest
 */
export interface LivesLiveIdDeleteRequest {
  /**
   *
   * @type {number}
   * @memberof LivesLiveIdDeleteRequest
   */
  startAt?: number;
  /**
   *
   * @type {number}
   * @memberof LivesLiveIdDeleteRequest
   */
  duration?: number;
  /**
   *
   * @type {string}
   * @memberof LivesLiveIdDeleteRequest
   */
  creatorDescription?: string | null;
  /**
   *
   * @type {string}
   * @memberof LivesLiveIdDeleteRequest
   */
  description?: string | null;
  /**
   *
   * @type {string}
   * @memberof LivesLiveIdDeleteRequest
   */
  requirements?: string | null;
  /**
   *
   * @type {string}
   * @memberof LivesLiveIdDeleteRequest
   */
  offerDescription?: string | null;
  /**
   *
   * @type {string}
   * @memberof LivesLiveIdDeleteRequest
   */
  offerSummary?: string | null;
  /**
   *
   * @type {string}
   * @memberof LivesLiveIdDeleteRequest
   */
  link?: string;
  /**
   *
   * @type {string}
   * @memberof LivesLiveIdDeleteRequest
   */
  title?: string;
}
/**
 *
 * @export
 * @interface ModerationChatsSpaceGet200ResponseInner
 */
export interface ModerationChatsSpaceGet200ResponseInner {
  /**
   *
   * @type {number}
   * @memberof ModerationChatsSpaceGet200ResponseInner
   */
  id?: number;
  /**
   *
   * @type {ModerationChatsSpaceGet200ResponseInnerSpace}
   * @memberof ModerationChatsSpaceGet200ResponseInner
   */
  space?: ModerationChatsSpaceGet200ResponseInnerSpace | null;
}
/**
 *
 * @export
 * @interface ModerationChatsSpaceGet200ResponseInnerSpace
 */
export interface ModerationChatsSpaceGet200ResponseInnerSpace {
  /**
   *
   * @type {number}
   * @memberof ModerationChatsSpaceGet200ResponseInnerSpace
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof ModerationChatsSpaceGet200ResponseInnerSpace
   */
  title?: string;
  /**
   *
   * @type {ModerationChatsSpaceGet200ResponseInnerSpaceCreator}
   * @memberof ModerationChatsSpaceGet200ResponseInnerSpace
   */
  creator?: ModerationChatsSpaceGet200ResponseInnerSpaceCreator | null;
  /**
   *
   * @type {AvatarsAvatarIdReplacePost200ResponseAvatar}
   * @memberof ModerationChatsSpaceGet200ResponseInnerSpace
   */
  cover?: AvatarsAvatarIdReplacePost200ResponseAvatar | null;
}
/**
 *
 * @export
 * @interface ModerationChatsSpaceGet200ResponseInnerSpaceCreator
 */
export interface ModerationChatsSpaceGet200ResponseInnerSpaceCreator {
  /**
   *
   * @type {number}
   * @memberof ModerationChatsSpaceGet200ResponseInnerSpaceCreator
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof ModerationChatsSpaceGet200ResponseInnerSpaceCreator
   */
  name?: string;
}
/**
 *
 * @export
 * @interface Notify0Post200Response
 */
export interface Notify0Post200Response {
  /**
   *
   * @type {string}
   * @memberof Notify0Post200Response
   */
  successfull?: string;
}
/**
 *
 * @export
 * @interface Notify0PostRequest
 */
export interface Notify0PostRequest {
  /**
   *
   * @type {number}
   * @memberof Notify0PostRequest
   */
  userId?: number | null;
  /**
   *
   * @type {string}
   * @memberof Notify0PostRequest
   */
  message?: string;
  /**
   *
   * @type {string}
   * @memberof Notify0PostRequest
   */
  url?: string | null;
}
/**
 *
 * @export
 * @interface NotifyPostRequest
 */
export interface NotifyPostRequest {
  /**
   *
   * @type {string}
   * @memberof NotifyPostRequest
   */
  message?: string;
  /**
   *
   * @type {string}
   * @memberof NotifyPostRequest
   */
  url?: string | null;
  /**
   *
   * @type {string}
   * @memberof NotifyPostRequest
   */
  deviceToken?: string;
}
/**
 *
 * @export
 * @interface NotifyRemovePostRequest
 */
export interface NotifyRemovePostRequest {
  /**
   *
   * @type {string}
   * @memberof NotifyRemovePostRequest
   */
  deviceToken?: string;
  /**
   *
   * @type {string}
   * @memberof NotifyRemovePostRequest
   */
  notificationId?: string;
}
/**
 *
 * @export
 * @interface PlatformPostsGet200ResponseInner
 */
export interface PlatformPostsGet200ResponseInner {
  /**
   *
   * @type {number}
   * @memberof PlatformPostsGet200ResponseInner
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof PlatformPostsGet200ResponseInner
   */
  createdAt?: number;
  /**
   *
   * @type {number}
   * @memberof PlatformPostsGet200ResponseInner
   */
  updatedAt?: number | null;
  /**
   *
   * @type {number}
   * @memberof PlatformPostsGet200ResponseInner
   */
  publishAt?: number | null;
  /**
   *
   * @type {number}
   * @memberof PlatformPostsGet200ResponseInner
   */
  publishedAt?: number | null;
  /**
   *
   * @type {number}
   * @memberof PlatformPostsGet200ResponseInner
   */
  sentAt?: number | null;
  /**
   *
   * @type {object}
   * @memberof PlatformPostsGet200ResponseInner
   */
  content?: object;
  /**
   *
   * @type {number}
   * @memberof PlatformPostsGet200ResponseInner
   */
  order?: number;
  /**
   *
   * @type {string}
   * @memberof PlatformPostsGet200ResponseInner
   */
  type?: PlatformPostsGet200ResponseInnerTypeEnum;
  /**
   *
   * @type {string}
   * @memberof PlatformPostsGet200ResponseInner
   */
  event?: PlatformPostsGet200ResponseInnerEventEnum;
  /**
   *
   * @type {object}
   * @memberof PlatformPostsGet200ResponseInner
   */
  eventParams?: object | null;
}

export const PlatformPostsGet200ResponseInnerTypeEnum = {
  Onboarding: 'onboarding',
  Realtime: 'realtime',
  AfterEvent: 'afterEvent',
  Deferred: 'deferred',
} as const;

export type PlatformPostsGet200ResponseInnerTypeEnum =
  typeof PlatformPostsGet200ResponseInnerTypeEnum[keyof typeof PlatformPostsGet200ResponseInnerTypeEnum];
export const PlatformPostsGet200ResponseInnerEventEnum = {
  TripleMarkAsComplete: 'tripleMarkAsComplete',
} as const;

export type PlatformPostsGet200ResponseInnerEventEnum =
  typeof PlatformPostsGet200ResponseInnerEventEnum[keyof typeof PlatformPostsGet200ResponseInnerEventEnum];

/**
 *
 * @export
 * @interface PlatformPostsGetRequest
 */
export interface PlatformPostsGetRequest {
  /**
   *
   * @type {object}
   * @memberof PlatformPostsGetRequest
   */
  content?: object;
  /**
   *
   * @type {string}
   * @memberof PlatformPostsGetRequest
   */
  type?: PlatformPostsGetRequestTypeEnum;
  /**
   *
   * @type {string}
   * @memberof PlatformPostsGetRequest
   */
  event?: PlatformPostsGetRequestEventEnum;
  /**
   *
   * @type {object}
   * @memberof PlatformPostsGetRequest
   */
  eventParams?: object | null;
  /**
   *
   * @type {number}
   * @memberof PlatformPostsGetRequest
   */
  publishAt?: number | null;
}

export const PlatformPostsGetRequestTypeEnum = {
  Onboarding: 'onboarding',
  Realtime: 'realtime',
  AfterEvent: 'afterEvent',
  Deferred: 'deferred',
} as const;

export type PlatformPostsGetRequestTypeEnum =
  typeof PlatformPostsGetRequestTypeEnum[keyof typeof PlatformPostsGetRequestTypeEnum];
export const PlatformPostsGetRequestEventEnum = {
  TripleMarkAsComplete: 'tripleMarkAsComplete',
} as const;

export type PlatformPostsGetRequestEventEnum =
  typeof PlatformPostsGetRequestEventEnum[keyof typeof PlatformPostsGetRequestEventEnum];

/**
 *
 * @export
 * @interface PlatformPostsOrderPostRequest
 */
export interface PlatformPostsOrderPostRequest {
  /**
   *
   * @type {Array<CreatorsCreatorIdLinksOrderPatchRequestLinksInner>}
   * @memberof PlatformPostsOrderPostRequest
   */
  posts?: Array<CreatorsCreatorIdLinksOrderPatchRequestLinksInner>;
}
/**
 *
 * @export
 * @interface PlatformPostsPostIdDelete200Response
 */
export interface PlatformPostsPostIdDelete200Response {
  /**
   *
   * @type {number}
   * @memberof PlatformPostsPostIdDelete200Response
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof PlatformPostsPostIdDelete200Response
   */
  createdAt?: number;
  /**
   *
   * @type {number}
   * @memberof PlatformPostsPostIdDelete200Response
   */
  updatedAt?: number | null;
  /**
   *
   * @type {number}
   * @memberof PlatformPostsPostIdDelete200Response
   */
  publishAt?: number | null;
  /**
   *
   * @type {number}
   * @memberof PlatformPostsPostIdDelete200Response
   */
  publishedAt?: number | null;
  /**
   *
   * @type {object}
   * @memberof PlatformPostsPostIdDelete200Response
   */
  content?: object;
  /**
   *
   * @type {number}
   * @memberof PlatformPostsPostIdDelete200Response
   */
  order?: number;
  /**
   *
   * @type {string}
   * @memberof PlatformPostsPostIdDelete200Response
   */
  type?: PlatformPostsPostIdDelete200ResponseTypeEnum;
  /**
   *
   * @type {string}
   * @memberof PlatformPostsPostIdDelete200Response
   */
  event?: PlatformPostsPostIdDelete200ResponseEventEnum;
  /**
   *
   * @type {object}
   * @memberof PlatformPostsPostIdDelete200Response
   */
  eventParams?: object | null;
}

export const PlatformPostsPostIdDelete200ResponseTypeEnum = {
  Onboarding: 'onboarding',
  Realtime: 'realtime',
  AfterEvent: 'afterEvent',
  Deferred: 'deferred',
} as const;

export type PlatformPostsPostIdDelete200ResponseTypeEnum =
  typeof PlatformPostsPostIdDelete200ResponseTypeEnum[keyof typeof PlatformPostsPostIdDelete200ResponseTypeEnum];
export const PlatformPostsPostIdDelete200ResponseEventEnum = {
  TripleMarkAsComplete: 'tripleMarkAsComplete',
} as const;

export type PlatformPostsPostIdDelete200ResponseEventEnum =
  typeof PlatformPostsPostIdDelete200ResponseEventEnum[keyof typeof PlatformPostsPostIdDelete200ResponseEventEnum];

/**
 *
 * @export
 * @interface PlatformPostsPostIdDeleteRequest
 */
export interface PlatformPostsPostIdDeleteRequest {
  /**
   *
   * @type {object}
   * @memberof PlatformPostsPostIdDeleteRequest
   */
  content?: object | null;
  /**
   *
   * @type {number}
   * @memberof PlatformPostsPostIdDeleteRequest
   */
  order?: number | null;
  /**
   *
   * @type {string}
   * @memberof PlatformPostsPostIdDeleteRequest
   */
  type?: PlatformPostsPostIdDeleteRequestTypeEnum;
  /**
   *
   * @type {string}
   * @memberof PlatformPostsPostIdDeleteRequest
   */
  event?: PlatformPostsPostIdDeleteRequestEventEnum;
  /**
   *
   * @type {object}
   * @memberof PlatformPostsPostIdDeleteRequest
   */
  eventParams?: object | null;
  /**
   *
   * @type {number}
   * @memberof PlatformPostsPostIdDeleteRequest
   */
  publishAt?: number | null;
}

export const PlatformPostsPostIdDeleteRequestTypeEnum = {
  Onboarding: 'onboarding',
  Realtime: 'realtime',
  AfterEvent: 'afterEvent',
  Deferred: 'deferred',
} as const;

export type PlatformPostsPostIdDeleteRequestTypeEnum =
  typeof PlatformPostsPostIdDeleteRequestTypeEnum[keyof typeof PlatformPostsPostIdDeleteRequestTypeEnum];
export const PlatformPostsPostIdDeleteRequestEventEnum = {
  TripleMarkAsComplete: 'tripleMarkAsComplete',
} as const;

export type PlatformPostsPostIdDeleteRequestEventEnum =
  typeof PlatformPostsPostIdDeleteRequestEventEnum[keyof typeof PlatformPostsPostIdDeleteRequestEventEnum];

/**
 *
 * @export
 * @interface PlatformPostsPostIdLocalPublishPostRequest
 */
export interface PlatformPostsPostIdLocalPublishPostRequest {
  /**
   *
   * @type {Array<number>}
   * @memberof PlatformPostsPostIdLocalPublishPostRequest
   */
  userId?: Array<number>;
}
/**
 *
 * @export
 * @interface PlatformPostsPostIdPublishPost200Response
 */
export interface PlatformPostsPostIdPublishPost200Response {
  /**
   *
   * @type {number}
   * @memberof PlatformPostsPostIdPublishPost200Response
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof PlatformPostsPostIdPublishPost200Response
   */
  createdAt?: number;
  /**
   *
   * @type {number}
   * @memberof PlatformPostsPostIdPublishPost200Response
   */
  updatedAt?: number | null;
  /**
   *
   * @type {number}
   * @memberof PlatformPostsPostIdPublishPost200Response
   */
  publishedAt?: number | null;
  /**
   *
   * @type {object}
   * @memberof PlatformPostsPostIdPublishPost200Response
   */
  content?: object;
  /**
   *
   * @type {number}
   * @memberof PlatformPostsPostIdPublishPost200Response
   */
  order?: number;
  /**
   *
   * @type {string}
   * @memberof PlatformPostsPostIdPublishPost200Response
   */
  type?: PlatformPostsPostIdPublishPost200ResponseTypeEnum;
  /**
   *
   * @type {string}
   * @memberof PlatformPostsPostIdPublishPost200Response
   */
  event?: PlatformPostsPostIdPublishPost200ResponseEventEnum;
  /**
   *
   * @type {object}
   * @memberof PlatformPostsPostIdPublishPost200Response
   */
  eventParams?: object | null;
}

export const PlatformPostsPostIdPublishPost200ResponseTypeEnum = {
  Onboarding: 'onboarding',
  Realtime: 'realtime',
  AfterEvent: 'afterEvent',
  Deferred: 'deferred',
} as const;

export type PlatformPostsPostIdPublishPost200ResponseTypeEnum =
  typeof PlatformPostsPostIdPublishPost200ResponseTypeEnum[keyof typeof PlatformPostsPostIdPublishPost200ResponseTypeEnum];
export const PlatformPostsPostIdPublishPost200ResponseEventEnum = {
  TripleMarkAsComplete: 'tripleMarkAsComplete',
} as const;

export type PlatformPostsPostIdPublishPost200ResponseEventEnum =
  typeof PlatformPostsPostIdPublishPost200ResponseEventEnum[keyof typeof PlatformPostsPostIdPublishPost200ResponseEventEnum];

/**
 *
 * @export
 * @interface SpacesGet200ResponseInner
 */
export interface SpacesGet200ResponseInner {
  /**
   *
   * @type {number}
   * @memberof SpacesGet200ResponseInner
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof SpacesGet200ResponseInner
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof SpacesGet200ResponseInner
   */
  type?: SpacesGet200ResponseInnerTypeEnum;
  /**
   *
   * @type {string}
   * @memberof SpacesGet200ResponseInner
   */
  status?: SpacesGet200ResponseInnerStatusEnum;
  /**
   *
   * @type {number}
   * @memberof SpacesGet200ResponseInner
   */
  metaImageFileId?: number | null;
  /**
   *
   * @type {string}
   * @memberof SpacesGet200ResponseInner
   */
  metaDescription?: string | null;
  /**
   *
   * @type {SpacesGet200ResponseInnerConfig}
   * @memberof SpacesGet200ResponseInner
   */
  config?: SpacesGet200ResponseInnerConfig | null;
  /**
   *
   * @type {CreatorsCreatorIdChallengesGet200ResponseInnerMarketingPriceConfig}
   * @memberof SpacesGet200ResponseInner
   */
  marketingPriceConfig?: CreatorsCreatorIdChallengesGet200ResponseInnerMarketingPriceConfig;
  /**
   *
   * @type {number}
   * @memberof SpacesGet200ResponseInner
   */
  stepsCount?: number;
  /**
   *
   * @type {number}
   * @memberof SpacesGet200ResponseInner
   */
  startAt?: number | null;
  /**
   *
   * @type {SpacesGet200ResponseInnerCreator}
   * @memberof SpacesGet200ResponseInner
   */
  creator?: SpacesGet200ResponseInnerCreator | null;
  /**
   *
   * @type {AvatarsAvatarIdReplacePost200ResponseAvatar}
   * @memberof SpacesGet200ResponseInner
   */
  cover?: AvatarsAvatarIdReplacePost200ResponseAvatar | null;
  /**
   *
   * @type {Array<SpacesGet200ResponseInnerTagsInner>}
   * @memberof SpacesGet200ResponseInner
   */
  tags?: Array<SpacesGet200ResponseInnerTagsInner>;
  /**
   *
   * @type {string}
   * @memberof SpacesGet200ResponseInner
   */
  slug?: string;
  /**
   *
   * @type {AvatarsAvatarIdReplacePost200ResponseAvatar}
   * @memberof SpacesGet200ResponseInner
   */
  metaImage?: AvatarsAvatarIdReplacePost200ResponseAvatar | null;
}

export const SpacesGet200ResponseInnerTypeEnum = {
  Post: 'post',
  Live: 'live',
  Challenge: 'challenge',
  Trailer: 'trailer',
  Channel: 'channel',
  Community: 'community',
} as const;

export type SpacesGet200ResponseInnerTypeEnum =
  typeof SpacesGet200ResponseInnerTypeEnum[keyof typeof SpacesGet200ResponseInnerTypeEnum];
export const SpacesGet200ResponseInnerStatusEnum = {
  Draft: 'draft',
  Published: 'published',
} as const;

export type SpacesGet200ResponseInnerStatusEnum =
  typeof SpacesGet200ResponseInnerStatusEnum[keyof typeof SpacesGet200ResponseInnerStatusEnum];

/**
 *
 * @export
 * @interface SpacesGet200ResponseInnerConfig
 */
export interface SpacesGet200ResponseInnerConfig {
  /**
   *
   * @type {CreatorsCreatorIdChallengesGet200ResponseInnerConfigChallenge}
   * @memberof SpacesGet200ResponseInnerConfig
   */
  challenge?: CreatorsCreatorIdChallengesGet200ResponseInnerConfigChallenge | null;
  /**
   *
   * @type {SpacesGet200ResponseInnerConfigLive}
   * @memberof SpacesGet200ResponseInnerConfig
   */
  live?: SpacesGet200ResponseInnerConfigLive | null;
}
/**
 *
 * @export
 * @interface SpacesGet200ResponseInnerConfigLive
 */
export interface SpacesGet200ResponseInnerConfigLive {
  /**
   *
   * @type {number}
   * @memberof SpacesGet200ResponseInnerConfigLive
   */
  startAt?: number;
  /**
   *
   * @type {number}
   * @memberof SpacesGet200ResponseInnerConfigLive
   */
  duration?: number;
}
/**
 *
 * @export
 * @interface SpacesGet200ResponseInnerCreator
 */
export interface SpacesGet200ResponseInnerCreator {
  /**
   *
   * @type {number}
   * @memberof SpacesGet200ResponseInnerCreator
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof SpacesGet200ResponseInnerCreator
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof SpacesGet200ResponseInnerCreator
   */
  description?: string | null;
  /**
   *
   * @type {AvatarsAvatarIdReplacePost200ResponseAvatar}
   * @memberof SpacesGet200ResponseInnerCreator
   */
  avatar?: AvatarsAvatarIdReplacePost200ResponseAvatar | null;
}
/**
 *
 * @export
 * @interface SpacesGet200ResponseInnerTagsInner
 */
export interface SpacesGet200ResponseInnerTagsInner {
  /**
   *
   * @type {number}
   * @memberof SpacesGet200ResponseInnerTagsInner
   */
  weight?: number;
  /**
   *
   * @type {number}
   * @memberof SpacesGet200ResponseInnerTagsInner
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof SpacesGet200ResponseInnerTagsInner
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof SpacesGet200ResponseInnerTagsInner
   */
  color?: string;
}
/**
 *
 * @export
 * @interface SpacesSpaceIdDelete200Response
 */
export interface SpacesSpaceIdDelete200Response {
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdDelete200Response
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdDelete200Response
   */
  title?: string;
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdDelete200Response
   */
  price?: number;
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdDelete200Response
   */
  coverFileId?: number;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdDelete200Response
   */
  description?: string | null;
  /**
   *
   * @type {SpacesSpaceIdDelete200ResponseConfig}
   * @memberof SpacesSpaceIdDelete200Response
   */
  config?: SpacesSpaceIdDelete200ResponseConfig | null;
  /**
   *
   * @type {AvatarsAvatarIdReplacePost200ResponseAvatar}
   * @memberof SpacesSpaceIdDelete200Response
   */
  cover?: AvatarsAvatarIdReplacePost200ResponseAvatar | null;
  /**
   *
   * @type {Array<LivesLiveIdDelete200Response>}
   * @memberof SpacesSpaceIdDelete200Response
   */
  lives?: Array<LivesLiveIdDelete200Response>;
}
/**
 *
 * @export
 * @interface SpacesSpaceIdDelete200ResponseConfig
 */
export interface SpacesSpaceIdDelete200ResponseConfig {
  /**
   *
   * @type {SpacesSpaceIdDelete200ResponseConfigCommunity}
   * @memberof SpacesSpaceIdDelete200ResponseConfig
   */
  community?: SpacesSpaceIdDelete200ResponseConfigCommunity | null;
}
/**
 *
 * @export
 * @interface SpacesSpaceIdDelete200ResponseConfigCommunity
 */
export interface SpacesSpaceIdDelete200ResponseConfigCommunity {
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdDelete200ResponseConfigCommunity
   */
  updateFrequency?: SpacesSpaceIdDelete200ResponseConfigCommunityUpdateFrequencyEnum;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdDelete200ResponseConfigCommunity
   */
  creatorDescription?: string | null;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdDelete200ResponseConfigCommunity
   */
  targetAudience?: string | null;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdDelete200ResponseConfigCommunity
   */
  inside?: string | null;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdDelete200ResponseConfigCommunity
   */
  offerSummary?: string | null;
  /**
   *
   * @type {Array<string>}
   * @memberof SpacesSpaceIdDelete200ResponseConfigCommunity
   */
  creatorAchievements?: Array<string>;
  /**
   *
   * @type {Array<SpacesSpaceIdDelete200ResponseConfigCommunityReviewsInner>}
   * @memberof SpacesSpaceIdDelete200ResponseConfigCommunity
   */
  reviews?: Array<SpacesSpaceIdDelete200ResponseConfigCommunityReviewsInner> | null;
  /**
   *
   * @type {Array<SpacesSpaceIdDelete200ResponseConfigCommunityFaqInner>}
   * @memberof SpacesSpaceIdDelete200ResponseConfigCommunity
   */
  faq?: Array<SpacesSpaceIdDelete200ResponseConfigCommunityFaqInner> | null;
  /**
   *
   * @type {SpacesSpaceIdDelete200ResponseConfigCommunityVideo}
   * @memberof SpacesSpaceIdDelete200ResponseConfigCommunity
   */
  video?: SpacesSpaceIdDelete200ResponseConfigCommunityVideo | null;
}

export const SpacesSpaceIdDelete200ResponseConfigCommunityUpdateFrequencyEnum = {
  Daily: 'daily',
  TwoThreePerWeek: 'twoThreePerWeek',
  Weekly: 'weekly',
  TwoThreePerMonth: 'twoThreePerMonth',
  Occasionally: 'occasionally',
} as const;

export type SpacesSpaceIdDelete200ResponseConfigCommunityUpdateFrequencyEnum =
  typeof SpacesSpaceIdDelete200ResponseConfigCommunityUpdateFrequencyEnum[keyof typeof SpacesSpaceIdDelete200ResponseConfigCommunityUpdateFrequencyEnum];

/**
 *
 * @export
 * @interface SpacesSpaceIdDelete200ResponseConfigCommunityFaqInner
 */
export interface SpacesSpaceIdDelete200ResponseConfigCommunityFaqInner {
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdDelete200ResponseConfigCommunityFaqInner
   */
  question?: string;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdDelete200ResponseConfigCommunityFaqInner
   */
  answer?: string;
}
/**
 *
 * @export
 * @interface SpacesSpaceIdDelete200ResponseConfigCommunityReviewsInner
 */
export interface SpacesSpaceIdDelete200ResponseConfigCommunityReviewsInner {
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdDelete200ResponseConfigCommunityReviewsInner
   */
  name?: string;
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdDelete200ResponseConfigCommunityReviewsInner
   */
  avatarFileId?: number;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdDelete200ResponseConfigCommunityReviewsInner
   */
  text?: string;
  /**
   *
   * @type {AvatarsAvatarIdReplacePost200ResponseAvatar}
   * @memberof SpacesSpaceIdDelete200ResponseConfigCommunityReviewsInner
   */
  avatar?: AvatarsAvatarIdReplacePost200ResponseAvatar | null;
}
/**
 *
 * @export
 * @interface SpacesSpaceIdDelete200ResponseConfigCommunityVideo
 */
export interface SpacesSpaceIdDelete200ResponseConfigCommunityVideo {
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdDelete200ResponseConfigCommunityVideo
   */
  videoFileId?: number | null;
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdDelete200ResponseConfigCommunityVideo
   */
  coverFileId?: number | null;
  /**
   *
   * @type {AvatarsAvatarIdReplacePost200ResponseAvatar}
   * @memberof SpacesSpaceIdDelete200ResponseConfigCommunityVideo
   */
  file?: AvatarsAvatarIdReplacePost200ResponseAvatar | null;
  /**
   *
   * @type {AvatarsAvatarIdReplacePost200ResponseAvatar}
   * @memberof SpacesSpaceIdDelete200ResponseConfigCommunityVideo
   */
  cover?: AvatarsAvatarIdReplacePost200ResponseAvatar | null;
}
/**
 *
 * @export
 * @interface SpacesSpaceIdPriceGet200Response
 */
export interface SpacesSpaceIdPriceGet200Response {
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdPriceGet200Response
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdPriceGet200Response
   */
  price?: number;
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdPriceGet200Response
   */
  creatorPrice?: number;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdPriceGet200Response
   */
  payment_type?: SpacesSpaceIdPriceGet200ResponsePaymentTypeEnum;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdPriceGet200Response
   */
  priceModel?: SpacesSpaceIdPriceGet200ResponsePriceModelEnum;
  /**
   *
   * @type {object}
   * @memberof SpacesSpaceIdPriceGet200Response
   */
  priceConfig?: object | null;
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdPriceGet200Response
   */
  trialPrice?: number;
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdPriceGet200Response
   */
  trialCreatorPrice?: number;
  /**
   *
   * @type {SpacesSpaceIdPriceGet200ResponseMarketingPriceConfig}
   * @memberof SpacesSpaceIdPriceGet200Response
   */
  marketingPriceConfig?: SpacesSpaceIdPriceGet200ResponseMarketingPriceConfig;
}

export const SpacesSpaceIdPriceGet200ResponsePaymentTypeEnum = {
  FixedPrice: 'fixed_price',
  FixedPriceSubscribersOnly: 'fixed_price_subscribers_only',
  SubscribersOnly: 'subscribers_only',
  Free: 'free',
  MonthlyPrice: 'monthly_price',
} as const;

export type SpacesSpaceIdPriceGet200ResponsePaymentTypeEnum =
  typeof SpacesSpaceIdPriceGet200ResponsePaymentTypeEnum[keyof typeof SpacesSpaceIdPriceGet200ResponsePaymentTypeEnum];
export const SpacesSpaceIdPriceGet200ResponsePriceModelEnum = {
  Fixed: 'fixed',
  Auction: 'auction',
} as const;

export type SpacesSpaceIdPriceGet200ResponsePriceModelEnum =
  typeof SpacesSpaceIdPriceGet200ResponsePriceModelEnum[keyof typeof SpacesSpaceIdPriceGet200ResponsePriceModelEnum];

/**
 *
 * @export
 * @interface SpacesSpaceIdPriceGet200Response1
 */
export interface SpacesSpaceIdPriceGet200Response1 {
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdPriceGet200Response1
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdPriceGet200Response1
   */
  price?: number;
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdPriceGet200Response1
   */
  creatorPrice?: number;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdPriceGet200Response1
   */
  priceModel?: SpacesSpaceIdPriceGet200Response1PriceModelEnum;
  /**
   *
   * @type {object}
   * @memberof SpacesSpaceIdPriceGet200Response1
   */
  priceConfig?: object | null;
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdPriceGet200Response1
   */
  trialPrice?: number;
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdPriceGet200Response1
   */
  trialCreatorPrice?: number;
  /**
   *
   * @type {SpacesSpaceIdPriceGet200ResponseMarketingPriceConfig}
   * @memberof SpacesSpaceIdPriceGet200Response1
   */
  marketingPriceConfig?: SpacesSpaceIdPriceGet200ResponseMarketingPriceConfig;
}

export const SpacesSpaceIdPriceGet200Response1PriceModelEnum = {
  Fixed: 'fixed',
  Auction: 'auction',
} as const;

export type SpacesSpaceIdPriceGet200Response1PriceModelEnum =
  typeof SpacesSpaceIdPriceGet200Response1PriceModelEnum[keyof typeof SpacesSpaceIdPriceGet200Response1PriceModelEnum];

/**
 *
 * @export
 * @interface SpacesSpaceIdPriceGet200ResponseMarketingPriceConfig
 */
export interface SpacesSpaceIdPriceGet200ResponseMarketingPriceConfig {
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdPriceGet200ResponseMarketingPriceConfig
   */
  previousPrice?: number | null;
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdPriceGet200ResponseMarketingPriceConfig
   */
  iosPrice?: number;
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdPriceGet200ResponseMarketingPriceConfig
   */
  iosTrialPrice?: number;
}
/**
 *
 * @export
 * @interface SpacesSpaceIdPriceGetRequest
 */
export interface SpacesSpaceIdPriceGetRequest {
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdPriceGetRequest
   */
  price?: number | null;
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdPriceGetRequest
   */
  creatorPrice?: number | null;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdPriceGetRequest
   */
  priceModel?: SpacesSpaceIdPriceGetRequestPriceModelEnum;
  /**
   *
   * @type {object}
   * @memberof SpacesSpaceIdPriceGetRequest
   */
  priceConfig?: object | null;
  /**
   *
   * @type {object}
   * @memberof SpacesSpaceIdPriceGetRequest
   */
  marketingPriceConfig?: object | null;
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdPriceGetRequest
   */
  trialPrice?: number | null;
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdPriceGetRequest
   */
  trialCreatorPrice?: number | null;
}

export const SpacesSpaceIdPriceGetRequestPriceModelEnum = {
  Fixed: 'fixed',
  Auction: 'auction',
} as const;

export type SpacesSpaceIdPriceGetRequestPriceModelEnum =
  typeof SpacesSpaceIdPriceGetRequestPriceModelEnum[keyof typeof SpacesSpaceIdPriceGetRequestPriceModelEnum];

/**
 *
 * @export
 * @interface TagsTagIdDelete200Response
 */
export interface TagsTagIdDelete200Response {
  /**
   *
   * @type {number}
   * @memberof TagsTagIdDelete200Response
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof TagsTagIdDelete200Response
   */
  createdAt?: number;
  /**
   *
   * @type {number}
   * @memberof TagsTagIdDelete200Response
   */
  updatedAt?: number | null;
  /**
   *
   * @type {number}
   * @memberof TagsTagIdDelete200Response
   */
  deletedAt?: number | null;
  /**
   *
   * @type {string}
   * @memberof TagsTagIdDelete200Response
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof TagsTagIdDelete200Response
   */
  color?: string;
}
/**
 *
 * @export
 * @interface TagsTagIdDeleteRequest
 */
export interface TagsTagIdDeleteRequest {
  /**
   *
   * @type {string}
   * @memberof TagsTagIdDeleteRequest
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof TagsTagIdDeleteRequest
   */
  color?: string;
}
/**
 *
 * @export
 * @interface TagsTagIdSpacesSpaceIdDelete200Response
 */
export interface TagsTagIdSpacesSpaceIdDelete200Response {
  /**
   *
   * @type {number}
   * @memberof TagsTagIdSpacesSpaceIdDelete200Response
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof TagsTagIdSpacesSpaceIdDelete200Response
   */
  createdAt?: number;
  /**
   *
   * @type {number}
   * @memberof TagsTagIdSpacesSpaceIdDelete200Response
   */
  deletedAt?: number | null;
  /**
   *
   * @type {number}
   * @memberof TagsTagIdSpacesSpaceIdDelete200Response
   */
  spaceId?: number;
  /**
   *
   * @type {number}
   * @memberof TagsTagIdSpacesSpaceIdDelete200Response
   */
  tagId?: number;
  /**
   *
   * @type {number}
   * @memberof TagsTagIdSpacesSpaceIdDelete200Response
   */
  weight?: number;
}
/**
 *
 * @export
 * @interface TagsTagIdSpacesSpaceIdDeleteRequest
 */
export interface TagsTagIdSpacesSpaceIdDeleteRequest {
  /**
   *
   * @type {number}
   * @memberof TagsTagIdSpacesSpaceIdDeleteRequest
   */
  weight?: number;
}
/**
 *
 * @export
 * @interface UndoFreeBuyPostRequest
 */
export interface UndoFreeBuyPostRequest {
  /**
   *
   * @type {number}
   * @memberof UndoFreeBuyPostRequest
   */
  spaceId?: number;
  /**
   *
   * @type {number}
   * @memberof UndoFreeBuyPostRequest
   */
  customerId?: number;
  /**
   *
   * @type {boolean}
   * @memberof UndoFreeBuyPostRequest
   */
  force?: boolean;
}
/**
 *
 * @export
 * @interface UsersGet200ResponseInner
 */
export interface UsersGet200ResponseInner {
  /**
   *
   * @type {number}
   * @memberof UsersGet200ResponseInner
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof UsersGet200ResponseInner
   */
  created_at?: number;
  /**
   *
   * @type {string}
   * @memberof UsersGet200ResponseInner
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof UsersGet200ResponseInner
   */
  name?: string;
  /**
   *
   * @type {AvatarsAvatarIdReplacePost200ResponseAvatar}
   * @memberof UsersGet200ResponseInner
   */
  avatar?: AvatarsAvatarIdReplacePost200ResponseAvatar | null;
  /**
   *
   * @type {string}
   * @memberof UsersGet200ResponseInner
   */
  slug?: string;
  /**
   *
   * @type {number}
   * @memberof UsersGet200ResponseInner
   */
  spent?: number;
  /**
   *
   * @type {number}
   * @memberof UsersGet200ResponseInner
   */
  purchasedCount?: number;
  /**
   *
   * @type {number}
   * @memberof UsersGet200ResponseInner
   */
  trialsCount?: number;
  /**
   *
   * @type {number}
   * @memberof UsersGet200ResponseInner
   */
  addedToHomeCount?: number;
}
/**
 *
 * @export
 * @interface UsersUserIdSendPlatformMessagePostRequest
 */
export interface UsersUserIdSendPlatformMessagePostRequest {
  /**
   *
   * @type {object}
   * @memberof UsersUserIdSendPlatformMessagePostRequest
   */
  message?: object;
  /**
   *
   * @type {boolean}
   * @memberof UsersUserIdSendPlatformMessagePostRequest
   */
  encode?: boolean;
}

/**
 * AvatarsApi - axios parameter creator
 * @export
 */
export const AvatarsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} avatarId
     * @param {AvatarsAvatarIdReplacePostRequest} [avatarsAvatarIdReplacePostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    avatarsAvatarIdReplacePost: async (
      avatarId: number,
      avatarsAvatarIdReplacePostRequest?: AvatarsAvatarIdReplacePostRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'avatarId' is not null or undefined
      assertParamExists('avatarsAvatarIdReplacePost', 'avatarId', avatarId);
      const localVarPath = `/avatars/{avatarId}/replace`.replace(
        `{${'avatarId'}}`,
        encodeURIComponent(String(avatarId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        avatarsAvatarIdReplacePostRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AvatarsApi - functional programming interface
 * @export
 */
export const AvatarsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = AvatarsApiAxiosParamCreator(configuration);
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} avatarId
     * @param {AvatarsAvatarIdReplacePostRequest} [avatarsAvatarIdReplacePostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async avatarsAvatarIdReplacePost(
      avatarId: number,
      avatarsAvatarIdReplacePostRequest?: AvatarsAvatarIdReplacePostRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AvatarsAvatarIdReplacePost200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.avatarsAvatarIdReplacePost(
        avatarId,
        avatarsAvatarIdReplacePostRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * AvatarsApi - factory interface
 * @export
 */
export const AvatarsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = AvatarsApiFp(configuration);
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} avatarId
     * @param {AvatarsAvatarIdReplacePostRequest} [avatarsAvatarIdReplacePostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    avatarsAvatarIdReplacePost(
      avatarId: number,
      avatarsAvatarIdReplacePostRequest?: AvatarsAvatarIdReplacePostRequest,
      options?: any,
    ): AxiosPromise<AvatarsAvatarIdReplacePost200Response> {
      return localVarFp
        .avatarsAvatarIdReplacePost(avatarId, avatarsAvatarIdReplacePostRequest, options)
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * AvatarsApi - object-oriented interface
 * @export
 * @class AvatarsApi
 * @extends {BaseAPI}
 */
export class AvatarsApi extends BaseAPI {
  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {number} avatarId
   * @param {AvatarsAvatarIdReplacePostRequest} [avatarsAvatarIdReplacePostRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AvatarsApi
   */
  public avatarsAvatarIdReplacePost(
    avatarId: number,
    avatarsAvatarIdReplacePostRequest?: AvatarsAvatarIdReplacePostRequest,
    options?: AxiosRequestConfig,
  ) {
    return AvatarsApiFp(this.configuration)
      .avatarsAvatarIdReplacePost(avatarId, avatarsAvatarIdReplacePostRequest, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * BuyForFreeApi - axios parameter creator
 * @export
 */
export const BuyForFreeApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {BuyForFreePostRequest} [buyForFreePostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    buyForFreePost: async (
      buyForFreePostRequest?: BuyForFreePostRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/buyForFree`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(buyForFreePostRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * BuyForFreeApi - functional programming interface
 * @export
 */
export const BuyForFreeApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = BuyForFreeApiAxiosParamCreator(configuration);
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {BuyForFreePostRequest} [buyForFreePostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async buyForFreePost(
      buyForFreePostRequest?: BuyForFreePostRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.buyForFreePost(buyForFreePostRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * BuyForFreeApi - factory interface
 * @export
 */
export const BuyForFreeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = BuyForFreeApiFp(configuration);
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {BuyForFreePostRequest} [buyForFreePostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    buyForFreePost(buyForFreePostRequest?: BuyForFreePostRequest, options?: any): AxiosPromise<object> {
      return localVarFp.buyForFreePost(buyForFreePostRequest, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * BuyForFreeApi - object-oriented interface
 * @export
 * @class BuyForFreeApi
 * @extends {BaseAPI}
 */
export class BuyForFreeApi extends BaseAPI {
  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {BuyForFreePostRequest} [buyForFreePostRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BuyForFreeApi
   */
  public buyForFreePost(buyForFreePostRequest?: BuyForFreePostRequest, options?: AxiosRequestConfig) {
    return BuyForFreeApiFp(this.configuration)
      .buyForFreePost(buyForFreePostRequest, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * CategoriesApi - axios parameter creator
 * @export
 */
export const CategoriesApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} categoryId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    categoriesClubsCategoryIdDelete: async (
      categoryId: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'categoryId' is not null or undefined
      assertParamExists('categoriesClubsCategoryIdDelete', 'categoryId', categoryId);
      const localVarPath = `/categories/clubs/{categoryId}`.replace(
        `{${'categoryId'}}`,
        encodeURIComponent(String(categoryId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} categoryId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    categoriesClubsCategoryIdGet: async (
      categoryId: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'categoryId' is not null or undefined
      assertParamExists('categoriesClubsCategoryIdGet', 'categoryId', categoryId);
      const localVarPath = `/categories/clubs/{categoryId}`.replace(
        `{${'categoryId'}}`,
        encodeURIComponent(String(categoryId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} categoryId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    categoriesClubsCategoryIdManualGet: async (
      categoryId: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'categoryId' is not null or undefined
      assertParamExists('categoriesClubsCategoryIdManualGet', 'categoryId', categoryId);
      const localVarPath = `/categories/clubs/{categoryId}/manual`.replace(
        `{${'categoryId'}}`,
        encodeURIComponent(String(categoryId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} categoryId
     * @param {CategoriesClubsCategoryIdManualGetRequest} [categoriesClubsCategoryIdManualGetRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    categoriesClubsCategoryIdManualPatch: async (
      categoryId: number,
      categoriesClubsCategoryIdManualGetRequest?: CategoriesClubsCategoryIdManualGetRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'categoryId' is not null or undefined
      assertParamExists('categoriesClubsCategoryIdManualPatch', 'categoryId', categoryId);
      const localVarPath = `/categories/clubs/{categoryId}/manual`.replace(
        `{${'categoryId'}}`,
        encodeURIComponent(String(categoryId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        categoriesClubsCategoryIdManualGetRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} categoryId
     * @param {CategoriesClubsCategoryIdDeleteRequest} [categoriesClubsCategoryIdDeleteRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    categoriesClubsCategoryIdPatch: async (
      categoryId: number,
      categoriesClubsCategoryIdDeleteRequest?: CategoriesClubsCategoryIdDeleteRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'categoryId' is not null or undefined
      assertParamExists('categoriesClubsCategoryIdPatch', 'categoryId', categoryId);
      const localVarPath = `/categories/clubs/{categoryId}`.replace(
        `{${'categoryId'}}`,
        encodeURIComponent(String(categoryId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        categoriesClubsCategoryIdDeleteRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    categoriesClubsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/categories/clubs`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {CategoriesClubsGetRequest} [categoriesClubsGetRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    categoriesClubsPost: async (
      categoriesClubsGetRequest?: CategoriesClubsGetRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/categories/clubs`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        categoriesClubsGetRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} categoryId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    categoriesSpacesCategoryIdDelete: async (
      categoryId: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'categoryId' is not null or undefined
      assertParamExists('categoriesSpacesCategoryIdDelete', 'categoryId', categoryId);
      const localVarPath = `/categories/spaces/{categoryId}`.replace(
        `{${'categoryId'}}`,
        encodeURIComponent(String(categoryId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} categoryId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    categoriesSpacesCategoryIdGet: async (
      categoryId: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'categoryId' is not null or undefined
      assertParamExists('categoriesSpacesCategoryIdGet', 'categoryId', categoryId);
      const localVarPath = `/categories/spaces/{categoryId}`.replace(
        `{${'categoryId'}}`,
        encodeURIComponent(String(categoryId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} categoryId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    categoriesSpacesCategoryIdManualGet: async (
      categoryId: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'categoryId' is not null or undefined
      assertParamExists('categoriesSpacesCategoryIdManualGet', 'categoryId', categoryId);
      const localVarPath = `/categories/spaces/{categoryId}/manual`.replace(
        `{${'categoryId'}}`,
        encodeURIComponent(String(categoryId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} categoryId
     * @param {CategoriesSpacesCategoryIdManualGetRequest} [categoriesSpacesCategoryIdManualGetRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    categoriesSpacesCategoryIdManualPatch: async (
      categoryId: number,
      categoriesSpacesCategoryIdManualGetRequest?: CategoriesSpacesCategoryIdManualGetRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'categoryId' is not null or undefined
      assertParamExists('categoriesSpacesCategoryIdManualPatch', 'categoryId', categoryId);
      const localVarPath = `/categories/spaces/{categoryId}/manual`.replace(
        `{${'categoryId'}}`,
        encodeURIComponent(String(categoryId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        categoriesSpacesCategoryIdManualGetRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} categoryId
     * @param {CategoriesClubsCategoryIdDeleteRequest} [categoriesClubsCategoryIdDeleteRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    categoriesSpacesCategoryIdPatch: async (
      categoryId: number,
      categoriesClubsCategoryIdDeleteRequest?: CategoriesClubsCategoryIdDeleteRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'categoryId' is not null or undefined
      assertParamExists('categoriesSpacesCategoryIdPatch', 'categoryId', categoryId);
      const localVarPath = `/categories/spaces/{categoryId}`.replace(
        `{${'categoryId'}}`,
        encodeURIComponent(String(categoryId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        categoriesClubsCategoryIdDeleteRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    categoriesSpacesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/categories/spaces`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {CategoriesSpacesGetRequest} [categoriesSpacesGetRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    categoriesSpacesPost: async (
      categoriesSpacesGetRequest?: CategoriesSpacesGetRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/categories/spaces`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        categoriesSpacesGetRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * CategoriesApi - functional programming interface
 * @export
 */
export const CategoriesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = CategoriesApiAxiosParamCreator(configuration);
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} categoryId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async categoriesClubsCategoryIdDelete(
      categoryId: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.categoriesClubsCategoryIdDelete(categoryId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} categoryId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async categoriesClubsCategoryIdGet(
      categoryId: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CategoriesClubsCategoryIdDelete200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.categoriesClubsCategoryIdGet(categoryId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} categoryId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async categoriesClubsCategoryIdManualGet(
      categoryId: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<CategoriesClubsCategoryIdManualGet200ResponseInner>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.categoriesClubsCategoryIdManualGet(categoryId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} categoryId
     * @param {CategoriesClubsCategoryIdManualGetRequest} [categoriesClubsCategoryIdManualGetRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async categoriesClubsCategoryIdManualPatch(
      categoryId: number,
      categoriesClubsCategoryIdManualGetRequest?: CategoriesClubsCategoryIdManualGetRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CategoriesClubsCategoryIdManualGet200Response>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.categoriesClubsCategoryIdManualPatch(
        categoryId,
        categoriesClubsCategoryIdManualGetRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} categoryId
     * @param {CategoriesClubsCategoryIdDeleteRequest} [categoriesClubsCategoryIdDeleteRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async categoriesClubsCategoryIdPatch(
      categoryId: number,
      categoriesClubsCategoryIdDeleteRequest?: CategoriesClubsCategoryIdDeleteRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CategoriesClubsCategoryIdDelete200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.categoriesClubsCategoryIdPatch(
        categoryId,
        categoriesClubsCategoryIdDeleteRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async categoriesClubsGet(
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CategoriesClubsCategoryIdDelete200Response>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.categoriesClubsGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {CategoriesClubsGetRequest} [categoriesClubsGetRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async categoriesClubsPost(
      categoriesClubsGetRequest?: CategoriesClubsGetRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CategoriesClubsCategoryIdDelete200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.categoriesClubsPost(categoriesClubsGetRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} categoryId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async categoriesSpacesCategoryIdDelete(
      categoryId: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.categoriesSpacesCategoryIdDelete(categoryId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} categoryId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async categoriesSpacesCategoryIdGet(
      categoryId: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CategoriesSpacesCategoryIdDelete200Response>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.categoriesSpacesCategoryIdGet(categoryId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} categoryId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async categoriesSpacesCategoryIdManualGet(
      categoryId: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<CategoriesSpacesCategoryIdManualGet200ResponseInner>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.categoriesSpacesCategoryIdManualGet(
        categoryId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} categoryId
     * @param {CategoriesSpacesCategoryIdManualGetRequest} [categoriesSpacesCategoryIdManualGetRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async categoriesSpacesCategoryIdManualPatch(
      categoryId: number,
      categoriesSpacesCategoryIdManualGetRequest?: CategoriesSpacesCategoryIdManualGetRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CategoriesClubsCategoryIdManualGet200Response>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.categoriesSpacesCategoryIdManualPatch(
        categoryId,
        categoriesSpacesCategoryIdManualGetRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} categoryId
     * @param {CategoriesClubsCategoryIdDeleteRequest} [categoriesClubsCategoryIdDeleteRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async categoriesSpacesCategoryIdPatch(
      categoryId: number,
      categoriesClubsCategoryIdDeleteRequest?: CategoriesClubsCategoryIdDeleteRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CategoriesSpacesCategoryIdDelete200Response>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.categoriesSpacesCategoryIdPatch(
        categoryId,
        categoriesClubsCategoryIdDeleteRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async categoriesSpacesGet(
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CategoriesSpacesCategoryIdDelete200Response>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.categoriesSpacesGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {CategoriesSpacesGetRequest} [categoriesSpacesGetRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async categoriesSpacesPost(
      categoriesSpacesGetRequest?: CategoriesSpacesGetRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CategoriesSpacesCategoryIdDelete200Response>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.categoriesSpacesPost(
        categoriesSpacesGetRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * CategoriesApi - factory interface
 * @export
 */
export const CategoriesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = CategoriesApiFp(configuration);
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} categoryId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    categoriesClubsCategoryIdDelete(categoryId: number, options?: any): AxiosPromise<object> {
      return localVarFp.categoriesClubsCategoryIdDelete(categoryId, options).then(request => request(axios, basePath));
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} categoryId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    categoriesClubsCategoryIdGet(
      categoryId: number,
      options?: any,
    ): AxiosPromise<CategoriesClubsCategoryIdDelete200Response> {
      return localVarFp.categoriesClubsCategoryIdGet(categoryId, options).then(request => request(axios, basePath));
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} categoryId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    categoriesClubsCategoryIdManualGet(
      categoryId: number,
      options?: any,
    ): AxiosPromise<Array<CategoriesClubsCategoryIdManualGet200ResponseInner>> {
      return localVarFp
        .categoriesClubsCategoryIdManualGet(categoryId, options)
        .then(request => request(axios, basePath));
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} categoryId
     * @param {CategoriesClubsCategoryIdManualGetRequest} [categoriesClubsCategoryIdManualGetRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    categoriesClubsCategoryIdManualPatch(
      categoryId: number,
      categoriesClubsCategoryIdManualGetRequest?: CategoriesClubsCategoryIdManualGetRequest,
      options?: any,
    ): AxiosPromise<CategoriesClubsCategoryIdManualGet200Response> {
      return localVarFp
        .categoriesClubsCategoryIdManualPatch(categoryId, categoriesClubsCategoryIdManualGetRequest, options)
        .then(request => request(axios, basePath));
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} categoryId
     * @param {CategoriesClubsCategoryIdDeleteRequest} [categoriesClubsCategoryIdDeleteRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    categoriesClubsCategoryIdPatch(
      categoryId: number,
      categoriesClubsCategoryIdDeleteRequest?: CategoriesClubsCategoryIdDeleteRequest,
      options?: any,
    ): AxiosPromise<CategoriesClubsCategoryIdDelete200Response> {
      return localVarFp
        .categoriesClubsCategoryIdPatch(categoryId, categoriesClubsCategoryIdDeleteRequest, options)
        .then(request => request(axios, basePath));
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    categoriesClubsGet(options?: any): AxiosPromise<Array<CategoriesClubsCategoryIdDelete200Response>> {
      return localVarFp.categoriesClubsGet(options).then(request => request(axios, basePath));
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {CategoriesClubsGetRequest} [categoriesClubsGetRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    categoriesClubsPost(
      categoriesClubsGetRequest?: CategoriesClubsGetRequest,
      options?: any,
    ): AxiosPromise<CategoriesClubsCategoryIdDelete200Response> {
      return localVarFp
        .categoriesClubsPost(categoriesClubsGetRequest, options)
        .then(request => request(axios, basePath));
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} categoryId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    categoriesSpacesCategoryIdDelete(categoryId: number, options?: any): AxiosPromise<object> {
      return localVarFp.categoriesSpacesCategoryIdDelete(categoryId, options).then(request => request(axios, basePath));
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} categoryId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    categoriesSpacesCategoryIdGet(
      categoryId: number,
      options?: any,
    ): AxiosPromise<CategoriesSpacesCategoryIdDelete200Response> {
      return localVarFp.categoriesSpacesCategoryIdGet(categoryId, options).then(request => request(axios, basePath));
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} categoryId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    categoriesSpacesCategoryIdManualGet(
      categoryId: number,
      options?: any,
    ): AxiosPromise<Array<CategoriesSpacesCategoryIdManualGet200ResponseInner>> {
      return localVarFp
        .categoriesSpacesCategoryIdManualGet(categoryId, options)
        .then(request => request(axios, basePath));
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} categoryId
     * @param {CategoriesSpacesCategoryIdManualGetRequest} [categoriesSpacesCategoryIdManualGetRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    categoriesSpacesCategoryIdManualPatch(
      categoryId: number,
      categoriesSpacesCategoryIdManualGetRequest?: CategoriesSpacesCategoryIdManualGetRequest,
      options?: any,
    ): AxiosPromise<CategoriesClubsCategoryIdManualGet200Response> {
      return localVarFp
        .categoriesSpacesCategoryIdManualPatch(categoryId, categoriesSpacesCategoryIdManualGetRequest, options)
        .then(request => request(axios, basePath));
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} categoryId
     * @param {CategoriesClubsCategoryIdDeleteRequest} [categoriesClubsCategoryIdDeleteRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    categoriesSpacesCategoryIdPatch(
      categoryId: number,
      categoriesClubsCategoryIdDeleteRequest?: CategoriesClubsCategoryIdDeleteRequest,
      options?: any,
    ): AxiosPromise<CategoriesSpacesCategoryIdDelete200Response> {
      return localVarFp
        .categoriesSpacesCategoryIdPatch(categoryId, categoriesClubsCategoryIdDeleteRequest, options)
        .then(request => request(axios, basePath));
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    categoriesSpacesGet(options?: any): AxiosPromise<Array<CategoriesSpacesCategoryIdDelete200Response>> {
      return localVarFp.categoriesSpacesGet(options).then(request => request(axios, basePath));
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {CategoriesSpacesGetRequest} [categoriesSpacesGetRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    categoriesSpacesPost(
      categoriesSpacesGetRequest?: CategoriesSpacesGetRequest,
      options?: any,
    ): AxiosPromise<CategoriesSpacesCategoryIdDelete200Response> {
      return localVarFp
        .categoriesSpacesPost(categoriesSpacesGetRequest, options)
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * CategoriesApi - object-oriented interface
 * @export
 * @class CategoriesApi
 * @extends {BaseAPI}
 */
export class CategoriesApi extends BaseAPI {
  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {number} categoryId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CategoriesApi
   */
  public categoriesClubsCategoryIdDelete(categoryId: number, options?: AxiosRequestConfig) {
    return CategoriesApiFp(this.configuration)
      .categoriesClubsCategoryIdDelete(categoryId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {number} categoryId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CategoriesApi
   */
  public categoriesClubsCategoryIdGet(categoryId: number, options?: AxiosRequestConfig) {
    return CategoriesApiFp(this.configuration)
      .categoriesClubsCategoryIdGet(categoryId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {number} categoryId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CategoriesApi
   */
  public categoriesClubsCategoryIdManualGet(categoryId: number, options?: AxiosRequestConfig) {
    return CategoriesApiFp(this.configuration)
      .categoriesClubsCategoryIdManualGet(categoryId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {number} categoryId
   * @param {CategoriesClubsCategoryIdManualGetRequest} [categoriesClubsCategoryIdManualGetRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CategoriesApi
   */
  public categoriesClubsCategoryIdManualPatch(
    categoryId: number,
    categoriesClubsCategoryIdManualGetRequest?: CategoriesClubsCategoryIdManualGetRequest,
    options?: AxiosRequestConfig,
  ) {
    return CategoriesApiFp(this.configuration)
      .categoriesClubsCategoryIdManualPatch(categoryId, categoriesClubsCategoryIdManualGetRequest, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {number} categoryId
   * @param {CategoriesClubsCategoryIdDeleteRequest} [categoriesClubsCategoryIdDeleteRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CategoriesApi
   */
  public categoriesClubsCategoryIdPatch(
    categoryId: number,
    categoriesClubsCategoryIdDeleteRequest?: CategoriesClubsCategoryIdDeleteRequest,
    options?: AxiosRequestConfig,
  ) {
    return CategoriesApiFp(this.configuration)
      .categoriesClubsCategoryIdPatch(categoryId, categoriesClubsCategoryIdDeleteRequest, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CategoriesApi
   */
  public categoriesClubsGet(options?: AxiosRequestConfig) {
    return CategoriesApiFp(this.configuration)
      .categoriesClubsGet(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {CategoriesClubsGetRequest} [categoriesClubsGetRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CategoriesApi
   */
  public categoriesClubsPost(categoriesClubsGetRequest?: CategoriesClubsGetRequest, options?: AxiosRequestConfig) {
    return CategoriesApiFp(this.configuration)
      .categoriesClubsPost(categoriesClubsGetRequest, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {number} categoryId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CategoriesApi
   */
  public categoriesSpacesCategoryIdDelete(categoryId: number, options?: AxiosRequestConfig) {
    return CategoriesApiFp(this.configuration)
      .categoriesSpacesCategoryIdDelete(categoryId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {number} categoryId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CategoriesApi
   */
  public categoriesSpacesCategoryIdGet(categoryId: number, options?: AxiosRequestConfig) {
    return CategoriesApiFp(this.configuration)
      .categoriesSpacesCategoryIdGet(categoryId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {number} categoryId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CategoriesApi
   */
  public categoriesSpacesCategoryIdManualGet(categoryId: number, options?: AxiosRequestConfig) {
    return CategoriesApiFp(this.configuration)
      .categoriesSpacesCategoryIdManualGet(categoryId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {number} categoryId
   * @param {CategoriesSpacesCategoryIdManualGetRequest} [categoriesSpacesCategoryIdManualGetRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CategoriesApi
   */
  public categoriesSpacesCategoryIdManualPatch(
    categoryId: number,
    categoriesSpacesCategoryIdManualGetRequest?: CategoriesSpacesCategoryIdManualGetRequest,
    options?: AxiosRequestConfig,
  ) {
    return CategoriesApiFp(this.configuration)
      .categoriesSpacesCategoryIdManualPatch(categoryId, categoriesSpacesCategoryIdManualGetRequest, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {number} categoryId
   * @param {CategoriesClubsCategoryIdDeleteRequest} [categoriesClubsCategoryIdDeleteRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CategoriesApi
   */
  public categoriesSpacesCategoryIdPatch(
    categoryId: number,
    categoriesClubsCategoryIdDeleteRequest?: CategoriesClubsCategoryIdDeleteRequest,
    options?: AxiosRequestConfig,
  ) {
    return CategoriesApiFp(this.configuration)
      .categoriesSpacesCategoryIdPatch(categoryId, categoriesClubsCategoryIdDeleteRequest, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CategoriesApi
   */
  public categoriesSpacesGet(options?: AxiosRequestConfig) {
    return CategoriesApiFp(this.configuration)
      .categoriesSpacesGet(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {CategoriesSpacesGetRequest} [categoriesSpacesGetRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CategoriesApi
   */
  public categoriesSpacesPost(categoriesSpacesGetRequest?: CategoriesSpacesGetRequest, options?: AxiosRequestConfig) {
    return CategoriesApiFp(this.configuration)
      .categoriesSpacesPost(categoriesSpacesGetRequest, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * Class100msApi - axios parameter creator
 * @export
 */
export const Class100msApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * <br /><br /> <b>Authentication:</b> not required
     * @summary
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _100msTokenGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/100ms/token`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * Class100msApi - functional programming interface
 * @export
 */
export const Class100msApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = Class100msApiAxiosParamCreator(configuration);
  return {
    /**
     * <br /><br /> <b>Authentication:</b> not required
     * @summary
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async _100msTokenGet(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator._100msTokenGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * Class100msApi - factory interface
 * @export
 */
export const Class100msApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = Class100msApiFp(configuration);
  return {
    /**
     * <br /><br /> <b>Authentication:</b> not required
     * @summary
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _100msTokenGet(options?: any): AxiosPromise<object> {
      return localVarFp._100msTokenGet(options).then(request => request(axios, basePath));
    },
  };
};

/**
 * Class100msApi - object-oriented interface
 * @export
 * @class Class100msApi
 * @extends {BaseAPI}
 */
export class Class100msApi extends BaseAPI {
  /**
   * <br /><br /> <b>Authentication:</b> not required
   * @summary
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof Class100msApi
   */
  public _100msTokenGet(options?: AxiosRequestConfig) {
    return Class100msApiFp(this.configuration)
      ._100msTokenGet(options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * ClubsApi - axios parameter creator
 * @export
 */
export const ClubsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} clubId
     * @param {ClubsClubIdPostRequest} [clubsClubIdPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clubsClubIdPost: async (
      clubId: number,
      clubsClubIdPostRequest?: ClubsClubIdPostRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'clubId' is not null or undefined
      assertParamExists('clubsClubIdPost', 'clubId', clubId);
      const localVarPath = `/clubs/{clubId}`.replace(`{${'clubId'}}`, encodeURIComponent(String(clubId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        clubsClubIdPostRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ClubsApi - functional programming interface
 * @export
 */
export const ClubsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ClubsApiAxiosParamCreator(configuration);
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} clubId
     * @param {ClubsClubIdPostRequest} [clubsClubIdPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async clubsClubIdPost(
      clubId: number,
      clubsClubIdPostRequest?: ClubsClubIdPostRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClubsClubIdPost200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.clubsClubIdPost(
        clubId,
        clubsClubIdPostRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * ClubsApi - factory interface
 * @export
 */
export const ClubsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = ClubsApiFp(configuration);
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} clubId
     * @param {ClubsClubIdPostRequest} [clubsClubIdPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clubsClubIdPost(
      clubId: number,
      clubsClubIdPostRequest?: ClubsClubIdPostRequest,
      options?: any,
    ): AxiosPromise<ClubsClubIdPost200Response> {
      return localVarFp
        .clubsClubIdPost(clubId, clubsClubIdPostRequest, options)
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * ClubsApi - object-oriented interface
 * @export
 * @class ClubsApi
 * @extends {BaseAPI}
 */
export class ClubsApi extends BaseAPI {
  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {number} clubId
   * @param {ClubsClubIdPostRequest} [clubsClubIdPostRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ClubsApi
   */
  public clubsClubIdPost(
    clubId: number,
    clubsClubIdPostRequest?: ClubsClubIdPostRequest,
    options?: AxiosRequestConfig,
  ) {
    return ClubsApiFp(this.configuration)
      .clubsClubIdPost(clubId, clubsClubIdPostRequest, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * CreatorsApi - axios parameter creator
 * @export
 */
export const CreatorsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} creatorId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    creatorsCreatorIdChallengesGet: async (
      creatorId: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'creatorId' is not null or undefined
      assertParamExists('creatorsCreatorIdChallengesGet', 'creatorId', creatorId);
      const localVarPath = `/creators/{creatorId}/challenges`.replace(
        `{${'creatorId'}}`,
        encodeURIComponent(String(creatorId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} creatorId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    creatorsCreatorIdLinksGet: async (creatorId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'creatorId' is not null or undefined
      assertParamExists('creatorsCreatorIdLinksGet', 'creatorId', creatorId);
      const localVarPath = `/creators/{creatorId}/links`.replace(
        `{${'creatorId'}}`,
        encodeURIComponent(String(creatorId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} linkId
     * @param {number} creatorId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    creatorsCreatorIdLinksLinkIdDelete: async (
      linkId: number,
      creatorId: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'linkId' is not null or undefined
      assertParamExists('creatorsCreatorIdLinksLinkIdDelete', 'linkId', linkId);
      // verify required parameter 'creatorId' is not null or undefined
      assertParamExists('creatorsCreatorIdLinksLinkIdDelete', 'creatorId', creatorId);
      const localVarPath = `/creators/{creatorId}/links/{linkId}`
        .replace(`{${'linkId'}}`, encodeURIComponent(String(linkId)))
        .replace(`{${'creatorId'}}`, encodeURIComponent(String(creatorId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} linkId
     * @param {number} creatorId
     * @param {CreatorsCreatorIdLinksLinkIdDeleteRequest} [creatorsCreatorIdLinksLinkIdDeleteRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    creatorsCreatorIdLinksLinkIdPost: async (
      linkId: number,
      creatorId: number,
      creatorsCreatorIdLinksLinkIdDeleteRequest?: CreatorsCreatorIdLinksLinkIdDeleteRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'linkId' is not null or undefined
      assertParamExists('creatorsCreatorIdLinksLinkIdPost', 'linkId', linkId);
      // verify required parameter 'creatorId' is not null or undefined
      assertParamExists('creatorsCreatorIdLinksLinkIdPost', 'creatorId', creatorId);
      const localVarPath = `/creators/{creatorId}/links/{linkId}`
        .replace(`{${'linkId'}}`, encodeURIComponent(String(linkId)))
        .replace(`{${'creatorId'}}`, encodeURIComponent(String(creatorId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        creatorsCreatorIdLinksLinkIdDeleteRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} creatorId
     * @param {CreatorsCreatorIdLinksOrderPatchRequest} [creatorsCreatorIdLinksOrderPatchRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    creatorsCreatorIdLinksOrderPatch: async (
      creatorId: number,
      creatorsCreatorIdLinksOrderPatchRequest?: CreatorsCreatorIdLinksOrderPatchRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'creatorId' is not null or undefined
      assertParamExists('creatorsCreatorIdLinksOrderPatch', 'creatorId', creatorId);
      const localVarPath = `/creators/{creatorId}/links/order`.replace(
        `{${'creatorId'}}`,
        encodeURIComponent(String(creatorId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        creatorsCreatorIdLinksOrderPatchRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} creatorId
     * @param {CreatorsCreatorIdLinksLinkIdDeleteRequest} [creatorsCreatorIdLinksLinkIdDeleteRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    creatorsCreatorIdLinksPost: async (
      creatorId: number,
      creatorsCreatorIdLinksLinkIdDeleteRequest?: CreatorsCreatorIdLinksLinkIdDeleteRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'creatorId' is not null or undefined
      assertParamExists('creatorsCreatorIdLinksPost', 'creatorId', creatorId);
      const localVarPath = `/creators/{creatorId}/links`.replace(
        `{${'creatorId'}}`,
        encodeURIComponent(String(creatorId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        creatorsCreatorIdLinksLinkIdDeleteRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} creatorId
     * @param {CreatorsCreatorIdPatchRequest} [creatorsCreatorIdPatchRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    creatorsCreatorIdPatch: async (
      creatorId: number,
      creatorsCreatorIdPatchRequest?: CreatorsCreatorIdPatchRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'creatorId' is not null or undefined
      assertParamExists('creatorsCreatorIdPatch', 'creatorId', creatorId);
      const localVarPath = `/creators/{creatorId}`.replace(`{${'creatorId'}}`, encodeURIComponent(String(creatorId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        creatorsCreatorIdPatchRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    creatorsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/creators`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {CreatorsGetRequest} [creatorsGetRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    creatorsPost: async (
      creatorsGetRequest?: CreatorsGetRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/creators`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(creatorsGetRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * CreatorsApi - functional programming interface
 * @export
 */
export const CreatorsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = CreatorsApiAxiosParamCreator(configuration);
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} creatorId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async creatorsCreatorIdChallengesGet(
      creatorId: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CreatorsCreatorIdChallengesGet200ResponseInner>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.creatorsCreatorIdChallengesGet(creatorId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} creatorId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async creatorsCreatorIdLinksGet(
      creatorId: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CreatorsCreatorIdLinksLinkIdDelete200Response>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.creatorsCreatorIdLinksGet(creatorId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} linkId
     * @param {number} creatorId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async creatorsCreatorIdLinksLinkIdDelete(
      linkId: number,
      creatorId: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.creatorsCreatorIdLinksLinkIdDelete(
        linkId,
        creatorId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} linkId
     * @param {number} creatorId
     * @param {CreatorsCreatorIdLinksLinkIdDeleteRequest} [creatorsCreatorIdLinksLinkIdDeleteRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async creatorsCreatorIdLinksLinkIdPost(
      linkId: number,
      creatorId: number,
      creatorsCreatorIdLinksLinkIdDeleteRequest?: CreatorsCreatorIdLinksLinkIdDeleteRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreatorsCreatorIdLinksLinkIdDelete200Response>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.creatorsCreatorIdLinksLinkIdPost(
        linkId,
        creatorId,
        creatorsCreatorIdLinksLinkIdDeleteRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} creatorId
     * @param {CreatorsCreatorIdLinksOrderPatchRequest} [creatorsCreatorIdLinksOrderPatchRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async creatorsCreatorIdLinksOrderPatch(
      creatorId: number,
      creatorsCreatorIdLinksOrderPatchRequest?: CreatorsCreatorIdLinksOrderPatchRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.creatorsCreatorIdLinksOrderPatch(
        creatorId,
        creatorsCreatorIdLinksOrderPatchRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} creatorId
     * @param {CreatorsCreatorIdLinksLinkIdDeleteRequest} [creatorsCreatorIdLinksLinkIdDeleteRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async creatorsCreatorIdLinksPost(
      creatorId: number,
      creatorsCreatorIdLinksLinkIdDeleteRequest?: CreatorsCreatorIdLinksLinkIdDeleteRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreatorsCreatorIdLinksGet200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.creatorsCreatorIdLinksPost(
        creatorId,
        creatorsCreatorIdLinksLinkIdDeleteRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} creatorId
     * @param {CreatorsCreatorIdPatchRequest} [creatorsCreatorIdPatchRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async creatorsCreatorIdPatch(
      creatorId: number,
      creatorsCreatorIdPatchRequest?: CreatorsCreatorIdPatchRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreatorsCreatorIdPatch200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.creatorsCreatorIdPatch(
        creatorId,
        creatorsCreatorIdPatchRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async creatorsGet(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CreatorsGet200ResponseInner>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.creatorsGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {CreatorsGetRequest} [creatorsGetRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async creatorsPost(
      creatorsGetRequest?: CreatorsGetRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreatorsGet200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.creatorsPost(creatorsGetRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * CreatorsApi - factory interface
 * @export
 */
export const CreatorsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = CreatorsApiFp(configuration);
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} creatorId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    creatorsCreatorIdChallengesGet(
      creatorId: number,
      options?: any,
    ): AxiosPromise<Array<CreatorsCreatorIdChallengesGet200ResponseInner>> {
      return localVarFp.creatorsCreatorIdChallengesGet(creatorId, options).then(request => request(axios, basePath));
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} creatorId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    creatorsCreatorIdLinksGet(
      creatorId: number,
      options?: any,
    ): AxiosPromise<Array<CreatorsCreatorIdLinksLinkIdDelete200Response>> {
      return localVarFp.creatorsCreatorIdLinksGet(creatorId, options).then(request => request(axios, basePath));
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} linkId
     * @param {number} creatorId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    creatorsCreatorIdLinksLinkIdDelete(linkId: number, creatorId: number, options?: any): AxiosPromise<object> {
      return localVarFp
        .creatorsCreatorIdLinksLinkIdDelete(linkId, creatorId, options)
        .then(request => request(axios, basePath));
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} linkId
     * @param {number} creatorId
     * @param {CreatorsCreatorIdLinksLinkIdDeleteRequest} [creatorsCreatorIdLinksLinkIdDeleteRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    creatorsCreatorIdLinksLinkIdPost(
      linkId: number,
      creatorId: number,
      creatorsCreatorIdLinksLinkIdDeleteRequest?: CreatorsCreatorIdLinksLinkIdDeleteRequest,
      options?: any,
    ): AxiosPromise<CreatorsCreatorIdLinksLinkIdDelete200Response> {
      return localVarFp
        .creatorsCreatorIdLinksLinkIdPost(linkId, creatorId, creatorsCreatorIdLinksLinkIdDeleteRequest, options)
        .then(request => request(axios, basePath));
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} creatorId
     * @param {CreatorsCreatorIdLinksOrderPatchRequest} [creatorsCreatorIdLinksOrderPatchRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    creatorsCreatorIdLinksOrderPatch(
      creatorId: number,
      creatorsCreatorIdLinksOrderPatchRequest?: CreatorsCreatorIdLinksOrderPatchRequest,
      options?: any,
    ): AxiosPromise<object> {
      return localVarFp
        .creatorsCreatorIdLinksOrderPatch(creatorId, creatorsCreatorIdLinksOrderPatchRequest, options)
        .then(request => request(axios, basePath));
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} creatorId
     * @param {CreatorsCreatorIdLinksLinkIdDeleteRequest} [creatorsCreatorIdLinksLinkIdDeleteRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    creatorsCreatorIdLinksPost(
      creatorId: number,
      creatorsCreatorIdLinksLinkIdDeleteRequest?: CreatorsCreatorIdLinksLinkIdDeleteRequest,
      options?: any,
    ): AxiosPromise<CreatorsCreatorIdLinksGet200Response> {
      return localVarFp
        .creatorsCreatorIdLinksPost(creatorId, creatorsCreatorIdLinksLinkIdDeleteRequest, options)
        .then(request => request(axios, basePath));
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} creatorId
     * @param {CreatorsCreatorIdPatchRequest} [creatorsCreatorIdPatchRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    creatorsCreatorIdPatch(
      creatorId: number,
      creatorsCreatorIdPatchRequest?: CreatorsCreatorIdPatchRequest,
      options?: any,
    ): AxiosPromise<CreatorsCreatorIdPatch200Response> {
      return localVarFp
        .creatorsCreatorIdPatch(creatorId, creatorsCreatorIdPatchRequest, options)
        .then(request => request(axios, basePath));
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    creatorsGet(options?: any): AxiosPromise<Array<CreatorsGet200ResponseInner>> {
      return localVarFp.creatorsGet(options).then(request => request(axios, basePath));
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {CreatorsGetRequest} [creatorsGetRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    creatorsPost(creatorsGetRequest?: CreatorsGetRequest, options?: any): AxiosPromise<CreatorsGet200Response> {
      return localVarFp.creatorsPost(creatorsGetRequest, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * CreatorsApi - object-oriented interface
 * @export
 * @class CreatorsApi
 * @extends {BaseAPI}
 */
export class CreatorsApi extends BaseAPI {
  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {number} creatorId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CreatorsApi
   */
  public creatorsCreatorIdChallengesGet(creatorId: number, options?: AxiosRequestConfig) {
    return CreatorsApiFp(this.configuration)
      .creatorsCreatorIdChallengesGet(creatorId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {number} creatorId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CreatorsApi
   */
  public creatorsCreatorIdLinksGet(creatorId: number, options?: AxiosRequestConfig) {
    return CreatorsApiFp(this.configuration)
      .creatorsCreatorIdLinksGet(creatorId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {number} linkId
   * @param {number} creatorId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CreatorsApi
   */
  public creatorsCreatorIdLinksLinkIdDelete(linkId: number, creatorId: number, options?: AxiosRequestConfig) {
    return CreatorsApiFp(this.configuration)
      .creatorsCreatorIdLinksLinkIdDelete(linkId, creatorId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {number} linkId
   * @param {number} creatorId
   * @param {CreatorsCreatorIdLinksLinkIdDeleteRequest} [creatorsCreatorIdLinksLinkIdDeleteRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CreatorsApi
   */
  public creatorsCreatorIdLinksLinkIdPost(
    linkId: number,
    creatorId: number,
    creatorsCreatorIdLinksLinkIdDeleteRequest?: CreatorsCreatorIdLinksLinkIdDeleteRequest,
    options?: AxiosRequestConfig,
  ) {
    return CreatorsApiFp(this.configuration)
      .creatorsCreatorIdLinksLinkIdPost(linkId, creatorId, creatorsCreatorIdLinksLinkIdDeleteRequest, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {number} creatorId
   * @param {CreatorsCreatorIdLinksOrderPatchRequest} [creatorsCreatorIdLinksOrderPatchRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CreatorsApi
   */
  public creatorsCreatorIdLinksOrderPatch(
    creatorId: number,
    creatorsCreatorIdLinksOrderPatchRequest?: CreatorsCreatorIdLinksOrderPatchRequest,
    options?: AxiosRequestConfig,
  ) {
    return CreatorsApiFp(this.configuration)
      .creatorsCreatorIdLinksOrderPatch(creatorId, creatorsCreatorIdLinksOrderPatchRequest, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {number} creatorId
   * @param {CreatorsCreatorIdLinksLinkIdDeleteRequest} [creatorsCreatorIdLinksLinkIdDeleteRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CreatorsApi
   */
  public creatorsCreatorIdLinksPost(
    creatorId: number,
    creatorsCreatorIdLinksLinkIdDeleteRequest?: CreatorsCreatorIdLinksLinkIdDeleteRequest,
    options?: AxiosRequestConfig,
  ) {
    return CreatorsApiFp(this.configuration)
      .creatorsCreatorIdLinksPost(creatorId, creatorsCreatorIdLinksLinkIdDeleteRequest, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {number} creatorId
   * @param {CreatorsCreatorIdPatchRequest} [creatorsCreatorIdPatchRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CreatorsApi
   */
  public creatorsCreatorIdPatch(
    creatorId: number,
    creatorsCreatorIdPatchRequest?: CreatorsCreatorIdPatchRequest,
    options?: AxiosRequestConfig,
  ) {
    return CreatorsApiFp(this.configuration)
      .creatorsCreatorIdPatch(creatorId, creatorsCreatorIdPatchRequest, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CreatorsApi
   */
  public creatorsGet(options?: AxiosRequestConfig) {
    return CreatorsApiFp(this.configuration)
      .creatorsGet(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {CreatorsGetRequest} [creatorsGetRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CreatorsApi
   */
  public creatorsPost(creatorsGetRequest?: CreatorsGetRequest, options?: AxiosRequestConfig) {
    return CreatorsApiFp(this.configuration)
      .creatorsPost(creatorsGetRequest, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * DebugTokenApi - axios parameter creator
 * @export
 */
export const DebugTokenApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    debugTokenGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/debugToken`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DebugTokenApi - functional programming interface
 * @export
 */
export const DebugTokenApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = DebugTokenApiAxiosParamCreator(configuration);
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async debugTokenGet(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.debugTokenGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * DebugTokenApi - factory interface
 * @export
 */
export const DebugTokenApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = DebugTokenApiFp(configuration);
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    debugTokenGet(options?: any): AxiosPromise<object> {
      return localVarFp.debugTokenGet(options).then(request => request(axios, basePath));
    },
  };
};

/**
 * DebugTokenApi - object-oriented interface
 * @export
 * @class DebugTokenApi
 * @extends {BaseAPI}
 */
export class DebugTokenApi extends BaseAPI {
  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DebugTokenApi
   */
  public debugTokenGet(options?: AxiosRequestConfig) {
    return DebugTokenApiFp(this.configuration)
      .debugTokenGet(options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * FormsApi - axios parameter creator
 * @export
 */
export const FormsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    formsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/forms`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * FormsApi - functional programming interface
 * @export
 */
export const FormsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = FormsApiAxiosParamCreator(configuration);
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async formsGet(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.formsGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * FormsApi - factory interface
 * @export
 */
export const FormsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = FormsApiFp(configuration);
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    formsGet(options?: any): AxiosPromise<object> {
      return localVarFp.formsGet(options).then(request => request(axios, basePath));
    },
  };
};

/**
 * FormsApi - object-oriented interface
 * @export
 * @class FormsApi
 * @extends {BaseAPI}
 */
export class FormsApi extends BaseAPI {
  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FormsApi
   */
  public formsGet(options?: AxiosRequestConfig) {
    return FormsApiFp(this.configuration)
      .formsGet(options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * GoogeApi - axios parameter creator
 * @export
 */
export const GoogeApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * <br /><br /> <b>Authentication:</b> not required
     * @summary
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    googeDecodeTokenGet: async (token: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'token' is not null or undefined
      assertParamExists('googeDecodeTokenGet', 'token', token);
      const localVarPath = `/googe/decodeToken`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (token !== undefined) {
        localVarQueryParameter['token'] = token;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * GoogeApi - functional programming interface
 * @export
 */
export const GoogeApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = GoogeApiAxiosParamCreator(configuration);
  return {
    /**
     * <br /><br /> <b>Authentication:</b> not required
     * @summary
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async googeDecodeTokenGet(
      token: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.googeDecodeTokenGet(token, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * GoogeApi - factory interface
 * @export
 */
export const GoogeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = GoogeApiFp(configuration);
  return {
    /**
     * <br /><br /> <b>Authentication:</b> not required
     * @summary
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    googeDecodeTokenGet(token: string, options?: any): AxiosPromise<object> {
      return localVarFp.googeDecodeTokenGet(token, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * GoogeApi - object-oriented interface
 * @export
 * @class GoogeApi
 * @extends {BaseAPI}
 */
export class GoogeApi extends BaseAPI {
  /**
   * <br /><br /> <b>Authentication:</b> not required
   * @summary
   * @param {string} token
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GoogeApi
   */
  public googeDecodeTokenGet(token: string, options?: AxiosRequestConfig) {
    return GoogeApiFp(this.configuration)
      .googeDecodeTokenGet(token, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * ImpersonateApi - axios parameter creator
 * @export
 */
export const ImpersonateApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    impersonateGet: async (userId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists('impersonateGet', 'userId', userId);
      const localVarPath = `/impersonate`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (userId !== undefined) {
        localVarQueryParameter['userId'] = userId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ImpersonateApi - functional programming interface
 * @export
 */
export const ImpersonateApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ImpersonateApiAxiosParamCreator(configuration);
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async impersonateGet(
      userId: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.impersonateGet(userId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * ImpersonateApi - factory interface
 * @export
 */
export const ImpersonateApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = ImpersonateApiFp(configuration);
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    impersonateGet(userId: number, options?: any): AxiosPromise<object> {
      return localVarFp.impersonateGet(userId, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * ImpersonateApi - object-oriented interface
 * @export
 * @class ImpersonateApi
 * @extends {BaseAPI}
 */
export class ImpersonateApi extends BaseAPI {
  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {number} userId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ImpersonateApi
   */
  public impersonateGet(userId: number, options?: AxiosRequestConfig) {
    return ImpersonateApiFp(this.configuration)
      .impersonateGet(userId, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * LiveApi - axios parameter creator
 * @export
 */
export const LiveApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {LivePostRequest} [livePostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    livePost: async (livePostRequest?: LivePostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/live`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(livePostRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * LiveApi - functional programming interface
 * @export
 */
export const LiveApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = LiveApiAxiosParamCreator(configuration);
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {LivePostRequest} [livePostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async livePost(
      livePostRequest?: LivePostRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LivePost200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.livePost(livePostRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * LiveApi - factory interface
 * @export
 */
export const LiveApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = LiveApiFp(configuration);
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {LivePostRequest} [livePostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    livePost(livePostRequest?: LivePostRequest, options?: any): AxiosPromise<LivePost200Response> {
      return localVarFp.livePost(livePostRequest, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * LiveApi - object-oriented interface
 * @export
 * @class LiveApi
 * @extends {BaseAPI}
 */
export class LiveApi extends BaseAPI {
  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {LivePostRequest} [livePostRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LiveApi
   */
  public livePost(livePostRequest?: LivePostRequest, options?: AxiosRequestConfig) {
    return LiveApiFp(this.configuration)
      .livePost(livePostRequest, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * LivesApi - axios parameter creator
 * @export
 */
export const LivesApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} liveId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    livesLiveIdDelete: async (liveId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'liveId' is not null or undefined
      assertParamExists('livesLiveIdDelete', 'liveId', liveId);
      const localVarPath = `/lives/{liveId}`.replace(`{${'liveId'}}`, encodeURIComponent(String(liveId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} liveId
     * @param {LivesLiveIdDeleteRequest} [livesLiveIdDeleteRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    livesLiveIdPatch: async (
      liveId: number,
      livesLiveIdDeleteRequest?: LivesLiveIdDeleteRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'liveId' is not null or undefined
      assertParamExists('livesLiveIdPatch', 'liveId', liveId);
      const localVarPath = `/lives/{liveId}`.replace(`{${'liveId'}}`, encodeURIComponent(String(liveId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        livesLiveIdDeleteRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * LivesApi - functional programming interface
 * @export
 */
export const LivesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = LivesApiAxiosParamCreator(configuration);
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} liveId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async livesLiveIdDelete(
      liveId: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LivesLiveIdDelete200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.livesLiveIdDelete(liveId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} liveId
     * @param {LivesLiveIdDeleteRequest} [livesLiveIdDeleteRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async livesLiveIdPatch(
      liveId: number,
      livesLiveIdDeleteRequest?: LivesLiveIdDeleteRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LivesLiveIdDelete200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.livesLiveIdPatch(
        liveId,
        livesLiveIdDeleteRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * LivesApi - factory interface
 * @export
 */
export const LivesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = LivesApiFp(configuration);
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} liveId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    livesLiveIdDelete(liveId: number, options?: any): AxiosPromise<LivesLiveIdDelete200Response> {
      return localVarFp.livesLiveIdDelete(liveId, options).then(request => request(axios, basePath));
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} liveId
     * @param {LivesLiveIdDeleteRequest} [livesLiveIdDeleteRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    livesLiveIdPatch(
      liveId: number,
      livesLiveIdDeleteRequest?: LivesLiveIdDeleteRequest,
      options?: any,
    ): AxiosPromise<LivesLiveIdDelete200Response> {
      return localVarFp
        .livesLiveIdPatch(liveId, livesLiveIdDeleteRequest, options)
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * LivesApi - object-oriented interface
 * @export
 * @class LivesApi
 * @extends {BaseAPI}
 */
export class LivesApi extends BaseAPI {
  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {number} liveId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LivesApi
   */
  public livesLiveIdDelete(liveId: number, options?: AxiosRequestConfig) {
    return LivesApiFp(this.configuration)
      .livesLiveIdDelete(liveId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {number} liveId
   * @param {LivesLiveIdDeleteRequest} [livesLiveIdDeleteRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LivesApi
   */
  public livesLiveIdPatch(
    liveId: number,
    livesLiveIdDeleteRequest?: LivesLiveIdDeleteRequest,
    options?: AxiosRequestConfig,
  ) {
    return LivesApiFp(this.configuration)
      .livesLiveIdPatch(liveId, livesLiveIdDeleteRequest, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * ModerationApi - axios parameter creator
 * @export
 */
export const ModerationApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    moderationChatsSpaceGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/moderation/chats/space`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} chatId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    moderationMessagesGet: async (chatId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'chatId' is not null or undefined
      assertParamExists('moderationMessagesGet', 'chatId', chatId);
      const localVarPath = `/moderation/messages`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (chatId !== undefined) {
        localVarQueryParameter['chatId'] = chatId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ModerationApi - functional programming interface
 * @export
 */
export const ModerationApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ModerationApiAxiosParamCreator(configuration);
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async moderationChatsSpaceGet(
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ModerationChatsSpaceGet200ResponseInner>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.moderationChatsSpaceGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} chatId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async moderationMessagesGet(
      chatId: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.moderationMessagesGet(chatId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * ModerationApi - factory interface
 * @export
 */
export const ModerationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = ModerationApiFp(configuration);
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    moderationChatsSpaceGet(options?: any): AxiosPromise<Array<ModerationChatsSpaceGet200ResponseInner>> {
      return localVarFp.moderationChatsSpaceGet(options).then(request => request(axios, basePath));
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} chatId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    moderationMessagesGet(chatId: number, options?: any): AxiosPromise<object> {
      return localVarFp.moderationMessagesGet(chatId, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * ModerationApi - object-oriented interface
 * @export
 * @class ModerationApi
 * @extends {BaseAPI}
 */
export class ModerationApi extends BaseAPI {
  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ModerationApi
   */
  public moderationChatsSpaceGet(options?: AxiosRequestConfig) {
    return ModerationApiFp(this.configuration)
      .moderationChatsSpaceGet(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {number} chatId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ModerationApi
   */
  public moderationMessagesGet(chatId: number, options?: AxiosRequestConfig) {
    return ModerationApiFp(this.configuration)
      .moderationMessagesGet(chatId, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * NotifyApi - axios parameter creator
 * @export
 */
export const NotifyApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {NotifyPostRequest} [notifyPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notifyPost: async (
      notifyPostRequest?: NotifyPostRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/notify`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(notifyPostRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {NotifyRemovePostRequest} [notifyRemovePostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notifyRemovePost: async (
      notifyRemovePostRequest?: NotifyRemovePostRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/notify/remove`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        notifyRemovePostRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * NotifyApi - functional programming interface
 * @export
 */
export const NotifyApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = NotifyApiAxiosParamCreator(configuration);
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {NotifyPostRequest} [notifyPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async notifyPost(
      notifyPostRequest?: NotifyPostRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.notifyPost(notifyPostRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {NotifyRemovePostRequest} [notifyRemovePostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async notifyRemovePost(
      notifyRemovePostRequest?: NotifyRemovePostRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.notifyRemovePost(notifyRemovePostRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * NotifyApi - factory interface
 * @export
 */
export const NotifyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = NotifyApiFp(configuration);
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {NotifyPostRequest} [notifyPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notifyPost(notifyPostRequest?: NotifyPostRequest, options?: any): AxiosPromise<object> {
      return localVarFp.notifyPost(notifyPostRequest, options).then(request => request(axios, basePath));
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {NotifyRemovePostRequest} [notifyRemovePostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notifyRemovePost(notifyRemovePostRequest?: NotifyRemovePostRequest, options?: any): AxiosPromise<object> {
      return localVarFp.notifyRemovePost(notifyRemovePostRequest, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * NotifyApi - object-oriented interface
 * @export
 * @class NotifyApi
 * @extends {BaseAPI}
 */
export class NotifyApi extends BaseAPI {
  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {NotifyPostRequest} [notifyPostRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotifyApi
   */
  public notifyPost(notifyPostRequest?: NotifyPostRequest, options?: AxiosRequestConfig) {
    return NotifyApiFp(this.configuration)
      .notifyPost(notifyPostRequest, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {NotifyRemovePostRequest} [notifyRemovePostRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotifyApi
   */
  public notifyRemovePost(notifyRemovePostRequest?: NotifyRemovePostRequest, options?: AxiosRequestConfig) {
    return NotifyApiFp(this.configuration)
      .notifyRemovePost(notifyRemovePostRequest, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * Notify0Api - axios parameter creator
 * @export
 */
export const Notify0ApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {Notify0PostRequest} [notify0PostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notify0Post: async (
      notify0PostRequest?: Notify0PostRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/notify_0`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(notify0PostRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * Notify0Api - functional programming interface
 * @export
 */
export const Notify0ApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = Notify0ApiAxiosParamCreator(configuration);
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {Notify0PostRequest} [notify0PostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async notify0Post(
      notify0PostRequest?: Notify0PostRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Notify0Post200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.notify0Post(notify0PostRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * Notify0Api - factory interface
 * @export
 */
export const Notify0ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = Notify0ApiFp(configuration);
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {Notify0PostRequest} [notify0PostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notify0Post(notify0PostRequest?: Notify0PostRequest, options?: any): AxiosPromise<Notify0Post200Response> {
      return localVarFp.notify0Post(notify0PostRequest, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * Notify0Api - object-oriented interface
 * @export
 * @class Notify0Api
 * @extends {BaseAPI}
 */
export class Notify0Api extends BaseAPI {
  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {Notify0PostRequest} [notify0PostRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof Notify0Api
   */
  public notify0Post(notify0PostRequest?: Notify0PostRequest, options?: AxiosRequestConfig) {
    return Notify0ApiFp(this.configuration)
      .notify0Post(notify0PostRequest, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * PlatformApi - axios parameter creator
 * @export
 */
export const PlatformApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {'onboarding' | 'realtime' | 'afterEvent'} [type]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    platformPostsGet: async (
      type?: 'onboarding' | 'realtime' | 'afterEvent',
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/platform/posts`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (type !== undefined) {
        localVarQueryParameter['type'] = type;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {PlatformPostsOrderPostRequest} [platformPostsOrderPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    platformPostsOrderPost: async (
      platformPostsOrderPostRequest?: PlatformPostsOrderPostRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/platform/posts/order`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        platformPostsOrderPostRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {PlatformPostsGetRequest} [platformPostsGetRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    platformPostsPost: async (
      platformPostsGetRequest?: PlatformPostsGetRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/platform/posts`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        platformPostsGetRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} postId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    platformPostsPostIdDelete: async (postId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'postId' is not null or undefined
      assertParamExists('platformPostsPostIdDelete', 'postId', postId);
      const localVarPath = `/platform/posts/{postId}`.replace(`{${'postId'}}`, encodeURIComponent(String(postId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} postId
     * @param {PlatformPostsPostIdLocalPublishPostRequest} [platformPostsPostIdLocalPublishPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    platformPostsPostIdLocalPublishPost: async (
      postId: number,
      platformPostsPostIdLocalPublishPostRequest?: PlatformPostsPostIdLocalPublishPostRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'postId' is not null or undefined
      assertParamExists('platformPostsPostIdLocalPublishPost', 'postId', postId);
      const localVarPath = `/platform/posts/{postId}/localPublish`.replace(
        `{${'postId'}}`,
        encodeURIComponent(String(postId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        platformPostsPostIdLocalPublishPostRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} postId
     * @param {PlatformPostsPostIdDeleteRequest} [platformPostsPostIdDeleteRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    platformPostsPostIdPatch: async (
      postId: number,
      platformPostsPostIdDeleteRequest?: PlatformPostsPostIdDeleteRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'postId' is not null or undefined
      assertParamExists('platformPostsPostIdPatch', 'postId', postId);
      const localVarPath = `/platform/posts/{postId}`.replace(`{${'postId'}}`, encodeURIComponent(String(postId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        platformPostsPostIdDeleteRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} postId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    platformPostsPostIdPublishPost: async (postId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'postId' is not null or undefined
      assertParamExists('platformPostsPostIdPublishPost', 'postId', postId);
      const localVarPath = `/platform/posts/{postId}/publish`.replace(
        `{${'postId'}}`,
        encodeURIComponent(String(postId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} postId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    platformPostsPostIdTestPost: async (postId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'postId' is not null or undefined
      assertParamExists('platformPostsPostIdTestPost', 'postId', postId);
      const localVarPath = `/platform/posts/{postId}/test`.replace(`{${'postId'}}`, encodeURIComponent(String(postId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PlatformApi - functional programming interface
 * @export
 */
export const PlatformApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = PlatformApiAxiosParamCreator(configuration);
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {'onboarding' | 'realtime' | 'afterEvent'} [type]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async platformPostsGet(
      type?: 'onboarding' | 'realtime' | 'afterEvent',
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PlatformPostsGet200ResponseInner>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.platformPostsGet(type, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {PlatformPostsOrderPostRequest} [platformPostsOrderPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async platformPostsOrderPost(
      platformPostsOrderPostRequest?: PlatformPostsOrderPostRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.platformPostsOrderPost(
        platformPostsOrderPostRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {PlatformPostsGetRequest} [platformPostsGetRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async platformPostsPost(
      platformPostsGetRequest?: PlatformPostsGetRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PlatformPostsPostIdDelete200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.platformPostsPost(platformPostsGetRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} postId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async platformPostsPostIdDelete(
      postId: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.platformPostsPostIdDelete(postId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} postId
     * @param {PlatformPostsPostIdLocalPublishPostRequest} [platformPostsPostIdLocalPublishPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async platformPostsPostIdLocalPublishPost(
      postId: number,
      platformPostsPostIdLocalPublishPostRequest?: PlatformPostsPostIdLocalPublishPostRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.platformPostsPostIdLocalPublishPost(
        postId,
        platformPostsPostIdLocalPublishPostRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} postId
     * @param {PlatformPostsPostIdDeleteRequest} [platformPostsPostIdDeleteRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async platformPostsPostIdPatch(
      postId: number,
      platformPostsPostIdDeleteRequest?: PlatformPostsPostIdDeleteRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PlatformPostsPostIdDelete200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.platformPostsPostIdPatch(
        postId,
        platformPostsPostIdDeleteRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} postId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async platformPostsPostIdPublishPost(
      postId: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PlatformPostsPostIdPublishPost200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.platformPostsPostIdPublishPost(postId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} postId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async platformPostsPostIdTestPost(
      postId: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.platformPostsPostIdTestPost(postId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * PlatformApi - factory interface
 * @export
 */
export const PlatformApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = PlatformApiFp(configuration);
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {'onboarding' | 'realtime' | 'afterEvent'} [type]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    platformPostsGet(
      type?: 'onboarding' | 'realtime' | 'afterEvent',
      options?: any,
    ): AxiosPromise<Array<PlatformPostsGet200ResponseInner>> {
      return localVarFp.platformPostsGet(type, options).then(request => request(axios, basePath));
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {PlatformPostsOrderPostRequest} [platformPostsOrderPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    platformPostsOrderPost(
      platformPostsOrderPostRequest?: PlatformPostsOrderPostRequest,
      options?: any,
    ): AxiosPromise<object> {
      return localVarFp
        .platformPostsOrderPost(platformPostsOrderPostRequest, options)
        .then(request => request(axios, basePath));
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {PlatformPostsGetRequest} [platformPostsGetRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    platformPostsPost(
      platformPostsGetRequest?: PlatformPostsGetRequest,
      options?: any,
    ): AxiosPromise<PlatformPostsPostIdDelete200Response> {
      return localVarFp.platformPostsPost(platformPostsGetRequest, options).then(request => request(axios, basePath));
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} postId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    platformPostsPostIdDelete(postId: number, options?: any): AxiosPromise<object> {
      return localVarFp.platformPostsPostIdDelete(postId, options).then(request => request(axios, basePath));
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} postId
     * @param {PlatformPostsPostIdLocalPublishPostRequest} [platformPostsPostIdLocalPublishPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    platformPostsPostIdLocalPublishPost(
      postId: number,
      platformPostsPostIdLocalPublishPostRequest?: PlatformPostsPostIdLocalPublishPostRequest,
      options?: any,
    ): AxiosPromise<object> {
      return localVarFp
        .platformPostsPostIdLocalPublishPost(postId, platformPostsPostIdLocalPublishPostRequest, options)
        .then(request => request(axios, basePath));
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} postId
     * @param {PlatformPostsPostIdDeleteRequest} [platformPostsPostIdDeleteRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    platformPostsPostIdPatch(
      postId: number,
      platformPostsPostIdDeleteRequest?: PlatformPostsPostIdDeleteRequest,
      options?: any,
    ): AxiosPromise<PlatformPostsPostIdDelete200Response> {
      return localVarFp
        .platformPostsPostIdPatch(postId, platformPostsPostIdDeleteRequest, options)
        .then(request => request(axios, basePath));
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} postId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    platformPostsPostIdPublishPost(
      postId: number,
      options?: any,
    ): AxiosPromise<PlatformPostsPostIdPublishPost200Response> {
      return localVarFp.platformPostsPostIdPublishPost(postId, options).then(request => request(axios, basePath));
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} postId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    platformPostsPostIdTestPost(postId: number, options?: any): AxiosPromise<object> {
      return localVarFp.platformPostsPostIdTestPost(postId, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * PlatformApi - object-oriented interface
 * @export
 * @class PlatformApi
 * @extends {BaseAPI}
 */
export class PlatformApi extends BaseAPI {
  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {'onboarding' | 'realtime' | 'afterEvent'} [type]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlatformApi
   */
  public platformPostsGet(type?: 'onboarding' | 'realtime' | 'afterEvent', options?: AxiosRequestConfig) {
    return PlatformApiFp(this.configuration)
      .platformPostsGet(type, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {PlatformPostsOrderPostRequest} [platformPostsOrderPostRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlatformApi
   */
  public platformPostsOrderPost(
    platformPostsOrderPostRequest?: PlatformPostsOrderPostRequest,
    options?: AxiosRequestConfig,
  ) {
    return PlatformApiFp(this.configuration)
      .platformPostsOrderPost(platformPostsOrderPostRequest, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {PlatformPostsGetRequest} [platformPostsGetRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlatformApi
   */
  public platformPostsPost(platformPostsGetRequest?: PlatformPostsGetRequest, options?: AxiosRequestConfig) {
    return PlatformApiFp(this.configuration)
      .platformPostsPost(platformPostsGetRequest, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {number} postId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlatformApi
   */
  public platformPostsPostIdDelete(postId: number, options?: AxiosRequestConfig) {
    return PlatformApiFp(this.configuration)
      .platformPostsPostIdDelete(postId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {number} postId
   * @param {PlatformPostsPostIdLocalPublishPostRequest} [platformPostsPostIdLocalPublishPostRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlatformApi
   */
  public platformPostsPostIdLocalPublishPost(
    postId: number,
    platformPostsPostIdLocalPublishPostRequest?: PlatformPostsPostIdLocalPublishPostRequest,
    options?: AxiosRequestConfig,
  ) {
    return PlatformApiFp(this.configuration)
      .platformPostsPostIdLocalPublishPost(postId, platformPostsPostIdLocalPublishPostRequest, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {number} postId
   * @param {PlatformPostsPostIdDeleteRequest} [platformPostsPostIdDeleteRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlatformApi
   */
  public platformPostsPostIdPatch(
    postId: number,
    platformPostsPostIdDeleteRequest?: PlatformPostsPostIdDeleteRequest,
    options?: AxiosRequestConfig,
  ) {
    return PlatformApiFp(this.configuration)
      .platformPostsPostIdPatch(postId, platformPostsPostIdDeleteRequest, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {number} postId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlatformApi
   */
  public platformPostsPostIdPublishPost(postId: number, options?: AxiosRequestConfig) {
    return PlatformApiFp(this.configuration)
      .platformPostsPostIdPublishPost(postId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {number} postId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlatformApi
   */
  public platformPostsPostIdTestPost(postId: number, options?: AxiosRequestConfig) {
    return PlatformApiFp(this.configuration)
      .platformPostsPostIdTestPost(postId, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * SpacesApi - axios parameter creator
 * @export
 */
export const SpacesApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Список всех спейсов <br /><br /> <b>Authentication:</b> required
     * @summary Список всех спейсов
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    spacesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/spaces`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} spaceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    spacesSpaceIdDelete: async (spaceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'spaceId' is not null or undefined
      assertParamExists('spacesSpaceIdDelete', 'spaceId', spaceId);
      const localVarPath = `/spaces/{spaceId}`.replace(`{${'spaceId'}}`, encodeURIComponent(String(spaceId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} spaceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    spacesSpaceIdGet: async (spaceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'spaceId' is not null or undefined
      assertParamExists('spacesSpaceIdGet', 'spaceId', spaceId);
      const localVarPath = `/spaces/{spaceId}`.replace(`{${'spaceId'}}`, encodeURIComponent(String(spaceId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} spaceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    spacesSpaceIdPriceGet: async (spaceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'spaceId' is not null or undefined
      assertParamExists('spacesSpaceIdPriceGet', 'spaceId', spaceId);
      const localVarPath = `/spaces/{spaceId}/price`.replace(`{${'spaceId'}}`, encodeURIComponent(String(spaceId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} spaceId
     * @param {SpacesSpaceIdPriceGetRequest} [spacesSpaceIdPriceGetRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    spacesSpaceIdPricePatch: async (
      spaceId: number,
      spacesSpaceIdPriceGetRequest?: SpacesSpaceIdPriceGetRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'spaceId' is not null or undefined
      assertParamExists('spacesSpaceIdPricePatch', 'spaceId', spaceId);
      const localVarPath = `/spaces/{spaceId}/price`.replace(`{${'spaceId'}}`, encodeURIComponent(String(spaceId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        spacesSpaceIdPriceGetRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} spaceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    spacesSpaceIdRevokePost: async (spaceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'spaceId' is not null or undefined
      assertParamExists('spacesSpaceIdRevokePost', 'spaceId', spaceId);
      const localVarPath = `/spaces/{spaceId}/revoke`.replace(`{${'spaceId'}}`, encodeURIComponent(String(spaceId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * SpacesApi - functional programming interface
 * @export
 */
export const SpacesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = SpacesApiAxiosParamCreator(configuration);
  return {
    /**
     * Список всех спейсов <br /><br /> <b>Authentication:</b> required
     * @summary Список всех спейсов
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async spacesGet(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SpacesGet200ResponseInner>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.spacesGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} spaceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async spacesSpaceIdDelete(
      spaceId: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.spacesSpaceIdDelete(spaceId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} spaceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async spacesSpaceIdGet(
      spaceId: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SpacesSpaceIdDelete200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.spacesSpaceIdGet(spaceId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} spaceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async spacesSpaceIdPriceGet(
      spaceId: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SpacesSpaceIdPriceGet200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.spacesSpaceIdPriceGet(spaceId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} spaceId
     * @param {SpacesSpaceIdPriceGetRequest} [spacesSpaceIdPriceGetRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async spacesSpaceIdPricePatch(
      spaceId: number,
      spacesSpaceIdPriceGetRequest?: SpacesSpaceIdPriceGetRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SpacesSpaceIdPriceGet200Response1>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.spacesSpaceIdPricePatch(
        spaceId,
        spacesSpaceIdPriceGetRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} spaceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async spacesSpaceIdRevokePost(
      spaceId: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.spacesSpaceIdRevokePost(spaceId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * SpacesApi - factory interface
 * @export
 */
export const SpacesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = SpacesApiFp(configuration);
  return {
    /**
     * Список всех спейсов <br /><br /> <b>Authentication:</b> required
     * @summary Список всех спейсов
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    spacesGet(options?: any): AxiosPromise<Array<SpacesGet200ResponseInner>> {
      return localVarFp.spacesGet(options).then(request => request(axios, basePath));
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} spaceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    spacesSpaceIdDelete(spaceId: number, options?: any): AxiosPromise<object> {
      return localVarFp.spacesSpaceIdDelete(spaceId, options).then(request => request(axios, basePath));
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} spaceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    spacesSpaceIdGet(spaceId: number, options?: any): AxiosPromise<SpacesSpaceIdDelete200Response> {
      return localVarFp.spacesSpaceIdGet(spaceId, options).then(request => request(axios, basePath));
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} spaceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    spacesSpaceIdPriceGet(spaceId: number, options?: any): AxiosPromise<SpacesSpaceIdPriceGet200Response> {
      return localVarFp.spacesSpaceIdPriceGet(spaceId, options).then(request => request(axios, basePath));
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} spaceId
     * @param {SpacesSpaceIdPriceGetRequest} [spacesSpaceIdPriceGetRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    spacesSpaceIdPricePatch(
      spaceId: number,
      spacesSpaceIdPriceGetRequest?: SpacesSpaceIdPriceGetRequest,
      options?: any,
    ): AxiosPromise<SpacesSpaceIdPriceGet200Response1> {
      return localVarFp
        .spacesSpaceIdPricePatch(spaceId, spacesSpaceIdPriceGetRequest, options)
        .then(request => request(axios, basePath));
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} spaceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    spacesSpaceIdRevokePost(spaceId: number, options?: any): AxiosPromise<object> {
      return localVarFp.spacesSpaceIdRevokePost(spaceId, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * SpacesApi - object-oriented interface
 * @export
 * @class SpacesApi
 * @extends {BaseAPI}
 */
export class SpacesApi extends BaseAPI {
  /**
   * Список всех спейсов <br /><br /> <b>Authentication:</b> required
   * @summary Список всех спейсов
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SpacesApi
   */
  public spacesGet(options?: AxiosRequestConfig) {
    return SpacesApiFp(this.configuration)
      .spacesGet(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {number} spaceId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SpacesApi
   */
  public spacesSpaceIdDelete(spaceId: number, options?: AxiosRequestConfig) {
    return SpacesApiFp(this.configuration)
      .spacesSpaceIdDelete(spaceId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {number} spaceId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SpacesApi
   */
  public spacesSpaceIdGet(spaceId: number, options?: AxiosRequestConfig) {
    return SpacesApiFp(this.configuration)
      .spacesSpaceIdGet(spaceId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {number} spaceId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SpacesApi
   */
  public spacesSpaceIdPriceGet(spaceId: number, options?: AxiosRequestConfig) {
    return SpacesApiFp(this.configuration)
      .spacesSpaceIdPriceGet(spaceId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {number} spaceId
   * @param {SpacesSpaceIdPriceGetRequest} [spacesSpaceIdPriceGetRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SpacesApi
   */
  public spacesSpaceIdPricePatch(
    spaceId: number,
    spacesSpaceIdPriceGetRequest?: SpacesSpaceIdPriceGetRequest,
    options?: AxiosRequestConfig,
  ) {
    return SpacesApiFp(this.configuration)
      .spacesSpaceIdPricePatch(spaceId, spacesSpaceIdPriceGetRequest, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {number} spaceId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SpacesApi
   */
  public spacesSpaceIdRevokePost(spaceId: number, options?: AxiosRequestConfig) {
    return SpacesApiFp(this.configuration)
      .spacesSpaceIdRevokePost(spaceId, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * TagsApi - axios parameter creator
 * @export
 */
export const TagsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tagsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/tags`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {TagsTagIdDeleteRequest} [tagsTagIdDeleteRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tagsPost: async (
      tagsTagIdDeleteRequest?: TagsTagIdDeleteRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/tags`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        tagsTagIdDeleteRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} tagId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tagsTagIdDelete: async (tagId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'tagId' is not null or undefined
      assertParamExists('tagsTagIdDelete', 'tagId', tagId);
      const localVarPath = `/tags/{tagId}`.replace(`{${'tagId'}}`, encodeURIComponent(String(tagId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} tagId
     * @param {TagsTagIdDeleteRequest} [tagsTagIdDeleteRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tagsTagIdPost: async (
      tagId: number,
      tagsTagIdDeleteRequest?: TagsTagIdDeleteRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'tagId' is not null or undefined
      assertParamExists('tagsTagIdPost', 'tagId', tagId);
      const localVarPath = `/tags/{tagId}`.replace(`{${'tagId'}}`, encodeURIComponent(String(tagId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        tagsTagIdDeleteRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} spaceId
     * @param {number} tagId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tagsTagIdSpacesSpaceIdDelete: async (
      spaceId: number,
      tagId: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'spaceId' is not null or undefined
      assertParamExists('tagsTagIdSpacesSpaceIdDelete', 'spaceId', spaceId);
      // verify required parameter 'tagId' is not null or undefined
      assertParamExists('tagsTagIdSpacesSpaceIdDelete', 'tagId', tagId);
      const localVarPath = `/tags/{tagId}/spaces/{spaceId}`
        .replace(`{${'spaceId'}}`, encodeURIComponent(String(spaceId)))
        .replace(`{${'tagId'}}`, encodeURIComponent(String(tagId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} spaceId
     * @param {number} tagId
     * @param {TagsTagIdSpacesSpaceIdDeleteRequest} [tagsTagIdSpacesSpaceIdDeleteRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tagsTagIdSpacesSpaceIdPost: async (
      spaceId: number,
      tagId: number,
      tagsTagIdSpacesSpaceIdDeleteRequest?: TagsTagIdSpacesSpaceIdDeleteRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'spaceId' is not null or undefined
      assertParamExists('tagsTagIdSpacesSpaceIdPost', 'spaceId', spaceId);
      // verify required parameter 'tagId' is not null or undefined
      assertParamExists('tagsTagIdSpacesSpaceIdPost', 'tagId', tagId);
      const localVarPath = `/tags/{tagId}/spaces/{spaceId}`
        .replace(`{${'spaceId'}}`, encodeURIComponent(String(spaceId)))
        .replace(`{${'tagId'}}`, encodeURIComponent(String(tagId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        tagsTagIdSpacesSpaceIdDeleteRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * TagsApi - functional programming interface
 * @export
 */
export const TagsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = TagsApiAxiosParamCreator(configuration);
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async tagsGet(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TagsTagIdDelete200Response>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.tagsGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {TagsTagIdDeleteRequest} [tagsTagIdDeleteRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async tagsPost(
      tagsTagIdDeleteRequest?: TagsTagIdDeleteRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TagsTagIdDelete200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.tagsPost(tagsTagIdDeleteRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} tagId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async tagsTagIdDelete(
      tagId: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.tagsTagIdDelete(tagId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} tagId
     * @param {TagsTagIdDeleteRequest} [tagsTagIdDeleteRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async tagsTagIdPost(
      tagId: number,
      tagsTagIdDeleteRequest?: TagsTagIdDeleteRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TagsTagIdDelete200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.tagsTagIdPost(tagId, tagsTagIdDeleteRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} spaceId
     * @param {number} tagId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async tagsTagIdSpacesSpaceIdDelete(
      spaceId: number,
      tagId: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TagsTagIdSpacesSpaceIdDelete200Response>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.tagsTagIdSpacesSpaceIdDelete(spaceId, tagId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} spaceId
     * @param {number} tagId
     * @param {TagsTagIdSpacesSpaceIdDeleteRequest} [tagsTagIdSpacesSpaceIdDeleteRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async tagsTagIdSpacesSpaceIdPost(
      spaceId: number,
      tagId: number,
      tagsTagIdSpacesSpaceIdDeleteRequest?: TagsTagIdSpacesSpaceIdDeleteRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TagsTagIdSpacesSpaceIdDelete200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.tagsTagIdSpacesSpaceIdPost(
        spaceId,
        tagId,
        tagsTagIdSpacesSpaceIdDeleteRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * TagsApi - factory interface
 * @export
 */
export const TagsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = TagsApiFp(configuration);
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tagsGet(options?: any): AxiosPromise<Array<TagsTagIdDelete200Response>> {
      return localVarFp.tagsGet(options).then(request => request(axios, basePath));
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {TagsTagIdDeleteRequest} [tagsTagIdDeleteRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tagsPost(tagsTagIdDeleteRequest?: TagsTagIdDeleteRequest, options?: any): AxiosPromise<TagsTagIdDelete200Response> {
      return localVarFp.tagsPost(tagsTagIdDeleteRequest, options).then(request => request(axios, basePath));
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} tagId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tagsTagIdDelete(tagId: number, options?: any): AxiosPromise<object> {
      return localVarFp.tagsTagIdDelete(tagId, options).then(request => request(axios, basePath));
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} tagId
     * @param {TagsTagIdDeleteRequest} [tagsTagIdDeleteRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tagsTagIdPost(
      tagId: number,
      tagsTagIdDeleteRequest?: TagsTagIdDeleteRequest,
      options?: any,
    ): AxiosPromise<TagsTagIdDelete200Response> {
      return localVarFp.tagsTagIdPost(tagId, tagsTagIdDeleteRequest, options).then(request => request(axios, basePath));
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} spaceId
     * @param {number} tagId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tagsTagIdSpacesSpaceIdDelete(
      spaceId: number,
      tagId: number,
      options?: any,
    ): AxiosPromise<Array<TagsTagIdSpacesSpaceIdDelete200Response>> {
      return localVarFp.tagsTagIdSpacesSpaceIdDelete(spaceId, tagId, options).then(request => request(axios, basePath));
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} spaceId
     * @param {number} tagId
     * @param {TagsTagIdSpacesSpaceIdDeleteRequest} [tagsTagIdSpacesSpaceIdDeleteRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tagsTagIdSpacesSpaceIdPost(
      spaceId: number,
      tagId: number,
      tagsTagIdSpacesSpaceIdDeleteRequest?: TagsTagIdSpacesSpaceIdDeleteRequest,
      options?: any,
    ): AxiosPromise<TagsTagIdSpacesSpaceIdDelete200Response> {
      return localVarFp
        .tagsTagIdSpacesSpaceIdPost(spaceId, tagId, tagsTagIdSpacesSpaceIdDeleteRequest, options)
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * TagsApi - object-oriented interface
 * @export
 * @class TagsApi
 * @extends {BaseAPI}
 */
export class TagsApi extends BaseAPI {
  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TagsApi
   */
  public tagsGet(options?: AxiosRequestConfig) {
    return TagsApiFp(this.configuration)
      .tagsGet(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {TagsTagIdDeleteRequest} [tagsTagIdDeleteRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TagsApi
   */
  public tagsPost(tagsTagIdDeleteRequest?: TagsTagIdDeleteRequest, options?: AxiosRequestConfig) {
    return TagsApiFp(this.configuration)
      .tagsPost(tagsTagIdDeleteRequest, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {number} tagId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TagsApi
   */
  public tagsTagIdDelete(tagId: number, options?: AxiosRequestConfig) {
    return TagsApiFp(this.configuration)
      .tagsTagIdDelete(tagId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {number} tagId
   * @param {TagsTagIdDeleteRequest} [tagsTagIdDeleteRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TagsApi
   */
  public tagsTagIdPost(tagId: number, tagsTagIdDeleteRequest?: TagsTagIdDeleteRequest, options?: AxiosRequestConfig) {
    return TagsApiFp(this.configuration)
      .tagsTagIdPost(tagId, tagsTagIdDeleteRequest, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {number} spaceId
   * @param {number} tagId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TagsApi
   */
  public tagsTagIdSpacesSpaceIdDelete(spaceId: number, tagId: number, options?: AxiosRequestConfig) {
    return TagsApiFp(this.configuration)
      .tagsTagIdSpacesSpaceIdDelete(spaceId, tagId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {number} spaceId
   * @param {number} tagId
   * @param {TagsTagIdSpacesSpaceIdDeleteRequest} [tagsTagIdSpacesSpaceIdDeleteRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TagsApi
   */
  public tagsTagIdSpacesSpaceIdPost(
    spaceId: number,
    tagId: number,
    tagsTagIdSpacesSpaceIdDeleteRequest?: TagsTagIdSpacesSpaceIdDeleteRequest,
    options?: AxiosRequestConfig,
  ) {
    return TagsApiFp(this.configuration)
      .tagsTagIdSpacesSpaceIdPost(spaceId, tagId, tagsTagIdSpacesSpaceIdDeleteRequest, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * UndoFreeBuyApi - axios parameter creator
 * @export
 */
export const UndoFreeBuyApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {UndoFreeBuyPostRequest} [undoFreeBuyPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    undoFreeBuyPost: async (
      undoFreeBuyPostRequest?: UndoFreeBuyPostRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/undoFreeBuy`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        undoFreeBuyPostRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * UndoFreeBuyApi - functional programming interface
 * @export
 */
export const UndoFreeBuyApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = UndoFreeBuyApiAxiosParamCreator(configuration);
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {UndoFreeBuyPostRequest} [undoFreeBuyPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async undoFreeBuyPost(
      undoFreeBuyPostRequest?: UndoFreeBuyPostRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.undoFreeBuyPost(undoFreeBuyPostRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * UndoFreeBuyApi - factory interface
 * @export
 */
export const UndoFreeBuyApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = UndoFreeBuyApiFp(configuration);
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {UndoFreeBuyPostRequest} [undoFreeBuyPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    undoFreeBuyPost(undoFreeBuyPostRequest?: UndoFreeBuyPostRequest, options?: any): AxiosPromise<object> {
      return localVarFp.undoFreeBuyPost(undoFreeBuyPostRequest, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * UndoFreeBuyApi - object-oriented interface
 * @export
 * @class UndoFreeBuyApi
 * @extends {BaseAPI}
 */
export class UndoFreeBuyApi extends BaseAPI {
  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {UndoFreeBuyPostRequest} [undoFreeBuyPostRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UndoFreeBuyApi
   */
  public undoFreeBuyPost(undoFreeBuyPostRequest?: UndoFreeBuyPostRequest, options?: AxiosRequestConfig) {
    return UndoFreeBuyApiFp(this.configuration)
      .undoFreeBuyPost(undoFreeBuyPostRequest, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {boolean} [filter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersGet: async (filter?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/users`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (filter !== undefined) {
        localVarQueryParameter['filter'] = filter;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} userId
     * @param {UsersUserIdSendPlatformMessagePostRequest} [usersUserIdSendPlatformMessagePostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersUserIdSendPlatformMessagePost: async (
      userId: number,
      usersUserIdSendPlatformMessagePostRequest?: UsersUserIdSendPlatformMessagePostRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists('usersUserIdSendPlatformMessagePost', 'userId', userId);
      const localVarPath = `/users/{userId}/sendPlatformMessage`.replace(
        `{${'userId'}}`,
        encodeURIComponent(String(userId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        usersUserIdSendPlatformMessagePostRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration);
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {boolean} [filter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async usersGet(
      filter?: boolean,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UsersGet200ResponseInner>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.usersGet(filter, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} userId
     * @param {UsersUserIdSendPlatformMessagePostRequest} [usersUserIdSendPlatformMessagePostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async usersUserIdSendPlatformMessagePost(
      userId: number,
      usersUserIdSendPlatformMessagePostRequest?: UsersUserIdSendPlatformMessagePostRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.usersUserIdSendPlatformMessagePost(
        userId,
        usersUserIdSendPlatformMessagePostRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = UsersApiFp(configuration);
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {boolean} [filter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersGet(filter?: boolean, options?: any): AxiosPromise<Array<UsersGet200ResponseInner>> {
      return localVarFp.usersGet(filter, options).then(request => request(axios, basePath));
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} userId
     * @param {UsersUserIdSendPlatformMessagePostRequest} [usersUserIdSendPlatformMessagePostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersUserIdSendPlatformMessagePost(
      userId: number,
      usersUserIdSendPlatformMessagePostRequest?: UsersUserIdSendPlatformMessagePostRequest,
      options?: any,
    ): AxiosPromise<object> {
      return localVarFp
        .usersUserIdSendPlatformMessagePost(userId, usersUserIdSendPlatformMessagePostRequest, options)
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {boolean} [filter]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public usersGet(filter?: boolean, options?: AxiosRequestConfig) {
    return UsersApiFp(this.configuration)
      .usersGet(filter, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {number} userId
   * @param {UsersUserIdSendPlatformMessagePostRequest} [usersUserIdSendPlatformMessagePostRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public usersUserIdSendPlatformMessagePost(
    userId: number,
    usersUserIdSendPlatformMessagePostRequest?: UsersUserIdSendPlatformMessagePostRequest,
    options?: AxiosRequestConfig,
  ) {
    return UsersApiFp(this.configuration)
      .usersUserIdSendPlatformMessagePost(userId, usersUserIdSendPlatformMessagePostRequest, options)
      .then(request => request(this.axios, this.basePath));
  }
}
