import { Nullable } from '@sp/util/helpers';
import { z } from 'zod';
import { UploadedFile } from './space-types/concrete-space.types';

export type UserLink = {
  href: string;
  title: string;
  order: number;
};

export type MeUserLink = {
  href: string;
  title: string;
  id: number;
  order: number;
  createdAt: number;
  updatedAt?: Nullable<number>;
};

export enum NotificationLevel {
  high = 'high',
  low = 'low',
  off = 'off',
}

export const NotificationSettings = z.object({
  newMessagesInChallenges: z.boolean(), // вообще все сообщения в челленджах
  newMentionsInChallenges: z.boolean(), // упоминания в челленджах
  newCreatorsMessagesInChallenges: z.boolean(), // сообщения криейтора в челленджах
  newMessagesInDMs: z.boolean(), // новые сообщения в DM
  newPlatformMessages: z.boolean(), // новые сообщения в канале платформы
  newRepliesInChallenges: z.boolean(), // новые реплаи в челленджах
});

export type NotificationSettings = z.infer<typeof NotificationSettings>;

// MeUser
// PublicUser
// Member

const KnownCardBrandEnum = z.enum([
  'visa',
  'mastercard',
  'amex',
  'discover',
  'jcb',
  'diners',
  'unionpay',
  'cartes_bancaires',
] as const);

export const KnownCardBrand = KnownCardBrandEnum.Values;
export type KnownCardBrand = z.infer<typeof KnownCardBrandEnum>;

export const MeUser = z.object({
  id: z.number().int(),
  created_at: z.number().int(),
  name: z.string(),
  email: z.string().nullable(),
  isAdmin: z.boolean(),
  isExpert: z.boolean(),
  description: z.string().nullable(),
  bio: z.string().nullable(),
  isFirstChallengeVisited: z.boolean(),
  isRegistrationCompleted: z.boolean(),
  slug: z.string(),
  subscriptionPrice: z.number().int().nullable(),
  notifications: NotificationSettings,
  avatar: z
    .object({
      url: z.string().url(),
      id: z.number().int(),
    })
    .nullable(),
  pinnedChatsIds: z.array(z.number().int()).nullable(),
  paymentMethod: z
    .object({
      id: z.string(),
      card: z.object({
        brand: KnownCardBrandEnum,
        last4: z.string(),
        exp_month: z.number(),
        exp_year: z.number(),
      }),
    })
    .nullable(),
  archivedChatsIds: z.array(z.number().int()).nullable(),
});

export type MeUser = z.infer<typeof MeUser>;

export interface UserAvatar {
  readonly url: string | null;
}

export type Skill = {
  readonly points: number;
  readonly tag: {
    readonly id: number;
    readonly name: string;
    readonly color: string;
  } | null;
};

export interface PublicUser {
  id: number;
  name: string;
  bio: string | null;
  created_at: number;
  isExpert: boolean; // FIXME[Dmitriy Teplov] change to isExpert: false
  // slug: string; // TODO[Dmitriy Teplov] нужен обычным юзерам?
  links: UserLink[];
  avatar: UserAvatar | null;
  points: readonly Skill[];
}

export type CreatorCommunity = Readonly<{
  id: number; // space id
  title: string;
  cover: UploadedFile | null;
  description: string | null;
  subscribersCount: number;
  price: number;
  iosProductId: string | null;
  trialPrice: number;
  subscription: null | Readonly<{ isActive: boolean }>;
  marketingPriceConfig: Readonly<{
    iosPrice: number;
    iosTrialPrice: number;
  }>;
}>;

export type ExpertUser = Omit<PublicUser, 'isExpert'> & {
  isExpert: true;
  slug: string;
  description: string;
  subscriptionPrice: number;
  communities: readonly CreatorCommunity[];
};

export function isExpertUser(user: PublicUser | ExpertUser): user is ExpertUser {
  return user.isExpert;
}

export type UserCreator = {
  id: number;
  name: string;
  avatar: Nullable<{ url: Nullable<string> }>;
};

export type UserCreators = {
  subscriptions: UserCreator[];
  purchases: UserCreator[];
};
