import { cls } from '@sp/util/helpers';
import { FC, ReactNode } from 'react';

export const SpaceDescriptionTable: FC<{
  rows: Array<{
    label: ReactNode;
    value: ReactNode;
    bold?: boolean;
  }>;
  heading?: string;
}> = ({ heading, rows }) => {
  return (
    <div>
      {heading && <h2 className="text-[1.75rem] leading-7 font-semibold mx-0 my-8">{heading}</h2>}

      <table className="w-auto m-0 p-0 border-collapse bg-transparent">
        <tbody>
          {rows.map(({ label, value, bold }, index) => (
            <tr key={index} className={cls(bold && 'font-medium')}>
              <td className="p-0 align-top">{label}</td>
              <td className="p-0 pl-4">{value}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
