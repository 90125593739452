import { useUser } from '@sp/data/auth';
import { COMMUNITY_NOTIFIER_MODEL } from '@sp/feature/community-notifier';
import { ChatChannelModel } from '@sp/feature/stream-chat';
import {
  ChatMessageBodyContainer,
  ChatMessageContainer,
  ChatMessageFooterContainer,
  ChatMessageLeftContainer,
  ChatMessageRightContainer,
} from '@sp/ui/chat';
import { IconArrowBendUpLeft, IconCopy, IconFeed, IconFeedActive, IconPencil, IconTrashSimple } from '@sp/ui/icons';
import { Modal } from '@sp/ui/modal';
import { isNotNullish } from '@sp/util/helpers';
import { ChatFormatMessage } from '@sp/util/stream-chat';
import { MessagePreview } from '@sp/util/teaser';
import { isEmptyDoc, parseMessageContent, renderDocToHtml, renderHtmlToText } from '@sp/util/text-editor';
import { FC, RefObject, useCallback, useEffect, useMemo, useRef, useState, VFC } from 'react';
import { ChatMessageAvatar } from '../elements/chat-message-avatar';
import { ChatMessageProvider } from '../elements/chat-message-context';
import { ChatMessageHeader } from '../elements/chat-message-header';
import { MessageFormHandle } from '../message-form';
import { useMessageExtraContent } from '../use-message-extra-content';
import { useMessageForm } from '../use-message-form';
import { MessageModalReactions } from './message-modal-reactions';

export const actionButtonClassNames =
  'mt-2 flex items-center py-4 gap-2 border-b border-b-stripe last-of-type:border-b-0 disabled:opacity-50';
export const CopyMessage: VFC<
  Readonly<{
    isActive: boolean;
    activeMessage: ChatFormatMessage | null;
    onSuccess: VoidFunction;
  }>
> = ({ activeMessage, isActive, onSuccess }) => {
  const canCopyActiveMessageContent = useMemo(() => {
    if (!activeMessage?.text) return false;
    return !isEmptyDoc(parseMessageContent(activeMessage.text));
  }, [activeMessage?.text]);

  const [copyStatus, setCopyStatus] = useState<string>('Copy');
  const copyStatusTimeout = useRef<number | null>(null);
  const clearCopyStatusTimeout = useCallback(() => {
    if (copyStatusTimeout.current == null) return;
    window.clearTimeout(copyStatusTimeout.current);
  }, []);

  useEffect(() => {
    if (!isActive) {
      clearCopyStatusTimeout();
      setCopyStatus('Copy');
    }
  }, [activeMessage, clearCopyStatusTimeout, isActive]);

  const copyActiveMessageContent = useCallback(async () => {
    if (!activeMessage?.text) return;
    clearCopyStatusTimeout();
    setCopyStatus('Copying...');

    const html = renderDocToHtml(parseMessageContent(activeMessage.text));
    const text = renderHtmlToText(html);
    try {
      await window.navigator.clipboard.write([
        new ClipboardItem({
          'text/html': new Blob([html], { type: 'text/html' }),
          'text/plain': new Blob([text], { type: 'text/plain' }),
        }),
      ]);
      setCopyStatus('Copied!');
    } catch (e) {
      console.error(e);
      setCopyStatus(`Can't copy.`);
    }

    window.setTimeout(() => {
      setCopyStatus('Copy');
      onSuccess();
    }, 200);
  }, [activeMessage?.text, clearCopyStatusTimeout, onSuccess]);

  return (
    <button
      type="button"
      className={actionButtonClassNames}
      onClick={copyActiveMessageContent}
      disabled={!canCopyActiveMessageContent}
    >
      <IconCopy size={24} /> {copyStatus}
    </button>
  );
};

const FeedMessage: FC<{ active?: boolean; onClick: (val: boolean) => void }> = ({ active, onClick }) => {
  const handleClick = () => {
    onClick(!active);
  };

  return active ? (
    <button type="button" className={actionButtonClassNames} onClick={handleClick}>
      <IconFeedActive size={24} /> Remove from feed
    </button>
  ) : (
    <button type="button" className={actionButtonClassNames} onClick={handleClick}>
      <IconFeed size={24} /> Send to feed
    </button>
  );
};

export type MessageModalConfig = {
  reactions: boolean;
  feed: boolean;
  reply: boolean;
  copy: boolean;
  edit: boolean;
  delete: boolean;
};

const defaultConfig = {
  reactions: true,
  feed: false,
  reply: true,
  copy: true,
  edit: true,
  delete: true,
};

export const MessageModal: FC<{
  activeMessage: ChatFormatMessage | null;
  readonly chatModel: ChatChannelModel;
  setActiveMessage: (message: ChatFormatMessage | null) => void;
  messageFormRef: RefObject<MessageFormHandle>;
  formModel: ReturnType<typeof useMessageForm>;
  config?: MessageModalConfig;
}> = ({ setActiveMessage, activeMessage, messageFormRef, chatModel, formModel, config = defaultConfig }) => {
  const user = useUser();
  const isMessageActionsModalOpen = activeMessage !== null;
  const closeMessageActionsModal = useCallback(() => setActiveMessage(null), [setActiveMessage]);

  const canEditActiveMessage = activeMessage?.user?.id === user.id.toString();

  const quoteActiveMessage = useCallback(() => {
    if (!activeMessage) return;
    formModel.quote(activeMessage);
    messageFormRef.current?.focus();
    closeMessageActionsModal();
  }, [activeMessage, closeMessageActionsModal, formModel, messageFormRef]);

  const editActiveMessage = useCallback(() => {
    if (!activeMessage) return;
    formModel.edit(activeMessage);
    messageFormRef.current?.focus();
    closeMessageActionsModal();
  }, [activeMessage, closeMessageActionsModal, formModel, messageFormRef]);

  const deleteActiveMessage = useCallback(() => {
    if (!activeMessage) return;
    chatModel.messageDeleted(activeMessage.id);
    closeMessageActionsModal();
  }, [activeMessage, chatModel, closeMessageActionsModal]);

  const addToFeed = useCallback(
    (val: boolean) => {
      if (!activeMessage) return;

      chatModel.pinMessage({ id: activeMessage.id, pin: val });

      closeMessageActionsModal();

      //animate tab
      COMMUNITY_NOTIFIER_MODEL.messageSentToFeed();
    },
    [activeMessage, chatModel, closeMessageActionsModal],
  );

  const messageContext = useMemo(
    () => (activeMessage ? { message: activeMessage, model: chatModel } : null),
    [activeMessage, chatModel],
  );

  const messageExtraContent = useMessageExtraContent();

  return (
    <Modal isOpen={isMessageActionsModalOpen} onClose={closeMessageActionsModal} closeOnBackdropClick withBackdrop>
      <div>
        {isNotNullish(messageContext) && isNotNullish(activeMessage) ? (
          <ChatMessageProvider value={messageContext}>
            <ChatMessageContainer>
              <ChatMessageLeftContainer>
                <ChatMessageAvatar />
              </ChatMessageLeftContainer>
              <ChatMessageRightContainer>
                <div className="-mt-1">
                  <ChatMessageHeader afterUserNameContent={messageExtraContent.afterUserName?.(activeMessage.user)} />
                </div>
                <ChatMessageBodyContainer>
                  <div className="-mt-1">
                    {activeMessage ? <MessagePreview message={activeMessage} showName={false} /> : null}
                  </div>
                </ChatMessageBodyContainer>
              </ChatMessageRightContainer>
            </ChatMessageContainer>
            <ChatMessageFooterContainer>
              {config.reactions && (
                <MessageModalReactions
                  activeMessage={activeMessage}
                  chatModel={chatModel}
                  onToggle={closeMessageActionsModal}
                />
              )}
            </ChatMessageFooterContainer>
          </ChatMessageProvider>
        ) : null}
        <div className="flex flex-col">
          {config.feed && <FeedMessage active={activeMessage?.pinned} onClick={addToFeed} />}
          {config.reply && (
            <button type="button" className={actionButtonClassNames} onClick={quoteActiveMessage}>
              <IconArrowBendUpLeft size={24} /> Reply
            </button>
          )}

          {config.copy && (
            <CopyMessage
              activeMessage={activeMessage}
              isActive={isMessageActionsModalOpen}
              onSuccess={closeMessageActionsModal}
            />
          )}

          {/* FIXME[Dmitriy Teplov] when modal is closed active message is reset immediately */}
          {/*   thus making these controls disappear. */}
          {canEditActiveMessage && (
            <>
              {config.edit && (
                <button type="button" className={actionButtonClassNames} onClick={editActiveMessage}>
                  <IconPencil size={24} /> Edit
                </button>
              )}

              {config.delete && (
                <button type="button" className={actionButtonClassNames} onClick={deleteActiveMessage}>
                  <IconTrashSimple size={24} /> Delete
                </button>
              )}
            </>
          )}
        </div>
      </div>
    </Modal>
  );
};
