import { SELFPLUS_WEBSITE_URL } from '@sp/data/env';
import { GlobalMediaPlayerModel } from '@sp/data/global';
import { BuyButtonResultFn, SubscribeButton } from '@sp/feature/buy-button';
import { ReactionsModal } from '@sp/feature/chat';
import { UserProfileCard } from '@sp/feature/profile';
import { Header } from '@sp/feature/space';
import { Avatar, Button, SpinnerPage } from '@sp/ui/elements';
import { IconCoins } from '@sp/ui/icons';
import { Modal } from '@sp/ui/modal';
import { SpaceTag } from '@sp/ui/spaces-shared';
import { GRAY_PIXEL_IMG_SRC } from '@sp/util/files';
import { useFormatPrice } from '@sp/util/format';
import { useStore } from 'effector-react';
import { FC, useCallback, useEffect, useMemo, useState, VFC } from 'react';
import { ChannelChat } from './channel-chat';
import { ChannelDescriptionModal, descriptionModalReset, openDescriptionModal } from './channel-description-modal';
import { CHANNEL_MODEL, ChannelInfo } from './channel-model';

export const ChannelHeaderPaywall: VFC<Readonly<{ onSubscribe: VoidFunction }>> = ({ onSubscribe }) => {
  return (
    <div className="flex justify-between items-center px-3 py-2 w-full bg-[#E8F7F0] border-b border-stripe">
      <div className="flex flex-col">
        <span className="font-semibold">Unlock premium content</span>
        <span className="text-xs mb-1">Receive the most useful information</span>
      </div>

      <Button color="black" onClick={onSubscribe}>
        Unlock
      </Button>
    </div>
  );
};

const SubscribeModal: FC<{
  isOpen: boolean;
  onClose: VoidFunction;
  channel: ChannelInfo;
  onResult: BuyButtonResultFn;
}> = ({ isOpen, onClose, channel, onResult }) => {
  const formatPrice = useFormatPrice();
  const price = useMemo(() => formatPrice(channel.priceConfig), [channel.priceConfig, formatPrice]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnBackdropClick withBackdrop>
      <div className="flex justify-start items-start gap-2 pb-2 w-full">
        <span>
          <Avatar size="sm" src={channel.cover?.url} />
        </span>
        <div className="flex flex-col flex-1 overflow-x-hidden">
          <h1 className="m-0 text-ellipsis whitespace-nowrap overflow-x-hidden text-base">{channel.title}</h1>
          <span className="text-secondary text-xs font-semibold">{channel.creator?.name}</span>
        </div>
      </div>

      {channel.tags.length > 0 ? (
        <div className="mb-2">
          <span className="font-semibold text-xs">Monthly Skills up:</span>
          {channel.tags.map(({ name, id, weight, color }) => (
            <SpaceTag key={id} color={color} text={`+${weight} ${name}`} />
          ))}
        </div>
      ) : null}

      <hr className="mt-2 mb-4" />

      <div className="flex flex-col gap-2 mb-6">
        <div className="flex items-center gap-1">
          <IconCoins size={18} />
          <span className="font-semibold">{price}</span>
          Monthly
        </div>
      </div>

      <SubscribeButton
        id={channel.id}
        title={channel.title}
        price={channel.priceConfig}
        iosProductId={channel.iosProductId}
        creatorId={channel.creator.id}
        creatorName={channel.creator.name}
        bought={false}
        openUrl="."
        onResult={onResult}
      >
        Subscribe
      </SubscribeButton>
    </Modal>
  );
};

export const ChannelPage: VFC<Readonly<{ channelId: number; onBackClick: () => void }>> = ({ channelId }) => {
  const chatModel = useStore(CHANNEL_MODEL.$chatModel);
  const isOwner = useStore(CHANNEL_MODEL.$isOwner);
  const channel = useStore(CHANNEL_MODEL.$channel);
  const isLoading = useStore(CHANNEL_MODEL.$isLoading);

  const [isSubscribeModalOpen, setIsSubscribeModalOpen] = useState(false);
  const handleOnSubscribe = useCallback(() => setIsSubscribeModalOpen(true), []);
  const closeManageSubscription = useCallback(() => setIsSubscribeModalOpen(false), []);

  const onSubscribeResult: BuyButtonResultFn = useCallback(({ success }) => {
    if (success) {
      CHANNEL_MODEL.refetchData();
      descriptionModalReset();
      setIsSubscribeModalOpen(false);
    }
  }, []);

  useEffect(() => {
    CHANNEL_MODEL.init(channelId);
  }, [channelId]);

  useEffect(() => {
    return () => {
      CHANNEL_MODEL.destroy();
      GlobalMediaPlayerModel.close();
    };
  }, []);

  if (!channel || !chatModel || isLoading) return <SpinnerPage />;

  return (
    <div className="h-full flex flex-col">
      <Header
        name={channel?.creator?.name}
        className="border-b border-stripe pb-4"
        creatorId={channel.creator?.id}
        title={channel?.title || ''}
        members={channel.membersCount}
        avatar={channel.cover?.url ?? GRAY_PIXEL_IMG_SRC}
        urlToShare={`${SELFPLUS_WEBSITE_URL}/channel/?id=${channel?.id}`}
        onClick={openDescriptionModal}
      />

      <div className="flex-1 flex flex-col overflow-y-hidden h-full">
        {!channel?.isPurchased && !isOwner && <ChannelHeaderPaywall onSubscribe={handleOnSubscribe} />}

        <div className="flex-grow">
          <ChannelChat chatModel={chatModel} onClickUnlock={handleOnSubscribe} />
        </div>
      </div>
      <UserProfileCard cardContext="space" />
      <ReactionsModal />
      <ChannelDescriptionModal onSubscribe={handleOnSubscribe} />
      <SubscribeModal
        isOpen={isSubscribeModalOpen}
        onClose={closeManageSubscription}
        channel={channel}
        onResult={onSubscribeResult}
      />
    </div>
  );
};
