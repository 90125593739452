import { ProfileApiFactory } from '../../api/default';
import { MeUser, NotificationSettings } from '../../user.types';
import { apiResponseAdHoc } from '../helpers';

const defaultProfileApi = ProfileApiFactory();

type PatchUserFields = Partial<{
  name: string;
  email: string;
  password: string;
  description: string;
  bio: string;
  slug: string;
  avatarId: number;
  isFirstChallengeVisited: boolean;
  notifications?: NotificationSettings;
}>;

export async function patchUserProfile(fields: PatchUserFields): Promise<MeUser> {
  return defaultProfileApi.profilePatch(fields).then(r => r.data as MeUser);
}

export function pushTokens(token: string, service: 'apns' | 'fcm') {
  defaultProfileApi.profilePushTokensPost({ service, deviceToken: token });
}

export async function deleteNotificationsTokens() {
  return defaultProfileApi.profilePushTokensDelete();
}

export async function pinChat(chatId: number): Promise<number[]> {
  return defaultProfileApi
    .profileChatsPinnedPost({ added: [chatId] })
    .then(r => apiResponseAdHoc(r.data).pinnedChatsIds ?? []);
}

export async function unpinChat(chatId: number): Promise<number[]> {
  return defaultProfileApi.profileChatsPinnedDelete(chatId).then(r => apiResponseAdHoc(r.data).pinnedChatsIds ?? []);
}

export async function archiveChat(chatId: number): Promise<number[]> {
  return defaultProfileApi
    .profileChatsArchivedPost({ added: [chatId] })
    .then(r => apiResponseAdHoc(r.data).archivedChatsIds ?? []);
}

export async function unarchiveChat(chatId: number): Promise<number[]> {
  return defaultProfileApi
    .profileChatsArchivedDelete(chatId)
    .then(r => apiResponseAdHoc(r.data).archivedChatsIds ?? []);
}
