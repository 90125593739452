import { DeepReadonly, Nullable } from '@sp/util/helpers';
import { KnownSkillColor } from '@sp/util/skills';
import {
  ChallengeApiFactory,
  ClubsApiFactory,
  CreatorsApiFactory,
  DiscoverApiFactory,
  SpacesApiFactory,
  UsersApiFactory,
} from '../api/private';
import {
  ChallengeSpaceConfig,
  ChallengeSpaceMeta,
  ChannelSpaceConfig,
  LiveSpaceConfig,
  SpaceMember,
} from '../space-types/concrete-space.types';
import { ProtectedSpace } from '../space-types/protected-space.types';
import { SPACE_PAYMENT_TYPE, SPACE_STATUS, SPACE_TYPE } from '../space-types/shared-space.types';
import { ExpertUser, PublicUser, UserCreators } from '../user.types';
import { apiResponseAdHoc } from './helpers';
import { castApiProtectedSpaceDto } from './space-helpers';

const privateUsersApi = UsersApiFactory();
const privateChallengeApi = ChallengeApiFactory();
const privateDiscoverApi = DiscoverApiFactory();
const privateClubsApi = ClubsApiFactory();
const privateCreatorApi = CreatorsApiFactory();
const privateSpacesApi = SpacesApiFactory();

export type CreatorInfo = {
  id: number;
  name: string;
  description: Nullable<string>;
  isAvatar: boolean;
  isFollowed: boolean;
  isSubscribed: boolean;
  avatar: Nullable<{
    id: number;
    url: string;
  }>;
  subscriptionPrice: Nullable<number>;
  spaces: CreatorSpace[];
};

export type CreatorSpace = {
  id: number;
  title: string;
  price: number;
  type: string;
  payment_type: keyof typeof SPACE_PAYMENT_TYPE;
  publishedAt?: number | null;
  config: Nullable<{
    challenge: Nullable<{
      finishTimestamp: Nullable<number>;
    }>;
  }>;
  isPurchased: boolean;
  isTrial: boolean;
  isSubscribed: boolean;
};

export function loadCreatorById(id: number): Promise<CreatorInfo> {
  return privateCreatorApi.creatorsCreatorIdGet(id).then(r => apiResponseAdHoc(r.data));
}

export function loadSpaceById(spaceId: number): Promise<ProtectedSpace> {
  return privateSpacesApi.spacesSpaceIdGet(spaceId).then(r => castApiProtectedSpaceDto(r.data));
}

export function followSpaceById(spaceId: number) {
  return privateSpacesApi.spacesSpaceIdFollowPost(spaceId).then(r => apiResponseAdHoc(r.data));
}

export function loadSpaceMembers(
  spaceId: number,
  limit = 10000,
): Promise<{ members: SpaceMember[]; membersCountNumber: number }> {
  return privateSpacesApi.spacesSpaceIdMembersGet(spaceId, limit).then(r => apiResponseAdHoc(r.data));
}

export function getPrivateUserById(userId: number): Promise<PublicUser | ExpertUser> {
  return privateUsersApi.usersUserIdGet(userId).then(r => apiResponseAdHoc(r.data));
}

export function getPrivateUserCreatorsById(userId: number): Promise<UserCreators> {
  return privateUsersApi.usersUserIdCreatorsGet(userId).then(r => apiResponseAdHoc(r.data));
}

export async function updateChallengeStepResult({
  stepId,
  isCompleted,
  isSkipped,
  rate,
  message,
}: {
  stepId: number;
  isCompleted?: boolean;
  isSkipped?: boolean;
  rate?: number;
  message?: string;
}): Promise<{ success?: string }> {
  return privateChallengeApi
    .challengeStepsStepIdResultPost(stepId, {
      isCompleted,
      isSkipped,
      rate,
      message,
    })
    .then(r => r.data);
}

enum MadeByVariant {
  manual = 'manual',
  popular = 'popular',
}

enum CategoryItemSize {
  big = 'big',
  small = 'small',
}

type CreatorChallenge = DeepReadonly<{
  id: number;
  title: string;
  price: number;
  marketingPriceConfig: {
    iosPrice: number;
  };
  slug: string;
  userSlug: string;
  status: SPACE_STATUS;
  payment_type: SPACE_PAYMENT_TYPE;
  meta: ChallengeSpaceMeta;
  config: ChallengeSpaceConfig;
  finishAt: number;
  firstStep: {
    id: number;
    startAt: number;
  };
  stepsCount: number;
  tag: Nullable<{
    id: number;
    name: string;
  }>;
  cover: Nullable<{
    id: number;
    url: string;
  }>;
  isPurchased: boolean;
  isSubscribed: boolean;
  isTrial: boolean;
}>;

type CreatorClub = DeepReadonly<{
  id: number;
  subscriptionPrice: number;
  isSubscribed: boolean;
  isFollowed: boolean;
  challenges: ReadonlyArray<CreatorChallenge>;
  owner: {
    id: number;
    name: string;
    description: string;
    isAvatar: boolean;
    avatar: Nullable<{
      id: number;
      url: string;
    }>;
  };
  isUpvoted: boolean;
}>;

type CreatorCategory = DeepReadonly<{
  id: number;
  createdAt: number;
  updatedAt: number;
  title: string;
  madeBy: MadeByVariant;
  order: number;
  isCompilation: boolean;
  size: Nullable<CategoryItemSize>;
  cover: Nullable<{
    id: number;
    url: string;
  }>;
  clubs: ReadonlyArray<CreatorClub>;
}>;

export type CategorySpace = {
  id: number;
  slug: string;
  userSlug: string;
  title: string;
  price: number;
  type: SPACE_TYPE;
  payment_type: SPACE_PAYMENT_TYPE;
  config: ChallengeSpaceConfig | LiveSpaceConfig | ChannelSpaceConfig;
  marketingPriceConfig: {
    iosPrice: number;
  };
  expert: {
    id: number;
    name: string;
    description: string | null;
    isAvatar: boolean;
    avatar: Nullable<{
      id: number;
      url: string;
    }>;
  };
  tag: Nullable<{
    id: number;
    name: string;
  }>;
  tags: Nullable<
    ReadonlyArray<{
      id: number;
      name: string;
      weight: number;
      color: KnownSkillColor;
    }>
  >;
  cover: Nullable<{
    id: number;
    url: string;
  }>;
  firstStep: {
    id: number;
    startAt: number;
  };
  stepsCount: number;
  isPurchased: boolean;
  isSubscribed: boolean;
  isFollowed: boolean;
  isTrial: boolean;
  isAddedToHome: boolean;
  iosProductId: string;
};

export type SpaceCategory = {
  id: number;
  createdAt: number;
  updatedAt: number;
  title: string;
  madeBy: MadeByVariant;
  size: Nullable<CategoryItemSize>;
  cover: Nullable<{
    id: number;
    url: string;
  }>;
  order: number;
  isCompilation: boolean;
  spaces: CategorySpace[];
};

export async function getDiscoverCreatorsCategories(): Promise<ReadonlyArray<CreatorCategory>> {
  // каст потому, что не хватает нескольких полей в доке api
  return privateDiscoverApi.discoverClubsCategoriesGet().then(r => r.data as unknown as ReadonlyArray<CreatorCategory>);
}

export async function getDiscoverCreatorsCategoryById(id: number): Promise<CreatorCategory> {
  // каст потому, что не хватает нескольких полей в доке api
  return privateDiscoverApi.discoverClubsCategoriesCategoryIdGet(id).then(r => r.data as unknown as CreatorCategory);
}

export async function getDiscoverChallengesCategories(): Promise<SpaceCategory[]> {
  // каст потому, что не хватает нескольких полей в доке api
  return privateDiscoverApi.discoverSpacesCategoriesGet().then(r => r.data as unknown as SpaceCategory[]);
}

export async function followClub(id: number): Promise<unknown> {
  return privateClubsApi.clubsClubIdFollowPost(id).then(r => r.data);
}

export async function unfollowClub(id: number): Promise<unknown> {
  return privateClubsApi.clubsClubIdUnfollowPost(id).then(r => r.data);
}

export async function startChallengeTrial({ id }: { id: number }): Promise<unknown> {
  return privateSpacesApi.spacesSpaceIdChallengeTrialPost(id).then(r => r.data);
}

export async function addTrailerToHome({ id }: { id: number }): Promise<unknown> {
  return privateSpacesApi.spacesSpaceIdAddTrailerToHomePost(id).then(r => r.data);
}
