import { uploadFileToAWS } from '@sp/data/bif';
import { createKeyValueStore, generateUniqueString, isNullish } from '@sp/util/helpers';
import { createEffect, createEvent, EventPayload, merge, sample } from 'effector';
import { condition } from 'patronum';

type UploadedFile = {
  id: number;
  url: string;
};

type MaybePromise<T> = Promise<T> | T;

export function createFileUploader<Meta = never>(
  prepareFn?: (file: File) => MaybePromise<File>,
  metaFn?: Meta extends never ? never : (file: File) => MaybePromise<Meta>,
) {
  const uploads = createKeyValueStore<{ abortController: AbortController }>(`file-uploader-${generateUniqueString()}`);

  const uploadFx = createEffect(
    async ({ file, abortController }: { key: string; file: File; abortController: AbortController }) => {
      const preparedFile = prepareFn ? await prepareFn(file) : file;
      if (abortController.signal.aborted) throw new Error('canceled');

      const meta = metaFn ? await metaFn(preparedFile) : undefined;
      if (abortController.signal.aborted) throw new Error('canceled');

      const { url, id } = await uploadFileToAWS(preparedFile, abortController.signal);

      return {
        data: { url, id } as UploadedFile,
        meta,
      };
    },
  );

  const upload = createEvent<{ key: string; file: File }>();
  const reset = createEvent<string>();
  const resetAll = createEvent();

  const uploadAborted = merge([upload.map(payload => payload.key), reset]);

  sample({
    source: uploads.$store,
    clock: uploadAborted,
    fn: (store, key) => store[key]?.abortController,
  }).watch(abortController => abortController?.abort());

  sample({
    clock: uploadAborted,
    target: uploads.unset,
  });

  sample({
    source: uploads.$list,
    clock: resetAll,
  }).watch(list => {
    for (const { abortController } of list) {
      abortController.abort();
    }
  });

  sample({
    clock: resetAll,
    target: uploads.reset,
  });

  sample({
    source: uploads.$store,
    clock: upload,
    filter: (store, { key }) => isNullish(store[key]),
    fn: (store, { key }) => key,
    target: uploads.set.prepend((key: string) => ({ key, value: { abortController: new AbortController() } })),
  });

  sample({
    source: uploads.$store,
    clock: upload,
    fn: (store, { key, file }) => {
      const abortController = store[key]?.abortController;

      if (!abortController) {
        throw new Error(`No abort controller for ${key}. State: ${JSON.stringify(store, undefined, '\t')}`);
      }

      return { key, file, abortController };
    },
    target: uploadFx,
  });

  sample({
    clock: uploadFx.finally,
    fn: ({ params }) => params.key,
    target: reset,
  });

  const uploadCanceled = createEvent<{ params: EventPayload<typeof upload>; error: Error }>();
  const uploadFailed = createEvent<{ params: EventPayload<typeof upload>; error: Error }>();

  condition({
    source: uploadFx.fail.map(({ error, params: { file, key } }) => ({
      params: { key, file },
      error: error,
    })),
    if: ({ error }) => error?.message === 'canceled',
    then: uploadCanceled,
    else: uploadFailed,
  });

  return {
    upload,
    reset,
    resetAll,
    uploadFinished: uploadFx.done.map(({ params: { key }, result }) => ({ result, key })),
    uploadCanceled,
    uploadFailed,
    $isUploading: uploadFx.pending,
    $loadingKeys: uploads.$keys,
  } as const;
}

export function createSingleFileUploader<Meta = never>(key: string, uploader = createFileUploader<Meta>()) {
  return {
    upload: uploader.upload.prepend((file: File) => ({ key, file })),
    reset: uploader.reset.prepend(() => key),
    finished: uploader.uploadFinished.filterMap(payload => (payload.key === key ? payload.result : undefined)),
    failed: uploader.uploadFailed.filterMap(({ params, error }) =>
      params.key === key ? { params: params.file, error } : undefined,
    ),
    canceled: uploader.uploadCanceled.filterMap(({ params, error }) =>
      params.key === key ? { params: params.file, error } : undefined,
    ),
    $isUploading: uploader.$loadingKeys.map(keys => keys.includes(key)),
  };
}
