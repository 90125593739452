import { compress, decompress } from './compression';

// Enum values are numeric to reduce the size of the serialized data.
export const enum SerializationFormat {
  Plain = 0,
  Compressed = 1,
}

/**
 * Serialize the given data to a string.
 * Property names are kept short to reduce the size of the serialized data.
 * @param f serialization format.
 * @param d serialized data.
 */
export interface SerializedContainer {
  readonly f: SerializationFormat;
  readonly d: string;
}

function isNonNullObject(value: unknown): value is { [key: string]: unknown | undefined } {
  return value !== null && typeof value === 'object';
}

export function isSerializedContainer(value: unknown): value is SerializedContainer {
  return isNonNullObject(value) && 'f' in value && 'd' in value && typeof value['d'] === 'string';
}

export function serialize(data: string, format: SerializationFormat): SerializedContainer {
  switch (format) {
    case SerializationFormat.Plain:
      return { f: format, d: data };
    case SerializationFormat.Compressed:
      return { f: format, d: compress(data) };
    default: {
      throw new Error('serialize: unknown format:', format);
    }
  }
}

export function deserialize({ d, f }: SerializedContainer): string {
  switch (f) {
    case SerializationFormat.Plain:
      return d;
    case SerializationFormat.Compressed:
      return decompress(d);
    default: {
      console.error('deserialize: unknown format:', f);
      return d;
    }
  }
}
