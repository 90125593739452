import { IconFile, IconFilePdf, IconFileText, IconFileXls, IconFileZip, IconMusicNotes, IconProps } from '@sp/ui/icons';
import { RESOLVED_FILE_TYPE, resolveFileType } from '@sp/util/files';
import { FileAttachment } from '@sp/util/stream-chat';
import { FC, VFC } from 'react';

const typeIconsMap: Record<RESOLVED_FILE_TYPE, VFC<IconProps>> = {
  xls: IconFileXls,
  pdf: IconFilePdf,
  zip: IconFileZip,
  text: IconFileText,
  audio: IconMusicNotes,
  image: IconFile,
  video: IconFile,
  unknownFile: IconFile,
};

export const FileAttachmentIconPart: FC<
  {
    type: string; // MIME type
  } & IconProps
> = ({ type, ...props }) => {
  const resolvedType = resolveFileType({ type });
  const IconComponent = typeIconsMap[resolvedType];

  return <IconComponent {...props} />;
};

export const FileAttachmentNamePart: FC<{
  name: string;
}> = ({ name }) => {
  return (
    <div className="text-xs flex-auto min-w-0 max-w-full">
      <div className="font-semibold text-ellipsis whitespace-nowrap overflow-hidden">{name}</div>
    </div>
  );
};

export const AttachmentFileView: FC<{
  attachment: FileAttachment;
}> = ({ attachment }) => {
  return (
    <a
      href={attachment.url}
      rel="noopener noreferrer"
      download={attachment.name}
      target="_blank"
      className="w-full flex items-center gap-2 h-16 p-4 overflow-hidden rounded-2.5xl bg-stripe cursor-pointer no-underline"
    >
      <FileAttachmentIconPart type={attachment.fileType} size={24} />
      <FileAttachmentNamePart name={attachment.name} />
    </a>
  );
};
