import { GlobalMediaPlayerModel, MediaPlayersCache } from '@sp/data/global';
import { AudioHistogram } from '@sp/ui/elements';
import { IconPause, IconPlay } from '@sp/ui/icons';
import { ViewAudioAttachment } from '@sp/util/chat-attachments';
import { getMediaDurationString } from '@sp/util/format';
import { useStore } from 'effector-react';
import { ReactElement, useCallback, useMemo } from 'react';

export function AttachmentAudioView({
  attachment,
  authorName,
}: {
  attachment: ViewAudioAttachment;
  authorName: string;
}): ReactElement {
  const duration = useMemo(() => getMediaDurationString(attachment.duration), [attachment.duration]);
  const mediaPlayer = useMemo(
    () => MediaPlayersCache.get(attachment.fileDataUrl ?? attachment.url),
    [attachment.fileDataUrl, attachment.url],
  );
  const { $paused, $progress, play, pause, setProgress } = mediaPlayer;
  const paused = useStore($paused);
  const progress = useStore($progress);

  const togglePlay = useCallback(() => {
    if (!paused) {
      pause();
    } else {
      GlobalMediaPlayerModel.pause();
      GlobalMediaPlayerModel.setPlayer({ player: mediaPlayer, meta: { authorName } });
      play();
    }
  }, [authorName, mediaPlayer, pause, paused, play]);

  return (
    <div className="w-full h-16 p-2 overflow-hidden rounded-2.5xl bg-stripe">
      <div className="flex justify-between items-center gap-2">
        <button
          onClick={togglePlay}
          type="button"
          className="flex flex-none items-center justify-center h-8 w-8 bg-accent rounded-full text-active"
        >
          {!paused ? <IconPause size={16} /> : <IconPlay filled size={16} />}
        </button>
        <AudioHistogram peaks={attachment.histogram ?? []} progress={progress} onSetProgress={setProgress} />
      </div>

      <div className="text-xs ml-10">{duration}</div>
    </div>
  );
}
