import { Nullable } from '@sp/util/helpers';
import { ChatNonFormatMessage } from '@sp/util/stream-chat';
import { ChatsApiFactory, CreatorsApiFactory, GsApiFactory, UsersApiFactory } from '../api/chat';
import { ChatTeaser, Creator, DmChatTeaser } from '../chat.types';
import { apiResponseAdHoc } from './helpers';

export enum ChatMessageEventTypes {
  reactions = 'reaction',
  mentions = 'mention',
  reply = 'reply',
}

export type ChatEventsRes = { events: ChatBackEvent[]; unviewedCount: number };

export type ChatMessageEvent = ChatMessageReactionEvent | ChatMessageMentionEvent;

export type ChatMessageReactionEvent = {
  id?: number;
  createdAt: number;
  reactionType: string;
  type: ChatMessageEventTypes.reactions;
};

export type ChatMessageMentionEvent = {
  id?: number;
  createdAt: number;
  type: ChatMessageEventTypes.mentions | ChatMessageEventTypes.reply;
};

export type ChatEvent = {
  events: ChatMessageEvent;
  deleted: boolean;
  message: ChatNonFormatMessage;
} & ChatBaseEvent;

export type ChatBackEvent = {
  events: ChatMessageEvent[];
  message: Nullable<ChatNonFormatMessage>;
} & ChatBaseEvent;

type ChatBaseEvent = {
  userId: number;
  chatId: number;
  messageId: string;
  createdAt: number;
};

const chatsApi = ChatsApiFactory();
const creatorsApi = CreatorsApiFactory();
const streamChatApi = GsApiFactory();
const usersApi = UsersApiFactory();

export async function getChatTeasers(
  { force }: { force: boolean } = { force: false },
): Promise<ReadonlyArray<ChatTeaser>> {
  return chatsApi.chatsGet(force).then(r =>
    r.data.map(
      v =>
        ({
          ...apiResponseAdHoc(v),
          lastMessage: null,
        } as ChatTeaser),
    ),
  );
}

export async function getCreators(): Promise<ReadonlyArray<Creator>> {
  return creatorsApi.creatorsGet().then(r => apiResponseAdHoc(r.data));
}

export async function getChatEvents(chatId: number): Promise<ChatEventsRes> {
  return chatsApi.chatsChatIdEventsGet(chatId).then(({ data }) => apiResponseAdHoc(data)) as unknown as ChatEventsRes;
}

export async function readChatEvents(chatId: number) {
  return chatsApi.chatsChatIdEventsReadPost(chatId).then(({ data }) => apiResponseAdHoc(data));
}

export async function getStreamChatToken(): Promise<string> {
  return streamChatApi.gsTokenGet().then(r => (r.data as { accessToken: string }).accessToken);
}

export function getDm(usersIds: number[]): Promise<DmChatTeaser> {
  return usersApi.usersDmPost({ usersIds }).then(({ data }) => apiResponseAdHoc(data) as DmChatTeaser);
}
