import { useIsUserInsideOwnExpertRoute } from '@sp/data/global';
import { useIsInsidePath } from '@sp/util/helpers';
import { ROUTES } from '@sp/util/router';

const enum NavTab {
  home = 'home',
  discover = 'discover',
  people = 'people',
  profile = 'profile',
}

export function useResolveActiveNavTab(): NavTab {
  const isInsideHome = useIsInsidePath(ROUTES.HOME_URL);
  const isInsideDiscover = useIsInsidePath(ROUTES.DISCOVER_URL);
  const isInsidePeople = useIsInsidePath(ROUTES.PEOPLE_URL);
  const isInsideProfile = useIsInsidePath(ROUTES.PROFILE_URL);
  const isInsideStudio = useIsInsidePath(ROUTES.STUDIO_URL);

  const isUserInsideOwnExpert = useIsUserInsideOwnExpertRoute();
  const isInsideExpert = useIsInsidePath(ROUTES.EXPERT_PAGE_URL);

  if (!isUserInsideOwnExpert && (isInsideHome || isInsideExpert)) {
    return NavTab.home;
  }

  if ((isUserInsideOwnExpert && isInsideExpert) || isInsideProfile || isInsideStudio) {
    return NavTab.profile;
  }

  if (isInsideDiscover) {
    return NavTab.discover;
  }

  if (isInsidePeople) {
    return NavTab.people;
  }

  return NavTab.home;
}
