import { getResponsiveAWSImageSrc, RETINA_PIXEL_DENSITY } from '@sp/util/files';
import { cls } from '@sp/util/helpers';
import { CSSProperties, ReactElement, useMemo } from 'react';

export const AVATAR_CLIP_PATH =
  'm25.39303,1.01037c-13.15954,0.9775 -23.3651,11.12292 -24.35303,24.17254c-0.54352,7.1789 -0.96418,15.68521 -0.96418,24.81709c0,9.13189 0.42065,17.63819 0.96418,24.81709c0.98793,13.04962 11.19349,23.19528 24.35303,24.17229c7.10901,0.52816 15.52557,0.93408 24.60697,0.93408c9.0814,0 17.49786,-0.40592 24.60687,-0.93408c13.15964,-0.97701 23.36555,-11.12267 24.35273,-24.17229c0.54351,-7.1789 0.96457,-15.68521 0.96457,-24.81709c0,-9.13189 -0.42106,-17.63819 -0.96457,-24.81709c-0.98718,-13.04962 -11.19309,-23.19504 -24.35273,-24.17254c-7.10901,-0.52816 -15.52548,-0.93383 -24.60687,-0.93383c-9.0814,0 -17.49795,0.40567 -24.60697,0.93383z';

type SizeAlias = 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl';

export interface AvatarProps {
  readonly src?: string | null | undefined;
  readonly name?: string;
  readonly increased?: boolean;
  readonly border?: boolean;
  readonly size?: SizeAlias | number;
  readonly outline?: boolean;
  readonly className?: string;
}

const aliases: Record<SizeAlias, number> = {
  xxs: 24,
  xs: 32,
  sm: 40,
  md: 64,
  lg: 92,
  xl: 150,
};

function resolve(size: SizeAlias | number): number {
  if (typeof size === 'number') {
    return size;
  } else {
    return aliases[size];
  }
}

export function Avatar({
  size = 'md',
  src,
  name,
  increased = false,
  border = false,
  outline = false,
  className,
}: AvatarProps): ReactElement {
  const resolvedSize = useMemo(() => resolve(size), [size]);

  const sizeStyles: CSSProperties = useMemo(() => {
    const scaledSize = increased ? Math.round(resolvedSize * 1.3) : resolvedSize;
    return { width: scaledSize, height: scaledSize };
  }, [increased, resolvedSize]);

  const normalizedSrc =
    src != null && src.length > 0
      ? getResponsiveAWSImageSrc(src, resolvedSize * RETINA_PIXEL_DENSITY)
      : '/images/avatar-placeholder.png';

  return (
    <div
      className={cls(
        'flex-none block box-content relative',
        border ? 'rounded-full border-white border-2 ' : '',
        outline
          ? `rounded-full shadow-outline after:w-full after:h-full after:border-2 after:absolute after:-top-0.5 after:-left-0.5 after:border-white after:rounded-full after:box-content`
          : '',
        className,
      )}
      style={sizeStyles}
    >
      <div className="w-full aspect-square aspect-ratio-wrap">
        <img className="rounded-full object-cover overflow-hidden" src={normalizedSrc} alt={name} />
      </div>
    </div>
  );
}
