import { USER_MODEL, useUser } from '@sp/data/auth';
import { LoaderBlock } from '@sp/ui/elements';
import { Modal } from '@sp/ui/modal';
import { combine } from 'effector';
import { useStore } from 'effector-react';
import { FC, useEffect } from 'react';
import PullToRefresh from 'react-simple-pull-to-refresh';
import { CREATOR_EARNINGS_MODEL, CreatorIncome } from './creator-income';
import { EditUserInfoForm } from './edit-user-info-form';
import { Links, LINKS_MODEL } from './links';
import { OWNED_PROFILE_MODEL } from './owned-profile-model';
import { ProfileHeader } from './profile-header';
import { ProfileSettings } from './profile-settings';
import { OwnedVerifiedBadge } from './verified-badge';

async function onRefreshProfile(isCreator: boolean) {
  USER_MODEL.forceFetch();
  LINKS_MODEL.linksLoadStarted();
  isCreator && CREATOR_EARNINGS_MODEL.earnings.load();
  return new Promise<void>(resolve => {
    setTimeout(() => {
      const { unsubscribe } = combine([
        USER_MODEL.$isFetching,
        OWNED_PROFILE_MODEL.$isSkillsLoading,
        ...[isCreator ? [CREATOR_EARNINGS_MODEL.earnings.$isLoading] : []],
      ]).watch(conditions => {
        if (conditions.every(condition => !condition)) {
          resolve();
          unsubscribe();
        }
      });
    });
  });
}

export const OwnedProfileLayout: FC = () => {
  const user = useUser();
  const isUpdating = useStore(OWNED_PROFILE_MODEL.$isUpdating);
  // const subscriptions = useStore(USER_CREATORS_MODEL.$subscriptions) || [];
  // const purchases = useStore(USER_CREATORS_MODEL.$purchases) || [];
  const isEditProfileModalOpen = useStore(OWNED_PROFILE_MODEL.$isShowEditProfileForm);
  const isCreator = user.isExpert;

  useEffect(() => LINKS_MODEL.linksLoadStarted(), []);

  return (
    <PullToRefresh
      onRefresh={() => onRefreshProfile(isCreator)}
      pullingContent={<></>}
      refreshingContent={
        <div className="flex items-center justify-center h-10">
          <LoaderBlock />
        </div>
      }
      pullDownThreshold={40}
    >
      <div className="h-full mobile-pan">
        <ProfileHeader
          created_at={user?.created_at}
          isLoading={false}
          userName={user?.name}
          bio={user?.bio}
          avatar={user?.avatar?.url}
          owned
          onEditProfile={OWNED_PROFILE_MODEL.showEditForm}
        />

        {isCreator ? (
          <>
            <div className="px-6 mb-6">
              {/* FIXME: кнопка share будет на челлендже, в бейдже шера не будет https://app.clickup.com/t/34w9zbu */}
              <OwnedVerifiedBadge />
            </div>
            <hr className="mb-6 border-stripe w-full" />
            <CreatorIncome />
          </>
        ) : null}

        {/* {isCreator ? <ProfileExplore /> : null} */}

        {/* <UserCreatorsLayout
          addMentorSub={subscriptions.length === 0}
          showSkeleton
          items={subscriptions}
          type="subscriptions"
        />
        <UserCreatorsLayout addMentorSub={false} showSkeleton items={purchases} type="purchases" /> */}

        <hr className="border-stripe w-full" />
        <div className="px-6">
          <Links editable={true} />
        </div>
        <hr className="mt-2 border-stripe w-full" />
        <div className="px-6 mb-6">
          <ProfileSettings isAdmin={user.isAdmin} />
        </div>

        <Modal
          isOpen={isEditProfileModalOpen}
          withBackdrop
          closeOnBackdropClick
          onClose={OWNED_PROFILE_MODEL.hideEditForm}
        >
          <EditUserInfoForm
            user={user}
            onSubmit={OWNED_PROFILE_MODEL.updateUser}
            onCancel={OWNED_PROFILE_MODEL.hideEditForm}
            isUpdating={isUpdating}
          />
        </Modal>
      </div>
    </PullToRefresh>
  );
};
