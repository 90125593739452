import { ChatFormatMessage } from '@sp/util/stream-chat';
import { createContext, ReactNode, useContext } from 'react';

export type MessageExtraContent = {
  beforeText?: (message: ChatFormatMessage) => ReactNode;
  afterAvatar?: (message: ChatFormatMessage) => ReactNode;
  afterUserName?: (user: ChatFormatMessage['user']) => ReactNode;
  platformForm?: (message: ChatFormatMessage) => ReactNode;
  showEditButton?: (message: ChatFormatMessage) => boolean;
};

export const MessageExtraContentContext = createContext<
  MessageExtraContent | Record<keyof MessageExtraContent, undefined>
>({
  afterAvatar: undefined,
  beforeText: undefined,
  afterUserName: undefined,
  platformForm: undefined,
  showEditButton: undefined,
});

export const useMessageExtraContent = () => useContext(MessageExtraContentContext);
