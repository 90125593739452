import { ChatMessageAvatar, ChatMessageHeader, ChatMessageProvider, mockChatModel } from '@sp/feature/chat';
import {
  ChatMessageBodyContainer,
  ChatMessageContainer,
  ChatMessageLeftContainer,
  ChatMessageRightContainer,
} from '@sp/ui/chat';
import { formatChatMessage } from '@sp/util/stream-chat';
import { formatISO } from 'date-fns';
import { useStore } from 'effector-react';
import { FC, useMemo } from 'react';
import { CHALLENGE_MODEL } from '../../challenge-model';
import { ChallengeStepWithUI } from '../../steps';
import { StepFormPane } from '../step-form-pane';
import { POSTS_MODEL } from './posts-model';
import { createMockMessage, PostsView } from './posts-view';

const PostWithForm: FC<{ stepWithUI: ChallengeStepWithUI }> = ({ stepWithUI }) => {
  const challenge = useStore(CHALLENGE_MODEL.$challenge);
  const mockMessage = createMockMessage({ text: '' }, challenge?.creator, formatISO(stepWithUI.step.startAt));
  const message = useMemo(() => formatChatMessage(mockMessage), [mockMessage]);
  const contextValue = useMemo(() => ({ message, model: mockChatModel() }), [message]);
  return (
    <div>
      <ChatMessageProvider value={contextValue}>
        <ChatMessageContainer>
          <ChatMessageLeftContainer>
            <ChatMessageAvatar />
          </ChatMessageLeftContainer>

          <ChatMessageRightContainer>
            <ChatMessageHeader />

            <ChatMessageBodyContainer>
              <StepFormPane stepWithUI={stepWithUI} />
            </ChatMessageBodyContainer>
          </ChatMessageRightContainer>
        </ChatMessageContainer>
      </ChatMessageProvider>
    </div>
  );
};

export const StepPosts: FC<{ stepWithUI: ChallengeStepWithUI }> = ({ stepWithUI }) => {
  const posts = useStore(POSTS_MODEL.$postsMap)[stepWithUI.step.id] ?? [];
  const challenge = useStore(CHALLENGE_MODEL.$challenge);
  return (
    <>
      <PostWithForm stepWithUI={stepWithUI} />
      <PostsView posts={posts} startAt={stepWithUI.step.startAt} isProgramPost={!!challenge.isProgram} />
    </>
  );
};
