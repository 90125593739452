import { differenceInSeconds } from 'date-fns';
import { FC } from 'react';
import { useTime } from 'react-time-sync';
import { Interval } from 'time-sync/constants';

export const Timer: FC<{
  refPoint: number | Date; // Reference point to count down to or up from
  diffPoint?: number | Date; // If set, will show difference between refPoint and diffPoint
}> = ({ refPoint, diffPoint }) => {
  const now = useTime({ interval: Interval.SECONDS }) * 1000;
  const diff = Math.abs(differenceInSeconds(refPoint, diffPoint ?? now));
  const days = Math.floor(diff / (60 * 60 * 24));
  const hours = Math.floor((diff % (60 * 60 * 24)) / (60 * 60));
  const minutes = Math.floor((diff % (60 * 60)) / 60);
  const seconds = Math.floor(diff % 60);
  const daysStr = days > 0 ? `${days}d ` : '';
  const hoursStr = days > 0 || hours > 0 ? `${hours}h ` : '';
  const minutesStr = days > 0 || hours > 0 || minutes > 0 ? `${minutes}m ` : '';
  const secondsStr = days > 0 || hours > 0 || minutes > 0 || seconds > 0 ? `${seconds}s` : '';
  return (
    <>
      {daysStr}
      {hoursStr}
      {minutesStr}
      {secondsStr}
    </>
  );
};
