import { z } from 'zod';
import { SelfplusUsersApiFactory } from '../../api/default';
import { apiResponseAdHoc } from '../helpers';

const selfplusUsersApi = SelfplusUsersApiFactory();

export const SelfPlusAdmin = z.object({
  id: z.number().int(),
  name: z.string(),
  slug: z.string(),
  avatar: z
    .object({
      url: z.string().url(),
      id: z.number().int(),
    })
    .nullable(),
});

export type SelfPlusAdmin = z.infer<typeof SelfPlusAdmin>;

export async function getSelfPlusAdmins(): Promise<SelfPlusAdmin[]> {
  return selfplusUsersApi.selfplusUsersGet().then(r => apiResponseAdHoc(r.data));
}
