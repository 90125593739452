import { addDays, differenceInDays, getDaysInMonth, parse, startOfMonth, startOfYear } from 'date-fns';
import random from 'lodash/random';
import { loremIpsum } from 'lorem-ipsum';
import { Income, IncomeType, Period } from './types';

function createRandomUser() {
  const id = random(5000, 500000);
  return {
    id,
    name: loremIpsum({
      count: 2,
      units: 'words',
    })
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' '),
    avatar: {
      id: Math.random(),
      url: `/images/avatar-placeholder.png`,
    },
  };
}

function createRandomChallenge() {
  const id = random(5000, 500000);
  return {
    id,
    title: [
      loremIpsum({
        count: 3,
        units: 'words',
      }),
    ]
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(''),
    price: random(5, 30) * 100,
    startAt: addDays(new Date(), random(-30, 30)).getTime(),
    stepsCount: random(5, 14),
  };
}

function createRandomSubscription() {
  const id = random(5000, 500000);
  return {
    id,
    title: [
      loremIpsum({
        count: 4,
        units: 'words',
      }),
    ]
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(''),
    price: random(5, 30) * 100,
  };
}

function getRandomIncomeType(): IncomeType {
  const types = [IncomeType.challenge, IncomeType.subscription, IncomeType.follow];
  return types[random(0, types.length - 1)];
}

export function mockIncomes(period: Period): ReadonlyArray<Income> {
  const usersCount = period === 'overall' ? random(100, 500) : random(30, 80);
  const challengesCount = period === 'overall' ? random(5, 20) : random(2, 6);
  const subscriptionsCount = period === 'overall' ? random(5, 20) : random(2, 6);

  const users = Array.from({ length: usersCount }, () => createRandomUser());
  const challenges = Array.from({ length: challengesCount }, () => createRandomChallenge());
  const subscriptions = Array.from({ length: subscriptionsCount }, () => createRandomSubscription());

  const now = Date.now();
  const daysCount =
    period === 'overall'
      ? differenceInDays(now, startOfYear(now))
      : period === 'this-month'
      ? differenceInDays(now, startOfMonth(now))
      : getDaysInMonth(parse(period, 'MM-yyyy', now));

  const startAt =
    period === 'overall'
      ? startOfYear(now)
      : period === 'this-month'
      ? startOfMonth(now)
      : startOfMonth(parse(period, 'MM-yyyy', now));

  return Array.from({ length: daysCount }, (_, dayIndex) => {
    return Array.from({ length: random(2, 15) }, __ => {
      const type = getRandomIncomeType();
      const user = users[random(0, users.length - 1)];
      const createdAt = addDays(startAt, dayIndex).getTime();
      const id = random(5000, 500000);

      if (type === IncomeType.challenge) {
        const challenge = challenges[random(0, challenges.length - 1)];
        return {
          id,
          createdAt,
          user,
          type,
          amount: challenge.price,
          challenge: {
            id: challenge.id,
            title: challenge.title,
            startAt: challenge.startAt,
            stepsCount: challenge.stepsCount,
          },
        };
      }

      if (type === IncomeType.subscription) {
        const subscription = subscriptions[random(0, subscriptions.length - 1)];
        return {
          id,
          createdAt,
          user,
          type,
          chanel: {
            id: subscription.id,
            title: subscription.title,
          },
          amount: subscription.price,
        };
      }

      const subscription = subscriptions[random(0, subscriptions.length - 1)];
      return {
        id,
        createdAt,
        user,
        chanel: {
          id: subscription.id,
          title: subscription.title,
        },
        type,
      };
    });
  }).flat();
}
