import { MediaPlayer } from '@sp/data/global';
import { IconClose, IconPause, IconPlay } from '@sp/ui/icons';
import { UNKNOWN_MESSAGE_AUTHOR } from '@sp/util/teaser';
import { useStore } from 'effector-react';
import { ReactElement, useCallback } from 'react';

export function ChatActiveAudioPanel({
  player,
  authorName,
  onClose,
}: {
  player: MediaPlayer;
  authorName?: string;
  onClose: () => void;
}): ReactElement {
  const { $paused, play, pause } = player;
  const paused = useStore($paused);

  const togglePlay = useCallback(() => {
    if (!paused) {
      pause();
    } else {
      play();
    }
  }, [pause, paused, play]);

  return (
    <div className="w-full flex justify-between py-2 px-5 bg-white border-b border-stripe shadow-light">
      <button onClick={togglePlay} type="button">
        {!paused ? <IconPause size={16} /> : <IconPlay filled size={16} />}
      </button>

      <span className="text-xs">{authorName ?? UNKNOWN_MESSAGE_AUTHOR}</span>

      <button onClick={onClose} type="button">
        <IconClose size={16} />
      </button>
    </div>
  );
}
