import { UpdateFrequency } from '@sp/data/bif';
import { Input, TextArea } from '@sp/ui/elements';
import { ImageCropperMode } from '@sp/ui/image-cropper';
import { ADAPTER_FACTORIES, ADAPTERS, bindFieldValue, bindFieldValueWithAdapter } from '@sp/util/effector-forms';
import { ConnectedField, useForm } from 'effector-forms';
import { useStore } from 'effector-react';
import { ReactElement, VFC } from 'react';
import { ImagePicker } from '../utils/image-picker';
import { CHANNEL_FORM_MODEL } from './editor-form-model';

function getInvalidClassName<T>(field: ConnectedField<T>) {
  return field.isValid ? '' : 'ring-1 ring-alert';
}

const updateFrequencyAdapter = ADAPTER_FACTORIES.stringOptions(Object.values(UpdateFrequency));

function FieldError<T>({ field }: Readonly<{ field: ConnectedField<T> }>): ReactElement | null {
  if (field.isValid) {
    return null;
  }
  return <span className="text-alert">{field.firstError?.errorText}</span>;
}

export const ChannelEditorForm: VFC = () => {
  const details = useForm(CHANNEL_FORM_MODEL.form);

  return (
    <div className="flex gap-4 flex-col md:flex-row">
      <div className="flex flex-col basis-1/2">
        <div className="flex flex-col break-inside-avoid-column">
          <label htmlFor="title">Title</label>
          <Input
            id="title"
            type="text"
            placeholder="My channel"
            className={getInvalidClassName(details.fields.title)}
            {...bindFieldValue(details.fields.title)}
          />
          <FieldError field={details.fields.title} />
        </div>

        <div className="flex flex-col break-inside-avoid-column mt-4">
          <label htmlFor="slug">Slug</label>
          <Input
            id="slug"
            type="text"
            placeholder="my-channel"
            className={getInvalidClassName(details.fields.slug)}
            {...bindFieldValue(details.fields.slug)}
          />
          <FieldError field={details.fields.slug} />
        </div>

        <div className="flex flex-col break-inside-avoid-column mt-4">
          <label className="mb-1">Cover</label>
          <ImagePicker
            currentUrl={details.fields.cover.value?.url}
            onChange={CHANNEL_FORM_MODEL.uploader.cover.upload}
            isLoading={useStore(CHANNEL_FORM_MODEL.uploader.cover.$isUploading)}
            cropMode={ImageCropperMode.squareImage}
          />
          {!details.fields.cover.isValid && <span className="text-alert mt-4">Cover is required.</span>}
        </div>

        <div className="flex flex-col break-inside-avoid-column mt-4">
          <label htmlFor="price">Price (USD $)</label>
          <Input
            id="price"
            type="number"
            min="0.01"
            step="0.01"
            placeholder="4,99"
            className={getInvalidClassName(details.fields.price)}
            {...bindFieldValueWithAdapter(details.fields.price, ADAPTERS.money)}
          />
          <FieldError field={details.fields.price} />
        </div>

        <div className="flex flex-col break-inside-avoid-column mt-4">
          <label htmlFor="about-creator">About Creator</label>
          <TextArea
            id="about-creator"
            minRows={4}
            maxRows={10}
            className={getInvalidClassName(details.fields.creatorDescription)}
            {...bindFieldValue(details.fields.creatorDescription)}
          />
          <FieldError field={details.fields.creatorDescription} />
        </div>

        <div className="flex flex-col break-inside-avoid-column mt-4">
          <label htmlFor="about-channel">About Channel</label>
          <TextArea
            id="about-channel"
            minRows={4}
            maxRows={10}
            className={getInvalidClassName(details.fields.description)}
            {...bindFieldValue(details.fields.description)}
          />
          <FieldError field={details.fields.description} />
        </div>

        <div className="flex flex-col break-inside-avoid-column mt-4">
          <label htmlFor="target-audience">Target audience</label>
          <TextArea
            id="target-audience"
            minRows={4}
            maxRows={10}
            className={getInvalidClassName(details.fields.targetAudience)}
            {...bindFieldValue(details.fields.targetAudience)}
          />
          <FieldError field={details.fields.targetAudience} />
        </div>
        <div className="flex flex-col break-inside-avoid-column mt-4">
          <label htmlFor="content-format">Content format</label>
          <TextArea
            id="content-format"
            minRows={4}
            maxRows={10}
            className={getInvalidClassName(details.fields.contentFormat)}
            {...bindFieldValue(details.fields.contentFormat)}
          />
          <FieldError field={details.fields.contentFormat} />
        </div>
        <div className="flex flex-col break-inside-avoid-column mb-4 mt-4">
          <label htmlFor="update-frequency">Update frequency</label>
          <select
            id="content-format"
            className={getInvalidClassName(details.fields.updateFrequency)}
            {...bindFieldValueWithAdapter<UpdateFrequency, string>(
              details.fields.updateFrequency,
              updateFrequencyAdapter,
            )}
          >
            <option value={UpdateFrequency.daily}>Daily</option>
            <option value={UpdateFrequency.twoThreePerWeek}>Two three per week</option>
            <option value={UpdateFrequency.weekly}>weekly</option>
            <option value={UpdateFrequency.twoThreePerMonth}>Two three per month</option>
            <option value={UpdateFrequency.occasionally}>Occasionally</option>
          </select>
          <FieldError field={details.fields.updateFrequency} />
        </div>
      </div>
    </div>
  );
};
