import { getMe, MeUser } from '@sp/data/bif';
import { createLocalStorageCache, registerLocalStorageCache } from '@sp/util/cache';
import { snd } from '@sp/util/helpers';
import { identifyUser } from '@sp/util/native';
import { createEffect, createEvent, createStore, sample } from 'effector';

const userCache = createLocalStorageCache(MeUser, 'sp_user');
registerLocalStorageCache(userCache.key);

export const isUserSet = (user: MeUser | null): user is MeUser => user != null;

const patch = createEvent<Partial<MeUser>>();
const forceFetch = createEvent();
const fetch = createEvent();
const reset = createEvent();

const fetchFx = createEffect((force: boolean) => getMe({ force }));

const $isFetching = fetchFx.pending;

const initFromCacheFx = createEffect(userCache.getValue);

const $user = createStore<MeUser | null>(null)
  .on(initFromCacheFx.doneData, snd)
  .on(patch, (user, payload) => (user ? { ...user, ...payload } : user))
  .on(fetchFx.doneData, snd)
  .reset(reset);

sample({
  clock: [forceFetch.map(() => true), fetch.map(() => false)],
  target: fetchFx,
});

sample({
  source: $user,
  target: createEffect(userCache.setValue),
});

initFromCacheFx();

export const USER_MODEL = {
  $user,
  patch,
  forceFetch,
  fetch,
  $isFetching,
  reset,
};

// Init native analytics
sample({
  source: $user,
  filter: isUserSet,
  fn: user => user.id.toString(),
  target: createEffect(identifyUser),
});
