import { CreatorSpace, loadSpaceById } from '@sp/data/bif';
import { isNotNullish, Nullable, ShallowNonNullable } from '@sp/util/helpers';
import { combine, createDomain, sample } from 'effector';
import { CREATOR_INFO_MODEL } from './creator-info-model';

const challengeDomain = createDomain();

const challengeReset = challengeDomain.createEvent();

const activeChallengeIdSet = challengeDomain.createEvent<number>();

const $activeChallengeId = challengeDomain.createStore<Nullable<number>>(null).reset(challengeReset);

const $challenge = challengeDomain.createStore<Nullable<CreatorSpace>>(null).reset(challengeReset);

const loadChallengeFx = challengeDomain.createEffect(
  ({ id, map }: { id: number; map: Record<number, CreatorSpace> }) => {
    return map[id] || loadSpaceById(id);
  },
);

sample({ source: activeChallengeIdSet, target: $activeChallengeId });
sample({
  source: combine({ id: $activeChallengeId, map: CREATOR_INFO_MODEL.$challengesMap }),
  filter: (data): data is ShallowNonNullable<typeof data> => isNotNullish(data.id),
  target: loadChallengeFx,
});
sample({ source: loadChallengeFx.doneData, target: $challenge });

const $challengeLoading = loadChallengeFx.pending;

export const CHALLENGE_INFO_MODEL = { $challenge, $challengeLoading, activeChallengeIdSet, challengeReset };
