/* tslint:disable */
/* eslint-disable */
/**
 * OAuth
 * This API group handles all aspects of OAuth support.
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 *
 * @export
 * @interface OauthAppleContinuePostRequest
 */
export interface OauthAppleContinuePostRequest {
  /**
   *
   * @type {string}
   * @memberof OauthAppleContinuePostRequest
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof OauthAppleContinuePostRequest
   */
  state?: string;
  /**
   *
   * @type {OauthAppleContinuePostRequestUser}
   * @memberof OauthAppleContinuePostRequest
   */
  user?: OauthAppleContinuePostRequestUser | null;
}
/**
 *
 * @export
 * @interface OauthAppleContinuePostRequestUser
 */
export interface OauthAppleContinuePostRequestUser {
  /**
   *
   * @type {string}
   * @memberof OauthAppleContinuePostRequestUser
   */
  email?: string;
  /**
   *
   * @type {OauthAppleContinuePostRequestUserName}
   * @memberof OauthAppleContinuePostRequestUser
   */
  name?: OauthAppleContinuePostRequestUserName | null;
}
/**
 *
 * @export
 * @interface OauthAppleContinuePostRequestUserName
 */
export interface OauthAppleContinuePostRequestUserName {
  /**
   *
   * @type {string}
   * @memberof OauthAppleContinuePostRequestUserName
   */
  firstName?: string;
  /**
   *
   * @type {string}
   * @memberof OauthAppleContinuePostRequestUserName
   */
  lastName?: string;
}
/**
 *
 * @export
 * @interface OauthAppleMobileGet200Response
 */
export interface OauthAppleMobileGet200Response {
  /**
   *
   * @type {string}
   * @memberof OauthAppleMobileGet200Response
   */
  token?: string;
}

/**
 * OauthApi - axios parameter creator
 * @export
 */
export const OauthApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * <br /><br /> <b>Authentication:</b> not required
     * @summary
     * @param {OauthAppleContinuePostRequest} [oauthAppleContinuePostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    oauthAppleContinuePost: async (
      oauthAppleContinuePostRequest?: OauthAppleContinuePostRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/oauth/apple/continue`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        oauthAppleContinuePostRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <br /><br /> <b>Authentication:</b> not required
     * @summary
     * @param {string} authUri
     * @param {string} redirectUri
     * @param {object} [metadata]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    oauthAppleInitGet: async (
      authUri: string,
      redirectUri: string,
      metadata?: object,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'authUri' is not null or undefined
      assertParamExists('oauthAppleInitGet', 'authUri', authUri);
      // verify required parameter 'redirectUri' is not null or undefined
      assertParamExists('oauthAppleInitGet', 'redirectUri', redirectUri);
      const localVarPath = `/oauth/apple/init`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (authUri !== undefined) {
        localVarQueryParameter['authUri'] = authUri;
      }

      if (redirectUri !== undefined) {
        localVarQueryParameter['redirectUri'] = redirectUri;
      }

      if (metadata !== undefined) {
        localVarQueryParameter['metadata'] = metadata;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <br /><br /> <b>Authentication:</b> not required
     * @summary
     * @param {string} payload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    oauthAppleMobileGet: async (payload: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'payload' is not null or undefined
      assertParamExists('oauthAppleMobileGet', 'payload', payload);
      const localVarPath = `/oauth/apple/mobile`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (payload !== undefined) {
        localVarQueryParameter['payload'] = payload;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * This endpoint handles both login and signup depending on the state of the user account. <br /><br /> <b>Authentication:</b> not required
     * @summary This endpoint handles both login and signup depending on the state of the user account.
     * @param {string} [code]
     * @param {string} [redirectUri]
     * @param {string} [state]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    oauthFbContinueGet: async (
      code?: string,
      redirectUri?: string,
      state?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/oauth/fb/continue`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (code !== undefined) {
        localVarQueryParameter['code'] = code;
      }

      if (redirectUri !== undefined) {
        localVarQueryParameter['redirect_uri'] = redirectUri;
      }

      if (state !== undefined) {
        localVarQueryParameter['state'] = state;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <br /><br /> <b>Authentication:</b> not required
     * @summary
     * @param {string} redirectUri
     * @param {object} [metadata]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    oauthFbInitGet: async (
      redirectUri: string,
      metadata?: object,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'redirectUri' is not null or undefined
      assertParamExists('oauthFbInitGet', 'redirectUri', redirectUri);
      const localVarPath = `/oauth/fb/init`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (redirectUri !== undefined) {
        localVarQueryParameter['redirect_uri'] = redirectUri;
      }

      if (metadata !== undefined) {
        localVarQueryParameter['metadata'] = metadata;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <br /><br /> <b>Authentication:</b> not required
     * @summary
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    oauthFbMobileGet: async (token: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'token' is not null or undefined
      assertParamExists('oauthFbMobileGet', 'token', token);
      const localVarPath = `/oauth/fb/mobile`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (token !== undefined) {
        localVarQueryParameter['token'] = token;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * This endpoint handles both login and signup depending on the state of the user account. <br /><br /> <b>Authentication:</b> not required
     * @summary This endpoint handles both login and signup depending on the state of the user account.
     * @param {string} [code]
     * @param {string} [redirectUri]
     * @param {string} [state]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    oauthGoogleContinueGet: async (
      code?: string,
      redirectUri?: string,
      state?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/oauth/google/continue`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (code !== undefined) {
        localVarQueryParameter['code'] = code;
      }

      if (redirectUri !== undefined) {
        localVarQueryParameter['redirect_uri'] = redirectUri;
      }

      if (state !== undefined) {
        localVarQueryParameter['state'] = state;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * This endpoint is responsible for sending the user off to a Google webpage to authenticate. Once complete, the user will be redirected to where this request was initiated and then depending on your requirements, the user will go down the login, signup, or continue path. <br /><br /> <b>Authentication:</b> not required
     * @summary This endpoint is responsible for sending the user off to a Google webpage to authenticate. Once complete, the user will be redirected to where this request was initiated and then depending on your requirements, the user will go down the login, signup, or continue path.
     * @param {string} [redirectUri]
     * @param {object} [metadata]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    oauthGoogleInitGet: async (
      redirectUri?: string,
      metadata?: object,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/oauth/google/init`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (redirectUri !== undefined) {
        localVarQueryParameter['redirect_uri'] = redirectUri;
      }

      if (metadata !== undefined) {
        localVarQueryParameter['metadata'] = metadata;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <br /><br /> <b>Authentication:</b> not required
     * @summary
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    oauthGoogleMobileGet: async (token: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'token' is not null or undefined
      assertParamExists('oauthGoogleMobileGet', 'token', token);
      const localVarPath = `/oauth/google/mobile`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (token !== undefined) {
        localVarQueryParameter['token'] = token;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * OauthApi - functional programming interface
 * @export
 */
export const OauthApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = OauthApiAxiosParamCreator(configuration);
  return {
    /**
     * <br /><br /> <b>Authentication:</b> not required
     * @summary
     * @param {OauthAppleContinuePostRequest} [oauthAppleContinuePostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async oauthAppleContinuePost(
      oauthAppleContinuePostRequest?: OauthAppleContinuePostRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.oauthAppleContinuePost(
        oauthAppleContinuePostRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * <br /><br /> <b>Authentication:</b> not required
     * @summary
     * @param {string} authUri
     * @param {string} redirectUri
     * @param {object} [metadata]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async oauthAppleInitGet(
      authUri: string,
      redirectUri: string,
      metadata?: object,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.oauthAppleInitGet(
        authUri,
        redirectUri,
        metadata,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * <br /><br /> <b>Authentication:</b> not required
     * @summary
     * @param {string} payload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async oauthAppleMobileGet(
      payload: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OauthAppleMobileGet200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.oauthAppleMobileGet(payload, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * This endpoint handles both login and signup depending on the state of the user account. <br /><br /> <b>Authentication:</b> not required
     * @summary This endpoint handles both login and signup depending on the state of the user account.
     * @param {string} [code]
     * @param {string} [redirectUri]
     * @param {string} [state]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async oauthFbContinueGet(
      code?: string,
      redirectUri?: string,
      state?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OauthAppleMobileGet200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.oauthFbContinueGet(code, redirectUri, state, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * <br /><br /> <b>Authentication:</b> not required
     * @summary
     * @param {string} redirectUri
     * @param {object} [metadata]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async oauthFbInitGet(
      redirectUri: string,
      metadata?: object,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.oauthFbInitGet(redirectUri, metadata, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * <br /><br /> <b>Authentication:</b> not required
     * @summary
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async oauthFbMobileGet(
      token: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OauthAppleMobileGet200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.oauthFbMobileGet(token, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * This endpoint handles both login and signup depending on the state of the user account. <br /><br /> <b>Authentication:</b> not required
     * @summary This endpoint handles both login and signup depending on the state of the user account.
     * @param {string} [code]
     * @param {string} [redirectUri]
     * @param {string} [state]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async oauthGoogleContinueGet(
      code?: string,
      redirectUri?: string,
      state?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OauthAppleMobileGet200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.oauthGoogleContinueGet(
        code,
        redirectUri,
        state,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * This endpoint is responsible for sending the user off to a Google webpage to authenticate. Once complete, the user will be redirected to where this request was initiated and then depending on your requirements, the user will go down the login, signup, or continue path. <br /><br /> <b>Authentication:</b> not required
     * @summary This endpoint is responsible for sending the user off to a Google webpage to authenticate. Once complete, the user will be redirected to where this request was initiated and then depending on your requirements, the user will go down the login, signup, or continue path.
     * @param {string} [redirectUri]
     * @param {object} [metadata]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async oauthGoogleInitGet(
      redirectUri?: string,
      metadata?: object,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.oauthGoogleInitGet(redirectUri, metadata, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * <br /><br /> <b>Authentication:</b> not required
     * @summary
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async oauthGoogleMobileGet(
      token: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OauthAppleMobileGet200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.oauthGoogleMobileGet(token, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * OauthApi - factory interface
 * @export
 */
export const OauthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = OauthApiFp(configuration);
  return {
    /**
     * <br /><br /> <b>Authentication:</b> not required
     * @summary
     * @param {OauthAppleContinuePostRequest} [oauthAppleContinuePostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    oauthAppleContinuePost(
      oauthAppleContinuePostRequest?: OauthAppleContinuePostRequest,
      options?: any,
    ): AxiosPromise<object> {
      return localVarFp
        .oauthAppleContinuePost(oauthAppleContinuePostRequest, options)
        .then(request => request(axios, basePath));
    },
    /**
     * <br /><br /> <b>Authentication:</b> not required
     * @summary
     * @param {string} authUri
     * @param {string} redirectUri
     * @param {object} [metadata]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    oauthAppleInitGet(authUri: string, redirectUri: string, metadata?: object, options?: any): AxiosPromise<object> {
      return localVarFp
        .oauthAppleInitGet(authUri, redirectUri, metadata, options)
        .then(request => request(axios, basePath));
    },
    /**
     * <br /><br /> <b>Authentication:</b> not required
     * @summary
     * @param {string} payload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    oauthAppleMobileGet(payload: string, options?: any): AxiosPromise<OauthAppleMobileGet200Response> {
      return localVarFp.oauthAppleMobileGet(payload, options).then(request => request(axios, basePath));
    },
    /**
     * This endpoint handles both login and signup depending on the state of the user account. <br /><br /> <b>Authentication:</b> not required
     * @summary This endpoint handles both login and signup depending on the state of the user account.
     * @param {string} [code]
     * @param {string} [redirectUri]
     * @param {string} [state]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    oauthFbContinueGet(
      code?: string,
      redirectUri?: string,
      state?: string,
      options?: any,
    ): AxiosPromise<OauthAppleMobileGet200Response> {
      return localVarFp.oauthFbContinueGet(code, redirectUri, state, options).then(request => request(axios, basePath));
    },
    /**
     * <br /><br /> <b>Authentication:</b> not required
     * @summary
     * @param {string} redirectUri
     * @param {object} [metadata]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    oauthFbInitGet(redirectUri: string, metadata?: object, options?: any): AxiosPromise<object> {
      return localVarFp.oauthFbInitGet(redirectUri, metadata, options).then(request => request(axios, basePath));
    },
    /**
     * <br /><br /> <b>Authentication:</b> not required
     * @summary
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    oauthFbMobileGet(token: string, options?: any): AxiosPromise<OauthAppleMobileGet200Response> {
      return localVarFp.oauthFbMobileGet(token, options).then(request => request(axios, basePath));
    },
    /**
     * This endpoint handles both login and signup depending on the state of the user account. <br /><br /> <b>Authentication:</b> not required
     * @summary This endpoint handles both login and signup depending on the state of the user account.
     * @param {string} [code]
     * @param {string} [redirectUri]
     * @param {string} [state]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    oauthGoogleContinueGet(
      code?: string,
      redirectUri?: string,
      state?: string,
      options?: any,
    ): AxiosPromise<OauthAppleMobileGet200Response> {
      return localVarFp
        .oauthGoogleContinueGet(code, redirectUri, state, options)
        .then(request => request(axios, basePath));
    },
    /**
     * This endpoint is responsible for sending the user off to a Google webpage to authenticate. Once complete, the user will be redirected to where this request was initiated and then depending on your requirements, the user will go down the login, signup, or continue path. <br /><br /> <b>Authentication:</b> not required
     * @summary This endpoint is responsible for sending the user off to a Google webpage to authenticate. Once complete, the user will be redirected to where this request was initiated and then depending on your requirements, the user will go down the login, signup, or continue path.
     * @param {string} [redirectUri]
     * @param {object} [metadata]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    oauthGoogleInitGet(redirectUri?: string, metadata?: object, options?: any): AxiosPromise<object> {
      return localVarFp.oauthGoogleInitGet(redirectUri, metadata, options).then(request => request(axios, basePath));
    },
    /**
     * <br /><br /> <b>Authentication:</b> not required
     * @summary
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    oauthGoogleMobileGet(token: string, options?: any): AxiosPromise<OauthAppleMobileGet200Response> {
      return localVarFp.oauthGoogleMobileGet(token, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * OauthApi - object-oriented interface
 * @export
 * @class OauthApi
 * @extends {BaseAPI}
 */
export class OauthApi extends BaseAPI {
  /**
   * <br /><br /> <b>Authentication:</b> not required
   * @summary
   * @param {OauthAppleContinuePostRequest} [oauthAppleContinuePostRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OauthApi
   */
  public oauthAppleContinuePost(
    oauthAppleContinuePostRequest?: OauthAppleContinuePostRequest,
    options?: AxiosRequestConfig,
  ) {
    return OauthApiFp(this.configuration)
      .oauthAppleContinuePost(oauthAppleContinuePostRequest, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * <br /><br /> <b>Authentication:</b> not required
   * @summary
   * @param {string} authUri
   * @param {string} redirectUri
   * @param {object} [metadata]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OauthApi
   */
  public oauthAppleInitGet(authUri: string, redirectUri: string, metadata?: object, options?: AxiosRequestConfig) {
    return OauthApiFp(this.configuration)
      .oauthAppleInitGet(authUri, redirectUri, metadata, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * <br /><br /> <b>Authentication:</b> not required
   * @summary
   * @param {string} payload
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OauthApi
   */
  public oauthAppleMobileGet(payload: string, options?: AxiosRequestConfig) {
    return OauthApiFp(this.configuration)
      .oauthAppleMobileGet(payload, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * This endpoint handles both login and signup depending on the state of the user account. <br /><br /> <b>Authentication:</b> not required
   * @summary This endpoint handles both login and signup depending on the state of the user account.
   * @param {string} [code]
   * @param {string} [redirectUri]
   * @param {string} [state]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OauthApi
   */
  public oauthFbContinueGet(code?: string, redirectUri?: string, state?: string, options?: AxiosRequestConfig) {
    return OauthApiFp(this.configuration)
      .oauthFbContinueGet(code, redirectUri, state, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * <br /><br /> <b>Authentication:</b> not required
   * @summary
   * @param {string} redirectUri
   * @param {object} [metadata]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OauthApi
   */
  public oauthFbInitGet(redirectUri: string, metadata?: object, options?: AxiosRequestConfig) {
    return OauthApiFp(this.configuration)
      .oauthFbInitGet(redirectUri, metadata, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * <br /><br /> <b>Authentication:</b> not required
   * @summary
   * @param {string} token
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OauthApi
   */
  public oauthFbMobileGet(token: string, options?: AxiosRequestConfig) {
    return OauthApiFp(this.configuration)
      .oauthFbMobileGet(token, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * This endpoint handles both login and signup depending on the state of the user account. <br /><br /> <b>Authentication:</b> not required
   * @summary This endpoint handles both login and signup depending on the state of the user account.
   * @param {string} [code]
   * @param {string} [redirectUri]
   * @param {string} [state]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OauthApi
   */
  public oauthGoogleContinueGet(code?: string, redirectUri?: string, state?: string, options?: AxiosRequestConfig) {
    return OauthApiFp(this.configuration)
      .oauthGoogleContinueGet(code, redirectUri, state, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * This endpoint is responsible for sending the user off to a Google webpage to authenticate. Once complete, the user will be redirected to where this request was initiated and then depending on your requirements, the user will go down the login, signup, or continue path. <br /><br /> <b>Authentication:</b> not required
   * @summary This endpoint is responsible for sending the user off to a Google webpage to authenticate. Once complete, the user will be redirected to where this request was initiated and then depending on your requirements, the user will go down the login, signup, or continue path.
   * @param {string} [redirectUri]
   * @param {object} [metadata]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OauthApi
   */
  public oauthGoogleInitGet(redirectUri?: string, metadata?: object, options?: AxiosRequestConfig) {
    return OauthApiFp(this.configuration)
      .oauthGoogleInitGet(redirectUri, metadata, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * <br /><br /> <b>Authentication:</b> not required
   * @summary
   * @param {string} token
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OauthApi
   */
  public oauthGoogleMobileGet(token: string, options?: AxiosRequestConfig) {
    return OauthApiFp(this.configuration)
      .oauthGoogleMobileGet(token, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * TestApi - axios parameter creator
 * @export
 */
export const TestApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * <br /><br /> <b>Authentication:</b> not required
     * @summary
     * @param {string} state
     * @param {'fb' | 'google' | 'apple'} provider
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    testStateGet: async (
      state: string,
      provider: 'fb' | 'google' | 'apple',
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'state' is not null or undefined
      assertParamExists('testStateGet', 'state', state);
      // verify required parameter 'provider' is not null or undefined
      assertParamExists('testStateGet', 'provider', provider);
      const localVarPath = `/test/state`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (state !== undefined) {
        localVarQueryParameter['state'] = state;
      }

      if (provider !== undefined) {
        localVarQueryParameter['provider'] = provider;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * TestApi - functional programming interface
 * @export
 */
export const TestApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = TestApiAxiosParamCreator(configuration);
  return {
    /**
     * <br /><br /> <b>Authentication:</b> not required
     * @summary
     * @param {string} state
     * @param {'fb' | 'google' | 'apple'} provider
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async testStateGet(
      state: string,
      provider: 'fb' | 'google' | 'apple',
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.testStateGet(state, provider, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * TestApi - factory interface
 * @export
 */
export const TestApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = TestApiFp(configuration);
  return {
    /**
     * <br /><br /> <b>Authentication:</b> not required
     * @summary
     * @param {string} state
     * @param {'fb' | 'google' | 'apple'} provider
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    testStateGet(state: string, provider: 'fb' | 'google' | 'apple', options?: any): AxiosPromise<object> {
      return localVarFp.testStateGet(state, provider, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * TestApi - object-oriented interface
 * @export
 * @class TestApi
 * @extends {BaseAPI}
 */
export class TestApi extends BaseAPI {
  /**
   * <br /><br /> <b>Authentication:</b> not required
   * @summary
   * @param {string} state
   * @param {'fb' | 'google' | 'apple'} provider
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TestApi
   */
  public testStateGet(state: string, provider: 'fb' | 'google' | 'apple', options?: AxiosRequestConfig) {
    return TestApiFp(this.configuration)
      .testStateGet(state, provider, options)
      .then(request => request(this.axios, this.basePath));
  }
}
