import { useUser } from '@sp/data/auth';
import { SELFPLUS_WEBSITE_URL } from '@sp/data/env';
import { GlobalMediaPlayer, GlobalMediaPlayerModel } from '@sp/data/global';
import { ANALYTICS, useSetAnalyticsContext } from '@sp/feature/analytics';
import { useSetPreviousUrl } from '@sp/feature/back-button';
import { ChatActiveAudioPanel, ReactionsModal } from '@sp/feature/chat';
import { CHAT_CHANNEL } from '@sp/feature/chat-channel';
import { MEMBERS_MODEL, MembersModal } from '@sp/feature/members';
import { MENTIONS_MODEL, MentionsLayout } from '@sp/feature/mentions';
import { UserProfileCard } from '@sp/feature/profile';
import { ChatTitle, Header, MentionsTitle } from '@sp/feature/space';
import { Button, ErrorMessage, SpinnerPage } from '@sp/ui/elements';
import { TabPane, Tabs } from '@sp/ui/tabs';
import { GRAY_PIXEL_IMG_SRC } from '@sp/util/files';
import { openGrooveWidget } from '@sp/util/groovehq';
import { isNotNullish } from '@sp/util/helpers';
import { parseFromParam, ROUTES } from '@sp/util/router';
import { useEvent, useGate, useStore } from 'effector-react';
import { FC, useEffect } from 'react';
import { Navigate, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { CHALLENGE_MODEL } from './challenge-model';
import { ChatPage } from './chat/chat-page';
import { JourneyLayout } from './journey';
import { StepFeedbackFormModal } from './steps';
import { TrialHeader } from './trial-header';

const RELOAD_TIMEOUT = 15 * 1000;

function canReloadOnError() {
  const lastReloadDate = Number(localStorage.getItem('lastReload'));
  if (!lastReloadDate) return true;
  const date = Date.now();
  return date - lastReloadDate > RELOAD_TIMEOUT;
}

function reloadOnError() {
  if (canReloadOnError()) {
    localStorage.setItem('lastReload', String(Date.now()));
    window.location.reload();
  }
}

export const AudioPlayer = ({ player }: Readonly<{ player: GlobalMediaPlayer | null }>) => {
  if (!player) {
    return null;
  }

  return (
    <div className="absolute left-0 right-0 top-0 z-20">
      <ChatActiveAudioPanel
        onClose={GlobalMediaPlayerModel.close}
        player={player.player}
        authorName={player.meta.authorName}
      />
    </div>
  );
};

const ChallengeErrorScreen: FC = () => {
  const navigate = useNavigate();
  const handleSupportClick = useEvent(openGrooveWidget);

  useEffect(() => {
    reloadOnError();
  }, []);
  return (
    <div className="h-full w-full flex flex-col justify-center items-center p-8 gap-8">
      <ErrorMessage>
        Something went wrong.
        <div className="mt-2">
          Could you try again in a few moments?{' '}
          <a role="button" onClick={handleSupportClick}>
            Ping us
          </a>{' '}
          if you keep getting this message, and we&apos;ll look into that.
        </div>
      </ErrorMessage>

      <div className="w-full flex flex-col gap-2">
        <Button onClick={() => window.location.reload()} block color="black">
          Try again
        </Button>

        <Button onClick={() => navigate(ROUTES.HOME_URL)} block color="transparent">
          Back to home
        </Button>
      </div>
    </div>
  );
};

export const ChallengeLayout: FC<{ spaceId: number; tabKey: string }> = ({ tabKey, spaceId }) => {
  const navigate = useNavigate();
  const user = useUser();
  useGate(CHALLENGE_MODEL.ChallengeGate, spaceId);
  useEffect(() => GlobalMediaPlayerModel.close, []);
  useSetPreviousUrl(ROUTES.HOME_URL);

  const mentionsUnviewedCount = useStore(MENTIONS_MODEL.$unviewedCount);

  const activeAudioPlayer = useStore(GlobalMediaPlayerModel.$player);

  const challenge = useStore(CHALLENGE_MODEL.$challenge);
  const isLoadingError = useStore(CHALLENGE_MODEL.$loadingError) != null;
  const isChatModelError = useStore(CHAT_CHANNEL.$isError);
  const isLoaded = useStore(CHALLENGE_MODEL.$isLoaded);
  const isMembersModalOpen = useStore(MEMBERS_MODEL.$isOpen);
  const [searchParams] = useSearchParams();
  const fromParam = parseFromParam(searchParams);

  useSetAnalyticsContext(
    isNotNullish(challenge)
      ? {
          'Space ID': challenge?.id.toString() ?? '',
          'Space Name': challenge?.title.toString() ?? '',
          'Creator ID': challenge?.creator?.id.toString() ?? '',
          'Creator Name': challenge?.creator?.name.toString() ?? '',
          From: fromParam,
          Type: 'Challenge',
        }
      : null,
  );

  const showTrialHeader = challenge.creator?.id !== user.id && !challenge.isPurchased && challenge.isTrial;

  useEffect(() => {
    if (tabKey === 'journey') {
      ANALYTICS.journeyCheckTracked();
      // Refresh challenge info in case new steps were posted.
      CHALLENGE_MODEL.refreshChallengeStarted();
    }
  }, [tabKey]);

  useEffect(() => {
    ANALYTICS.spaceOpenTrackingStart();
    return () => ANALYTICS.spaceCloseTracked();
  }, []);

  if (!isLoaded) {
    return <SpinnerPage />;
  }

  if (isLoadingError || isChatModelError) {
    return <ChallengeErrorScreen />;
  }

  function onTabChange(key: string) {
    navigate(`${ROUTES.CHALLENGE_PAGE_URL}/${spaceId}/${key}`, { replace: true });
  }

  return (
    <div className="h-full flex flex-col">
      <Header
        creatorId={challenge.creator?.id}
        title={challenge.title}
        members={challenge.membersCount}
        avatar={challenge.cover?.url ?? GRAY_PIXEL_IMG_SRC}
        urlToShare={`${SELFPLUS_WEBSITE_URL}/${challenge.userSlug}/${challenge.slug}/`}
        onClick={MEMBERS_MODEL.open}
      />
      <div className="flex-1">
        <Tabs onChange={onTabChange} activeKey={tabKey} activeTabTextColor="text-primary">
          <TabPane title={<ChatTitle />} key="chat">
            <ChatPage />
          </TabPane>
          <TabPane title="Journey" key="journey">
            {showTrialHeader && <TrialHeader challenge={challenge} />}
            <div className="relative h-full w-full">
              <AudioPlayer player={activeAudioPlayer} />
              <JourneyLayout />
            </div>
          </TabPane>
          <TabPane title={<MentionsTitle unviewedCount={mentionsUnviewedCount} />} key="mentions">
            {showTrialHeader && <TrialHeader challenge={challenge} />}
            <div className="relative h-full w-full">
              <AudioPlayer player={activeAudioPlayer} />
              <MentionsLayout chatId={challenge.chatId} />
            </div>
          </TabPane>
        </Tabs>
      </div>
      <ReactionsModal />
      <UserProfileCard cardContext="space" />
      <StepFeedbackFormModal />
      <MembersModal
        creator={challenge.creator}
        title={challenge.title}
        avatar={challenge.cover?.url ?? GRAY_PIXEL_IMG_SRC}
        urlToShare={`${SELFPLUS_WEBSITE_URL}/${challenge.userSlug}/${challenge.slug}/`}
        members={challenge.members}
        isOpen={isMembersModalOpen}
      />
    </div>
  );
};

export const ChallengeScreen: FC<{ tabKey: string }> = props => {
  const params = useParams<typeof ROUTES.SPACE_ID_PARAM>();
  const spaceId = Number(params.spaceId);

  if (isNaN(spaceId)) {
    console.error(`Invalid challenge space id: ${params.spaceId}`);
    return <Navigate to={ROUTES.HOME_URL} />;
  }

  return <ChallengeLayout spaceId={spaceId} tabKey={props.tabKey} />;
};
