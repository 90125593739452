import { AlreadyPurchasedError, checkNotPurchased, registerInAppPurchasedSpace, SpacePurchaseType } from '@sp/data/bif';
import { ANALYTICS } from '@sp/feature/analytics';
import { makeInAppPurchase } from '@sp/util/native';
import { createEffect, createEvent, restore, sample } from 'effector';

type InAppSpacePurchaseType = SpacePurchaseType;

type InAppPurchasePayload = {
  type: InAppSpacePurchaseType;
  spaceId: number;
  product: string;
  title: string;
  price: number;
  creatorId: number;
  creatorName: string;
};

const purchaseSpace = createEvent<InAppPurchasePayload>();

const checkPurchaseFx = createEffect(async (data: InAppPurchasePayload) => {
  await checkNotPurchased(data.spaceId, data.type);
  return data;
});

const purchaseSpaceFx = createEffect(
  async ({
    type,
    spaceId,
    product,
    title,
    price,
    error,
    creatorName,
    creatorId,
  }: InAppPurchasePayload & { error?: Error }) => {
    const context = { type, spaceId, product, title, price, creatorName, creatorId } as const;

    if (error) {
      return {
        success: false,
        error: error instanceof AlreadyPurchasedError ? 'alreadyPurchased' : 'failedToCheckPurchase',
        context,
      } as const;
    }

    if (product === null || product.length === 0) {
      return {
        success: false,
        error: 'invalidProductId',
        context,
      } as const;
    }

    const iapResult = await makeInAppPurchase(product);
    if (!iapResult.success) return { ...iapResult, context };

    if (iapResult.product !== product) {
      return {
        success: false,
        error: 'mismatchingInAppPurchase',
        context,
        data: { expected: product, received: iapResult.product },
      } as const;
    }

    try {
      await registerInAppPurchasedSpace({
        spaceId,
        platform: 'ios',
        transactionId: iapResult.transactionId,
        type,
      });
      return { success: true, context } as const;
    } catch (e) {
      return { success: false, error: 'failedToRegister', context } as const;
    }
  },
);

const trackPurchaseFx = createEffect(({ context }: Awaited<ReturnType<typeof purchaseSpaceFx>>) => {
  ANALYTICS.purchaseViewedTracked({
    'Creator Name': context.creatorName,
    'Creator ID': context.creatorId.toString(),
    'Space Name': context.title,
    'Space ID': context.spaceId.toString(),
    Value: (context.price / 100).toString(),
    Source: '2',
  });
});

const reset = createEvent();

const $result = restore(purchaseSpaceFx.doneData, null).reset(purchaseSpace).reset(reset);
const $error = $result.map(result => (result?.success === false ? result.error : null));

const $isPending = purchaseSpaceFx.pending;

sample({
  clock: purchaseSpace,
  target: checkPurchaseFx,
});

sample({
  clock: checkPurchaseFx.fail,
  fn: ({ params, error }) => {
    return { ...params, error };
  },
  target: purchaseSpaceFx,
});

sample({
  clock: checkPurchaseFx.doneData,
  target: purchaseSpaceFx,
});

sample({
  clock: [
    purchaseSpaceFx.failData,
    purchaseSpaceFx.doneData.filterMap(r => (r.success || r.error === 'cancel' ? undefined : r)),
  ],
  target: createEffect((v: unknown) => console.error('iOS In-App Purchase failed.', JSON.stringify(v))),
});

sample({
  clock: purchaseSpaceFx.doneData,
  filter: r => r.success,
  target: trackPurchaseFx,
});

export const IOS_IN_APP_PURCHASE_MODEL = {
  purchaseSpace,
  reset,
  $isPending,
  $result,
  $error,
};
