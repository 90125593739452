import { MeUserLink, UserLink } from '@sp/data/bif';
import { Button } from '@sp/ui/elements';
import { IconSocialDefault } from '@sp/ui/icons';
import { ROUTES } from '@sp/util/router';
import { useStore } from 'effector-react';
import { FC } from 'react';
import { Link as NavigateLink, useNavigate } from 'react-router-dom';
import { socialServiceIcon } from './edit-links-page';
import { linkResolver } from './link-resolver';
import { LINKS_MODEL } from './links-model';

const Link: FC<UserLink | MeUserLink> = link => {
  const socialType = linkResolver(link.href);
  return (
    <a className="pb-2 block no-underline" target="_blank" href={link.href} rel="noreferrer">
      <div className="flex items-center py-2 px-3 border-none rounded-lg bg-light">
        <span className="mr-2">
          {socialType ? socialServiceIcon[socialType] : <IconSocialDefault className="text-brand" />}
        </span>

        <span className="font-semibold">{link.title}</span>
      </div>
    </a>
  );
};

const AddLinksBtn: FC = () => {
  const navigate = useNavigate();
  return (
    <Button color="black" onClick={() => navigate(ROUTES.PROFILE.LINKS_SETTINGS)} block>
      Add my links
    </Button>
  );
};

export const Links: FC<{ editable: boolean }> = ({ editable }) => {
  const nonEditableLinks = useStore(LINKS_MODEL.$sortedStaticLinks);
  const editableLinks = useStore(LINKS_MODEL.$sortedEditableLinks);
  const links = editable ? editableLinks : nonEditableLinks;

  return links.length === 0 && !editable ? null : (
    <div className="mt-4 pb-6">
      <div className="flex justify-between mb-4">
        <span className="text-xl font-semibold">Links</span>
        {editable && links.length > 0 ? (
          <NavigateLink
            to={`${ROUTES.PROFILE_URL}/${ROUTES.PROFILE.LINKS_SETTINGS}`}
            className="text-brand font-medium no-underline"
          >
            Edit
          </NavigateLink>
        ) : null}
      </div>
      <div className="flex flex-col">
        {links.length > 0 ? (
          links.map(link => <Link key={link.href} {...link} />)
        ) : (
          <div className="flex flex-col items-center justify-center">
            <div className="w-48 h-40 bg-brand-main mb-4">
              <img className="w-full h-full object-cover overflow-hidden" src="/images/links.svg" />
            </div>
            <div className="text-center mb-4">
              Add your socials to find like-minded people and share your journey with them
            </div>
            <AddLinksBtn />
          </div>
        )}
      </div>
    </div>
  );
};
