import { RemirrorJSON } from '@remirror/core';
import LRUCache from 'lru-cache';
import { MENTION_TYPE_DATA_ATTR_KEY, MentionType } from './extensions';
import { renderDocToHtml } from './render-doc-to-html';

const cache = new LRUCache<RemirrorJSON, string>({ max: 1000 });

const userMentionSelector = `[${MENTION_TYPE_DATA_ATTR_KEY}="${MentionType.User}"]` as const;

function formatUserMentions(container: Element): void {
  container.querySelectorAll<HTMLSpanElement>(userMentionSelector).forEach(m => {
    m.innerText = '@' + m.innerText;
  });
}

export function renderDocToText(doc: RemirrorJSON): string {
  const cached = cache.get(doc);
  if (cached != null) {
    return cached;
  }

  const text = renderHtmlToText(renderDocToHtml(doc));

  cache.set(doc, text);
  return text;
}

export function renderHtmlToText(html: string): string {
  const renderContainer = document.createElement('div');
  renderContainer.innerHTML = html;

  formatUserMentions(renderContainer);

  return renderContainer.innerText;
}
