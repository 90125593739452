import { getNps, NpsForm, postNps } from '@sp/data/bif';
import { rateApp } from '@sp/util/native';
import { createEffect, createEvent, createStore, sample } from 'effector';
import { createGate } from 'effector-react';

const RATE_TO_SHOW_NATIVE_MODAL = 7;

const RateFormGate = createGate();

const $rateForm = createStore<NpsForm | null>(null);

const ratingSent = createEvent<NpsForm>();

const sendRatingFx = createEffect((data: NpsForm) => {
  if (data.rate >= RATE_TO_SHOW_NATIVE_MODAL) {
    rateApp();
  }
  return postNps(data);
});

const getRatingFx = createEffect(() => {
  return getNps();
});

sample({ clock: RateFormGate.open, target: getRatingFx });
sample({ source: getRatingFx.doneData, target: $rateForm });
sample({ clock: ratingSent, target: sendRatingFx });
sample({ source: sendRatingFx.doneData, target: $rateForm });

export const PLATFORM_FORM = { ratingSent, RateFormGate, $rateForm };
