import { ReactElement } from 'react';
import { ICON_DEFAULTS, IconProps } from './types';

export function IconMusicNotes({
  size = ICON_DEFAULTS.size,
  color = ICON_DEFAULTS.color,
  className = ICON_DEFAULTS.className,
}: IconProps): ReactElement {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M14.0625 15.6248C15.2706 15.6248 16.25 14.6454 16.25 13.4373C16.25 12.2291 15.2706 11.2498 14.0625 11.2498C12.8544 11.2498 11.875 12.2291 11.875 13.4373C11.875 14.6454 12.8544 15.6248 14.0625 15.6248Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.0625 18.1248C5.27062 18.1248 6.25 17.1454 6.25 15.9373C6.25 14.7291 5.27062 13.7498 4.0625 13.7498C2.85438 13.7498 1.875 14.7291 1.875 15.9373C1.875 17.1454 2.85438 18.1248 4.0625 18.1248Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M16.25 6.24976L6.25 8.74976" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M6.25 15.9373V4.99976L16.25 2.49976V13.4373"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
