import { ReactElement } from 'react';
import { ICON_DEFAULTS, IconProps } from './types';

export function IconCheck({
  size = ICON_DEFAULTS.size,
  color = ICON_DEFAULTS.color,
  className = ICON_DEFAULTS.className,
}: IconProps): ReactElement {
  return (
    <svg
      width={size}
      height={size}
      className={className}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.4053 5.09467C17.6982 5.38756 17.6982 5.86244 17.4053 6.15533L8.65533 14.9053C8.36244 15.1982 7.88756 15.1982 7.59467 14.9053L3.21967 10.5303C2.92678 10.2374 2.92678 9.76256 3.21967 9.46967C3.51256 9.17678 3.98744 9.17678 4.28033 9.46967L8.125 13.3143L16.3447 5.09467C16.6376 4.80178 17.1124 4.80178 17.4053 5.09467Z"
        fill={color}
      />
    </svg>
  );
}
