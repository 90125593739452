import { DiscriminatingOmit, Mutable } from '@sp/util/helpers';
import { SpacesApiFactory } from '../api/spaces';
import { ChallengeSpaceConfig, ChannelSpaceConfig } from '../space-types/concrete-space.types';
import {
  OwnedChallengeSpace,
  OwnedChallengeSpacePost,
  OwnedChallengeSpaceStep,
  OwnedChannelSpace,
  OwnedLivestreamSpace,
  OwnedSpace,
  OwnedSpaceBase,
} from '../space-types/owned-space.types';
import { SPACE_TYPE } from '../space-types/shared-space.types';

const spacesSpacesApi = SpacesApiFactory();

type OwnedSpaceApiDto = Awaited<ReturnType<ReturnType<typeof SpacesApiFactory>['spacesSpaceIdGet']>>['data'];
type CreateSpaceDto = Awaited<Parameters<ReturnType<typeof SpacesApiFactory>['spacesPost']>[0]>;
type PatchSpaceDto = Awaited<Parameters<ReturnType<typeof SpacesApiFactory>['spacesSpaceIdPatch']>[1]>;

type OwnedChallengeStepApiDto = Awaited<
  ReturnType<ReturnType<typeof SpacesApiFactory>['spacesSpaceIdChallengeStepsGet']>
>['data'][number];

type OwnedChallengePostApiDto = Awaited<
  ReturnType<ReturnType<typeof SpacesApiFactory>['spacesSpaceIdChallengePostsGet']>
>['data'][number];

// TODO[Dmitriy Teplov] actually validate properties.
function assertOwnedSpace<T extends OwnedSpace>(dto: OwnedSpaceApiDto): asserts dto is T {
  return;
}

// TODO[Dmitriy Teplov] actually validate properties.
function assertChallengeStep(dto: OwnedChallengeStepApiDto): asserts dto is OwnedChallengeSpaceStep {
  return;
}

// TODO[Dmitriy Teplov] actually validate properties.
function assertChallengePost(dto: OwnedChallengePostApiDto): asserts dto is OwnedChallengeSpacePost {
  return;
}

function castApiOwnedSpaceDto<T extends OwnedSpace>(dto: OwnedSpaceApiDto): T {
  assertOwnedSpace<T>(dto);
  return dto;
}

function castApiOwnedChallengeStepDto(dto: OwnedChallengeStepApiDto): OwnedChallengeSpaceStep {
  assertChallengeStep(dto);
  return dto;
}

function castApiOwnedChallengePostDto(dto: OwnedChallengePostApiDto): OwnedChallengeSpacePost {
  assertChallengePost(dto);
  return dto;
}

type CreateBaseSpaceDto<Type extends SPACE_TYPE> = DiscriminatingOmit<
  OwnedSpaceBase,
  'id' | 'created_at' | 'updated_at' | 'status' | 'cover' | 'owner'
> & {
  type: Type;
  coverFileId: number;
};

// FIXME[Dmitriy Teplov] type discrimination doesn't work after omit.
type UpdateBaseSpaceDto<Type extends SPACE_TYPE> = Mutable<
  Partial<
    Omit<OwnedSpaceBase, 'id' | 'created_at' | 'updated_at' | 'cover' | 'owner'> & {
      type: Type;
      coverFileId?: number;
    }
  >
>;

export type LivestreamSpaceTestimonialCreateDto = {
  title: string;
  authorName: string;
  review: string;
  avatarFileId: number | null;
};

type LivestreamSpaceConfigCreateDto = {
  startAt: number; // unix timestamp
  duration: number; // duration in minutes
  linkToZoom: string;
  requirements: string;
  creatorDescription: string;
  description: string;
  offerDescription: string;
  offerSummary: string;
  record: null | {
    videoFileId: number;
    coverFileId: number | null;
    meta: { duration: number };
  };
  testimonials: LivestreamSpaceTestimonialCreateDto[];
};

type LivestreamSpaceConfigUpdateDto = LivestreamSpaceConfigCreateDto;

export type CreateLivestreamSpaceDto = CreateBaseSpaceDto<SPACE_TYPE.live> & {
  config: { live: LivestreamSpaceConfigCreateDto };
};
export type UpdateLivestreamSpaceDto = UpdateBaseSpaceDto<SPACE_TYPE.live> & {
  config?: { live: LivestreamSpaceConfigUpdateDto };
};

export type CreateChallengeSpaceDto = CreateBaseSpaceDto<SPACE_TYPE.challenge> & { config: ChallengeSpaceConfig };
export type UpdateChallengeSpaceDto = UpdateBaseSpaceDto<SPACE_TYPE.challenge> & { config?: ChallengeSpaceConfig };

export type CreateChannelSpaceDto = CreateBaseSpaceDto<SPACE_TYPE.channel> & { config: ChannelSpaceConfig };
export type UpdateChannelSpaceDto = UpdateBaseSpaceDto<SPACE_TYPE.channel> & { config?: ChannelSpaceConfig };

export type CreateChallengeStepDto<
  ConcreteOwnedChallengeStep extends OwnedChallengeSpaceStep = OwnedChallengeSpaceStep,
> = DiscriminatingOmit<ConcreteOwnedChallengeStep, 'id' | 'createdAt' | 'updatedAt' | 'challengeSpace'>;

export type UpdateChallengeStepDto<
  ConcreteOwnedChallengeStep extends OwnedChallengeSpaceStep = OwnedChallengeSpaceStep,
> = DiscriminatingOmit<ConcreteOwnedChallengeStep, 'createdAt' | 'updatedAt' | 'challengeSpace'>;

export interface ChallengeStepsPatchDto {
  readonly created: CreateChallengeStepDto[];
  readonly updated: UpdateChallengeStepDto[];
  readonly deleted: number[];
}

export type CreateChallengePostDto<
  ConcreteOwnedChallengePost extends OwnedChallengeSpacePost = OwnedChallengeSpacePost,
> = DiscriminatingOmit<
  ConcreteOwnedChallengePost,
  'id' | 'createdAt' | 'updatedAt' | 'deletedAt' | 'gsMessageId' | 'publishedAt' | 'spaceId'
>;

export type UpdateChallengePostDto<
  ConcreteOwnedChallengePost extends OwnedChallengeSpacePost = OwnedChallengeSpacePost,
> = DiscriminatingOmit<
  ConcreteOwnedChallengePost,
  'createdAt' | 'updatedAt' | 'deletedAt' | 'gsMessageId' | 'publishedAt' | 'spaceId'
>;

export interface ChallengePostsPatchDto {
  readonly created: CreateChallengePostDto[];
  readonly updated: UpdateChallengePostDto[];
  readonly deleted: number[];
}

export async function getSpace({ spaceId }: { spaceId: number }): Promise<OwnedSpace> {
  return spacesSpacesApi.spacesSpaceIdGet(spaceId).then(r => castApiOwnedSpaceDto(r.data));
}

export async function createSpace({ space }: { space: CreateChallengeSpaceDto }): Promise<OwnedChallengeSpace>;
export async function createSpace({ space }: { space: CreateChannelSpaceDto }): Promise<OwnedChannelSpace>;
export async function createSpace({ space }: { space: CreateLivestreamSpaceDto }): Promise<OwnedLivestreamSpace>;
export async function createSpace({ space }: { space: CreateSpaceDto }): Promise<OwnedSpace> {
  return spacesSpacesApi.spacesPost(space).then(r => castApiOwnedSpaceDto(r.data));
}

export type PatchSpacePayload<Patch> = { spaceId: number; patch: Patch };

export async function patchSpace(payload: PatchSpacePayload<UpdateChallengeSpaceDto>): Promise<OwnedChallengeSpace>;
export async function patchSpace(payload: PatchSpacePayload<UpdateLivestreamSpaceDto>): Promise<OwnedLivestreamSpace>;
export async function patchSpace(payload: PatchSpacePayload<UpdateChannelSpaceDto>): Promise<OwnedChannelSpace>;
export async function patchSpace({ spaceId, patch }: PatchSpacePayload<PatchSpaceDto>): Promise<OwnedSpace> {
  return spacesSpacesApi.spacesSpaceIdPatch(spaceId, patch).then(r => castApiOwnedSpaceDto(r.data));
}

export async function getChallengeSteps({
  challengeId,
}: {
  challengeId: number;
}): Promise<ReadonlyArray<OwnedChallengeSpaceStep>> {
  return spacesSpacesApi
    .spacesSpaceIdChallengeStepsGet(challengeId)
    .then(r => r.data.map(castApiOwnedChallengeStepDto));
}

export async function patchChallengeSteps({
  challengeId,
  patch,
}: {
  challengeId: number;
  patch: ChallengeStepsPatchDto;
}): Promise<readonly OwnedChallengeSpaceStep[]> {
  return spacesSpacesApi
    .spacesSpaceIdChallengeStepsPatch(challengeId, patch)
    .then(r => r.data.map(castApiOwnedChallengeStepDto));
}

export async function getChallengePosts({
  challengeId,
}: {
  challengeId: number;
}): Promise<ReadonlyArray<OwnedChallengeSpacePost>> {
  return spacesSpacesApi
    .spacesSpaceIdChallengePostsGet(challengeId)
    .then(r => r.data.map(castApiOwnedChallengePostDto));
}

export async function patchChallengePosts({
  challengeId,
  patch,
}: {
  challengeId: number;
  patch: ChallengePostsPatchDto;
}): Promise<readonly OwnedChallengeSpacePost[]> {
  return spacesSpacesApi
    .spacesSpaceIdChallengePostsPatch(challengeId, patch)
    .then(r => r.data.map(castApiOwnedChallengePostDto));
}
