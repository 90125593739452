import { ChatMessageViewAttachment, sanitizeDataUrlInAttachment } from '@sp/util/chat-attachments';
import { BooleanMap, isNotNullish } from '@sp/util/helpers';
import { ChatAttachment, SelfplusExtraMessageFields } from '@sp/util/stream-chat';

const updatableFieldsConfig: BooleanMap<Required<SelfplusExtraMessageFields>> = {
  selfplusCommunityPost: true,
};
export const isExtraMessageField = (field: string): field is keyof SelfplusExtraMessageFields => {
  return field in updatableFieldsConfig;
};

export function mapAttachmentsToStream(attachments: readonly ChatMessageViewAttachment[]): ChatAttachment[] {
  return attachments.map(a => ({
    selfplusAttachment: sanitizeDataUrlInAttachment(a),
  }));
}

export function mapAttachmentsToClient(attachments: readonly ChatAttachment[]): ChatMessageViewAttachment[] {
  return attachments.map(a => a.selfplusAttachment).filter(isNotNullish);
}
