/* tslint:disable */
/* eslint-disable */
/**
 * Private
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 *
 * @export
 * @interface ChallengeStepsStepIdResultPost200Response
 */
export interface ChallengeStepsStepIdResultPost200Response {
  /**
   *
   * @type {string}
   * @memberof ChallengeStepsStepIdResultPost200Response
   */
  success?: string;
}
/**
 *
 * @export
 * @interface ChallengeStepsStepIdResultPostRequest
 */
export interface ChallengeStepsStepIdResultPostRequest {
  /**
   *
   * @type {boolean}
   * @memberof ChallengeStepsStepIdResultPostRequest
   */
  isSkipped?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ChallengeStepsStepIdResultPostRequest
   */
  isCompleted?: boolean;
  /**
   *
   * @type {string}
   * @memberof ChallengeStepsStepIdResultPostRequest
   */
  message?: string;
  /**
   *
   * @type {number}
   * @memberof ChallengeStepsStepIdResultPostRequest
   */
  rate?: number;
}
/**
 *
 * @export
 * @interface ClubsClubIdFollowPost200Response
 */
export interface ClubsClubIdFollowPost200Response {
  /**
   *
   * @type {number}
   * @memberof ClubsClubIdFollowPost200Response
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof ClubsClubIdFollowPost200Response
   */
  createdAt?: number;
  /**
   *
   * @type {number}
   * @memberof ClubsClubIdFollowPost200Response
   */
  deletedAt?: number | null;
  /**
   *
   * @type {number}
   * @memberof ClubsClubIdFollowPost200Response
   */
  customerId?: number;
  /**
   *
   * @type {number}
   * @memberof ClubsClubIdFollowPost200Response
   */
  clubId?: number | null;
}
/**
 *
 * @export
 * @interface CreatorCreatorSlugCommunitiesGet200ResponseInner
 */
export interface CreatorCreatorSlugCommunitiesGet200ResponseInner {
  /**
   *
   * @type {number}
   * @memberof CreatorCreatorSlugCommunitiesGet200ResponseInner
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof CreatorCreatorSlugCommunitiesGet200ResponseInner
   */
  title?: string;
  /**
   *
   * @type {number}
   * @memberof CreatorCreatorSlugCommunitiesGet200ResponseInner
   */
  price?: number;
  /**
   *
   * @type {string}
   * @memberof CreatorCreatorSlugCommunitiesGet200ResponseInner
   */
  iosProductId?: string | null;
  /**
   *
   * @type {number}
   * @memberof CreatorCreatorSlugCommunitiesGet200ResponseInner
   */
  trialPrice?: number;
  /**
   *
   * @type {string}
   * @memberof CreatorCreatorSlugCommunitiesGet200ResponseInner
   */
  description?: string | null;
  /**
   *
   * @type {CreatorCreatorSlugCommunitiesGet200ResponseInnerConfig}
   * @memberof CreatorCreatorSlugCommunitiesGet200ResponseInner
   */
  config?: CreatorCreatorSlugCommunitiesGet200ResponseInnerConfig | null;
  /**
   *
   * @type {CreatorCreatorSlugCommunitiesGet200ResponseInnerMarketingPriceConfig}
   * @memberof CreatorCreatorSlugCommunitiesGet200ResponseInner
   */
  marketingPriceConfig?: CreatorCreatorSlugCommunitiesGet200ResponseInnerMarketingPriceConfig;
  /**
   *
   * @type {CreatorCreatorSlugCommunitiesGet200ResponseInnerConfigCommunityReviewsInnerAvatar}
   * @memberof CreatorCreatorSlugCommunitiesGet200ResponseInner
   */
  cover?: CreatorCreatorSlugCommunitiesGet200ResponseInnerConfigCommunityReviewsInnerAvatar | null;
  /**
   *
   * @type {Array<CreatorCreatorSlugCommunitiesGet200ResponseInnerLivesInner>}
   * @memberof CreatorCreatorSlugCommunitiesGet200ResponseInner
   */
  lives?: Array<CreatorCreatorSlugCommunitiesGet200ResponseInnerLivesInner>;
  /**
   *
   * @type {number}
   * @memberof CreatorCreatorSlugCommunitiesGet200ResponseInner
   */
  subscribersCount?: number;
  /**
   *
   * @type {CreatorCreatorSlugCommunitiesGet200ResponseInnerSubscription}
   * @memberof CreatorCreatorSlugCommunitiesGet200ResponseInner
   */
  subscription?: CreatorCreatorSlugCommunitiesGet200ResponseInnerSubscription | null;
}
/**
 *
 * @export
 * @interface CreatorCreatorSlugCommunitiesGet200ResponseInnerConfig
 */
export interface CreatorCreatorSlugCommunitiesGet200ResponseInnerConfig {
  /**
   *
   * @type {CreatorCreatorSlugCommunitiesGet200ResponseInnerConfigCommunity}
   * @memberof CreatorCreatorSlugCommunitiesGet200ResponseInnerConfig
   */
  community?: CreatorCreatorSlugCommunitiesGet200ResponseInnerConfigCommunity | null;
}
/**
 *
 * @export
 * @interface CreatorCreatorSlugCommunitiesGet200ResponseInnerConfigCommunity
 */
export interface CreatorCreatorSlugCommunitiesGet200ResponseInnerConfigCommunity {
  /**
   *
   * @type {string}
   * @memberof CreatorCreatorSlugCommunitiesGet200ResponseInnerConfigCommunity
   */
  updateFrequency?: CreatorCreatorSlugCommunitiesGet200ResponseInnerConfigCommunityUpdateFrequencyEnum;
  /**
   *
   * @type {string}
   * @memberof CreatorCreatorSlugCommunitiesGet200ResponseInnerConfigCommunity
   */
  creatorDescription?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreatorCreatorSlugCommunitiesGet200ResponseInnerConfigCommunity
   */
  targetAudience?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreatorCreatorSlugCommunitiesGet200ResponseInnerConfigCommunity
   */
  inside?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreatorCreatorSlugCommunitiesGet200ResponseInnerConfigCommunity
   */
  offerSummary?: string | null;
  /**
   *
   * @type {Array<string>}
   * @memberof CreatorCreatorSlugCommunitiesGet200ResponseInnerConfigCommunity
   */
  creatorAchievements?: Array<string>;
  /**
   *
   * @type {Array<CreatorCreatorSlugCommunitiesGet200ResponseInnerConfigCommunityReviewsInner>}
   * @memberof CreatorCreatorSlugCommunitiesGet200ResponseInnerConfigCommunity
   */
  reviews?: Array<CreatorCreatorSlugCommunitiesGet200ResponseInnerConfigCommunityReviewsInner> | null;
  /**
   *
   * @type {Array<CreatorCreatorSlugCommunitiesGet200ResponseInnerConfigCommunityFaqInner>}
   * @memberof CreatorCreatorSlugCommunitiesGet200ResponseInnerConfigCommunity
   */
  faq?: Array<CreatorCreatorSlugCommunitiesGet200ResponseInnerConfigCommunityFaqInner> | null;
  /**
   *
   * @type {CreatorCreatorSlugCommunitiesGet200ResponseInnerConfigCommunityVideo}
   * @memberof CreatorCreatorSlugCommunitiesGet200ResponseInnerConfigCommunity
   */
  video?: CreatorCreatorSlugCommunitiesGet200ResponseInnerConfigCommunityVideo | null;
}

export const CreatorCreatorSlugCommunitiesGet200ResponseInnerConfigCommunityUpdateFrequencyEnum = {
  Daily: 'daily',
  TwoThreePerWeek: 'twoThreePerWeek',
  Weekly: 'weekly',
  TwoThreePerMonth: 'twoThreePerMonth',
  Occasionally: 'occasionally',
} as const;

export type CreatorCreatorSlugCommunitiesGet200ResponseInnerConfigCommunityUpdateFrequencyEnum =
  typeof CreatorCreatorSlugCommunitiesGet200ResponseInnerConfigCommunityUpdateFrequencyEnum[keyof typeof CreatorCreatorSlugCommunitiesGet200ResponseInnerConfigCommunityUpdateFrequencyEnum];

/**
 *
 * @export
 * @interface CreatorCreatorSlugCommunitiesGet200ResponseInnerConfigCommunityFaqInner
 */
export interface CreatorCreatorSlugCommunitiesGet200ResponseInnerConfigCommunityFaqInner {
  /**
   *
   * @type {string}
   * @memberof CreatorCreatorSlugCommunitiesGet200ResponseInnerConfigCommunityFaqInner
   */
  question?: string;
  /**
   *
   * @type {string}
   * @memberof CreatorCreatorSlugCommunitiesGet200ResponseInnerConfigCommunityFaqInner
   */
  answer?: string;
}
/**
 *
 * @export
 * @interface CreatorCreatorSlugCommunitiesGet200ResponseInnerConfigCommunityReviewsInner
 */
export interface CreatorCreatorSlugCommunitiesGet200ResponseInnerConfigCommunityReviewsInner {
  /**
   *
   * @type {string}
   * @memberof CreatorCreatorSlugCommunitiesGet200ResponseInnerConfigCommunityReviewsInner
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof CreatorCreatorSlugCommunitiesGet200ResponseInnerConfigCommunityReviewsInner
   */
  text?: string;
  /**
   *
   * @type {CreatorCreatorSlugCommunitiesGet200ResponseInnerConfigCommunityReviewsInnerAvatar}
   * @memberof CreatorCreatorSlugCommunitiesGet200ResponseInnerConfigCommunityReviewsInner
   */
  avatar?: CreatorCreatorSlugCommunitiesGet200ResponseInnerConfigCommunityReviewsInnerAvatar | null;
}
/**
 *
 * @export
 * @interface CreatorCreatorSlugCommunitiesGet200ResponseInnerConfigCommunityReviewsInnerAvatar
 */
export interface CreatorCreatorSlugCommunitiesGet200ResponseInnerConfigCommunityReviewsInnerAvatar {
  /**
   *
   * @type {number}
   * @memberof CreatorCreatorSlugCommunitiesGet200ResponseInnerConfigCommunityReviewsInnerAvatar
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof CreatorCreatorSlugCommunitiesGet200ResponseInnerConfigCommunityReviewsInnerAvatar
   */
  url?: string;
}
/**
 *
 * @export
 * @interface CreatorCreatorSlugCommunitiesGet200ResponseInnerConfigCommunityVideo
 */
export interface CreatorCreatorSlugCommunitiesGet200ResponseInnerConfigCommunityVideo {
  /**
   *
   * @type {number}
   * @memberof CreatorCreatorSlugCommunitiesGet200ResponseInnerConfigCommunityVideo
   */
  videoFileId?: number | null;
  /**
   *
   * @type {number}
   * @memberof CreatorCreatorSlugCommunitiesGet200ResponseInnerConfigCommunityVideo
   */
  coverFileId?: number | null;
  /**
   *
   * @type {CreatorCreatorSlugCommunitiesGet200ResponseInnerConfigCommunityVideoFile}
   * @memberof CreatorCreatorSlugCommunitiesGet200ResponseInnerConfigCommunityVideo
   */
  file?: CreatorCreatorSlugCommunitiesGet200ResponseInnerConfigCommunityVideoFile | null;
  /**
   *
   * @type {CreatorCreatorSlugCommunitiesGet200ResponseInnerConfigCommunityReviewsInnerAvatar}
   * @memberof CreatorCreatorSlugCommunitiesGet200ResponseInnerConfigCommunityVideo
   */
  cover?: CreatorCreatorSlugCommunitiesGet200ResponseInnerConfigCommunityReviewsInnerAvatar | null;
}
/**
 *
 * @export
 * @interface CreatorCreatorSlugCommunitiesGet200ResponseInnerConfigCommunityVideoFile
 */
export interface CreatorCreatorSlugCommunitiesGet200ResponseInnerConfigCommunityVideoFile {
  /**
   *
   * @type {number}
   * @memberof CreatorCreatorSlugCommunitiesGet200ResponseInnerConfigCommunityVideoFile
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof CreatorCreatorSlugCommunitiesGet200ResponseInnerConfigCommunityVideoFile
   */
  url?: string;
  /**
   *
   * @type {object}
   * @memberof CreatorCreatorSlugCommunitiesGet200ResponseInnerConfigCommunityVideoFile
   */
  meta?: object | null;
}
/**
 *
 * @export
 * @interface CreatorCreatorSlugCommunitiesGet200ResponseInnerLivesInner
 */
export interface CreatorCreatorSlugCommunitiesGet200ResponseInnerLivesInner {
  /**
   *
   * @type {number}
   * @memberof CreatorCreatorSlugCommunitiesGet200ResponseInnerLivesInner
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof CreatorCreatorSlugCommunitiesGet200ResponseInnerLivesInner
   */
  title?: string;
  /**
   *
   * @type {number}
   * @memberof CreatorCreatorSlugCommunitiesGet200ResponseInnerLivesInner
   */
  startAt?: number;
  /**
   *
   * @type {number}
   * @memberof CreatorCreatorSlugCommunitiesGet200ResponseInnerLivesInner
   */
  duration?: number;
}
/**
 *
 * @export
 * @interface CreatorCreatorSlugCommunitiesGet200ResponseInnerMarketingPriceConfig
 */
export interface CreatorCreatorSlugCommunitiesGet200ResponseInnerMarketingPriceConfig {
  /**
   *
   * @type {number}
   * @memberof CreatorCreatorSlugCommunitiesGet200ResponseInnerMarketingPriceConfig
   */
  iosPrice?: number;
  /**
   *
   * @type {number}
   * @memberof CreatorCreatorSlugCommunitiesGet200ResponseInnerMarketingPriceConfig
   */
  iosTrialPrice?: number;
}
/**
 *
 * @export
 * @interface CreatorCreatorSlugCommunitiesGet200ResponseInnerSubscription
 */
export interface CreatorCreatorSlugCommunitiesGet200ResponseInnerSubscription {
  /**
   *
   * @type {number}
   * @memberof CreatorCreatorSlugCommunitiesGet200ResponseInnerSubscription
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof CreatorCreatorSlugCommunitiesGet200ResponseInnerSubscription
   */
  createdAt?: number;
  /**
   *
   * @type {number}
   * @memberof CreatorCreatorSlugCommunitiesGet200ResponseInnerSubscription
   */
  lastPaymentDate?: number | null;
  /**
   *
   * @type {string}
   * @memberof CreatorCreatorSlugCommunitiesGet200ResponseInnerSubscription
   */
  type?: CreatorCreatorSlugCommunitiesGet200ResponseInnerSubscriptionTypeEnum;
  /**
   *
   * @type {number}
   * @memberof CreatorCreatorSlugCommunitiesGet200ResponseInnerSubscription
   */
  startedAt?: number | null;
  /**
   *
   * @type {number}
   * @memberof CreatorCreatorSlugCommunitiesGet200ResponseInnerSubscription
   */
  cancelledAt?: number | null;
  /**
   *
   * @type {number}
   * @memberof CreatorCreatorSlugCommunitiesGet200ResponseInnerSubscription
   */
  price?: number;
  /**
   *
   * @type {boolean}
   * @memberof CreatorCreatorSlugCommunitiesGet200ResponseInnerSubscription
   */
  isActive?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof CreatorCreatorSlugCommunitiesGet200ResponseInnerSubscription
   */
  inApp?: boolean;
  /**
   *
   * @type {number}
   * @memberof CreatorCreatorSlugCommunitiesGet200ResponseInnerSubscription
   */
  nextPaymentDate?: number | null;
}

export const CreatorCreatorSlugCommunitiesGet200ResponseInnerSubscriptionTypeEnum = {
  ForeverFree: 'foreverFree',
  FreeTrial: 'freeTrial',
  PaidTrial: 'paidTrial',
  FullPrice: 'fullPrice',
} as const;

export type CreatorCreatorSlugCommunitiesGet200ResponseInnerSubscriptionTypeEnum =
  typeof CreatorCreatorSlugCommunitiesGet200ResponseInnerSubscriptionTypeEnum[keyof typeof CreatorCreatorSlugCommunitiesGet200ResponseInnerSubscriptionTypeEnum];

/**
 *
 * @export
 * @interface CreatorsCreatorIdGet200Response
 */
export interface CreatorsCreatorIdGet200Response {
  /**
   *
   * @type {number}
   * @memberof CreatorsCreatorIdGet200Response
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof CreatorsCreatorIdGet200Response
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof CreatorsCreatorIdGet200Response
   */
  description?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof CreatorsCreatorIdGet200Response
   */
  isAvatar?: boolean;
  /**
   *
   * @type {CreatorCreatorSlugCommunitiesGet200ResponseInnerConfigCommunityReviewsInnerAvatar}
   * @memberof CreatorsCreatorIdGet200Response
   */
  avatar?: CreatorCreatorSlugCommunitiesGet200ResponseInnerConfigCommunityReviewsInnerAvatar | null;
  /**
   *
   * @type {number}
   * @memberof CreatorsCreatorIdGet200Response
   */
  subscriptionPrice?: number | null;
  /**
   *
   * @type {Array<CreatorsCreatorIdGet200ResponseSpacesInner>}
   * @memberof CreatorsCreatorIdGet200Response
   */
  spaces?: Array<CreatorsCreatorIdGet200ResponseSpacesInner>;
  /**
   *
   * @type {boolean}
   * @memberof CreatorsCreatorIdGet200Response
   */
  isFollowed?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof CreatorsCreatorIdGet200Response
   */
  isSubscribed?: boolean;
}
/**
 *
 * @export
 * @interface CreatorsCreatorIdGet200ResponseSpacesInner
 */
export interface CreatorsCreatorIdGet200ResponseSpacesInner {
  /**
   *
   * @type {number}
   * @memberof CreatorsCreatorIdGet200ResponseSpacesInner
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof CreatorsCreatorIdGet200ResponseSpacesInner
   */
  title?: string;
  /**
   *
   * @type {number}
   * @memberof CreatorsCreatorIdGet200ResponseSpacesInner
   */
  price?: number;
  /**
   *
   * @type {string}
   * @memberof CreatorsCreatorIdGet200ResponseSpacesInner
   */
  type?: CreatorsCreatorIdGet200ResponseSpacesInnerTypeEnum;
  /**
   *
   * @type {string}
   * @memberof CreatorsCreatorIdGet200ResponseSpacesInner
   */
  payment_type?: CreatorsCreatorIdGet200ResponseSpacesInnerPaymentTypeEnum;
  /**
   *
   * @type {number}
   * @memberof CreatorsCreatorIdGet200ResponseSpacesInner
   */
  coverFileId?: number;
  /**
   *
   * @type {CreatorsCreatorIdGet200ResponseSpacesInnerConfig}
   * @memberof CreatorsCreatorIdGet200ResponseSpacesInner
   */
  config?: CreatorsCreatorIdGet200ResponseSpacesInnerConfig | null;
  /**
   *
   * @type {boolean}
   * @memberof CreatorsCreatorIdGet200ResponseSpacesInner
   */
  isPurchased?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof CreatorsCreatorIdGet200ResponseSpacesInner
   */
  isTrial?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof CreatorsCreatorIdGet200ResponseSpacesInner
   */
  isSubscribed?: boolean;
  /**
   *
   * @type {CreatorCreatorSlugCommunitiesGet200ResponseInnerConfigCommunityReviewsInnerAvatar}
   * @memberof CreatorsCreatorIdGet200ResponseSpacesInner
   */
  cover?: CreatorCreatorSlugCommunitiesGet200ResponseInnerConfigCommunityReviewsInnerAvatar | null;
}

export const CreatorsCreatorIdGet200ResponseSpacesInnerTypeEnum = {
  Post: 'post',
  Live: 'live',
  Challenge: 'challenge',
  Trailer: 'trailer',
  Channel: 'channel',
  Community: 'community',
} as const;

export type CreatorsCreatorIdGet200ResponseSpacesInnerTypeEnum =
  typeof CreatorsCreatorIdGet200ResponseSpacesInnerTypeEnum[keyof typeof CreatorsCreatorIdGet200ResponseSpacesInnerTypeEnum];
export const CreatorsCreatorIdGet200ResponseSpacesInnerPaymentTypeEnum = {
  FixedPrice: 'fixed_price',
  FixedPriceSubscribersOnly: 'fixed_price_subscribers_only',
  SubscribersOnly: 'subscribers_only',
  Free: 'free',
  MonthlyPrice: 'monthly_price',
} as const;

export type CreatorsCreatorIdGet200ResponseSpacesInnerPaymentTypeEnum =
  typeof CreatorsCreatorIdGet200ResponseSpacesInnerPaymentTypeEnum[keyof typeof CreatorsCreatorIdGet200ResponseSpacesInnerPaymentTypeEnum];

/**
 *
 * @export
 * @interface CreatorsCreatorIdGet200ResponseSpacesInnerConfig
 */
export interface CreatorsCreatorIdGet200ResponseSpacesInnerConfig {
  /**
   *
   * @type {CreatorsCreatorIdSpacesSpaceIdGet200ResponseConfigChallenge}
   * @memberof CreatorsCreatorIdGet200ResponseSpacesInnerConfig
   */
  challenge?: CreatorsCreatorIdSpacesSpaceIdGet200ResponseConfigChallenge | null;
}
/**
 *
 * @export
 * @interface CreatorsCreatorIdSpacesSpaceIdGet200Response
 */
export interface CreatorsCreatorIdSpacesSpaceIdGet200Response {
  /**
   *
   * @type {number}
   * @memberof CreatorsCreatorIdSpacesSpaceIdGet200Response
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof CreatorsCreatorIdSpacesSpaceIdGet200Response
   */
  title?: string;
  /**
   *
   * @type {number}
   * @memberof CreatorsCreatorIdSpacesSpaceIdGet200Response
   */
  price?: number;
  /**
   *
   * @type {string}
   * @memberof CreatorsCreatorIdSpacesSpaceIdGet200Response
   */
  type?: CreatorsCreatorIdSpacesSpaceIdGet200ResponseTypeEnum;
  /**
   *
   * @type {string}
   * @memberof CreatorsCreatorIdSpacesSpaceIdGet200Response
   */
  payment_type?: CreatorsCreatorIdSpacesSpaceIdGet200ResponsePaymentTypeEnum;
  /**
   *
   * @type {CreatorsCreatorIdSpacesSpaceIdGet200ResponseConfig}
   * @memberof CreatorsCreatorIdSpacesSpaceIdGet200Response
   */
  config?: CreatorsCreatorIdSpacesSpaceIdGet200ResponseConfig | null;
  /**
   *
   * @type {CreatorsCreatorIdSpacesSpaceIdGet200ResponseMarketingPriceConfig}
   * @memberof CreatorsCreatorIdSpacesSpaceIdGet200Response
   */
  marketingPriceConfig?: CreatorsCreatorIdSpacesSpaceIdGet200ResponseMarketingPriceConfig;
  /**
   *
   * @type {string}
   * @memberof CreatorsCreatorIdSpacesSpaceIdGet200Response
   */
  slug?: string;
  /**
   *
   * @type {string}
   * @memberof CreatorsCreatorIdSpacesSpaceIdGet200Response
   */
  userSlug?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof CreatorsCreatorIdSpacesSpaceIdGet200Response
   */
  isPurchased?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof CreatorsCreatorIdSpacesSpaceIdGet200Response
   */
  isSubscribed?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof CreatorsCreatorIdSpacesSpaceIdGet200Response
   */
  isTrial?: boolean;
  /**
   *
   * @type {CreatorCreatorSlugCommunitiesGet200ResponseInnerConfigCommunityReviewsInnerAvatar}
   * @memberof CreatorsCreatorIdSpacesSpaceIdGet200Response
   */
  cover?: CreatorCreatorSlugCommunitiesGet200ResponseInnerConfigCommunityReviewsInnerAvatar | null;
  /**
   *
   * @type {number}
   * @memberof CreatorsCreatorIdSpacesSpaceIdGet200Response
   */
  chatId?: number | null;
  /**
   *
   * @type {CreatorsCreatorIdSpacesSpaceIdGet200ResponseCreator}
   * @memberof CreatorsCreatorIdSpacesSpaceIdGet200Response
   */
  creator?: CreatorsCreatorIdSpacesSpaceIdGet200ResponseCreator | null;
  /**
   *
   * @type {boolean}
   * @memberof CreatorsCreatorIdSpacesSpaceIdGet200Response
   */
  isFollowed?: boolean;
}

export const CreatorsCreatorIdSpacesSpaceIdGet200ResponseTypeEnum = {
  Post: 'post',
  Live: 'live',
  Challenge: 'challenge',
  Trailer: 'trailer',
  Channel: 'channel',
  Community: 'community',
} as const;

export type CreatorsCreatorIdSpacesSpaceIdGet200ResponseTypeEnum =
  typeof CreatorsCreatorIdSpacesSpaceIdGet200ResponseTypeEnum[keyof typeof CreatorsCreatorIdSpacesSpaceIdGet200ResponseTypeEnum];
export const CreatorsCreatorIdSpacesSpaceIdGet200ResponsePaymentTypeEnum = {
  FixedPrice: 'fixed_price',
  FixedPriceSubscribersOnly: 'fixed_price_subscribers_only',
  SubscribersOnly: 'subscribers_only',
  Free: 'free',
  MonthlyPrice: 'monthly_price',
} as const;

export type CreatorsCreatorIdSpacesSpaceIdGet200ResponsePaymentTypeEnum =
  typeof CreatorsCreatorIdSpacesSpaceIdGet200ResponsePaymentTypeEnum[keyof typeof CreatorsCreatorIdSpacesSpaceIdGet200ResponsePaymentTypeEnum];

/**
 *
 * @export
 * @interface CreatorsCreatorIdSpacesSpaceIdGet200ResponseConfig
 */
export interface CreatorsCreatorIdSpacesSpaceIdGet200ResponseConfig {
  /**
   *
   * @type {CreatorsCreatorIdSpacesSpaceIdGet200ResponseConfigChallenge}
   * @memberof CreatorsCreatorIdSpacesSpaceIdGet200ResponseConfig
   */
  challenge?: CreatorsCreatorIdSpacesSpaceIdGet200ResponseConfigChallenge | null;
  /**
   *
   * @type {CreatorsCreatorIdSpacesSpaceIdGet200ResponseConfigLive}
   * @memberof CreatorsCreatorIdSpacesSpaceIdGet200ResponseConfig
   */
  live?: CreatorsCreatorIdSpacesSpaceIdGet200ResponseConfigLive | null;
}
/**
 *
 * @export
 * @interface CreatorsCreatorIdSpacesSpaceIdGet200ResponseConfigChallenge
 */
export interface CreatorsCreatorIdSpacesSpaceIdGet200ResponseConfigChallenge {
  /**
   *
   * @type {number}
   * @memberof CreatorsCreatorIdSpacesSpaceIdGet200ResponseConfigChallenge
   */
  finishTimestamp?: number | null;
}
/**
 *
 * @export
 * @interface CreatorsCreatorIdSpacesSpaceIdGet200ResponseConfigLive
 */
export interface CreatorsCreatorIdSpacesSpaceIdGet200ResponseConfigLive {
  /**
   *
   * @type {number}
   * @memberof CreatorsCreatorIdSpacesSpaceIdGet200ResponseConfigLive
   */
  startAt?: number;
  /**
   *
   * @type {number}
   * @memberof CreatorsCreatorIdSpacesSpaceIdGet200ResponseConfigLive
   */
  duration?: number;
  /**
   *
   * @type {string}
   * @memberof CreatorsCreatorIdSpacesSpaceIdGet200ResponseConfigLive
   */
  linkToZoom?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreatorsCreatorIdSpacesSpaceIdGet200ResponseConfigLive
   */
  requirements?: string | null;
  /**
   *
   * @type {CreatorsCreatorIdSpacesSpaceIdGet200ResponseConfigLiveRecord}
   * @memberof CreatorsCreatorIdSpacesSpaceIdGet200ResponseConfigLive
   */
  record?: CreatorsCreatorIdSpacesSpaceIdGet200ResponseConfigLiveRecord | null;
  /**
   *
   * @type {Array<CreatorsCreatorIdSpacesSpaceIdGet200ResponseConfigLiveTestimonialsInner>}
   * @memberof CreatorsCreatorIdSpacesSpaceIdGet200ResponseConfigLive
   */
  testimonials?: Array<CreatorsCreatorIdSpacesSpaceIdGet200ResponseConfigLiveTestimonialsInner>;
}
/**
 *
 * @export
 * @interface CreatorsCreatorIdSpacesSpaceIdGet200ResponseConfigLiveRecord
 */
export interface CreatorsCreatorIdSpacesSpaceIdGet200ResponseConfigLiveRecord {
  /**
   *
   * @type {object}
   * @memberof CreatorsCreatorIdSpacesSpaceIdGet200ResponseConfigLiveRecord
   */
  meta?: object | null;
  /**
   *
   * @type {CreatorCreatorSlugCommunitiesGet200ResponseInnerConfigCommunityReviewsInnerAvatar}
   * @memberof CreatorsCreatorIdSpacesSpaceIdGet200ResponseConfigLiveRecord
   */
  video?: CreatorCreatorSlugCommunitiesGet200ResponseInnerConfigCommunityReviewsInnerAvatar | null;
  /**
   *
   * @type {CreatorCreatorSlugCommunitiesGet200ResponseInnerConfigCommunityReviewsInnerAvatar}
   * @memberof CreatorsCreatorIdSpacesSpaceIdGet200ResponseConfigLiveRecord
   */
  cover?: CreatorCreatorSlugCommunitiesGet200ResponseInnerConfigCommunityReviewsInnerAvatar | null;
}
/**
 *
 * @export
 * @interface CreatorsCreatorIdSpacesSpaceIdGet200ResponseConfigLiveTestimonialsInner
 */
export interface CreatorsCreatorIdSpacesSpaceIdGet200ResponseConfigLiveTestimonialsInner {
  /**
   *
   * @type {string}
   * @memberof CreatorsCreatorIdSpacesSpaceIdGet200ResponseConfigLiveTestimonialsInner
   */
  title?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreatorsCreatorIdSpacesSpaceIdGet200ResponseConfigLiveTestimonialsInner
   */
  authorName?: string;
  /**
   *
   * @type {string}
   * @memberof CreatorsCreatorIdSpacesSpaceIdGet200ResponseConfigLiveTestimonialsInner
   */
  review?: string;
  /**
   *
   * @type {CreatorCreatorSlugCommunitiesGet200ResponseInnerConfigCommunityReviewsInnerAvatar}
   * @memberof CreatorsCreatorIdSpacesSpaceIdGet200ResponseConfigLiveTestimonialsInner
   */
  avatar?: CreatorCreatorSlugCommunitiesGet200ResponseInnerConfigCommunityReviewsInnerAvatar | null;
}
/**
 *
 * @export
 * @interface CreatorsCreatorIdSpacesSpaceIdGet200ResponseCreator
 */
export interface CreatorsCreatorIdSpacesSpaceIdGet200ResponseCreator {
  /**
   *
   * @type {number}
   * @memberof CreatorsCreatorIdSpacesSpaceIdGet200ResponseCreator
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof CreatorsCreatorIdSpacesSpaceIdGet200ResponseCreator
   */
  name?: string;
  /**
   *
   * @type {CreatorCreatorSlugCommunitiesGet200ResponseInnerConfigCommunityReviewsInnerAvatar}
   * @memberof CreatorsCreatorIdSpacesSpaceIdGet200ResponseCreator
   */
  avatar?: CreatorCreatorSlugCommunitiesGet200ResponseInnerConfigCommunityReviewsInnerAvatar | null;
}
/**
 *
 * @export
 * @interface CreatorsCreatorIdSpacesSpaceIdGet200ResponseMarketingPriceConfig
 */
export interface CreatorsCreatorIdSpacesSpaceIdGet200ResponseMarketingPriceConfig {
  /**
   *
   * @type {number}
   * @memberof CreatorsCreatorIdSpacesSpaceIdGet200ResponseMarketingPriceConfig
   */
  previousPrice?: number | null;
  /**
   *
   * @type {number}
   * @memberof CreatorsCreatorIdSpacesSpaceIdGet200ResponseMarketingPriceConfig
   */
  iosPrice?: number;
}
/**
 *
 * @export
 * @interface CreatorsCreatorIdSpacesSpaceIdMembersGet200Response
 */
export interface CreatorsCreatorIdSpacesSpaceIdMembersGet200Response {
  /**
   *
   * @type {number}
   * @memberof CreatorsCreatorIdSpacesSpaceIdMembersGet200Response
   */
  membersCountNumber?: number;
  /**
   *
   * @type {number}
   * @memberof CreatorsCreatorIdSpacesSpaceIdMembersGet200Response
   */
  trialsCountNumber?: number;
}
/**
 *
 * @export
 * @interface DiscoverClubsCategoriesCategoryIdGet200Response
 */
export interface DiscoverClubsCategoriesCategoryIdGet200Response {
  /**
   *
   * @type {number}
   * @memberof DiscoverClubsCategoriesCategoryIdGet200Response
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof DiscoverClubsCategoriesCategoryIdGet200Response
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof DiscoverClubsCategoriesCategoryIdGet200Response
   */
  madeBy?: DiscoverClubsCategoriesCategoryIdGet200ResponseMadeByEnum;
  /**
   *
   * @type {boolean}
   * @memberof DiscoverClubsCategoriesCategoryIdGet200Response
   */
  isCompilation?: boolean;
  /**
   *
   * @type {string}
   * @memberof DiscoverClubsCategoriesCategoryIdGet200Response
   */
  size?: DiscoverClubsCategoriesCategoryIdGet200ResponseSizeEnum;
  /**
   *
   * @type {CreatorCreatorSlugCommunitiesGet200ResponseInnerConfigCommunityReviewsInnerAvatar}
   * @memberof DiscoverClubsCategoriesCategoryIdGet200Response
   */
  cover?: CreatorCreatorSlugCommunitiesGet200ResponseInnerConfigCommunityReviewsInnerAvatar | null;
  /**
   *
   * @type {Array<DiscoverClubsCategoriesCategoryIdGet200ResponseClubsIdsInner>}
   * @memberof DiscoverClubsCategoriesCategoryIdGet200Response
   */
  clubsIds?: Array<DiscoverClubsCategoriesCategoryIdGet200ResponseClubsIdsInner>;
}

export const DiscoverClubsCategoriesCategoryIdGet200ResponseMadeByEnum = {
  Manual: 'manual',
  Popular: 'popular',
} as const;

export type DiscoverClubsCategoriesCategoryIdGet200ResponseMadeByEnum =
  typeof DiscoverClubsCategoriesCategoryIdGet200ResponseMadeByEnum[keyof typeof DiscoverClubsCategoriesCategoryIdGet200ResponseMadeByEnum];
export const DiscoverClubsCategoriesCategoryIdGet200ResponseSizeEnum = {
  Small: 'small',
  Big: 'big',
} as const;

export type DiscoverClubsCategoriesCategoryIdGet200ResponseSizeEnum =
  typeof DiscoverClubsCategoriesCategoryIdGet200ResponseSizeEnum[keyof typeof DiscoverClubsCategoriesCategoryIdGet200ResponseSizeEnum];

/**
 *
 * @export
 * @interface DiscoverClubsCategoriesCategoryIdGet200ResponseClubsIdsInner
 */
export interface DiscoverClubsCategoriesCategoryIdGet200ResponseClubsIdsInner {
  /**
   *
   * @type {number}
   * @memberof DiscoverClubsCategoriesCategoryIdGet200ResponseClubsIdsInner
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof DiscoverClubsCategoriesCategoryIdGet200ResponseClubsIdsInner
   */
  createdAt?: number;
  /**
   *
   * @type {number}
   * @memberof DiscoverClubsCategoriesCategoryIdGet200ResponseClubsIdsInner
   */
  deletedAt?: number | null;
  /**
   *
   * @type {number}
   * @memberof DiscoverClubsCategoriesCategoryIdGet200ResponseClubsIdsInner
   */
  clubId?: number;
  /**
   *
   * @type {number}
   * @memberof DiscoverClubsCategoriesCategoryIdGet200ResponseClubsIdsInner
   */
  categoryId?: number;
  /**
   *
   * @type {number}
   * @memberof DiscoverClubsCategoriesCategoryIdGet200ResponseClubsIdsInner
   */
  order?: number | null;
}
/**
 *
 * @export
 * @interface DiscoverClubsCategoriesGet200ResponseInner
 */
export interface DiscoverClubsCategoriesGet200ResponseInner {
  /**
   *
   * @type {number}
   * @memberof DiscoverClubsCategoriesGet200ResponseInner
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof DiscoverClubsCategoriesGet200ResponseInner
   */
  createdAt?: number;
  /**
   *
   * @type {number}
   * @memberof DiscoverClubsCategoriesGet200ResponseInner
   */
  updatedAt?: number | null;
  /**
   *
   * @type {number}
   * @memberof DiscoverClubsCategoriesGet200ResponseInner
   */
  deletedAt?: number | null;
  /**
   *
   * @type {string}
   * @memberof DiscoverClubsCategoriesGet200ResponseInner
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof DiscoverClubsCategoriesGet200ResponseInner
   */
  madeBy?: DiscoverClubsCategoriesGet200ResponseInnerMadeByEnum;
  /**
   *
   * @type {number}
   * @memberof DiscoverClubsCategoriesGet200ResponseInner
   */
  order?: number;
  /**
   *
   * @type {object}
   * @memberof DiscoverClubsCategoriesGet200ResponseInner
   */
  config?: object | null;
  /**
   *
   * @type {boolean}
   * @memberof DiscoverClubsCategoriesGet200ResponseInner
   */
  isCompilation?: boolean;
  /**
   *
   * @type {number}
   * @memberof DiscoverClubsCategoriesGet200ResponseInner
   */
  coverFileId?: number | null;
  /**
   *
   * @type {string}
   * @memberof DiscoverClubsCategoriesGet200ResponseInner
   */
  size?: DiscoverClubsCategoriesGet200ResponseInnerSizeEnum;
  /**
   *
   * @type {Array<DiscoverClubsCategoriesCategoryIdGet200ResponseClubsIdsInner>}
   * @memberof DiscoverClubsCategoriesGet200ResponseInner
   */
  clubsIds?: Array<DiscoverClubsCategoriesCategoryIdGet200ResponseClubsIdsInner>;
  /**
   *
   * @type {CreatorCreatorSlugCommunitiesGet200ResponseInnerConfigCommunityReviewsInnerAvatar}
   * @memberof DiscoverClubsCategoriesGet200ResponseInner
   */
  cover?: CreatorCreatorSlugCommunitiesGet200ResponseInnerConfigCommunityReviewsInnerAvatar | null;
}

export const DiscoverClubsCategoriesGet200ResponseInnerMadeByEnum = {
  Manual: 'manual',
  Popular: 'popular',
} as const;

export type DiscoverClubsCategoriesGet200ResponseInnerMadeByEnum =
  typeof DiscoverClubsCategoriesGet200ResponseInnerMadeByEnum[keyof typeof DiscoverClubsCategoriesGet200ResponseInnerMadeByEnum];
export const DiscoverClubsCategoriesGet200ResponseInnerSizeEnum = {
  Small: 'small',
  Big: 'big',
} as const;

export type DiscoverClubsCategoriesGet200ResponseInnerSizeEnum =
  typeof DiscoverClubsCategoriesGet200ResponseInnerSizeEnum[keyof typeof DiscoverClubsCategoriesGet200ResponseInnerSizeEnum];

/**
 *
 * @export
 * @interface DiscoverSpacesCategoriesGet200ResponseInner
 */
export interface DiscoverSpacesCategoriesGet200ResponseInner {
  /**
   *
   * @type {number}
   * @memberof DiscoverSpacesCategoriesGet200ResponseInner
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof DiscoverSpacesCategoriesGet200ResponseInner
   */
  createdAt?: number;
  /**
   *
   * @type {number}
   * @memberof DiscoverSpacesCategoriesGet200ResponseInner
   */
  updatedAt?: number | null;
  /**
   *
   * @type {number}
   * @memberof DiscoverSpacesCategoriesGet200ResponseInner
   */
  deletedAt?: number | null;
  /**
   *
   * @type {string}
   * @memberof DiscoverSpacesCategoriesGet200ResponseInner
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof DiscoverSpacesCategoriesGet200ResponseInner
   */
  madeBy?: DiscoverSpacesCategoriesGet200ResponseInnerMadeByEnum;
  /**
   *
   * @type {number}
   * @memberof DiscoverSpacesCategoriesGet200ResponseInner
   */
  order?: number;
  /**
   *
   * @type {object}
   * @memberof DiscoverSpacesCategoriesGet200ResponseInner
   */
  config?: object | null;
  /**
   *
   * @type {boolean}
   * @memberof DiscoverSpacesCategoriesGet200ResponseInner
   */
  isCompilation?: boolean;
  /**
   *
   * @type {number}
   * @memberof DiscoverSpacesCategoriesGet200ResponseInner
   */
  coverFileId?: number | null;
  /**
   *
   * @type {string}
   * @memberof DiscoverSpacesCategoriesGet200ResponseInner
   */
  size?: DiscoverSpacesCategoriesGet200ResponseInnerSizeEnum;
  /**
   *
   * @type {Array<DiscoverSpacesCategoriesGet200ResponseInnerSpacesIdsInner>}
   * @memberof DiscoverSpacesCategoriesGet200ResponseInner
   */
  spacesIds?: Array<DiscoverSpacesCategoriesGet200ResponseInnerSpacesIdsInner>;
  /**
   *
   * @type {CreatorCreatorSlugCommunitiesGet200ResponseInnerConfigCommunityReviewsInnerAvatar}
   * @memberof DiscoverSpacesCategoriesGet200ResponseInner
   */
  cover?: CreatorCreatorSlugCommunitiesGet200ResponseInnerConfigCommunityReviewsInnerAvatar | null;
}

export const DiscoverSpacesCategoriesGet200ResponseInnerMadeByEnum = {
  Manual: 'manual',
  Popular: 'popular',
  All: 'all',
} as const;

export type DiscoverSpacesCategoriesGet200ResponseInnerMadeByEnum =
  typeof DiscoverSpacesCategoriesGet200ResponseInnerMadeByEnum[keyof typeof DiscoverSpacesCategoriesGet200ResponseInnerMadeByEnum];
export const DiscoverSpacesCategoriesGet200ResponseInnerSizeEnum = {
  Small: 'small',
  Big: 'big',
} as const;

export type DiscoverSpacesCategoriesGet200ResponseInnerSizeEnum =
  typeof DiscoverSpacesCategoriesGet200ResponseInnerSizeEnum[keyof typeof DiscoverSpacesCategoriesGet200ResponseInnerSizeEnum];

/**
 *
 * @export
 * @interface DiscoverSpacesCategoriesGet200ResponseInnerSpacesIdsInner
 */
export interface DiscoverSpacesCategoriesGet200ResponseInnerSpacesIdsInner {
  /**
   *
   * @type {number}
   * @memberof DiscoverSpacesCategoriesGet200ResponseInnerSpacesIdsInner
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof DiscoverSpacesCategoriesGet200ResponseInnerSpacesIdsInner
   */
  createdAt?: number;
  /**
   *
   * @type {number}
   * @memberof DiscoverSpacesCategoriesGet200ResponseInnerSpacesIdsInner
   */
  deletedAt?: number | null;
  /**
   *
   * @type {number}
   * @memberof DiscoverSpacesCategoriesGet200ResponseInnerSpacesIdsInner
   */
  spaceId?: number;
  /**
   *
   * @type {number}
   * @memberof DiscoverSpacesCategoriesGet200ResponseInnerSpacesIdsInner
   */
  categoryId?: number;
  /**
   *
   * @type {number}
   * @memberof DiscoverSpacesCategoriesGet200ResponseInnerSpacesIdsInner
   */
  order?: number | null;
}
/**
 *
 * @export
 * @interface ExpertExpertIdSpacesGet200ResponseInner
 */
export interface ExpertExpertIdSpacesGet200ResponseInner {
  /**
   *
   * @type {number}
   * @memberof ExpertExpertIdSpacesGet200ResponseInner
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof ExpertExpertIdSpacesGet200ResponseInner
   */
  expert?: number;
  /**
   *
   * @type {string}
   * @memberof ExpertExpertIdSpacesGet200ResponseInner
   */
  title?: string;
  /**
   *
   * @type {number}
   * @memberof ExpertExpertIdSpacesGet200ResponseInner
   */
  price?: number;
  /**
   *
   * @type {object}
   * @memberof ExpertExpertIdSpacesGet200ResponseInner
   */
  content?: object | null;
  /**
   *
   * @type {object}
   * @memberof ExpertExpertIdSpacesGet200ResponseInner
   */
  teaser?: object;
  /**
   *
   * @type {string}
   * @memberof ExpertExpertIdSpacesGet200ResponseInner
   */
  type?: ExpertExpertIdSpacesGet200ResponseInnerTypeEnum;
  /**
   *
   * @type {string}
   * @memberof ExpertExpertIdSpacesGet200ResponseInner
   */
  status?: ExpertExpertIdSpacesGet200ResponseInnerStatusEnum;
  /**
   *
   * @type {string}
   * @memberof ExpertExpertIdSpacesGet200ResponseInner
   */
  payment_type?: ExpertExpertIdSpacesGet200ResponseInnerPaymentTypeEnum;
  /**
   *
   * @type {object}
   * @memberof ExpertExpertIdSpacesGet200ResponseInner
   */
  meta?: object | null;
  /**
   *
   * @type {CreatorsCreatorIdGet200ResponseSpacesInnerConfig}
   * @memberof ExpertExpertIdSpacesGet200ResponseInner
   */
  config?: CreatorsCreatorIdGet200ResponseSpacesInnerConfig | null;
  /**
   *
   * @type {ExpertExpertIdSpacesGet200ResponseInnerMarketingPriceConfig}
   * @memberof ExpertExpertIdSpacesGet200ResponseInner
   */
  marketingPriceConfig?: ExpertExpertIdSpacesGet200ResponseInnerMarketingPriceConfig;
  /**
   *
   * @type {boolean}
   * @memberof ExpertExpertIdSpacesGet200ResponseInner
   */
  isPurchased?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ExpertExpertIdSpacesGet200ResponseInner
   */
  isSubscribed?: boolean;
  /**
   *
   * @type {string}
   * @memberof ExpertExpertIdSpacesGet200ResponseInner
   */
  slug?: string;
  /**
   *
   * @type {string}
   * @memberof ExpertExpertIdSpacesGet200ResponseInner
   */
  userSlug?: string | null;
  /**
   *
   * @type {number}
   * @memberof ExpertExpertIdSpacesGet200ResponseInner
   */
  chatId?: number | null;
}

export const ExpertExpertIdSpacesGet200ResponseInnerTypeEnum = {
  Post: 'post',
  Live: 'live',
  Challenge: 'challenge',
  Trailer: 'trailer',
  Channel: 'channel',
  Community: 'community',
} as const;

export type ExpertExpertIdSpacesGet200ResponseInnerTypeEnum =
  typeof ExpertExpertIdSpacesGet200ResponseInnerTypeEnum[keyof typeof ExpertExpertIdSpacesGet200ResponseInnerTypeEnum];
export const ExpertExpertIdSpacesGet200ResponseInnerStatusEnum = {
  Draft: 'draft',
  Published: 'published',
} as const;

export type ExpertExpertIdSpacesGet200ResponseInnerStatusEnum =
  typeof ExpertExpertIdSpacesGet200ResponseInnerStatusEnum[keyof typeof ExpertExpertIdSpacesGet200ResponseInnerStatusEnum];
export const ExpertExpertIdSpacesGet200ResponseInnerPaymentTypeEnum = {
  FixedPrice: 'fixed_price',
  FixedPriceSubscribersOnly: 'fixed_price_subscribers_only',
  SubscribersOnly: 'subscribers_only',
  Free: 'free',
  MonthlyPrice: 'monthly_price',
} as const;

export type ExpertExpertIdSpacesGet200ResponseInnerPaymentTypeEnum =
  typeof ExpertExpertIdSpacesGet200ResponseInnerPaymentTypeEnum[keyof typeof ExpertExpertIdSpacesGet200ResponseInnerPaymentTypeEnum];

/**
 *
 * @export
 * @interface ExpertExpertIdSpacesGet200ResponseInnerMarketingPriceConfig
 */
export interface ExpertExpertIdSpacesGet200ResponseInnerMarketingPriceConfig {
  /**
   *
   * @type {number}
   * @memberof ExpertExpertIdSpacesGet200ResponseInnerMarketingPriceConfig
   */
  previousPrice?: number | null;
}
/**
 *
 * @export
 * @interface ExpertGet200ResponseInner
 */
export interface ExpertGet200ResponseInner {
  /**
   *
   * @type {number}
   * @memberof ExpertGet200ResponseInner
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof ExpertGet200ResponseInner
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof ExpertGet200ResponseInner
   */
  description?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof ExpertGet200ResponseInner
   */
  isAvatar?: boolean;
  /**
   *
   * @type {string}
   * @memberof ExpertGet200ResponseInner
   */
  slug?: string;
  /**
   *
   * @type {boolean}
   * @memberof ExpertGet200ResponseInner
   */
  isSubscribed?: boolean;
  /**
   *
   * @type {number}
   * @memberof ExpertGet200ResponseInner
   */
  subscriptionPrice?: number | null;
  /**
   *
   * @type {boolean}
   * @memberof ExpertGet200ResponseInner
   */
  isExpert?: boolean;
  /**
   *
   * @type {Array<ExpertGet200ResponseInnerLinksInner>}
   * @memberof ExpertGet200ResponseInner
   */
  links?: Array<ExpertGet200ResponseInnerLinksInner>;
  /**
   *
   * @type {CreatorCreatorSlugCommunitiesGet200ResponseInnerConfigCommunityReviewsInnerAvatar}
   * @memberof ExpertGet200ResponseInner
   */
  avatar?: CreatorCreatorSlugCommunitiesGet200ResponseInnerConfigCommunityReviewsInnerAvatar | null;
}
/**
 *
 * @export
 * @interface ExpertGet200ResponseInnerLinksInner
 */
export interface ExpertGet200ResponseInnerLinksInner {
  /**
   *
   * @type {number}
   * @memberof ExpertGet200ResponseInnerLinksInner
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof ExpertGet200ResponseInnerLinksInner
   */
  href?: string;
  /**
   *
   * @type {string}
   * @memberof ExpertGet200ResponseInnerLinksInner
   */
  title?: string;
  /**
   *
   * @type {number}
   * @memberof ExpertGet200ResponseInnerLinksInner
   */
  order?: number;
}
/**
 *
 * @export
 * @interface SpacesSpaceIdChallengeStepsGet200ResponseInner
 */
export interface SpacesSpaceIdChallengeStepsGet200ResponseInner {
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdChallengeStepsGet200ResponseInner
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdChallengeStepsGet200ResponseInner
   */
  daysOffset?: number;
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdChallengeStepsGet200ResponseInner
   */
  previousStep?: number | null;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdChallengeStepsGet200ResponseInner
   */
  type?: SpacesSpaceIdChallengeStepsGet200ResponseInnerTypeEnum;
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdChallengeStepsGet200ResponseInner
   */
  startAt?: number | null;
  /**
   *
   * @type {SpacesSpaceIdChallengeStepsGet200ResponseInnerContent}
   * @memberof SpacesSpaceIdChallengeStepsGet200ResponseInner
   */
  content?: SpacesSpaceIdChallengeStepsGet200ResponseInnerContent | null;
  /**
   *
   * @type {SpacesSpaceIdChallengeStepsGet200ResponseInnerResult}
   * @memberof SpacesSpaceIdChallengeStepsGet200ResponseInner
   */
  result?: SpacesSpaceIdChallengeStepsGet200ResponseInnerResult;
}

export const SpacesSpaceIdChallengeStepsGet200ResponseInnerTypeEnum = {
  Post: 'post',
  LiveStream: 'live_stream',
} as const;

export type SpacesSpaceIdChallengeStepsGet200ResponseInnerTypeEnum =
  typeof SpacesSpaceIdChallengeStepsGet200ResponseInnerTypeEnum[keyof typeof SpacesSpaceIdChallengeStepsGet200ResponseInnerTypeEnum];

/**
 *
 * @export
 * @interface SpacesSpaceIdChallengeStepsGet200ResponseInnerContent
 */
export interface SpacesSpaceIdChallengeStepsGet200ResponseInnerContent {
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdChallengeStepsGet200ResponseInnerContent
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdChallengeStepsGet200ResponseInnerContent
   */
  createdAt?: number;
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdChallengeStepsGet200ResponseInnerContent
   */
  updatedAt?: number | null;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdChallengeStepsGet200ResponseInnerContent
   */
  title?: string;
  /**
   *
   * @type {Array<SpacesSpaceIdChallengeStepsGet200ResponseInnerContentSlidesInner>}
   * @memberof SpacesSpaceIdChallengeStepsGet200ResponseInnerContent
   */
  slides?: Array<SpacesSpaceIdChallengeStepsGet200ResponseInnerContentSlidesInner>;
}
/**
 *
 * @export
 * @interface SpacesSpaceIdChallengeStepsGet200ResponseInnerContentSlidesInner
 */
export interface SpacesSpaceIdChallengeStepsGet200ResponseInnerContentSlidesInner {
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdChallengeStepsGet200ResponseInnerContentSlidesInner
   */
  url?: string;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdChallengeStepsGet200ResponseInnerContentSlidesInner
   */
  caption?: string | null;
}
/**
 *
 * @export
 * @interface SpacesSpaceIdChallengeStepsGet200ResponseInnerResult
 */
export interface SpacesSpaceIdChallengeStepsGet200ResponseInnerResult {
  /**
   *
   * @type {boolean}
   * @memberof SpacesSpaceIdChallengeStepsGet200ResponseInnerResult
   */
  isSkipped?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof SpacesSpaceIdChallengeStepsGet200ResponseInnerResult
   */
  isDone?: boolean;
}
/**
 *
 * @export
 * @interface SpacesSpaceIdChallengeTrialPost200Response
 */
export interface SpacesSpaceIdChallengeTrialPost200Response {
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdChallengeTrialPost200Response
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdChallengeTrialPost200Response
   */
  createdAt?: number;
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdChallengeTrialPost200Response
   */
  updatedAt?: number | null;
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdChallengeTrialPost200Response
   */
  deletedAt?: number | null;
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdChallengeTrialPost200Response
   */
  customerId?: number;
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdChallengeTrialPost200Response
   */
  spaceId?: number;
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdChallengeTrialPost200Response
   */
  chatRevokedAt?: number | null;
}
/**
 *
 * @export
 * @interface SpacesSpaceIdFollowPost200Response
 */
export interface SpacesSpaceIdFollowPost200Response {
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdFollowPost200Response
   */
  id?: number;
  /**
   *
   * @type {SpacesSpaceIdFollowPost200ResponseSpace}
   * @memberof SpacesSpaceIdFollowPost200Response
   */
  space?: SpacesSpaceIdFollowPost200ResponseSpace | null;
  /**
   *
   * @type {SpacesSpaceIdFollowPost200ResponseCreator}
   * @memberof SpacesSpaceIdFollowPost200Response
   */
  creator?: SpacesSpaceIdFollowPost200ResponseCreator | null;
}
/**
 *
 * @export
 * @interface SpacesSpaceIdFollowPost200ResponseCreator
 */
export interface SpacesSpaceIdFollowPost200ResponseCreator {
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdFollowPost200ResponseCreator
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdFollowPost200ResponseCreator
   */
  name?: string;
}
/**
 *
 * @export
 * @interface SpacesSpaceIdFollowPost200ResponseSpace
 */
export interface SpacesSpaceIdFollowPost200ResponseSpace {
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdFollowPost200ResponseSpace
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdFollowPost200ResponseSpace
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdFollowPost200ResponseSpace
   */
  type?: SpacesSpaceIdFollowPost200ResponseSpaceTypeEnum;
}

export const SpacesSpaceIdFollowPost200ResponseSpaceTypeEnum = {
  Post: 'post',
  Live: 'live',
  Challenge: 'challenge',
  Trailer: 'trailer',
  Channel: 'channel',
  Community: 'community',
} as const;

export type SpacesSpaceIdFollowPost200ResponseSpaceTypeEnum =
  typeof SpacesSpaceIdFollowPost200ResponseSpaceTypeEnum[keyof typeof SpacesSpaceIdFollowPost200ResponseSpaceTypeEnum];

/**
 *
 * @export
 * @interface SpacesSpaceIdGet200Response
 */
export interface SpacesSpaceIdGet200Response {
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdGet200Response
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdGet200Response
   */
  publishedAt?: number | null;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdGet200Response
   */
  title?: string;
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdGet200Response
   */
  price?: number;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdGet200Response
   */
  type?: SpacesSpaceIdGet200ResponseTypeEnum;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdGet200Response
   */
  payment_type?: SpacesSpaceIdGet200ResponsePaymentTypeEnum;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdGet200Response
   */
  iosProductId?: string | null;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdGet200Response
   */
  description?: string | null;
  /**
   *
   * @type {SpacesSpaceIdGet200ResponseConfig}
   * @memberof SpacesSpaceIdGet200Response
   */
  config?: SpacesSpaceIdGet200ResponseConfig | null;
  /**
   *
   * @type {CreatorsCreatorIdSpacesSpaceIdGet200ResponseMarketingPriceConfig}
   * @memberof SpacesSpaceIdGet200Response
   */
  marketingPriceConfig?: CreatorsCreatorIdSpacesSpaceIdGet200ResponseMarketingPriceConfig;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdGet200Response
   */
  slug?: string;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdGet200Response
   */
  userSlug?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof SpacesSpaceIdGet200Response
   */
  isPurchased?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof SpacesSpaceIdGet200Response
   */
  isSubscribed?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof SpacesSpaceIdGet200Response
   */
  isTrial?: boolean;
  /**
   *
   * @type {CreatorCreatorSlugCommunitiesGet200ResponseInnerConfigCommunityReviewsInnerAvatar}
   * @memberof SpacesSpaceIdGet200Response
   */
  cover?: CreatorCreatorSlugCommunitiesGet200ResponseInnerConfigCommunityReviewsInnerAvatar | null;
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdGet200Response
   */
  chatId?: number | null;
  /**
   *
   * @type {CreatorsCreatorIdSpacesSpaceIdGet200ResponseCreator}
   * @memberof SpacesSpaceIdGet200Response
   */
  creator?: CreatorsCreatorIdSpacesSpaceIdGet200ResponseCreator | null;
  /**
   *
   * @type {Array<SpacesSpaceIdGet200ResponseTagsInner>}
   * @memberof SpacesSpaceIdGet200Response
   */
  tags?: Array<SpacesSpaceIdGet200ResponseTagsInner>;
  /**
   *
   * @type {CreatorCreatorSlugCommunitiesGet200ResponseInnerSubscription}
   * @memberof SpacesSpaceIdGet200Response
   */
  subscription?: CreatorCreatorSlugCommunitiesGet200ResponseInnerSubscription | null;
  /**
   *
   * @type {Array<SpacesSpaceIdGet200ResponseLivesInner>}
   * @memberof SpacesSpaceIdGet200Response
   */
  lives?: Array<SpacesSpaceIdGet200ResponseLivesInner>;
}

export const SpacesSpaceIdGet200ResponseTypeEnum = {
  Post: 'post',
  Live: 'live',
  Challenge: 'challenge',
  Trailer: 'trailer',
  Channel: 'channel',
  Community: 'community',
} as const;

export type SpacesSpaceIdGet200ResponseTypeEnum =
  typeof SpacesSpaceIdGet200ResponseTypeEnum[keyof typeof SpacesSpaceIdGet200ResponseTypeEnum];
export const SpacesSpaceIdGet200ResponsePaymentTypeEnum = {
  FixedPrice: 'fixed_price',
  FixedPriceSubscribersOnly: 'fixed_price_subscribers_only',
  SubscribersOnly: 'subscribers_only',
  Free: 'free',
  MonthlyPrice: 'monthly_price',
} as const;

export type SpacesSpaceIdGet200ResponsePaymentTypeEnum =
  typeof SpacesSpaceIdGet200ResponsePaymentTypeEnum[keyof typeof SpacesSpaceIdGet200ResponsePaymentTypeEnum];

/**
 *
 * @export
 * @interface SpacesSpaceIdGet200ResponseConfig
 */
export interface SpacesSpaceIdGet200ResponseConfig {
  /**
   *
   * @type {SpacesSpaceIdGet200ResponseConfigChallenge}
   * @memberof SpacesSpaceIdGet200ResponseConfig
   */
  challenge?: SpacesSpaceIdGet200ResponseConfigChallenge | null;
  /**
   *
   * @type {SpacesSpaceIdGet200ResponseConfigLive}
   * @memberof SpacesSpaceIdGet200ResponseConfig
   */
  live?: SpacesSpaceIdGet200ResponseConfigLive | null;
  /**
   *
   * @type {SpacesSpaceIdGet200ResponseConfigChannel}
   * @memberof SpacesSpaceIdGet200ResponseConfig
   */
  channel?: SpacesSpaceIdGet200ResponseConfigChannel | null;
}
/**
 *
 * @export
 * @interface SpacesSpaceIdGet200ResponseConfigChallenge
 */
export interface SpacesSpaceIdGet200ResponseConfigChallenge {
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdGet200ResponseConfigChallenge
   */
  finishTimestamp?: number | null;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdGet200ResponseConfigChallenge
   */
  offerDescription?: string | null;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdGet200ResponseConfigChallenge
   */
  offerSummary?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof SpacesSpaceIdGet200ResponseConfigChallenge
   */
  isProgram?: boolean;
}
/**
 *
 * @export
 * @interface SpacesSpaceIdGet200ResponseConfigChannel
 */
export interface SpacesSpaceIdGet200ResponseConfigChannel {
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdGet200ResponseConfigChannel
   */
  creatorDescription?: string;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdGet200ResponseConfigChannel
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdGet200ResponseConfigChannel
   */
  targetAudience?: string;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdGet200ResponseConfigChannel
   */
  contentFormat?: string;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdGet200ResponseConfigChannel
   */
  updateFrequency?: SpacesSpaceIdGet200ResponseConfigChannelUpdateFrequencyEnum;
}

export const SpacesSpaceIdGet200ResponseConfigChannelUpdateFrequencyEnum = {
  Daily: 'daily',
  TwoThreePerWeek: 'twoThreePerWeek',
  Weekly: 'weekly',
  TwoThreePerMonth: 'twoThreePerMonth',
  Occasionally: 'occasionally',
} as const;

export type SpacesSpaceIdGet200ResponseConfigChannelUpdateFrequencyEnum =
  typeof SpacesSpaceIdGet200ResponseConfigChannelUpdateFrequencyEnum[keyof typeof SpacesSpaceIdGet200ResponseConfigChannelUpdateFrequencyEnum];

/**
 *
 * @export
 * @interface SpacesSpaceIdGet200ResponseConfigLive
 */
export interface SpacesSpaceIdGet200ResponseConfigLive {
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdGet200ResponseConfigLive
   */
  startAt?: number;
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdGet200ResponseConfigLive
   */
  duration?: number;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdGet200ResponseConfigLive
   */
  linkToZoom?: string | null;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdGet200ResponseConfigLive
   */
  creatorDescription?: string;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdGet200ResponseConfigLive
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdGet200ResponseConfigLive
   */
  requirements?: string | null;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdGet200ResponseConfigLive
   */
  offerDescription?: string | null;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdGet200ResponseConfigLive
   */
  offerSummary?: string | null;
  /**
   *
   * @type {CreatorsCreatorIdSpacesSpaceIdGet200ResponseConfigLiveRecord}
   * @memberof SpacesSpaceIdGet200ResponseConfigLive
   */
  record?: CreatorsCreatorIdSpacesSpaceIdGet200ResponseConfigLiveRecord | null;
  /**
   *
   * @type {Array<CreatorsCreatorIdSpacesSpaceIdGet200ResponseConfigLiveTestimonialsInner>}
   * @memberof SpacesSpaceIdGet200ResponseConfigLive
   */
  testimonials?: Array<CreatorsCreatorIdSpacesSpaceIdGet200ResponseConfigLiveTestimonialsInner>;
}
/**
 *
 * @export
 * @interface SpacesSpaceIdGet200ResponseLivesInner
 */
export interface SpacesSpaceIdGet200ResponseLivesInner {
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdGet200ResponseLivesInner
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdGet200ResponseLivesInner
   */
  title?: string;
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdGet200ResponseLivesInner
   */
  startAt?: number;
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdGet200ResponseLivesInner
   */
  duration?: number;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdGet200ResponseLivesInner
   */
  link?: string;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdGet200ResponseLivesInner
   */
  creatorDescription?: string;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdGet200ResponseLivesInner
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdGet200ResponseLivesInner
   */
  requirements?: string;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdGet200ResponseLivesInner
   */
  offerDescription?: string;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdGet200ResponseLivesInner
   */
  offerSummary?: string;
  /**
   *
   * @type {CreatorsCreatorIdSpacesSpaceIdGet200ResponseConfigLiveRecord}
   * @memberof SpacesSpaceIdGet200ResponseLivesInner
   */
  record?: CreatorsCreatorIdSpacesSpaceIdGet200ResponseConfigLiveRecord | null;
}
/**
 *
 * @export
 * @interface SpacesSpaceIdGet200ResponseTagsInner
 */
export interface SpacesSpaceIdGet200ResponseTagsInner {
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdGet200ResponseTagsInner
   */
  weight?: number;
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdGet200ResponseTagsInner
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdGet200ResponseTagsInner
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdGet200ResponseTagsInner
   */
  color?: string;
}
/**
 *
 * @export
 * @interface SpacesSpaceIdMembersGet200Response
 */
export interface SpacesSpaceIdMembersGet200Response {
  /**
   *
   * @type {Array<SpacesSpaceIdMembersGet200ResponseMembersInner>}
   * @memberof SpacesSpaceIdMembersGet200Response
   */
  members?: Array<SpacesSpaceIdMembersGet200ResponseMembersInner>;
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdMembersGet200Response
   */
  membersCountNumber?: number;
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdMembersGet200Response
   */
  trialsCountNumber?: number;
}
/**
 *
 * @export
 * @interface SpacesSpaceIdMembersGet200ResponseMembersInner
 */
export interface SpacesSpaceIdMembersGet200ResponseMembersInner {
  /**
   *
   * @type {number}
   * @memberof SpacesSpaceIdMembersGet200ResponseMembersInner
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdMembersGet200ResponseMembersInner
   */
  name?: string;
  /**
   *
   * @type {CreatorCreatorSlugCommunitiesGet200ResponseInnerConfigCommunityReviewsInnerAvatar}
   * @memberof SpacesSpaceIdMembersGet200ResponseMembersInner
   */
  avatar?: CreatorCreatorSlugCommunitiesGet200ResponseInnerConfigCommunityReviewsInnerAvatar | null;
  /**
   *
   * @type {string}
   * @memberof SpacesSpaceIdMembersGet200ResponseMembersInner
   */
  slug?: string;
  /**
   *
   * @type {boolean}
   * @memberof SpacesSpaceIdMembersGet200ResponseMembersInner
   */
  isTrial?: boolean;
}
/**
 *
 * @export
 * @interface UserSlugGet200Response
 */
export interface UserSlugGet200Response {
  /**
   *
   * @type {number}
   * @memberof UserSlugGet200Response
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof UserSlugGet200Response
   */
  created_at?: number;
  /**
   *
   * @type {string}
   * @memberof UserSlugGet200Response
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof UserSlugGet200Response
   */
  bio?: string | null;
  /**
   *
   * @type {string}
   * @memberof UserSlugGet200Response
   */
  description?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof UserSlugGet200Response
   */
  isAvatar?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof UserSlugGet200Response
   */
  isExpert?: boolean;
  /**
   *
   * @type {number}
   * @memberof UserSlugGet200Response
   */
  subscriptionPrice?: number | null;
  /**
   *
   * @type {string}
   * @memberof UserSlugGet200Response
   */
  slug?: string;
  /**
   *
   * @type {boolean}
   * @memberof UserSlugGet200Response
   */
  isSubscribed?: boolean;
  /**
   *
   * @type {Array<ExpertGet200ResponseInnerLinksInner>}
   * @memberof UserSlugGet200Response
   */
  links?: Array<ExpertGet200ResponseInnerLinksInner>;
  /**
   *
   * @type {CreatorCreatorSlugCommunitiesGet200ResponseInnerConfigCommunityReviewsInnerAvatar}
   * @memberof UserSlugGet200Response
   */
  avatar?: CreatorCreatorSlugCommunitiesGet200ResponseInnerConfigCommunityReviewsInnerAvatar | null;
  /**
   *
   * @type {Array<UserSlugGet200ResponsePointsInner>}
   * @memberof UserSlugGet200Response
   */
  points?: Array<UserSlugGet200ResponsePointsInner>;
}
/**
 *
 * @export
 * @interface UserSlugGet200ResponsePointsInner
 */
export interface UserSlugGet200ResponsePointsInner {
  /**
   *
   * @type {number}
   * @memberof UserSlugGet200ResponsePointsInner
   */
  points?: number;
  /**
   *
   * @type {UserSlugGet200ResponsePointsInnerTag}
   * @memberof UserSlugGet200ResponsePointsInner
   */
  tag?: UserSlugGet200ResponsePointsInnerTag | null;
}
/**
 *
 * @export
 * @interface UserSlugGet200ResponsePointsInnerTag
 */
export interface UserSlugGet200ResponsePointsInnerTag {
  /**
   *
   * @type {number}
   * @memberof UserSlugGet200ResponsePointsInnerTag
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof UserSlugGet200ResponsePointsInnerTag
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof UserSlugGet200ResponsePointsInnerTag
   */
  color?: string;
}
/**
 *
 * @export
 * @interface UserUserSlugCreatorsGet200Response
 */
export interface UserUserSlugCreatorsGet200Response {
  /**
   *
   * @type {Array<UserUserSlugCreatorsGet200ResponseSubscriptionsInner>}
   * @memberof UserUserSlugCreatorsGet200Response
   */
  subscriptions?: Array<UserUserSlugCreatorsGet200ResponseSubscriptionsInner>;
  /**
   *
   * @type {Array<UserUserSlugCreatorsGet200ResponseSubscriptionsInner>}
   * @memberof UserUserSlugCreatorsGet200Response
   */
  purchases?: Array<UserUserSlugCreatorsGet200ResponseSubscriptionsInner>;
}
/**
 *
 * @export
 * @interface UserUserSlugCreatorsGet200ResponseSubscriptionsInner
 */
export interface UserUserSlugCreatorsGet200ResponseSubscriptionsInner {
  /**
   *
   * @type {number}
   * @memberof UserUserSlugCreatorsGet200ResponseSubscriptionsInner
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof UserUserSlugCreatorsGet200ResponseSubscriptionsInner
   */
  name?: string;
  /**
   *
   * @type {boolean}
   * @memberof UserUserSlugCreatorsGet200ResponseSubscriptionsInner
   */
  isAvatar?: boolean;
  /**
   *
   * @type {CreatorCreatorSlugCommunitiesGet200ResponseInnerConfigCommunityReviewsInnerAvatar}
   * @memberof UserUserSlugCreatorsGet200ResponseSubscriptionsInner
   */
  avatar?: CreatorCreatorSlugCommunitiesGet200ResponseInnerConfigCommunityReviewsInnerAvatar | null;
}
/**
 *
 * @export
 * @interface UserUserSlugSpacesSpaceSlugChallengeStepsGet200Response
 */
export interface UserUserSlugSpacesSpaceSlugChallengeStepsGet200Response {
  /**
   *
   * @type {Array<UserUserSlugSpacesSpaceSlugChallengeStepsGet200ResponseStepsInner>}
   * @memberof UserUserSlugSpacesSpaceSlugChallengeStepsGet200Response
   */
  steps?: Array<UserUserSlugSpacesSpaceSlugChallengeStepsGet200ResponseStepsInner>;
  /**
   *
   * @type {Array<UserUserSlugSpacesSpaceSlugChallengeStepsGet200ResponseIntroInner>}
   * @memberof UserUserSlugSpacesSpaceSlugChallengeStepsGet200Response
   */
  intro?: Array<UserUserSlugSpacesSpaceSlugChallengeStepsGet200ResponseIntroInner>;
  /**
   *
   * @type {Array<UserUserSlugSpacesSpaceSlugChallengeStepsGet200ResponseIntroInner>}
   * @memberof UserUserSlugSpacesSpaceSlugChallengeStepsGet200Response
   */
  finish?: Array<UserUserSlugSpacesSpaceSlugChallengeStepsGet200ResponseIntroInner>;
}
/**
 *
 * @export
 * @interface UserUserSlugSpacesSpaceSlugChallengeStepsGet200ResponseIntroInner
 */
export interface UserUserSlugSpacesSpaceSlugChallengeStepsGet200ResponseIntroInner {
  /**
   *
   * @type {number}
   * @memberof UserUserSlugSpacesSpaceSlugChallengeStepsGet200ResponseIntroInner
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof UserUserSlugSpacesSpaceSlugChallengeStepsGet200ResponseIntroInner
   */
  type?: UserUserSlugSpacesSpaceSlugChallengeStepsGet200ResponseIntroInnerTypeEnum;
  /**
   *
   * @type {number}
   * @memberof UserUserSlugSpacesSpaceSlugChallengeStepsGet200ResponseIntroInner
   */
  spaceId?: number;
  /**
   *
   * @type {number}
   * @memberof UserUserSlugSpacesSpaceSlugChallengeStepsGet200ResponseIntroInner
   */
  order?: number;
  /**
   *
   * @type {object}
   * @memberof UserUserSlugSpacesSpaceSlugChallengeStepsGet200ResponseIntroInner
   */
  content?: object;
  /**
   *
   * @type {string}
   * @memberof UserUserSlugSpacesSpaceSlugChallengeStepsGet200ResponseIntroInner
   */
  gsMessageId?: string | null;
  /**
   *
   * @type {number}
   * @memberof UserUserSlugSpacesSpaceSlugChallengeStepsGet200ResponseIntroInner
   */
  publishedAt?: number | null;
  /**
   *
   * @type {boolean}
   * @memberof UserUserSlugSpacesSpaceSlugChallengeStepsGet200ResponseIntroInner
   */
  isDraft?: boolean;
}

export const UserUserSlugSpacesSpaceSlugChallengeStepsGet200ResponseIntroInnerTypeEnum = {
  Intro: 'intro',
  Step: 'step',
  Finish: 'finish',
  Deferred: 'deferred',
} as const;

export type UserUserSlugSpacesSpaceSlugChallengeStepsGet200ResponseIntroInnerTypeEnum =
  typeof UserUserSlugSpacesSpaceSlugChallengeStepsGet200ResponseIntroInnerTypeEnum[keyof typeof UserUserSlugSpacesSpaceSlugChallengeStepsGet200ResponseIntroInnerTypeEnum];

/**
 *
 * @export
 * @interface UserUserSlugSpacesSpaceSlugChallengeStepsGet200ResponseStepsInner
 */
export interface UserUserSlugSpacesSpaceSlugChallengeStepsGet200ResponseStepsInner {
  /**
   *
   * @type {number}
   * @memberof UserUserSlugSpacesSpaceSlugChallengeStepsGet200ResponseStepsInner
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof UserUserSlugSpacesSpaceSlugChallengeStepsGet200ResponseStepsInner
   */
  title?: string;
  /**
   *
   * @type {number}
   * @memberof UserUserSlugSpacesSpaceSlugChallengeStepsGet200ResponseStepsInner
   */
  startAt?: number | null;
  /**
   *
   * @type {number}
   * @memberof UserUserSlugSpacesSpaceSlugChallengeStepsGet200ResponseStepsInner
   */
  estimatedEffort?: number;
  /**
   *
   * @type {boolean}
   * @memberof UserUserSlugSpacesSpaceSlugChallengeStepsGet200ResponseStepsInner
   */
  isSignificant?: boolean;
  /**
   *
   * @type {Array<UserUserSlugSpacesSpaceSlugChallengeStepsGet200ResponseStepsInnerPostsInner>}
   * @memberof UserUserSlugSpacesSpaceSlugChallengeStepsGet200ResponseStepsInner
   */
  posts?: Array<UserUserSlugSpacesSpaceSlugChallengeStepsGet200ResponseStepsInnerPostsInner>;
  /**
   *
   * @type {UserUserSlugSpacesSpaceSlugChallengeStepsGet200ResponseStepsInnerResult}
   * @memberof UserUserSlugSpacesSpaceSlugChallengeStepsGet200ResponseStepsInner
   */
  result?: UserUserSlugSpacesSpaceSlugChallengeStepsGet200ResponseStepsInnerResult;
}
/**
 *
 * @export
 * @interface UserUserSlugSpacesSpaceSlugChallengeStepsGet200ResponseStepsInnerPostsInner
 */
export interface UserUserSlugSpacesSpaceSlugChallengeStepsGet200ResponseStepsInnerPostsInner {
  /**
   *
   * @type {number}
   * @memberof UserUserSlugSpacesSpaceSlugChallengeStepsGet200ResponseStepsInnerPostsInner
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof UserUserSlugSpacesSpaceSlugChallengeStepsGet200ResponseStepsInnerPostsInner
   */
  type?: UserUserSlugSpacesSpaceSlugChallengeStepsGet200ResponseStepsInnerPostsInnerTypeEnum;
  /**
   *
   * @type {number}
   * @memberof UserUserSlugSpacesSpaceSlugChallengeStepsGet200ResponseStepsInnerPostsInner
   */
  spaceId?: number;
  /**
   *
   * @type {number}
   * @memberof UserUserSlugSpacesSpaceSlugChallengeStepsGet200ResponseStepsInnerPostsInner
   */
  stepId?: number | null;
  /**
   *
   * @type {number}
   * @memberof UserUserSlugSpacesSpaceSlugChallengeStepsGet200ResponseStepsInnerPostsInner
   */
  order?: number;
  /**
   *
   * @type {object}
   * @memberof UserUserSlugSpacesSpaceSlugChallengeStepsGet200ResponseStepsInnerPostsInner
   */
  content?: object;
  /**
   *
   * @type {string}
   * @memberof UserUserSlugSpacesSpaceSlugChallengeStepsGet200ResponseStepsInnerPostsInner
   */
  gsMessageId?: string | null;
  /**
   *
   * @type {number}
   * @memberof UserUserSlugSpacesSpaceSlugChallengeStepsGet200ResponseStepsInnerPostsInner
   */
  publishedAt?: number | null;
  /**
   *
   * @type {boolean}
   * @memberof UserUserSlugSpacesSpaceSlugChallengeStepsGet200ResponseStepsInnerPostsInner
   */
  isDraft?: boolean;
}

export const UserUserSlugSpacesSpaceSlugChallengeStepsGet200ResponseStepsInnerPostsInnerTypeEnum = {
  Intro: 'intro',
  Step: 'step',
  Finish: 'finish',
  Deferred: 'deferred',
} as const;

export type UserUserSlugSpacesSpaceSlugChallengeStepsGet200ResponseStepsInnerPostsInnerTypeEnum =
  typeof UserUserSlugSpacesSpaceSlugChallengeStepsGet200ResponseStepsInnerPostsInnerTypeEnum[keyof typeof UserUserSlugSpacesSpaceSlugChallengeStepsGet200ResponseStepsInnerPostsInnerTypeEnum];

/**
 *
 * @export
 * @interface UserUserSlugSpacesSpaceSlugChallengeStepsGet200ResponseStepsInnerResult
 */
export interface UserUserSlugSpacesSpaceSlugChallengeStepsGet200ResponseStepsInnerResult {
  /**
   *
   * @type {boolean}
   * @memberof UserUserSlugSpacesSpaceSlugChallengeStepsGet200ResponseStepsInnerResult
   */
  isSkipped?: boolean;
  /**
   *
   * @type {number}
   * @memberof UserUserSlugSpacesSpaceSlugChallengeStepsGet200ResponseStepsInnerResult
   */
  rate?: number;
  /**
   *
   * @type {string}
   * @memberof UserUserSlugSpacesSpaceSlugChallengeStepsGet200ResponseStepsInnerResult
   */
  message?: string;
}
/**
 *
 * @export
 * @interface UserUserSlugSpacesSpaceSlugGet200Response
 */
export interface UserUserSlugSpacesSpaceSlugGet200Response {
  /**
   *
   * @type {number}
   * @memberof UserUserSlugSpacesSpaceSlugGet200Response
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof UserUserSlugSpacesSpaceSlugGet200Response
   */
  title?: string;
  /**
   *
   * @type {number}
   * @memberof UserUserSlugSpacesSpaceSlugGet200Response
   */
  price?: number;
  /**
   *
   * @type {string}
   * @memberof UserUserSlugSpacesSpaceSlugGet200Response
   */
  type?: UserUserSlugSpacesSpaceSlugGet200ResponseTypeEnum;
  /**
   *
   * @type {string}
   * @memberof UserUserSlugSpacesSpaceSlugGet200Response
   */
  status?: UserUserSlugSpacesSpaceSlugGet200ResponseStatusEnum;
  /**
   *
   * @type {string}
   * @memberof UserUserSlugSpacesSpaceSlugGet200Response
   */
  payment_type?: UserUserSlugSpacesSpaceSlugGet200ResponsePaymentTypeEnum;
  /**
   *
   * @type {string}
   * @memberof UserUserSlugSpacesSpaceSlugGet200Response
   */
  iosProductId?: string | null;
  /**
   *
   * @type {CreatorsCreatorIdGet200ResponseSpacesInnerConfig}
   * @memberof UserUserSlugSpacesSpaceSlugGet200Response
   */
  config?: CreatorsCreatorIdGet200ResponseSpacesInnerConfig | null;
  /**
   *
   * @type {CreatorsCreatorIdSpacesSpaceIdGet200ResponseMarketingPriceConfig}
   * @memberof UserUserSlugSpacesSpaceSlugGet200Response
   */
  marketingPriceConfig?: CreatorsCreatorIdSpacesSpaceIdGet200ResponseMarketingPriceConfig;
  /**
   *
   * @type {string}
   * @memberof UserUserSlugSpacesSpaceSlugGet200Response
   */
  slug?: string;
  /**
   *
   * @type {string}
   * @memberof UserUserSlugSpacesSpaceSlugGet200Response
   */
  userSlug?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof UserUserSlugSpacesSpaceSlugGet200Response
   */
  isPurchased?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof UserUserSlugSpacesSpaceSlugGet200Response
   */
  isSubscribed?: boolean;
  /**
   *
   * @type {number}
   * @memberof UserUserSlugSpacesSpaceSlugGet200Response
   */
  chatId?: number | null;
  /**
   *
   * @type {boolean}
   * @memberof UserUserSlugSpacesSpaceSlugGet200Response
   */
  isTrial?: boolean;
  /**
   *
   * @type {UserUserSlugSpacesSpaceSlugGet200ResponseCreator}
   * @memberof UserUserSlugSpacesSpaceSlugGet200Response
   */
  creator?: UserUserSlugSpacesSpaceSlugGet200ResponseCreator | null;
  /**
   *
   * @type {number}
   * @memberof UserUserSlugSpacesSpaceSlugGet200Response
   */
  startAt?: number | null;
  /**
   *
   * @type {CreatorCreatorSlugCommunitiesGet200ResponseInnerConfigCommunityReviewsInnerAvatar}
   * @memberof UserUserSlugSpacesSpaceSlugGet200Response
   */
  cover?: CreatorCreatorSlugCommunitiesGet200ResponseInnerConfigCommunityReviewsInnerAvatar | null;
}

export const UserUserSlugSpacesSpaceSlugGet200ResponseTypeEnum = {
  Post: 'post',
  Live: 'live',
  Challenge: 'challenge',
  Trailer: 'trailer',
  Channel: 'channel',
  Community: 'community',
} as const;

export type UserUserSlugSpacesSpaceSlugGet200ResponseTypeEnum =
  typeof UserUserSlugSpacesSpaceSlugGet200ResponseTypeEnum[keyof typeof UserUserSlugSpacesSpaceSlugGet200ResponseTypeEnum];
export const UserUserSlugSpacesSpaceSlugGet200ResponseStatusEnum = {
  Draft: 'draft',
  Published: 'published',
} as const;

export type UserUserSlugSpacesSpaceSlugGet200ResponseStatusEnum =
  typeof UserUserSlugSpacesSpaceSlugGet200ResponseStatusEnum[keyof typeof UserUserSlugSpacesSpaceSlugGet200ResponseStatusEnum];
export const UserUserSlugSpacesSpaceSlugGet200ResponsePaymentTypeEnum = {
  FixedPrice: 'fixed_price',
  FixedPriceSubscribersOnly: 'fixed_price_subscribers_only',
  SubscribersOnly: 'subscribers_only',
  Free: 'free',
  MonthlyPrice: 'monthly_price',
} as const;

export type UserUserSlugSpacesSpaceSlugGet200ResponsePaymentTypeEnum =
  typeof UserUserSlugSpacesSpaceSlugGet200ResponsePaymentTypeEnum[keyof typeof UserUserSlugSpacesSpaceSlugGet200ResponsePaymentTypeEnum];

/**
 *
 * @export
 * @interface UserUserSlugSpacesSpaceSlugGet200ResponseCreator
 */
export interface UserUserSlugSpacesSpaceSlugGet200ResponseCreator {
  /**
   *
   * @type {number}
   * @memberof UserUserSlugSpacesSpaceSlugGet200ResponseCreator
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof UserUserSlugSpacesSpaceSlugGet200ResponseCreator
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof UserUserSlugSpacesSpaceSlugGet200ResponseCreator
   */
  description?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof UserUserSlugSpacesSpaceSlugGet200ResponseCreator
   */
  isAvatar?: boolean;
  /**
   *
   * @type {CreatorCreatorSlugCommunitiesGet200ResponseInnerConfigCommunityReviewsInnerAvatar}
   * @memberof UserUserSlugSpacesSpaceSlugGet200ResponseCreator
   */
  avatar?: CreatorCreatorSlugCommunitiesGet200ResponseInnerConfigCommunityReviewsInnerAvatar | null;
  /**
   *
   * @type {UserUserSlugSpacesSpaceSlugGet200ResponseCreatorClub}
   * @memberof UserUserSlugSpacesSpaceSlugGet200ResponseCreator
   */
  club?: UserUserSlugSpacesSpaceSlugGet200ResponseCreatorClub | null;
}
/**
 *
 * @export
 * @interface UserUserSlugSpacesSpaceSlugGet200ResponseCreatorClub
 */
export interface UserUserSlugSpacesSpaceSlugGet200ResponseCreatorClub {
  /**
   *
   * @type {number}
   * @memberof UserUserSlugSpacesSpaceSlugGet200ResponseCreatorClub
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof UserUserSlugSpacesSpaceSlugGet200ResponseCreatorClub
   */
  title?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof UserUserSlugSpacesSpaceSlugGet200ResponseCreatorClub
   */
  isFollowed?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof UserUserSlugSpacesSpaceSlugGet200ResponseCreatorClub
   */
  isSubscribed?: boolean;
}
/**
 *
 * @export
 * @interface UserUserSlugUpvoteDelete200Response
 */
export interface UserUserSlugUpvoteDelete200Response {
  /**
   *
   * @type {number}
   * @memberof UserUserSlugUpvoteDelete200Response
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof UserUserSlugUpvoteDelete200Response
   */
  createdAt?: number;
  /**
   *
   * @type {number}
   * @memberof UserUserSlugUpvoteDelete200Response
   */
  deletedAt?: number | null;
  /**
   *
   * @type {number}
   * @memberof UserUserSlugUpvoteDelete200Response
   */
  clubId?: number;
  /**
   *
   * @type {number}
   * @memberof UserUserSlugUpvoteDelete200Response
   */
  customerId?: number;
}
/**
 *
 * @export
 * @interface UsersUserIdGet200Response
 */
export interface UsersUserIdGet200Response {
  /**
   *
   * @type {number}
   * @memberof UsersUserIdGet200Response
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof UsersUserIdGet200Response
   */
  created_at?: number;
  /**
   *
   * @type {string}
   * @memberof UsersUserIdGet200Response
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof UsersUserIdGet200Response
   */
  bio?: string | null;
  /**
   *
   * @type {string}
   * @memberof UsersUserIdGet200Response
   */
  description?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof UsersUserIdGet200Response
   */
  isAvatar?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof UsersUserIdGet200Response
   */
  isExpert?: boolean;
  /**
   *
   * @type {number}
   * @memberof UsersUserIdGet200Response
   */
  subscriptionPrice?: number | null;
  /**
   *
   * @type {string}
   * @memberof UsersUserIdGet200Response
   */
  slug?: string;
  /**
   *
   * @type {boolean}
   * @memberof UsersUserIdGet200Response
   */
  isSubscribed?: boolean;
  /**
   *
   * @type {Array<ExpertGet200ResponseInnerLinksInner>}
   * @memberof UsersUserIdGet200Response
   */
  links?: Array<ExpertGet200ResponseInnerLinksInner>;
  /**
   *
   * @type {CreatorCreatorSlugCommunitiesGet200ResponseInnerConfigCommunityReviewsInnerAvatar}
   * @memberof UsersUserIdGet200Response
   */
  avatar?: CreatorCreatorSlugCommunitiesGet200ResponseInnerConfigCommunityReviewsInnerAvatar | null;
  /**
   *
   * @type {Array<UserSlugGet200ResponsePointsInner>}
   * @memberof UsersUserIdGet200Response
   */
  points?: Array<UserSlugGet200ResponsePointsInner>;
  /**
   *
   * @type {Array<UsersUserIdGet200ResponseCommunitiesInner>}
   * @memberof UsersUserIdGet200Response
   */
  communities?: Array<UsersUserIdGet200ResponseCommunitiesInner>;
}
/**
 *
 * @export
 * @interface UsersUserIdGet200ResponseCommunitiesInner
 */
export interface UsersUserIdGet200ResponseCommunitiesInner {
  /**
   *
   * @type {number}
   * @memberof UsersUserIdGet200ResponseCommunitiesInner
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof UsersUserIdGet200ResponseCommunitiesInner
   */
  title?: string;
  /**
   *
   * @type {number}
   * @memberof UsersUserIdGet200ResponseCommunitiesInner
   */
  price?: number;
  /**
   *
   * @type {string}
   * @memberof UsersUserIdGet200ResponseCommunitiesInner
   */
  payment_type?: UsersUserIdGet200ResponseCommunitiesInnerPaymentTypeEnum;
  /**
   *
   * @type {string}
   * @memberof UsersUserIdGet200ResponseCommunitiesInner
   */
  iosProductId?: string | null;
  /**
   *
   * @type {number}
   * @memberof UsersUserIdGet200ResponseCommunitiesInner
   */
  trialPrice?: number;
  /**
   *
   * @type {string}
   * @memberof UsersUserIdGet200ResponseCommunitiesInner
   */
  description?: string | null;
  /**
   *
   * @type {UsersUserIdGet200ResponseCommunitiesInnerMarketingPriceConfig}
   * @memberof UsersUserIdGet200ResponseCommunitiesInner
   */
  marketingPriceConfig?: UsersUserIdGet200ResponseCommunitiesInnerMarketingPriceConfig;
  /**
   *
   * @type {CreatorCreatorSlugCommunitiesGet200ResponseInnerConfigCommunityReviewsInnerAvatar}
   * @memberof UsersUserIdGet200ResponseCommunitiesInner
   */
  cover?: CreatorCreatorSlugCommunitiesGet200ResponseInnerConfigCommunityReviewsInnerAvatar | null;
  /**
   *
   * @type {number}
   * @memberof UsersUserIdGet200ResponseCommunitiesInner
   */
  subscribersCount?: number;
  /**
   *
   * @type {UsersUserIdGet200ResponseCommunitiesInnerSubscription}
   * @memberof UsersUserIdGet200ResponseCommunitiesInner
   */
  subscription?: UsersUserIdGet200ResponseCommunitiesInnerSubscription | null;
}

export const UsersUserIdGet200ResponseCommunitiesInnerPaymentTypeEnum = {
  FixedPrice: 'fixed_price',
  FixedPriceSubscribersOnly: 'fixed_price_subscribers_only',
  SubscribersOnly: 'subscribers_only',
  Free: 'free',
  MonthlyPrice: 'monthly_price',
} as const;

export type UsersUserIdGet200ResponseCommunitiesInnerPaymentTypeEnum =
  typeof UsersUserIdGet200ResponseCommunitiesInnerPaymentTypeEnum[keyof typeof UsersUserIdGet200ResponseCommunitiesInnerPaymentTypeEnum];

/**
 *
 * @export
 * @interface UsersUserIdGet200ResponseCommunitiesInnerMarketingPriceConfig
 */
export interface UsersUserIdGet200ResponseCommunitiesInnerMarketingPriceConfig {
  /**
   *
   * @type {number}
   * @memberof UsersUserIdGet200ResponseCommunitiesInnerMarketingPriceConfig
   */
  previousPrice?: number | null;
  /**
   *
   * @type {number}
   * @memberof UsersUserIdGet200ResponseCommunitiesInnerMarketingPriceConfig
   */
  iosPrice?: number;
  /**
   *
   * @type {number}
   * @memberof UsersUserIdGet200ResponseCommunitiesInnerMarketingPriceConfig
   */
  iosTrialPrice?: number;
}
/**
 *
 * @export
 * @interface UsersUserIdGet200ResponseCommunitiesInnerSubscription
 */
export interface UsersUserIdGet200ResponseCommunitiesInnerSubscription {
  /**
   *
   * @type {number}
   * @memberof UsersUserIdGet200ResponseCommunitiesInnerSubscription
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof UsersUserIdGet200ResponseCommunitiesInnerSubscription
   */
  lastPaymentDate?: number | null;
  /**
   *
   * @type {string}
   * @memberof UsersUserIdGet200ResponseCommunitiesInnerSubscription
   */
  type?: UsersUserIdGet200ResponseCommunitiesInnerSubscriptionTypeEnum;
  /**
   *
   * @type {number}
   * @memberof UsersUserIdGet200ResponseCommunitiesInnerSubscription
   */
  startedAt?: number | null;
  /**
   *
   * @type {number}
   * @memberof UsersUserIdGet200ResponseCommunitiesInnerSubscription
   */
  cancelledAt?: number | null;
  /**
   *
   * @type {number}
   * @memberof UsersUserIdGet200ResponseCommunitiesInnerSubscription
   */
  price?: number;
  /**
   *
   * @type {boolean}
   * @memberof UsersUserIdGet200ResponseCommunitiesInnerSubscription
   */
  isActive?: boolean;
  /**
   *
   * @type {number}
   * @memberof UsersUserIdGet200ResponseCommunitiesInnerSubscription
   */
  nextPaymentDate?: number | null;
}

export const UsersUserIdGet200ResponseCommunitiesInnerSubscriptionTypeEnum = {
  ForeverFree: 'foreverFree',
  FreeTrial: 'freeTrial',
  PaidTrial: 'paidTrial',
  FullPrice: 'fullPrice',
} as const;

export type UsersUserIdGet200ResponseCommunitiesInnerSubscriptionTypeEnum =
  typeof UsersUserIdGet200ResponseCommunitiesInnerSubscriptionTypeEnum[keyof typeof UsersUserIdGet200ResponseCommunitiesInnerSubscriptionTypeEnum];

/**
 * ChallengeApi - axios parameter creator
 * @export
 */
export const ChallengeApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Result of feedback form for the step of challenge <br /><br /> <b>Authentication:</b> required
     * @summary Result of feedback form for the step of challenge
     * @param {number} stepId
     * @param {ChallengeStepsStepIdResultPostRequest} [challengeStepsStepIdResultPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    challengeStepsStepIdResultPost: async (
      stepId: number,
      challengeStepsStepIdResultPostRequest?: ChallengeStepsStepIdResultPostRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'stepId' is not null or undefined
      assertParamExists('challengeStepsStepIdResultPost', 'stepId', stepId);
      const localVarPath = `/challenge/steps/{stepId}/result`.replace(
        `{${'stepId'}}`,
        encodeURIComponent(String(stepId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        challengeStepsStepIdResultPostRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ChallengeApi - functional programming interface
 * @export
 */
export const ChallengeApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ChallengeApiAxiosParamCreator(configuration);
  return {
    /**
     * Result of feedback form for the step of challenge <br /><br /> <b>Authentication:</b> required
     * @summary Result of feedback form for the step of challenge
     * @param {number} stepId
     * @param {ChallengeStepsStepIdResultPostRequest} [challengeStepsStepIdResultPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async challengeStepsStepIdResultPost(
      stepId: number,
      challengeStepsStepIdResultPostRequest?: ChallengeStepsStepIdResultPostRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChallengeStepsStepIdResultPost200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.challengeStepsStepIdResultPost(
        stepId,
        challengeStepsStepIdResultPostRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * ChallengeApi - factory interface
 * @export
 */
export const ChallengeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = ChallengeApiFp(configuration);
  return {
    /**
     * Result of feedback form for the step of challenge <br /><br /> <b>Authentication:</b> required
     * @summary Result of feedback form for the step of challenge
     * @param {number} stepId
     * @param {ChallengeStepsStepIdResultPostRequest} [challengeStepsStepIdResultPostRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    challengeStepsStepIdResultPost(
      stepId: number,
      challengeStepsStepIdResultPostRequest?: ChallengeStepsStepIdResultPostRequest,
      options?: any,
    ): AxiosPromise<ChallengeStepsStepIdResultPost200Response> {
      return localVarFp
        .challengeStepsStepIdResultPost(stepId, challengeStepsStepIdResultPostRequest, options)
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * ChallengeApi - object-oriented interface
 * @export
 * @class ChallengeApi
 * @extends {BaseAPI}
 */
export class ChallengeApi extends BaseAPI {
  /**
   * Result of feedback form for the step of challenge <br /><br /> <b>Authentication:</b> required
   * @summary Result of feedback form for the step of challenge
   * @param {number} stepId
   * @param {ChallengeStepsStepIdResultPostRequest} [challengeStepsStepIdResultPostRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ChallengeApi
   */
  public challengeStepsStepIdResultPost(
    stepId: number,
    challengeStepsStepIdResultPostRequest?: ChallengeStepsStepIdResultPostRequest,
    options?: AxiosRequestConfig,
  ) {
    return ChallengeApiFp(this.configuration)
      .challengeStepsStepIdResultPost(stepId, challengeStepsStepIdResultPostRequest, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * ClubsApi - axios parameter creator
 * @export
 */
export const ClubsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} clubId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clubsClubIdFollowPost: async (clubId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'clubId' is not null or undefined
      assertParamExists('clubsClubIdFollowPost', 'clubId', clubId);
      const localVarPath = `/clubs/{clubId}/follow`.replace(`{${'clubId'}}`, encodeURIComponent(String(clubId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} clubId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clubsClubIdUnfollowPost: async (clubId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'clubId' is not null or undefined
      assertParamExists('clubsClubIdUnfollowPost', 'clubId', clubId);
      const localVarPath = `/clubs/{clubId}/unfollow`.replace(`{${'clubId'}}`, encodeURIComponent(String(clubId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ClubsApi - functional programming interface
 * @export
 */
export const ClubsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ClubsApiAxiosParamCreator(configuration);
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} clubId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async clubsClubIdFollowPost(
      clubId: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClubsClubIdFollowPost200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.clubsClubIdFollowPost(clubId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} clubId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async clubsClubIdUnfollowPost(
      clubId: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.clubsClubIdUnfollowPost(clubId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * ClubsApi - factory interface
 * @export
 */
export const ClubsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = ClubsApiFp(configuration);
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} clubId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clubsClubIdFollowPost(clubId: number, options?: any): AxiosPromise<ClubsClubIdFollowPost200Response> {
      return localVarFp.clubsClubIdFollowPost(clubId, options).then(request => request(axios, basePath));
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} clubId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clubsClubIdUnfollowPost(clubId: number, options?: any): AxiosPromise<object> {
      return localVarFp.clubsClubIdUnfollowPost(clubId, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * ClubsApi - object-oriented interface
 * @export
 * @class ClubsApi
 * @extends {BaseAPI}
 */
export class ClubsApi extends BaseAPI {
  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {number} clubId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ClubsApi
   */
  public clubsClubIdFollowPost(clubId: number, options?: AxiosRequestConfig) {
    return ClubsApiFp(this.configuration)
      .clubsClubIdFollowPost(clubId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {number} clubId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ClubsApi
   */
  public clubsClubIdUnfollowPost(clubId: number, options?: AxiosRequestConfig) {
    return ClubsApiFp(this.configuration)
      .clubsClubIdUnfollowPost(clubId, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * CreatorApi - axios parameter creator
 * @export
 */
export const CreatorApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {string} creatorSlug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    creatorCreatorSlugCommunitiesGet: async (
      creatorSlug: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'creatorSlug' is not null or undefined
      assertParamExists('creatorCreatorSlugCommunitiesGet', 'creatorSlug', creatorSlug);
      const localVarPath = `/creator/{creatorSlug}/communities`.replace(
        `{${'creatorSlug'}}`,
        encodeURIComponent(String(creatorSlug)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * CreatorApi - functional programming interface
 * @export
 */
export const CreatorApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = CreatorApiAxiosParamCreator(configuration);
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {string} creatorSlug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async creatorCreatorSlugCommunitiesGet(
      creatorSlug: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<CreatorCreatorSlugCommunitiesGet200ResponseInner>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.creatorCreatorSlugCommunitiesGet(creatorSlug, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * CreatorApi - factory interface
 * @export
 */
export const CreatorApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = CreatorApiFp(configuration);
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {string} creatorSlug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    creatorCreatorSlugCommunitiesGet(
      creatorSlug: string,
      options?: any,
    ): AxiosPromise<Array<CreatorCreatorSlugCommunitiesGet200ResponseInner>> {
      return localVarFp
        .creatorCreatorSlugCommunitiesGet(creatorSlug, options)
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * CreatorApi - object-oriented interface
 * @export
 * @class CreatorApi
 * @extends {BaseAPI}
 */
export class CreatorApi extends BaseAPI {
  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {string} creatorSlug
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CreatorApi
   */
  public creatorCreatorSlugCommunitiesGet(creatorSlug: string, options?: AxiosRequestConfig) {
    return CreatorApiFp(this.configuration)
      .creatorCreatorSlugCommunitiesGet(creatorSlug, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * CreatorsApi - axios parameter creator
 * @export
 */
export const CreatorsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} creatorId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    creatorsCreatorIdGet: async (creatorId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'creatorId' is not null or undefined
      assertParamExists('creatorsCreatorIdGet', 'creatorId', creatorId);
      const localVarPath = `/creators/{creatorId}`.replace(`{${'creatorId'}}`, encodeURIComponent(String(creatorId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} creatorId
     * @param {number} spaceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    creatorsCreatorIdSpacesSpaceIdGet: async (
      creatorId: number,
      spaceId: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'creatorId' is not null or undefined
      assertParamExists('creatorsCreatorIdSpacesSpaceIdGet', 'creatorId', creatorId);
      // verify required parameter 'spaceId' is not null or undefined
      assertParamExists('creatorsCreatorIdSpacesSpaceIdGet', 'spaceId', spaceId);
      const localVarPath = `/creators/{creatorId}/spaces/{spaceId}`
        .replace(`{${'creatorId'}}`, encodeURIComponent(String(creatorId)))
        .replace(`{${'spaceId'}}`, encodeURIComponent(String(spaceId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} creatorId
     * @param {number} spaceId
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    creatorsCreatorIdSpacesSpaceIdMembersGet: async (
      creatorId: number,
      spaceId: number,
      limit?: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'creatorId' is not null or undefined
      assertParamExists('creatorsCreatorIdSpacesSpaceIdMembersGet', 'creatorId', creatorId);
      // verify required parameter 'spaceId' is not null or undefined
      assertParamExists('creatorsCreatorIdSpacesSpaceIdMembersGet', 'spaceId', spaceId);
      const localVarPath = `/creators/{creatorId}/spaces/{spaceId}/members`
        .replace(`{${'creatorId'}}`, encodeURIComponent(String(creatorId)))
        .replace(`{${'spaceId'}}`, encodeURIComponent(String(spaceId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * CreatorsApi - functional programming interface
 * @export
 */
export const CreatorsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = CreatorsApiAxiosParamCreator(configuration);
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} creatorId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async creatorsCreatorIdGet(
      creatorId: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreatorsCreatorIdGet200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.creatorsCreatorIdGet(creatorId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} creatorId
     * @param {number} spaceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async creatorsCreatorIdSpacesSpaceIdGet(
      creatorId: number,
      spaceId: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreatorsCreatorIdSpacesSpaceIdGet200Response>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.creatorsCreatorIdSpacesSpaceIdGet(
        creatorId,
        spaceId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} creatorId
     * @param {number} spaceId
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async creatorsCreatorIdSpacesSpaceIdMembersGet(
      creatorId: number,
      spaceId: number,
      limit?: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreatorsCreatorIdSpacesSpaceIdMembersGet200Response>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.creatorsCreatorIdSpacesSpaceIdMembersGet(
        creatorId,
        spaceId,
        limit,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * CreatorsApi - factory interface
 * @export
 */
export const CreatorsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = CreatorsApiFp(configuration);
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} creatorId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    creatorsCreatorIdGet(creatorId: number, options?: any): AxiosPromise<CreatorsCreatorIdGet200Response> {
      return localVarFp.creatorsCreatorIdGet(creatorId, options).then(request => request(axios, basePath));
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} creatorId
     * @param {number} spaceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    creatorsCreatorIdSpacesSpaceIdGet(
      creatorId: number,
      spaceId: number,
      options?: any,
    ): AxiosPromise<CreatorsCreatorIdSpacesSpaceIdGet200Response> {
      return localVarFp
        .creatorsCreatorIdSpacesSpaceIdGet(creatorId, spaceId, options)
        .then(request => request(axios, basePath));
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} creatorId
     * @param {number} spaceId
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    creatorsCreatorIdSpacesSpaceIdMembersGet(
      creatorId: number,
      spaceId: number,
      limit?: number,
      options?: any,
    ): AxiosPromise<CreatorsCreatorIdSpacesSpaceIdMembersGet200Response> {
      return localVarFp
        .creatorsCreatorIdSpacesSpaceIdMembersGet(creatorId, spaceId, limit, options)
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * CreatorsApi - object-oriented interface
 * @export
 * @class CreatorsApi
 * @extends {BaseAPI}
 */
export class CreatorsApi extends BaseAPI {
  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {number} creatorId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CreatorsApi
   */
  public creatorsCreatorIdGet(creatorId: number, options?: AxiosRequestConfig) {
    return CreatorsApiFp(this.configuration)
      .creatorsCreatorIdGet(creatorId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {number} creatorId
   * @param {number} spaceId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CreatorsApi
   */
  public creatorsCreatorIdSpacesSpaceIdGet(creatorId: number, spaceId: number, options?: AxiosRequestConfig) {
    return CreatorsApiFp(this.configuration)
      .creatorsCreatorIdSpacesSpaceIdGet(creatorId, spaceId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {number} creatorId
   * @param {number} spaceId
   * @param {number} [limit]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CreatorsApi
   */
  public creatorsCreatorIdSpacesSpaceIdMembersGet(
    creatorId: number,
    spaceId: number,
    limit?: number,
    options?: AxiosRequestConfig,
  ) {
    return CreatorsApiFp(this.configuration)
      .creatorsCreatorIdSpacesSpaceIdMembersGet(creatorId, spaceId, limit, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * DiscoverApi - axios parameter creator
 * @export
 */
export const DiscoverApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} categoryId
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    discoverClubsCategoriesCategoryIdGet: async (
      categoryId: number,
      limit?: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'categoryId' is not null or undefined
      assertParamExists('discoverClubsCategoriesCategoryIdGet', 'categoryId', categoryId);
      const localVarPath = `/discover/clubs/categories/{categoryId}`.replace(
        `{${'categoryId'}}`,
        encodeURIComponent(String(categoryId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    discoverClubsCategoriesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/discover/clubs/categories`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    discoverSpacesCategoriesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/discover/spaces/categories`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DiscoverApi - functional programming interface
 * @export
 */
export const DiscoverApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = DiscoverApiAxiosParamCreator(configuration);
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} categoryId
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async discoverClubsCategoriesCategoryIdGet(
      categoryId: number,
      limit?: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DiscoverClubsCategoriesCategoryIdGet200Response>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.discoverClubsCategoriesCategoryIdGet(
        categoryId,
        limit,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async discoverClubsCategoriesGet(
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DiscoverClubsCategoriesGet200ResponseInner>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.discoverClubsCategoriesGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async discoverSpacesCategoriesGet(
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DiscoverSpacesCategoriesGet200ResponseInner>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.discoverSpacesCategoriesGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * DiscoverApi - factory interface
 * @export
 */
export const DiscoverApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = DiscoverApiFp(configuration);
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} categoryId
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    discoverClubsCategoriesCategoryIdGet(
      categoryId: number,
      limit?: number,
      options?: any,
    ): AxiosPromise<DiscoverClubsCategoriesCategoryIdGet200Response> {
      return localVarFp
        .discoverClubsCategoriesCategoryIdGet(categoryId, limit, options)
        .then(request => request(axios, basePath));
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    discoverClubsCategoriesGet(options?: any): AxiosPromise<Array<DiscoverClubsCategoriesGet200ResponseInner>> {
      return localVarFp.discoverClubsCategoriesGet(options).then(request => request(axios, basePath));
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    discoverSpacesCategoriesGet(options?: any): AxiosPromise<Array<DiscoverSpacesCategoriesGet200ResponseInner>> {
      return localVarFp.discoverSpacesCategoriesGet(options).then(request => request(axios, basePath));
    },
  };
};

/**
 * DiscoverApi - object-oriented interface
 * @export
 * @class DiscoverApi
 * @extends {BaseAPI}
 */
export class DiscoverApi extends BaseAPI {
  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {number} categoryId
   * @param {number} [limit]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DiscoverApi
   */
  public discoverClubsCategoriesCategoryIdGet(categoryId: number, limit?: number, options?: AxiosRequestConfig) {
    return DiscoverApiFp(this.configuration)
      .discoverClubsCategoriesCategoryIdGet(categoryId, limit, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DiscoverApi
   */
  public discoverClubsCategoriesGet(options?: AxiosRequestConfig) {
    return DiscoverApiFp(this.configuration)
      .discoverClubsCategoriesGet(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DiscoverApi
   */
  public discoverSpacesCategoriesGet(options?: AxiosRequestConfig) {
    return DiscoverApiFp(this.configuration)
      .discoverSpacesCategoriesGet(options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * ExpertApi - axios parameter creator
 * @export
 */
export const ExpertApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} expertId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    expertExpertIdSpacesGet: async (expertId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'expertId' is not null or undefined
      assertParamExists('expertExpertIdSpacesGet', 'expertId', expertId);
      const localVarPath = `/expert/{expertId}/spaces`.replace(`{${'expertId'}}`, encodeURIComponent(String(expertId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    expertGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/expert`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ExpertApi - functional programming interface
 * @export
 */
export const ExpertApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ExpertApiAxiosParamCreator(configuration);
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} expertId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async expertExpertIdSpacesGet(
      expertId: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ExpertExpertIdSpacesGet200ResponseInner>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.expertExpertIdSpacesGet(expertId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async expertGet(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ExpertGet200ResponseInner>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.expertGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * ExpertApi - factory interface
 * @export
 */
export const ExpertApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = ExpertApiFp(configuration);
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} expertId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    expertExpertIdSpacesGet(
      expertId: number,
      options?: any,
    ): AxiosPromise<Array<ExpertExpertIdSpacesGet200ResponseInner>> {
      return localVarFp.expertExpertIdSpacesGet(expertId, options).then(request => request(axios, basePath));
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    expertGet(options?: any): AxiosPromise<Array<ExpertGet200ResponseInner>> {
      return localVarFp.expertGet(options).then(request => request(axios, basePath));
    },
  };
};

/**
 * ExpertApi - object-oriented interface
 * @export
 * @class ExpertApi
 * @extends {BaseAPI}
 */
export class ExpertApi extends BaseAPI {
  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {number} expertId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExpertApi
   */
  public expertExpertIdSpacesGet(expertId: number, options?: AxiosRequestConfig) {
    return ExpertApiFp(this.configuration)
      .expertExpertIdSpacesGet(expertId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExpertApi
   */
  public expertGet(options?: AxiosRequestConfig) {
    return ExpertApiFp(this.configuration)
      .expertGet(options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * SpacesApi - axios parameter creator
 * @export
 */
export const SpacesApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} spaceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    spacesSpaceIdAddTrailerToHomePost: async (
      spaceId: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'spaceId' is not null or undefined
      assertParamExists('spacesSpaceIdAddTrailerToHomePost', 'spaceId', spaceId);
      const localVarPath = `/spaces/{spaceId}/addTrailerToHome`.replace(
        `{${'spaceId'}}`,
        encodeURIComponent(String(spaceId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} spaceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    spacesSpaceIdChallengeStepsGet: async (spaceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'spaceId' is not null or undefined
      assertParamExists('spacesSpaceIdChallengeStepsGet', 'spaceId', spaceId);
      const localVarPath = `/spaces/{spaceId}/challenge/steps`.replace(
        `{${'spaceId'}}`,
        encodeURIComponent(String(spaceId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} spaceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    spacesSpaceIdChallengeTrialPost: async (
      spaceId: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'spaceId' is not null or undefined
      assertParamExists('spacesSpaceIdChallengeTrialPost', 'spaceId', spaceId);
      const localVarPath = `/spaces/{spaceId}/challenge/trial`.replace(
        `{${'spaceId'}}`,
        encodeURIComponent(String(spaceId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} spaceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    spacesSpaceIdFollowPost: async (spaceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'spaceId' is not null or undefined
      assertParamExists('spacesSpaceIdFollowPost', 'spaceId', spaceId);
      const localVarPath = `/spaces/{spaceId}/follow`.replace(`{${'spaceId'}}`, encodeURIComponent(String(spaceId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} spaceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    spacesSpaceIdGet: async (spaceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'spaceId' is not null or undefined
      assertParamExists('spacesSpaceIdGet', 'spaceId', spaceId);
      const localVarPath = `/spaces/{spaceId}`.replace(`{${'spaceId'}}`, encodeURIComponent(String(spaceId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} spaceId
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    spacesSpaceIdMembersGet: async (
      spaceId: number,
      limit?: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'spaceId' is not null or undefined
      assertParamExists('spacesSpaceIdMembersGet', 'spaceId', spaceId);
      const localVarPath = `/spaces/{spaceId}/members`.replace(`{${'spaceId'}}`, encodeURIComponent(String(spaceId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} spaceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    spacesSpaceIdUnfollowPost: async (spaceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'spaceId' is not null or undefined
      assertParamExists('spacesSpaceIdUnfollowPost', 'spaceId', spaceId);
      const localVarPath = `/spaces/{spaceId}/unfollow`.replace(`{${'spaceId'}}`, encodeURIComponent(String(spaceId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * SpacesApi - functional programming interface
 * @export
 */
export const SpacesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = SpacesApiAxiosParamCreator(configuration);
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} spaceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async spacesSpaceIdAddTrailerToHomePost(
      spaceId: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.spacesSpaceIdAddTrailerToHomePost(spaceId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} spaceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async spacesSpaceIdChallengeStepsGet(
      spaceId: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SpacesSpaceIdChallengeStepsGet200ResponseInner>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.spacesSpaceIdChallengeStepsGet(spaceId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} spaceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async spacesSpaceIdChallengeTrialPost(
      spaceId: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SpacesSpaceIdChallengeTrialPost200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.spacesSpaceIdChallengeTrialPost(spaceId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} spaceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async spacesSpaceIdFollowPost(
      spaceId: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SpacesSpaceIdFollowPost200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.spacesSpaceIdFollowPost(spaceId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} spaceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async spacesSpaceIdGet(
      spaceId: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SpacesSpaceIdGet200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.spacesSpaceIdGet(spaceId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} spaceId
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async spacesSpaceIdMembersGet(
      spaceId: number,
      limit?: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SpacesSpaceIdMembersGet200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.spacesSpaceIdMembersGet(spaceId, limit, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} spaceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async spacesSpaceIdUnfollowPost(
      spaceId: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SpacesSpaceIdFollowPost200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.spacesSpaceIdUnfollowPost(spaceId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * SpacesApi - factory interface
 * @export
 */
export const SpacesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = SpacesApiFp(configuration);
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} spaceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    spacesSpaceIdAddTrailerToHomePost(spaceId: number, options?: any): AxiosPromise<object> {
      return localVarFp.spacesSpaceIdAddTrailerToHomePost(spaceId, options).then(request => request(axios, basePath));
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} spaceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    spacesSpaceIdChallengeStepsGet(
      spaceId: number,
      options?: any,
    ): AxiosPromise<Array<SpacesSpaceIdChallengeStepsGet200ResponseInner>> {
      return localVarFp.spacesSpaceIdChallengeStepsGet(spaceId, options).then(request => request(axios, basePath));
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} spaceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    spacesSpaceIdChallengeTrialPost(
      spaceId: number,
      options?: any,
    ): AxiosPromise<SpacesSpaceIdChallengeTrialPost200Response> {
      return localVarFp.spacesSpaceIdChallengeTrialPost(spaceId, options).then(request => request(axios, basePath));
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} spaceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    spacesSpaceIdFollowPost(spaceId: number, options?: any): AxiosPromise<SpacesSpaceIdFollowPost200Response> {
      return localVarFp.spacesSpaceIdFollowPost(spaceId, options).then(request => request(axios, basePath));
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} spaceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    spacesSpaceIdGet(spaceId: number, options?: any): AxiosPromise<SpacesSpaceIdGet200Response> {
      return localVarFp.spacesSpaceIdGet(spaceId, options).then(request => request(axios, basePath));
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} spaceId
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    spacesSpaceIdMembersGet(
      spaceId: number,
      limit?: number,
      options?: any,
    ): AxiosPromise<SpacesSpaceIdMembersGet200Response> {
      return localVarFp.spacesSpaceIdMembersGet(spaceId, limit, options).then(request => request(axios, basePath));
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} spaceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    spacesSpaceIdUnfollowPost(spaceId: number, options?: any): AxiosPromise<SpacesSpaceIdFollowPost200Response> {
      return localVarFp.spacesSpaceIdUnfollowPost(spaceId, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * SpacesApi - object-oriented interface
 * @export
 * @class SpacesApi
 * @extends {BaseAPI}
 */
export class SpacesApi extends BaseAPI {
  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {number} spaceId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SpacesApi
   */
  public spacesSpaceIdAddTrailerToHomePost(spaceId: number, options?: AxiosRequestConfig) {
    return SpacesApiFp(this.configuration)
      .spacesSpaceIdAddTrailerToHomePost(spaceId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {number} spaceId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SpacesApi
   */
  public spacesSpaceIdChallengeStepsGet(spaceId: number, options?: AxiosRequestConfig) {
    return SpacesApiFp(this.configuration)
      .spacesSpaceIdChallengeStepsGet(spaceId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {number} spaceId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SpacesApi
   */
  public spacesSpaceIdChallengeTrialPost(spaceId: number, options?: AxiosRequestConfig) {
    return SpacesApiFp(this.configuration)
      .spacesSpaceIdChallengeTrialPost(spaceId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {number} spaceId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SpacesApi
   */
  public spacesSpaceIdFollowPost(spaceId: number, options?: AxiosRequestConfig) {
    return SpacesApiFp(this.configuration)
      .spacesSpaceIdFollowPost(spaceId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {number} spaceId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SpacesApi
   */
  public spacesSpaceIdGet(spaceId: number, options?: AxiosRequestConfig) {
    return SpacesApiFp(this.configuration)
      .spacesSpaceIdGet(spaceId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {number} spaceId
   * @param {number} [limit]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SpacesApi
   */
  public spacesSpaceIdMembersGet(spaceId: number, limit?: number, options?: AxiosRequestConfig) {
    return SpacesApiFp(this.configuration)
      .spacesSpaceIdMembersGet(spaceId, limit, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {number} spaceId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SpacesApi
   */
  public spacesSpaceIdUnfollowPost(spaceId: number, options?: AxiosRequestConfig) {
    return SpacesApiFp(this.configuration)
      .spacesSpaceIdUnfollowPost(spaceId, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {string} slug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userSlugGet: async (slug: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'slug' is not null or undefined
      assertParamExists('userSlugGet', 'slug', slug);
      const localVarPath = `/user/{slug}`.replace(`{${'slug'}}`, encodeURIComponent(String(slug)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {string} userSlug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userUserSlugCreatorsGet: async (userSlug: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'userSlug' is not null or undefined
      assertParamExists('userUserSlugCreatorsGet', 'userSlug', userSlug);
      const localVarPath = `/user/{userSlug}/creators`.replace(`{${'userSlug'}}`, encodeURIComponent(String(userSlug)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * userSlug – slug of creator (owner of space) <br /><br /> <b>Authentication:</b> required
     * @summary userSlug – slug of creator (owner of space)
     * @param {string} userSlug
     * @param {string} spaceSlug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userUserSlugSpacesSpaceSlugChallengeStepsGet: async (
      userSlug: string,
      spaceSlug: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userSlug' is not null or undefined
      assertParamExists('userUserSlugSpacesSpaceSlugChallengeStepsGet', 'userSlug', userSlug);
      // verify required parameter 'spaceSlug' is not null or undefined
      assertParamExists('userUserSlugSpacesSpaceSlugChallengeStepsGet', 'spaceSlug', spaceSlug);
      const localVarPath = `/user/{userSlug}/spaces/{spaceSlug}/challenge/steps`
        .replace(`{${'userSlug'}}`, encodeURIComponent(String(userSlug)))
        .replace(`{${'spaceSlug'}}`, encodeURIComponent(String(spaceSlug)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {string} userSlug
     * @param {string} spaceSlug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userUserSlugSpacesSpaceSlugChallengeTrialPost: async (
      userSlug: string,
      spaceSlug: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userSlug' is not null or undefined
      assertParamExists('userUserSlugSpacesSpaceSlugChallengeTrialPost', 'userSlug', userSlug);
      // verify required parameter 'spaceSlug' is not null or undefined
      assertParamExists('userUserSlugSpacesSpaceSlugChallengeTrialPost', 'spaceSlug', spaceSlug);
      const localVarPath = `/user/{userSlug}/spaces/{spaceSlug}/challenge/trial`
        .replace(`{${'userSlug'}}`, encodeURIComponent(String(userSlug)))
        .replace(`{${'spaceSlug'}}`, encodeURIComponent(String(spaceSlug)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * userSlug – slug of creator (owner of space) <br /><br /> <b>Authentication:</b> required
     * @summary userSlug – slug of creator (owner of space)
     * @param {string} userSlug slug of creator (owner of space)
     * @param {string} spaceSlug
     * @param {boolean} [_short]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userUserSlugSpacesSpaceSlugGet: async (
      userSlug: string,
      spaceSlug: string,
      _short?: boolean,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userSlug' is not null or undefined
      assertParamExists('userUserSlugSpacesSpaceSlugGet', 'userSlug', userSlug);
      // verify required parameter 'spaceSlug' is not null or undefined
      assertParamExists('userUserSlugSpacesSpaceSlugGet', 'spaceSlug', spaceSlug);
      const localVarPath = `/user/{userSlug}/spaces/{spaceSlug}`
        .replace(`{${'userSlug'}}`, encodeURIComponent(String(userSlug)))
        .replace(`{${'spaceSlug'}}`, encodeURIComponent(String(spaceSlug)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (_short !== undefined) {
        localVarQueryParameter['short'] = _short;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * userSlug – slug of creator (owner of space) <br /><br /> <b>Authentication:</b> required
     * @summary userSlug – slug of creator (owner of space)
     * @param {string} userSlug
     * @param {string} spaceSlug
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userUserSlugSpacesSpaceSlugMembersGet: async (
      userSlug: string,
      spaceSlug: string,
      limit?: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userSlug' is not null or undefined
      assertParamExists('userUserSlugSpacesSpaceSlugMembersGet', 'userSlug', userSlug);
      // verify required parameter 'spaceSlug' is not null or undefined
      assertParamExists('userUserSlugSpacesSpaceSlugMembersGet', 'spaceSlug', spaceSlug);
      const localVarPath = `/user/{userSlug}/spaces/{spaceSlug}/members`
        .replace(`{${'userSlug'}}`, encodeURIComponent(String(userSlug)))
        .replace(`{${'spaceSlug'}}`, encodeURIComponent(String(spaceSlug)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {string} userSlug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userUserSlugUpvoteDelete: async (userSlug: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'userSlug' is not null or undefined
      assertParamExists('userUserSlugUpvoteDelete', 'userSlug', userSlug);
      const localVarPath = `/user/{userSlug}/upvote`.replace(`{${'userSlug'}}`, encodeURIComponent(String(userSlug)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {string} userSlug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userUserSlugUpvotePost: async (userSlug: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'userSlug' is not null or undefined
      assertParamExists('userUserSlugUpvotePost', 'userSlug', userSlug);
      const localVarPath = `/user/{userSlug}/upvote`.replace(`{${'userSlug'}}`, encodeURIComponent(String(userSlug)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration);
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {string} slug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userSlugGet(
      slug: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserSlugGet200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userSlugGet(slug, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {string} userSlug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userUserSlugCreatorsGet(
      userSlug: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserUserSlugCreatorsGet200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userUserSlugCreatorsGet(userSlug, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * userSlug – slug of creator (owner of space) <br /><br /> <b>Authentication:</b> required
     * @summary userSlug – slug of creator (owner of space)
     * @param {string} userSlug
     * @param {string} spaceSlug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userUserSlugSpacesSpaceSlugChallengeStepsGet(
      userSlug: string,
      spaceSlug: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UserUserSlugSpacesSpaceSlugChallengeStepsGet200Response>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userUserSlugSpacesSpaceSlugChallengeStepsGet(
        userSlug,
        spaceSlug,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {string} userSlug
     * @param {string} spaceSlug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userUserSlugSpacesSpaceSlugChallengeTrialPost(
      userSlug: string,
      spaceSlug: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SpacesSpaceIdChallengeTrialPost200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userUserSlugSpacesSpaceSlugChallengeTrialPost(
        userSlug,
        spaceSlug,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * userSlug – slug of creator (owner of space) <br /><br /> <b>Authentication:</b> required
     * @summary userSlug – slug of creator (owner of space)
     * @param {string} userSlug slug of creator (owner of space)
     * @param {string} spaceSlug
     * @param {boolean} [_short]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userUserSlugSpacesSpaceSlugGet(
      userSlug: string,
      spaceSlug: string,
      _short?: boolean,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserUserSlugSpacesSpaceSlugGet200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userUserSlugSpacesSpaceSlugGet(
        userSlug,
        spaceSlug,
        _short,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * userSlug – slug of creator (owner of space) <br /><br /> <b>Authentication:</b> required
     * @summary userSlug – slug of creator (owner of space)
     * @param {string} userSlug
     * @param {string} spaceSlug
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userUserSlugSpacesSpaceSlugMembersGet(
      userSlug: string,
      spaceSlug: string,
      limit?: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreatorsCreatorIdSpacesSpaceIdMembersGet200Response>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userUserSlugSpacesSpaceSlugMembersGet(
        userSlug,
        spaceSlug,
        limit,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {string} userSlug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userUserSlugUpvoteDelete(
      userSlug: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserUserSlugUpvoteDelete200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userUserSlugUpvoteDelete(userSlug, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {string} userSlug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userUserSlugUpvotePost(
      userSlug: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserUserSlugUpvoteDelete200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userUserSlugUpvotePost(userSlug, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = UserApiFp(configuration);
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {string} slug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userSlugGet(slug: string, options?: any): AxiosPromise<UserSlugGet200Response> {
      return localVarFp.userSlugGet(slug, options).then(request => request(axios, basePath));
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {string} userSlug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userUserSlugCreatorsGet(userSlug: string, options?: any): AxiosPromise<UserUserSlugCreatorsGet200Response> {
      return localVarFp.userUserSlugCreatorsGet(userSlug, options).then(request => request(axios, basePath));
    },
    /**
     * userSlug – slug of creator (owner of space) <br /><br /> <b>Authentication:</b> required
     * @summary userSlug – slug of creator (owner of space)
     * @param {string} userSlug
     * @param {string} spaceSlug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userUserSlugSpacesSpaceSlugChallengeStepsGet(
      userSlug: string,
      spaceSlug: string,
      options?: any,
    ): AxiosPromise<UserUserSlugSpacesSpaceSlugChallengeStepsGet200Response> {
      return localVarFp
        .userUserSlugSpacesSpaceSlugChallengeStepsGet(userSlug, spaceSlug, options)
        .then(request => request(axios, basePath));
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {string} userSlug
     * @param {string} spaceSlug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userUserSlugSpacesSpaceSlugChallengeTrialPost(
      userSlug: string,
      spaceSlug: string,
      options?: any,
    ): AxiosPromise<SpacesSpaceIdChallengeTrialPost200Response> {
      return localVarFp
        .userUserSlugSpacesSpaceSlugChallengeTrialPost(userSlug, spaceSlug, options)
        .then(request => request(axios, basePath));
    },
    /**
     * userSlug – slug of creator (owner of space) <br /><br /> <b>Authentication:</b> required
     * @summary userSlug – slug of creator (owner of space)
     * @param {string} userSlug slug of creator (owner of space)
     * @param {string} spaceSlug
     * @param {boolean} [_short]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userUserSlugSpacesSpaceSlugGet(
      userSlug: string,
      spaceSlug: string,
      _short?: boolean,
      options?: any,
    ): AxiosPromise<UserUserSlugSpacesSpaceSlugGet200Response> {
      return localVarFp
        .userUserSlugSpacesSpaceSlugGet(userSlug, spaceSlug, _short, options)
        .then(request => request(axios, basePath));
    },
    /**
     * userSlug – slug of creator (owner of space) <br /><br /> <b>Authentication:</b> required
     * @summary userSlug – slug of creator (owner of space)
     * @param {string} userSlug
     * @param {string} spaceSlug
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userUserSlugSpacesSpaceSlugMembersGet(
      userSlug: string,
      spaceSlug: string,
      limit?: number,
      options?: any,
    ): AxiosPromise<CreatorsCreatorIdSpacesSpaceIdMembersGet200Response> {
      return localVarFp
        .userUserSlugSpacesSpaceSlugMembersGet(userSlug, spaceSlug, limit, options)
        .then(request => request(axios, basePath));
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {string} userSlug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userUserSlugUpvoteDelete(userSlug: string, options?: any): AxiosPromise<UserUserSlugUpvoteDelete200Response> {
      return localVarFp.userUserSlugUpvoteDelete(userSlug, options).then(request => request(axios, basePath));
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {string} userSlug
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userUserSlugUpvotePost(userSlug: string, options?: any): AxiosPromise<UserUserSlugUpvoteDelete200Response> {
      return localVarFp.userUserSlugUpvotePost(userSlug, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {string} slug
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public userSlugGet(slug: string, options?: AxiosRequestConfig) {
    return UserApiFp(this.configuration)
      .userSlugGet(slug, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {string} userSlug
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public userUserSlugCreatorsGet(userSlug: string, options?: AxiosRequestConfig) {
    return UserApiFp(this.configuration)
      .userUserSlugCreatorsGet(userSlug, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * userSlug – slug of creator (owner of space) <br /><br /> <b>Authentication:</b> required
   * @summary userSlug – slug of creator (owner of space)
   * @param {string} userSlug
   * @param {string} spaceSlug
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public userUserSlugSpacesSpaceSlugChallengeStepsGet(
    userSlug: string,
    spaceSlug: string,
    options?: AxiosRequestConfig,
  ) {
    return UserApiFp(this.configuration)
      .userUserSlugSpacesSpaceSlugChallengeStepsGet(userSlug, spaceSlug, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {string} userSlug
   * @param {string} spaceSlug
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public userUserSlugSpacesSpaceSlugChallengeTrialPost(
    userSlug: string,
    spaceSlug: string,
    options?: AxiosRequestConfig,
  ) {
    return UserApiFp(this.configuration)
      .userUserSlugSpacesSpaceSlugChallengeTrialPost(userSlug, spaceSlug, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * userSlug – slug of creator (owner of space) <br /><br /> <b>Authentication:</b> required
   * @summary userSlug – slug of creator (owner of space)
   * @param {string} userSlug slug of creator (owner of space)
   * @param {string} spaceSlug
   * @param {boolean} [_short]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public userUserSlugSpacesSpaceSlugGet(
    userSlug: string,
    spaceSlug: string,
    _short?: boolean,
    options?: AxiosRequestConfig,
  ) {
    return UserApiFp(this.configuration)
      .userUserSlugSpacesSpaceSlugGet(userSlug, spaceSlug, _short, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * userSlug – slug of creator (owner of space) <br /><br /> <b>Authentication:</b> required
   * @summary userSlug – slug of creator (owner of space)
   * @param {string} userSlug
   * @param {string} spaceSlug
   * @param {number} [limit]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public userUserSlugSpacesSpaceSlugMembersGet(
    userSlug: string,
    spaceSlug: string,
    limit?: number,
    options?: AxiosRequestConfig,
  ) {
    return UserApiFp(this.configuration)
      .userUserSlugSpacesSpaceSlugMembersGet(userSlug, spaceSlug, limit, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {string} userSlug
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public userUserSlugUpvoteDelete(userSlug: string, options?: AxiosRequestConfig) {
    return UserApiFp(this.configuration)
      .userUserSlugUpvoteDelete(userSlug, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {string} userSlug
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public userUserSlugUpvotePost(userSlug: string, options?: AxiosRequestConfig) {
    return UserApiFp(this.configuration)
      .userUserSlugUpvotePost(userSlug, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersUserIdCreatorsGet: async (userId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists('usersUserIdCreatorsGet', 'userId', userId);
      const localVarPath = `/users/{userId}/creators`.replace(`{${'userId'}}`, encodeURIComponent(String(userId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersUserIdGet: async (userId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists('usersUserIdGet', 'userId', userId);
      const localVarPath = `/users/{userId}`.replace(`{${'userId'}}`, encodeURIComponent(String(userId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration);
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async usersUserIdCreatorsGet(
      userId: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserUserSlugCreatorsGet200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.usersUserIdCreatorsGet(userId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async usersUserIdGet(
      userId: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UsersUserIdGet200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.usersUserIdGet(userId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = UsersApiFp(configuration);
  return {
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersUserIdCreatorsGet(userId: number, options?: any): AxiosPromise<UserUserSlugCreatorsGet200Response> {
      return localVarFp.usersUserIdCreatorsGet(userId, options).then(request => request(axios, basePath));
    },
    /**
     * <br /><br /> <b>Authentication:</b> required
     * @summary
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersUserIdGet(userId: number, options?: any): AxiosPromise<UsersUserIdGet200Response> {
      return localVarFp.usersUserIdGet(userId, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {number} userId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public usersUserIdCreatorsGet(userId: number, options?: AxiosRequestConfig) {
    return UsersApiFp(this.configuration)
      .usersUserIdCreatorsGet(userId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * <br /><br /> <b>Authentication:</b> required
   * @summary
   * @param {number} userId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public usersUserIdGet(userId: number, options?: AxiosRequestConfig) {
    return UsersApiFp(this.configuration)
      .usersUserIdGet(userId, options)
      .then(request => request(this.axios, this.basePath));
  }
}
