import { ChatMessageEventTypes } from '@sp/data/bif';
import { formatRelative } from 'date-fns';
import { FC } from 'react';

export function formatMentionDate(timestamp: number) {
  return formatRelative(timestamp, new Date());
}

export const MentionDescription: FC<{ type: ChatMessageEventTypes; userName?: string; date: number }> = ({
  type,
  userName,
  date,
}) => {
  const formatDate = formatMentionDate(date);
  switch (type) {
    case ChatMessageEventTypes.mentions:
      return (
        <span>
          <b>{userName}</b>
          {` mentioned you ${formatDate}`}
        </span>
      );
    case ChatMessageEventTypes.reactions:
      return <span>{`Several people reacted to your message ${formatDate}`}</span>;
    case ChatMessageEventTypes.reply:
      return (
        <span>
          <b>{userName}</b>
          {` replied to you ${formatDate}`}
        </span>
      );
    default:
      return null;
  }
};
