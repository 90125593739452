import { Button } from '@sp/ui/elements';
import { getCroppedImg } from '@sp/util/files';
import { cls } from '@sp/util/helpers';
import { FC, useState } from 'react';
import Cropper, { Area, Point } from 'react-easy-crop';
import './image-cropper.pcss';

export enum ImageCropperMode {
  avatar = 'avatar',
  squareImage = 'squareImage',
  threeToFour = 'threeToFour',
}

const CROP_SHAPE_MODES: Record<ImageCropperMode, 'round' | 'rect'> = {
  avatar: 'round',
  squareImage: 'rect',
  threeToFour: 'rect',
};

// Tailwind classes need to be preprocessed, won't work dynamically in runtime.
export const CROP_TAILWIND_ASPECT_RATIOS: Record<ImageCropperMode, string> = {
  [ImageCropperMode.avatar]: 'aspect-[1]',
  [ImageCropperMode.squareImage]: 'aspect-[1]',
  [ImageCropperMode.threeToFour]: 'aspect-[0.75]',
};

const CROP_ASPECT_MODES: Record<ImageCropperMode, number> = {
  avatar: 1,
  squareImage: 1,
  threeToFour: 3 / 4,
};

const SHOW_DEFAULT_GRID_MODES: Record<ImageCropperMode, boolean> = {
  avatar: false,
  squareImage: true,
  threeToFour: true,
};

export interface ImageCropperProps {
  src: string;
  onResult: (resultDataURI: string) => void;
  onCancel: () => void;
  mode?: ImageCropperMode;
}

export const ImageCropper: FC<ImageCropperProps> = ({
  src,
  onResult,
  onCancel,
  mode = ImageCropperMode.squareImage,
}) => {
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState<number>(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>({
    width: 0,
    height: 0,
    x: 0,
    y: 0,
  });

  function onCropComplete(_: Area, croppedAreaPixels: Area) {
    setCroppedAreaPixels(croppedAreaPixels);
  }

  function cropImage() {
    getCroppedImg(src, croppedAreaPixels).then(dataUri => {
      onResult(dataUri);
    });
  }

  return (
    <div className="w-full h-full flex flex-col">
      <div className={cls('relative flex-1', `selfplus-image-cropper-mode-${mode}`)}>
        <Cropper
          crop={crop}
          zoom={zoom}
          aspect={CROP_ASPECT_MODES[mode]}
          cropShape={CROP_SHAPE_MODES[mode]}
          showGrid={SHOW_DEFAULT_GRID_MODES[mode]}
          onZoomChange={setZoom}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          image={src}
        />
      </div>

      {/*Фон как у бэкдропа кропера*/}
      <div className="flex justify-center gap-2 p-4" style={{ backgroundColor: 'rgba(0,0,0,0.5)' }}>
        <Button onClick={onCancel}>Cancel</Button>

        <Button onClick={cropImage} color="primary">
          Save
        </Button>
      </div>
    </div>
  );
};
