import { isNotNullish, useId } from '@sp/util/helpers';
import { interpolateArray } from '@sp/util/math';
import { FC, MouseEventHandler, SVGProps, useCallback, useEffect, useMemo, useRef, useState } from 'react';

const ACTIVE_BAR_COLOR = '#25262B';
const INACTIVE_BAR_COLOR = 'rgba(50, 60, 72, 0.5)';

export const AudioHistogram: FC<{
  peaks: readonly number[];
  progress?: number;
  onSetProgress?: (progress: number) => void;
  barHeight?: number;
  barWidth?: number;
  barGap?: number;
  recorderMode?: boolean;
}> = ({ peaks, progress = 0, onSetProgress, barWidth = 2, barHeight = 32, barGap = 2, recorderMode = false }) => {
  const id = useId();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(0);
  const length = useMemo(() => Math.floor(width / (barWidth + barGap)), [barGap, barWidth, width]);

  const calcBarProps = useCallback(
    (peak: number, index: number): SVGProps<SVGPathElement> => {
      const barRadius = barWidth / 2;
      const barHeightWithRadius = barHeight - barWidth;
      const height = peak * barHeightWithRadius;
      const xStart = index * (barWidth + barGap);
      const yStart = barHeight / 2 - height / 2;
      return {
        d: `M${xStart},${yStart} a${barRadius},${barRadius} 0 0 1 ${barWidth},0 v${height} a${barRadius},${barRadius} 0 0 1 -${barWidth},0 v-${height} z`,
      };
    },
    [barGap, barHeight, barWidth],
  );

  const barsProps = useMemo<SVGProps<SVGPathElement>[]>(() => {
    if (recorderMode) {
      const array = new Array(length);
      for (let i = length - 1; i >= 0; i--) {
        const peakIndex = peaks.length - 1 - (length - 1 - i);
        const peak = peaks[peakIndex] ?? 0;
        array[i] = {
          ...calcBarProps(peak, i),
          fill: isNotNullish(peaks[peakIndex]) ? ACTIVE_BAR_COLOR : INACTIVE_BAR_COLOR,
        };
      }
      return array;
    }
    const interpolatedPeaks = interpolateArray(peaks, length);
    const array = [];
    for (let i = 0; i < length; i++) {
      array.push(calcBarProps(interpolatedPeaks[i] ?? 0, i));
    }
    return array;
  }, [recorderMode, peaks, length, calcBarProps]);

  // Calculate width of the histogram on resize
  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      setWidth(wrapperRef.current?.clientWidth ?? 0);
    });
    if (wrapperRef.current) {
      resizeObserver.observe(wrapperRef.current);
    }
    return () => resizeObserver.disconnect();
  }, [wrapperRef]);

  const onProgressClick: MouseEventHandler = e => {
    const { clientX } = e;
    const { left, right } = wrapperRef.current?.getBoundingClientRect() ?? { left: 0, right: 0 };
    const progress = (clientX - left) / (right - left);
    onSetProgress?.(progress);
  };

  const percentProgress = useMemo(() => `${((isNaN(progress) ? 0 : progress) * 100).toFixed(3)}%`, [progress]);

  return (
    <div ref={wrapperRef} className="w-full" onClick={onProgressClick} id={`peaks_${id}`}>
      <svg width={width} height={barHeight} viewBox={`0 0 ${width} ${barHeight}`}>
        <defs>
          <linearGradient id={`grad_${id}`} x1="0" y1="0" x2={width} y2="0" gradientUnits="userSpaceOnUse">
            <stop offset="0%" stopColor={ACTIVE_BAR_COLOR} />
            <stop offset={percentProgress} stopColor={ACTIVE_BAR_COLOR} />
            <stop offset={percentProgress} stopColor={INACTIVE_BAR_COLOR} />
            <stop offset="100%" stopColor={INACTIVE_BAR_COLOR} />
          </linearGradient>
        </defs>
        <g fill={recorderMode ? 'none' : `url(#grad_${id})`}>
          {barsProps.map((props, i) => (
            <path key={i} {...props} />
          ))}
        </g>
      </svg>
    </div>
  );
};
