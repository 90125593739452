import { NAVIGATE_BACK_MODEL, useSetPreviousUrl } from '@sp/feature/back-button';
import { ROUTES } from '@sp/util/router';
import { VFC } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { ChannelPage } from './channel-page';

export const ChannelScreen: VFC = () => {
  useSetPreviousUrl(ROUTES.HOME_URL);

  const params = useParams<typeof ROUTES.SPACE_ID_PARAM>();
  const spaceId = Number(params.spaceId);

  if (isNaN(spaceId)) {
    console.error(`Invalid channel space id: ${params.spaceId}`);
    return <Navigate to={ROUTES.HOME_URL} />;
  }

  return <ChannelPage channelId={spaceId} onBackClick={NAVIGATE_BACK_MODEL.navigatedBack} />;
};
