import { SpacePurchaseType } from '@sp/data/bif';
import { IOS_IN_APP_PURCHASE_MODEL, PURCHASE_MODEL } from '@sp/data/global';
import { Button, ButtonProps } from '@sp/ui/elements';
import { PriceConfig, useFormatPrice } from '@sp/util/format';
import { isNotNullish, noop } from '@sp/util/helpers';
import { $isNativeIos } from '@sp/util/native';
import { useStore } from 'effector-react';
import { FC, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export type BuyButtonResultFn = (result: { success: boolean; error?: string }) => void;

type BuyButtonProps = {
  purchaseType: SpacePurchaseType;
  price: PriceConfig;
  id: number;
  title: string;
  iosProductId: string;
  bought: boolean;
  openUrl: string;
  creatorId: number;
  creatorName: string;
  onResult?: BuyButtonResultFn;
} & Omit<ButtonProps, 'id'>;

const InnerBuyButton: FC<
  Omit<BuyButtonProps, 'onResult' | 'purchaseType'> & { isLoading: boolean; onPurchase: VoidFunction }
> = ({
  isLoading,
  price,
  bought,
  children,
  onPurchase,
  color = 'black',
  disabled,
  loading,
  openUrl,
  // extract props which are not assignable to HTML button.
  id,
  creatorId,
  creatorName,
  iosProductId,
  ...props
}) => {
  const navigate = useNavigate();
  const formatPrice = useFormatPrice();

  const handleClick = useCallback(() => {
    if (bought) {
      navigate(openUrl);
    } else {
      onPurchase();
    }
  }, [bought, navigate, onPurchase, openUrl]);

  return (
    <Button
      {...props}
      disabled={disabled || isLoading}
      loading={loading || isLoading}
      onClick={handleClick}
      color={color}
    >
      {isNotNullish(children) ? children : bought ? 'Open' : `Join for ${formatPrice(price)}`}
    </Button>
  );
};

const IosInAppPurchaseBuyButton: FC<BuyButtonProps> = ({
  onResult = noop,
  id,
  title,
  price,
  iosProductId,
  creatorId,
  creatorName,
  purchaseType,
  ...restProps
}) => {
  const isLoading = useStore(IOS_IN_APP_PURCHASE_MODEL.$isPending);

  useEffect(() => {
    return IOS_IN_APP_PURCHASE_MODEL.$result.watch(result => {
      if (!result) return;
      if (result.success) return onResult({ success: true });
      onResult({ success: false, error: result.error });
      if (result.error === 'alreadyPurchased') {
        window.location.reload();
      }
    });
  }, [id, onResult, price.ios, title]);

  const handlePurchase = useCallback(
    () =>
      IOS_IN_APP_PURCHASE_MODEL.purchaseSpace({
        type: purchaseType,
        spaceId: id,
        product: iosProductId,
        title,
        creatorId,
        creatorName,
        price: price.ios,
      }),
    [creatorId, creatorName, id, iosProductId, price.ios, purchaseType, title],
  );
  return (
    <InnerBuyButton
      {...restProps}
      id={id}
      title={title}
      price={price}
      creatorId={creatorId}
      creatorName={creatorName}
      iosProductId={iosProductId}
      isLoading={isLoading}
      onPurchase={handlePurchase}
    />
  );
};

const AttachedCardBuyButton: FC<BuyButtonProps> = ({
  onResult = noop,
  id,
  title,
  price,
  creatorId,
  creatorName,
  purchaseType,
  ...restProps
}) => {
  const isLoading = useStore(PURCHASE_MODEL.$challengesIdsInProgress).includes(id);

  useEffect(() => {
    return PURCHASE_MODEL.purchaseSucceeded.watch(challengeId => {
      if (challengeId === id) onResult({ success: true });
    });
  }, [id, onResult, price.web, title]);

  useEffect(() => {
    return PURCHASE_MODEL.purchaseFailed.watch(({ spaceId, error, reload }) => {
      if (spaceId === id) {
        onResult({ success: false, error });
        reload && window.location.reload();
      }
    });
  }, [id, onResult]);

  const handlePurchase = useCallback(
    () =>
      PURCHASE_MODEL.initPurchaseStarted({
        type: purchaseType,
        spaceId: id,
        price: price.web,
        title,
        creatorId,
        creatorName,
      }),
    [creatorId, creatorName, id, price.web, purchaseType, title],
  );

  return (
    <InnerBuyButton
      {...restProps}
      id={id}
      title={title}
      price={price}
      creatorId={creatorId}
      creatorName={creatorName}
      isLoading={isLoading}
      onPurchase={handlePurchase}
    />
  );
};

export const BuyButton: FC<Omit<BuyButtonProps, 'purchaseType'>> = props => {
  const isNativeIos = useStore($isNativeIos);
  if (isNativeIos) return <IosInAppPurchaseBuyButton {...props} purchaseType={SpacePurchaseType.oneTime} />;
  return <AttachedCardBuyButton {...props} purchaseType={SpacePurchaseType.oneTime} />;
};

export const SubscribeButton: FC<Omit<BuyButtonProps, 'purchaseType'>> = props => {
  const isNativeIos = useStore($isNativeIos);
  if (isNativeIos) return <IosInAppPurchaseBuyButton {...props} purchaseType={SpacePurchaseType.subscription} />;
  return <AttachedCardBuyButton {...props} purchaseType={SpacePurchaseType.subscription} />;
};
