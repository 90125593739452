import { ReactElement } from 'react';
import { ICON_DEFAULTS, IconProps } from './types';

export const IconImage = ({
  size = ICON_DEFAULTS.size,
  color = ICON_DEFAULTS.color,
  filled = ICON_DEFAULTS.filled,
  className = ICON_DEFAULTS.className,
}: IconProps): ReactElement => (
  <svg
    className={className}
    width={size}
    height={size}
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {filled ? (
      <path
        d="M16 9.625C16 9.92337 15.8815 10.2095 15.6705 10.4205C15.4595 10.6315 15.1734 10.75 14.875 10.75C14.5766 10.75 14.2905 10.6315 14.0795 10.4205C13.8685 10.2095 13.75 9.92337 13.75 9.625C13.75 9.32663 13.8685 9.04048 14.0795 8.82951C14.2905 8.61853 14.5766 8.5 14.875 8.5C15.1734 8.5 15.4595 8.61853 15.6705 8.82951C15.8815 9.04048 16 9.32663 16 9.625V9.625ZM22 5.5V17.5V19C22 19.3978 21.842 19.7794 21.5607 20.0607C21.2794 20.342 20.8978 20.5 20.5 20.5H4C3.60218 20.5 3.22064 20.342 2.93934 20.0607C2.65804 19.7794 2.5 19.3978 2.5 19V16V5.5C2.5 5.10218 2.65804 4.72064 2.93934 4.43934C3.22064 4.15804 3.60218 4 4 4H20.5C20.8978 4 21.2794 4.15804 21.5607 4.43934C21.842 4.72064 22 5.10218 22 5.5ZM20.5 15.6906V5.5H4V14.1906L7.44063 10.75C7.72302 10.4717 8.10355 10.3158 8.5 10.3158C8.89645 10.3158 9.27698 10.4717 9.55938 10.75L13.75 14.9406L15.6906 13C15.973 12.7217 16.3536 12.5658 16.75 12.5658C17.1464 12.5658 17.527 12.7217 17.8094 13L20.5 15.6906Z"
        fill={color}
      />
    ) : (
      <>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.75 5.25C2.75 4.42157 3.42157 3.75 4.25 3.75H20.75C21.5784 3.75 22.25 4.42157 22.25 5.25V18.75C22.25 19.5784 21.5784 20.25 20.75 20.25H4.25C3.42157 20.25 2.75 19.5784 2.75 18.75V5.25ZM20.75 5.25H4.25V18.75H20.75V5.25Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.17067 10.1769C8.35416 10.1001 8.55109 10.0605 8.75 10.0605C8.94891 10.0605 9.14584 10.1001 9.32933 10.1769C9.51178 10.2533 9.67731 10.365 9.8164 10.5057L14 14.6893L14.004 14.6852L15.9336 12.7556C16.0727 12.615 16.2382 12.5033 16.4207 12.4269C16.6042 12.3501 16.8011 12.3105 17 12.3105C17.1989 12.3105 17.3958 12.3501 17.5793 12.4269C17.7618 12.5033 17.9273 12.615 18.0664 12.7556C18.0672 12.7564 18.068 12.7572 18.0687 12.758L22.0303 16.7196C22.3232 17.0125 22.3232 17.4874 22.0303 17.7802C21.7374 18.0731 21.2626 18.0731 20.9697 17.7802L17 13.8106L16.996 13.8146L15.0664 15.7442C14.9273 15.8848 14.7618 15.9965 14.5793 16.0729C14.3958 16.1497 14.1989 16.1893 14 16.1893C13.8011 16.1893 13.6042 16.1497 13.4207 16.0729C13.2382 15.9965 13.0727 15.8848 12.9336 15.7442L8.74998 11.5606L8.74597 11.5646L4.03033 16.2802C3.73744 16.5731 3.26256 16.5731 2.96967 16.2802C2.67678 15.9874 2.67678 15.5125 2.96967 15.2196L7.6836 10.5057C7.82269 10.365 7.98822 10.2533 8.17067 10.1769Z"
          fill={color}
        />
        <path
          d="M15.125 10.5C15.7463 10.5 16.25 9.99632 16.25 9.375C16.25 8.75368 15.7463 8.25 15.125 8.25C14.5037 8.25 14 8.75368 14 9.375C14 9.99632 14.5037 10.5 15.125 10.5Z"
          fill={color}
        />
      </>
    )}
  </svg>
);
