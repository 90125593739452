import { Navigate, useLocation, useParams } from 'react-router-dom';
import { ChallengeScreen } from './challenge-layout';

const ChallengeTabKeys: Record<string, string> = {
  chat: 'chat',
  journey: 'journey',
  mentions: 'mentions',
};

export const ValidateChallengeScreen = () => {
  const { tabKey } = useParams();
  const { search } = useLocation();
  if (!tabKey || !ChallengeTabKeys[tabKey]) {
    return <Navigate replace to={`../chat${search}`} />;
  }
  return <ChallengeScreen tabKey={tabKey} />;
};
