import { cls } from '@sp/util/helpers';
import { VFC } from 'react';

export const ToggleSwitch: VFC<{
  id?: string;
  checked?: boolean;
  onChange?: (v: boolean) => void;
  defaultChecked?: boolean;
  className?: string;
}> = ({ id, checked, onChange, defaultChecked, className }) => {
  return (
    <label className={cls('inline-flex relative items-center cursor-pointer', className)}>
      <input
        type="checkbox"
        id={id}
        checked={checked}
        defaultChecked={defaultChecked}
        onChange={({ target }) => {
          onChange?.(Boolean(target.checked));
        }}
        value=""
        className="sr-only peer"
      />
      <div className="w-14 h-8 bg-stroke rounded-full peer dark:bg-stroke peer-checked:after:translate-x-[80%] peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-7 after:w-7 after:transition-all peer-checked:bg-brand-main"></div>
    </label>
  );
};
