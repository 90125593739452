import { useAuth } from '@sp/data/auth';
import { IconCaretLeft } from '@sp/ui/icons';
import { $isGrooveActive, openGrooveWidget } from '@sp/util/groovehq';
import { cls } from '@sp/util/helpers';
import { ROUTES } from '@sp/util/router';
import { useEvent, useStore } from 'effector-react';
import { FC } from 'react';
import { Link } from 'react-router-dom';

export const SettingElement: FC<{
  title: string;
  url?: string;
  noBorder?: boolean;
}> = ({ title, url = '#', noBorder = false }) => {
  return (
    <Link to={url} className="no-underline">
      <div className={cls('flex justify-between items-center py-4', noBorder ? '' : 'border-b border-stripe')}>
        <span className="font-medium">{title}</span>
        <IconCaretLeft className="rotate-180" size={24} />
      </div>
    </Link>
  );
};

export const ProfileSettings: FC<{ isAdmin: boolean }> = ({ isAdmin }) => {
  const { logoutWithReload } = useAuth();

  const isGrooveActive = useStore($isGrooveActive);
  const handleSupportClick = useEvent(openGrooveWidget);

  return (
    <div>
      {/* <div className="mt-6 mb-4 text-xl font-semibold">Settings</div> */}

      <div className="flex flex-col">
        <SettingElement title="My purchases" url={`${ROUTES.PROFILE_URL}/${ROUTES.PROFILE.PURCHASES_NESTED_URL}`} />
        <SettingElement
          title="Notification settings"
          url={`${ROUTES.PROFILE_URL}/${ROUTES.PROFILE.NOTIFICATIONS_SETTINGS}`}
        />

        <button
          type="button"
          disabled={!isGrooveActive}
          onClick={handleSupportClick}
          className="disabled:opacity-50 disabled:cursor-not-allowed"
        >
          <div className={cls('flex justify-between items-center py-4', 'border-b border-stripe')}>
            <span className="font-medium">Contact support</span>
            <IconCaretLeft className="rotate-180" size={24} />
          </div>
        </button>

        {isAdmin ? (
          <>
            <SettingElement title="Create challenge" url={`${ROUTES.STUDIO_URL}/${ROUTES.STUDIO.EDIT_CHALLENGE_URL}`} />

            <SettingElement title="Create live" url={`${ROUTES.STUDIO_URL}/${ROUTES.STUDIO.CREATE_LIVE}`} />
            <SettingElement
              title="Create channel"
              url={`${ROUTES.STUDIO_URL}/${ROUTES.STUDIO.CREATOR_CHANNEL_EDITOR}`}
            />

            <SettingElement
              title="Platform messages"
              url={`${ROUTES.STUDIO_URL}/${ROUTES.STUDIO.PLATFORM_MESSAGE_EDITOR}`}
            />
          </>
        ) : null}

        <div className="mt-6">
          {/* eslint-disable-next-line react/jsx-no-target-blank */}
          <a href="https://selfplus.com/legal/terms_of_service" target="_blank" rel="noopener" className="no-underline">
            <div className={cls('flex justify-between items-center py-4', 'border-b border-stripe')}>
              <span className="font-medium">Terms of Service</span>
              <IconCaretLeft className="rotate-180" size={24} />
            </div>
          </a>

          {/* eslint-disable-next-line react/jsx-no-target-blank */}
          <a href="https://selfplus.com/legal/privacy_policy" target="_blank" rel="noopener" className="no-underline">
            <div className={cls('flex justify-between items-center py-4', 'border-b border-stripe')}>
              <span className="font-medium">Privacy Policy</span>
              <IconCaretLeft className="rotate-180" size={24} />
            </div>
          </a>

          <button type="button" onClick={logoutWithReload} className="w-full">
            <div className={cls('flex justify-between items-center py-4')}>
              <span className="font-bold">Log out</span>
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};
