import { useStore } from 'effector-react';
import { FC } from 'react';
import { CHALLENGE_MODEL } from '../../challenge-model';
import { POSTS_MODEL } from './posts-model';
import { PostsView } from './posts-view';

export const IntroPosts: FC = () => {
  const posts = useStore(POSTS_MODEL.$postsMap)['intro'] ?? [];
  const challenge = useStore(CHALLENGE_MODEL.$challenge);
  return <PostsView posts={posts} startAt={challenge.publishedAt} isProgramPost={!!challenge.isProgram} />;
};
