import { trackEvent, trackTime } from '@sp/util/native';
import { attach, createEffect } from 'effector';
import { $context } from './context';

const trackTimeFx = createEffect((name: string) => trackTime(name));

export function createTrackTimeEvent(name: string) {
  return trackTimeFx.prepend((_: void) => name);
}

type Properties = Record<string, string> | void;
type TrackEventPayload = { name: string; properties: Properties };

const trackEventFx = createEffect(({ name, properties }: TrackEventPayload) => trackEvent(name, properties ?? {}));

const trackEventWithoutContextFx = attach({
  mapParams: ({ properties, name }: TrackEventPayload) => ({
    name,
    properties,
  }),
  effect: trackEventFx,
});

const trackEventWithContextFx = attach({
  source: $context,
  mapParams: ({ properties, name }: TrackEventPayload, context) => ({
    name,
    properties: {
      ...properties,
      ...context,
    },
  }),
  effect: trackEventFx,
});

export function createTrackEvent<P extends Properties = void>(name: string) {
  return trackEventWithoutContextFx.prepend((properties: P) => ({ name, properties }));
}

export function createTrackWithContextEvent<P extends Properties = void>(name: string) {
  return trackEventWithContextFx.prepend((properties: P) => ({ name, properties }));
}
